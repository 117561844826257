/**
 * @generated SignedSource<<e861b8b97413236a64e8c189cae64476>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateSectionChordsInput = {
  chords: ReadonlyArray<ChordInput>;
  clientMutationId?: string | null;
  mongoVersion: number;
  rhythmUnit?: number | null;
  sectionId: string;
  songId: string;
};
export type ChordInput = {
  definition: ChordDefInput;
  duration: number;
  id: string;
  notation: ReadonlyArray<string>;
  rhythm?: ReadonlyArray<number> | null;
};
export type ChordDefInput = {
  bass: string;
  qual: string;
  root: string;
};
export type UpdateSectionChordsMutation$variables = {
  input: updateSectionChordsInput;
};
export type UpdateSectionChordsMutation$data = {
  readonly updateSectionChords: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message?: string;
    }>;
    readonly section: {
      readonly chords: ReadonlyArray<{
        readonly definition: {
          readonly bass: string;
          readonly qual: string;
          readonly root: string;
        };
        readonly duration: number;
        readonly id: string;
        readonly notation: ReadonlyArray<string>;
        readonly rhythm: ReadonlyArray<number>;
      }>;
      readonly id: string;
      readonly rhythmUnit: number;
    } | null;
    readonly song: {
      readonly id: string;
      readonly mongoVersion: number;
    } | null;
  } | null;
};
export type UpdateSectionChordsMutation = {
  response: UpdateSectionChordsMutation$data;
  variables: UpdateSectionChordsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateSectionChordsPayload",
    "kind": "LinkedField",
    "name": "updateSectionChords",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Song",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mongoVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SongSection",
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rhythmUnit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Chord",
            "kind": "LinkedField",
            "name": "chords",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rhythm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChordDef",
                "kind": "LinkedField",
                "name": "definition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "root",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qual",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bass",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notation",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "UserError",
            "abstractKey": "__isUserError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSectionChordsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSectionChordsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ca7198b38905857125240da3aca2d9ab",
    "id": null,
    "metadata": {},
    "name": "UpdateSectionChordsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSectionChordsMutation(\n  $input: updateSectionChordsInput!\n) {\n  updateSectionChords(input: $input) {\n    song {\n      id\n      mongoVersion\n    }\n    section {\n      id\n      rhythmUnit\n      chords {\n        id\n        duration\n        rhythm\n        definition {\n          root\n          qual\n          bass\n        }\n        notation\n      }\n    }\n    errors {\n      __typename\n      ... on UserError {\n        __isUserError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "680740d89c941eb4aba7061207b81368";

export default node;
