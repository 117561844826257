/**
 * @generated SignedSource<<3a22a02cd0ca04f37e542ff74b5b6ce7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type User_Role = "ADMIN" | "ANON" | "USER" | "%future added value";
export type updateOwnEmailInput = {
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type UpdateOwnEmailMutation$variables = {
  input: updateOwnEmailInput;
};
export type UpdateOwnEmailMutation$data = {
  readonly updateOwnEmail: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }>;
    readonly jwt: {
      readonly expiresAt: string | null;
      readonly expiresIn: string | null;
      readonly token: string | null;
    } | null;
    readonly user: {
      readonly boxIntervals: ReadonlyArray<number>;
      readonly email: string;
      readonly emailConfirmed: boolean;
      readonly id: string;
      readonly roles: ReadonlyArray<User_Role>;
    } | null;
  } | null;
};
export type UpdateOwnEmailMutation = {
  response: UpdateOwnEmailMutation$data;
  variables: UpdateOwnEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateOwnEmailPayload",
    "kind": "LinkedField",
    "name": "updateOwnEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JsonWebToken",
        "kind": "LinkedField",
        "name": "jwt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresIn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boxIntervals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailConfirmed",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "UserError",
            "abstractKey": "__isUserError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOwnEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOwnEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3a5b0696751c890a19e997f0787b9e56",
    "id": null,
    "metadata": {},
    "name": "UpdateOwnEmailMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOwnEmailMutation(\n  $input: updateOwnEmailInput!\n) {\n  updateOwnEmail(input: $input) {\n    jwt {\n      token\n      expiresIn\n      expiresAt\n    }\n    user {\n      id\n      email\n      roles\n      boxIntervals\n      emailConfirmed\n    }\n    errors {\n      __typename\n      ... on UserError {\n        __isUserError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a3d771e9e8f3171a6fa3ec3542997d0";

export default node;
