import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { SongDeleteShare_permission$key } from "../__generated__/SongDeleteShare_permission.graphql";
import { SongDeleteShareMutation } from "../__generated__/SongDeleteShareMutation.graphql";

import { CustomErrors } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { Modal } from "../_components/Modal";
import { Email } from "../_components/Email";
import { SongDeleteShare_song$key } from "../__generated__/SongDeleteShare_song.graphql";
import {
  IndentedListItem,
} from "../_components/IndentedList";
import {
  ModalContent,
  ModalHeader,
  ModalTitle,
} from "../_style-components/Modal.style";
import Spacer from "../_components/Spacer";
import { TitleArtist } from "../_style-components/TitleArtist";

type Props = {
  songRef: SongDeleteShare_song$key;
  permissionRef: SongDeleteShare_permission$key;
  shareUserId: string;
};

export function SongDeleteShare(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");
  // const [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const song = useFragment(
    graphql`
      fragment SongDeleteShare_song on Song {
        id
        title
        artist
      }
    `,
    props.songRef
  );

  const permission = useFragment(
    graphql`
      fragment SongDeleteShare_permission on SongPermission {
        user
        email
        isOwner
        canEdit
        didAccept
      }
    `,
    props.permissionRef
  );

  const [commit, isInFlight] = useMutation<SongDeleteShareMutation>(graphql`
    mutation SongDeleteShareMutation($input: deleteSongShareInput!) {
      deleteSongShare(input: $input) {
        song {
          id
          mongoVersion
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);
  /* 
  function getOptimisticResponse(newCanEdit: boolean) {
    const updatedPermissions = song.permissions?.map((val) => {
      if (val.user === props.shareUserId) {
        return {
          ...val,
          canEdit: newCanEdit,
        };
      } else {
        return val;
      }
    });
    return {
      updateSongShare: {
        song: {
          id: song.id,
          permissions: updatedPermissions,
        },
        errors: [],
      },
    };
  } */

  function handleCommit() {
    commit({
      variables: {
        input: {
          songId: song.id,
          userId: props.shareUserId,
        },
      },
      onCompleted: (response) => {
        if (response.deleteSongShare) {
          const { errors } = response.deleteSongShare;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
      // optimisticResponse: getOptimisticResponse(newCanEdit),
    });
  }

  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);

  function openConfirmModal() {
    setDoShowDialog(true);
  }

  function closeConfirmModal() {
    setDoShowDialog(false);
  }

  return (
    <>
      {/* <StatusWrapper> */}
      <Button
        onClick={openConfirmModal}
        expand="inline"
        fill="clear"
        palette="danger"
        // size="xs"
        disabled={isInFlight}
      >
        <Icon id="user-minus" size={24} />
      </Button>
      {/*         <ShareeDidAccept>
          {permission.didAccept ? "Sharing" : "Invited"}
        </ShareeDidAccept> */}
      {/* </StatusWrapper> */}

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={closeConfirmModal}
        label={"Stop sharing"}
        handleConfirm={handleCommit}
        actionText={"Stop"}
        actionColor="danger"
        isInFlight={isInFlight}
        showCloseButton={true}
      >
        <ModalHeader>
          <TitleArtist title={song.title} artist={song.artist} />
        </ModalHeader>
        <ModalTitle>Stop Sharing With User</ModalTitle>
        <ModalContent>
          <IndentedListItem>
            <EmailAddress>
              <Email email={permission.email} />
            </EmailAddress>
          </IndentedListItem>
          <Spacer size={16} />
          {permission.didAccept && (
            <p>They will receive their own copy of the song.</p>
          )}
        </ModalContent>
      </Modal>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
    </>
  );
}

const StatusWrapper = styled.div`
  position: relative;
`;

const ShareeDidAccept = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  line-height: 1;
  font-size: ${14 / 16}rem;
  color: var(--color-text-medium);
  pointer-events: none;
`;

const ModalBody = styled.div`
  padding: 0px 16px;
  /* font-size: ${16 / 16}rem; */
`;

const SongTitle = styled.span`
  font-weight: 500;
`;

const EmailAddress = styled.span`
  /* font-weight: 500; */
`;
