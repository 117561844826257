import styled, { css } from "styled-components/macro";

export const textInputStyles = css`
  width: 100%;
  /* if input font below 1rem, Safari will zoom-in on focus  */
  font-size: ${18 / 16 + "rem"};
  font-weight: 400;
  /* padding: 2px 4px; */
  padding: 4px 8px;
  border: none;
  border-bottom: 2px solid var(--color-text-translucent);
  border-radius: 0;
  color: inherit;
  background: inherit;

  &::placeholder {
    color: var(--color-text-medium);
    font-weight: 300;
  }

  &:hover,
  &:focus-visible {
    outline: none;
    border-bottom: 2px solid var(--color-primary-light);
    color: var(--color-text);
  }

  transition: border 0.2s;
`;

export const TextInput = styled.input`
  ${textInputStyles}
`;

// export const TextArea = styled(TextInput)`
export const TextArea = styled.textarea`
  ${textInputStyles}
  /* appears that Safari's stylesheet will add box shadow to the inside top of text area... but only if I assign border-top... use "appearance:none" to override  */
  appearance: none;
  border-left: 2px dashed var(--color-text-translucent);

  &:focus-visible {
    border-left: 2px dashed var(--color-primary-light);
  }
`;
