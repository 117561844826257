import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";

import { UpdateSongMeta_song$key } from "../__generated__/UpdateSongMeta_song.graphql";
import type {
  updateSongMetaInput,
  UpdateSongMetaMutation,
} from "../__generated__/UpdateSongMetaMutation.graphql";
import { /* CustomErrors, */ isApiError } from "../_types/errorTypes";

import { TextInputModal } from "../_components/TextInputModal";
// import { FriendlyErrors } from "../_components/FriendlyErrors";
import { ModalError } from "../_components/ModalError";

type Props = {
  song: UpdateSongMeta_song$key;
};

export default function UpdateSongMeta(props: Props) {
  const songData = useFragment(
    graphql`
      fragment UpdateSongMeta_song on Song {
        id
        mongoVersion
        title
        artist
        isLockedByUser
        editLock {
          isLocked
          lockedBy
          lockedByEmail
          lockedUntil
        }
      }
    `,
    props.song
  );

  const { id, title, artist, mongoVersion, isLockedByUser, editLock } =
    songData;

  // let [friendlyErrors, setFriendlyErrors] = useState<CustomErrors>([]);
  // *** food for thought: good reason to make prominent true "errors"?  Difference between "no connection" errors and "server operation" errors (e.g. attempt to save invalid value to Mongoose Schema)?
  const [errMsg, setErrMsg] = useState<string>("");

  const [commit, isInFlight] = useMutation<UpdateSongMetaMutation>(graphql`
    mutation UpdateSongMetaMutation($input: updateSongMetaInput!) {
      updateSongMeta(input: $input) {
        updatedSong {
          ...UpdateSongMeta_song
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function getOptimisticResponse(input: updateSongMetaInput) {
    return {
      updateSongMeta: {
        updatedSong: {
          id: input.songId,
          mongoVersion: input.mongoVersion + 1,
          title: input.title,
          artist: input.artist,
          isLockedByUser,
          editLock,
        },
        errors: [],
      },
    };
  }

  function handleUpdateSong(input: updateSongMetaInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSongMeta) {
          const { errors } = response.updateSongMeta;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "title",
            initialValue: title,
            label: "Title",
            required: true,
            autoCapitalize: "sentences",
          },
          {
            id: "artist",
            initialValue: artist,
            label: "Artist",
            required: false,
            autoCapitalize: "sentences",
          },
        ]}
        callback={(inputValues: Record<string, string>) => {
          handleUpdateSong({
            songId: id,
            mongoVersion: songData.mongoVersion,
            title: inputValues.title,
            artist: inputValues.artist,
          });
        }}
        // modalLabel="Update Song Title and Artist"
        // modalLabel="Title and Artist"
      />
      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
