import React from "react";
import styled from "styled-components/macro";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// import { Icon } from "../../_components/Icon";

type Props = {
  id: string;
  doAllowReorder: boolean;
  isDragging: boolean;
  children?: React.ReactNode;
  isCursor?: boolean;
};

export function SortableChord({
  id,
  doAllowReorder,
  isDragging,
  children,
  isCursor,
}: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled: !doAllowReorder,
      transition: { duration: 150, easing: "cubic-bezier(0.25, 1, 0.5, 1)" },
    });

  // when dragging, there will two "versions" of the item you're dragging: the "DragOverlay" item, which "hovers", adhered to your point of touch; and the "normal, glued-to-the-list" item: these styles will affect the latter, "glued", item, whereas styles given to the SectionListItem wrapped in DragOverlay will affect the "hovering" item
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging && {
      "--opacity": 0.5,
    }),
  };

  return (
    <Wrapper
      ref={setNodeRef}
      style={style as React.CSSProperties}
      // *** use below if want to entire area of chords/cursor to be draggable, instead of merely handle
      {...listeners}
      {...attributes}
      // *** use below if want to treat cursor differently RE: handle vs. entirely draggable
      // {...(isCursor ? listeners : undefined)}
      // {...(isCursor ? attributes : undefined)}
    >
      {children}
      {/*  {doAllowReorder && !isCursor ? (
        <DragDecoration {...listeners} {...attributes}>
          <Icon id="move" size={24} strokeWidth={1} />
        </DragDecoration>
      ) : null} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* position: relative; */
  opacity: var(--opacity);
  /* display: flex; */

  &:focus-visible {
    /* https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press */
    outline: none;
    background-color: var(--color-highlight-translucent);
  }
`;

const DragDecoration = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  /* docs suggest touch-action:manipulation; should work for TouchSensor, and "none" is needed only for PointerSensor, but not my experience for this context */
  /* https://docs.dndkit.com/api-documentation/sensors/pointer#recommendations */
  /* https://github.com/clauderic/dnd-kit/issues/272#issuecomment-920054835 */
  /* https://github.com/clauderic/dnd-kit/issues/435#issuecomment-905887162 */
  touch-action: none;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-medium);

  /* --checker-color-1: #dddddd99;
  --checker-color-2: transparent;
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-position: 0px 0px, 5px 5px;
  background-size: 10px 10px;
  background-image: linear-gradient(
      45deg,
      var(--checker-color-1) 25%,
      transparent 25%,
      transparent 75%,
      var(--checker-color-1) 75%,
      var(--checker-color-1) 100%
    ),
    linear-gradient(
      45deg,
      var(--checker-color-1) 25%,
      var(--checker-color-2) 25%,
      var(--checker-color-2) 75%,
      var(--checker-color-1) 75%,
      var(--checker-color-1) 100%
    ); */
`;
