import React from "react";

import { Page, PageHeader, PageContent } from "../_components/Page";
import { LeitnerQuery } from "./LeitnerQuery";
import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers/appReducer";
import { PageFooter } from "../_components/Page/PageFooter";
import { ADD_TEST_EVENT_FOOTER_HEIGHT } from "./AddSongTestEvent";

type PageFields = {
  title: string;
  controls: React.ReactNode | null;
  footer: React.ReactNode | null;
};

const pageFieldsInitial: PageFields = {
  title: "",
  controls: null,
  footer: null,
};

export const PageFieldsContext = React.createContext<
  [PageFields, (fields: Partial<PageFields>) => void]
>([pageFieldsInitial, (fields: Partial<PageFields>): void => {}]);

export const LeitnerPage: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  React.useEffect(() => {
    dispatch({
      type: AppActionTypes.SetMode,
      payload: "learn",
    });
  }, []);

  const [pageFields, setPageFields] =
    React.useState<PageFields>(pageFieldsInitial);

  function setSomePageFields(fields: Partial<PageFields>) {
    setPageFields((prevState) => ({
      ...prevState,
      ...fields,
    }));
  }

  return (
    <Page footerHeight={ADD_TEST_EVENT_FOOTER_HEIGHT}>
      <PageHeader
        title={`${pageFields.title}`}
        backPath={`/user-songs`}
        backIcon="list"
        controls={[pageFields.controls]}
      />
      <PageContent>
        {(queryOptions) => {
          return (
            <PageFieldsContext.Provider value={[pageFields, setSomePageFields]}>
              <LeitnerQuery queryOptions={queryOptions} />
            </PageFieldsContext.Provider>
          );
        }}
      </PageContent>
      {pageFields.footer && <PageFooter>{pageFields.footer}</PageFooter>}
    </Page>
  );
};
