import React, { FormEvent } from "react";
import styled from "styled-components/macro";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import * as Yup from "yup";

import {
  addFeedbackEventInput,
  AddFeedbackEventMutation,
  AddFeedbackEventMutation$data,
  FeedbackEventType,
  FeedbackInitiatedBy,
} from "../__generated__/AddFeedbackEventMutation.graphql";
import { isApiError } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import {
  FieldError,
  InputFields,
  StyledForm,
} from "../_components/FormComponents";
import { TextArea } from "./TextInput";
import { getFieldErrors, YUP_FIELDS } from "../_utilities/forms";

import Button from "../_components/Button";
import Spacer from "./Spacer";
import { Icon } from "./Icon";
import { Modal } from "./Modal";
import { requestReview } from "../_plugins/RateApp";
import { isNativeCap } from "../_utilities/detectNative";

const FORM_SCHEMA = Yup.object().shape({
  isSatisfied: YUP_FIELDS.isSatisfied,
  comment: YUP_FIELDS.comment,
});

type FormInput = {
  isSatisfied?: boolean;
  comment: string;
};

const FORM_INPUT_INITIAL = {
  isSatisfied: undefined,
  comment: "",
};

type Props = {
  hideButton?: boolean;
  forceShow?: boolean;
};

export function AddFeedbackEvent(props: Props) {
  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);
  const [inputVals, setInputVals] =
    React.useState<FormInput>(FORM_INPUT_INITIAL);
  const [formInputIsInvalid, setFormInputIsInvalid] =
    React.useState<boolean>(false);
  const [didSucceed, setDidSucceed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  let timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {
    if (typeof props.forceShow === "boolean" && props.forceShow === true) {
      timeoutRef.current = setTimeout(() => {
        setDoShowDialog(true);
      }, 200);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [props.forceShow]);

  const [commit, isInFlight] = useMutation<AddFeedbackEventMutation>(graphql`
    mutation AddFeedbackEventMutation($input: addFeedbackEventInput!) {
      addFeedbackEvent(input: $input) {
        user {
          id
          # after feedback, update doPromptFeedback, otherwise will remain in cache as "true", causing the prompt to show (briefly) again on next load until query completes and "false" is returned by server
          doPromptFeedback
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCommitMutation(input: addFeedbackEventInput) {
    if (isInFlight || didSucceed) return;
    commit({
      variables: {
        input,
      },
      onCompleted: (response: AddFeedbackEventMutation$data) => {
        if (response.addFeedbackEvent) {
          const { user, errors } = response.addFeedbackEvent;
          if (user) {
            setDidSucceed(true);
            let { type, initiatedBy, isSatisfied } = input.feedbackEvent;
            if (
              type === "PROVIDED" &&
              initiatedBy === "SERVER" &&
              isSatisfied === true
            ) {
              // alert("prompt in-app review");
              if (isNativeCap) {
                requestReview();
              }
            }
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  function handleSubmit(e: FormEvent) {
    // make sure e.preventDefault() comes first for form submission
    e.preventDefault();
    if (!FORM_SCHEMA.isValidSync(inputVals)) {
      setFormInputIsInvalid(true);
      return;
    }
    let initiatedBy = props.forceShow
      ? ("SERVER" as FeedbackInitiatedBy)
      : ("USER" as FeedbackInitiatedBy);
    handleCommitMutation({
      feedbackEvent: {
        ...inputVals,
        type: "PROVIDED" as FeedbackEventType,
        initiatedBy,
      },
    });
  }

  function handleDeclineFeedback() {
    if (!props.forceShow) {
      // don't log a "decline" if user initiated the feedback prompt
      return;
    }
    handleCommitMutation({
      feedbackEvent: { type: "DECLINED" as FeedbackEventType },
    });
  }

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  return (
    <>
      {!props.hideButton && (
        <Button
          onClick={open}
          fill="clear"
          palette="secondary"
          // size="sm"
          iconId={"message-circle"}
          disabled={isInFlight}
        >
          Give Feedback
        </Button>
      )}
      <Modal
        doShowDialog={doShowDialog}
        closeDialog={close}
        doUseLeastDestructiveRef={true}
        isInFlight={isInFlight}
        showCloseButton={didSucceed}
        handleOnModalCancel={handleDeclineFeedback}
        // headerIcon={{ id: "message-circle", color: "var(--color-secondary" }}
      >
        {didSucceed ? (
          <FeedbackConfirmation>Thanks for your thoughts!</FeedbackConfirmation>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            {/* <FormHeader>May we have your thoughts?</FormHeader> */}
            <Spacer size={8} axis="vert" />
            <InputFields>
              <TextLabel>
                Are you happy with {process.env.REACT_APP_NAME}?
              </TextLabel>
              <Spacer size={8} axis="vert" />
              <ButtonsWrapper>
                <Button
                  onClick={() =>
                    setInputVals((state) => ({
                      ...state,
                      isSatisfied: false,
                    }))
                  }
                  fill={inputVals.isSatisfied === false ? "fill" : "clear"}
                  // disabled={inputVals.isSatisfied === false}
                  // palette="danger"
                >
                  <IconWrapper>
                    <Icon
                      // id="thumbs-down"
                      id="frown"
                      size={40}
                      strokeWidth={2}
                    />
                    <Spacer size={8} axis="vert" />
                    No
                  </IconWrapper>
                </Button>
                <Spacer size={8} axis="horiz" />
                <Button
                  onClick={() =>
                    setInputVals((state) => ({
                      ...state,
                      isSatisfied: true,
                    }))
                  }
                  fill={inputVals.isSatisfied === true ? "fill" : "clear"}
                  // disabled={inputVals.isSatisfied === true}
                >
                  <IconWrapper>
                    <Icon
                      // id="thumbs-up"
                      id="smile"
                      size={40}
                      strokeWidth={2}
                    />
                    <Spacer size={8} axis="vert" />
                    Yes
                  </IconWrapper>
                </Button>
              </ButtonsWrapper>
              <FieldError>
                {getFieldErrors(
                  FORM_SCHEMA,
                  inputVals,
                  "isSatisfied",
                  formInputIsInvalid
                )}
              </FieldError>
              <Spacer size={16} axis="vert" />
              <label>
                <TextLabel>What do you like? What should change?</TextLabel>
                <Spacer size={8} axis="vert" />
                <TextArea
                  name="comment"
                  // as={"textarea"}
                  rows={4}
                  value={inputVals.comment}
                  // placeholder={"I like...  You should change..."}
                  onChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement>
                    // | React.ChangeEvent<HTMLInputElement>
                  ) =>
                    setInputVals((state) => ({
                      ...state,
                      comment: e.target.value,
                    }))
                  }
                />
              </label>
              <FieldError>
                {getFieldErrors(
                  FORM_SCHEMA,
                  inputVals,
                  "comment",
                  formInputIsInvalid
                )}
              </FieldError>
            </InputFields>
            <Button
              type="submit"
              // allow attempted submission when inputs are invalid, to trigger display of input errors
              disabled={isInFlight}
              palette={isInFlight ? "mono" : "primary"}
              expand="block"
            >
              Submit
            </Button>
          </StyledForm>
        )}
      </Modal>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: space-between; */
  align-items: center;
`;

const FormHeader = styled.header`
  font-size: ${20 / 16}rem;
  font-weight: 500;
  padding: 8px 8px 16px 8px;
  color: var(--color-text);
`;

const TextLabel = styled.div`
  /* note: in an earlier iteration, this was a "label" html element; if you give left/right padding to an inline element, like a label, you'll end up "indenting" the first line of multiline text */
  font-size: ${18 / 16}rem;
  color: var(--color-text);
  padding: 0 8px;
`;

const FeedbackConfirmation = styled.div`
  padding: 8px;
  white-space: pre-wrap;
  font-size: ${18 / 16}rem;
  /* font-weight: 500; */
  /* text-align: center; */
`;
