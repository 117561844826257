import { Haptics, ImpactStyle } from "@capacitor/haptics";

export const hapticsImpactMedium = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Medium });
  } catch (err) {
    console.error(err);
  }
};

export const hapticsImpactLight = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Light });
  } catch (err) {
    console.error(err);
  }
};

export const hapticsVibrate = async () => {
  try {
    await Haptics.vibrate();
  } catch (err) {
    console.error(err);
  }
};

export const hapticsSelectionStart = async () => {
  try {
    await Haptics.selectionStart();
  } catch (err) {
    console.error(err);
  }
};

export const hapticsSelectionChanged = async () => {
  try {
    await Haptics.selectionChanged();
  } catch (err) {
    console.error(err);
  }
};

export const hapticsSelectionEnd = async () => {
  try {
    await Haptics.selectionEnd();
  } catch (err) {
    console.error(err);
  }
};
