import { USER_ROLE_OBJ } from "../_constants";
import { UserType, JwtType } from "../_types";
import type { ActionMap } from "./types";

export type AuthType = {
  user: UserType;
  jwt: JwtType;
};

const userInit: NonNullable<UserType> = {
  id: "",
  roles: [USER_ROLE_OBJ.ANON],
  email: "",
  boxIntervals: [],
};

// *** why did you make UserType non-nullable, not JwtType?

const jwtInit: JwtType = {
  token: "",
  expiresIn: "",
  expiresAt: "",
};

export const authReducerInit: AuthType = {
  user: userInit,
  jwt: jwtInit,
};

export enum AuthActionTypes {
  Set = "SET_USER",
  Reset = "RESET_USER",
}

type AuthPayload = {
  [AuthActionTypes.Set]: AuthType;
  [AuthActionTypes.Reset]: null;
};
export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export function authReducer(state: AuthType, action: AuthActions) {
  switch (action.type) {
    case AuthActionTypes.Set:
      let user = action.payload.user;
      let jwt = action.payload.jwt;
      return {
        ...state,
        user,
        jwt,
      };
    case AuthActionTypes.Reset:
      return {
        ...authReducerInit,
        user: {
          ...userInit,
          email: state?.user?.email || "",
        },
      };
    default:
      return state;
  }
}
