import React from "react";

// *** need to be made into a TS file - had problem with the typing of items in childArray

export function getDisplayedValue(
  // value: string | number,
  // children: React.ReactNode
  value,
  children
) {
  const childArray = React.Children.toArray(children);
  const selectedChild = childArray.find((child) => child.props.value === value);

  return selectedChild?.props.children;
}
