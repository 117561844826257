import React, { Suspense } from "react";
import styled from "styled-components/macro";

import { ErrorBoundary, ErrorUI } from "../../_components";
import Spacer from "../Spacer";
import { ModalError } from "../ModalError";
import { QueryOptions } from "../../_types/relayTypes";
import { FetchPolicy } from "relay-runtime";
import Button from "../Button";
import { Icon } from "../Icon";
import { LoadingSpinnerComplete } from "../LoadingSpinner";
import { PageContext } from "./Page";

type QueryState = {
  firstFetchDate: string;
  fetchCount: number;
  fetchKey: string;
  fetchPolicy: FetchPolicy;
};

type Props = {
  children?: React.ReactNode | ((queryOptions: QueryOptions) => void);
  // for use with e.g. "logout" button on settings page: elements you want to "escape" ErrorBoundary and Suspense; will display "alwaysVisible" content below children;
  alwaysVisible?: React.ReactNode;
};

export function PageContentData(props: Props) {
  const [pageState, setPageState] = React.useContext(PageContext);

  const queryStateInit: QueryState = {
    firstFetchDate: new Date().toISOString(),
    // date used in fetchKey to ensure each fresh mount of a query component has new fetchKey and will fetch fresh data *** NOTE: this means you must generate this *on render*; if this is a constant living outside a React component, the date will be fixed, and you will re-use a key, which will be tied to old data and possibly a cached error from a prior, failed, query,
    fetchKey: new Date().toISOString(),
    // count used to allow refreshing while query component remains mounted
    fetchCount: 0,
    fetchPolicy: "store-and-network" as FetchPolicy,
  };

  const [queryState, setQueryState] = React.useState(queryStateInit);

  const refreshQuery = React.useCallback((doUseStore?: boolean) => {
    // wraping in useCallback to allow refreshQuery to be included in useEffect dependency array w/o resulting in "Maximum update depth exceeded" warning to due a feedback loop of renders;
    setQueryState((prev) => ({
      firstFetchDate: prev.firstFetchDate,
      fetchCount: prev.fetchCount + 1,
      fetchKey: prev.firstFetchDate + `_${prev.fetchCount}`,
      // *** network-only will discard/ignore whatever is already in store, so user's data (e.g. list of songs) will be hidden and replaced by loading spinner; store-and-network will keep store data on screen while fetching network data, but in current setup will not show that a refresh is occuring; would be ideal if could get a refresh overlay, or cause button to spin while refresh occurring, though not currently sure how to get that sort of "feedback" from underlying queries
      // fetchPolicy: "network-only" as FetchPolicy,
      fetchPolicy: doUseStore
        ? ("store-and-network" as FetchPolicy)
        : ("network-only" as FetchPolicy),
    }));
  }, []);

  React.useEffect(() => {
    setPageState((prevState) => ({
      ...prevState,
      refreshQuery,
    }));
  }, [refreshQuery, setPageState]);

  return (
    <>
      <ErrorBoundary
        fallback={({ error, clearError }) => (
          <>
            <ErrorUI error={error} />
            {/* only show refresh button if a refresh is possible b/c child is a function that accepts queryOptions */}
            {typeof props.children === "function" && (
              <>
                <Button
                  onClick={() => {
                    clearError();
                    refreshQuery();
                  }}
                  iconId="refresh-cw"
                  fill="clear"
                  palette="secondary"
                >
                  Retry
                </Button>
              </>
            )}
            {/* <ModalError errMsg={error.message} setErrMsg={() => null} /> */}
          </>
        )}
      >
        <Suspense fallback={<LoadingSpinnerComplete />}>
          {typeof props.children === "function"
            ? props.children({
                fetchKey: queryState.fetchKey,
                fetchPolicy: queryState.fetchPolicy,
              })
            : props.children}
        </Suspense>
      </ErrorBoundary>
      {props.alwaysVisible}
      {/* only show refresh button if a refresh is possible b/c child is a function that accepts queryOptions */}
      {/*       {typeof props.children === "function" && (
        <>
          <Spacer size={64} axis="vert" />
          <Button onClick={refreshQuery} size="xs" fill="clear" palette="mono">
            <IconWrapper>
              <Icon id="refresh-cw" size={16} strokeWidth={2} />
            </IconWrapper>
          </Button>
        </>
      )} */}
    </>
  );
}

const IconWrapper = styled.div`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
`;
