/**
 * @generated SignedSource<<0129684cfe24d52cc303cba9cfac6a8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSongNotes_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly notes: string;
  readonly " $fragmentType": "UpdateSongNotes_song";
};
export type UpdateSongNotes_song$key = {
  readonly " $data"?: UpdateSongNotes_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSongNotes_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSongNotes_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "50926e7a2edc7f3f0afb835818fe26ce";

export default node;
