import { CHORD_QUALS } from "../_music/chordQualLib";
import { rootNums } from "../_music/roots";
import type { ChordDefinition } from "../_types";

export const CURSOR_ID = "CURSOR";
export const CURSOR_EMPTY_VAL = "CURSOR_EMPTY_VAL";

export type Cursor = {
  id: typeof CURSOR_ID;
  definition: ChordDefinition;
  duration: number;
  rhythm: readonly number[];
  notation: readonly string[];
  // whether cursor vals were set by app or user
  // isSuggestion: boolean;
};

export function makeDefaultCursor(): Cursor {
  // note to future self: you made a ref. mistake in earlier version, when you exported an object to act as default cursor, instead of this "factory": this all cursor instances referenced the same object;
  return {
    id: CURSOR_ID,
    definition: {
      root: CURSOR_EMPTY_VAL,
      qual: CURSOR_EMPTY_VAL,
      bass: CURSOR_EMPTY_VAL,
    },
    duration: 0,
    rhythm: [],
    notation: [],
  };
}

export function isRootValid(root: string) {
  if (rootNums[root] === undefined) {
    return false;
  }
  return true;
}

export function isQualValid(qual: string) {
  if (CHORD_QUALS[qual] === undefined) {
    return false;
  }
  return true;
}

// test whether cursor has user-selected values sufficient to add a chord
export function isCursorValidChord(root: string, qual: string) {
  if (rootNums[root] === undefined || CHORD_QUALS[qual] === undefined) {
    return false;
  }
  return true;
}

// for chord input, display partial vals for cursor if at least one of root, qual has value
export function doHideCursorVals(root: string, qual: string) {
  if (rootNums[root] === undefined && CHORD_QUALS[qual] === undefined) {
    return true;
  }
  return false;
}
