/**
 * @generated SignedSource<<93e3465d50a28185eb69234ae2f1dd5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisplaySection_song$data = {
  readonly id: string;
  readonly timeSig: TimeSig;
  readonly viewerPermission: {
    readonly canEdit: boolean;
    readonly didAccept: boolean;
    readonly email: string;
    readonly isOwner: boolean;
    readonly user: string;
  };
  readonly " $fragmentType": "DisplaySection_song";
};
export type DisplaySection_song$key = {
  readonly " $data"?: DisplaySection_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisplaySection_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisplaySection_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SongPermission",
      "kind": "LinkedField",
      "name": "viewerPermission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "user",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "didAccept",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "b01d66b3577c635e9e8f21e9731f513e";

export default node;
