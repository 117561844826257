/**
 * @generated SignedSource<<18c5d576bbf057f7bd7eb8f6ae3eb6ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackEventType = "DECLINED" | "PROVIDED" | "%future added value";
export type FeedbackInitiatedBy = "SERVER" | "USER" | "%future added value";
export type AdminQueryQuery$variables = {};
export type AdminQueryQuery$data = {
  readonly allData: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly songs: ReadonlyArray<{
      readonly createdAt: string;
      readonly id: string;
      readonly permissions: ReadonlyArray<{
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      }>;
      readonly title: string;
      readonly updatedAt: string;
      readonly user: string;
    } | null>;
    readonly users: ReadonlyArray<{
      readonly createdAt: string;
      readonly email: string;
      readonly feedbackHistory: ReadonlyArray<{
        readonly comment: string | null;
        readonly initiatedBy: FeedbackInitiatedBy | null;
        readonly isSatisfied: boolean | null;
        readonly time: string;
        readonly type: FeedbackEventType;
      }>;
      readonly id: string;
      readonly updatedAt: string;
    } | null>;
    readonly " $fragmentSpreads": FragmentRefs<"AdminUserDetails_songs" | "AdminUserDetails_users">;
  } | null;
};
export type AdminQueryQuery = {
  response: AdminQueryQuery$data;
  variables: AdminQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeedbackEvent",
      "kind": "LinkedField",
      "name": "feedbackHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initiatedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSatisfied",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comment",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Song",
  "kind": "LinkedField",
  "name": "songs",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SongPermission",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canEdit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "didAccept",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AllDataQueryResult",
        "kind": "LinkedField",
        "name": "allData",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminUserDetails_users"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminUserDetails_songs"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AllDataQueryResult",
        "kind": "LinkedField",
        "name": "allData",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ccc401f50fd34e06b700f1e0bc0fa93a",
    "id": null,
    "metadata": {},
    "name": "AdminQueryQuery",
    "operationKind": "query",
    "text": "query AdminQueryQuery {\n  allData {\n    users {\n      id\n      createdAt\n      updatedAt\n      email\n      feedbackHistory {\n        type\n        time\n        initiatedBy\n        isSatisfied\n        comment\n      }\n    }\n    songs {\n      id\n      user\n      createdAt\n      updatedAt\n      title\n      permissions {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n    ...AdminUserDetails_users\n    ...AdminUserDetails_songs\n  }\n}\n\nfragment AdminUserDetails_songs on AllDataQueryResult {\n  songs {\n    id\n    user\n    createdAt\n    updatedAt\n    title\n    permissions {\n      user\n      email\n      isOwner\n      canEdit\n      didAccept\n    }\n  }\n}\n\nfragment AdminUserDetails_users on AllDataQueryResult {\n  users {\n    id\n    createdAt\n    updatedAt\n    email\n    feedbackHistory {\n      type\n      time\n      initiatedBy\n      isSatisfied\n      comment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "740acd02d20da83709b68bae744ce43c";

export default node;
