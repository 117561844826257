import React from "react";
import styled from "styled-components/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Spacer from "../_components/Spacer";
import { LeitnerSettingsQueryQuery } from "../__generated__/LeitnerSettingsQueryQuery.graphql";
import { UpdateBoxIntervals } from "./UpdateBoxIntervals";
import { QueryOptions } from "../_types/relayTypes";
import { Icon } from "../_components/Icon";
import Button from "../_components/Button";

type Props = {
  queryOptions: QueryOptions;
};

export function LeitnerSettingsQuery(props: Props) {
  const data = useLazyLoadQuery<LeitnerSettingsQueryQuery>(
    graphql`
      query LeitnerSettingsQueryQuery {
        viewer {
          user {
            id
            boxIntervals
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    {},
    props.queryOptions
  );

  const viewerDataWithErrors = data.viewer;
  if (!viewerDataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors } = viewerDataWithErrors;

  return (
    <>
      {/* data result */}
      {user && (
        <>
          <Instructions>
            <p>
              To memorize songs, tap the
              <InlineIcon>
                <Icon id="book-open" display="inline" size={20} />
              </InlineIcon>
              button above the song list.
            </p>
            <p>
              Five virtual "boxes" hold your songs. The higher the box number,
              the less often you review the song.
            </p>
            <p>
              Remember a song: it moves "up" one box, and you will
              review it less often.
            </p>
            <p>
              Forget a song: it moves "down" to box 1, for most
              frequent review.
            </p>
            <p>
              Select how often you'll review your "box 1" songs. Your
              selection will change the frequency for each box.
            </p>
            <UpdateBoxIntervals boxIntervals={user.boxIntervals} />
            <Spacer axis="vert" size={16} />
            <BoxDays>
              {user.boxIntervals.map((val, i) => (
                <Box key={val}>
                  <b>Box {i + 1}</b> - {val} day{val === 1 ? "" : "s"}
                </Box>
              ))}
            </BoxDays>
          </Instructions>
        </>
      )}

      {/* null result */}
      {!user && <DataNotFound />}

      {/* user-friendly error messages from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}

const Instructions = styled.div`
  padding: 0 16px;
`;
const InlineIcon = styled.span`
  padding: ${8 / 16}rem;
  /* color: var(--color-primary); */
  color: var(--color-text);
`;

const BoxDays = styled.div``;
const Box = styled.div``;
