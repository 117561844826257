export type CustomErrors = readonly {
  readonly __typename: string;
  readonly message?: string;
}[];

interface ApiError {
  message: string;
}

export function isApiError(x: any): x is ApiError {
  return typeof x.message === "string";
}
