/**
 * @generated SignedSource<<f6e1b0182720cd87bcb852d2a6e63a09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type rejectSongShareInput = {
  clientMutationId?: string | null;
  songId: string;
};
export type SongRejectShareMutation$variables = {
  connections: ReadonlyArray<string>;
  input: rejectSongShareInput;
};
export type SongRejectShareMutation$data = {
  readonly rejectSongShare: {
    readonly clonedSong: {
      readonly artist: string;
      readonly id: string;
      readonly mongoVersion: number;
      readonly permissions: ReadonlyArray<{
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      }>;
      readonly tags: ReadonlyArray<string>;
      readonly title: string;
      readonly viewerPermission: {
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly rejectedSong: {
      readonly id: string;
      readonly permissions: ReadonlyArray<{
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      }>;
    } | null;
  } | null;
};
export type SongRejectShareMutation = {
  response: SongRejectShareMutation$data;
  variables: SongRejectShareMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SongPermission",
  "kind": "LinkedField",
  "name": "permissions",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Song",
  "kind": "LinkedField",
  "name": "clonedSong",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artist",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SongPermission",
      "kind": "LinkedField",
      "name": "viewerPermission",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "connections",
  "variableName": "connections"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongRejectShareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "rejectSongSharePayload",
        "kind": "LinkedField",
        "name": "rejectSongShare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "rejectedSong",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongRejectShareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "rejectSongSharePayload",
        "kind": "LinkedField",
        "name": "rejectSongShare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "rejectedSong",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  (v7/*: any*/)
                ]
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "clonedSong",
            "handleArgs": [
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "UserSongs"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a469326bacbc99a0e940646050158aac",
    "id": null,
    "metadata": {},
    "name": "SongRejectShareMutation",
    "operationKind": "mutation",
    "text": "mutation SongRejectShareMutation(\n  $input: rejectSongShareInput!\n) {\n  rejectSongShare(input: $input) {\n    rejectedSong {\n      id\n      permissions {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n    }\n    clonedSong {\n      id\n      mongoVersion\n      title\n      artist\n      tags\n      permissions {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n      viewerPermission {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cbb8318176df5e9a0bfc7894d3544c3";

export default node;
