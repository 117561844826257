import React from "react";
import styled from "styled-components/macro";
import { TimeSigDisplay } from "../_components/TimeSigDisplay";
import { TimeSigClient } from "../_music/rhythmCount";

import { Notes } from "./Notes";

type Props = {
  name: string;
  notes: string;
  timeSig?: TimeSigClient;
};

export function DisplaySectionName({ name }: { name: string }) {
  return <SectionName>{name}</SectionName>;
}
export function DisplaySectionNotes({ notes }: { notes: string }) {
  return (
    <SectionNotes>
      <Notes notes={notes} />
    </SectionNotes>
  );
}
export function DisplaySectionMeta(props: Props) {
  return (
    <Wrapper>
      <NameTimeSigWrapper>
        <DisplaySectionName name={props.name} />
      </NameTimeSigWrapper>
      <DisplaySectionNotes notes={props.notes} />
      {props.timeSig && (
        <TimeSigDisplay timeSigVal={props.timeSig} labelOrVert={"vert"} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 8px;
  padding-top: 8px;
  /* border-left: 4px solid var(--color-deep-silver); */
  /* box-shadow: inset 0px 1px 1px var(--color-shadow-light); */
  color: var(--color-text-medium);
`;
const NameTimeSigWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;
export const SectionName = styled.div`
  color: var(--color-text-medium);
  text-transform: uppercase;
  font-size: ${14 / 16}rem;
  font-weight: 600;
`;
export const SectionNotes = styled.div`
  /* font-size: ${16 / 16}rem; */
`;

export const Empty = styled.div`
  padding: 4px 8px;
  color: var(--color-text-medium);
`;
