import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";

import { UpdateSongNotes_song$key } from "../__generated__/UpdateSongNotes_song.graphql";
import {
  updateSongNotesInput,
  UpdateSongNotesMutation,
} from "../__generated__/UpdateSongNotesMutation.graphql";

import { CustomErrors, isApiError } from "../_types/errorTypes";

import { TextInputModal } from "../_components/TextInputModal";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { ModalError } from "../_components/ModalError";

type Props = {
  song: UpdateSongNotes_song$key;
};

export default function UpdateSongNotes(props: Props) {
  const songData = useFragment(
    graphql`
      fragment UpdateSongNotes_song on Song {
        id
        mongoVersion
        notes
      }
    `,
    props.song
  );

  const { id, notes } = songData;

  // let [friendlyErrors, setFriendlyErrors] = useState<CustomErrors>([]);
  // *** food for thought: good reason to make prominent true "errors"?  Difference between "no connection" errors and "server operation" errors (e.g. attempt to save invalid value to Mongoose Schema)?
  const [errMsg, setErrMsg] = useState<string>("");

  const [commit, isInFlight] = useMutation<UpdateSongNotesMutation>(graphql`
    mutation UpdateSongNotesMutation($input: updateSongNotesInput!) {
      updateSongNotes(input: $input) {
        updatedSong {
          id
          notes
          mongoVersion
          isLockedByUser
          editLock {
            isLocked
            lockedBy
            lockedByEmail
            lockedUntil
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleUpdateSong(input: updateSongNotesInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.updateSongNotes) {
          const { updatedSong, errors } = response.updateSongNotes;
          if (updatedSong) {
          }
          if (errors.length > 0) {
            if (errors.length > 0) {
              const errMsgs = errors.map((err) => err.message);
              setErrMsg(errMsgs.join("; "));
            }
            // setFriendlyErrors(errors);
          }
        }
      },

      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "notes",
            initialValue: notes,
            label: "Notes",
            elementType: "textarea",
          },
        ]}
        callback={(inputValues: Record<string, string>) => {
          handleUpdateSong({
            songId: id,
            mongoVersion: songData.mongoVersion,
            notes: inputValues.notes,
          });
        }}
        // modalLabel="Update Notes"
      />
      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
