/**
 * Consider:
 * - Your test set has an 8x ratio from shortest duration to longest; longer than most users would attempt;
 * - De-emphasizing duration of last chord tap, because determined by tap on "finish" button, which ergonomically is more awkward;
 */

import { CURSOR_ID } from "../ChordsSimple/Cursor";
import { FINISH_RHYTHM_ID } from "../_hooks/useRhythm.helpers";
import { ChordOrCursor, RhythmUnit } from "../_types";

export type ChordTapTime = {
  chordId: string;
  time: number;
};

type ChordTapDuration = {
  chordId: string;
  time: number;
  duration: number;
};

type ChordTapCorrected = {
  chordId: string;
  time: number;
  duration: number;
  ratio: number;
};

export type TapGroupByDuration = {
  taps: ChordTapDuration[];
  durs: number[];
  localCorrect: number;
  ratio: number;
  globalCorrect: number;
};

type TapGroupByChord = {
  chordId: string;
  rhythm: number[];
  //   duration: number;
  //   rhythmRatio: number[];
  durationRatio: number;
};

function roundToDecimal(val: number, decimal: number) {
  return Math.round(val * (1 / decimal)) / (1 / decimal);
}

function getRoundedRatio(
  val: number,
  benchmark: number,
  tapDurationSens: number
) {
  return roundToDecimal(val / benchmark, tapDurationSens);
}

function getAverage(numArray: number[]) {
  var sum = numArray.reduce((prev, curr) => {
    return prev + curr;
  }, 0);
  return sum / numArray.length;
}

function getStats(arr: number[]) {
  let groupAvg = getAverage(arr);
  let groupMedian = arr[Math.ceil(arr.length / 2) - 1];
  let groupAvgWMedian = (groupAvg + groupMedian) / 2;
  return { groupAvg, groupMedian, groupAvgWMedian };
}

function getGroupBenchmark(group: TapGroupByDuration) {
  let groupStats = getStats(group.durs);
  // had thought using median for larger groups would produce better results; at least in this test scenario, not the case; average performs significantly better; possibly because your test sets are created in a way that naturally prefers average, randomly assigning duration and direction of variance, whereas a real user may be more likely to drift in a single direction, and may be more likely to vary on the longest/shortest taps?
  let groupBenchmark =
    // group.durs.length > 2 ? groupStats.groupMedian : groupStats.groupAvg;
    groupStats.groupAvg;
  return groupBenchmark;
}

function getAllGroupsBenchmark(
  grouped: TapGroupByDuration[],
  allGroupsBenchmark: number,
  tapDurationSens: number
) {
  var weightedDivisors = [];
  for (let i = 0; i < grouped.length; i++) {
    var group = grouped[i];
    let groupBenchmark = getGroupBenchmark(group);
    group.localCorrect = groupBenchmark;
    let ratio = getRoundedRatio(
      groupBenchmark,
      allGroupsBenchmark,
      tapDurationSens
    );
    group.ratio = ratio;
    for (let j = 0; j < group.durs.length; j++) {
      weightedDivisors.push(groupBenchmark / ratio);
    }
  }
  // rounding makes a little less accurate, but otherwise easier to deal with;
  // necessarily, as ratio increases between user's shortest and longest taps, taps must be more accurate to ensure the longest taps are assigned the correct ratios; put another way, with a larger ratio (e.g. 200/25), slight deviations in the benchmark shortest tap result in larger changes to the ratio, which will be more likely to round to the next ratio, esp. when allow 1/2 ratios; 200/25===8; 200/24===8.333, which will round to 8.5; 212.5/25===8.5;
  return roundToDecimal(getAverage(weightedDivisors), tapDurationSens);
  // return getAverage(weightedDivisors);
}

function isInCurrentGroup(
  allGroupsBenchmark: number,
  thisGroup: TapGroupByDuration,
  chordTapDuration: ChordTapDuration,
  tapDurationSens: number
) {
  var deviationFromGroupBenchmark =
    chordTapDuration?.duration - thisGroup.localCorrect;
  // as tapDurationSens lowers, will make this check less permissive; getRoundedRatio() benefits from this less permissive rule, allowing you to round to non-integer ratios (namely, to include halves); somewhat contrary to intuition, if you used tapDurationSens of 0.75 here, but kept getRoundRatio limited to integers or halves, you'd reduce accuracy; this is because you'd be too permissive here
  return (
    deviationFromGroupBenchmark < allGroupsBenchmark * 0.5 * tapDurationSens
  );
}

// tested if would receive significantly improved results by performing a second round of groupTaps, feeding its resulting allGroupsBenchmark back into it as the new starting allGroupsBenchmark; no significant improvement;
function groupTaps(
  chordTapDurations: ChordTapDuration[],
  tapDurationSens: number
  //   startingBenchmark?: number
): { grouped: TapGroupByDuration[]; allGroupsBenchmark: number } {
  if (chordTapDurations.length === 0) {
    // if length of allGroupsBenchmark is 0, there was only one tap sent to normalizeTapGroup(), so couldn't calculate any tap durations; abort with empty return;
    return { grouped: [], allGroupsBenchmark: 0 };
  }

  let i: number = 0,
    grouped: TapGroupByDuration[] = [],
    allGroupsBenchmark: number = chordTapDurations[0]?.duration;
  // allGroupsBenchmark: number =
  //   startingBenchmark ?? chordTapDurations[0].duration;

  while (i < chordTapDurations.length) {
    // create new group;
    grouped.push({
      taps: [],
      durs: [],
      // placeholders
      localCorrect: 0,
      ratio: 0,
      globalCorrect: 0,
    });
    let thisGroup = grouped[grouped.length - 1];
    // console.log("Group #", grouped.length);
    // the first tap in each iteration of this outer loop will always be added to the new group (taps are in order of increasing duration, and either this is the first tap in the array, or code excluded this tap from the prior group);
    thisGroup.durs.push(chordTapDurations[i].duration);
    thisGroup.taps.push(chordTapDurations[i]);
    i++;
    // update allGroupsBenchmark using previously-calculated allGroupsBenchmark;
    allGroupsBenchmark = getAllGroupsBenchmark(
      grouped,
      allGroupsBenchmark,
      tapDurationSens
    );
    while (
      chordTapDurations[i] &&
      isInCurrentGroup(
        allGroupsBenchmark,
        thisGroup,
        chordTapDurations[i],
        tapDurationSens
      )
    ) {
      thisGroup.durs.push(chordTapDurations[i].duration);
      thisGroup.taps.push(chordTapDurations[i]);
      i++;
      // update allGroupsBenchmark using previously-calculated allGroupsBenchmark;
      allGroupsBenchmark = getAllGroupsBenchmark(
        grouped,
        allGroupsBenchmark,
        tapDurationSens
      );
    }
  }

  //   if (!startingBenchmark) {
  //     return groupTaps(chordTapDurations, tapDurationSens, allGroupsBenchmark);
  //   }

  grouped.forEach((group) => {
    group.globalCorrect = group.ratio * allGroupsBenchmark;
  });
  return { grouped, allGroupsBenchmark };
}

export function normalizeTapGroup(
  chordTapTimes: ChordTapTime[],
  tapDurationSens: number = 1,
  otherSectionChordTaps?: ChordTapDuration[]
) {
  var chordTapDurations: ChordTapDuration[] = [];
  // get duration of each tap; no duration capured for final tap, because that's a tap on the "finish" button;
  for (let i = 1; i < chordTapTimes.length; i++) {
    var thisTap = chordTapTimes[i],
      priorTap = chordTapTimes[i - 1],
      tapDuration = thisTap.time - priorTap.time;
    chordTapDurations.push({
      chordId: chordTapTimes[i - 1].chordId,
      time: chordTapTimes[i - 1].time,
      duration: tapDuration,
    });
  }
  if (otherSectionChordTaps) {
    chordTapDurations = [...chordTapDurations, ...otherSectionChordTaps];
  }
  // sort lowest to highest;
  chordTapDurations.sort((a, b) => a.duration - b.duration);
  // group durations based on proximity to sorted neighbors, anchoring to rolling "benchmark" for each group;
  let { grouped, allGroupsBenchmark } = groupTaps(
    chordTapDurations,
    tapDurationSens
  );
  return { grouped, allGroupsBenchmark };
}

function tapGroupsToArray(
  tapGroups: TapGroupByDuration[]
): ChordTapCorrected[] {
  // create array of all taps;
  let correctedTaps: ChordTapCorrected[] = tapGroups.reduce(
    (prev: ChordTapCorrected[], curr) => {
      let groupTapsWithGlobalCorrect: ChordTapCorrected[] = curr.taps.map(
        (tap) => ({
          chordId: tap.chordId,
          time: tap.time,
          duration: curr.globalCorrect,
          ratio: curr.ratio,
        })
      );
      return [...prev, ...groupTapsWithGlobalCorrect];
    },
    []
  );
  return correctedTaps;
}

function reassembleTaps(tapGroups: TapGroupByDuration[]): TapGroupByChord[] {
  var correctedTaps = tapGroupsToArray(tapGroups);
  // sort taps by chordId; to ensure all taps on a single chord are in a continuous group in the array;
  correctedTaps.sort((a, b) => {
    if (a.chordId > b.chordId) {
      return 1;
    } else if (a.chordId < b.chordId) {
      return -1;
    } else {
      // same chordId; sort by time;
      if (a.time - b.time > 0) {
        return 1;
      } else if (a.time - b.time < 0) {
        return -1;
      } else {
        // this should be impossible, as no two chords should have the same time; (note: for the sectionChords you pull in as otherSectionChordTaps, they will have 0 indexes for this value, so the rule for them is: "no two chords of the same chordId should have the same time value");
        return 0;
      }
    }
  });
  var tapGroupsAll: TapGroupByChord[] = [];
  // group taps by chordId;
  let tapIndex = 0;
  while (tapIndex < correctedTaps.length) {
    const firstChordTap = correctedTaps[tapIndex];
    const tapGroup: TapGroupByChord = {
      chordId: firstChordTap.chordId,
      rhythm: [firstChordTap.duration],
      // *** currently believe overall duration and ratios should be determined "live", to ensure taps created in separate sessions can be normalized with each other;
      // duration: firstChordTap.duration,
      // rhythmRatio: [firstChordTap.ratio],
      durationRatio: 0,
    };
    tapIndex++;
    // look for the "border" where the chordId of the sorted chord taps changes;
    while (correctedTaps[tapIndex]?.chordId === tapGroup.chordId) {
      let thisTap = correctedTaps[tapIndex];
      tapGroup.rhythm.push(thisTap.duration);
      //   tapGroup.duration += thisTap.duration;
      //   tapGroup.rhythmRatio.push(thisTap.ratio);
      tapIndex++;
    }
    tapGroupsAll.push(tapGroup);
  }
  return tapGroupsAll;
}

/**
 *
 *
 *
 *
 *
 * Entry point
 *
 *
 *
 *
 *
 */

type NormalizeTaps = {
  chordTapTimes: ChordTapTime[];
  tapDurationSens: number;
  tapIdSet: Set<string>;
  sectionChords: ChordOrCursor[];
};

export function normalizeTaps({
  chordTapTimes,
  tapDurationSens = 1,
  tapIdSet,
  sectionChords,
}: NormalizeTaps) {
  // *** next: if this works as desired, should remove redundant logic from rhythmCount.ts;

  // this is an optional parameter for normalizeTapGroup(); if used, it allows taps on a subset to modify the entire section, keeping all taps ratios of each other and thus consistent and scalable; you'll pass all section chord taps in, and update rhythm data for all; you're importing the out-of-session taps separately, to allow you to turn it on/off; when you reassembleTaps() taps, first group by chord id, then "time", so your artifical 0-based indexes will work as expected;
  const otherSectionChordTaps: ChordTapDuration[] = sectionChords?.reduce(
    (prev: ChordTapDuration[], curr) => {
      if (
        tapIdSet.has(curr.id) ||
        curr.id === CURSOR_ID
        //  || curr.rhythm.length === 0
      ) {
        // exclude pre-existing tap data for chords with current taps;
        return prev;
      } else {
        // create a ChordTapDuration for each item in chord.rhythm array to make ChordOrCursor data compatible with fresh tap data as processed by normalizeTapGroup();
        let tapDurations: ChordTapDuration[] = curr.rhythm.map(
          (rhythmVal, i) => {
            return {
              chordId: curr.id,
              // assign 0-based index for time; all that matters here is an in-order numbering for reassembleTaps();
              duration: rhythmVal,
              time: i,
            };
          }
        );
        return [...prev, ...tapDurations];
      }
    },
    []
  );

  var normalized = normalizeTapGroup(
    chordTapTimes,
    tapDurationSens,
    otherSectionChordTaps
  );

  var rhythmUnit = assignRhythmUnit(
    normalized.grouped,
    normalized.allGroupsBenchmark
  );

  var reassembledTaps = reassembleTaps(normalized.grouped);

  let lastChordTapTime = chordTapTimes[chordTapTimes.length - 1];
  if (lastChordTapTime.chordId !== FINISH_RHYTHM_ID) {
    reassembledTaps.push({
      chordId: lastChordTapTime.chordId,
      durationRatio: 0,
      // rhythm: [normalized.allGroupsBenchmark],
      rhythm: [],
    });
  }

  return { normalizedTaps: reassembledTaps, rhythmUnit };
}

function assignRhythmUnit(
  grouped: TapGroupByDuration[],
  allGroupsBenchmark: number
) {
  const LONGEST_PERMITTED_SIXTEENTH_MS = 500;
  const LONGEST_PERMITTED_EIGHTH_MS = LONGEST_PERMITTED_SIXTEENTH_MS * 2;

  var shortestTap: number = -1,
    longestTap: number = -1;

  grouped.forEach((tap) => {
    if (shortestTap === -1 || tap.globalCorrect < shortestTap) {
      shortestTap = tap.globalCorrect;
    }
    if (longestTap === -1 || tap.globalCorrect > longestTap) {
      longestTap = tap.globalCorrect;
    }
  });

  // set subdivision for rhythm count (i.e. quarter, eighth, or sixteenth note);
  var largestDurationRatio = Math.round(longestTap / shortestTap);
  var rhythmUnit: RhythmUnit;
  if (
    largestDurationRatio >= 4
    // && shortestTap <= LONGEST_PERMITTED_SIXTEENTH_MS
  ) {
    // sixteenth notes;
    rhythmUnit = 16;
  } else if (
    largestDurationRatio >= 2
    // && shortestTap <= LONGEST_PERMITTED_EIGHTH_MS
  ) {
    // eighth notes;
    rhythmUnit = 8;
  } else {
    // quarter notes;
    rhythmUnit = 4;
  }

  // console.log({
  //   allGroupsBenchmark,
  //   shortestTap,
  //   longestTap,
  //   largestDurationRatio,
  //   rhythmUnit,
  // });

  return rhythmUnit;
}
