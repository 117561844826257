import React from "react";
import { useHistory } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { AdminQueryQuery } from "../__generated__/AdminQueryQuery.graphql";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { QueryOptions } from "../_types/relayTypes";
import { AdminUserDetails } from "./AdminUserDetails";

type Props = {
  queryOptions: QueryOptions;
};

export function AdminQuery(props: Props) {
  const data = useLazyLoadQuery<AdminQueryQuery>(
    graphql`
      query AdminQueryQuery {
        allData {
          users {
            id
            createdAt
            updatedAt
            email
            feedbackHistory {
              type
              time
              initiatedBy
              isSatisfied
              comment
            }
          }
          songs {
            id
            user
            createdAt
            updatedAt
            title
            permissions {
              user
              email
              isOwner
              canEdit
              didAccept
            }
          }
          errors {
            __typename
            message
          }
          ...AdminUserDetails_users
          ...AdminUserDetails_songs
        }
      }
    `,
    {},
    props.queryOptions
  );

  const dataWithErrors = data.allData;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { users, songs, errors } = dataWithErrors;

  return (
    <>
      {/* data result */}
      {users && songs && (
        <>
          <AdminUserDetails
            usersRef={dataWithErrors}
            songsRef={dataWithErrors}
          />
        </>
      )}

      {/* null result */}
      {(!users || !songs) && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
