import React from "react";
import styled from "styled-components/macro";
import { animated } from "@react-spring/web";
import Portal from "@reach/portal";

import { OngoingTouch, UseTouchStyle } from "../../_hooks/usePullRefresh";
import { Icon } from "../Icon";

type Props = {
  primaryTouch: OngoingTouch;
  useTouchStyle: UseTouchStyle;
  VERT_MIN_FOR_DISPLAY: number;
};

const REFRESH_ICON_SIZE = 28;

export function PullToRefreshUI({
  primaryTouch,
  useTouchStyle,
  VERT_MIN_FOR_DISPLAY,
}: Props) {
  return (
    <Portal>
      <PullRefreshUIAnim
        style={{
          ...// primaryTouch.touchState === "refreshInit" &&
          ({
            "--icon-size": REFRESH_ICON_SIZE + "px",
            "--icon-top":
              primaryTouch.start.pageY - REFRESH_ICON_SIZE / 2 + "px",
            "--icon-left":
              primaryTouch.start.pageX - REFRESH_ICON_SIZE / 2 + "px",
            /*  "--stretch-bottom":
                    dimensions.height -
                    (primaryTouch.start.pageY + VERT_MIN_FOR_DISPLAY) +
                    "px", */
            "--stretch-height": VERT_MIN_FOR_DISPLAY + "px",
          } as React.CSSProperties),
          opacity: useTouchStyle.opacity.to((opacity) => {
            return opacity;
          }),
          display: useTouchStyle.opacity.to((opacity) => {
            if (opacity > 0) {
              return "block";
            } else {
              return "none";
            }
          }),
        }}
      >
        <RefreshIconAnim
          style={{
            transform: useTouchStyle.transform.to((scaleY, scaleX, rot) => {
              return `scale(${1 + scaleY / 12}) rotate(${rot}deg)`;
            }),
          }}
        >
          <Icon id="refresh-cw" size={REFRESH_ICON_SIZE} />
        </RefreshIconAnim>
        <RefreshStretchAnim
          style={{
            transform: useTouchStyle.transform.to((scaleY, scaleX, rot) => {
              return `scaleY(${scaleY}) scaleX(${scaleX}`;
            }),
          }}
        />
      </PullRefreshUIAnim>
    </Portal>
  );
}

const PullRefreshUI = styled.div`
  transition: none;
`;
const PullRefreshUIAnim = animated(PullRefreshUI);

const RefreshIcon = styled.div`
  position: fixed;
  top: var(--icon-top);
  left: var(--icon-left);
  height: var(--icon-size);
  width: var(--icon-size);
  color: var(--color-text);
  transition: none;
  border-radius: 50%;
  background-color: var(--color-background-translucent-75);
  box-shadow: 0px 0px 8px 4px var(--color-background-translucent-75);
`;
const RefreshIconAnim = animated(RefreshIcon);

const RefreshStretch = styled.div`
  position: fixed;
  background-color: var(--color-primary-translucent-20);
  transform-origin: center top;
  transition: none;
  top: calc(var(--icon-top) + var(--icon-size) + 8px);
  left: var(--icon-left);
  width: var(--icon-size);
  height: calc(var(--stretch-height) - var(--icon-size));
  border-radius: 50% / 10%;
`;

const RefreshStretchAnim = animated(RefreshStretch);
