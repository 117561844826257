/**
 * @generated SignedSource<<a69f74032e93365ddc548ce6b3ffbc75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UpdateSectionTimeSig_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly timeSig: TimeSig;
  readonly " $fragmentType": "UpdateSectionTimeSig_song";
};
export type UpdateSectionTimeSig_song$key = {
  readonly " $data"?: UpdateSectionTimeSig_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionTimeSig_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSectionTimeSig_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSig",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "558425e113126c412e5903ba86711fe8";

export default node;
