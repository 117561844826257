import React from "react";
import styled from "styled-components/macro";

type Props = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  canvasParentRef: React.RefObject<HTMLDivElement>;
  canvasSizingContainerRef: React.RefObject<HTMLDivElement>;
};

export function ResizableCanvas(props: Props) {
  return (
    <Wrapper ref={props.canvasSizingContainerRef}>
      <Blur />
      <CanvasWrapper ref={props.canvasParentRef}>
        <Canvas ref={props.canvasRef} />
      </CanvasWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* ensure component is behind main content (e.g. Page) */
  z-index: -1;
`;

const Blur = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  /* ensure Blur is in front of CanvasWrapper */
  z-index: 1;

  /* if backdrop-filter not supported: mostly opaque */
  background-color: var(--color-background-translucent-96);
  /* if backdrop-filter supported: more transparent and blurred */
  @supports (
    (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px))
  ) {
    background-color: var(--color-background-translucent-75);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
`;

const CanvasWrapper = styled.div`
  /* https://www.html5rocks.com/en/tutorials/casestudies/gopherwoord-studios-resizing-html5-games */
  cursor: auto;
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;
