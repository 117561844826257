import { useCallback, useRef, useState } from "react";
import { useSpring, config } from '@react-spring/web';

const useLongPress = (
    onLongPress,
    onClick,
    { shouldPreventDefault = true, delay = 500 } = {}
) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const [longPressStarted, setLongPressStarted] = useState(false);
    const timeout = useRef();
    const timeoutStarted = useRef();
    const target = useRef();
    const longPressStartDelay = 100;


    const start = useCallback(
        event => {
            if (shouldPreventDefault && event.target) {
                event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            }
            timeoutStarted.current = setTimeout(() => {
                setLongPressStarted(true);
            }, longPressStartDelay);
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            timeoutStarted.current && clearTimeout(timeoutStarted.current);

            shouldTriggerClick && !longPressTriggered && onClick();
            setLongPressTriggered(false);
            setLongPressStarted(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, onClick, longPressTriggered]
    );

    // https://aleclarson.github.io/react-spring/v9/breaking-changes/#The-to-prop
    const style = useSpring({
        from: { transform: "rotate(0deg)" },
        to: [
            { transform: longPressStarted ? "rotate(-10deg)" : "rotate(0deg)" },
            { transform: longPressStarted ? "rotate(180deg)" : "rotate(0deg)" },
        ],
        config: {
            ...config.stiff,
            // duration: 200,
        }
    });

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => clear(e),
        onMouseLeave: e => clear(e, false),
        // simple solution here: any touchmove cancels longpress; more complicated (and better user experience): onTouchMove, check element under touch and cancel only if not same element when onTouchStart; see useGKeyboardTouchHelpers for example of how to implement
        onTouchMove: e => clear(e, false),
        onTouchEnd: e => clear(e),
        style
    };
};

const isTouchEvent = event => {
    return "touches" in event;
};

const preventDefault = event => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};

export default useLongPress;
