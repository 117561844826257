import React from "react";

// WARNING if want to use to determine screen dimensions: initially used similar code with usePullRefresh to provide window.innerHeight; thought maintaining innerHeight in state (resulting from a "resize" event) would be way to go to ensure 1) minimize need to access window property, getting dimensions only when a "resize" is detected, and 2) ensuring a state update where needed; however, as tested in iOS, when your resize callback fires due, it receives the "intermediate" dimensions of the screen; e.g. when rotating your iPhone, it animates a transition between orientations; necessarily, it restricts *both* height/width to the smaller dimension of the two; thus instead of 812x375, or 375x812, you get 375x375 when changing orientation in either direction; I suspect a similar intermediate value is retrieved when onscreen keyboard is opened/dismissed; if *needed* to rely on a "resize" event, perhaps could use a setTimeout(); (useLayoutEffect did not help vs. useEffect); 

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { windowDimensions };
}
