import React from "react";
import styled from "styled-components/macro";

import Button from "../../_components/Button";
import { GKeyboard } from "../../_components/GKeyboard";
import { Icon } from "../../_components/Icon";
import { Modal } from "../../_components/Modal";
import Spacer from "../../_components/Spacer";
import {
  chordDefToNotes,
  chordDefToNotesSafe,
} from "../../_music/chordDefToNotes";
import { ChordOrCursor } from "../../_types";
import { CURSOR_EMPTY_VAL, CURSOR_ID } from "../Cursor";

type Props = {
  selectedId: number;
  selectedChord: ChordOrCursor;
  handleEditChord: ({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) => void;
  handleCreateChord: () => void;
  handleDeleteChord: () => void;
  moveToTab: () => void;
};

export function AddDelete(props: Props) {
  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);
  const { root, qual, bass } = props.selectedChord.definition;
  var { chordNotes, chordNotesSafe, includesMultiAcc, safeRootAlts } =
    chordDefToNotesSafe({
      root,
      qual,
    });
  // var chordNotesSafeExclBass = chordNotesSafe.filter((note) => note !== bass);
  // suggested bass notes come from chord tones; if bass was set manually - and isn't chord tone - add to display;
  if (!chordNotesSafe.includes(bass) && bass !== CURSOR_EMPTY_VAL) {
    chordNotesSafe.push(bass);
  }

  const [modalData, setModalData] = React.useState<{
    currentRoot: string;
    suggestedSafeRoot: string;
  }>({ currentRoot: root, suggestedSafeRoot: safeRootAlts[0] });

  function open() {
    setModalData({ currentRoot: root, suggestedSafeRoot: safeRootAlts[0] });
    setDoShowDialog(true);
  }
  function close() {
    setDoShowDialog(false);
  }

  function handleMoreOptions() {
    props.moveToTab();
  }

  function handleManualBassChange(value: string) {
    props.handleEditChord({
      name: "bass",
      value: value,
    });
    // props.handleCreateChord();
  }

  function handleRootChange(value: string) {
    props.handleEditChord({
      name: "root",
      value: value,
    });
  }

  return (
    <>
      <Wrapper>
        {props.selectedChord.id === CURSOR_ID ? (
          <Button
            onClick={
              // *** explicitly calling the fnc (vs simply passing the fnc var to onClick) b/c an apparent blindspot of TS: although the params of handleCreateChord are typed, TS doesn't spot that - if merely hand the fnc var to onClick - onClick will pass a React "SyntheticBaseEvent" as a param; thus, no type error is thrown, and handleCreateChord receives an invalid param; in your case, the presence of a param changes the behavior of handleCreateChord and results in an undesired computation
              () => props.handleCreateChord()
            }
            iconId="plus-square"
          >
            Add Chord
          </Button>
        ) : (
          <Button
            onClick={props.handleDeleteChord}
            iconId="delete"
            palette="danger"
          >
            Delete Chord
          </Button>
        )}
        {includesMultiAcc && safeRootAlts.length > 0 && (
          <>
            <Button
              onClick={open}
              iconId="alert-triangle"
              fill="clear"
              palette="danger"
              size="sm"
            >
              Consider changing root
            </Button>
          </>
        )}
        {props.selectedChord.id === CURSOR_ID && (
          <>
            {chordNotesSafe.length > 0 && (
              <QuickBassChangeWrapper>
                <BassLabel>Bass note</BassLabel>
                <GKeyboard
                  groupId={`ChordKeeper_Checkbox_addDelete_bass`}
                  items={chordNotesSafe.map((val) => {
                    return {
                      label: val.toString(),
                      value: val.toString(),
                    };
                  })}
                  columns={6}
                  checkedItems={[bass]}
                  manualChange={handleManualBassChange}
                  altKeyColor
                />
              </QuickBassChangeWrapper>
            )}
            {chordNotesSafe.length <= 6 && (
              <Button
                onClick={handleMoreOptions}
                iconId="corner-up-left"
                fill="clear"
                size="sm"
              >
                {chordNotesSafe.length > 0
                  ? "More Bass Notes"
                  : "Change Bass Note"}
              </Button>
            )}
          </>
        )}
      </Wrapper>

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={close}
        doUseLeastDestructiveRef={true}
        label={"Suggestion: Change root"}
        handleConfirm={() => {
          handleRootChange(modalData.suggestedSafeRoot);
          close();
        }}
        actionText={`Change to ${modalData.suggestedSafeRoot}`}
        showCloseButton
        cancelText={`Keep ${modalData.currentRoot}`}
        message={
          <>
            Some notes in this chord require double accidentals (♭♭ or ♯♯),
            which can be awkward to work with.
            <br />
            <br />
            Would you like to change to a friendlier root note?
          </>
        }
        showFullHeader
      >
        <>
          {/* <Button
            onClick={() => handleRootChange(safeRootAlts[0])}
            iconId="check"
            size="sm"
            palette="secondary"
            // fill="clear"
            expand="inline"
          >
            Change to {safeRootAlts[0]}
          </Button> */}
          {/* 
          <GKeyboard
            groupId={`ChordKeeper_Checkbox_addDelete_root`}
            items={safeRootAlts.map((val) => {
              return {
                label: val.toString(),
                value: val.toString(),
              };
            })}
            columns={6}
            checkedItems={[root]}
            manualChange={handleRootChange}
          /> */}
        </>
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  padding: 4px 12px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const ButtonContents = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
`;

const WarningAccidentals = styled.div`
  padding: 8px 18px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-danger);
`;

const QuickBassChangeWrapper = styled.div``;

const BassLabel = styled.label`
  /* padding: 0px 4px; */
  font-size: ${14 / 16}rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  /* font-variant-caps: small-caps; */

  color: var(--color-text-medium);
`;
