import React, { useRef } from "react";
import styled from "styled-components/macro";

import { ChordCursorContext } from "../../_types";
import { CURSOR_ID } from "../Cursor";
import ChordItem from "./ChordItem";
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from "../../_plugins/Haptics";
import { useInputMode } from "../../_context/chordContext";

interface mapRefs {
  [index: string]: React.RefObject<HTMLDivElement>;
}

//*** Upon reconsideration, this is likely a bad idea, and bug prone: don't want to create a global variable here, this should be its own ReactRef (containing other refs) living within the function component */
export var itemRefs: mapRefs = {};

interface Props {
  chordIndex: number;
  chordCursorObj: ChordCursorContext;
  selectedId: number;
  handleSetSelectedChord: (id: number) => void;
  handleCreateChord: () => void;
  dragOverlay?: boolean;
  handleTouchStart?: () => void;
  tappedChordIdSet?: Set<string>;
}

export function ChordGridItem({
  dragOverlay,
  chordIndex,
  chordCursorObj,
  selectedId,
  handleSetSelectedChord,
  handleCreateChord,
  handleTouchStart,
  tappedChordIdSet,
}: Props) {
  var thisRef = useRef<HTMLDivElement>(null);
  itemRefs[chordIndex] = thisRef;

  const { inputMode, setInputMode } = useInputMode();

  return (
    <ChordItemWrapper
      ref={thisRef}
      dragOverlay={dragOverlay}
      style={
        inputMode === "manual"
          ? ({
              "--background-color":
                chordIndex === selectedId
                  ? "var(--color-highlight-light)"
                  : "revert",
            } as React.CSSProperties)
          : ({
              "--background-color": tappedChordIdSet?.has(chordCursorObj.id)
                ? "var(--color-primary-translucent-10)"
                : "revert",
            } as React.CSSProperties)
      }
      onTouchStart={handleTouchStart}
      onClick={() => {
        if (inputMode !== "manual") {
          // for "tap" mode, don't want to create chords, change selected chord, or fire haptics for a "click" event
          return;
        }
        if (chordCursorObj.id === CURSOR_ID && chordIndex === selectedId) {
          hapticsImpactMedium();
          handleCreateChord();
        } else if (chordIndex !== selectedId) {
          hapticsImpactLight();
          handleSetSelectedChord(chordIndex);
        } else {
          // non-cursor chord that is already selected; do nothing
          //
        }
      }}
    >
      <ChordItem
        chordCursorObj={chordCursorObj}
        chordIndex={chordIndex}
        // for editing, always show all rhythm info
        rhythmDisplay="all"
        context="edit"
        selectedId={selectedId}
        dragOverlay={dragOverlay}
      />
    </ChordItemWrapper>
  );
}

const ChordItemWrapper = styled.div<{ dragOverlay?: boolean }>`
  --box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1))
    rgba(63, 63, 68, 0.05);
  --box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0
    rgba(34, 33, 81, 0.15);
  --box-shadow: var(--box-shadow-border), var(--box-shadow-common);
  --box-shadow-picked-up: var(--box-shadow-border),
    -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
  --focused-outline-color: #4c9ffe;

  height: 100%;

  cursor: grabbing;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  background-color: var(--background-color);

  ${({ dragOverlay }) =>
    dragOverlay &&
    `
    @keyframes pop {
      0% {
        box-shadow: var(--box-shadow);
        transform: scale(1);
      }
      100% {
        box-shadow: var(--box-shadow-picked-up);
        transform: scale(1.05);
      }
    }    
  cursor: grabbing;
  animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  box-shadow: var(--box-shadow-picked-up);
  transform: scale(1.05);
  transform-origin: top left;
  background-color: var(--color-background);
  `}
`;
