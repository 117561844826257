import React from "react";
import styled from "styled-components/macro";

import { createRange } from "../../_utilities";
import { GKeyboard } from "../../_components/GKeyboard";
// import { SVGComponent } from "../../_svg/SVGComponent";
import RepeatStart from "../../_svg/repeatStart.svg";
import RepeatEnd from "../../_svg/repeatEnd.svg";
import {
  ALTERNATE_ENDING_FLAG,
  REPEAT_START,
  REPEAT_END,
  WORD_JOINER,
  REPEAT_TIMES_FLAG,
} from "../../_constants";

const REPEAT_ENDINGS_OPTIONS = 6;
const REPEAT_TIMES_OPTIONS = 4;

/* consider adding "clear button" that removes all repetitions selections */

type Props = {
  notation: readonly string[] | string[];
  handleEditChord: ({ name, value }: { name: string; value: string[] }) => void;
};

export function RepetitionInputs({ notation, handleEditChord }: Props) {
  function handleManualChange(value: string) {
    var repAltClone = notation.slice(0);
    var valueIndex = repAltClone.indexOf(value);
    if (valueIndex === -1) {
      repAltClone.push(value);
    } else {
      repAltClone.splice(valueIndex, 1);
    }
    // sort, to ensure 1) reps display in correct order; 2) can easily stringify and compare for grouping
    // repAltClone.sort((a, b) => parseInt(a) - parseInt(b));
    handleEditChord({
      name: "notation",
      value: repAltClone,
    });
  }

  const repSymbols = [
    {
      label: (
        <SVGButtonWrapper>
          {/* <SVGComponent svgId={"repeatStart"} /> */}
          <SVGImg src={RepeatStart} />
        </SVGButtonWrapper>
      ),
      value: REPEAT_START,
    },
    {
      label: (
        <SVGButtonWrapper>
          {/* <SVGComponent svgId={"repeatEnd"} /> */}
          <SVGImg src={RepeatEnd} />
        </SVGButtonWrapper>
      ),
      value: REPEAT_END,
    },
  ];

  const altEndSymbols = createRange(
    REPEAT_ENDINGS_OPTIONS,
    (index) => index + 1
  ).map((val) => {
    return {
      // user WORD_JOINER to prevent auto line-breaking around hyphen
      label: `end${WORD_JOINER}-${WORD_JOINER}` + val.toString(),
      value: ALTERNATE_ENDING_FLAG + val.toString(),
    };
  });

  /*   const repeatTimesSymbols = [2,3,4,8].map((val) => {
    return {
      // user WORD_JOINER to prevent auto line-breaking
      label: `${val.toString()}${WORD_JOINER}x`,
      value: REPEAT_TIMES_FLAG + val.toString(),
    };
  }); */

  return (
    <Wrapper>
      <GKeyboard
        groupId={`ChordKeeper_Checkbox_${"reps"}`}
        items={[...repSymbols, ...altEndSymbols /* ...repeatTimesSymbols */]}
        checkedItems={notation}
        manualChange={handleManualChange}
        columns={4}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 4px;
`;

const SVGButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 2px;
  /* https://stackoverflow.com/a/53336754 */
  filter: var(--svg-color-filter);
`;

const SVGImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
