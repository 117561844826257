import React, { useEffect } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { SectionQueryQuery } from "../__generated__/SectionQueryQuery.graphql";

import SectionQueryChords from "./SectionQueryChords";
import type { SectionNavDetails } from "./Section";
import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { QueryOptions } from "../_types/relayTypes";
import { ChordProvider } from "../_context/chordContext";

//  *** cannot get this to work as I'd like (namely, if arrive on Section page, after starting on home page (list of user songs), all data here should have been lodaed by other queries along the way; don't understand why Relay tells this page to suspend; could try: 1) query at the song level here, as you do in MoveSection; 2) could consider "master" queries at the App level... but only way to consume that data across pages would be to treat as "normal" object data and pass e.g. via context/reducer?  Or could I pass the data down like that but then use it in fragments as you when the fragment component is a child of the query component?  Wouldn't want to lose suspense and other benefits of Relay, whatever I do.

// ***  see if - by querying all sections as part of song (then grabbing just the one section you need) - you can get section pages to load w/o suspending; throughout the app, you may be better served by that sort of "grab entire song, then parse it on client", esp. given that sections are now part of song docs; and/or you could be better off including sections as a simple list/array instead of a connection, and on mutations replace the entire instance of song or song.sections in the Relay store, instead of attempting an atomic Relay update (e.g. @deleteNode); Relay docs note that connections are stored separately, which could explain some difficulty you've had updating existing items or with using existing data instead of relay suspending to load data (https://relay.dev/docs/guided-tour/list-data/updating-connections/#connection-records)

type Props = {
  songId: string;
  sectionId: string;
  setSectionNavDetails: React.Dispatch<React.SetStateAction<SectionNavDetails>>;
  queryOptions: QueryOptions;
};

/* 
sectionEdge {
  node {
    id
    name
    # notes and key queried to ensure SectionMeta already has data it needs (thus no loading spinner)
    notes
    chords {
      id
      duration
      definition {
        root
        qual
        bass
      }
      notation
    }
    ...SectionQueryChords_section
  }
} */

export function SectionQuery(props: Props) {
  const data = useLazyLoadQuery<SectionQueryQuery>(
    graphql`
      query SectionQueryQuery($songId: ID!, $sectionId: ID!) {
        songSection(songId: $songId, sectionId: $sectionId) {
          song {
            id
            title
            mongoVersion
            timeSig
            ...SectionQueryChords_song
          }
          section {
            id
            name
            timeSig
            # notes and key queried to ensure SectionMeta already has data it needs (thus no loading spinner)
            notes
            chords {
              id
              duration
              definition {
                root
                qual
                bass
              }
              notation
            }
            ...SectionQueryChords_section
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { songId: props.songId, sectionId: props.sectionId },
    props.queryOptions
  );

  const dataWithErrors = data.songSection;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { song, section, /* sectionEdge, */ errors } = dataWithErrors;

  // const section = sectionEdge.node;

  useEffect(() => {
    if (song && section) {
      props.setSectionNavDetails({
        // songId: section.song.id,
        name: section.name,
        songTitle: song.title,
      });
    }
  }, [song, section]);

  return (
    <>
      {/* data result */}
      {song && section && (
        //  {/* use context/reducer for section chord values at this level (instead of top, "App" level) b/c don't need this data elsewhere, and b/c want it to be reset to default when new instance is created, to avoid e.g. the value of the last selected chord id from hanging around and potentially being used by a fresh load of this page */}
        <ChordProvider>
          <SectionQueryChords
            songRef={song}
            sectionRef={section}
            // sectionId={props.sectionId}
          />
        </ChordProvider>
      )}

      {/* null result */}
      {(!song || !section) && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
