import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Alert from "@reach/alert";
import Portal from "@reach/portal";

import type { CustomErrors } from "../_types/errorTypes";

type Props = {
  readonly errors: CustomErrors;
};

export function FriendlyErrors({ errors }: Props) {
  const [alerts, setAlerts] = useState<CustomErrors>([]);

  useEffect(() => {
    setAlerts(errors);
  }, [errors]);

  function dismissError(index: number) {
    setAlerts((state) => {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    });
  }

  // auto dismiss?
  //

  return (
    <Portal>
      <Wrapper>
        {alerts.map((alert, index) => (
          <AlertWrapper key={index} onClick={() => dismissError(index)}>
            <ErrorAlert type="polite">
              {alert.__typename}-{alert.message}
            </ErrorAlert>
          </AlertWrapper>
        ))}
      </Wrapper>
    </Portal>
  );
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* isolation: isolate; */
`;

const AlertWrapper = styled.div`
  background-color: var(--color-danger);
`;

const ErrorAlert = styled(Alert)`
  padding: 8px;
`;
