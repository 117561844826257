import { KeepAwake } from "@capacitor-community/keep-awake";
// import { Capacitor } from "@capacitor/core";

// returns true, even when running in browser... same for Haptics... don't undestand the point of this check... instead I run a check for "isNativeCap" in App
// const isAvailable = Capacitor.isPluginAvailable("KeepAwake");

export async function keepAwake() {
  try {
    await KeepAwake.keepAwake();
  } catch (err) {
    console.error(err);
  }
}

export async function allowSleep() {
  try {
    await KeepAwake.allowSleep();
  } catch (err) {
    console.error(err);
  }
}
