/**
 * @generated SignedSource<<fefcaaa812c0b3825072fa2f28897734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
export type updateSectionTimeSigInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  sectionId: string;
  songId: string;
  timeSig: TimeSig;
};
export type UpdateSectionTimeSigMutation$variables = {
  input: updateSectionTimeSigInput;
};
export type UpdateSectionTimeSigMutation$data = {
  readonly updateSectionTimeSig: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly section: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionTimeSig_section">;
    } | null;
    readonly song: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionTimeSig_song">;
    } | null;
  } | null;
};
export type UpdateSectionTimeSigMutation = {
  response: UpdateSectionTimeSigMutation$data;
  variables: UpdateSectionTimeSigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSig",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSectionTimeSigMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionTimeSigPayload",
        "kind": "LinkedField",
        "name": "updateSectionTimeSig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionTimeSig_section"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionTimeSig_song"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSectionTimeSigMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionTimeSigPayload",
        "kind": "LinkedField",
        "name": "updateSectionTimeSig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19c08cb53303c607b4658c8d9ac5a484",
    "id": null,
    "metadata": {},
    "name": "UpdateSectionTimeSigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSectionTimeSigMutation(\n  $input: updateSectionTimeSigInput!\n) {\n  updateSectionTimeSig(input: $input) {\n    section {\n      ...UpdateSectionTimeSig_section\n      id\n    }\n    song {\n      ...UpdateSectionTimeSig_song\n      id\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment UpdateSectionTimeSig_section on SongSection {\n  id\n  timeSig\n}\n\nfragment UpdateSectionTimeSig_song on Song {\n  id\n  mongoVersion\n  timeSig\n}\n"
  }
};
})();

(node as any).hash = "89affdcb51e7a142e2dc638525f780ff";

export default node;
