// @@@ disable in-app purchases @@@
/* 
import {
  Dom7ProductGroupName,
  DOM7_PRODUCT_GROUPS,
  IAPProduct,
} from "../_plugins/InAppPurchases";
import {
  UserProductGroup,
  UserProductId,
} from "../__generated__/AddPurchaseMutation.graphql";
 */

import { ColorThemeOption } from "../_constants";

import type { ActionMap } from "./types";

export type ModeOption = "play" | "learn";
export type SortOrder = "alpha" | "modified";
export type RhythmDisplay = "duration" | "count" | "all" | "none";

/* export type ProductVerifiedData = {
  isInFlight: boolean;
  didVerify: boolean;
  // populated from server; GraphQL doesn't care for undefineds, so allow null instead of allowing undefined; some discussion: https://github.com/graphql/graphql-js/issues/1298
  productGroup: string | null;
  expiresAt: string | null;
  startsAt: string | null;
  renewalIsActive: boolean | null;
  currentSubProductId: string | null;
  renewalSubProductId: string | null;
}; */

// @@@ disable in-app purchases @@@
/* 
export type SetProductGroupVerifiedPayload = {
  productGroup: UserProductGroup;
  currentSub: {
    id: UserProductId;
    startsAt: string;
    expiresAt: string;
  };
  renewalSub: {
    id: UserProductId;
    // startsAt: string;
    willRenew: boolean;
  };
};
export type ProductVerifiedData = {
  // productId: string;
  // productGroup: string | null;
  isSubscription: boolean;
  isCurrentSub: boolean | null;
  isRenewalSub: boolean | null;
  startsAt: string | null;
  expiresAt: string | null;
  willRenew: boolean | null;
};
*/

export type AppType = {
  colorTheme: ColorThemeOption;
  doShowTranspose: boolean;
  rhythmDisplay: RhythmDisplay;
  mode: ModeOption;
  searchFilter: string;
  tagFilter: string[];
  sortOrder: SortOrder;
  scrollPosMap: Map<string, number>;
  isInDebugMode: boolean;
  debugLog: { time: string; content: any[]; source?: string }[];

  // *** tighter typing: instead of string for keys of following maps, should use defined productId / productGroup
  // @@@ disable in-app purchases @@@
  /*   productMap: Map<string, IAPProduct>;
  productMapVerified: Map<string, ProductVerifiedData>;
  productGroupMapVerified: Map<string, SetProductGroupVerifiedPayload>; */
};

export const appReducerInit: AppType = {
  colorTheme: "light",
  doShowTranspose: false,
  rhythmDisplay: "count",
  mode: "play",
  searchFilter: "",
  tagFilter: [],
  sortOrder: "alpha",
  scrollPosMap: new Map(),
  isInDebugMode: false,
  debugLog: [],
  // @@@ disable in-app purchases @@@
  /*   productMap: new Map(),
  productMapVerified: new Map(),
  productGroupMapVerified: new Map(), */
};

export enum AppActionTypes {
  SetFromStorage = "SET_FROM_STORAGE",
  SetColorTheme = "SET_COLOR_THEME",
  SetShowTranspose = "SET_SHOW_TRANSPOSE",
  SetRhythmDisplay = "SET_RHYTHM_DISPLAY",
  SetMode = "SET_MODE",
  SetSearchFilter = "SET_SEARCH_FILTER",
  SetTagFilter = "SET_TAG_FILTER",
  SetSortOrder = "SET_SORT_ORDER",
  ClearFilters = "CLEAR_FILTERS",
  Reset = "RESET_APP",
  SetSongListScroll = "SET_SONG_LIST_SCROLL",
  SetDebugMode = "SET_DEBUG_MODE",
  SetDebugLog = "SET_DEBUG_LOG",
  ClearDebugLog = "CLEAR_DEBUG_LOG",
  // @@@ disable in-app purchases @@@
  /*   SetProduct = "SET_PRODUCT",
  SetProductVerified = "SET_PRODUCT_VERIFIED",
  SetProductGroupVerified = "SET_PRODUCT_GROUP_VERIFIED", */
}

type AppPayload = {
  [AppActionTypes.SetFromStorage]: Partial<AppType>;
  [AppActionTypes.SetColorTheme]: ColorThemeOption;
  [AppActionTypes.SetShowTranspose]: boolean;
  [AppActionTypes.SetRhythmDisplay]: RhythmDisplay;
  [AppActionTypes.SetMode]: ModeOption;
  [AppActionTypes.SetSearchFilter]: string;
  [AppActionTypes.SetTagFilter]: string[];
  [AppActionTypes.SetSortOrder]: SortOrder;
  [AppActionTypes.ClearFilters]: null;
  [AppActionTypes.Reset]: null;
  [AppActionTypes.SetSongListScroll]: { key: string; val: number };
  [AppActionTypes.SetDebugMode]: boolean;
  [AppActionTypes.SetDebugLog]: { content: any[]; source?: string };
  [AppActionTypes.ClearDebugLog]: null;
  // @@@ disable in-app purchases @@@
  /*   [AppActionTypes.SetProduct]: { key: string; val: IAPProduct };
  [AppActionTypes.SetProductVerified]: {
    key: string;
    val: ProductVerifiedData;
  };
  [AppActionTypes.SetProductGroupVerified]: SetProductGroupVerifiedPayload; */
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

// relevant only for subscriptions
/// ** (possibly only iOS)
// @@@ disable in-app purchases @@@
/* 
function updateProductGroupVerified(
  payload: SetProductGroupVerifiedPayload,
  productMapVerifiedClone: AppType["productMapVerified"],
  productGroupMapVerifiedClone: AppType["productGroupMapVerified"]
) {
  const { productGroup, currentSub, renewalSub } = payload;
  productGroupMapVerifiedClone.set(productGroup, payload);
  if (currentSub && renewalSub) {
    const productGroupArr =
      DOM7_PRODUCT_GROUPS[productGroup as Dom7ProductGroupName];
    for (let thisProductId of productGroupArr) {
      if (thisProductId === currentSub.id || thisProductId === renewalSub.id) {
        // productGroup member is current and/or renewal product; add/update entry;
        let verifiedBody = {
          isSubscription: true,
          isCurrentSub: thisProductId === currentSub.id,
          isRenewalSub: thisProductId === renewalSub.id,
          startsAt:
            thisProductId === currentSub.id
              ? currentSub.startsAt
              : currentSub.expiresAt,
          // : renewalSub.startsAt,
          expiresAt:
            thisProductId === currentSub.id ? currentSub.expiresAt : null,
          willRenew: renewalSub.willRenew,
          // thisProductId === renewalSub.id ? renewalSub.willRenew : null,
        };
        productMapVerifiedClone.set(thisProductId, verifiedBody);
      } else {
        // productGroup member is neither current nor renewal product;
        let verifiedBody = {
          isSubscription: true,
          isCurrentSub: false,
          isRenewalSub: false,
          startsAt: null,
          expiresAt: null,
          // a bit confusing, and maybe worth changing... goal here was to give a renewal status for the entire subscription group, so ostensibly each product should reflect the same "willRenew" status
          willRenew: renewalSub.willRenew,
        };
        productMapVerifiedClone.set(thisProductId, verifiedBody);
        // productMapVerifiedClone.delete(thisProductId);
      }
    }
  }
}
 */
export function appReducer(state: AppType, action: AppActions) {
  switch (action.type) {
    case AppActionTypes.SetFromStorage:
      return {
        ...state,
        ...action.payload,
      };

    case AppActionTypes.SetColorTheme:
      return {
        ...state,
        colorTheme: action.payload,
      };

    case AppActionTypes.SetShowTranspose:
      return {
        ...state,
        doShowTranspose: action.payload,
      };

    case AppActionTypes.SetRhythmDisplay:
      return {
        ...state,
        rhythmDisplay: action.payload,
      };

    case AppActionTypes.SetMode:
      return {
        ...state,
        mode: action.payload,
      };

    case AppActionTypes.SetSearchFilter:
      return {
        ...state,
        searchFilter: action.payload,
      };

    case AppActionTypes.SetTagFilter:
      return {
        ...state,
        tagFilter: action.payload,
      };

    case AppActionTypes.SetSongListScroll:
      return {
        ...state,
        scrollPosMap: state.scrollPosMap.set(
          action.payload.key,
          action.payload.val
        ),
      };

    case AppActionTypes.SetSortOrder:
      return {
        ...state,
        sortOrder: action.payload,
      };

    case AppActionTypes.ClearFilters:
      return {
        ...state,
        searchFilter: appReducerInit.searchFilter,
        tagFilter: appReducerInit.tagFilter,
        // if filters change, scroll position may be at invalid value
        scrollPosMap: appReducerInit.scrollPosMap,
      };
    // @@@ disable in-app purchases @@@
    /* 
    case AppActionTypes.SetProduct:
      return {
        ...state,
        productMap: state.productMap.set(
          action.payload.key,
          // clone product; one benefit: allows you to implement change-tracking in your debugLogs; without cloning, IAP plugin updates to a product would be reflected in the reducer immediately (without need for a dispatch), so unable to compare two product states
          // after updating to cordova-plugin-purchase v13, stricter typing resulted in error in below use of object spread... but using Object.assign() passes muster... (coercing with "as" also works)
          // { ...action.payload.val }
          // { ...action.payload.val } as IAPProduct
          Object.assign({}, action.payload.val)
        ),
      };

    case AppActionTypes.SetProductVerified:
      return {
        ...state,
        productMapVerified: state.productMapVerified.set(action.payload.key, {
          ...action.payload.val,
        }),
      };

    case AppActionTypes.SetProductGroupVerified:
      var productGroupMapVerifiedClone = new Map(state.productGroupMapVerified);
      var productMapVerifiedClone = new Map(state.productMapVerified);
      updateProductGroupVerified(
        action.payload,
        productMapVerifiedClone,
        productGroupMapVerifiedClone
      );
      return {
        ...state,
        productMapVerified: productMapVerifiedClone,
        productGroupMapVerified: productGroupMapVerifiedClone,
      };

 */
    case AppActionTypes.SetDebugMode:
      return {
        ...state,
        isInDebugMode: action.payload,
      };

    case AppActionTypes.SetDebugLog:
      return {
        ...state,
        debugLog: [
          {
            // capture time, not date
            time: new Date().toISOString(),
            content: action.payload.content,
            ...(action.payload.source && { source: action.payload.source }),
          },
          ...state.debugLog,
        ],
      };
    case AppActionTypes.ClearDebugLog:
      return {
        ...state,
        debugLog: appReducerInit.debugLog,
      };

    case AppActionTypes.Reset:
      return {
        ...appReducerInit,
        // don't reset debugLog, ensuring you can view full lifecycle of events, including logout;
        debugLog: state.debugLog,
      };

    default:
      return state;
  }
}
