import React from "react";
import styled from "styled-components/macro";
import { addBreaksToEmail } from "../_utilities/strings";

type Props = {
  email: string;
};

export function Email(props: Props) {
  let formattedEmail = addBreaksToEmail(props.email);

  return <Wrapper>{formattedEmail}</Wrapper>;
}

const Wrapper = styled.span`
  overflow-wrap: anywhere;
  /* white-space: pre-wrap; */
`;
