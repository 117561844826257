/**
 * @generated SignedSource<<1650530aed8c78b69cb07289accadf21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionQueryChords_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly " $fragmentSpreads": FragmentRefs<"ChordsSimple_song" | "UpdateSectionChordsMutation_song">;
  readonly " $fragmentType": "SectionQueryChords_song";
};
export type SectionQueryChords_song$key = {
  readonly " $data"?: SectionQueryChords_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"SectionQueryChords_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SectionQueryChords_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateSectionChordsMutation_song"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChordsSimple_song"
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "e304bc05eef20539d78a1f73cdf538f8";

export default node;
