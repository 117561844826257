import React from "react";
import { useLocation } from "react-router";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import styled from "styled-components/macro";

import type { SongEditQueryQuery } from "../__generated__/SongEditQueryQuery.graphql";

import CreateSection from "./CreateSection";
import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { FloatingWrapper } from "../_components/FloatingWrapper";
import { QueryOptions } from "../_types/relayTypes";

import type { SongEditHeaderDetails } from "./SongEdit";

import { UpdateTagsQuery } from "./UpdateTagsQuery";
import { PageContentData } from "../_components/Page/PageContentData";
import Spacer from "../_components/Spacer";
import UpdateSongMeta from "../SongMeta/UpdateSongMeta";
import UpdateSongTags from "../SongMeta/UpdateSongTags";
import { MoveSection } from "./SectionList/MoveSection";

type Props = {
  songId: string;
  setHeaderDetails: React.Dispatch<React.SetStateAction<SongEditHeaderDetails>>;
  queryOptions: QueryOptions;
};

export function SongEditQuery(props: Props) {
  const data = useLazyLoadQuery<SongEditQueryQuery>(
    graphql`
      query SongEditQueryQuery($id: ID!) {
        song(id: $id) {
          song {
            id
            title
            artist
            # although don't use notes here, you can query for it, so there is note data in the Relay store when you visit SongMeta (thus no need for loading indicator)
            notes
            timeSig
            ...UpdateSongMeta_song
            ...UpdateSongTags_song
            ...MoveSection_song
            ...CreateSection_song
            viewerPermission {
              user
              email
              isOwner
              canEdit
              didAccept
            }
          }
          errors {
            __typename
            message
          }
        }
        viewer {
          user {
            id
            ...UpdateSongTags_tags
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { id: props.songId },
    props.queryOptions
  );

  const songDataWithErrors = data.song;
  if (!songDataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const viewerDataWithErrors = data.viewer;
  if (!viewerDataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors: viewerErrors } = viewerDataWithErrors;
  const { song, errors: songErrors } = songDataWithErrors;

  React.useEffect(() => {
    if (song) {
      // props.setTitle(song.title);
      props.setHeaderDetails({
        title: song.title,
        viewerIsOwner: song.viewerPermission.isOwner,
      });
    }
  }, [song]);

  /*   const location: any = useLocation();
  const locationShowTagFilter: boolean =
    location?.state?.showTagFilter ?? false;
  const [showTagFilter, setShowTagFilter] = React.useState(
    locationShowTagFilter
  ); */

  return (
    <>
      {/* data result */}
      {song && user && (
        <>
          {/* <UpdateSongMeta song={song} /> */}
          <UpdateSongTags song={song} user={user} />
          <Spacer axis="vert" size={16} />
          {/* <SongSections song={song} /> */}
          <MoveSection songRef={song} />
          <FloatingWrapper>
            <CreateSection songRef={song} />
          </FloatingWrapper>
          {/*   <Spacer axis="vert" size={32} />
          <Spacer axis="vert" size={12} /> */}

          {/*  <Modal
            doShowDialog={showTagFilter}
            closeDialog={() => setShowTagFilter(false)}
            handleConfirm={() => setShowTagFilter(false)}
            showCloseButton={false}
            label="Add tags"
          >
            <ModalTitleComposed>Add Tags</ModalTitleComposed>
            <Spacer size={20} axis="vert" />
            <NegativeMargins>
              <PageContentData>
                {(queryOptions) => {
                  return (
                    <UpdateTagsQuery
                      songId={props.songId}
                      queryOptions={queryOptions}
                    />
                  );
                }}
              </PageContentData>
            </NegativeMargins>
            <Spacer size={20} axis="vert" />
          </Modal> */}
        </>
      )}

      {/* null result */}
      {(!song || !user) && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {songErrors.length > 0 && <FriendlyErrors errors={songErrors} />}
      {viewerErrors.length > 0 && <FriendlyErrors errors={viewerErrors} />}
    </>
  );
}