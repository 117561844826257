// *** functions like these beg for a unified typing with GraphQL; currently root/bass notes are merely typed as "string"; one complication (see key.js in server): can't use ♭ and ♯ in enum values in GraphQL due to restrictions in its spec (can use only "normal" word chars); so would either need to use your workaround GraphQL notation (e.g. "As" for A♯ and "Ab" for A♭); or would have to accept the GraphQL type, then perform a translation to work with these types;

// prettier-ignore
const flatNotesArr = ["A", "B♭", "B", "C", "D♭", "D", "E♭", "E", "F", "G♭", "G", "A♭"] as const;
// prettier-ignore
const sharpNotesArr = ["A", "A♯", "B", "C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯"] as const;
export const combinedNotesArr = [...flatNotesArr, ...sharpNotesArr];
// https://stackoverflow.com/a/54061487
export type Note = typeof combinedNotesArr[number];
export type TransposeAccidentalType = "preserve" | "flat" | "sharp";

export function transposeChord(
  note: Note,
  transposeBy: number,
  // accidentals: TransposeAccidentalType
  flatOrSharp: "flat" | "sharp"
) {
  // const { note, transAmt, flatsOrSharps } = props;
  // if (transAmt === 0) return note;

  if (!combinedNotesArr.includes(note)) {
    console.log("Error: invalid input for 'note'.");
    return note;
  }

  // this is an adjustment for TS; originally, sought indexOf for both sharp/flat note arrays, and used whichever gave a [non -1] result; but for TS, can't try to index both with same note, b/c declared both "as const" and they don't share the same notes; instead, combine into one array, and - knowing you work in only 12 notes - modify the result to ensure arrIndex is 0-11;
  var noteIndex = combinedNotesArr.indexOf(note);
  // if accidentals === "preserve", want to use same accidental type as note (if it had any); if note found in upper half of combinedNotesArr, it must have been a sharp; otherwise it was flat *** or it had no accidental *** which you will default to flat;
  // var defaultFlatOrSharp = "flat";
  if (noteIndex > 11) {
    // defaultFlatOrSharp = "sharp";
    noteIndex -= 12;
  }

  var transposedIndex = noteIndex + transposeBy;
  if (transposedIndex > 11) transposedIndex -= 12;
  if (transposedIndex < 0) transposedIndex += 12;

  if (
    flatOrSharp === "sharp"
    // || (accidentals === "preserve" && defaultFlatOrSharp === "sharp")
  ) {
    return sharpNotesArr[transposedIndex];
  } else {
    return flatNotesArr[transposedIndex];
  }
}

type ChordProps = {
  readonly id: string;
  readonly duration: number;
  readonly rhythm: readonly number[];
  readonly definition: {
    readonly root: string;
    readonly qual: string;
    readonly bass: string;
  };
  readonly notation: readonly string[];
};

export function transposeSection(
  chords: readonly ChordProps[],
  transposeBy: number,
  accidentals: TransposeAccidentalType
): ChordProps[] {
  if (accidentals === "preserve") {
    // "preserve" is simply meant as a "do nothing" default to allow user to toggle sharp/flats without transposing key;
    // allowed only when transposeBy === 0
    if (transposeBy === 0) {
      return chords.slice(0);
    } else {
      // this is an undesired state you forbid with your UI; return
      console.log(
        "transposeSection: undesired state; accidentals === 'preserve' but transposeBy !== 0"
      );
      return chords.slice(0);
    }
  }

  var transposedChords = chords.map((val) => {
    return {
      ...val,
      definition: {
        ...val.definition,
        root: transposeChord(
          val.definition.root as Note,
          transposeBy,
          accidentals
        ),
        bass: transposeChord(
          val.definition.bass as Note,
          transposeBy,
          accidentals
        ),
      },
    };
  });
  return transposedChords;
}
