import React from "react";
import styled from "styled-components/macro";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { ellipsizeText } from "../_utilities/strings";
import { BREAKPOINTS } from "../_constants";

const FontAwesomeIconForwardRef = React.forwardRef(
  ({ icon, ...delegated }: { icon: IconDefinition }, ref) => {
    return (
      <FontAwesomeIcon
        forwardedRef={ref}
        icon={icon}
        {...delegated}
      ></FontAwesomeIcon>
    );
  }
);

const defaultThumbSize = 2.0;

function getDragThreshold() {
  var adjustment = 72;
  if (window.innerWidth < BREAKPOINTS.smTabletMin) {
    return window.innerWidth / 2 - adjustment;
  } else if (window.innerWidth >= BREAKPOINTS.laptopMin) {
    return BREAKPOINTS.lgTabletMin / 2 - adjustment;
  } else {
    return BREAKPOINTS.smTabletMin / 2 - adjustment;
  }
}

type Props = {
  content: string;
  tags: readonly string[];
  rightAction: () => void;
  leftAction: () => void;
};

export function TwoWayDrag({ content, rightAction, leftAction, tags }: Props) {
  const style = {
    // "--stageHeight": "min(95vw, 360px)",
    "--stageHeight": 200 / 16 + "rem",

    "--thumbSize": "calc(var(--stageHeight) * 1/5)",
    "--twoWayDragPadding": 20 / 16 + "rem",
    "--iconDistanceFromEdge": "calc(20px - var(--twoWayDragPadding))",
  };

  const [{ xy, iconSizeRt, iconSizeLft }, api] = useSpring(() => ({
    xy: [0, 0],
    iconSizeRt: defaultThumbSize,
    iconSizeLft: defaultThumbSize,
  }));

  const bind = useDrag(
    ({
      down,
      movement,
      movement: [mx],
      direction: [xDir],
      distance,
      cancel,
    }) => {
      if (down && distance > getDragThreshold()) {
        cancel && cancel();
        const dir = xDir < 0 ? -1 : 1;
        if (dir === 1) {
          // api.start({
          //     iconSizeRt: 500,
          //     immediate: down,
          // })
          // console.log("...right");
          rightAction();
        } else {
          // console.log("...left");
          leftAction();
        }
      }
      // if (mx > 200) {
      //     console.log("RIGHT!")
      //     cancel()
      // } else if (mx < -200) {
      //     console.log("LEFT!")
      //     cancel()
      // }
      api.start({
        xy: down ? movement : [0, 0],
        // iconSizeRt: down ? defaultThumbSize + mx / 100 : defaultThumbSize,
        iconSizeRt: down
          ? defaultThumbSize + (mx / window.innerWidth) * 3.5
          : defaultThumbSize,
        iconSizeLft: down
          ? defaultThumbSize + (-mx / window.innerWidth) * 3.5
          : defaultThumbSize,
        immediate: down,
        config: {
          mass: 5,
          tension: 100,
          friction: 13,
        },
      });
    },
    {
      bounds: { /* left: -500, right: 500, */ top: -20, bottom: 20 },
      rubberband: true,
    }
  );

  return (
    <Wrapper style={style as React.CSSProperties}>
      <DraggableAnim
        {...bind()}
        style={{
          transform: xy.to((x, y) => `translate3d(${x}px, ${y}px, 0)`),
        }}
      >
        <PassiveKeyframeAnim>
          <Card>
            {tags.length > 0 && (
              <Tags>{ellipsizeText(tags.join(", "), 28)}</Tags>
            )}
            <Content>
              {/* {ellipsizeText(content, 32)} */}
              {content}
            </Content>
          </Card>
        </PassiveKeyframeAnim>
      </DraggableAnim>
      <IconWrapperRemember>
        <FontAwesomeStyledAnim
          icon={faCheck}
          style={{
            fontSize: iconSizeRt.to((iconSizeRt) => `${iconSizeRt}em`),
          }}
        />
      </IconWrapperRemember>
      <IconWrapperForget>
        <FontAwesomeStyledAnim
          icon={faTimes}
          style={{
            fontSize: iconSizeLft.to((iconSizeLft) => `${iconSizeLft}em`),
          }}
        />
      </IconWrapperForget>
      <IconShadowWrapperRemember>
        <ShadowIcon icon={faCheck} />
      </IconShadowWrapperRemember>
      <IconShadowWrapperForget>
        <ShadowIcon icon={faTimes} />
      </IconShadowWrapperForget>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  /* add height to stage so that card and thumbs don't or only partially overlap */
  height: calc(var(--stageHeight) + (var(--thumbSize) / 2));
  /* background:
      linear-gradient(rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.0) 70%, rgba(255, 255, 255, 1.0)),
      linear-gradient(to right, white, red 5%, white 45%, white 55%, green 95%, white); */
  user-select: none;
  /* if you use "visible" for either overflow-x or overflow-y and something other than visible for the other, the visible value is interpreted as auto: https://stackoverflow.com/a/6433475 */
  /* overflow-y: visible;
overflow-x: hidden; */
  /* good practice to add isolation: isolate to parent element of components that use z-index, to create a stacking context and avoid those elements using z-index from layering with elements of other components that use z-index  */
  isolation: isolate;
`;

const IconWrapper = styled.div`
  position: absolute;
  /* top: calc(50% - var(--thumbSize)/2); */
  top: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.48));
  z-index: 2;
  padding: var(--twoWayDragPadding);
`;

const IconWrapperRemember = styled(IconWrapper)`
  right: var(--iconDistanceFromEdge);
  color: var(--color-remembered);
`;
const IconWrapperForget = styled(IconWrapper)`
  left: var(--iconDistanceFromEdge);
  color: var(--color-forgot);
`;

const FontAwesomeStyled = styled(FontAwesomeIconForwardRef)`
  font-size: var(--thumbSize);
  /* Cures problem of - at end of page transition - FabButton appearing behind parts of page content; during transform animation, 2-D z-index may not be respected, so need to apply 3-D translation to Z axis */
  /* *** relic of Leitner app, prob want to discard */
  transform: translateZ(1000px);
`;

const FontAwesomeStyledAnim = animated(FontAwesomeStyled);

const IconShadowWrapper = styled(IconWrapper)`
  z-index: 0;
  filter: drop-shadow(0px 0px 2px var(--color-text-light));
`;

const IconShadowWrapperRemember = styled(IconShadowWrapper)`
  right: var(--iconDistanceFromEdge);
`;
const IconShadowWrapperForget = styled(IconShadowWrapper)`
  left: var(--iconDistanceFromEdge);
`;

const ShadowIcon = styled(FontAwesomeIconForwardRef)`
  /* font-size: var(--thumbSize); */
  color: var(--color-background);
  /* filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3)); */
  font-size: 3.2rem;
`;

const Draggable = styled.div`
  /* padding needed for Safari to (I believe) prevent TwoWayDrag__card's box-shadow from spilling out due to transform:scale (on hover/active), causing a dark line on the right side */
  padding: 0.2rem;
  position: absolute;
  --height: calc(var(--stageHeight) * 1);
  --width: calc(var(--height) * 7 / 5);
  width: var(--width);
  height: var(--height);
  left: calc(50% - (var(--width) / 2));

  /* top: calc(50% - (var(--height) / 2)); */
  /* top: 0; */
  bottom: 0;

  /* prevents user touches from scrolling the page */
  /* https://github.com/react-spring/react-use-gesture/issues/101 */
  touch-action: none;
`;

const DraggableAnim = animated(Draggable);

const PassiveKeyframeAnim = styled.div`
  animation: card-anim 12s ease infinite;

  /* animation: card-anim 8.0s ease-out infinite; */
  /* transform: translate3d(0, 0, 0); */
  /* animation: card-anim 6.0s linear infinite; */
  height: 100%;
  width: 100%;

  @keyframes card-anim {
    0% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }

    15% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }

    25% {
      /* transform: translateX(5%) translateY(-5%) skewX(-5deg) skewY(5deg); */
      transform: translate3d(5%, -2%, 0) rotate3d(0, 0, 1, 2deg);
      /* transform: translate3d(25px, -1%, 0); */
    }

    35% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }

    50% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }

    65% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }

    75% {
      /* transform: translateX(-5%) translateY(-5%) skewX(5deg) skewY(-5deg); */
      transform: translate3d(-5%, -2%, 0) rotate3d(0, 0, 1, -2deg);
      /* transform: translate3d(-25px, -1%, 0); */
    }

    85% {
      transform: none;
      /* transform: translate3d(0, 0, 0); */
    }
  }

  /* @keyframes card-grow {
  from {
      transform: scale(1.0);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 16px 0px;
  }

  to {
      transform: scale(1.15);
      box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 32px 0px;
  }
} */
`;

const Card = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5%;
  cursor: grab;
  /* overflow: hidden; */
  /* overflow: auto; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 16px 0px; */
  /* box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3); */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px 0px; */
  box-shadow: var(--shadow-elevation-low);
  transform: scale(1);
  transition: all 600ms ease-in;
  background-image: repeating-linear-gradient(
      45deg,
      var(--color-primary-translucent-10),
      var(--color-primary-translucent-10) 1px,
      transparent 2px,
      transparent 4px
    ),
    repeating-linear-gradient(
      -45deg,
      var(--color-mono-translucent-15),
      var(--color-mono-translucent-15) 1px,
      transparent 2px,
      transparent 4px
    ),
    linear-gradient(
      190deg,
      var(--color-background) 0%,
      var(--color-background) 10%,
      var(--color-background-dark) 100%
    );
  padding: 1.5rem 0.5rem 1.5rem 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  /* align-items: stretch; */

  &:hover,
  &:active {
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 32px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px 0px; */
    box-shadow: var(--shadow-elevation-medium);
    /* transform: scale(1.10); */
    transform: scale(1.05);
    transition: all 200ms ease-out;
    /* deactivate animation */
    /* animation: 0; */
  }

  &:active {
    cursor: grabbing;
  }
`;

const Tags = styled.div`
  position: absolute;
  bottom: ${8 / 16}rem;
  right: ${8 / 16}rem;
  /* margin: 0 auto; */
  color: var(--color-secondary);
  /* text-align: right; */
  /* font-size: 0.6em; */
  font-size: ${16 / 16}rem;
  font-weight: 400;
`;

const Content = styled.div`
  margin: 0 auto;
  /* height: 100%; */
  /* width: 100%; */
  /* margin: 1.5em; */
  /* padding: 1.5em; */
  /* font-size: calc(var(--stageHeight) * 0.08); */
  font-size: ${20 / 16}rem;
  overflow-wrap: anywhere;
  /* *** hyphens property... adds hyphens to words that are broken; however, it also allows breaking of words that fit on a single line and thus normally would be forced to a new line by word-break / overflow-wrap props; hyphens: auto only works if the lang attribute is set on the <html> tag (and it mainly only works in English; Chrome will add hyphens to a long URL on MacOS, but not on Windows. Firefox doesn't support hyphenated capitalized words.  
   note: appears word-break:break-all; does not work with hyphens:auto; */
  hyphens: auto;

  /* text-align: center; */
  overflow: auto;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* Required for text-overflow to do anything */
  /* white-space: nowrap; */
  /* 
For fixed multiline with ellipsis, could use line-clamp, but it's not a standard and didn't seem to work as advertised in this sitation
https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
 */

  /* prevents user touches from scrolling the page */
  /* https://github.com/react-spring/react-use-gesture/issues/101 */
  touch-action: none;
`;
