// *** this file probably should be merged into chordsReducer.helpers.ts; or, if you want to keep the "rhythm" functionality separated, rename b/c current name is a relic of the "useRhythm" hook, and thus is confusing;

import { countProgressionRhythm } from "../_music/rhythmCount";
import { ChordOrCursor } from "../_types";

export const FINISH_RHYTHM_ID = "FINISH_RHYTHM_ID";
export const MIN_TAP_DISTANCE_MS = 50;
export const HALF_SCALE_AT_RATIO = 2;
export const QUARTER_SCALE_AT_RATIO = 4;
export const MAX_RATIO_AFTER_SCALING = 16;
const MIN_RATIO = 0.25;
const MAX_RATIO = 16;

export type Tap = {
  chordId: string;
  time: number;
};

type TapStat = {
  chordId: string;
  time: number;
  tapDuration: number;
  bpm: number;
  durationRatio: number;
  correctedBpm: number;
};

export type TapStatGroup = {
  chordId: string;
  durationRatio: number;
  rhythm: number[];
};

export type ScaleOption = {
  scale: number;
  isAvailable: boolean;
};

export const scaleOptionsInit: ScaleOption[] = [
  {
    scale: 1 / 3,
    isAvailable: false,
  },
  {
    scale: 1 / 2,
    isAvailable: false,
  },
  {
    scale: 2,
    isAvailable: false,
  },
  {
    scale: 3,
    isAvailable: false,
  },
];

export function pruneNonConsecTapsOnChord(
  tapsInSession: Tap[],
  chordId: string
) {
  // remove all prior taps on chord with this id, except those immediately prior; (i.e. preserve the most recent unbroken chain of taps); needed b/c allow a user to "retry" their rhythm on a set of chords simply by re-tapping them, versus having to "finish" the tap session; don't want the "overwritten" taps to continue reflecting in the tap data;
  const tapsInSessionClone = tapsInSession.slice(0);
  var isIdChainBroken = false;
  for (let i = tapsInSessionClone.length - 1; i >= 0; i--) {
    if (tapsInSessionClone[i].chordId !== chordId) {
      isIdChainBroken = true;
    } else if (
      tapsInSessionClone[i].chordId === chordId &&
      isIdChainBroken === true
    ) {
      tapsInSessionClone.splice(i, 1);
      i++;
    }
  }
  return tapsInSessionClone;
}

function moderateTapDurationRatio(ratio: number) {
  // restrict potential ratios based on possible DurationSlider values
  var moderatedRatio = Math.max(Math.min(ratio, MAX_RATIO), MIN_RATIO);
  if (moderatedRatio > 8) {
    // above 8, only whole steps available; round to integer;
    moderatedRatio = Math.round(moderatedRatio);
    // if floor would set value to 0 (e.g. ratio of 0.5), set to 1 instead;
    moderatedRatio = moderatedRatio > 0 ? moderatedRatio : 1;
  } else if (moderatedRatio > 4) {
    // above 4 and up to 8, half steps are available, but not quarter; round quarter down to nearest half;
    var remainder = moderatedRatio % 0.5;
    moderatedRatio = moderatedRatio - remainder;
    moderatedRatio = moderatedRatio > 0 ? moderatedRatio : 0.5;
  }
  return moderatedRatio;
}

// NO LONGER USED
export function calcTapStats(allTaps: Tap[], tapDurationSens: number) {
  console.log("allTaps.length", allTaps.length);
  var tapStats: TapStat[] = [];
  var highestBpm = 0;
  var shortestTapDuration = 0;
  for (let i = 1; i < allTaps.length; i++) {
    var thisTap = allTaps[i],
      priorTap = allTaps[i - 1],
      tapDuration = thisTap.time - priorTap.time,
      bpm = (1000 / tapDuration) * 60;
    // identify highestBpm beat (the largest number, but will represent fastest/shortest beat);
    if (bpm > highestBpm) {
      highestBpm = bpm;
    }
    if (shortestTapDuration === 0 || tapDuration < shortestTapDuration) {
      shortestTapDuration = tapDuration;
    }
    tapStats.push({
      ...priorTap,
      tapDuration,
      bpm,
      // placeholders to satisfy typing; will be overwritten... fix this
      durationRatio: 1,
      correctedBpm: 1,
    });
  }

  var weightedTotal = 0,
    // default to smallest ratio
    largestRatio = tapDurationSens;
  // calculate ratios based on highestBpm beat and determine weighted average tempo;
  tapStats = tapStats.map((val) => {
    // sets resolution to the smallest beat, or half of the smallest beat (latter allows more flexibility, but also provides less correction for the user's rhythm)
    let thisRatio = Math.round(highestBpm / (val.bpm * tapDurationSens));
    if (thisRatio > largestRatio) {
      largestRatio = thisRatio;
    }
    // console.log(val.bpm, thisRatio, thisRatio * val.bpm);

    // weightedTotal += val.bpm * thisRatio;

    /*
      Math.round(1120 / 257) = 4
      4 * 257 = 1028
      Math.round(1120 / (257 * 0.5)) = 9
      9 * 257 * 0.5 = 1156.5
    */
    let durationRatio = Math.round(
      val.tapDuration / (shortestTapDuration * tapDurationSens)
    );
    /*
      Instead of using single shortest tap, would be better if used average of all "shortest" taps (those within one ratio of shortest, tapDurationSens also considered)
      - Assumptions: 
        - each tap durations should be an integer ratio of the shortest;
        - ideally average slight variations in tap durations so e.g. what you use for shortest tap duration is an average of all "shortest" taps (those that would end up with a 1:1 ratio to the shortest), all 2:1 durations are roughly averaged, etc.

    */
    let correctedDuration =
      durationRatio * shortestTapDuration * tapDurationSens;
    // console.log(val.tapDuration, durationRatio, correctedDuration);
    return {
      ...val,
      // cap durationRatio at 16, to ensure when scaled down, it doesn't exceed range of appropriate DurationSlider (e.g. QUARTER_FOUR when divided by 4)
      // durationRatio: Math.min(thisRatio, MAX_RATIO_AFTER_SCALING),
      durationRatio: thisRatio,
      // durationRatio: correctedDuration,
    };
  });

  /*   var weightedAverage = weightedTotal / tapStats.length;
  tapStats = tapStats.map((val) => {
    // correct tempos to be multiples of weightedAverage, based on beatRatios;
    var thisCorrected = weightedAverage / val.durationRatio;
    return {
      ...val,
      correctedBpm: thisCorrected,
    };
  }); */

  /*   if (largestRatio >= HALF_SCALE_AT_RATIO) {
    // default smallest ratio is 1x; if largest ratio is too large, cut all in half or quarter (to make smallest 1/2 or 1/4);
    tapStats = tapStats.map((val) => {
      var scaleDownBy = largestRatio >= QUARTER_SCALE_AT_RATIO ? 4 : 2;
      return {
        ...val,
        durationRatio: val.durationRatio / scaleDownBy,
      };
    });
  } */

  // note: you save both "rhythm" (array of each consecutive tap on chord) and "durationRatio" (aggregate duration of all consecutive taps on a chord); however - at present - you moderate the duration of durationRatio, but not rhythm;
  var tapStatsGroupById: TapStatGroup[] = [];
  tapStats.forEach((val, i) => {
    // does this chord already have an entry in tapStatsGroupById?
    var priorEntryForIdGroup = tapStatsGroupById.find(
      (group) => group.chordId === val.chordId
    );
    if (priorEntryForIdGroup) {
      // aggregate taps for chord; (prior use of pruneNonConsecTapsOnChord() purged non-consecutive taps on same chord);
      priorEntryForIdGroup.rhythm.push(val.durationRatio);
      priorEntryForIdGroup.durationRatio =
        priorEntryForIdGroup.durationRatio + val.durationRatio;
      /*       priorEntryForIdGroup.durationRatio = moderateTapDurationRatio(
        priorEntryForIdGroup.durationRatio + val.durationRatio
      ); */
    } else {
      // chord with id has no prior entry; push new;
      tapStatsGroupById.push({
        chordId: val.chordId,
        rhythm: [val.durationRatio],
        durationRatio: val.durationRatio,
        // durationRatio: moderateTapDurationRatio(val.durationRatio),
      });
    }
  });
  console.log({ tapStatsGroupById });
  return { tapStatsGroupById };
}

export function determineAvailableScales(tapStatsGroupById: TapStatGroup[]) {
  if (tapStatsGroupById.length === 0) {
    // if no chords in group (esp. if user is selecting/de-selecting group to scale), no scaling options will be available;
    return { availableScales: [] };
  }

  /*  const { progressionWithRhythmCount, subdivisionDuration } =
    countProgressionRhythm(tapStatsGroupById, "t4_4");

  const tapStatsGroupByIdWithCount: TapStatGroup[] =
    progressionWithRhythmCount.map((chord) => {
      return {
        chordId: chord.id,
        durationRatio: chord.rhythmDuration,
        // rhythm: chord.rhythm.slice(),
        rhythm: chord.adjustedRhythmDurations.slice(),
      };
    }); */

  /* 
    - this logic needs to be in step with rhythmCount, and so they should rely on a common function;
    - goal is to prohibit scaling 1) below lowest handled duration (1/16 note), otherwise user taps would be "destoyed" by the down scale, and user would be unable to manually select the scaled-to duration... and you wouldn't have the logic to properly display it; 2) above reasonable duration given UI constraints, e.g. if allow sixteen notes, and 8-wide rhythm count per chord, an "8 beat" chord requires four lines of count in UI... suppose could try to limit it based on this anticipated use of space, e.g. limit max chord duration to 8 if sixteenth notes required (a lowest-to-highest ratio of 32x), but allow up to 16 if eighth notes are the lowest, 32 for quarter notes; 
  */

  var potentialScales = scaleOptionsInit.map((option) => option.scale),
    permittedRemaindersBelow4 = [0, 0.25, 0.5, 0.75],
    permittedRemainders4to8 = [0, 0.5],
    permittedRemaindersAbove8 = [0],
    availableScales: number[] = [];

  for (let scale of potentialScales) {
    var scaleIsValid = true,
      durationScaled,
      remainderAfterScale;
    for (let group of tapStatsGroupById) {
      // for (let group of tapStatsGroupByIdWithCount) {
      durationScaled = group.durationRatio * scale;
      if (durationScaled > MAX_RATIO || durationScaled < MIN_RATIO) {
        scaleIsValid = false;
        break;
      }
      remainderAfterScale = durationScaled % 1;
      if (durationScaled > 8) {
        if (permittedRemaindersAbove8.indexOf(remainderAfterScale) === -1) {
          scaleIsValid = false;
          break;
        }
      } else if (durationScaled > 4) {
        if (permittedRemainders4to8.indexOf(remainderAfterScale) === -1) {
          scaleIsValid = false;
          break;
        }
      } else {
        if (permittedRemaindersBelow4.indexOf(remainderAfterScale) === -1) {
          scaleIsValid = false;
          break;
        }
      }
    }
    if (scaleIsValid) {
      availableScales.push(scale);
    }
  }

  return { availableScales };
}

export function getTempo(tapStats: TapStat[]) {
  var len = tapStats.length,
    sum = 0;
  for (var i = 0; i < len; i++) {
    sum += tapStats[i].bpm;
  }
  var tempo = sum / len;
  return tempo;
}

// export function scaleSectionRhythmUnit(
export function getScalingUpdates(
  sectionChords: ChordOrCursor[],
  tapIdSet: Set<string>,
  scale: number
) {
  var scaledChordsTapStats: TapStatGroup[] = [];
  const updatedSectionChords = sectionChords.map((chord) => {
    let chordRhythmProps: {
      duration: number;
    };
    chordRhythmProps = {
      duration: chord.duration * scale,
    };
    scaledChordsTapStats.push({
      chordId: chord.id,
      rhythm: chord.rhythm as number[],
      durationRatio: chord.duration,
      // durationRatio: chordRhythmProps.duration,
    });
    return {
      ...chord,
      ...chordRhythmProps,
    };
  }) as ChordOrCursor[];
  // determineAvailableScales expects type TapStatGroup, and needs to be fed only subset of chord info under consideration;
  const { availableScales } = determineAvailableScales(scaledChordsTapStats);
  const tappedBeatTotal = scaledChordsTapStats.reduce((prev, curr) => {
    return prev + curr.durationRatio;
  }, 0);
  return {
    scaledChordsTapStats,
    updatedSectionChords,
    availableScales,
    tappedBeatTotal,
  };
}

export function getScalingUpdatesOLD(
  sectionChords: ChordOrCursor[],
  tapIdSet: Set<string>,
  scale: number
) {
  var scaledChordsTapStats: TapStatGroup[] = [];
  const updatedSectionChords = sectionChords.map((chord) => {
    // *** perhaps tapIdSet should be renamed, used more generally for any time multiple chords will be selected at once for an operation
    //
    var isInScaleGroup = tapIdSet.has(chord.id);
    var chordRhythmProps: {
      duration: number;
      rhythm: readonly number[];
    };
    if (isInScaleGroup) {
      chordRhythmProps = {
        duration: chord.duration * scale,
        rhythm: chord.rhythm.map((val) => val * scale),
      };
      scaledChordsTapStats.push({
        chordId: chord.id,
        durationRatio: chordRhythmProps.duration,
        rhythm: chordRhythmProps.rhythm as number[],
      });
    } else {
      chordRhythmProps = {
        duration: chord.duration,
        rhythm: chord.rhythm,
      };
    }
    return {
      ...chord,
      ...chordRhythmProps,
    };
  }) as ChordOrCursor[];
  // determineAvailableScales expects type TapStatGroup, and needs to be fed only subset of chord info under consideration;
  const { availableScales } = determineAvailableScales(scaledChordsTapStats);
  const tappedBeatTotal = scaledChordsTapStats.reduce((prev, curr) => {
    return prev + curr.durationRatio;
  }, 0);
  return {
    scaledChordsTapStats,
    updatedSectionChords,
    availableScales,
    tappedBeatTotal,
  };
}
