import React from "react";

/* 
Functions to provide hover/touch interactions:
-on mousemove, touchstart/touchmove, set UI state, but do not trigger update
-on mousedown, touchEnd, trigger update
*/

// to facilitate touch actions, esp. allowing user to drag finger around keyboard, this component uses elementFromPoint() to retrieve the element (and thus value) to use for the input; elementFromPoint() can grab from anywhere in the DOM, and thus isn't bound by this component, or any resulting element; this can result in undesirable behavior if user taps as chord inputs transition (e.g. from root selection to qual selection), whereby the touch actions from the prior input component (e.g. root) triggers on the incoming (e.g. qual) checkboxes, thus setting root to a qual value; give each GKeyboard a unique id, and check for that id before firing action; (alternative solution: could pass both input type and value to become dom properties of the checkboxes, and use the newly passed type instead of the type residing in the component)

/**
 * If want to accommodate user releasing finger while hovered over key's popup (PresentationCheckBoxPopup):
 * -must add class and name to popup, in order to pass getKeyName()
 * -must remove pointer-events:none
 * -as result of removing pointer-events:none, must adjust Z order, or otherwise ensure checkbox input receives pointer event
 */

export const GKEY_HANDOFF_ID = "gkey_handoff_enabled";

export function useGKeyboardTouchHelpers(
  groupId: string,
  removeTouchListeners: () => void,
  setHandoffRef: React.Dispatch<React.SetStateAction<Element | null>>,
  touchRef: React.RefObject<HTMLDivElement>,
  handoffRef: Element | null
) {
  // allow user to navigate the chord input tabs and buttons without requiring them to lift their finger, e.g. moving from main tabs to chord qual tabs to chord qual buttons... and back;
  // this relies on touchmove, which always targets the same element that received touchstart for the touch point; there is no way to break that touch point's association with the first element, and "bind" it to the subsequent element; thus this code doesn't truly "hand off"; the browser continues to fire touchmove for the element that received touchstart... and then this code fires a separate touchmove for the other elements;
  // did a lot of reading and testing in sarch of a "true hand off": for the code to act as if the user had lifted finger replace on the new element, ending the last touch point and starting a new one with touchstart on the desired element; this way, continued touchmove events would be organic, and not fired by my code; no dice!
  // other options that occur to me... move useGKeyboardTouch to CreateUpdateChord, which is the parent of all components you want to be able to "hand off" between, but problem is each component requires different callbacks for handleSelect/handleHover... you would have to e.g. modify useGKeyboard touch to receive a map of groupId:callback... and re-route some functions so they would be available within CreateUpdateChord, which would break the modularity of the code... it's likely a bad idea;
  // would using custom events make more sense here?  Or would it be just as inefficient as re-firing touchmove?  Might be safer than dispatching a touch event, as less likely to run afoul of browser restrictions;
  function handOffTouch(element: Element, e: React.TouchEvent | TouchEvent) {
    var currentTarget = e.currentTarget;
    if (element?.getAttribute("data-gkey-handoff-id") === GKEY_HANDOFF_ID) {
      if (!currentTarget) {
        return;
      }
      // reminder: you tried firing a touchend/touchcancel event on the element that originally received touchstart, trying to prevent touchmove from continuing to fire on that element, but that doesn't work; although the event does fire, it doesn't stop touchmove and other "continuing" touch events from firing on the original target of touchstart (e.currentTarget);
      // so technically this setup doesn't fully "hand off" the touch, but spams a second touch event every time the original event occurs (which is directly generated by the user's touch);
      // reminder: tried removing the event listeners attached to original touchstart element, then initiating a touchstart on the now-hovered gkey group; this does seem to be the only way to cease touchmove from continuing to fire on the original touchstart target, but 1) no handoff occurs, seemingly because the browser won't let you replicate a true user-initiated touchstart to pass touch "control" to another component (presumably security concern), and 2) you'd have to rebind the touch listeners that you removed otherwise couldn't touchmove back to that compoment;
      const newTouch = new Touch({
        identifier: 0,
        target: element,
        // this was the final piece I was missing when I struggled to make this "hand off" work; it's not enough to trigger an event on the element: need to specify a location conciding with location of that element;
        pageX: e.changedTouches[0].pageX,
        pageY: e.changedTouches[0].pageY,
      });
      // hand off same type of touchevent originally received; e.g. for GKeyboard need to hand off touchend to select the key; whereas for SideTab only need to hand off touchmove;
      var eTouch = new TouchEvent(e.type, {
        touches: [newTouch],
        targetTouches: [newTouch],
        changedTouches: [newTouch],
        bubbles: true,
        cancelable: true,
      });
      element.dispatchEvent(eTouch);
      if (element !== handoffRef) {
        setHandoffRef(element);
      }
    } else {
      // send touchcancel to last gkey group that was handed-off to;
      // there was a touchstart on a gkey group; subsequent touchmoves may have taken it over other gkey groups, but now we've detected a touchmove that's over a non-gkey group (or a gkey group that's not part of same handoff group); the gkey group that received the touchstart will naturally handle this properly, but the last intervening gkey group will have received a touchmove event from above logic, and needs to receive a touchcancel to ensure UI and behavior acts as desired;
      // e.g. if touchstart on root key then move up until you're clear of the keyboard, the root key will lose hover state; without belo code, if touchstart on input tabs then touchmove over root keys, then move up until you're clear, root key will maintain hover state;
      if (handoffRef) {
        const endTouch = new Touch({
          identifier: 0,
          target: handoffRef,
          pageX: e.changedTouches[0].pageX,
          pageY: e.changedTouches[0].pageY,
        });
        var eTouchEnd = new TouchEvent("touchcancel", {
          touches: [],
          targetTouches: [],
          changedTouches: [endTouch],
          bubbles: true,
          cancelable: true,
        });
        handoffRef?.dispatchEvent(eTouchEnd);
      }
    }
  }

  function getKeyName(element: Element, e?: React.TouchEvent | TouchEvent) {
    // element will be that which covers the button and receives the touch; ensure it has the groupId applied as a class (for GKey: NativeCheckBoxLabel); one of its children will be a checkbox input, which will carry all other data; (as I recall this convention is purely a relic of the origins of GKeyboard, and a checkbox need not be employed, though it's perhaps still semantically appropriate)
    let thisGkeyGroupId = element?.getAttribute("data-gkey-group-id");
    const clickedElementHasGroupIdClass = thisGkeyGroupId === groupId;
    // console.log(
    //   e?.type,
    //   thisGkeyGroupId,
    //   clickedElementHasGroupIdClass,
    //   element.isConnected,
    //   e?.currentTarget
    // );

    if (
      // this is a different component; handoff;
      clickedElementHasGroupIdClass === false ||
      // if touchRef.current is null, then the element that was the original recipient of the touchstart has been unmounted from the DOM, and thus progressing with this code will do you no good, because e.g. that instance of GKeyboard will not receive an updated hoverTarget from these fncs; remember these fncs are bound inside event listeners; (though it's not clear to me why the unmounting doesn't result in useGKeyboardTouch calling removeListeners() and thus preventing further event callbacks from firing related to the unmounted component); anyway, treat this case the same as with other handoffs, but handoff to the new instance of the same component;
      touchRef.current === null
    ) {
      e && handOffTouch(element, e);
      return null;
    }

    const childCheckbox: HTMLInputElement | null = element?.querySelector(
      "input[type='checkbox']"
    );
    if (!childCheckbox || childCheckbox.disabled) {
      return null;
    }
    const name = childCheckbox?.getAttribute("name");
    return name;
  }

  function getElementFromCoords(x: number, y: number) {
    return document.elementFromPoint(x, y);
  }

  return {
    handleMouseMove: function handleMouseMove(
      e: React.MouseEvent | MouseEvent,
      setHovered: (name: string) => void
    ) {
      // *** would think I could simply use e.target, but TypeScript complains it isn't returning an Element... suppose b/c was using React's MouseEvent instead of vanilla JS
      var element = getElementFromCoords(e.pageX, e.pageY);
      if (element) {
        const checkboxName = getKeyName(element);
        if (checkboxName) {
          setHovered(checkboxName);
        } else {
          // if element doesn't pass getKeyName(), clear setHovered, otherwise user may be hovering over invalid element, but because hover state still shows for key, user will think releasing their finger will select that key
          setHovered("");
        }
      }
    },

    handleTouchMove: function handleTouchMove(
      e: React.TouchEvent | TouchEvent,
      setHovered: (name: string) => void
    ) {
      // *** use targetTouches/changedTouches prop instead of e.touches?
      if (e.changedTouches) {
        var element = getElementFromCoords(
          e.changedTouches[0].pageX,
          e.changedTouches[0].pageY
        );
        if (element) {
          const checkboxName = getKeyName(element, e);
          if (checkboxName) {
            setHovered(checkboxName);
          } else {
            // if element doesn't pass getKeyName(), clear setHovered, otherwise user may be hovering over invalid element, but because hover state still shows for key, user will think releasing their finger will select that key
            setHovered("");
          }
        }
      }
    },

    handleMouseUp: function handleMouseUp(
      e: React.MouseEvent | MouseEvent,
      setSelected: (name: string) => void
    ) {
      // *** would think I could simply use e.target, but TypeScript complains it isn't returning an Element... suppose b/c was using React's MouseEvent instead of vanilla JS
      var element = getElementFromCoords(e.pageX, e.pageY);
      if (element) {
        const checkboxName = getKeyName(element);
        checkboxName && setSelected(checkboxName);
      }
    },

    handleTouchEnd: function handleTouchEnd(
      e: React.TouchEvent | TouchEvent,
      setSelected: (name: string) => void,
      setHovered: (name: string) => void
    ) {
      // for touchend, use changedTouches prop: there will be no active touches listed in touches prop b/c the touches have ended
      // https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent
      if (e.changedTouches) {
        var element = getElementFromCoords(
          e.changedTouches[0].pageX,
          e.changedTouches[0].pageY
        );
        if (element) {
          const checkboxName = getKeyName(element, e);
          checkboxName && setSelected(checkboxName);
        }
      }
      // delay removal of hover state to give visual feedback of selection
      setTimeout(() => {
        setHovered("");
      }, 200);
    },

    handleTouchCancel: function handleTouchCancel(
      e: React.TouchEvent | TouchEvent,
      setSelected: (name: string) => void,
      setHovered: (name: string) => void
    ) {
      setHovered("");
    },
  };
}
