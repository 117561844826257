import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import styled from "styled-components/macro";

import { SongRejectShare_song$key } from "../__generated__/SongRejectShare_song.graphql";
import { SongRejectShareMutation } from "../__generated__/SongRejectShareMutation.graphql";
import { CustomErrors } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";
import { Modal } from "../_components/Modal";
import {
  IndentedList,
  IndentedListGroup,
  IndentedListItem,
  IndentedListLabel,
  IndentedListTitle,
} from "../_components/IndentedList";

type Props = {
  songRef: SongRejectShare_song$key;
  userId: string;
  callback?: () => void;
};

export function SongRejectShare(props: Props) {
  const song = useFragment(
    graphql`
      fragment SongRejectShare_song on Song {
        id
        title
        viewerPermission {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const [errMsg, setErrMsg] = React.useState<string>("");
  // const [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const [commit, isInFlight] = useMutation<SongRejectShareMutation>(graphql`
    mutation SongRejectShareMutation(
      $connections: [ID!]!
      $input: rejectSongShareInput!
    ) {
      rejectSongShare(input: $input) {
        rejectedSong {
          id @deleteEdge(connections: $connections)
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        #
        # relay will warn "MutationHandlers: Expected target node to exist." if clonedSong is undefined; may want to create separate mutation query for rejecting after accepting?
        #
        clonedSong
          @appendNode(connections: $connections, edgeTypeName: "UserSongs") {
          id
          mongoVersion
          title
          artist
          tags
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
          viewerPermission {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCommit() {
    const connectionID = ConnectionHandler.getConnectionID(
      props.userId,
      "UserSongs_songs"
    );
    commit({
      variables: {
        input: {
          songId: song.id,
        },
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.rejectSongShare) {
          const { errors } = response.rejectSongShare;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          } else {
            props.callback && props.callback();
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
    });
  }

  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);

  function openConfirmModal() {
    setDoShowDialog(true);
  }

  function closeConfirmModal() {
    setDoShowDialog(false);
  }

  function handleOnClick() {
    if (song.viewerPermission.didAccept) {
      // user previously accepted; open confirm modal
      openConfirmModal();
    } else {
      // used has not accepted; reject share immediately
      handleCommit();
    }
  }

  return (
    <>
      <Button
        onClick={handleOnClick}
        fill="clear"
        palette="danger"
        disabled={isInFlight}
        iconId="user-x"
      >
        {song.viewerPermission.didAccept
          ? // ? "Leave Share and Create Copy"
            "Leave Share"
          : "Reject Song Share"}
      </Button>

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={closeConfirmModal}
        label={"Leave share"}
        handleConfirm={handleCommit}
        actionText={"Leave"}
        actionColor="danger"
        isInFlight={isInFlight}
        showCloseButton={true}
      >
        <ModalBody>
          <h3>Leave Share</h3>
          <p>
            <SongTitle>"{song.title}"</SongTitle>
          </p>
          <p>You will receive your own copy of the song.</p>
        </ModalBody>
      </Modal>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
    </>
  );
}

const ModalBody = styled.div`
  padding: 0px 16px;
  /* font-size: ${16 / 16}rem; */
`;

const Item = styled.div`
  margin-bottom: ${16 / 16}rem;
`;
const ItemLabel = styled.div`
  padding-bottom: ${8 / 16}rem;
`;

const EmphasizeIndent = styled.div`
  font-weight: 500;
  margin-left: ${16 / 16}rem;
`;
const SongTitle = styled.span`
  font-weight: 500;
`;

const EmailAddress = styled.span`
  font-weight: 500;
`;
