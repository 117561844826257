/**
 * @generated SignedSource<<1b6bad1ea6acc6fd95a9f510c68be426>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteSection_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly " $fragmentType": "DeleteSection_song";
};
export type DeleteSection_song$key = {
  readonly " $data"?: DeleteSection_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteSection_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteSection_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "9ad4314e1f8484d83d05b5ab27db2588";

export default node;
