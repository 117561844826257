import React from "react";
import { useHistory } from "react-router-dom";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { SongListItem_song$key } from "../__generated__/SongListItem_song.graphql";

import { ListItemContext } from "../_components/ListItemContext";
import { TagsSmall } from "./TagsSmall";
import { TitleArtist } from "../_style-components/TitleArtist";

// in px, will be used as rem
// export const SONG_LIST_ITEM_HEIGHT = 84;
export const SONG_LIST_ITEM_HEIGHT = 76;

type Props = {
  songRef: SongListItem_song$key;
  tagFilter: string[];
  openDialog: () => void;
};

export function SongListItem(props: Props) {
  const song = useFragment(
    graphql`
      fragment SongListItem_song on Song {
        id
        title
        artist
        tags
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
        viewerPermission {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const itemRef = React.useRef<HTMLDivElement>(null);
  // works for most cases, but when font size is made very large, CHAR_WIDTH constant may be too small compared to reality and tags overflow
  const CHAR_WIDTH = 7;
  // const [availWidth, setAvailWidth] = React.useState<number>(0);
  const availWidthRef = React.useRef<number>(0);
  React.useLayoutEffect(() => {
    // clientWidth includes padding; if you want to be precise - would need to subtract padding to determine available width; no padding on this element, though
    const widthWithPadding = itemRef.current?.clientWidth;
    // widthWithPadding && setAvailWidth(widthWithPadding);
    if (widthWithPadding) availWidthRef.current = widthWithPadding;
  }, []);

  const history = useHistory();

  function handleNavToSong() {
    history.push(`/song/${song.id}`);
  }

  const style = {
    // for block item with fixed height, use rem instead of px to ensure will scale with user's font size
    "--height": `${SONG_LIST_ITEM_HEIGHT / 16}rem`,
    // "--height": `${SONG_LIST_ITEM_HEIGHT}px`,
    "--color": "var(--color-text)",
    "--song-list-item-background": song.viewerPermission.didAccept
      ? "transparent"
      : "var(--color-secondary-translucent-20)",
  };

  function getContextIconId() {
    if (song.viewerPermission.isOwner === true) {
      if (song.permissions.length > 1) {
        return "users";
      }
    }
    if (song.viewerPermission.isOwner === false) {
      if (!song.viewerPermission.didAccept) {
        return "user-plus";
      } else {
        return "user-check";
      }
    }
    return undefined;
  }

  return (
    <ListItemContext
      itemOnClick={
        song.viewerPermission.didAccept ? handleNavToSong : props.openDialog
      }
      contextOnClick={props.openDialog}
      style={style as React.CSSProperties}
      contextIconId={getContextIconId()}
      itemButtonPalette={song.viewerPermission.didAccept ? undefined : "mono"}
    >
      <Content ref={itemRef}>
        <TitleArtist title={song.title} artist={song.artist} />
        <TagsSmall
          tags={song.tags}
          tagFilter={props.tagFilter}
          maxChars={availWidthRef.current / CHAR_WIDTH}
        />
      </Content>
    </ListItemContext>
  );
}

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
`;
