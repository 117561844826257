import * as React from "react";
import styled from "styled-components/macro";

export function LoadingSpinnerComplete({
  width,
  overlay,
}: {
  width?: number;
  overlay?: boolean;
}) {
  if (overlay) {
    return (
      <LoadingSpinnerOverlay>
        <LoadingSpinner width={width} />
      </LoadingSpinnerOverlay>
    );
  } else {
    return <LoadingSpinner width={width} />;
  }
}

const LoadingSpinnerOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-background-translucent-60);
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LoadingSpinner({ width = 64 }: { width?: number }) {
  var padding = width / 16,
    borderWidth = width / 6;

  return (
    <Wrapper
      style={
        {
          "--width": width + "px",
          "--padding": padding + "px",
        } as React.CSSProperties
      }
    >
      <HeightEqualToWidth>
        <Spinner
          style={
            { "--border-width": borderWidth + "px" } as React.CSSProperties
          }
        />
      </HeightEqualToWidth>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: var(--width);
  /* center, when not used within LoadingSpinnerOverlay */
  margin: 0 auto;
  padding: var(--padding);
`;

const HeightEqualToWidth = styled.div`
  width: 100%;
  /* use padding-bottom to "set" height equal to width (b/c padding is always based on width), allowing component to be scalable based on parent width, while maintaining 1:1 height:width */
  padding-bottom: 100%;
  position: relative;
`;

const Spinner = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  /* center with left, right, and margin */
  left: 0px;
  right: 0px;
  /* margin: 0 auto; */

  height: 100%;
  width: 100%;

  border: var(--border-width) solid var(--color-primary-light);
  border-top: var(--border-width) solid var(--color-primary);
  border-bottom: var(--border-width) solid var(--color-primary);
  border-radius: 50%;

  animation: spin 1.4s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
