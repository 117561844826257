/**
 * @generated SignedSource<<d27624586c2f53c46278e22a15cf0d4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongDeleteShare_permission$data = {
  readonly canEdit: boolean;
  readonly didAccept: boolean;
  readonly email: string;
  readonly isOwner: boolean;
  readonly user: string;
  readonly " $fragmentType": "SongDeleteShare_permission";
};
export type SongDeleteShare_permission$key = {
  readonly " $data"?: SongDeleteShare_permission$data;
  readonly " $fragmentSpreads": FragmentRefs<"SongDeleteShare_permission">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SongDeleteShare_permission",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "didAccept",
      "storageKey": null
    }
  ],
  "type": "SongPermission",
  "abstractKey": null
};

(node as any).hash = "c1e826d167d3140e25ff1ff0ec1cf5bc";

export default node;
