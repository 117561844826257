import { ScreenOrientation } from "@capacitor/screen-orientation";

// wrap all in try catch
// think of clean way to export single object with each fnc as method, including a single check for isNativeCap that will return before executing any method if false

export function lockPortrait() {
  ScreenOrientation.lock({ orientation: "portrait-primary" });
}

// export function lockLandscape() {
//   ScreenOrientation.lock({ orientation: "landscape" });
// }

export function unlockOrientation() {
  ScreenOrientation.unlock();
}
