import React from "react";

import { useLogout } from "../_hooks/useLogout";

export function Logout() {
  const { handleLogout } = useLogout();

  React.useEffect(
    () => handleLogout(),
    /* eslint-disable react-hooks/exhaustive-deps */
    []
  );

  return null;
}
