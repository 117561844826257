import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { UnlockSong_song$key } from "../__generated__/UnlockSong_song.graphql";
import { UnlockSongMutation } from "../__generated__/UnlockSongMutation.graphql";

import Button from "../_components/Button";
import { isApiError } from "../_types/errorTypes";
import { ModalError } from "../_components/ModalError";
import { formatSongLockDate } from "../_utilities/dates";
import { HelpModal } from "../_components/HelpModal";

type Props = {
  songRef: UnlockSong_song$key;
};

export function UnlockSong(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");

  const song = useFragment(
    graphql`
      fragment UnlockSong_song on Song {
        id
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
        editLock {
          isLocked
          lockedBy
          lockedByEmail
          lockedUntil
        }
        isLockedByUser
      }
    `,
    props.songRef
  );

  const [commit, isInFlight] = useMutation<UnlockSongMutation>(graphql`
    mutation UnlockSongMutation($input: unlockSongInput!) {
      unlockSong(input: $input) {
        song {
          id
          editLock {
            isLocked
            lockedBy
            lockedByEmail
            lockedUntil
          }
          isLockedByUser
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCommit() {
    commit({
      variables: {
        input: {
          songId: song.id,
        },
      },
      onCompleted: (response) => {
        if (response.unlockSong) {
          const { errors } = response.unlockSong;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  if (song.permissions.length === 1) {
    // if song not shared, don't show UnlockSong interface;
    return null;
  }

  const songIsLockedRightNow =
    song.editLock &&
    song.editLock.isLocked &&
    new Date(song.editLock.lockedUntil) > new Date();

  const buttonIsDisabled =
    isInFlight || !song.isLockedByUser || !songIsLockedRightNow;

  return (
    <>
      <Wrapper>
        <ButtonsWrapper>
          <Button
            onClick={handleCommit}
            fill="clear"
            iconId={"unlock"}
            disabled={buttonIsDisabled}
            palette={buttonIsDisabled ? "disabled" : "primary"}
          >
            <ButtonContents>
              {(song.isLockedByUser || !songIsLockedRightNow) && (
                <div>Unlock Song</div>
              )}
              <LockInfo>
                {song.editLock && songIsLockedRightNow && (
                  <LockedBy>
                    Locked by{" "}
                    {song.isLockedByUser ? "you" : song.editLock.lockedByEmail}{" "}
                    until {formatSongLockDate(song.editLock.lockedUntil)}
                  </LockedBy>
                )}
              </LockInfo>
            </ButtonContents>
          </Button>
          <HelpModal label="Unlock song">
            <h3>Song Edit Lock</h3>
            <p>Only one user may edit a shared song at a time.</p>
            <p>
              When you edit a song, it is locked for a short period of time to
              ensure other users don't make conflicting edits.
            </p>
            <p>
              If other users want to edit the song, you can manually unlock it,
              or they can wait for the lock to time out.
            </p>
          </HelpModal>
        </ButtonsWrapper>
      </Wrapper>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const Wrapper = styled.div``;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const LockInfo = styled.div`
  font-size: ${14 / 16}rem;
  color: var(--color-text-medium);
`;
const ButtonContents = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const LockedBy = styled.div``;
