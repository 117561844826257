import React from "react";
import styled from "styled-components/macro";
import { TimeSigClient, timeSigValToLabel } from "../_music/rhythmCount";

type Props = {
  timeSigVal: TimeSigClient;
  labelOrVert?: "label" | "vert";
};

export function TimeSigDisplay(props: Props) {
  function timeSigLabelToVert(val: string) {
    const slashIndex = val.indexOf("/");
    const beatsPerBar = val.slice(0, slashIndex);
    const beatDuration = val.slice(slashIndex + 1);
    return (
      <InlineWrapper>
        <Label>
          <VertWrapper>
            <VertPart>{beatsPerBar}</VertPart>
            <Divider />
            <VertPart>{beatDuration}</VertPart>
          </VertWrapper>
        </Label>
      </InlineWrapper>
    );
  }

  const timeSigLabel = timeSigValToLabel(props.timeSigVal);

  if (props.labelOrVert === "vert") {
    return timeSigLabelToVert(timeSigLabel);
  } else {
    return <Label>{timeSigLabel}</Label>;
  }
}

const InlineWrapper = styled.div`
  display: inline-block;
`;

const VertWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  line-height: 0.95;
`;

const VertPart = styled.div``;
const Divider = styled.div`
  width: 100%;
  flex-basis: 1px;
  background-color: var(--color-text-medium);
`;

const Label = styled.div`
  font-family: var(--font-family-serif);
  color: var(--color-text-medium);
  font-size: ${16 / 16}rem;
  font-weight: bold;
`;
