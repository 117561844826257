/**
 *
 * Immediate goal: after user has input root and qual, suggest potential bass notes for one-touch creation of a slash chord;
 * setScaleInKey() in scaleTools.js performs similar work to create a scale; perhaps could replace it with a version of this code, which seems a better algorithm than what I used there;
 *
 **/

import { CHORD_QUALS, intervalToHalfSteps } from "./chordQualLib";
import { rootLetters, rootNums, roots } from "./roots";

// should have typed root/qual more explicitly?  Or would that be a problem given GraphQL's limitations on string characters (so couldn't use sharp/flat special chars)?
type ChordDefToNotes = {
  root: string;
  qual: string;
};

export function chordDefToNotes(props: ChordDefToNotes): string[] {
  var rootNum = rootNums[props.root];
  var intervals = CHORD_QUALS[props.qual]?.intervals;
  if (rootNum === undefined || intervals === undefined) {
    // rootNum or intervals undefined; returning empty array;
    // this will occur e.g. when selected chord is default CURSOR;
    return [];
  }
  var chordNotes = [props.root];
  var currNoteNum = rootNum;
  for (let interval of intervals) {
    // get letter only; strip any accidental;
    let priorNoteLetter = chordNotes[chordNotes.length - 1].slice(0, 1);
    let currHalfSteps = intervalToHalfSteps[interval];
    currNoteNum += currHalfSteps;
    // possible to have an interval exceeding an octave, so need "while" instead of "if";
    while (currNoteNum >= 12) {
      currNoteNum -= 12;
    }
    let notesMatchingNum = roots.filter(
      (note) => rootNums[note] === currNoteNum
    );
    // interval string includes a single letter and a one- or two-digit number; subtract one from that number to get the number of "letter steps" that need to be taken; e.g. if moving a "M3" from a "C", want to move up 2 letters, to "E"; using intervalToHalfSteps to get the halfstep value of "M3", which will determine any accidental added to the letter;
    let incrementLetterBy = parseInt(interval.slice(1)) - 1;
    let nextLetterIndex =
      rootLetters.indexOf(priorNoteLetter) + incrementLetterBy;
    while (nextLetterIndex >= rootLetters.length) {
      nextLetterIndex -= rootLetters.length;
    }
    let nextLetter = rootLetters[nextLetterIndex];
    // whichever note of notesMatchingNum has the correct letter (as described by the interval) is the next note;
    let nextNote = notesMatchingNum.find(
      (val) => nextLetter && val.indexOf(nextLetter) > -1
    );
    if (!nextNote) {
      console.error(
        "nextNote not identified; returning incomplete note array;"
      );
      console.log(chordNotes, nextLetter, notesMatchingNum);
      // return partial set of notes; most likely any failures to identify nextNote comes from using an unlikely root, resulting in the need for double/triple accidentals you haven't specified in rootNums; returning a partial set will usually be enough for your "suggest alternate root" feature to function on the AddDelete input tab;
      return chordNotes;
    }
    chordNotes.push(nextNote);
  }
  return chordNotes;
}

export function chordDefToNotesSafe(props: ChordDefToNotes) {
  const chordNotes = chordDefToNotes(props);
  const chordNotesSafe = chordNotes.filter(
    (note) => note.indexOf("♯♯") === -1 && note.indexOf("♭♭") === -1
  );
  var includesMultiAcc = chordNotes.length !== chordNotesSafe.length;
  var rootNum = rootNums[props.root];
  var notesMatchingNum = roots.filter((note) => rootNums[note] === rootNum);
  // not checking to confirm suggested alternate roots will not produce any multi-accidental notes for chosen chord quality, but given that don't allow multi-accidental root notes, odds are the other available root note will be safe;
  var safeRootAlts = includesMultiAcc
    ? notesMatchingNum.filter(
        (note) =>
          note !== props.root &&
          note.indexOf("♯♯") === -1 &&
          note.indexOf("♭♭") === -1
      )
    : // if current root is "safe", don't return alternates; again, not checking if returned roots are safe, but assuming will be *if* current root is not; if current root *is* safe, that assumption fails and may return an unsafe root;
      [];
  return { chordNotes, chordNotesSafe, includesMultiAcc, safeRootAlts };
}

// for testing with nodemon

// Object.keys(CHORD_QUALS).forEach((qual, i) => {
//   console.log(i, qual, chordDefToNotes({ root: "C♭", qual }));
// });

// *** add test that cycles through all potential root notes, along with all quals, and logs only problem combos;
//
