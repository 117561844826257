import React from "react";
import styled from "styled-components/macro";

import { TransposeAccidentalType, transposeSection } from "./transpose.helpers";
import { FAB_EDGE_MARGIN, FloatingWrapper } from "..//FloatingWrapper";
import { WORD_JOINER } from "../../_constants";
import { RippleButton } from "../RippleButton";
import { Icon } from "../Icon";
import { AppContext } from "../../_context/appContext";

type Props = {
  transposeVal: number;
  setTransposeVal: React.Dispatch<React.SetStateAction<number>>;
  accidentalsVal: TransposeAccidentalType;
  setAccidentalsVal: React.Dispatch<
    React.SetStateAction<TransposeAccidentalType>
  >;
};

/*   
// parent component should provide state similar to below;
// these values are consumed by transposeSection (in transpose.helpers)
const [transposeVal, setTransposeVal] = React.useState<number>(0);
const [accidentalsVal, setAccidentalsVal] =
  React.useState<TransposeAccidentalType>("preserve"); 
*/

export function Transpose(props: Props) {
  const { transposeVal, setTransposeVal, accidentalsVal, setAccidentalsVal } =
    props;

  const { state, dispatch } = React.useContext(AppContext);

  function resetTransposeVal() {
    setTransposeVal(0);
    setAccidentalsVal("preserve");
  }

  function incrementTransposeVal(incr: number) {
    if (accidentalsVal === "preserve") {
      setAccidentalsVal("flat");
    }

    var newVal = transposeVal + incr;
    if (newVal > 11) newVal -= 12;
    if (newVal < -11) newVal += 12;

    setTransposeVal(newVal);
    if (newVal === 0) {
      setAccidentalsVal("preserve");
    }
  }

  function toggleAccidentalsVal() {
    setAccidentalsVal((val) => {
      if (val === "preserve") {
        return "flat";
      } else if (val === "flat") {
        return "sharp";
      } else {
        // when val === "sharp", check transposeVal
        if (transposeVal === 0) {
          return "preserve";
        } else {
          return "flat";
        }
      }
    });
  }

  return (
    <>
      {state.app.doShowTranspose && (
        <TransposeControls>
          <StyledFloatingWrapperTransposeDown>
            <ButtonTranspose onClick={() => incrementTransposeVal(-1)}>
              <Icon id="minus" size={20} />
            </ButtonTranspose>
          </StyledFloatingWrapperTransposeDown>
          <StyledFloatingWrapperTransposeReset $canReset={transposeVal !== 0}>
            <ButtonTransposeReset onClick={resetTransposeVal}>
              {transposeVal > 0 && "+"}
              {transposeVal}
            </ButtonTransposeReset>
          </StyledFloatingWrapperTransposeReset>
          <StyledFloatingWrapperAccidentals $canReset={transposeVal !== 0}>
            <ButtonAccidental onClick={toggleAccidentalsVal}>
              <AccidentalsVal>
                {
                  {
                    "preserve": `♭${WORD_JOINER}/${WORD_JOINER}♯`,
                    "flat": `♭`,
                    "sharp": `♯`,
                  }[accidentalsVal]
                }
              </AccidentalsVal>
            </ButtonAccidental>
          </StyledFloatingWrapperAccidentals>
          <StyledFloatingWrapperTransposeUp>
            <ButtonTranspose onClick={() => incrementTransposeVal(1)}>
              <Icon id="plus" size={20} />
            </ButtonTranspose>
          </StyledFloatingWrapperTransposeUp>
        </TransposeControls>
      )}
    </>
  );
}

const TransposeControls = styled.div`
  /* I enjoyed the idea of this, but too difficult to see the buttons when not focused */
  /* opacity: 0.6; */
  transition: opacity 0.4s;

  /* ffx needed focus-within to work as expected; chrome did not */
  &:focus-visible,
  &:focus-within,
  &:hover,
  &:active {
    opacity: 1;
  }
`;

const FAB_WIDTH = 44,
  FAB_SPACING = 6;

const StyledFloatingWrapper = styled(FloatingWrapper)`
  transform: translateX(0px) translateY(0px) scale(1);
  transition: transform 0.4s, opacity 0.8s;
  /* box-shadow: var(--shadow-elevation-low); */
`;
const StyledFloatingWrapperTransposeDown = styled(StyledFloatingWrapper)`
  right: ${FAB_EDGE_MARGIN.horiz + FAB_WIDTH * 2 + FAB_SPACING * 2}px;
  border-radius: 50% 10% 10% 50%;
`;
const StyledFloatingWrapperTransposeReset = styled(StyledFloatingWrapper)<{
  $canReset: boolean;
}>`
  /* the reset button (which this wraps), is smaller, only 40px wide, so to center it on the 44px accidental button, add 2px to "right" */
  right: ${FAB_EDGE_MARGIN.horiz + FAB_WIDTH + FAB_SPACING + 2}px;
  border-radius: 10%;
  box-shadow: var(--shadow-elevation-low);
  opacity: 0;
  /* when resetting, delay transition so button press can be seen */
  transition-delay: 0.2s;

  ${({ $canReset }) =>
    $canReset &&
    `
    opacity:1;
    transform: translateY(-40px);
    transition-delay: 0.0s;
    `}
`;
const StyledFloatingWrapperAccidentals = styled(StyledFloatingWrapper)<{
  $canReset: boolean;
}>`
  right: ${FAB_EDGE_MARGIN.horiz + FAB_WIDTH + FAB_SPACING}px;
  border-radius: 10%;

  ${({ $canReset }) =>
    $canReset &&
    `
    transform: translateY(8px);
    `}
`;
const StyledFloatingWrapperTransposeUp = styled(StyledFloatingWrapper)`
  border-radius: 10% 50% 50% 10%;
`;
const StyledRippleButton = styled(RippleButton)`
  padding: 0px;
  min-height: 44px;
  min-width: ${FAB_WIDTH}px;
  height: 44px;
  width: ${FAB_WIDTH}px;
  font-size: ${20 / 16}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--color-background-translucent-20); */
  /* background-color: var(--color-background); */
  // allow FloatingWrapper to control background;
  background: none;
  color: var(--color-primary);
`;
const ButtonTranspose = styled(StyledRippleButton)``;
const ButtonTransposeReset = styled(StyledRippleButton)`
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  color: var(--color-text);
`;
const ButtonAccidental = styled(StyledRippleButton)`
  flex-flow: column nowrap;
`;
const AccidentalsVal = styled.div``;
