import React from "react";
import styled from "styled-components/macro";
import { useTransition, animated, config } from "@react-spring/web";
// import BezierEasing from "bezier-easing";

type Props = {
  // children: React.ReactNode;
  tabsObj: { [index: string]: React.ReactNode };
  selectedTab: string;
};

export function InputTabWrapper(props: Props) {
  // commented code below is to allow "leave" animation to complete before beginning next "enter"; it's against the react-spring philosophy, but an alternative to using position:absolute if you want to avoid the transition affecting layout: https://github.com/pmndrs/react-spring/issues/583
  // const ANIMATION_DURATION = 200;
  const transitions = useTransition(props.selectedTab, {
    from: { opacity: 0, transform: "translate3d(16px,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0px,0,0)" },
    leave: { opacity: 0, transform: "translate3d(8px,0,0)" },
    // trail: ANIMATION_DURATION,
    config: {
      ...config.stiff,
      clamp: true,
      // duration: ANIMATION_DURATION,
      // easing: BezierEasing(0, 0, 0.58, 1),
    },
    // order: ["leave", "enter", "update"],
  });

  return (
    <InputsWrapper>
      {transitions((style, selectedTabTransition) => (
        <InputsWrapperAbsoluteAnim style={style}>
          {props.tabsObj[selectedTabTransition]}
        </InputsWrapperAbsoluteAnim>
      ))}
    </InputsWrapper>
  );
}

const InputsWrapper = styled.div`
  position: relative;
  flex: 1;
  // ensure 3D elements in these inputs stay in front of DurationSlider's 3D handle
  z-index: 1;
`;

const InputsWrapperAbsolute = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const InputsWrapperAbsoluteAnim = animated(InputsWrapperAbsolute);
