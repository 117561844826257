import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { isApiError } from "../_types/errorTypes";
import { ModalError } from "../_components/ModalError";
import {
  confirmEmailInput,
  ConfirmEmailMutation,
} from "../__generated__/ConfirmEmailMutation.graphql";
import { AppIcon } from "../_components/AppIcon";

export function ConfirmEmail() {
  const { id, token } = useParams<{ id: string; token: string }>();

  const [didSucceed, setDidSucceed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState<string>("");

  const [commit, isInFlight] = useMutation<ConfirmEmailMutation>(graphql`
    mutation ConfirmEmailMutation($input: confirmEmailInput!) {
      confirmEmail(input: $input) {
        success
        errors {
          __typename
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function handleConfirmEmail(input: confirmEmailInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.confirmEmail) {
          const { success, errors } = response.confirmEmail;
          if (success) {
            setDidSucceed(true);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  React.useEffect(() => {
    handleConfirmEmail({ userId: id, token });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [id, token]);

  return (
    <Wrapper>
      <SafeWrapper>
        <AppIcon />
        <Header>{process.env.REACT_APP_NAME}</Header>
        <SuccessMessage>
          {didSucceed && (
            <p>{`Email confirmed. You may close this browser tab.`}</p>
          )}
        </SuccessMessage>
        <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      </SafeWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
`;

const SafeWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
`;

const Header = styled.header`
  text-align: center;
  font-size: ${20 / 16}rem;
  font-weight: 500;
`;

const SuccessMessage = styled.div`
  font-size: ${20 / 16}rem;
  padding: 32px 16px;
`;
