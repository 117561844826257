import React from "react";
import styled from "styled-components/macro";

import { DebugLog } from "./DebugLog";

// https://stackoverflow.com/questions/45978230/get-version-number-from-package-json-in-react-redux-create-react-app/50822003#50822003
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

export const VersionCopyright: React.FC = () => {
  return (
    <Wrapper>
      <AppVersion>v{REACT_APP_VERSION}</AppVersion>
      <DebugLog>
        <Copyright>
          &copy; 2016 - {new Date().getFullYear()} Grant Kidner
        </Copyright>
      </DebugLog>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* padding: 0px 16px; */
  /* text-align: right; */
  font-size: ${14 / 16}rem;
  color: var(--color-text-medium);
`;
const AppVersion = styled.div``;
const Copyright = styled.div``;
