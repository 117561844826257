import React from "react";
import { scrollToElAnim } from "../_utilities/scroll";

/**
 * Could add option to scroll to *end* of element, e.g. for vertical scroll to "bottom": element.scrollTo(0, element.scrollHeight);
 *
 */

type Props = {
  element: HTMLDivElement | HTMLElement | null;
  axis?: "vert" | "horiz";
  duration?: number;
  resetValArr: (string | number)[];
  // resetCondition?: (val: string | number) => boolean;
};

export function ResetScroll({
  element,
  axis = "vert",
  duration = 200,
  resetValArr,
}: // resetCondition,
Props) {
  let firstRenderRef = React.useRef<boolean>(true);

  React.useLayoutEffect(() => {
    let firstRenderRefCurrent = firstRenderRef.current;
    // do not reset scroll unless at least one render has occurred in which element is non null; two purposes: 1) no point resetting scroll on initial render of page b/c it will already be scrolled to the top; 2) want this component to play nicely with ResetScroll, which *does* need to modify scroll on initial render
    if (element && !firstRenderRefCurrent) {
      // if (!resetCondition || resetCondition(resetValArr)) {
      // webkit momentum scroll (at least in iOS Safari) can interfere with programmatically setting scroll position; temporarily set overflow to "hidden" to stop momentum scroll before setting new scroll position
      // ostensibly could achieve same result by changing -webkit-overflow-scrolling to "auto" (then back to "touch"), but this is probably the cleaner/safer way
      element.style.overflow = "hidden";
      scrollToElAnim(element, 0, duration, axis);
      setTimeout(() => {
        element.style.overflow = "auto";
      }, 0);
      // }
    } else if (element && firstRenderRefCurrent) {
      firstRenderRef.current = false;
    }
  }, [element, ...resetValArr /* resetCondition */]);

  return null;
}
