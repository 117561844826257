// ZERO_WIDTH_SPACE allows line breaks
export const ZERO_WIDTH_SPACE = "\u200B";
export const NON_BREAKING_SPACE = "\u00A0";
export const NARROW_NO_BREAK_SPACE = "\u202F";
// a zero width non-breaking space
export const WORD_JOINER = "\u2060";
export const NON_BREAKING_HYPHEN = "\u2011";

const REPEAT_FLAG = "REPEAT_" + WORD_JOINER;
export const REPEAT_START = REPEAT_FLAG + "START";
export const REPEAT_END = REPEAT_FLAG + "END";

export const ALTERNATE_ENDING_FLAG = "ALT_END_" + WORD_JOINER;

export const REPEAT_TIMES_FLAG = "REPEAT_TIMES_" + WORD_JOINER;

export const ELLIPSIS_SINGLE_CHAR = "…";
