import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { Modal } from "./Modal";
import { AUTH_ERROR } from "../RelayEnvironment";

const REDIRECT_ERRORS = [AUTH_ERROR];

type Props = {
  // *** perhaps this should take an Error instead of a string, akin to ErrorUI
  errMsg: string;
  setErrMsg: React.Dispatch<React.SetStateAction<string>>;
  children?: React.ReactNode;
};

export function ModalError({ errMsg, setErrMsg, children }: Props) {
  errMsg && console.error(errMsg);
  const [doShowDialog, setDoShowDialog] = useState<boolean>(false);

  let history = useHistory();
  function redirectOnAuthError() {
    if (REDIRECT_ERRORS.indexOf(errMsg) > -1) {
      history.push("/logout");
    }
  }
  React.useEffect(() => {
    redirectOnAuthError();
  }, [errMsg]);

  // show modal if non-empty error message
  React.useEffect(() => {
    errMsg !== "" && open();
  }, [errMsg]);

  // on modal close, clear error message
  React.useEffect(() => {
    if (
      doShowDialog === false &&
      // only fire if errMsg is populated to - effectively - ensure this only fires after ModalError has been opened; otherwise will open on first render (b/c doShowDialog is initially false), and - due to setTimeout in clearErr, if an error is caught soon after render (currently, within 500ms) it will be cleared almost as soon as it is shown
      errMsg !== ""
    ) {
      clearErr();
    }
  }, [doShowDialog]);

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  function clearErr() {
    // delay clearing error message to ensure message does not clear before modal has faded out
    setTimeout(() => setErrMsg(""), 500);
  }

  function handleConfirm() {
    close();
  }

  return (
    <Modal
      doShowDialog={doShowDialog}
      closeDialog={close}
      // handleConfirm={handleConfirm}
      label={"Error"}
      actionText={"OK"}
      handleConfirm={close}
      doUseLeastDestructiveRef={true}
      // showFullHeader={true}
    >
      <ModalBody>
        <ErrorMessage>{errMsg}</ErrorMessage>
      </ModalBody>
      {children}
    </Modal>
  );
}

const ModalBody = styled.div`
  padding: 16px;
  /* trial this: at a minimum, useful for printing errors with massive strings, e.g. those returned by GraphQL if ResetPasswordMutation errors (due to GraphQL returning the inputs, which will include the massive token the client sends) */
  overflow: auto;
`;

const ErrorMessage = styled.span`
  font-size: ${18 / 16}rem;
  font-weight: 400;
  /* useful e.g. if server sends an email address in err message ("this song is locked for editing by joe@aol.com"), to prevent y-axis overflow */
  overflow-wrap: anywhere;

  /* preserve line breaks \n and tabs \t in user-entered text */
  white-space: pre-wrap;
  user-select: text;
`;
