/**
 * Returns set of tags found in an array of songs.
 *
 * */

export type SongMeta = {
  readonly id?: string;
  readonly title?: string;
  readonly artist?: string;
  readonly tags: readonly string[];
};

// tagFilter arg. used by UserSongsList to ensure selected tags are always displayed, even when there are no matching songs (i.e. none with selected tags matches the provided search term);
export function aggregateTags(songMetaArray: SongMeta[], tagFilter?: string[]) {
  const tagCounts: Record<string, number> = {};
  const tagSet = songMetaArray.reduce((prev: string[], curr) => {
    for (let tag of curr.tags) {
      tagCounts[tag] = tagCounts[tag] ? tagCounts[tag] + 1 : 1;
      if (prev.indexOf(tag) === -1) {
        prev.push(tag);
      }
    }
    return prev;
  }, []);

  if (tagFilter) {
    for (let tag of tagFilter) {
      if (tagSet.indexOf(tag) === -1) {
        tagSet.push(tag);
      }
    }
  }

  return { tagSet, tagCounts };
}
