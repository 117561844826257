import React from "react";

// assert type argument "T" will extend type "any[]"; declare callbackParams will be of type "T"; b/c "T" is any[], it can be spread as an array as arguments for callback; b/c T is a type argument, the function that consumes it must pass a type value for T; in this case, you want to pass "any[]", as you intend to pass a variable set of arguments for consumption by callback;
export type DelayFncProps<T extends any[]> = {
  callback: ((...args: T) => void) | ((...args: T) => Promise<void>);
  callbackParams?: T;
  delay: number;
};

export function useDelayFnc() {
  const delayTimeout = React.useRef<NodeJS.Timeout | null>(null);

  function delayFnc({
    callback,
    callbackParams = [],
    delay = 1000,
  }: DelayFncProps<any[]>) {
    if (delayTimeout.current !== null) {
      // console.log("useDelayFnc - clearTimeout()");
      clearTimeout(delayTimeout.current);
      delayTimeout.current = null;
    }
    delayTimeout.current = setTimeout(
      (callback, callbackParams) => {
        // console.log("useDelayFnc - firing, params:", callbackParams);
        delayTimeout.current = null;
        callback(...callbackParams);
      },
      delay,
      callback,
      callbackParams
    );
  }

  return { delayFnc };
}
