import React, { HTMLInputTypeAttribute } from "react";
import styled from "styled-components/macro";

import Button, { BUTTON_MIN_HEIGHT } from "./Button";

import { Icon, icons } from "./Icon";
import { TextInput } from "./TextInput";
// import VisuallyHidden from "./VisuallyHidden";

const SIZES = {
  small: {
    "--paddingVert": 4 / 16 + "rem",
    "--borderWidth": 1 + "px",
    // "--fontSize": 16 + "px",
  },
  large: {
    "--paddingVert": 4 / 16 + "rem",
    "--borderWidth": 2 + "px",
    // "--fontSize": 18 + "px",
  },
};

const MODES = {
  background: {
    "--border-bottom-width": 0 + "px",
    "--background-color": "hsl(0,0%,98%)",
    "--border-radius": 12 + "px",
    "--box-shadow": "inset 0px 1px 4px -3px var(--color-shadow)",
    "--box-shadow-focus": "0px 1px 3px -2px var(--color-primary)",
    "--padding-left": 12 + "px",
    "--padding-left-icon": 32 + "px",
    "--padding-right": 12 + "px",
    "--padding-right-clear": 32 + "px",
  },
  border: {
    "--border-bottom-width": 2 + "px",
    "--background-color": "inherit",
    "--border-radius": "none",
    "--box-shadow": "none",
    "--box-shadow-focus": "none",
    "--padding-left": 8 + "px",
    "--padding-left-icon": 32 + "px",
    "--padding-right": 8 + "px",
    "--padding-right-clear": 32 + "px",
  },
};

export type AutoCapitalize = "off" | "sentences" | "words" | "characters";

type Props = {
  value: string;
  onChange: (value: string) => void;
  name?: string;
  label?: string;
  // *** presently, including an icon also "enables" a blur() button
  icon?: keyof typeof icons;
  size?: "small" | "large";
  mode?: "background" | "border";
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  autoCapitalize?: AutoCapitalize;
  autoFocus?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
};

export const TextInputIcon = ({
  value,
  onChange,
  name,
  label,
  icon,
  size = "small",
  mode = "background",
  type = "text",
  placeholder,
  autoCapitalize,
  autoFocus = false,
  disabled,
  allowClear = true,
}: Props) => {
  /*  function handleInput(e: React.ChangeEvent) {
    const value = e.target.value;
    onChange(value);
  } */

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = React.useState(false);

  function handleClear() {
    onChange("");
  }

  function handleFocus() {
    // focus() doesn't work in iOS Safari except via a relevant event handler; in this case, want to focus input after clicking "clear" button, but Safari ignores the focus() request if triggered from a click on that button (which is a sibling of the input); however, Safari respects focus() triggered by the parent label of the input; as this label also wraps the "clear" button, its click event is triggered when clicking the clear button
    inputRef.current?.focus();
  }

  function handleBlur() {
    inputRef.current?.blur();
  }

  var style = {
    ...SIZES[size],
    ...MODES[mode],
  };
  if (!style) {
    throw new Error("Invalid size specified");
  }
  return (
    <InputLabel onClick={handleFocus} style={style as React.CSSProperties}>
      <Wrapper>
        <ComposedTextInput
          ref={inputRef}
          icon={icon}
          allowClear={allowClear}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          autoCapitalize={autoCapitalize}
          autoFocus={autoFocus}
          type={type}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          disabled={disabled}
        />
        {icon && (
          <IconWrapper>
            {isInputFocused ? (
              <Button onClick={handleBlur} fill="ghost" size="xs">
                <BlurButtonContent>
                  <Icon id={"chevron-left"} size={32} strokeWidth={2} />
                </BlurButtonContent>
              </Button>
            ) : (
              <Icon id={icon} size={24} strokeWidth={2} />
            )}
          </IconWrapper>
        )}
        {allowClear && value !== "" && (
          <ClearButtonWrapper>
            <Button onClick={handleClear} fill="ghost" size="xs">
              <Icon id={"x-circle"} size={20} strokeWidth={2} />
            </Button>
          </ClearButtonWrapper>
        )}
      </Wrapper>
      {label && <LabelText>{label}</LabelText>}
    </InputLabel>
  );
};

const InputLabel = styled.label`
  flex: 1;
  color: var(--color-text-medium);

  &:hover,
  &:focus-visible {
    color: var(--color-text);
  }
`;

const LabelText = styled.div`
  padding-left: var(--padding-left);
  color: var(--color-text);
`;

// need a div (or other block element) wrapping the input with position:relative; with the label only, the label doesn't increase in height to match the block size of the input (appears to match only the line height), leaving the position:absolute block elements squished
const Wrapper = styled.div`
  position: relative;
`;

// *** this was an earlier styled component you worked on; would prob. prefer to determine the prop-based styles in the parent component, instead of passing the props into the styled component to calc.;
const ComposedTextInput = styled(TextInput)<{
  icon?: string;
  allowClear?: boolean;
}>`
  /* prevent "clear" button from overflowing by ensuring input never has less height */
  min-height: ${BUTTON_MIN_HEIGHT}px;
  padding-top: var(--paddingVert);
  /* padding-bottom: var(--paddingVert); */
  padding-left: ${({ icon }) =>
    icon ? "var(--padding-left-icon)" : "var(--padding-left)"};

  /* padding-right: ${({ icon }) => (icon ? "var(--paddingHoriz)" : "16px")}; */
  /* padding-right: var(--padding-right); */
  padding-right: ${({ allowClear }) =>
    allowClear ? "var(--padding-right-clear)" : "var(--padding-right)"};
  padding-bottom: 0;

  /* required for box-shadow to appear on an input on iOS Safari  */
  -webkit-appearance: none;
  box-shadow: var(--box-shadow);
  transition: all 0.2s ease-out;

  background-color: var(--background-color);
  /* font-size: var(--fontSize); */
  border-radius: var(--border-radius);
  &:focus-visible {
    outline: none;
    border-bottom: var(--border-bottom-width) solid var(--color-primary-light);
    box-shadow: var(--box-shadow-focus);
    color: var(--color-text);
  }
`;

const InputButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  /* Not necessary, but helpful reminder that intentionally inheriting color */
  /* color: inherit; */
  color: var(--color-text-medium);
`;

const IconWrapper = styled(InputButtonWrapper)`
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClearButtonWrapper = styled(InputButtonWrapper)`
  right: 0px;
  display: flex;
  opacity: 0.5;
`;

const BlurButtonContent = styled.div`
  /* want "back" chevron to keep to left side of button, instead of centering */
  margin-left: -8px;
`;
