import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { SongManageShareSharee_song$key } from "../__generated__/SongManageShareSharee_song.graphql";

import { SongAcceptShare } from "./SongAcceptShare";
import { SongRejectShare } from "./SongRejectShare";
import { Email } from "../_components/Email";
import {
  IndentedListGroup,
  IndentedListItem,
  IndentedListLabel,
} from "../_components/IndentedList";

type Props = {
  songRef: SongManageShareSharee_song$key;
  userId: string;
  callback?: () => void;
};

export function SongManageShareSharee(props: Props) {
  const song = useFragment(
    graphql`
      fragment SongManageShareSharee_song on Song {
        ...SongRejectShare_song
        id
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
        viewerPermission {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const ownerPermission = song.permissions.find((val) => val.isOwner);

  if (!ownerPermission) {
    throw new Error("Permissions should include entry for owning user");
  }

  const ownerEmail = ownerPermission.email;
  const { didAccept, canEdit } = song.viewerPermission;

  return (
    <Wrapper>
      {didAccept === true && (
        <>
          <ShareStatusText>
            <IndentedListGroup>
              <IndentedListLabel>Owner:</IndentedListLabel>
              <IndentedListItem>
                <Email email={ownerEmail} />
              </IndentedListItem>
            </IndentedListGroup>
            You have permission to {canEdit ? "view and edit" : "view only"}.
          </ShareStatusText>
          {/* "eject song", change icon... should you use a different mutation, depending on what you need to return to update local store? */}
          <SongRejectShare
            songRef={song}
            userId={props.userId}
            callback={props.callback}
          />
        </>
      )}
      {didAccept === false && (
        <>
          <ShareStatusText>
            <IndentedListGroup>
              <IndentedListLabel>Shared by:</IndentedListLabel>
              <IndentedListItem>
                <Email email={ownerEmail} />
              </IndentedListItem>
            </IndentedListGroup>
          </ShareStatusText>
          <SongAcceptShare songId={song.id} callback={props.callback} />
          <SongRejectShare
            songRef={song}
            userId={props.userId}
            callback={props.callback}
          />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ShareStatusText = styled.div`
  padding: 0px 18px;
`;

const OwnerEmail = styled.span`
  font-weight: 500;
`;
