import React from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { UpdateBoxIntervalsMutation } from "../__generated__/UpdateBoxIntervalsMutation.graphql";

import { AppContext } from "../_context/appContext";
import {Select} from "../_components/Select";
import { BOX_COUNT } from "../Leitner/leitner";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { CustomErrors } from "../_types/errorTypes";

type Props = {
  boxIntervals: readonly number[];
};

export function UpdateBoxIntervals(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const userId = state.auth.user?.id;
  let [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const [commit, isInFlight] = useMutation<UpdateBoxIntervalsMutation>(graphql`
    mutation UpdateBoxIntervalsMutation($input: updateOwnPrefsInput!) {
      updateOwnPrefs(input: $input) {
        updatedUser {
          id
          boxIntervals
        }
        errors {
          __typename
          # not sure why I can't declare message directly on errors... something to do with the error union type I created in the schema
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function calcBoxIntervals(boxCount: number, maxFrequency: number) {
    var newBoxIntervals = [maxFrequency];
    for (let i = 1; i < boxCount; i++) {
      newBoxIntervals.push(newBoxIntervals[i - 1] * 2);
    }
    return newBoxIntervals;
  }

  function getOptimisticResponse(boxIntervals: number[]) {
    return {
      updateOwnPrefs: {
        updatedUser: {
          id: userId,
          boxIntervals,
        },
        errors: [],
      },
    };
  }

  function handleUpdate(maxFrequency: number) {
    const newBoxIntervals = calcBoxIntervals(BOX_COUNT, maxFrequency);
    commit({
      variables: {
        input: {
          boxIntervals: newBoxIntervals,
        },
      },
      onCompleted: (response) => {
        if (response.updateOwnPrefs) {
          const { updatedUser, errors } = response.updateOwnPrefs;
          if (updatedUser) {
          }
          if (errors.length > 0) {
            setFriendlyErrors(errors);
          }
        }
      },
      onError: () => null,
      optimisticResponse: getOptimisticResponse(newBoxIntervals),
    });
  }

  const MAX_FREQUENCY_OPTIONS = [1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <Select
        name="boxIntervals"
        label="Max review frequency"
        onChange={(e) => handleUpdate(parseInt(e.target.value))}
        value={props.boxIntervals[0]}
      >
        {MAX_FREQUENCY_OPTIONS.map((val) => {
          return (
            <option value={val} key={val}>
              Every {val} day{val === 1 ? "" : "s"}
            </option>
          );
        })}
      </Select>
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </>
  );
}
