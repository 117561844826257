import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { SongMetaQueryQuery } from "../__generated__/SongMetaQueryQuery.graphql";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import UpdateSongMeta from "./UpdateSongMeta";
import { DeleteSong } from "./DeleteSong";
// import Spacer from "../_components/Spacer";
// import UpdateSongTags from "./UpdateSongTags";
import { QueryOptions } from "../_types/relayTypes";
import UpdateSongNotes from "./UpdateSongNotes";
import { useHistory } from "react-router";
import { UnlockSong } from "./UnlockSong";
// import { UpgradeFeature } from "../InAppPurchase/UpgradeFeature";
// import { SongManageShare } from "./SongManageShare";
import UpdateSongTimeSig from "./UpdateSongTimeSig";

type Props = {
  songId: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  queryOptions: QueryOptions;
};

export function SongMetaQuery(props: Props) {
  const history = useHistory();

  const data = useLazyLoadQuery<SongMetaQueryQuery>(
    graphql`
      query SongMetaQueryQuery($id: ID!) {
        song(id: $id) {
          song {
            id
            title
            viewerPermission {
              isOwner
            }
            ...UpdateSongMeta_song
            ...UpdateSongNotes_song
            ...UpdateSongTimeSig_song
            ...UpdateSongTags_song
            ...UnlockSong_song
            ...DeleteSong_song
          }
          errors {
            __typename
            message
          }
        }
        viewer {
          user {
            id
            ...UpdateSongTags_tags
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { id: props.songId },
    props.queryOptions
  );

  const songDataWithErrors = data.song;
  if (!songDataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const viewerDataWithErrors = data.viewer;
  if (!viewerDataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors: viewerErrors } = viewerDataWithErrors;
  const { song, errors: songErrors } = songDataWithErrors;

  React.useEffect(() => {
    if (song) {
      props.setTitle(song.title);
    }
  }, [song]);

  return (
    <>
      {/* data result */}
      {song && user && (
        <>
          {song.viewerPermission.isOwner === true && (
            <UpdateSongMeta song={song} />
          )}
          <UpdateSongNotes song={song} />
          <UpdateSongTimeSig song={song} />
          {/* <UpdateSongTags song={song} user={user} /> */}
          <UnlockSong songRef={song} />
          {song.viewerPermission.isOwner === true && (
            <>
              {/* want to include share management in SongMeta at some point... but not bothered to go through wiring it at the moment... below is a start */}
              {/*               <UpgradeFeature
              productGroupName={"pro"}
              featureName="Sharing"
              lockIconOffset={[8, 8]}
              lockIconOffsetUnit="px"
            >
              <SongManageShare
                songRef={song}
                // userId={user.id}
                shareRelations={shareRelations}
              />
            </UpgradeFeature> */}
              <DeleteSong
                songRef={song}
                userId={user.id}
                callback={() => history.push("/user-songs")}
              />
            </>
          )}
        </>
      )}

      {/* null result */}
      {(!song || !user) && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {songErrors.length > 0 && <FriendlyErrors errors={songErrors} />}
      {viewerErrors.length > 0 && <FriendlyErrors errors={viewerErrors} />}
    </>
  );
}
