import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import styled from "styled-components/macro";

import { SongBody_song$key } from "../__generated__/SongBody_song.graphql";
import { typeNotEmpty } from "../_types/typeHelpers";
import { getNodeArrayFromEdges } from "../_types/graphqlData";

import { DisplaySection } from "./DisplaySection";
import { Empty } from "./DisplaySectionMeta";
import { TransposeAccidentalType } from "../_components/Transpose";
import { AppContext } from "../_context/appContext";

type Props = {
  songRef: SongBody_song$key;
  transposeVal: number;
  accidentalsVal: TransposeAccidentalType;
};

export function SongBody(props: Props) {
  const song = useFragment(
    graphql`
      fragment SongBody_song on Song {
        ...DisplaySection_song
        sections(first: 2147483647) @connection(key: "MoveSection_sections") {
          edges {
            node {
              ...DisplaySection_section
              id
              name
              notes
              timeSig
              chords {
                id
                duration
                rhythm
                definition {
                  root
                  qual
                  bass
                }
                notation
              }
            }
          }
        }
      }
    `,
    props.songRef
  );

  const { state, dispatch } = React.useContext(AppContext);

  const { sections } = song;

  const sectionNodes = getNodeArrayFromEdges(sections).filter(typeNotEmpty);

  return (
    <>
      <Wrapper>
        {sectionNodes.length > 0 ? (
          <>
            {sectionNodes.map((node) => {
              // if (!node) return null;
              return (
                <DisplaySection
                  key={node.id}
                  songRef={song}
                  sectionRef={node}
                  transposeVal={props.transposeVal}
                  accidentalsVal={props.accidentalsVal}
                  rhythmDisplay={state.app.rhythmDisplay}
                />
              );
            })}
          </>
        ) : (
          <Empty>[No sections]</Empty>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div``;
