export const TEST_EVENT_TYPE_OBJ = {
  remembered: "REMEMBERED",
  forgot: "FORGOT",
  reviewed: "REVIEWED",
};

/**
 * 
 * Below code must be kept in-sync b/w server and browser, to ensure consistent results
 * 
 */

const DEFAULT_DATE_STRING = new Date(0).toISOString();
const BOX_COUNT = 5;
const MAX_RECENT_HISTORY = 15;

function getLastTestResult(history) {
  const historyLen = history.length;
  for (let i = historyLen - 1; i >= 0; i--) {
    var event = history[i];
    if (event.eventType === TEST_EVENT_TYPE_OBJ.remembered)
      return { lastViewDidRemember: true, timeLastTested: event.time };
    if (event.eventType === TEST_EVENT_TYPE_OBJ.forgot)
      return { lastViewDidRemember: false, timeLastTested: event.time };
  }
  return {
    lastViewDidRemember: null,
    timeLastTested: DEFAULT_DATE_STRING,
  };
}

function getTimeLastReviewed(history) {
  const historyLen = history.length;
  for (let i = historyLen - 1; i >= 0; i--) {
    var event = history[i];
    if (event.eventType === TEST_EVENT_TYPE_OBJ.reviewed) {
      return event.time;
    }
  }
  return DEFAULT_DATE_STRING;
}

/* Perhaps more motivating to consider only most recent X history events; in other words, start "fresh" with those most recent - don't consider prior history at all.  Otherwise, over time, graph will flatten out and testing done months/years earlier will be reflected even though it likely has little effect on person's current memory */
function getRecallRateHistoryRecent(history) {
  var timesTested = 0,
    timesRemembered = 0,
    recallRateHistoryRecent = [],
    event;
  const historyLen = history.length,
    start = Math.max(0, historyLen - MAX_RECENT_HISTORY);
  for (let i = start; i < historyLen; i++) {
    event = history[i];
    switch (event.eventType) {
      case TEST_EVENT_TYPE_OBJ.remembered:
        timesRemembered++;
      // falls through
      case TEST_EVENT_TYPE_OBJ.forgot:
        timesTested++;
        recallRateHistoryRecent.push(timesRemembered / timesTested);
        break;
      default:
        break;
    }
  }
  return recallRateHistoryRecent;
}

export function getSongStats(history) {
  var timesTested = 0,
    timesRemembered = 0,
    recallRateHistory = [],
    boxIndex = 0,
    event,
    priorEvent;

  // stats that require looping through entire history
  for (let i = 0; i < history.length; i++) {
    event = history[i];
    switch (event.eventType) {
      case TEST_EVENT_TYPE_OBJ.remembered:
        timesRemembered++;
      // falls through
      case TEST_EVENT_TYPE_OBJ.forgot:
        timesTested++;
        recallRateHistory.push(timesRemembered / timesTested);
        break;
      default:
        break;
    }
    // start at i=1 b/c unable to "review" before "remembering" or "forgetting"; and to avoid TypeError from attempting to check array at position [-1] (priorEvent)
    // important to change box assignment only after been reviewed, or may cease to be "due" immediately after testing, and thus disappear from deck before review
    if (i > 0 && event.eventType === TEST_EVENT_TYPE_OBJ.reviewed) {
      priorEvent = history[i - 1];
      if (priorEvent.eventType === TEST_EVENT_TYPE_OBJ.remembered) {
        // 0-based index
        boxIndex = Math.min(BOX_COUNT - 1, boxIndex + 1);
      } else if (priorEvent.eventType === TEST_EVENT_TYPE_OBJ.forgot) {
        // original Leitner system: forgetting moves song "down" to box 0
        boxIndex = 0;
      }
    }
  }
  // return "0" if untested; rate is decimal, not percent
  const recallRate = timesTested > 0 ? timesRemembered / timesTested : 0;

  // stats that require recent history only
  const { lastViewDidRemember, timeLastTested } = getLastTestResult(history);
  const timeLastReviewed = getTimeLastReviewed(history);
  const recallRateHistoryRecent = getRecallRateHistoryRecent(history);

  return {
    boxIndex,
    timesTested,
    timesRemembered,
    recallRate,
    recallRateHistory,
    recallRateHistoryRecent,
    lastViewDidRemember,
    timeLastTested,
    timeLastReviewed,
  };
}
