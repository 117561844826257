import React from "react";

import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers";

const DEBUG_LOG_TYPE = {
  render: "render",
} as const;

type DebugLogType = typeof DEBUG_LOG_TYPE[keyof typeof DEBUG_LOG_TYPE];

const SUPPRESS_RENDER_LOGS = false;

export function useDebugLog(source?: string) {
  const { state, dispatch } = React.useContext(AppContext);

  function setDebugLog(content: any[], logType?: DebugLogType) {
    if (logType === DEBUG_LOG_TYPE.render && SUPPRESS_RENDER_LOGS) {
      return;
    }
    if (process.env.NODE_ENV !== "production") {
      content.forEach((val) => {
        if (typeof val === "object") {
          console.log(`${source}:`);
          console.table(val);
        } else {
          console.log(`${source}: ${val}`);
        }
      });
    }
    if (state.app.isInDebugMode) {
      // prevent potential performance impact of setDebugLog() dispatching events in background when debugMode isn't active;

      // clone objects so you can see historical values of objects in your logs (otherwise older logs will update to current vals, b/c they maintain reference)
      const contentClone = content.map((val) => {
        if (typeof val === "object") {
          // *** cutting edge: https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
          // return structuredClone(val);
          return JSON.parse(JSON.stringify(val));
        } else {
          return val;
        }
      });
      dispatch({
        type: AppActionTypes.SetDebugLog,
        payload: { content: contentClone, source },
      });
    }
  }

  return { setDebugLog };
}
