import React from "react";
import styled from "styled-components/macro";

import { LogoutUser } from "../_components/LogoutUser";
import { Page, PageHeader, PageContent } from "../_components/Page";
import { AccountQuery } from "./AccountQuery";

export const Account: React.FC = () => {
  return (
    <Page>
      <PageHeader title={"Account"} backPath={`/settings`} />
      <PageContent
        alwaysVisible={
          <>
            <LogoutUser />
          </>
        }
      >
        {(queryOptions) => {
          return <AccountQuery queryOptions={queryOptions} />;
        }}
      </PageContent>
    </Page>
  );
};

const AppVersion = styled.div`
  padding: 0px 16px;
  text-align: right;
  font-size: ${14 / 16}rem;
`;
