import React from "react";
import styled, { css } from "styled-components/macro";

import Button from "../../../_components/Button";

import { getDurationStrings } from "../../DurationSlider";

export function ShowTappedBeatTotal({
  tappedBeatTotal,
}: {
  tappedBeatTotal: number;
}) {
  const durationStrings = getDurationStrings(tappedBeatTotal);
  return (
    <TappedBeatTotal
      style={
        {
          "--tapped-beat-color":
            tappedBeatTotal > 0
              ? "var(--color-text)"
              : "var(--color-text-medium)",
        } as React.CSSProperties
      }
    >
      {tappedBeatTotal > 0 ? (
        <>
          {durationStrings.wholeNum}
          {durationStrings.fraction}
        </>
      ) : (
        <div>-</div>
      )}
      <TappedBeatTotalLabel>beats</TappedBeatTotalLabel>
    </TappedBeatTotal>
  );
}

export const TapGroupWrapper = styled.div`
  padding: 0px 12px 8px 12px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  /* justify-content: flex-start; */
  /* gap: 16px; */
`;

export const TapButtonRow = styled.div`
  min-height: 56px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const TapGroupInstructions = styled.div`
  font-size: ${18 / 16}rem;
  text-align: left;
  color: var(--color-text-medium);
`;

export const gKeyboardLikeStyles = css`
  min-height: 44px;
  min-width: 44px;
  padding: 4px;
  border-radius: 6px;
  transition: background-color 0.1s;
  box-shadow: 0 1px 1px var(--g-keyboard-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--g-keyboard-key);
`;

export const ResetButton = styled(Button)`
  ${gKeyboardLikeStyles}
`;

export const ResetButtonWrapper = styled.div`
  flex: 0 0 auto;
`;

export const TappedBeatTotal = styled.div`
  min-width: 64px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  font-size: ${20 / 16}rem;
  color: var(--tapped-beat-color);
`;

export const TappedBeatTotalLabel = styled.label`
  font-size: ${13 / 16}rem;
`;

export const ScaleButton = styled(Button)`
  ${gKeyboardLikeStyles}
`;

export const ScaleButtonText = styled.div`
  font-size: ${18 / 16}rem;
`;
