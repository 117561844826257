import { SplashScreen } from "@capacitor/splash-screen";

export function hideSplashScreen() {
  // ensure splashscreen sits for at least 500ms, so it's not merely a jarring flash on the screen
  // also gives app time to make initial query
  setTimeout(() => {
    SplashScreen.hide();
  }, 500);
}

export const Splash = {
  hide: () => SplashScreen.hide(),
};
