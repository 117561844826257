import React from "react";
import styled from "styled-components/macro";

import { SideTabs, TabItem } from "../../../_components/SideTabs";
import { InputTabWrapper } from "../InputTabWrapper";
import { ChordOrCursor } from "../../../_types";
import { TapDuration } from "./TapDuration";
import { TapScale } from "./TapScale";
import { useTap } from "../../../_context/chordContext";
import type { ChordInputMode } from "../../../_reducers";
import { TapManual } from "./TapManual";

function addTransparencyByIndex(i: number) {
  // add 1 to indext to ensure even item at index 0 gets some transparency
  return `hsl(0 0% var(--color-tint-lightness) / ${(i + 1) * 0.01})`;
}

type Props = {
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
};

export function TapInputGroups(props: Props) {
  const { setInputMode } = useTap({
    callBackAfterUpdateChords: props.handleUpdateSectionChords,
  });

  type TabsObj = {
    [K in ChordInputMode]: React.ReactNode;
  };

  // const tabsMap = new Map<ChordInputMode, React.ReactNode>([
  //   [
  //     "tap",
  //     <TapDuration
  //       handleUpdateSectionChords={props.handleUpdateSectionChords}
  //     />,
  //   ],
  //   [
  //     "scale",
  //     <TapScale handleUpdateSectionChords={props.handleUpdateSectionChords} />,
  //   ],
  //   [
  //     "manual",
  //     <TapManual handleUpdateSectionChords={props.handleUpdateSectionChords} />,
  //   ],
  // ]);

  const tabsObj: TabsObj = {
    tap: (
      <TapDuration
        handleUpdateSectionChords={props.handleUpdateSectionChords}
      />
    ),
    scale: (
      <TapScale handleUpdateSectionChords={props.handleUpdateSectionChords} />
    ),
    manual: (
      <TapManual handleUpdateSectionChords={props.handleUpdateSectionChords} />
    ),
  };

  const tabItems: TabItem[] = [
    {
      id: "tap",
      label: "tap",
    },
  /*   {
      id: "scale",
      label: "scale",
    }, */
    {
      id: "manual",
      label: "slide",
    },
  ];

  const DEFAULT_INPUT_TAB: ChordInputMode = "tap";

  const [selectedTab, setSelectedTab] =
    React.useState<ChordInputMode>(DEFAULT_INPUT_TAB);

  React.useEffect(() => {
    setInputMode(DEFAULT_INPUT_TAB);
  }, []);

  const TAB_WIDTH = 52;
  const POSITION_LEFT = 8;

  return (
    <Wrapper
      style={
        {
          "--padding-left": `${TAB_WIDTH + POSITION_LEFT}px`,
        } as React.CSSProperties
      }
    >
      <SideTabs
        groupId={"TabInputGroups"}
        // items={[...tabsMap].map(([val, key], i) => {
        items={tabItems.map((val, i) => {
          return {
            id: val.id,
            label: val.label,
            // if tab selected, want it to be same color as chord quality background, so remove color from tab;
            background:
              selectedTab === val.id ? "none" : addTransparencyByIndex(i),
            callback: () => setInputMode(val.id as ChordInputMode),
          };
        })}
        selectedTab={selectedTab}
        handleSetSelectedTab={(id) => setSelectedTab(id as ChordInputMode)}
        wrapperStyles={{
          tabWidth: TAB_WIDTH,
          positionLeft: POSITION_LEFT,
        }}
      />
      <InputTabWrapper tabsObj={tabsObj} selectedTab={selectedTab} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  padding-left: var(--padding-left);
  background-color: var(--background);
  /* b/c you render InputTabWrapper as a child, which has flex:1; without display:flex; all children collapse to 0 height... meaning your touches *near* the keys weren't actually hitting the keyboard (b/c it was only showing due to overflow) and thus you couldn't touchstart on keyboard then drag to key */
  display: flex;
`;
