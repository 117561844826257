/**
 * @generated SignedSource<<2f4765a16f1bd5b77a1cc6077c9c39ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongMetaQueryQuery$variables = {
  id: string;
};
export type SongMetaQueryQuery$data = {
  readonly song: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NotFound";
      readonly message: string;
    }>;
    readonly song: {
      readonly id: string;
      readonly title: string;
      readonly viewerPermission: {
        readonly isOwner: boolean;
      };
      readonly " $fragmentSpreads": FragmentRefs<"DeleteSong_song" | "UnlockSong_song" | "UpdateSongMeta_song" | "UpdateSongNotes_song" | "UpdateSongTags_song" | "UpdateSongTimeSig_song">;
    } | null;
  } | null;
  readonly viewer: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly user: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSongTags_tags">;
    } | null;
  } | null;
};
export type SongMetaQueryQuery = {
  response: SongMetaQueryQuery$data;
  variables: SongMetaQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOwner",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SongPermission",
  "kind": "LinkedField",
  "name": "viewerPermission",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "NotFound",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongMetaQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongMeta_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongNotes_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongTimeSig_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongTags_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnlockSong_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeleteSong_song"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryResult",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongTags_tags"
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongMetaQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "artist",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLockedByUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "editLock",
                "kind": "LinkedField",
                "name": "editLock",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedByEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedUntil",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeSig",
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongPermission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canEdit",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "didAccept",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryResult",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserSongsConnection",
                "kind": "LinkedField",
                "name": "songs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSongsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Song",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2b289bfd5682febffc60914f4c02cbd",
    "id": null,
    "metadata": {},
    "name": "SongMetaQueryQuery",
    "operationKind": "query",
    "text": "query SongMetaQueryQuery(\n  $id: ID!\n) {\n  song(id: $id) {\n    song {\n      id\n      title\n      viewerPermission {\n        isOwner\n      }\n      ...UpdateSongMeta_song\n      ...UpdateSongNotes_song\n      ...UpdateSongTimeSig_song\n      ...UpdateSongTags_song\n      ...UnlockSong_song\n      ...DeleteSong_song\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n  viewer {\n    user {\n      id\n      ...UpdateSongTags_tags\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment DeleteSong_song on Song {\n  id\n  title\n  artist\n  mongoVersion\n  permissions {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n\nfragment UnlockSong_song on Song {\n  id\n  permissions {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n  editLock {\n    isLocked\n    lockedBy\n    lockedByEmail\n    lockedUntil\n  }\n  isLockedByUser\n}\n\nfragment UpdateSongMeta_song on Song {\n  id\n  mongoVersion\n  title\n  artist\n  isLockedByUser\n  editLock {\n    isLocked\n    lockedBy\n    lockedByEmail\n    lockedUntil\n  }\n}\n\nfragment UpdateSongNotes_song on Song {\n  id\n  mongoVersion\n  notes\n}\n\nfragment UpdateSongTags_song on Song {\n  id\n  mongoVersion\n  tags\n}\n\nfragment UpdateSongTags_tags on User {\n  songs {\n    edges {\n      node {\n        tags\n        id\n      }\n    }\n  }\n}\n\nfragment UpdateSongTimeSig_song on Song {\n  id\n  mongoVersion\n  timeSig\n}\n"
  }
};
})();

(node as any).hash = "0514f0ba6d3146407877762aac8fc0ca";

export default node;
