import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import { SortableItem } from "./SortableItem";
import { Modal } from "../../_components/Modal";
import Spacer from "../../_components/Spacer";
import { DeleteSection } from "../DeleteSection";
import { CloneSection } from "../CloneSection";
import Button from "../../_components/Button";
import { SectionListItem } from "./SectionListItem";
import {
  SectionList_song$data,
  SectionList_song$key,
} from "../../__generated__/SectionList_song.graphql";
import { getNodeArrayFromEdges } from "../../_types/graphqlData";
import { typeNotEmpty } from "../../_types/typeHelpers";
import { ArrayElement } from "../../_types";
import { ModalError } from "../../_components/ModalError";
import { ModalHeader } from "../../_style-components/Modal.style";

type SectionEdge = NonNullable<
  ArrayElement<NonNullable<SectionList_song$data["sections"]["edges"]>>
>;
type SectionNode = SectionEdge["node"];
type SectionNodeMap = Map<string, SectionNode>;

type Props = {
  songRef: SectionList_song$key;
  doAllowReorder: boolean;
  activeId: string | null;
};

export function SectionList(props: Props) {
  const history = useHistory();

  const song = useFragment(
    graphql`
      fragment SectionList_song on Song {
        ...DeleteSection_song
        ...CloneSection_song
        id
        mongoVersion
        sections(first: 2147483647) @connection(key: "MoveSection_sections") {
          edges {
            node {
              ...DeleteSection_section
              id
              name
              notes
              chords {
                id
                duration
                rhythm
                definition {
                  root
                  qual
                  bass
                }
                notation
              }
            }
          }
        }
      }
    `,
    props.songRef
  );

  const sectionNodes = getNodeArrayFromEdges(song.sections).filter(
    typeNotEmpty
  );

  const sectionNodeMap: SectionNodeMap = new Map();
  sectionNodes.forEach((val) => sectionNodeMap.set(val.id, val));

  const DEFAULT_DIALOG_DATA = {
    doShowDialog: false,
    id: "",
    name: "",
  };
  const [doShowDialogWithData, setDoShowDialogWithData] = React.useState<{
    doShowDialog: boolean;
    id: string;
    name: string;
  }>(DEFAULT_DIALOG_DATA);

  function openDialog(id: string, name: string) {
    setDoShowDialogWithData({ doShowDialog: true, id, name });
  }

  function closeDialog() {
    setDoShowDialogWithData((prev) => ({ ...prev, doShowDialog: false }));
  }

  const nodeForModal = sectionNodeMap.get(doShowDialogWithData.id);

  const [errMsg, setErrMsg] = React.useState<string>("");

  function handleError(errMsg: string) {
    setErrMsg(errMsg);
  }

  return (
    <>
      {sectionNodes.map((node) => {
        return (
          <SortableItem
            key={node.id}
            id={node.id}
            doAllowReorder={props.doAllowReorder}
            isDragging={props.activeId === node.id}
          >
            <SectionListItem
              songId={song.id}
              id={node.id}
              sectionName={node.name}
              openDialog={() => openDialog(node.id, node.name)}
              doAllowReorder={props.doAllowReorder}
            />
          </SortableItem>
        );
      })}
      <Modal
        doShowDialog={doShowDialogWithData.doShowDialog}
        closeDialog={closeDialog}
        label={`Options for ${doShowDialogWithData.name}`}
      >
        <ModalBody>
          <ModalHeader>
            <SectionName>{doShowDialogWithData.name}</SectionName>
          </ModalHeader>
          <Spacer axis="vert" size={8} />
          <Button
            onClick={() =>
              history.push(`/section/${song.id}/${doShowDialogWithData.id}`)
            }
            palette="primary"
            fill="clear"
            iconId="edit"
          >
            Edit Section
          </Button>
          <CloneSection
            songRef={song}
            sectionId={doShowDialogWithData.id}
            callback={closeDialog}
            callBackOnError={handleError}
          />
          {nodeForModal && (
            <DeleteSection
              songRef={song}
              sectionRef={nodeForModal}
              // songId={props.songId}
              // sectionId={doShowDialogWithData.id}
              // name={doShowDialogWithData.name}
              // *** would like to dismiss parent modal when open delete modal... but of course won't work b/c parent contains delete and would destroy it; only way for this to work would be to spin delete modal out of here, and separately control its state, but not presently worthwhile
              // handleOnModalOpen={closeDialog}
              handleOnModalClose={closeDialog}
              callBackOnError={handleError}
            />
          )}
          {/*  <Button onClick={closeDialog} fill="ghost" iconId="x">
            Cancel
          </Button> */}
        </ModalBody>
      </Modal>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const ModalBody = styled.div``;

const SectionName = styled.div`
  font-size: ${18 / 16}rem;
  /* padding: 0 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-shadow); */
`;
