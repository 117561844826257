/**
 * @generated SignedSource<<404f156c66fe28304e8a3fb215b659d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSectionChordsMutation_section$data = {
  readonly chords: ReadonlyArray<{
    readonly definition: {
      readonly bass: string;
      readonly qual: string;
      readonly root: string;
    };
    readonly duration: number;
    readonly id: string;
    readonly notation: ReadonlyArray<string>;
    readonly rhythm: ReadonlyArray<number>;
  }>;
  readonly id: string;
  readonly rhythmUnit: number;
  readonly " $fragmentType": "UpdateSectionChordsMutation_section";
};
export type UpdateSectionChordsMutation_section$key = {
  readonly " $data"?: UpdateSectionChordsMutation_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionChordsMutation_section">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSectionChordsMutation_section",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rhythmUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Chord",
      "kind": "LinkedField",
      "name": "chords",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rhythm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChordDef",
          "kind": "LinkedField",
          "name": "definition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "root",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qual",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bass",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SongSection",
  "abstractKey": null
};
})();

(node as any).hash = "d90371cf68b353c3c1fc99451ec0df00";

export default node;
