import React from "react";
import { useDelayFnc } from "./useDelayFnc";

// main outstanding weakness: there is no time restriction on firing of the "queued" mutation; e.g. if user lets the first ("instant") mutation fire, then provides nonstop input, it will be a non-stop cycle of: mutation 1 in flight; mutation 2 queued; mutation 1 finishes, instantly firing mutation 2; mutation 3 queued; mutation 2 finishes, instantly firing mutation 3...

const DEFAULT_DELAY_MS = 1000;

export type LocalState<T extends any> = {
  setLocalState: (data: T) => void | React.Dispatch<React.SetStateAction<T>>;
  serverStateData: T;
};

type Props<T extends any> = {
  callback: (...args: any[]) => void;
  isInFlight: boolean;
  defaultDelayMs?: number;
  localState?: LocalState<T>;
};

export function useBatchQueue<T extends any>(props: Props<T>) {
  const mutationWasCommitted = React.useRef<boolean>(false);
  const batchQueueParams = React.useRef<any[] | null>(null);

  const { delayFnc } = useDelayFnc();

  function handleCallbackAndReset(...args: any) {
    props.callback(...args);
  }

  React.useEffect(() => {
    if (props.isInFlight === true) {
      // only set to true when isInFlight changes to true; if set in a way diconnected from isInFlight (e.g. in body of handleCallbackAndReset before calling props.callback()) possible for mutationWasCommitted to be stuck at "true" if, e.g. the callback errors-out before calling commit;
      // this hook assumes when callback is called, it will result in a mutation being committed, and thus will result in isInFlight toggling to true, and then to false; if the callback can be interrupted (e.g. your AddPurchase component had its own test for isInFlight that would prevent a commit if isInFlight===true), the hook is permanently disabled, b/c 1) mutationWasCommitted.current===true; 2) batchQueue will never try to commit unless mutationWasCommitted.current===false; 3) and mutationWasCommitted is reset only in a useEffect that is triggered only by a change to isInFlight... which will never occur because committing is no longer possible;
      mutationWasCommitted.current = true;
      batchQueueParams.current = null;
    } else {
      if (batchQueueParams.current === null) {
        // no mutation in flight; no values in the queue;
        mutationWasCommitted.current = false;
      } else {
        // console.log("@@@ Commit from batch");
        handleCallbackAndReset(...batchQueueParams.current);
        // *** you wanted this to work, but won't due to current flow for setting mutationWasCommitted; if try batchQueue here, possible you'll be stuck in limbo: mutationWasCommitted is likely "true" at this point, which means instead of calling delayFnc, you'll update batchQueueParams; then, because no commit was triggered, isInFlight won't change, and thus this useEffect code won't run again;
        // batchQueue({ callbackParams: batchQueueParams.current }); perhaps could call delayFnc directly here, but for now... this works fine, and generally like idea of batching a group of changes based on what was attempted while the last commit was in flight (which is what this does) vs applying another arbitrary timeframe for batching;
      }
    }
  }, [props.isInFlight]);

  /* React.useEffect(() => {
    console.log(
      ">>>>>>>>>>>>>>>>>>>>>>>>>serverStateData changed",
      mutationWasCommitted.current
    );
    if (mutationWasCommitted.current === false) {
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>update local state with server state");
      // update local state with server state
      localState?.setLocalState(localState?.serverStateData);
    } else {
      // update pending; ignore changes
    }
  }, [localState?.serverStateData]); */

  function batchQueue({
    callbackParams = [],
    delay,
  }: {
    callbackParams: any[];
    delay?: number;
  }) {
    /*   console.log(
      mutationWasCommitted.current,
      props.isInFlight,
      batchQueueParams.current
    ); */
    if (mutationWasCommitted.current === false) {
      // console.log("@@@ Immediate commit");
      // handleCallbackAndReset(...callbackParams);
      delayFnc({
        callback: handleCallbackAndReset,
        callbackParams,
        delay: delay ?? props.defaultDelayMs ?? DEFAULT_DELAY_MS,
      });
    } else {
      // console.log("@@@ Added to batchQueue");
      batchQueueParams.current = callbackParams;
    }
  }

  return {
    batchQueue /*  mutationWasCommitted: mutationWasCommitted.current */,
  };
}

/* 
  Outstanding concerns:
  - this code only handles one mutation at a time... if e.g. edit tags then edit section order, can result in err from your custom version checking; this is actually made worse by your time-based batching, b/c it means there's sufficient delay (e.g. after editing tags) for user to start editing section order *before* the tag-editing request fires, allowing them to arrive at the server simultaneously (of course, right now don't have that batching applied to section order... which should practically fix this issue)
  
  - handleCallbackAndReset() will fire (if batchQueueParams is populated) as soon as isInFlight===false following prior commit, even if that commit ended in an error instead of success
  -- this could mean user gets two errs in a row, or doesn't have a chance to read the first err if it's overwritten by the second err;
  -- would need to listen to onComplete vs onError to handle this concern;

  - do I want a minimum delay/buffer/debounce/throttle time for the queued callback?  Right now, you explicitly set the delay for the "first" batch of inputs, but the queued batch fires immediately upon completion of the first; could use setTimeout and timestamps to enforce a minimum delay for this queued callback;

  - Curious: could this be simplified?  I'm constantly updating a state variable anyway, so (instead of the "batchQueue") why couldn’t I just check the state variable against the latest from server on every onComplete from a commit call, and if not in sync fire a new commit with the current state of state? ...thinking about this at a later date: yes, this is essentially the same thing this fnc does (batchQueueParams will match the changed state, and will fire when commit is done via the useEffect listening to isInFlight); however, this solution allows you to keep everything under one roof, instead of having to include separate code within onCompleted (and possibly onError) callbacks of commit();
  -- answer: remember the code in onComplete will be run with values from the *same* render in which it was triggered, so when server returns the updated data, onComplete will still see the pre-input state, meaning they won't match up...

  - And curious: instead of applying this same logic in multiple components could/should I apply similar at the RelayEnvironment / fetchRelay level so 1) wouldn't have to apply it individually, and 2) so it could "batch" similar mutations, e.g. all mutations affecting the same songId (to insure against version errors)

  *** Notes on how this does (and does not) work:
      // the components that use this load relay data into state on mount, and that state is the "source of truth" for that component, allowing updates to be made instantly *without* requiring a mutation to be triggered every time (using relay's optimistic update); thus, if want to update state due to changes in data from network, e.g. when use pull-to-refresh, you need to watch for changes; however, you don't need or want to update when a mutation is still pending; that is the purpose of the useEffect that watches for changes to serverStateData;
  - mutation's optimisticResponse:
   // despite the benefits of useBatchQueue (some duplicative of optimisticResponse), optimisticResponse is helpful; if nothing else: it can help provide (optimistically) accurate updates for mongoVersion, to ensure you aren't firing a mutation with an old mongoVersion; this timing has been difficult to get right to ensure mutations only fired after data has been updated following mutation, esp. for the first "immediate" fire after a "batchQueue"; I had tried  gatekeeping the "immediate" fire with props.isInFlight===false, but that would result in a race condition and sometimes a user's input would be put in the "queue" after the last isInFlight returned false, meaning it would never be fired to server, even though the UI reflected the change;
   - be careful about order, e.g. in handleCallbackAndReset:
   // order important here; if call callback() first, b/c it is [no longer] in same function, it will execute before changes below, and so will trigger mutation, which will trigger change to isInFlight, which will trigger useEffect, which will fire another delayFnc with the same params... b/c lastCallbackParams won't have cleared yet;

  - in batchQueue, you decide whether to fire "immediately" or add to queue based on (mutationWasCommitted.current === false); you also experimented with testing isInFlight===false; doing so ensures you do not fire a commit using stale data (namely the old mongoVersion) 
  --(or if using optimistic response, also possible to advance the version too far if you optimistically updated mongoVersion, but server didn't, either due to a server error that prevented the song from being updated, or - as you learned the hard way - if your batch/queued change is the same as what is already on the server (e.g. b/c you tapped a tag twice quickly meaning consecutive updates reflect the same tag state), mongoose won't increment a doc version (__v) on .save() if the document hasn't changed) I don't see this documented anywhere, and you'd expect a complete replacement of an array (as I do for tags, and elsewhere) would be sufficient to trigger a version update; however, mongoose must perform a deep equality check and version only when it has changed... seems unnecessary and expensive, esp. for an undocumented behavior;
  -- downside of testing isInFlight here: occasionally the timing of a user input will leave an update in limbo, as it will be added to batchQueueTags after the the last isInFlight has returned to false, and so it will remain there unfilfilled, contrary to what the user sees, as the UI does reflect the change; this was a worse outcome
  -- further, if you didn't clear that "in limbo" queued data, when you next fired handleCallbackAndReset, the next user input would result in the desired mutation... followed by the queued limbo'd value, which will set to the limbo'd, now incorrect, state;
*/
