import React from "react";

import { AppContext } from "../_context/appContext";
import Button from "../_components/Button";
import { AppActionTypes } from "../_reducers";

export function ToggleShowTranspose() {
  const { state, dispatch } = React.useContext(AppContext);
  const { doShowTranspose } = state.app;

  function handleClick() {
    dispatch({
      type: AppActionTypes.SetShowTranspose,
      payload: !doShowTranspose,
    });
  }

  return (
    <Button
      onClick={handleClick}
      // palette={doShowTranspose ? "secondary" : "primary"}
      fill="clear"
      size="md"
      iconId={doShowTranspose ? "eye" : "eye-off"}
    >
      Transpose: {doShowTranspose ? "Show" : "Hide"}
    </Button>
  );
}
