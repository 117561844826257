import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Page, PageHeader, PageContent } from "../_components/Page";
import SongQuery from "./SongQuery";
import { SongControls } from "../_components/SongControls";
import { RestoreScroll } from "../_components/RestoreScroll";
import { ErrorSuspenseWrapper } from "../_components/ErrorSuspenseWrapper";
import SongEditControl from "./SongEditControl";

export const Song: React.FC = () => {
  const { songId } = useParams<{ songId: string }>();
  const scrollingContentRef = React.useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState<string>("");
  const [editControl, setEditControl] = useState<React.ReactNode>();

  return (
    <>
      <Page>
        <PageHeader
          title={title}
          backPath={`/user-songs`}
          backState={{ clientAllowPromptFeedback: true }}
          backIcon="list"
          controls={[
            <ErrorSuspenseWrapper suspenseFallback={""}>
              {({ queryOptions }) => (
                <SongEditControl songId={songId} queryOptions={queryOptions} />
              )}
            </ErrorSuspenseWrapper>,
            // editControl,
            <SongControls />,
          ]}
          hideTitleIfScrollTop={true}
        />
        <PageContent ref={scrollingContentRef}>
          {(queryOptions) => {
            return (
              <SongQuery
                songId={songId}
                setTitle={setTitle}
                setEditControl={setEditControl}
                queryOptions={queryOptions}
              />
            );
          }}
        </PageContent>
      </Page>

      <RestoreScroll
        scrollEl={scrollingContentRef.current}
        scrollId={`Song_${songId}`}
        restoreDependencyArr={[]}
        doRestore={true}
      />
    </>
  );
};
