import React from "react";
import styled from "styled-components/macro";
import { useTransition, animated, config } from "@react-spring/web";

import { AppContext } from "../_context/appContext";
import { Login } from "./Login";
import Button from "../_components/Button";
import Spacer from "../_components/Spacer";
import { CreateUser } from "./CreateUser";

import appIcon from "../_svg/appIcon.png";
import { Page, PageContent, PageHeader } from "../_components/Page";

export function LoginCreate() {
  const { state, dispatch } = React.useContext(AppContext);
  const recentEmail = state.auth.user?.email;

  enum LoginOrCreate {
    LOGIN = "LOGIN",
    CREATE = "CREATE",
  }
  let [loginOrCreate, setLoginOrCreate] = React.useState<LoginOrCreate>(
    // if app has an email in local storage, direct to login; otherwise to create
    recentEmail ? LoginOrCreate.LOGIN : LoginOrCreate.CREATE
  );

  const transitions = useTransition(loginOrCreate, {
    from: { opacity: 0, transform: "translate3d(0px,-32px,0)" },
    enter: { opacity: 1, transform: "translate3d(0px,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0px,32px,0)" },
    config: {
      ...config.stiff,  
      clamp: true,
    },
  });

  return (
    <Page>
      <PageHeader allowHeaderCollapse={false}>
        <ButtonsWrapper>
          <Button
            onClick={() => setLoginOrCreate(LoginOrCreate.LOGIN)}
            fill={loginOrCreate === LoginOrCreate.LOGIN ? "underline" : "clear"}
            // disabled={loginOrCreate === LoginOrCreate.LOGIN}
            // palette="primary"
            palette={loginOrCreate === LoginOrCreate.LOGIN ? "text" : "primary"}
            size="sm"
          >
            Sign in
          </Button>
          {/* <ButtonDivider /> */}
          <Button
            onClick={() => setLoginOrCreate(LoginOrCreate.CREATE)}
            fill={
              loginOrCreate === LoginOrCreate.CREATE ? "underline" : "clear"
            }
            // disabled={loginOrCreate === LoginOrCreate.CREATE}
            // palette="primary"
            palette={loginOrCreate === LoginOrCreate.CREATE ? "text" : "primary"}
            size="sm"
          >
            Create
          </Button>
        </ButtonsWrapper>
      </PageHeader>
      <PageContent>
        <SubPage>
          {transitions((style, loginOrCreateTransition) => (
            <InputsWrapperAbsoluteAnim style={style}>
              {loginOrCreateTransition === LoginOrCreate.LOGIN ? (
                <Login />
              ) : (
                <CreateUser />
              )}
              {/* b/c subpage content is abs positioned inside SubPage relative container, it will scroll separate from rest of page; want extra space below to ensure can confortably scroll down to access buttons that will be hidden by soft keyboard */}
              <Spacer size={48} axis="vert" />
            </InputsWrapperAbsoluteAnim>
          ))}
        </SubPage>
      </PageContent>
    </Page>
  );
}

const Wrapper = styled.div`
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  display: flex;
  flex-flow: column nowrap;

  /* &::after {
    content: "";

    background-image: url(${appIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 20%;
    filter: blur(1px);

    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  } */
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 8px;
`;

const ButtonDivider = styled.div`
  align-self: stretch;
  width: 1px;
  /* background-color: var(--color-mono-light); */
  background-color: var(--color-primary);
`;

const SubPage = styled.div`
  position: relative;
  /* flex: 1; */
  /* overflow: auto; */
`;

const InputsWrapperAbsolute = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px;
`;

const InputsWrapperAbsoluteAnim = animated(InputsWrapperAbsolute);
