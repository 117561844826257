import React from "react";
import styled from "styled-components/macro";

import { Icon, icons } from "./Icon";
import Button, { Palette } from "./Button";
import { SECTION_LIST_ITEM_HEIGHT } from "../SongEdit/SectionList/SectionListItem";

type ContextProps = {
  children?: React.ReactNode;
  itemOnClick: () => void;
  contextIconId?: keyof typeof icons;
  contextOnClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  itemButtonPalette?: Palette;
};

// Used by both SectionListItem and SongListItem

export function ListItemContext(props: ContextProps) {
  return (
    <Wrapper
      style={
        {
          ...props.style,
        } as React.CSSProperties
      }
    >
      <ItemButton
        onClick={props.itemOnClick}
        fill="clear"
        // use "block", not "full"; full's 100% height makes no sense in flex context and prevents filling height (would have to override with height:unset);
        expand="block"
        disabled={props.disabled}
        palette={props.itemButtonPalette}
      >
        <ItemChildren>{props.children}</ItemChildren>
      </ItemButton>
      <ContextButtonWrapper>
        <Button
          fill="ghost"
          size="sm"
          expand="block"
          disabled={props.disabled}
          onClick={props.contextOnClick}
        >
          <IconWrapper>
            <Icon
              id={props.contextIconId || "more-horizontal"}
              size={24}
              strokeWidth={2}
            />
          </IconWrapper>
        </Button>
      </ContextButtonWrapper>
    </Wrapper>
  );
}

type DraggableProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export function ListItemDraggable(props: DraggableProps) {
  return (
    <Wrapper
      style={
        {
          ...props.style,
        } as React.CSSProperties
      }
    >
      <ItemWrapper>
        <ItemChildren>{props.children}</ItemChildren>
      </ItemWrapper>
      <DragHandleWrapper>
        {/* <Icon id={"menu"} size={24} strokeWidth={2} /> */}
        <Icon id={"maximize-2"} size={24} strokeWidth={2} />
      </DragHandleWrapper>
    </Wrapper>
  );
}

/**
 * Used by both ListItemContext and ListItemDraggable
 */

const Wrapper = styled.div`
  /* avoid fixed height, namely for sake for Android "Font Size" accessibility setting, which does not simply scale up base HTML font size (as a browser would), but appears to specifically target text only, preventing your use of rems on block elements from resizing as they do in browser */
  min-height: var(--height);
  display: flex;
  align-items: stretch;

  background-color: var(--song-list-item-background);

  
  /* address mobile Safari issue where - when song list exceeded a certain length, some items would flash in and out of visibility during scroll (namely those items with tags); per a StackOverflow answer "Mobile Safari does not render the elements that are offscreen, or sometimes renders erratically, when using -webkit-overflow-scrolling: touch. Unless a translate3d is applied to all other elements that might go offscreen owing to that scroll, those elements will be chopped off after scrolling."  https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela */
  -webkit-transform: translate3d(0, 0, 0);
`;

const ItemChildren = styled.div`
  flex: 1;
  font-size: ${18 / 16}rem;
  color: var(--color-text);
  /* constrain long section names  */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * ListItemContext only
 */

const ItemButton = styled(Button)`
  text-align: left;
  padding: ${4 / 16}rem ${0 / 16}rem ${4 / 16}rem ${16 / 16}rem;
`;

const ContextButtonWrapper = styled.div`
  display: flex;
  flex: 0 0 ${SECTION_LIST_ITEM_HEIGHT / 16}rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * ListItemDraggable only
 */
const ItemWrapper = styled.div`
  text-align: left;
  padding: ${4 / 16}rem ${16 / 16}rem;
  /* could also do width:100%; which is how Button occupies the same space */
  flex: 1;
  align-self: center;
`;

const DragHandleWrapper = styled(ContextButtonWrapper)`
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
  color: var(--color-text-medium);
`;
