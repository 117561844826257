import React from "react";
import { useHistory } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { SettingsQueryQuery } from "../__generated__/SettingsQueryQuery.graphql";
import { QueryOptions } from "../_types/relayTypes";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";
import { Email } from "../_components/Email";
import { AddFeedbackEvent } from "../_components/AddFeedbackEvent";

type Props = {
  queryOptions: QueryOptions;
};

// export const SettingsQuery: React.FC = () => {
export function SettingsQuery(props: Props) {
  const history = useHistory();

  const data = useLazyLoadQuery<SettingsQueryQuery>(
    graphql`
      query SettingsQueryQuery {
        viewer {
          user {
            id
            email
            emailConfirmed
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    {},
    props.queryOptions
  );

  const dataWithErrors = data.viewer;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors } = dataWithErrors;

  return (
    <>
      {/* data result */}
      {user && (
        <>
          <Button
            fill="clear"
            iconId="user"
            size="md"
            onClick={() => history.push(`/account`)}
          >
            <AccountButtonContent>
              <div>Account</div>
              <EmailWrapper>
                <Email email={user.email} />
              </EmailWrapper>
            </AccountButtonContent>
          </Button>
          <Button
            onClick={() => history.push(`/leitner-settings`)}
            fill="clear"
            size="md"
            iconId="book-open"
          >
            Memorize Songs
          </Button>
          <AddFeedbackEvent />
        </>
      )}
      {/* null result */}
      {!user && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}

const AccountButtonContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const EmailWrapper = styled.div`
  font-size: ${14 / 16}rem;
`;
