import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import Button from "../_components/Button";
import { CloneSectionMutation } from "../__generated__/CloneSectionMutation.graphql";
import { CustomErrors, isApiError } from "../_types/errorTypes";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { ConnectionHandler } from "relay-runtime";
import { CloneSection_song$key } from "../__generated__/CloneSection_song.graphql";

type Props = {
  songRef: CloneSection_song$key;
  sectionId: string;
  callback?: () => void;
  callBackOnError?: (errMsg: string) => void;
};

export function CloneSection(props: Props) {
  let [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const song = useFragment(
    graphql`
      fragment CloneSection_song on Song {
        id
        mongoVersion
      }
    `,
    props.songRef
  );

  const [commit, isInFlight] = useMutation<CloneSectionMutation>(graphql`
    mutation CloneSectionMutation(
      # Define a GraphQL variable for the connections array
      $connections: [ID!]!
      $input: cloneSectionInput!
    ) {
      cloneSection(input: $input) {
        song {
          id
          mongoVersion
        }
        sectionEdge @appendEdge(connections: $connections) {
          __typename
          cursor
          node {
            id
            name
            notes
            chords {
              id
              duration
              rhythm
              definition {
                root
                qual
                bass
              }
              notation
            }
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCloneSection() {
    const connectionID = ConnectionHandler.getConnectionID(
      song.id,
      "MoveSection_sections"
    );
    commit({
      variables: {
        input: {
          songId: song.id,
          mongoVersion: song.mongoVersion,
          sectionId: props.sectionId,
        },
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.cloneSection) {
          props.callback && props.callback();
          const { sectionEdge, errors } = response.cloneSection;
          if (sectionEdge) {
          }
          if (errors.length > 0) {
            // setFriendlyErrors(errors);
            const errMsgs = errors.map((err) => err.message);
            props.callBackOnError && props.callBackOnError(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          // setErrMsg(err.message);
          props.callBackOnError && props.callBackOnError(err.message);
        }
      },
    });
  }

  return (
    <Wrapper>
      <Button
        onClick={handleCloneSection}
        fill="clear"
        palette="secondary"
        disabled={isInFlight}
        // size="sm"
        iconId="copy"
      >
        Clone Section
      </Button>
      {/* user-friendly error message from schema */}
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
