import React from "react";
import styled from "styled-components/macro";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import {
  requestEmailConfirmationInput,
  RequestEmailConfirmationMutation,
} from "../__generated__/RequestEmailConfirmationMutation.graphql";

import Button from "../_components/Button";
import { isApiError } from "../_types/errorTypes";
import { ModalError } from "../_components/ModalError";

type Props = {
  emailConfirmed: boolean;
};

export function RequestEmailConfirmation(props: Props) {
  const [didSucceed, setDidSucceed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState<string>("");

  const [commit, isInFlight] =
    useMutation<RequestEmailConfirmationMutation>(graphql`
      mutation RequestEmailConfirmationMutation(
        $input: requestEmailConfirmationInput!
      ) {
        requestEmailConfirmation(input: $input) {
          success
          errors {
            __typename
            ... on UserError {
              message
            }
          }
        }
      }
    `);

  function handleClick(input: requestEmailConfirmationInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.requestEmailConfirmation) {
          const { success, errors } = response.requestEmailConfirmation;
          if (success) {
            setDidSucceed(true);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      {props.emailConfirmed ? (
        <Button fill="ghost" iconId="check-square" size="md" disabled>
          Email confirmed
        </Button>
      ) : (
        <Wrapper>
          {didSucceed ? (
            <Button fill="ghost" iconId="check-square" size="md" disabled>
              Confirmation email sent
            </Button>
          ) : (
            <>
              <Button
                onClick={() => handleClick({})}
                fill="clear"
                disabled={isInFlight}
                palette={isInFlight ? "mono" : "primary"}
                size="md"
                isInFlight={isInFlight}
                iconId="send"
              >
                Send Confirmation Email
              </Button>
            </>
          )}
        </Wrapper>
      )}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const Wrapper = styled.div``;
