import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import styled from "styled-components/macro";

import { UpdateSongTags_song$key } from "../__generated__/UpdateSongTags_song.graphql";
import { UpdateSongTags_tags$key } from "../__generated__/UpdateSongTags_tags.graphql";
import type {
  updateSongTagsInput,
  UpdateSongTagsMutation,
} from "../__generated__/UpdateSongTagsMutation.graphql";
import { CustomErrors, isApiError } from "../_types/errorTypes";

import { FriendlyErrors } from "../_components/FriendlyErrors";
import { getNodeArrayFromEdges } from "../_types/graphqlData";
import { typeNotEmpty } from "../_types/typeHelpers";
import { aggregateTags } from "../_utilities/tagUtils";
import { FilterTags } from "../UserSongs/FilterTags";
import {
  HeaderTitle,
  HeaderWrapper,
  SpinnerWrapper,
} from "../SongEdit/SectionList/MoveSection";
import { LoadingSpinnerComplete } from "../_components";
import { ModalError } from "../_components/ModalError";
import { arraysEqual } from "../_utilities/arrays";
import { useBatchQueue } from "../_hooks/useBatchQueue";

type Props = {
  song: UpdateSongTags_song$key;
  user: UpdateSongTags_tags$key;
};

export default function UpdateSongTags(props: Props) {
  const songData = useFragment(
    graphql`
      fragment UpdateSongTags_song on Song {
        id
        mongoVersion
        tags
      }
    `,
    props.song
  );

  const tagData = useFragment(
    graphql`
      fragment UpdateSongTags_tags on User {
        songs {
          __id
          edges {
            node {
              tags
            }
          }
        }
      }
    `,
    props.user
  );

  const songNodes = getNodeArrayFromEdges(tagData.songs).filter(typeNotEmpty);
  const { tagSet, tagCounts } = aggregateTags(songNodes);
  const [tags, setTags] = React.useState<string[]>(songData.tags.slice(0));
  const [errMsg, setErrMsg] = React.useState<string>("");

  const [commit, isInFlight] = useMutation<UpdateSongTagsMutation>(graphql`
    mutation UpdateSongTagsMutation($input: updateSongTagsInput!) {
      updateSongTags(input: $input) {
        updatedSong {
          # spread same fragment used by this component, as it comprises the same data you want to update with the mutation response
          ...UpdateSongTags_song
          # used in onCompleted
          tags
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function getOptimisticResponse(input: updateSongTagsInput) {
    return {
      updateSongTags: {
        updatedSong: {
          id: input.songId,
          tags: input.tags,
          // mongoose will update a doc's versionKey on .save() *only* if the doc changed; so if outgoing data is same as current state, anticipate no change to mongoVersion;
          mongoVersion: arraysEqual(tags, input.tags)
            ? input.mongoVersion
            : input.mongoVersion + 1,
        },
        errors: [],
      },
    };
  }

  function handleUpdateTags(input: updateSongTagsInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSongTags) {
          const { updatedSong, errors } = response.updateSongTags;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            if (updatedSong) {
              // overwrite state when relay reports errors; this is needed when the server state has *not* changed, to "undo" the local changes user made that were not accepted by the server b/c of error; (most likely errors: song is locked or client is using outdated version of song); when data *has* has changed due to a query refresh like pull-to-refresh (as opposed to due to a mutation), useEffect below will trigger and update if appropriate;
              console.log("Replacing tags with songData.tags (GraphQL Error)");
              setTags(updatedSong.tags.slice(0));
            }
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  const { batchQueue, /* mutationWasCommitted */ } = useBatchQueue<string[]>({
    callback: handleTagFilterCallback,
    isInFlight,
   /*  localState: {
      setLocalState: setTags,
      serverStateData: songData.tags as string[],
    }, */
  });

/*   React.useEffect(() => {
    // update state due to changes in data, e.g. when use pull-to-refresh; however, you don't need or want to update when a mutation is still pending;
    if (mutationWasCommitted === false) {
      // update local state with server state
      setTags(songData.tags.slice(0));
    } else {
      // update pending; ignore changes
    }
  }, [songData.tags]); */

  function handleTagFilterCallback(selectedTags: string[]) {
    handleUpdateTags({
      songId: songData.id,
      mongoVersion: songData.mongoVersion,
      tags: selectedTags,
    });
  }

  function handleTagFilter(selectedTags: string[]) {
    setTags(selectedTags);
    batchQueue({ callbackParams: [selectedTags.slice(0)] });
    // handleTagFilterCallback(selectedTags);
  }

  function handleAddTag(newTag: string) {
    setTags((state) => [newTag, ...state]);
    handleUpdateTags({
      songId: songData.id,
      mongoVersion: songData.mongoVersion,
      tags: [...tags, newTag],
    });
  }

  return (
    <>
      <HeaderWrapper>
        <HeaderTitle>
          Tags
          {isInFlight && (
            <SpinnerWrapper>
              <LoadingSpinnerComplete width={14} />
            </SpinnerWrapper>
          )}
        </HeaderTitle>
      </HeaderWrapper>
      {/* <OverlayWrapper> */}
      <FilterTags
        tags={tagSet}
        tagFilter={tags}
        handleTagFilter={handleTagFilter}
        handleAddTag={handleAddTag}
      />
      {/* {isInFlight && <LoadingSpinnerComplete width={0} overlay />} */}
      {/* </OverlayWrapper> */}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

/* const OverlayWrapper = styled.div`
  position: relative;
`; */
