/**
 * @generated SignedSource<<b00fc3f5e2dc8f3b15456573d28ae3c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type cloneSectionInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  sectionId: string;
  songId: string;
};
export type CloneSectionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: cloneSectionInput;
};
export type CloneSectionMutation$data = {
  readonly cloneSection: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly sectionEdge: {
      readonly __typename: "SectionEdge";
      readonly cursor: string;
      readonly node: {
        readonly chords: ReadonlyArray<{
          readonly definition: {
            readonly bass: string;
            readonly qual: string;
            readonly root: string;
          };
          readonly duration: number;
          readonly id: string;
          readonly notation: ReadonlyArray<string>;
          readonly rhythm: ReadonlyArray<number>;
        }>;
        readonly id: string;
        readonly name: string;
        readonly notes: string;
      } | null;
    };
    readonly song: {
      readonly id: string;
      readonly mongoVersion: number;
    } | null;
  } | null;
};
export type CloneSectionMutation = {
  response: CloneSectionMutation$data;
  variables: CloneSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Song",
  "kind": "LinkedField",
  "name": "song",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionEdge",
  "kind": "LinkedField",
  "name": "sectionEdge",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SongSection",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Chord",
          "kind": "LinkedField",
          "name": "chords",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rhythm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChordDef",
              "kind": "LinkedField",
              "name": "definition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "root",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "qual",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bass",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "notation",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CloneSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "cloneSectionPayload",
        "kind": "LinkedField",
        "name": "cloneSection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CloneSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "cloneSectionPayload",
        "kind": "LinkedField",
        "name": "cloneSection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "sectionEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87d5aedf260bf7e63a52e89d5c439163",
    "id": null,
    "metadata": {},
    "name": "CloneSectionMutation",
    "operationKind": "mutation",
    "text": "mutation CloneSectionMutation(\n  $input: cloneSectionInput!\n) {\n  cloneSection(input: $input) {\n    song {\n      id\n      mongoVersion\n    }\n    sectionEdge {\n      __typename\n      cursor\n      node {\n        id\n        name\n        notes\n        chords {\n          id\n          duration\n          rhythm\n          definition {\n            root\n            qual\n            bass\n          }\n          notation\n        }\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8c8694b89683999100362ca8b4ed84a";

export default node;
