/**
 * @generated SignedSource<<97eb10ce9cc5f38b861b1cec49d6e48c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSectionName_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly " $fragmentType": "UpdateSectionName_song";
};
export type UpdateSectionName_song$key = {
  readonly " $data"?: UpdateSectionName_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionName_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSectionName_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "08b89585e251fa5fbc37dd28db5fe834";

export default node;
