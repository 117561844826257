import React from "react";

/* 
Functions to provide hover/touch interactions:
-on mousemove, touchstart/touchmove, set UI state, but do not trigger update
-on mousedown, touchEnd, trigger update
*/

export function useSimpleTouchHelpers() {
  return {
    handleTouchMove: function handleTouchMove(
      e: React.TouchEvent | TouchEvent,
      setHovered: () => void
    ) {
      setHovered();
    },
    handleTouchEnd: function handleTouchEnd(
      e: React.TouchEvent | TouchEvent,
      setSelected: () => void,
      handleRemoveActive: () => void
    ) {
      setSelected();

      // delay removal of hover state to give visual feedback of selection
      // setTimeout(() => {
      handleRemoveActive();
      // }, 0);
    },

    /* handleMouseMove: function handleMouseMove(
      e: React.MouseEvent | MouseEvent,
      setHovered: () => void
    ) {
      setHovered();
    },

    handleMouseDown: function handleMouseDown(
      e: React.MouseEvent | MouseEvent,
      setSelected: () => void
    ) {
      // setTimeout(() => {
      setSelected();
      // }, 0);
    }, */
  };
}
