/**
 * @generated SignedSource<<f2a0a595327b11d3be2761e05f762481>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type createSectionInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  name: string;
  songId: string;
};
export type CreateSectionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: createSectionInput;
};
export type CreateSectionMutation$data = {
  readonly createSection: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly sectionEdge: {
      readonly __typename: "SectionEdge";
      readonly cursor: string;
      readonly node: {
        readonly chords: ReadonlyArray<{
          readonly definition: {
            readonly bass: string;
            readonly qual: string;
            readonly root: string;
          };
          readonly duration: number;
          readonly id: string;
          readonly notation: ReadonlyArray<string>;
          readonly rhythm: ReadonlyArray<number>;
        }>;
        readonly id: string;
        readonly name: string;
        readonly notes: string;
      } | null;
    };
    readonly song: {
      readonly artist: string;
      readonly id: string;
      readonly mongoVersion: number;
      readonly sections: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly chords: ReadonlyArray<{
              readonly definition: {
                readonly bass: string;
                readonly qual: string;
                readonly root: string;
              };
              readonly duration: number;
              readonly id: string;
              readonly notation: ReadonlyArray<string>;
              readonly rhythm: ReadonlyArray<number>;
            }>;
            readonly id: string;
            readonly name: string;
            readonly notes: string;
          } | null;
        } | null> | null;
      };
      readonly title: string;
    } | null;
  } | null;
};
export type CreateSectionMutation = {
  response: CreateSectionMutation$data;
  variables: CreateSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoVersion",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artist",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Chord",
  "kind": "LinkedField",
  "name": "chords",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rhythm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChordDef",
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "root",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qual",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bass",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notation",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SongSection",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionEdge",
  "kind": "LinkedField",
  "name": "sectionEdge",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SongSection",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createSectionPayload",
        "kind": "LinkedField",
        "name": "createSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": "sections",
                "args": null,
                "concreteType": "SectionConnection",
                "kind": "LinkedField",
                "name": "__MoveSection_sections_connection",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createSectionPayload",
        "kind": "LinkedField",
        "name": "createSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "SectionConnection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "sections(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MoveSection_sections",
                "kind": "LinkedHandle",
                "name": "sections"
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "sectionEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d27a0ce113f30b1ade7850f79cb447f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "createSection",
            "song",
            "sections"
          ]
        }
      ]
    },
    "name": "CreateSectionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSectionMutation(\n  $input: createSectionInput!\n) {\n  createSection(input: $input) {\n    song {\n      id\n      mongoVersion\n      title\n      artist\n      sections(first: 2147483647) {\n        edges {\n          node {\n            id\n            name\n            notes\n            chords {\n              id\n              duration\n              rhythm\n              definition {\n                root\n                qual\n                bass\n              }\n              notation\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    sectionEdge {\n      __typename\n      cursor\n      node {\n        id\n        name\n        notes\n        chords {\n          id\n          duration\n          rhythm\n          definition {\n            root\n            qual\n            bass\n          }\n          notation\n        }\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "812e2c1b4401c8e95636f2900a50fee3";

export default node;
