/**
 * @generated SignedSource<<b2ce6f25f18a154523231841543706df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteSongShareInput = {
  clientMutationId?: string | null;
  songId: string;
  userId: string;
};
export type SongDeleteShareMutation$variables = {
  input: deleteSongShareInput;
};
export type SongDeleteShareMutation$data = {
  readonly deleteSongShare: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly song: {
      readonly id: string;
      readonly mongoVersion: number;
      readonly permissions: ReadonlyArray<{
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      }>;
    } | null;
  } | null;
};
export type SongDeleteShareMutation = {
  response: SongDeleteShareMutation$data;
  variables: SongDeleteShareMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "deleteSongSharePayload",
    "kind": "LinkedField",
    "name": "deleteSongShare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Song",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mongoVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongPermission",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "user",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOwner",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "didAccept",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongDeleteShareMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongDeleteShareMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73fdae542c8b361fba366e853f7a34c1",
    "id": null,
    "metadata": {},
    "name": "SongDeleteShareMutation",
    "operationKind": "mutation",
    "text": "mutation SongDeleteShareMutation(\n  $input: deleteSongShareInput!\n) {\n  deleteSongShare(input: $input) {\n    song {\n      id\n      mongoVersion\n      permissions {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "53a2935a175ee213408617f1fb4283a0";

export default node;
