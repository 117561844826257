import React from "react";
// import { AppContext } from "../_context/appContext";
import { Page, PageHeader, PageContent } from "../_components/Page";
import { RequestPasswordReset } from "./RequestPasswordReset";

export function ForgotPassword() {
  /*   const { state, dispatch } = React.useContext(AppContext);
  const recentEmail = state.auth.user?.email; */
  return (
    <Page>
      <PageHeader title="Forgot Password" /* backPath="/login-create" */ />
      <PageContent>
        <RequestPasswordReset /* email={recentEmail} */ />
      </PageContent>
    </Page>
  );
}
