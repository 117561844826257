// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
// https://codesandbox.io/s/context-reducer-ts-9ctis?file=/src/context.tsx:908-920

import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  Dispatch,
} from "react";

import {
  appReducer,
  appReducerInit,
  AppType,
  AppActions,
  AppActionTypes,
} from "../_reducers/appReducer";

import {
  authReducer,
  authReducerInit,
  AuthType,
  AuthActions,
  AuthActionTypes,
} from "../_reducers/authReducer";

import { AUTH_STORAGE_KEY, APP_STORAGE_KEY } from "../_constants";
import { storage } from "../_plugins";
import { serializeMap } from "../_utilities/maps";

type InitialStateType = {
  app: AppType;
  auth: AuthType;
};

const initialState: InitialStateType = {
  app: appReducerInit,
  auth: authReducerInit,
};

export type AllActions = AppActions | AuthActions;

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<AllActions>;
}>({
  state: initialState,
  dispatch: () => {},
});

function logger(action: AllActions) {
  if (process.env.NODE_ENV !== "production") {
    console.log(
      `%cReducer - \n type: ${action.type}; \n payload: ${action.payload};
      `,
      "color: yellow; font-size: 12px; font-weight: bold;"
    );
    console.log(action.payload);
  }
}

function dispatchMiddleware(
  state: InitialStateType,
  dispatch: Dispatch<AllActions>
) {
  return async (action: AllActions) => {
    // logger(action);
    switch (action.type) {
      // *** could store state.auth to localStorage similarly to how do so for state.app (below, within useEffect, so don't have to effectively repeat reducer actions); at a glance, these seem different b/c Reset uses removeItem, but don't see that it needs to; overwriting it with setItem should be just as good;
      /*       case AuthActionTypes.Set:
        let user = action.payload.user;
        let jwt = action.payload.jwt;
        await storage.setItem(AUTH_STORAGE_KEY, JSON.stringify({ user, jwt }));
        dispatch(action);
        break;
      case AuthActionTypes.Reset:
        await storage.removeItem(AUTH_STORAGE_KEY);
        const newAuth = {
          ...authReducerInit,
          user: {
            ...authReducerInit.user,
            email: state.auth.user?.email,
          },
        };
        await storage.setItem(AUTH_STORAGE_KEY, JSON.stringify(newAuth));
        dispatch(action);
        break; */
      default:
        return dispatch(action);
    }
  };
}

const mainReducer = (
  { app, auth }: InitialStateType,
  action: AllActions
): InitialStateType => ({
  app: appReducer(app, action as AppActions),
  auth: authReducer(auth, action as AuthActions),
});

const authDataPromise = storage.getItem(AUTH_STORAGE_KEY);
const appDataPromise = storage.getItem(APP_STORAGE_KEY);

const AppProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // Async Operations with useReducer Hook -
  // https://gist.github.com/astoilkov/013c513e33fe95fa8846348038d8fe42
  // https://stackoverflow.com/questions/53146795/react-usereducer-async-data-fetch
  async function getFromStorage() {
    const authDataString = await authDataPromise;
    if (authDataString) {
      const { user, jwt } = JSON.parse(authDataString);
      process.env.NODE_ENV !== "production" &&
        console.log("logged in as:", user);

      // alert(`${JSON.stringify(user)} ${JSON.stringify(jwt)}`);

      dispatch({
        type: AuthActionTypes.Set,
        payload: {
          user,
          jwt,
        },
      });
    }

    const appDataString = await appDataPromise;
    if (appDataString) {
      const {
        searchFilter,
        tagFilter,
        sortOrder,
        isInDebugMode,
        colorTheme,
        doShowTranspose,
        rhythmDisplay,
        // productMap,
        // productMapVerified,
      } = JSON.parse(appDataString);
      dispatch({
        type: AppActionTypes.SetFromStorage,
        payload: {
          // update only if defined (otherwise reducer init value will be used)
          ...(searchFilter && { searchFilter }),
          ...(tagFilter && { tagFilter }),
          ...(sortOrder && { sortOrder }),
          ...(isInDebugMode && { isInDebugMode }),
          ...(colorTheme && { colorTheme }),
          ...(doShowTranspose && { doShowTranspose }),
          ...(rhythmDisplay && { rhythmDisplay }),
          // *** restore productMap, productMapVerified, other maps in state.app, for benefit of (future) offline mode; not useful for e.g. ensuring product "owned" state shows sooner, b/c IAP plugin will go through same approval check on each load, which will briefly modify productMap entries out of an "owned" state; (if it mattered, you could ignore these intermediate stages if localStorage showed a product was already owned, and defer updating product states until after IAP plugin has finished, to ensure owned product stay "owned"... not presently worth the while) *** punted on this for now; see notes below, where saving these values to localStorage
          // productMap,
          // productMapVerified,
          // don't restore scrollPosMap
          // no point restoring app “mode” unless also restore route.
        },
      });
    }

    setIsDataLoaded(true);
  }

  useEffect(() => {
    getFromStorage();
  }, []);

  useEffect(() => {
    // after state.app has been updated, save it to localStorage;
    storage.setItem(
      APP_STORAGE_KEY,
      JSON.stringify({
        ...state.app,
        // *** punting on this for now, as offline mode isn't an immediate concern: issue is you cannot directly serialize Maps to JSON; you figured out how to serialize (see _utilities/maps.ts), however additional issue here is you are stringifying many fields together here (and then parsing them together when reading localStorage); you need to either 1) save productMap/productMapVerified to their own localStorage keys, so they can be stringified/parsed individually; or 2) use strategy like that mentioned in your second hyperlink in maps.ts to make use of the additional arguments of of JSON.stringify/parse; or 3) save them all together as you are doing below, which requires serializing every map in state.app (e.g. productMap/productMapVerified), AND de-serializing them in getFromStorage() above;
        // scrollPosMap: serializeMap(state.app.scrollPosMap),
        // productMap: serializeMap(state.app.productMap),
        // productMapVerified: serializeMap(state.app.productMapVerified),
        // productGroupMapVerified: serializeMap(state.app.productGroupMapVerified),
      })
    );
  }, [state.app]);

  useEffect(() => {
    // after state.auth has been updated, save it to localStorage;
    storage.setItem(AUTH_STORAGE_KEY, JSON.stringify(state.auth));
  }, [state.auth]);

  // delay app render until data fetched from async / local storage; ensures e.g. logged-in user isn't bounced back and forth by the router, b/c will initially be "anon" in local state, then "authorized" after data loaded from storage
  // note that this "load" is in addition to any required by Relay to fetch data, and will result in multiple "stages" of loading; given that this is local - and so will always be fast - probably best not to display anything for this loading stage
  if (!isDataLoaded) {
    return null;
  }

  return (
    <AppContext.Provider
      // could pass these values as a tuple "[]" to create a similar interface on useContext as you have on useReducer, e.g.: https://stackoverflow.com/a/59432211/6281555
      value={{ state, dispatch: dispatchMiddleware(state, dispatch) }}
      // *** using key here as a means of forcing a re-render of RelayEnvironmentProvider whenever the user changes; during normal use, this should be only during login/logout events
      // key={state.auth.user?.id}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
