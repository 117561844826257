import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { SongManageShare_song$key } from "../__generated__/SongManageShare_song.graphql";
import { SongManageShare_user$key } from "../__generated__/SongManageShare_user.graphql";

import { SongAddShare } from "./SongAddShare";
import { ShareSongPDF } from "./ShareSongPDF";
import { SongUpdateShare } from "./SongUpdateShare";
import { SongDeleteShare } from "./SongDeleteShare";
import { Email } from "../_components/Email";
import { ShareRelation } from "../UserSongs/UserSongsList";
import { ModalTitle } from "../_style-components/Modal.style";

type Props = {
  songRef: SongManageShare_song$key;
  userRef: SongManageShare_user$key;
  shareRelations: ShareRelation[];
};

export function SongManageShare(props: Props) {

  const userData = useFragment(
    graphql`
      fragment SongManageShare_user on User {
        id
        email
      }
    `,
    props.userRef
  );

  const song = useFragment(
    graphql`
      fragment SongManageShare_song on Song {
        ...SongAddShare_song
        ...ShareSongPDF_song
        ...SongUpdateShare_song
        ...SongDeleteShare_song
        id
        permissions {
          ...SongUpdateShare_permission
          ...SongDeleteShare_permission
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  return (
    <Wrapper>
      <ModalTitle>Sharing</ModalTitle>
      {song.permissions
        .filter((val) => !val.isOwner)
        .map((val) => (
          <AddShareInputsWrapper key={val.user}>
            <SongDeleteShare
              permissionRef={val}
              songRef={song}
              shareUserId={val.user}
            />
            <ShareeInfo>
              <Email email={val.email} />
              <ShareeDidAccept>
                {val.didAccept ? "Sharing" : "Invited"}
              </ShareeDidAccept>
            </ShareeInfo>
            <SongUpdateShare
              songRef={song}
              permissionRef={val}
              shareUserId={val.user}
            />
          </AddShareInputsWrapper>
        ))}
      <SongAddShare
        // userId={props.userId}
        songRef={song}
        shareRelations={props.shareRelations}
      />
      <ShareSongPDF
        songRef={song}
        shareRelations={props.shareRelations}
        userEmail={userData.email}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-shadow);
  border-bottom: 1px solid var(--color-text-light);
`;
export const AddShareInputsWrapper = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
`;
export const ShareeInfo = styled.div`
  flex: 1;
  /* padding: 8px; */
  font-size: ${14 / 16}rem;
`;
const ShareeDidAccept = styled.div`
  /* line-height: 1; */
  font-size: ${14 / 16}rem;
  color: var(--color-text-medium);
  pointer-events: none;
`;
