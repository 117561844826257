import React, { FormEvent } from "react";
import styled from "styled-components/macro";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import * as Yup from "yup";

import {
  RequestPasswordResetMutation,
  requestPasswordResetInput,
} from "../__generated__/RequestPasswordResetMutation.graphql";

import Button from "../_components/Button";
import { isApiError } from "../_types/errorTypes";
import { ModalError } from "../_components/ModalError";
import { HiddenSubmitButton, LoadingSpinnerComplete } from "../_components";
import { Icon } from "../_components/Icon";
import Spacer from "../_components/Spacer";
import { TextInputIcon } from "../_components/TextInputIcon";
import {
  FORM_PLACEHOLDERS,
  getFieldErrors,
  YUP_FIELDS,
} from "../_utilities/forms";
import {
  FieldError,
  InputFields,
  StyledForm,
} from "../_components/FormComponents";
import { AppContext } from "../_context/appContext";

const FORM_SCHEMA = Yup.object().shape({
  email: YUP_FIELDS.email,
});

/* type Props = {
  email?: string;
};
 */
export function RequestPasswordReset() {
  // const [email, setEmail] = React.useState(props.email ?? "");
  const [didSucceed, setDidSucceed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState<string>("");

  const { state, dispatch } = React.useContext(AppContext);
  const recentEmail = state.auth.user?.email;

  type FormInput = {
    email: string;
  };
  const FORM_INPUT_INITIAL = {
    email: recentEmail ?? "",
  };
  const [inputVals, setInputVals] =
    React.useState<FormInput>(FORM_INPUT_INITIAL);
  const [formInputIsInvalid, setFormInputIsInvalid] =
    React.useState<boolean>(false);

  const [commit, isInFlight] =
    useMutation<RequestPasswordResetMutation>(graphql`
      mutation RequestPasswordResetMutation(
        $input: requestPasswordResetInput!
      ) {
        requestPasswordReset(input: $input) {
          success
          errors {
            __typename
            ... on UserError {
              message
            }
          }
        }
      }
    `);

  function handleRequest(input: requestPasswordResetInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.requestPasswordReset) {
          const { success, errors } = response.requestPasswordReset;
          if (success) {
            setDidSucceed(true);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  function handleSubmit(e: FormEvent) {
    // make sure e.preventDefault() comes first for form submission
    e.preventDefault();
    if (isInFlight) return;

    if (!FORM_SCHEMA.isValidSync(inputVals)) {
      setFormInputIsInvalid(true);
      return;
    }
    handleRequest(inputVals);
  }

  return (
    <Wrapper>
      {didSucceed ? (
        <Button fill="ghost" iconId="check-square" disabled>
          Reset email sent
        </Button>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <InputFields>
            <TextInputIcon
              value={inputVals.email}
              onChange={(val) =>
                setInputVals({
                  ...inputVals,
                  email: val,
                })
              }
              label="Email"
              type="email"
              size="large"
              mode="border"
              placeholder={FORM_PLACEHOLDERS.email}
            />
            <FieldError>
              {getFieldErrors(
                FORM_SCHEMA,
                inputVals,
                "email",
                formInputIsInvalid
              )}
            </FieldError>
          </InputFields>
          <Button
            type="submit"
            // allow attempted submission when inputs are invalid, to trigger display of input errors
            disabled={isInFlight}
            palette={isInFlight ? "mono" : "primary"}
          >
            Send Reset Link
          </Button>
          {isInFlight && <LoadingSpinnerComplete overlay />}
        </StyledForm>
      )}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 8px;
`;
