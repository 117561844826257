import React, { useEffect } from "react";
import styled from "styled-components/macro";

import { ResetScroll } from "./ResetScroll";
import Spacer from "./Spacer";

// inspiration: https://codepen.io/thenutz/pen/VwYeYEE

type Props = {
  paddingHoriz?: number;
  children: React.ReactNode;
  resetScrollKey: number;
};

export function SideScroller({
  paddingHoriz = 8,
  children,
  resetScrollKey,
}: Props) {
  var refSideScroller = React.useRef<HTMLDivElement>(null);
  var refScrollItems = React.useRef<HTMLDivElement>(null);

  function applyListeners() {
    const slider = refSideScroller.current;
    const items = refScrollItems.current;
    const scrollSpeedMult = 2;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    slider?.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
      if (items) {
        items.style.pointerEvents = "unset";
      }
    });
    slider?.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
      if (items) {
        items.style.pointerEvents = "unset";
      }
    });
    slider?.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      if (items) {
        // disable pointer-events for children, to prevent clicking child buttons while dragging
        items.style.pointerEvents = "none";
      }
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * scrollSpeedMult;
      slider.scrollLeft = scrollLeft - walk;
    });
  }

  useEffect(() => {
    applyListeners();
  }, []);

  var wrapperStyle = { "--padding-horiz": paddingHoriz + "px" };

  return (
    <>
      <BlurWrapper>
        <Wrapper
          ref={refSideScroller}
          style={wrapperStyle as React.CSSProperties}
        >
          <ScrollItems ref={refScrollItems}>
            {children}
            {/* due to bug (or at least unexpected behavior), padding "collapses" in circumstances like this (https://bugzilla.mozilla.org/show_bug.cgi?id=748518); spacer is a great alternative */}
            <Spacer size={paddingHoriz} />
          </ScrollItems>
        </Wrapper>
        <EdgeFadeLeft style={wrapperStyle} />
        <EdgeFadeRight style={wrapperStyle} />
      </BlurWrapper>

      <ResetScroll
        element={refSideScroller.current}
        resetValArr={[resetScrollKey]}
        duration={200}
        axis="horiz"
      />
    </>
  );
}

const BlurWrapper = styled.div`
  position: relative;
  user-select: none;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /* transition: all 0.2s;
  transform: scale(1);
  will-change: transform; */
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  padding-left: var(--padding-horiz);
  padding-right: var(--padding-horiz);

  /* padding-bottom to leave room for scrollbar */
  /* padding-bottom: 4px; */
  /* margin-bottom to hide scrollbar -- *** doesn't not work in iOS Safari */
  /* margin-bottom: -4px; */

  /* https://stackoverflow.com/a/13184693 */
  /* https://stackoverflow.com/a/49278385/6281555 */
  /* Firefox */
  scrollbar-width: none;
  /* Internet Explorer 10+ */
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    /* -webkit-appearance: none; */
    /* width: 0; */
    /* height: 0; */
  }

  &.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    /* transform: scale(1.04); */
  }
`;

const ScrollItems = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const EdgeFade = styled.div<{
  style: object;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--padding-horiz);
  transform: scale(1.04);
  /* user should be able to touch items through the edge fades */
  pointer-events: none;
`;

const EdgeFadeLeft = styled(EdgeFade)`
  left: 0;
  background: linear-gradient(
    to right,
    var(--color-background-translucent-20),
    transparent
  );
`;

const EdgeFadeRight = styled(EdgeFade)`
  right: 0;
  background: linear-gradient(
    to left,
    var(--color-background-translucent-20),
    transparent
  );
`;
