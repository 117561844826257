/**
 * @generated SignedSource<<57f51f85b87905f7fca333bed84b69bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongEditControlQuery$variables = {
  id: string;
};
export type SongEditControlQuery$data = {
  readonly song: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NotFound";
      readonly message: string;
    }>;
    readonly song: {
      readonly id: string;
      readonly viewerPermission: {
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"SongManageShareSharee_song">;
    } | null;
  } | null;
};
export type SongEditControlQuery = {
  response: SongEditControlQuery$data;
  variables: SongEditControlQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SongPermission",
  "kind": "LinkedField",
  "name": "viewerPermission",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "NotFound",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongEditControlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SongManageShareSharee_song"
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongEditControlQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SongPermission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28cbc1f83152ee4721c0f97f7f359197",
    "id": null,
    "metadata": {},
    "name": "SongEditControlQuery",
    "operationKind": "query",
    "text": "query SongEditControlQuery(\n  $id: ID!\n) {\n  song(id: $id) {\n    song {\n      id\n      viewerPermission {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n      ...SongManageShareSharee_song\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment SongManageShareSharee_song on Song {\n  ...SongRejectShare_song\n  id\n  permissions {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n  viewerPermission {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n\nfragment SongRejectShare_song on Song {\n  id\n  title\n  viewerPermission {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n"
  }
};
})();

(node as any).hash = "50236dd7dbeef8d84fec99f6e42f3f90";

export default node;
