import React from "react";
import styled from "styled-components/macro";

import { SideScroller } from "../_components";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import Spacer from "../_components/Spacer";
import { TextInputModal } from "../_components/TextInputModal";
import { ellipsizeText } from "../_utilities/strings";

function AddTagButton({
  handleAddTag,
}: {
  handleAddTag: (newTag: string) => void;
}) {
  return (
    <Tag>
      <TextInputModal
        textInputs={[
          {
            id: "tag",
            initialValue: "",
            label: "Tag",
            required: true,
            autoCapitalize: "sentences",
          },
        ]}
        createOrUpdate="create"
        expand="inline"
        callback={(inputValues: Record<string, string>) => {
          handleAddTag(inputValues.tag.trim());
        }}
        modalLabel="Create tag"
        resetOnSubmit
      />
    </Tag>
  );
}

type Props = {
  tags: string[];
  tagCounts?: Record<string, number>;
  tagFilter: readonly string[];
  handleTagFilter?: (newTags: string[]) => void;
  // if handleAddTag callback provided, include UI for adding tags
  handleAddTag?: (newTag: string) => void;
  disabled?: boolean;
  resetScrollOnChange?: boolean;
};

export function FilterTags({
  tags,
  tagCounts,
  tagFilter,
  handleTagFilter,
  handleAddTag,
  disabled = false,
  resetScrollOnChange,
}: Props) {
  function handleTagClick(
    isInFilter: boolean,
    tagFilterLocation: number,
    tag: string
  ) {
    if (isInFilter) {
      handleTagFilter &&
        handleTagFilter([
          ...tagFilter.slice(0, tagFilterLocation),
          ...tagFilter.slice(tagFilterLocation + 1),
        ]);
    } else {
      handleTagFilter && handleTagFilter([...tagFilter, tag]);
    }
  }

  function sortTags(a: string, b: string) {
    var aInFilter = tagFilter.indexOf(a) > -1;
    var bInFilter = tagFilter.indexOf(b) > -1;
    if ((aInFilter && bInFilter) || (!aInFilter && !bInFilter)) {
      // both either in or not in filter; sort by alpha
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    } else if (aInFilter) {
      // only "a" in filter; don't change positions
      return -1;
    } else {
      // only "b" in filter; move "b" before "a"
      return 1;
    }
  }

  return (
    <Wrapper>
      <SideScroller
        // resetting scroll desireable for search filter, but not when setting tags for song
        resetScrollKey={resetScrollOnChange ? tagFilter.length : 0}
      >
        {handleAddTag && <AddTagButton handleAddTag={handleAddTag} />}
        {tags.sort(sortTags).map((tag) => {
          var tagFilterLocation = tagFilter.indexOf(tag);
          var isInFilter = tagFilterLocation > -1;
          var tagSongCount = tagCounts && tagCounts[tag];
          return (
            <Tag key={tag}>
              <ComposedButton
                size={"sm"}
                fill={isInFilter ? "fill" : "outline"}
                onClick={() =>
                  handleTagClick(isInFilter, tagFilterLocation, tag)
                }
                palette="secondary"
                disabled={disabled}
                $tagSongCount={tagSongCount}
                $isInFilter={isInFilter}
              >
                <TagName>{ellipsizeText(tag)}</TagName>
                {isInFilter && !disabled && (
                  <CloseIcon>
                    <Icon id="x" size={12} strokeWidth={3} />
                  </CloseIcon>
                )}
                {tagCounts && !isInFilter && (
                  <TagCount>{tagSongCount}</TagCount>
                )}
              </ComposedButton>
            </Tag>
          );
        })}
        {tags.length > 8 && (
          // provide a second "add tag" button at end, as convenience for longer tag lists
          <> {handleAddTag && <AddTagButton handleAddTag={handleAddTag} />}</>
        )}
      </SideScroller>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Tag = styled.div`
  position: relative;
  margin-right: 4px;
`;

const ComposedButton = styled(Button)<{
  $tagSongCount?: number;
  $isInFilter?: boolean;
}>`
  min-height: ${44 / 16}rem;
  /* scale padding to number of digits in count, only if tagCounts provided */
  ${({ $tagSongCount }) =>
    $tagSongCount &&
    `padding-right: ${
      (10 + $tagSongCount.toString().length * 8) / 16 + "rem"
    };`}/* toggling buttons switches between "fill" and "outline" style buttons; only the latter has border by default; want to maintain consistent width, so add border to "fill" style */
 /* This is no longer necessary: added a "fix" to FillButton component */
  /*  ${({ $isInFilter }) =>
    $isInFilter && `border: var(--border-width) solid transparent;`} */
`;

const TagName = styled.div`
  font-size: ${16 / 16}rem;
`;

const TagCount = styled.div`
  position: absolute;
  bottom: -2px;
  right: 5px;
  font-size: ${14 / 16}rem;
  /* using flex here is helpful to eliminate line-height of the icon, which otherwise makes it taller than expected */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* padding: 2px; */
`;

const CloseIcon = styled.div`
  position: absolute;
  /* top: ${3 / 16}rem; */
  /* right: ${2 / 16}rem; */
  top: 3px;
  right: 2px;
  /* using flex here is helpful to eliminate line-height of the icon, which otherwise makes it taller than expected */
  display: flex;
  justify-content: center;
  align-items: center;
`;
