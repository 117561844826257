import React from "react";
import styled from "styled-components/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { SongQueryQuery } from "../__generated__/SongQueryQuery.graphql";

import { QueryOptions } from "../_types/relayTypes";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { SongBody } from "./SongBody";
import { TagsSmall } from "../UserSongs/TagsSmall";
import { isNativeCap } from "../_utilities/detectNative";
import { allowSleep, keepAwake } from "../_plugins/keepAwake";
import { Notes } from "./Notes";
import { TitleArtist } from "../_style-components/TitleArtist";
import { Transpose, TransposeAccidentalType } from "../_components/Transpose";
// import SongEditControl from "./SongEditControl";
import UpdateSongTimeSig from "../SongMeta/UpdateSongTimeSig";
import { TIME_SIGS_DEFAULT } from "../_music/rhythmCount";
// import { ErrorSuspenseWrapper } from "../_components/ErrorSuspenseWrapper";
import { TimeSigDisplay } from "../_components/TimeSigDisplay";

type Props = {
  songId: string;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  setEditControl?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  queryOptions: QueryOptions;
};

export default function SongQuery(props: Props) {
  React.useEffect(() => {
    isNativeCap && keepAwake();
    return () => {
      isNativeCap && allowSleep();
    };
  }, [isNativeCap, keepAwake, allowSleep]);

  const data = useLazyLoadQuery<SongQueryQuery>(
    graphql`
      query SongQueryQuery($id: ID!) {
        song(id: $id) {
          song {
            id
            mongoVersion
            title
            artist
            notes
            tags
            timeSig
            ...UpdateSongTimeSig_song
            ...SongBody_song
            viewerPermission {
              user
              email
              isOwner
              canEdit
              didAccept
            }
            ...SongManageShareSharee_song
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { id: props.songId },
    props.queryOptions
  );

  const dataWithErrors = data.song;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }
  const { song, errors } = dataWithErrors;
  React.useEffect(() => {
    if (song) {
      props.setTitle?.(song.title);
      // props.setEditControl?.(
      //   <ErrorSuspenseWrapper>
      //     {({ queryOptions }) => (
      //       <SongEditControl
      //         songId={props.songId}
      //         queryOptions={props.queryOptions}
      //       />
      //     )}
      //   </ErrorSuspenseWrapper>
      // );
    }
  }, [song]);

  const [transposeVal, setTransposeVal] = React.useState<number>(0);
  const [accidentalsVal, setAccidentalsVal] =
    React.useState<TransposeAccidentalType>("preserve");

  return (
    <>
      {/* data result */}
      {song && (
        <>
          <DataWrapper>
            <MetaDataWrapper>
              <TitleEditWrapper>
                <TitleArtist title={song.title} artist={song.artist} />
                {/* <SongEditControl
                  songId={props.songId}
                  queryOptions={props.queryOptions}
                /> */}
              </TitleEditWrapper>
              {song.tags.length > 0 && <TagsSmall tags={song.tags} />}
              {song.notes && (
                <div>
                  <Notes notes={song.notes} />
                </div>
              )}
              {song.timeSig !== TIME_SIGS_DEFAULT && (
                <TimeSigDisplay
                  timeSigVal={song.timeSig}
                  labelOrVert={"vert"}
                />
              )}
            </MetaDataWrapper>
            {/* <UpdateSongTimeSig song={song} /> */}

            {/* Main body of song */}
            <SongBody
              songRef={song}
              transposeVal={transposeVal}
              accidentalsVal={accidentalsVal}
            />
            <EndOfPage></EndOfPage>
          </DataWrapper>

          <Transpose
            transposeVal={transposeVal}
            setTransposeVal={setTransposeVal}
            accidentalsVal={accidentalsVal}
            setAccidentalsVal={setAccidentalsVal}
          />
        </>
      )}

      {/* null result */}
      {!song && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}

const DataWrapper = styled.div``;
const MetaDataWrapper = styled.div`
  padding-left: 8px;
  /* if put MetaDataWrapper below SongBody, use following styles to create some breathing room */
  /*  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 32px; */
`;
const EndOfPage = styled.div`
  width: 100%;
  /* reserve space for floating button, e.g. transpose controls */
  min-height: 92px;
  /* border-top: 2px dotted var(--color-text-light); */
  /* background: linear-gradient(to bottom, transparent, var(--color-text-light)); */
`;
const TitleEditWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
