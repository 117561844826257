import { ChordOrCursor, RhythmUnit, RHYTHM_UNIT_DEFAULT } from "../_types";
import type { ActionMap } from "./types";

import { CURSOR_ID, makeDefaultCursor } from "../ChordsSimple/Cursor";
import { updateCursorForContext } from "./chordsReducer.helpers";
import {
  determineAvailableScales,
  getScalingUpdates,
  ScaleOption,
  scaleOptionsInit,
  Tap,
  TapStatGroup,
} from "../_hooks/useRhythm.helpers";

export type ChordInputMode = "manual" | "tap" | "scale";

export type ChordsType = {
  sectionChords: ChordOrCursor[];
  rhythmUnit: RhythmUnit;
  // *** reminder: this was an ID, but now is an index; consider switching back to using ID, now that you use stable IDs for chords (as opposed to IDs that are reassigned by mongo on every save)
  selectedId: number;
  inputMode: ChordInputMode;
  tapsInSession: Tap[];
  tapIdSet: Set<string>;
  tapStatGroup: TapStatGroup[];
  tapSessionRhythmUnit: RhythmUnit;
  scaleOptions: ScaleOption[];
  canFinishRhythm: boolean;
  tapDurationSens: number;
  tappedBeatTotal: number;
  lastTappedId: string;
};

export const chordsReducerInit: ChordsType = {
  sectionChords: [makeDefaultCursor()],
  rhythmUnit: RHYTHM_UNIT_DEFAULT,
  // *** reminder: this was an ID, but now is an index; consider switching back to using ID, now that you use stable IDs for chords (as opposed to IDs that are reassigned by mongo on every save)
  selectedId: 0, // if return to string, init val will be CURSOR_ID
  inputMode: "manual",
  tapsInSession: [],
  tapIdSet: new Set(),
  tapStatGroup: [],
  tapSessionRhythmUnit: RHYTHM_UNIT_DEFAULT,
  scaleOptions: scaleOptionsInit,
  canFinishRhythm: false,
  tapDurationSens: 1,
  tappedBeatTotal: 0,
  lastTappedId: "",
};

export enum ChordsActionTypes {
  Set = "SET_CHORDS",
  Reset = "RESET_CHORDS",
  UpdateSectionChords = "UPDATE_SECTION_CHORDS",
  SetSelected = "SET_SELECTED_CHORD",
  SetInputMode = "SET_INPUT_MODE",
  AddRhythmTap = "ADD_RHYTHM_TAP",
  FinishRhythmTapSession = "FINISH_RHYTHM_TAP_SESSION",
  ClearTapHistory = "CLEAR_TAP_HISTORY",
  SetRhythmScalingOptions = "SET_RHYTHM_SCALING_OPTIONS",
  ScaleTapStatGroupsBy = "SCALE_TAP_STAT_GROUPS_BY",
  SetRhythmUnit = "SET_RHYTHM_UNIT",
  SetTapDurationSens = "SET_TAP_DURATION_SENS",
  ToggleChordForScaling = "TOGGLE_CHORD_FOR_SCALING",
  SelectAllForScaling = "SELECT_ALL_FOR_SCALING",
  DeselectAllForScaling = "DESELECT_ALL_FOR_SCALING",
}

type ChordsPayload = {
  [ChordsActionTypes.Set]: {
    chords: ChordOrCursor[];
    rhythmUnit: RhythmUnit;
  };
  [ChordsActionTypes.UpdateSectionChords]: {
    sectionChords: ChordOrCursor[];
    selectedId?: number;
  };
  [ChordsActionTypes.SetSelected]: {
    selectedId: number;
  };
  [ChordsActionTypes.SetInputMode]: {
    inputMode: ChordInputMode;
  };
  [ChordsActionTypes.AddRhythmTap]: {
    tapsInSession: Tap[];
    tapIdSet: Set<string>;
    tapStatGroup: TapStatGroup[];
    tapSessionRhythmUnit: RhythmUnit;
    tappedBeatTotal: number;
    lastTappedId: string;
  };
  [ChordsActionTypes.FinishRhythmTapSession]: {
    sectionChords: ChordOrCursor[];
  };
  [ChordsActionTypes.ClearTapHistory]: {};
  [ChordsActionTypes.SetRhythmScalingOptions]: {};
  [ChordsActionTypes.ScaleTapStatGroupsBy]: {
    tapStatGroup: TapStatGroup[];
    sectionChords: ChordOrCursor[];
    scaleOptions: ScaleOption[];
    tappedBeatTotal: number;
  };
  [ChordsActionTypes.SetRhythmUnit]: {
    rhythmUnit: RhythmUnit;
  };
  [ChordsActionTypes.SetTapDurationSens]: {
    sensitivity: number;
  };
  [ChordsActionTypes.ToggleChordForScaling]: {
    chordId: string;
  };
  [ChordsActionTypes.SelectAllForScaling]: {};
  [ChordsActionTypes.DeselectAllForScaling]: {};
};

export type ChordsActions =
  ActionMap<ChordsPayload>[keyof ActionMap<ChordsPayload>];

export function chordsReducer(state: ChordsType, action: ChordsActions) {
  switch (action.type) {
    case ChordsActionTypes.Set: {
      const { sectionChords, selectedId } = state;
      let newSelectedId = selectedId;
      const cursorIndex = sectionChords.findIndex(
        (val) => val.id === CURSOR_ID
      );
      const cursorClone = {
        ...sectionChords[cursorIndex],
      };
      const { chords, rhythmUnit } = action.payload;
      const newSectionChords = chords.slice(0);
      if (
        sectionChords.length === chordsReducerInit.sectionChords.length &&
        cursorIndex === 0
      ) {
        // this is the first SET (only Cursor is present), or section has no chords besides default;
        // default cursor to end
        newSectionChords.push(cursorClone);
        // update selectedId to new cusor position
        newSelectedId = newSectionChords.length - 1;
      } else {
        // this is not the first SET (chords are present with the cursor); try to preserve cursor position and selectedId;
        if (newSectionChords.length - 1 >= cursorIndex) {
          // new sectionChords is long enough to preserve cursor position
          newSectionChords.splice(cursorIndex, 0, cursorClone);
        } else {
          newSectionChords.push(cursorClone);
        }
        if (newSectionChords.length - 1 >= newSelectedId) {
          // new sectionChords is long enough to preserve selectedId (which is an index now)
        } else {
          // new sectionChords is shorter; than old; cannot preserve index; place cursor at end;
          // prior selectedId no longer references a chord (b/c incoming chord array is smaller than prior)
          newSelectedId = newSectionChords.length - 1;
        }
      }
      const { newCursor, newCursorIndex } =
        updateCursorForContext(newSectionChords);
      newSectionChords.splice(newCursorIndex, 1, newCursor);
      return {
        ...state,
        sectionChords: newSectionChords,
        selectedId: newSelectedId,
        rhythmUnit,
        tapSessionRhythmUnit: rhythmUnit,
      };
    }

    case ChordsActionTypes.UpdateSectionChords: {
      const { sectionChords, selectedId } = action.payload;
      return {
        ...state,
        sectionChords,
        selectedId: selectedId ?? state.selectedId,
      };
    }

    case ChordsActionTypes.SetSelected: {
      const { selectedId } = action.payload;
      return {
        ...state,
        selectedId,
      };
    }

    case ChordsActionTypes.SetInputMode: {
      const { inputMode } = action.payload;
      return {
        ...state,
        inputMode,
      };
    }

    case ChordsActionTypes.ClearTapHistory: {
      return {
        ...state,
        tapsInSession: chordsReducerInit.tapsInSession,
        tapIdSet: chordsReducerInit.tapIdSet,
        tapStatGroup: chordsReducerInit.tapStatGroup,
        scaleOptions: chordsReducerInit.scaleOptions,
        canFinishRhythm: chordsReducerInit.canFinishRhythm,
        tappedBeatTotal: chordsReducerInit.tappedBeatTotal,
        tapSessionRhythmUnit: state.rhythmUnit,
      };
    }

    case ChordsActionTypes.AddRhythmTap: {
      const {
        tapsInSession,
        tapIdSet,
        tapStatGroup,
        tapSessionRhythmUnit,
        tappedBeatTotal,
        lastTappedId,
      } = action.payload;
      return {
        ...state,
        tapsInSession,
        tapIdSet,
        tapStatGroup,
        tapSessionRhythmUnit,
        tappedBeatTotal,
        // allow scaling only after "finish" is tapped;
        canFinishRhythm: true,
        lastTappedId,
      };
    }

    case ChordsActionTypes.FinishRhythmTapSession: {
      const { tapSessionRhythmUnit } = state;
      const { sectionChords } = action.payload;
      return {
        ...state,
        // after finish tap session, set rhythmUnit to tap session's value; set sectionChords to value derived from tap session values (different formats);
        sectionChords,
        rhythmUnit: tapSessionRhythmUnit,
        // allow scaling only after "finish" is tapped;
        canFinishRhythm: false,
      };
    }

    case ChordsActionTypes.SetRhythmScalingOptions: {
      const { tapStatGroup, scaleOptions } = state;
      const { availableScales } = determineAvailableScales(tapStatGroup);
      return {
        ...state,
        scaleOptions: scaleOptions.map((option) => {
          return {
            ...option,
            isAvailable: availableScales.indexOf(option.scale) > -1,
          };
        }),
      };
    }

    case ChordsActionTypes.ScaleTapStatGroupsBy: {
      const { tapStatGroup, sectionChords, scaleOptions, tappedBeatTotal } =
        action.payload;
      return {
        ...state,
        // presently tapStatGroup needs to be updated... but ideally it shouldn't... is there a way I could empty it on "finish"?
        // tapStatGroup -> tapSessionData.sectionChords -> sectionChordsWithContext -> gets used for chord duration while in "tap" mode; point of this is to allow use to undo, or other not save result of tap, by storing these vals separetly from sectionChords;
        tapStatGroup,
        sectionChords,
        scaleOptions,
        tappedBeatTotal,
      };
    }

    case ChordsActionTypes.SetRhythmUnit: {
      // const { sectionChords } = state;
      const { rhythmUnit } = action.payload;
      /* 
      const {
        scaledChordsTapStats,
        updatedSectionChords,
        availableScales,
        tappedBeatTotal,
      } = getScalingUpdates(sectionChords, tapIdSet, scale); */
      return {
        ...state,
        tapSessionRhythmUnit: rhythmUnit,
        rhythmUnit,
        // tappedBeatTotal,
      };
    }

    case ChordsActionTypes.SetTapDurationSens: {
      const { sensitivity } = action.payload;
      return {
        ...state,
        tapDurationSens: sensitivity,
      };
    }

    case ChordsActionTypes.ToggleChordForScaling: {
      const { tapIdSet, scaleOptions, sectionChords } = state;
      const { chordId } = action.payload;
      var updatedTapIdSet = new Set(tapIdSet);
      if (updatedTapIdSet.has(chordId)) {
        updatedTapIdSet.delete(chordId);
      } else {
        updatedTapIdSet.add(chordId);
      }
      // only uses a subset of getScalingUpdates (which is primarily meant to apply scaling change); ideally would break out function of determineAvailableScales for clean and easy use here;
      const {
        // scaledChordsTapStats,
        // updatedSectionChords,
        availableScales,
        tappedBeatTotal,
      } = getScalingUpdates(sectionChords, updatedTapIdSet, 1);
      return {
        ...state,
        tapIdSet: updatedTapIdSet,
        scaleOptions: scaleOptions.map((option) => {
          return {
            ...option,
            isAvailable: availableScales.indexOf(option.scale) > -1,
          };
        }),
        tappedBeatTotal,
      };
      // sectionChords: updatedSectionChords,
      // try not to use... should be needed only for "tap" mode, not for "scale"
      // tapStatGroup: scaledChordsTapStats,
    }

    case ChordsActionTypes.SelectAllForScaling: {
      const { scaleOptions, sectionChords } = state;
      let updatedTapIdSet = new Set<string>(
        sectionChords.filter((val) => val.id !== CURSOR_ID).map((val) => val.id)
      );
      const { availableScales, tappedBeatTotal } = getScalingUpdates(
        sectionChords,
        updatedTapIdSet,
        1
      );
      return {
        ...state,
        tapIdSet: updatedTapIdSet,
        scaleOptions: scaleOptions.map((option) => {
          return {
            ...option,
            isAvailable: availableScales.indexOf(option.scale) > -1,
          };
        }),
        tappedBeatTotal,
      };
    }

    case ChordsActionTypes.DeselectAllForScaling: {
      const { scaleOptions, sectionChords } = state;
      let updatedTapIdSet = new Set<string>();
      const { availableScales, tappedBeatTotal } = getScalingUpdates(
        sectionChords,
        updatedTapIdSet,
        1
      );
      return {
        ...state,
        tapIdSet: updatedTapIdSet,
        scaleOptions: scaleOptions.map((option) => {
          return {
            ...option,
            isAvailable: availableScales.indexOf(option.scale) > -1,
          };
        }),
        tappedBeatTotal,
      };
    }

    default:
      return state;
  }
}
