import React from "react";

import { useSimpleTouchHelpers } from "./useSimpleTouch.helpers";
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from "../../_plugins/Haptics";

const MIN_TIME_BETWEEN_HAPTICS = 200;

type Props = {
  actionOnTouchEnd?: () => void;
  useHaptics?: boolean;
};

export const useSimpleTouch = (props: Props) => {
  const { handleTouchMove, handleTouchEnd } = useSimpleTouchHelpers();

  const [isActive, setIsActive] = React.useState(false);

  // if you try to use HTMLElement, to make type less specific and thus this fnc more versatile, will likely receive errors about the type of element you apply this ref to, e.g. a div, having properties that HTMLElement lacks; if want to make this component more flexible, find way to allow it to receive type from consuming component, directly or by inference
  const touchRef = React.useRef<HTMLDivElement>(null);
  const lastHoverHapticTime = React.useRef<number>(0);

  function handleSetActive() {
    if (isActive) return;
    props.useHaptics && hapticsImpactLight();
    lastHoverHapticTime.current = Date.now();
    setIsActive(true);
  }

  function handleRemoveActive() {
    setIsActive(false);
  }

  function setSelected() {
    if (!props.actionOnTouchEnd) return;
    if (Date.now() - MIN_TIME_BETWEEN_HAPTICS >= lastHoverHapticTime.current) {
      // if user simply "taps" an element, don't want both "active" and "selected" haptics firing; suppress the "selected" haptic if too soon after most recent "active"
      props.useHaptics && hapticsImpactMedium();
    }
    props.actionOnTouchEnd();
  }

  // *** was appealing to send the callback (setSelected) directly inline with the event handler callback, but ends up causing trouble b/c(???) it ends up using the wrong reference...?  It manifested itself by causing my clicks on a root to actually select a quality on the next tab (which is auto-switched-to upon selection), and the same occurred there, an apparent click on a quality actually selected a note on the next tab (bass notes); binding the callback here - within the render of the component - has no such problem
  function touchEvent(e: React.TouchEvent | TouchEvent) {
    // wanted to suppress default when used with GKeyboard, but here - at least for use with DurationSlider - want to allow events to interact as usual, otherwise slider will not behave as expected
    // e.preventDefault();
    handleTouchMove(e, handleSetActive);
  }
  // function touchMoveEvent(e: React.TouchEvent | TouchEvent) {}
  function touchEndEvent(e: React.TouchEvent | TouchEvent) {
    handleTouchEnd(e, setSelected, handleRemoveActive);
  }

  /*   function mouseDownEvent(e: React.MouseEvent | MouseEvent) {
    handleMouseDown(e, setSelected);
  }
  function mouseMoveEvent(e: React.MouseEvent | MouseEvent) {
    handleMouseMove(e, handleSetActive);
  } */

  function applyListeners(ref: React.RefObject<HTMLDivElement>) {
    let target = ref?.current;
    if (!target) return;
    target.addEventListener("touchstart", touchEvent, {
      passive: true,
    });
    target.addEventListener("touchend", touchEndEvent, {
      passive: true,
    });
    target.addEventListener("touchcancel", touchEndEvent, {
      passive: true,
    });
    /*     target.addEventListener("touchmove", touchMoveEvent, {
      passive: true,
    }); */
    // target.addEventListener("mousedown", mouseDownEvent);
    // target.addEventListener("mousemove", mouseMoveEvent);
  }

  function removeListeners(ref: React.RefObject<HTMLDivElement>) {
    let target = ref?.current;
    if (!target) return;
    target.removeEventListener("touchstart", touchEvent);
    target.removeEventListener("touchend", touchEndEvent);
    target.removeEventListener("touchcancel", touchEndEvent);
    // target.removeEventListener("touchmove", touchMoveEvent);
    // target.removeEventListener("mousedown", mouseDownEvent);
    // target.removeEventListener("mousemove", mouseMoveEvent);
  }

  React.useEffect(() => {
    applyListeners(touchRef);
    return function cleanup() {
      removeListeners(touchRef);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [touchRef /*  applyListeners, removeListeners */]);

  return { touchRef, isActive };
};

// *** use React synthetic events instead of vanilla dom events?
// attempted to use React synthetic events (e.g. onTouchStart), but could not use preventDefault(): in Chrome, received error "Unable to preventDefault inside passive event listener invocation"; known issue, no obvious solution, so reverted to attaching vanilla event listeners via ref
// had some head scratching when first implemented with vanilla listeners: listeners used old prop value, namely the prop value on first render; explanation appears to be something like: the listener fnc grabs the prop value via enclosure when it is applied, but on re-render the component scope the value came from effectively disappears... so it's still using the value from original render; need to add new listeners with updated scope when dependent variables change, using useEffect (and need to remove the prior set of listeners)
