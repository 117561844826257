import React from "react";

import { ChordOrCursor, rhythmUnitOptions } from "../../../_types";

import Button from "../../../_components/Button";
import { HelpModal } from "../../../_components/HelpModal";
import { useTap } from "../../../_context/chordContext";
import { Icon } from "../../../_components/Icon";
import Spacer from "../../../_components/Spacer";
import {
  ResetButton,
  ResetButtonWrapper,
  ScaleButton,
  ScaleButtonText,
  ShowTappedBeatTotal,
  TapButtonRow,
  TapGroupWrapper,
} from "./TapGroupShared";

type Props = {
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
};

export function TapDuration(props: Props) {
  const {
    inputMode,
    tapDurationSens,
    toggleTapDurationSens,
    canFinishRhythm,
    finishRhythmTap,
    tappedBeatTotal,
    scaleOptions,
    canScale,
    scaleTapStatGroupsBy,
    rhythmUnit,
    setRhythmUnit,
    clearTapHistory,
  } = useTap({
    callBackAfterUpdateChords: props.handleUpdateSectionChords,
  });

  return (
    <TapGroupWrapper>
      <TapButtonRow>
        <Button
          onTouchStart={finishRhythmTap}
          expand="block"
          // iconId="check-square"
          size={"lg"}
          disabled={!canFinishRhythm}
          palette={canFinishRhythm ? "primary" : "mono"}
          fill={canFinishRhythm ? "fill" : "clear"}
        >
          {canFinishRhythm
            ? "Finish"
            : canScale
            ? "Scale or re-tap"
            : "Tap chords"}
        </Button>
        <Spacer size={8} axis="horiz" />
        <ResetButtonWrapper>
          <ResetButton
            onClick={clearTapHistory}
            size="sm"
            fill="clear"
            // fill="outline"
            palette={canFinishRhythm ? "text" : "disabled"}
            disabled={!canFinishRhythm}
            // iconId={"rotate-ccw"}
          >
            <Icon id="rotate-ccw" />
          </ResetButton>
        </ResetButtonWrapper>
      </TapButtonRow>
      <TapButtonRow>
        {/* *** Would like to show total tapped beats, but as your logic presently works, rhythm duration is determined "live" based on addChartContext and countProgressionRhythm, setting chord.rhythmDuration for tapped chords; you'd need to wire differently than you did here originally, where the duration was available because it was a saved property of the chords; */}
        {/* <ShowTappedBeatTotal tappedBeatTotal={tappedBeatTotal} /> */}
        {/* <Spacer size={24} axis="horiz" /> */}
        <Button
          onClick={toggleTapDurationSens}
          size="sm"
          fill="clear"
          disabled={inputMode !== "tap"}
          palette={inputMode !== "tap" ? "disabled" : "text"}
          iconId={tapDurationSens === 1 ? "toggle-left" : "toggle-right"}
        >
          {tapDurationSens === 1 ? "Norm Sens" : "High Sens"}
        </Button>
      </TapButtonRow>
        {/* Rhythm Subdivision */}
      <TapButtonRow>
        {rhythmUnitOptions.map((option) => {
          return (
            <ScaleButton
              key={option}
              onClick={() => setRhythmUnit(option)}
              expand="inline"
              size="sm"
              disabled={option === rhythmUnit}
              fill="clear"
              palette={option !== rhythmUnit ? "text" : "primary"}
            >
              <ScaleButtonText>
                {`1/${option}`}
              </ScaleButtonText>
            </ScaleButton>
          );
        })}
        {/* {scaleOptions.map((option) => {
          const { scale, isAvailable } = option;
          return (
            <ScaleButton
              key={scale}
              onClick={() => scaleTapStatGroupsBy(scale)}
              expand="inline"
              size="sm"
              disabled={!isAvailable}
              fill="clear"
              // fill="outline"
              palette={isAvailable ? "text" : "disabled"}
            >
              <ScaleButtonText>
                {scale < 1 ? `1/${Math.round(1 / scale)}` : `${scale} x`}
              </ScaleButtonText>
            </ScaleButton>
          );
        })} */}
        <HelpModal label="Tap Rhythm">
          <h3>Tap Rhythm</h3>
          <p>
            Tap chords to add rhythm to your notation. Tap any chord to start.
            Tap "Finish" (keeping rhythm!) to stop.
          </p>
          <p>
            <b>Redo</b> - if you're unhappy with your rhythm, tap the undo
            button or simply re-tap any chord.
          </p>
          {/* <p>
            <b>Scaling</b> - after you "Finish", you can scale the chords you
            tapped. Scaling maintains ratios, while changing overall duration by
            1/3, 1/2, 2x, or 3x.
          </p> */}
          <p>
            <b>Subdivision</b> - we will auto-select a rhythm subdivision that fits your taps, but you may override our selection. Choose 1/4, 1/8, or 1/16 notes.
          </p>
          <p>
            <b>Tip</b> - tap as if you were playing an instrument.
          </p>
          <p>
            <b>Sensitivity</b> - for complex rhythms, you may need to use "High
            Sens[itivity]". This will reduce how much we correct your rhythm,
            giving you more flexibility... but requiring tighter timing when you
            tap!
          </p>
        </HelpModal>
      </TapButtonRow>
    </TapGroupWrapper>
  );
}
