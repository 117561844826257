import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";

import { UserSongsList_user$key } from "../__generated__/UserSongsList_user.graphql";
import { UserSongsList_songs$key } from "../__generated__/UserSongsList_songs.graphql";

import { getNodeArrayFromEdges } from "../_types/graphqlData";
import { typeNotEmpty } from "../_types/typeHelpers";

// import { VirtualCardList } from "../_components/VirtualCardList";
import { aggregateTags } from "../_utilities/tagUtils";
import { SongListItem } from "./SongListItem";
import { createSortedDeck, filterSongs } from "../Leitner/leitner";
import Button from "../_components/Button";
import Spacer from "../_components/Spacer";
import { getSongStats } from "../_utilities/cardStatsCombined";
import { Modal } from "../_components/Modal";
import { DeleteSong } from "../SongMeta/DeleteSong";
import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers/appReducer";
import { SongManageShare } from "../SongMeta/SongManageShare";
import { SongManageShareSharee } from "../SongMeta/SongManageShareSharee";
import { SongClone } from "../SongMeta/SongClone";
// import { UpgradeFeature } from "../InAppPurchase/UpgradeFeature";
import { ModalHeader } from "../_style-components/Modal.style";
import { TitleArtist } from "../_style-components/TitleArtist";
import { ShareSongPDF } from "../SongMeta/ShareSongPDF";
// import { useDebugLog } from "../_hooks/useDebugLog";

export type ShareRelation = {
  // userId: string;
  email: string;
  shareCount: number;
};
type ShareRelationMap = Map<string, ShareRelation>;

type Props = {
  userRef: UserSongsList_user$key;
  songsRef: UserSongsList_songs$key;
  searchFilter: string;
  tagFilter: string[];
  setAvailSongTags: React.Dispatch<
    React.SetStateAction<{
      tagSet: string[];
      tagCounts: Record<string, number>;
    }>
  >;
};

// due to all of the scroll/touch magic in Page/PageContent, there are many re-renders; memoize this component to prevent loads of unnecessary renders while scrolling/toucing (e.g. pull-to-refresh);
export const UserSongsList = React.memo(UserSongsListComponent);
function UserSongsListComponent(props: Props) {
  // export function UserSongsList(props: Props) {
  const history = useHistory();
  function handleTestNav() {
    history.push("/test");
  }

  const userData = useFragment(
    graphql`
      fragment UserSongsList_user on User {
        id
        boxIntervals
        # querying more than needed for this component, to pre-populate Settings page
        email
        emailConfirmed
        testHistory {
          song
          eventType
          time
        }
        ...SongManageShare_user
      }
    `,
    props.userRef
  );

  const songData = useFragment(
    graphql`
      fragment UserSongsList_songs on User {
        songs(first: 2147483647) @connection(key: "UserSongs_songs") {
          __id
          edges {
            node {
              ...SongListItem_song
              ...SongManageShare_song
              ...SongManageShareSharee_song
              ...DeleteSong_song
              ...ShareSongPDF_song
              id
              title
              artist
              tags
              permissions {
                user
                email
                isOwner
                canEdit
                didAccept
              }
              viewerPermission {
                user
                email
                isOwner
                canEdit
                didAccept
              }
              # querying more than needed for this component, to pre-populate Leitner (and... once I figure it out, Song display/edit)
              #...SectionsOnly_song
              # notes
            }
          }
        }
      }
    `,
    props.songsRef
  );

  const { id: userId, boxIntervals, testHistory } = userData;
  const { songs } = songData;

  // memoize for use in songNodesWithStats, which is used in useEffect; GraphQL will not change value of songs on every render, so it is safe object to rely upon in dependency
  const songNodes = React.useMemo(
    () => getNodeArrayFromEdges(songs).filter(typeNotEmpty),
    [songs]
  );

  // memoize for use in useEffect
  const songNodesWithStats = React.useMemo(
    () =>
      songNodes.map((node) => {
        const songTestHistory = testHistory.filter(
          (val) => val.song === node.id
        );
        return {
          ...node,
          // songStats: getSongStats(node.testHistory),
          songStats: getSongStats(songTestHistory),
          testHistory: songTestHistory,
        };
      }),
    [songNodes]
  );

  const [filteredSongs, setFilteredSongs] = React.useState(songNodesWithStats);

  const { sortedDueToday, tagsDueToday } = createSortedDeck(
    // exclude non-accepted shared songs from leitner review deck
    filteredSongs.filter((song) => song.viewerPermission.didAccept === true),
    boxIntervals
  );

  const { searchFilter, tagFilter, setAvailSongTags } = props;
  React.useEffect(() => {
    const filtered = filterSongs(songNodesWithStats, tagFilter, searchFilter);
    setFilteredSongs(filtered);
    const { tagSet, tagCounts } = aggregateTags(filtered, tagFilter);
    setAvailSongTags({ tagSet, tagCounts });
  }, [songNodesWithStats, searchFilter, tagFilter, setAvailSongTags]);

  const [shareRelations, setShareRelations] = React.useState<ShareRelation[]>(
    []
  );

  React.useEffect(() => {
    const shareRelationMap: ShareRelationMap = new Map();

    songNodes.forEach((node) => {
      node.permissions.forEach((permission) => {
        let shareUser = shareRelationMap.get(permission.email);
        if (shareUser) {
          shareRelationMap.set(permission.email, {
            ...shareUser,
            shareCount: shareUser.shareCount + 1,
          });
          // do not add entry for logged in user (i.e. don't identify user as a being in a shareRelation with himself)
        } else if (permission.user !== userId) {
          shareRelationMap.set(permission.email, {
            email: permission.email,
            shareCount: 1,
          });
        }
      });
    });

    const shareRelationArray: ShareRelation[] = [];
    // required "downlevelIteration": true, to be set in tsconfig.json
    // https://stackoverflow.com/questions/53441292/why-downleveliteration-is-not-on-by-default
    for (let [key, val] of shareRelationMap) {
      shareRelationArray.push(val);
    }

    setShareRelations(shareRelationArray);
  }, [songNodes, setShareRelations]);

  /* React.useEffect(() => {
    const filtered = filterSongs(
      songNodesWithStats,
      props.tagFilter,
      props.searchFilter
    );
    setFilteredSongs(filtered);
    const { tagSet, tagCounts } = aggregateTags(filtered, props.tagFilter);
    props.setAvailSongTags({ tagSet, tagCounts });
  }, [songNodesWithStats, props.searchFilter, props.tagFilter]); */

  const [songContextModal, setSongContextModal] = React.useState({
    doShow: false,
    // hacky way to type song, though effective, given that this is the only way I set it...
    song: songNodes.find((val) => val.id === ""),
  });

  React.useEffect(() => {
    // purpose of this useEffect: needed to typeguard the songContextModal, so wrapped it to ensure it would display only if its target song existed; but, when deleting the song, that would cause it to be dismounted immediately, so 1) wouldn't get the nice animation out, 2) it would take with it any modals it contained, preventing me from e.g. leaving a message modal longer for a user to see, and 3) it could result in the DeleteSong component still trying to update state after being unmounted (due to its parent, this modal, being unmounted), which again would limit my ability to act in that component, and would throw an annoying "tried to update state on unmounted component" error; solution: keep song node in state; but, need to be sure data isn't stale, so use useEffect to update it, but *only* if the node exists; if it doesn't keep the stale data of the [deleted] song, to ensure modal continues to display as expected until dismissed;
    const newSongSelectedForModal = songNodes.find(
      (val) => val.id === songContextModal.song?.id
    );
    if (newSongSelectedForModal) {
      setSongContextModal((prev) => ({
        ...prev,
        song: newSongSelectedForModal,
      }));
    }
  }, [songNodes]);

  function openDialog(id: string) {
    const newSongSelectedForModal = songNodes.find((val) => val.id === id);
    setSongContextModal({
      doShow: true,
      song: newSongSelectedForModal,
    });
  }

  function closeDialog() {
    setSongContextModal((prev) => ({ ...prev, doShow: false }));
  }

  const { state, dispatch } = React.useContext(AppContext);

  function clearFilters() {
    dispatch({
      type: AppActionTypes.ClearFilters,
      payload: null,
    });
  }

  // *** ... you've run into this again... this component also consumes AppContext, so you create an infinite render loop; I suggest spinning debugLog state into its own context, if you plan to use it broadly across components for on-device debugging; you could follow suit of uniqueUserContext for a simpler setup; you would not immediately have localStorage save/restore, though (you'd have to add similar to what is in AppContext); only a problem when you want to see certain logs created as app exits, so probably not a concern for now;
  /*   const { setDebugLog } = useDebugLog("UserSongsList");
  setDebugLog(["Rendering"], "render"); */

  return (
    <>
      <SongList>
        {/*  <ListStats>
          <StatWrapper>{filteredSongs.length} songs</StatWrapper>
        </ListStats> */}
        <ReviewButton
          onClick={handleTestNav}
          fill="clear"
          size="sm"
          iconId="book-open"
          disabled={sortedDueToday.length === 0}
          palette={sortedDueToday.length > 0 ? "primary" : "mono"}
        >
          {sortedDueToday.length === 0
            ? `${filteredSongs.length} song${
                filteredSongs.length !== 1 ? "s" : ""
              }`
            : `${filteredSongs.length} song${
                filteredSongs.length !== 1 ? "s" : ""
              } - ${sortedDueToday.length} due for review`}
        </ReviewButton>
        {filteredSongs
          .sort((a, b) => {
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            } else if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            // place shared, unaccepted songs at top of song list
            let aViewerDidAccept = a.viewerPermission.didAccept;
            let bViewerDidAccept = b.viewerPermission.didAccept;
            if (aViewerDidAccept && !bViewerDidAccept) {
              return 1;
            } else if (!aViewerDidAccept && bViewerDidAccept) {
              return -1;
            } else {
              return 0;
            }
          })
          .map((song) => {
            return (
              <SongListItem
                key={song.id}
                songRef={song}
                tagFilter={props.tagFilter}
                openDialog={() => openDialog(song.id)}
              />
            );
          })}
        {songNodes.length - filteredSongs.length > 0 && (
          <>
            {/* <Spacer size={8} axis="vert" /> */}
            <Button
              onClick={clearFilters}
              fill="clear"
              size="sm"
              iconId="eye-off"
              palette="mono"
            >
              {songNodes.length - filteredSongs.length} songs hidden by filters
              {/* {filteredSongs.length}/{songNodes.length} songs shown; show all */}
            </Button>
          </>
        )}
      </SongList>
      {/*  <VirtualCardList
        keys={Object.keys(filteredSongs)}
        listItem={(key) => {
          const song = filteredSongs[parseInt(key)];
          return (
            <SongListItem
              key={song.id}
              id={song.id}
              title={song.title}
              artist={song.artist}
              tags={song.tags}
            />
          );
        }}
      /> */}
      {songContextModal.song !== undefined && (
        <Modal
          doShowDialog={songContextModal.doShow}
          closeDialog={closeDialog}
          label={`Options for ${songContextModal.song.title}`}
        >
          <ModalBody>
            <ModalHeader>
              <TitleArtist
                title={songContextModal.song.title}
                artist={songContextModal.song.artist}
              />
            </ModalHeader>

            {songContextModal.song.viewerPermission.isOwner === true && (
              <>
                {/* @@@ disable in-app purchases @@@ */}
                {/* 
                <UpgradeFeature
                  productGroupName={"pro"}
                  featureName="Sharing"
                  lockIconOffset={[8, 8]}
                  lockIconOffsetUnit="px"
                >
                  <SongManageShare
                    songRef={songContextModal.song}
                    userRef={userData}
                    shareRelations={shareRelations}
                  />
                </UpgradeFeature>
                */}
                <SongManageShare
                  songRef={songContextModal.song}
                  userRef={userData}
                  shareRelations={shareRelations}
                />
                <Button
                  onClick={() =>
                    // for some reason, the two instances of history.push needed an extra type guard, starting when I changed the source of "song" from a simple var (calc'd at render) to a state object property (songContextModal.song`); the "songContextModal.song !== undefined" that wraps the entire modal is sufficient for all other references in the modal, but still seen as potentially undefined here;
                    songContextModal.song &&
                    history.push(`/song-edit/${songContextModal.song.id}`)
                  }
                  palette="primary"
                  fill="clear"
                  iconId="edit"
                >
                  Edit{" "}
                  {songContextModal.song.permissions.length > 1 ? "Shared" : ""}{" "}
                  Song
                </Button>
              </>
            )}

            {songContextModal.song.viewerPermission.isOwner === false && (
              <>
                {/* <Spacer axis="vert" size={8} /> */}
                <SongManageShareSharee
                  songRef={songContextModal.song}
                  userId={userId}
                  callback={closeDialog}
                />
                <ShareSongPDF
                  songRef={songContextModal.song}
                  shareRelations={shareRelations}
                  userEmail={userData.email}
                />
                {
                  // sharees must have an active subscription to participate in shared editing
                  songContextModal.song.viewerPermission.didAccept &&
                    songContextModal.song.viewerPermission.canEdit && (
                      // @@@ disable in-app purchases @@@
                      /* 
                      <UpgradeFeature
                        productGroupName={"pro"}
                        featureName="Shared Editing"
                        lockIconOffset={[8, 8]}
                        lockIconOffsetUnit="px"
                      >
                        <Button
                          onClick={() =>
                            // for some reason, the two instances of history.push needed an extra type guard, starting when I changed the source of "song" from a simple var (calc'd at render) to a state object property (songContextModal.song`); the "songContextModal.song !== undefined" that wraps the entire modal is sufficient for all other references in the modal, but still seen as potentially undefined here;
                            songContextModal.song &&
                            history.push(
                              `/song-edit/${songContextModal.song.id}`
                            )
                          }
                          palette="primary"
                          fill="clear"
                          iconId="edit"
                        >
                          Edit{" "}
                          {songContextModal.song.permissions.length > 1
                            ? "Shared"
                            : ""}{" "}
                          Song
                        </Button>
                      </UpgradeFeature>
                       */
                      <Button
                        onClick={() =>
                          // for some reason, the two instances of history.push needed an extra type guard, starting when I changed the source of "song" from a simple var (calc'd at render) to a state object property (songContextModal.song`); the "songContextModal.song !== undefined" that wraps the entire modal is sufficient for all other references in the modal, but still seen as potentially undefined here;
                          songContextModal.song &&
                          history.push(`/song-edit/${songContextModal.song.id}`)
                        }
                        palette="primary"
                        fill="clear"
                        iconId="edit"
                      >
                        Edit{" "}
                        {songContextModal.song.permissions.length > 1
                          ? "Shared"
                          : ""}{" "}
                        Song
                      </Button>
                    )
                }
              </>
            )}

            {songContextModal.song.viewerPermission.didAccept && (
              <SongClone
                songId={songContextModal.song.id}
                userId={userId}
                callback={closeDialog}
              />
            )}
            {songContextModal.song.viewerPermission.isOwner && (
              <DeleteSong
                songRef={songContextModal.song}
                userId={userId}
                // *** would like to dismiss parent modal when open delete modal... but of course won't work b/c parent contains delete and would destroy it; only way for this to work would be to spin delete modal out of here, and separately control its state, but not presently worthwhile
                // handleOnModalOpen={closeDialog}
                callback={closeDialog}
              />
            )}
            {/*   <Button
            onClick={closeDialog}
            fill="ghost"
            // size="sm"
            iconId="x"
          >
            Cancel
          </Button> */}
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

const SongList = styled.div``;

const ReviewButton = styled(Button)`
  font-size: ${15 / 16}rem;
`;

const ListStats = styled.div`
  padding: 4px 12px;
  color: var(--color-text-medium);
`;
const StatWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const ModalBody = styled.div`
  /* padding: 0 16px; */
`;
