import React from "react";
import { USER_ROLE_OBJ } from "../_constants/userRole";

import { Intro } from "../Intro";
import { LoginCreate } from "../LoginCreate";
import { Settings } from "../Settings";
import { Account } from "../Account";
import { ConfirmEmail } from "../ConfirmEmail";
import { ForgotPassword } from "../ForgotPassword";
import { ResetPassword } from "../ResetPassword";
import { Logout } from "../Logout";

import { UserSongs } from "../UserSongs";
import { Song } from "../Song";
import { SongEdit } from "../SongEdit";
import { SongMeta } from "../SongMeta";

import { Section } from "../Section";
import { SectionMeta } from "../SectionMeta";

import { LeitnerPage } from "../Leitner";
import { LeitnerSettings } from "../LeitnerSettings";

import { Admin } from "../Admin";

export function stripParamsFromPath(path: string) {
  return "/" + path.split("/")[1];
}

type Route = {
  path: string;
  params?: string;
  allowedRoles: USER_ROLE_OBJ[];
  component: React.FC;
};

export const routes: Array<Route> = [
  {
    path: "/intro",
    allowedRoles: [USER_ROLE_OBJ.ANON],
    component: Intro,
  },
  {
    path: "/login-create",
    allowedRoles: [USER_ROLE_OBJ.ANON],
    component: LoginCreate,
  },
  {
    path: "/confirm-email",
    params: "/:id/:token",
    allowedRoles: [USER_ROLE_OBJ.ANON, USER_ROLE_OBJ.USER],
    component: ConfirmEmail,
  },
  {
    path: "/reset-password",
    params: "/:id/:token",
    allowedRoles: [USER_ROLE_OBJ.ANON, USER_ROLE_OBJ.USER],
    component: ResetPassword,
  },
  {
    path: "/logout",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: Logout,
  },
  {
    path: "/user-songs",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: UserSongs,
  },
  {
    path: "/settings",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: Settings,
  },
  {
    path: "/account",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: Account,
  },
  {
    path: "/forgot-password",
    allowedRoles: [USER_ROLE_OBJ.ANON, USER_ROLE_OBJ.USER],
    component: ForgotPassword,
  },
  {
    path: "/test",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: LeitnerPage,
  },
  {
    path: "/leitner-settings",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: LeitnerSettings,
  },
  {
    path: "/song",
    params: "/:songId?",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: Song,
  },
  {
    path: "/song-edit",
    params: "/:songId?",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: SongEdit,
  },
  {
    path: "/song-meta",
    params: "/:songId?",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: SongMeta,
  },
  {
    path: "/section",
    params: "/:songId?/:sectionId?",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: Section,
  },
  {
    path: "/section-meta",
    params: "/:songId?/:sectionId?",
    allowedRoles: [USER_ROLE_OBJ.USER],
    component: SectionMeta,
  },
  {
    path: "/admin",
    allowedRoles: [USER_ROLE_OBJ.ADMIN],
    component: Admin,
  },
  // leaving path undefined (or defining but not allowing any roles) causes RouteRoleWrapper to route to default for each role
  // {
  //     path: "/",
  // },
  {
    // must come last of all routes, public and private (this is your catch-all or "404")
    path: "*",
    // allowedRoles: [USER_ROLE_OBJ.ADMIN, USER_ROLE_OBJ.USER, USER_ROLE_OBJ.ANON],
    allowedRoles: [],
    component: LoginCreate,
  },
];

export const routeOrder = routes.map((route) => route.path);
interface RouteTable {
  [index: string]: Route;
}
export const routeTable: RouteTable = {};
routes.forEach((route) => {
  routeTable[route.path] = route;
});
