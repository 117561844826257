import { Plugins } from '@capacitor/core';

const { App } = Plugins;

export function onAppStateChange({ onActive, onInactive } = {}) {
    App.addListener('appStateChange', (state) => {
        if (state.isActive) {
            console.log("%cApp active", "color: blue;");
            onActive()
        } else {
            console.log("%cApp INactive", "color: red;");
            onInactive()
        }
    });
}