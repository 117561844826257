/**
 * @generated SignedSource<<b48ea31d5fc45c93059e8e8c8d683961>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongUpdateShare_permission$data = {
  readonly canEdit: boolean;
  readonly didAccept: boolean;
  readonly email: string;
  readonly isOwner: boolean;
  readonly user: string;
  readonly " $fragmentType": "SongUpdateShare_permission";
};
export type SongUpdateShare_permission$key = {
  readonly " $data"?: SongUpdateShare_permission$data;
  readonly " $fragmentSpreads": FragmentRefs<"SongUpdateShare_permission">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SongUpdateShare_permission",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "didAccept",
      "storageKey": null
    }
  ],
  "type": "SongPermission",
  "abstractKey": null
};

(node as any).hash = "62ec0c9e07441b12e094d94636836fa3";

export default node;
