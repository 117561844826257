import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { DeleteSongMutation } from "../__generated__/DeleteSongMutation.graphql";

import Button from "../_components/Button";
import { Modal } from "../_components/Modal";
import { DeleteSong_song$key } from "../__generated__/DeleteSong_song.graphql";
import { ModalError } from "../_components/ModalError";
import { isApiError } from "../_types/errorTypes";
import {
  ModalContent,
  ModalHeader,
  ModalTitle,
} from "../_style-components/Modal.style";
import Spacer from "../_components/Spacer";
import { TitleArtist } from "../_style-components/TitleArtist";

/* these imports... bring in different versions of ConnectionHandler?  .getConnectionID() is unavailable on the import, but available on the "require" */
// import { ConnectionHandler } from "relay-runtime";
const { ConnectionHandler } = require("relay-runtime");

// *** seems this component should be receiving a fragment, not explicit variables, given that those variables are pulled directly from the GraphQL schema
type Props = {
  songRef: DeleteSong_song$key;
  userId: string;
  callback?: () => void;
};

export function DeleteSong(props: Props) {
  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");

  const song = useFragment(
    graphql`
      fragment DeleteSong_song on Song {
        id
        title
        artist
        mongoVersion
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const [commit, isInFlight] = useMutation<DeleteSongMutation>(graphql`
    mutation DeleteSongMutation(
      $connections: [ID!]!
      $input: deleteSongInput!
    ) {
      deleteSong(input: $input) {
        song {
          # update song to latest from server if delete fails, to ensure user knows what they're deleting; (e.g. they had a stale version, or a sharing user is making edits)
          id
          mongoVersion
          title
          artist
          notes
          tags
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
          editLock {
            isLocked
            lockedBy
            lockedByEmail
            lockedUntil
          }
          isLockedByUser
        }
        deletedSong {
          id @deleteEdge(connections: $connections)
          title
          artist
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function openConfirmModal() {
    setDoShowDialog(true);
  }

  function closeConfirmModal() {
    setDoShowDialog(false);
  }

  function handleDeleteSong() {
    const connectionID = ConnectionHandler.getConnectionID(
      props.userId,
      "UserSongs_songs"
    );
    commit({
      variables: {
        input: {
          songId: song.id,
          mongoVersion: song.mongoVersion,
        },
        connections: [connectionID],
      },
      onCompleted: (response) => {
        // close confirmation modal whether success or failure
        closeConfirmModal();
        if (response.deleteSong) {
          const { deletedSong, errors } = response.deleteSong;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
          if (deletedSong) {
            // if song deleted, also close song-related context modal (if any), and navigate away from now-deleted song's page (if applicable)
            props.callback && props.callback();
          }
        }
      },
      onError: (err) => {
        // close confirmation modal whether success or failure
        closeConfirmModal();
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <Button
        onClick={openConfirmModal}
        fill="clear"
        palette="danger"
        // size="sm"
        iconId={"trash-2"}
        disabled={isInFlight}
      >
        Delete Song
      </Button>

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={closeConfirmModal}
        label={"Delete Song"}
        handleConfirm={handleDeleteSong}
        actionText={"Delete"}
        actionColor="danger"
        isInFlight={isInFlight}
        showCloseButton={true}
      >
        <ModalHeader>
          <TitleArtist title={song.title} artist={song.artist} />
        </ModalHeader>
        <ModalTitle>Delete Song</ModalTitle>
        <ModalContent>
          <p>
            <TextEmpasis>This cannot be undone.</TextEmpasis>
          </p>
          {song.permissions.length > 1 && (
            <p>
              Each user who is sharing this song will receive their own copy.
            </p>
          )}
        </ModalContent>
      </Modal>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const ModalBody = styled.div`
  padding: 0px 16px;
  /* font-size: ${16 / 16}rem; */
`;

const SongTitle = styled.span`
  font-weight: 500;
`;

const TextEmpasis = styled.span`
  /* font-style: italic; */
`;
