import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import graphql from "babel-plugin-relay/macro";
import { FetchPolicy, useMutation, useFragment } from "react-relay/hooks";
import { useTransition, animated, config } from "@react-spring/web";

import {
  addUserTestEventInput,
  AddSongTestEventMutation,
  TestEventType,
} from "../__generated__/AddSongTestEventMutation.graphql";

import type { CustomErrors } from "../_types/errorTypes";

import { FriendlyErrors } from "../_components/FriendlyErrors";
import { TwoWayDrag } from "./TwoWayDrag";
import SongQuery from "../Song/SongQuery";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { LeitnerCard } from "./leitner";
import { PageFieldsContext } from "./LeitnerPage";
import Spacer from "../_components/Spacer";
import { TEST_EVENT_TYPE_OBJ } from "../_utilities/cardStatsCombined";
import { LeitnerFooter } from "./LeitnerFooter";
import { PageContentData } from "../_components/Page/PageContentData";
import { HelpModal } from "../_components/HelpModal";
import { AddSongTestEvent_user$key } from "../__generated__/AddSongTestEvent_user.graphql";
import { SongControls } from "../_components/SongControls";

type Props = {
  userRef: AddSongTestEvent_user$key;
  leitnerCard: LeitnerCard;
  boxIntervals: readonly number[];
  countSongsDue: number;
};

export function AddSongTestEvent(props: Props) {
  const user = useFragment(
    graphql`
      fragment AddSongTestEvent_user on User {
        id
        testHistory {
          song
          eventType
          time
        }
      }
    `,
    props.userRef
  );

  const [pageFields, setSomePageFields] = React.useContext(PageFieldsContext);

  let [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);
  let history = useHistory();

  const { id, title, artist, tags, testHistory, songStats } = props.leitnerCard;

  const lastEvent = testHistory && testHistory[testHistory.length - 1];
  // if no prior events, treat same as if last event was "reviewed"
  const lastEventType = lastEvent?.eventType || ("REVIEWED" as TestEventType);
  const displayMode = lastEventType === "REVIEWED" ? "TEST" : "REVIEW";

  const [commit, isInFlight] = useMutation<AddSongTestEventMutation>(graphql`
    mutation AddSongTestEventMutation($input: addUserTestEventInput!) {
      addUserTestEvent(input: $input) {
        user {
          id
          testHistory {
            song
            eventType
            time
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  // this component receives a filtered subset of user.testHistory; but to handle optimisticUpdate, have to mimic the base data Relay will receive, so work with the unfiltered user.testHistory, containing history for all user's tested songs;
  function getOptimisticResponse(input: addUserTestEventInput) {
    // b/c time prop is assigned on server, can't simply use type from addUserTestEventInput
    type TestEvent = {
      readonly song: string;
      readonly eventType: TestEventType;
      readonly time: string;
    };

    // *** optimistic response code is parallel - though not identical - to server code; e.g. on server use Mongoose's .equals() to find lastSongTestEvent;
    // test events for all songs are stored in same array
    function shouldReplaceLastEvent(
      testHistory: readonly TestEvent[],
      testEvent: TestEvent
    ) {
      // require a "review" inbetween every "test"; if incoming event would violate this rule, remove last event and replace with incoming;
      let lastSongTestEvent, lastSongTestEventIndex;
      // get last testEvent for target song
      for (let i = testHistory.length - 1; i >= 0; i--) {
        if (testHistory[i].song === testEvent.song) {
          lastSongTestEvent = testHistory[i];
          lastSongTestEventIndex = i;
          break;
        }
      }
      if (
        lastSongTestEvent &&
        ((lastSongTestEvent.eventType !== TEST_EVENT_TYPE_OBJ.reviewed &&
          testEvent.eventType !== TEST_EVENT_TYPE_OBJ.reviewed) ||
          (lastSongTestEvent.eventType === TEST_EVENT_TYPE_OBJ.reviewed &&
            testEvent.eventType === TEST_EVENT_TYPE_OBJ.reviewed))
      ) {
        return { doReplace: true, lastSongTestEventIndex };
      } else {
        return { doReplace: false };
      }
    }

    const testEvent = {
      song: input.songId,
      eventType: input.eventType,
      time: new Date().toISOString(),
    };

    const { doReplace, lastSongTestEventIndex } = shouldReplaceLastEvent(
      user.testHistory,
      testEvent
    );
    const priorHistory = user.testHistory.slice(0);
    if (doReplace && lastSongTestEventIndex) {
      priorHistory.splice(lastSongTestEventIndex, 1);
    }

    return {
      addUserTestEvent: {
        user: {
          id: user.id,
          testHistory: [...priorHistory, testEvent],
        },
        errors: [],
      },
    };
  }

  function handleAddSongTestEvent(input: addUserTestEventInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.addUserTestEvent) {
          const { user, errors } = response.addUserTestEvent;
          if (user) {
            // if finished reviewing filtered set, auto return to home
            /*  if (
              input.testEvent.eventType === "REVIEWED" &&
              props.countSongsDue <= 1
            ) {
              history.push("/user-songs");
            } */
          }
          if (errors.length > 0) {
            setFriendlyErrors(errors);
          }
        }
      },
      onError: () => {},
      optimisticResponse: getOptimisticResponse(input),
    });
  }

  function handleChangeTestAnswer(lastEventType?: TestEventType) {
    if (!lastEventType) return;
    var newEventType: TestEventType;
    if (lastEventType === "REMEMBERED") {
      newEventType = "FORGOT";
    } else if (lastEventType === "FORGOT") {
      newEventType = "REMEMBERED";
    } else {
      // bug if this occurs: would mean trying to change answer after completing "REVIEW"
      return;
    }
    handleAddSongTestEvent({
      songId: id,
      eventType: newEventType as TestEventType,
    });
  }

  React.useEffect(() => {
    if (lastEventType === "REVIEWED") {
      setSomePageFields({ controls: null, footer: null });
    } else {
      setSomePageFields({
        // controls: (
        //   <Button
        //     onClick={() => history.push(`/song-edit/${id}`)}
        //     fill="ghost"
        //     size="sm"
        //   >
        //     <Icon id={"edit"} size={24} strokeWidth={2} />
        //   </Button>
        // ),
        // NOTE: disabling controls on Leitner page until fix issue with Transpose not displaying; problems:
          // Area of screen where transpose controls show is occupied by testing buttons.
          // Also: due to layout of Leitner page, an element with a set "position" is "intercepting" Transpose controls, preventing them from rendering where they should.  Probably one of the animation layers that rely upon one component position:absolute; and another position:relative;
        // controls: <SongControls />,
        footer: (
          <LeitnerFooter
            lastEventType={lastEventType}
            songStats={songStats}
            id={id}
            boxIntervals={props.boxIntervals}
            handleChangeTestAnswer={handleChangeTestAnswer}
            handleAddSongTestEvent={handleAddSongTestEvent}
          />
        ),
      });
    }
  }, [lastEventType]);

  var from, leave;
  if (lastEventType === "REMEMBERED") {
    from = { opacity: 0, transform: [-50, 0, 0, 0] };
    leave = { opacity: 0, transform: [50, 0, 0, 0] };
  } else if (lastEventType === "FORGOT") {
    from = { opacity: 0, transform: [50, 0, 0, 0] };
    leave = { opacity: 0, transform: [-50, 0, 0, 0] };
  } else {
    // lastEventType === "REVIEWED"
    if (songStats.lastViewDidRemember) {
      from = { opacity: 0, transform: [50, 0, 0, 0] };
      leave = { opacity: 0, transform: [-50, 0, 0, 0] };
    } else {
      from = { opacity: 0, transform: [-50, 0, 0, 0] };
      leave = { opacity: 0, transform: [50, 0, 0, 0] };
    }
  }

  const transitions = useTransition(displayMode, {
    from,
    enter: { opacity: 1, transform: [0, 0, 0, 0] },
    leave,
    config: { ...config.stiff, clamp: true },
  });

  return (
    <Wrapper>
      {transitions((style, displayModeTransition) => (
        <TransistionWrapperAnim
          style={{
            opacity: style.opacity,
            transform: style.transform.to((x) => {
              // hack to resolve bug in WKWebView; the scroll bar of the transformed element dislocates and appears somewhere in the middle; removing the transform after animation finishes "resets" the scroll bar:
              // as described in the bug report below, the scroll bar still appears in the wrong position, but removing the transform causes it to quickly return to correct position
              // https://github.com/ionic-team/ionic-framework/issues/22379
              // https://bugs.webkit.org/show_bug.cgi?id=218124
              if (x === 0) {
                return `none`;
              } else return `translate3d(${x}%,0,0)`;
            }),
          }}
        >
          {displayModeTransition === "TEST" && (
            <>
              <Spacer size={64} axis={"vert"} />
              <TwoWayDrag
                content={`${title} - ${artist}`}
                tags={tags}
                leftAction={() =>
                  handleAddSongTestEvent({
                    songId: id,
                    eventType: "FORGOT" as TestEventType,
                  })
                }
                rightAction={() =>
                  handleAddSongTestEvent({
                    songId: id,
                    eventType: "REMEMBERED" as TestEventType,
                  })
                }
              />
              <Spacer size={32} axis={"vert"} />
              <HelpModal label="Test yourself">
                {/* <h2>Test yourself!</h2> */}
                <p>
                  <TextEmphasis>Swipe right</TextEmphasis> if you can play the
                  entire song on memory alone.
                </p>
                <p>
                  <TextEmphasis>Swipe left</TextEmphasis> if you can't.
                </p>
                <p>
                  You will then have the opportunity to refresh your memory.
                  {/*  If you swiped the wrong direction, you can change
                  your answer on the next screen. */}
                </p>
                <p>
                  If you <TextEmphasis>remembered</TextEmphasis> the song, you
                  will review it <TextEmphasis>less often</TextEmphasis>. If{" "}
                  <TextEmphasis>not</TextEmphasis>, you will review it{" "}
                  <TextEmphasis>more often</TextEmphasis>.
                </p>
              </HelpModal>
            </>
          )}

          {displayModeTransition === "REVIEW" && (
            // wrap SongQuery separately, b/c the surrounding UI doesn't require SongQuery's data, and don't want that UI to be replaced while loading the song data
            <PageContentData>
              {/* {(queryOptions) => { return ( */}
              <SongQuery
                songId={id}
                queryOptions={{
                  fetchKey: new Date().toISOString(),
                  fetchPolicy: "store-and-network" as FetchPolicy,
                }}
              />
              {/* ); }}  */}
            </PageContentData>
          )}
          {/* due to bug (or at least unexpected behavior), padding "collapses" in circumstances like this (https://bugzilla.mozilla.org/show_bug.cgi?id=748518); spacer is a great alternative */}
          <Spacer size={ADD_TEST_EVENT_FOOTER_HEIGHT + 24} axis="vert" />
        </TransistionWrapperAnim>
      ))}
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </Wrapper>
  );
}

export const ADD_TEST_EVENT_FOOTER_HEIGHT = 120;

const Wrapper = styled.div`
  /* position:relative needed to provide an attachment point for TransitionWrapper */
  position: relative;
`;

const TransitionWrapper = styled.div`
  /* animated.div needs absolute positioning b/c, during animation process, both pages briefly exist at once; without absolute, those pages become stacked, each taking up 50% briefly */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const TransistionWrapperAnim = animated(TransitionWrapper);

const TextEmphasis = styled.span`
  /* font-style: italic; */
  font-weight: 500;
`;
