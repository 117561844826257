import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import styled from "styled-components/macro";

import { UpdateSongTimeSig_song$key } from "../__generated__/UpdateSongTimeSig_song.graphql";
import {
  TimeSig,
  updateSongTimeSigInput,
  UpdateSongTimeSigMutation,
} from "../__generated__/UpdateSongTimeSigMutation.graphql";

import { isApiError } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { Select } from "../_components/Select";
import {
  timeSigValToLabel,
  TIME_SIGS,
  TIME_SIGS_DEFAULT,
} from "../_music/rhythmCount";
import {
  updateSectionTimeSigInput,
  UpdateSectionTimeSigMutation,
} from "../__generated__/UpdateSectionTimeSigMutation.graphql";
import { UpdateSectionTimeSig_song$key } from "../__generated__/UpdateSectionTimeSig_song.graphql";
import { UpdateSectionTimeSig_section$key } from "../__generated__/UpdateSectionTimeSig_section.graphql";

type Props = {
  song: UpdateSectionTimeSig_song$key;
  section: UpdateSectionTimeSig_section$key;
};

export default function UpdateSectionTimeSig(props: Props) {
  const songData = useFragment(
    graphql`
      fragment UpdateSectionTimeSig_song on Song {
        id
        mongoVersion
        timeSig
      }
    `,
    props.song
  );

  const sectionData = useFragment(
    graphql`
      fragment UpdateSectionTimeSig_section on SongSection {
        id
        timeSig
      }
    `,
    props.section
  );

  const [errMsg, setErrMsg] = useState<string>("");

  const [commit, isInFlight] =
    useMutation<UpdateSectionTimeSigMutation>(graphql`
      mutation UpdateSectionTimeSigMutation(
        $input: updateSectionTimeSigInput!
      ) {
        updateSectionTimeSig(input: $input) {
          section {
            ...UpdateSectionTimeSig_section
          }
          song {
            ...UpdateSectionTimeSig_song
          }
          errors {
            __typename
            message
          }
        }
      }
    `);

  /*
  // unless want to make changing key a "locking" activity, any need for these props?  What if update request rejected b/c other user has locked?   
  isLockedByUser
  editLock {
    isLocked
    lockedBy
    lockedByEmail
    lockedUntil
  } */

  function getOptimisticResponse(input: updateSectionTimeSigInput) {
    return {
      updateSectionTimeSig: {
        section: {
          id: sectionData.id,
          timeSig: input.timeSig,
        },
        song: {
          id: songData.id,
          mongoVersion:
            input.timeSig === sectionData.timeSig
              ? input.mongoVersion + 1
              : input.mongoVersion,
          timeSig: songData.timeSig,
        },
        errors: [],
      },
    };
  }

  function handleUpdate(input: updateSectionTimeSigInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSectionTimeSig) {
          const { section, errors } = response.updateSectionTimeSig;
          if (errors.length > 0) {
            if (errors.length > 0) {
              const errMsgs = errors.map((err) => err.message);
              setErrMsg(errMsgs.join("; "));
            }
          }
        }
      },

      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <Wrapper>
        <Select
          name="timeSig"
          label="Time Signature"
          onChange={(e) =>
            handleUpdate({
              songId: songData.id,
              mongoVersion: songData.mongoVersion,
              sectionId: sectionData.id,
              // *** I do this elsewhere, and I don't like it; need to identify a better way to ensure type "flow" between server and client code;
              timeSig: e.target.value as TimeSig,
            })
          }
          value={sectionData.timeSig}
        >
          {TIME_SIGS.filter((val) => {
            if (val === "%future added value") {
              return false;
            } else {
              return true;
            }
          }).map((val) => {
            if (val === TIME_SIGS_DEFAULT) {
              return (
                <option value={val} key={val}>
                  {timeSigValToLabel(songData.timeSig)}
                  {" (song setting)"}
                </option>
              );
            } else {
              return (
                <option value={val} key={val}>
                  {timeSigValToLabel(val)}
                </option>
              );
            }
          })}
        </Select>
      </Wrapper>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const Wrapper = styled.div`
  padding: 8px 16px;
`;
