import {
  Environment,
  Network,
  RecordSource,
  Store,
  Variables,
  RequestParameters,
  CacheConfig,
  // QueryResponseCache,
} from "relay-runtime";

import { fetchIt } from "./_fetch";

export const AUTH_ERROR = "AUTH_ERROR";

/**
 *
 * Relay and "errors":
 * - Server errors: should be thrown only in the event *you* did something wrong, someone attempted an unauthorized access, or your server melted. These are any errors thrown outside of graphqlHTTP. Don't throw these in response to user actions, b/c they disrupt the GraphQL flow (and by default make it show ugly error e.g. "Uncaught RelayNetwork: No data returned for operation"), and you'd have to handle them high up in the chain, in fetchIt. (May be possible to throw an error outside graphqlHTTP that relay will recognize as a "graphql" error, but at minimum would need to match its expected format)
 * - GraphQL "true" errors: thrown by GraphQL server code e.g. if a required variable is missing. All GraphQL responses, including those with errors, will return with status code 200.  These are thrown inside graphqlHTTP, ideally within the code contained in the "context" returned by graphqlHTTP (which will use any custom error fields you designate e.g. via customFormatErrorFn).  These should be intercepted in RelayEnvironment and either handled there, or thrown as client errors to be caught and handled by the client component that originated the request.
 * - GraphQL "friendly" errors: these are errors you build into your GraphQL schema, and - as such - are entirely custom and are permitted to co-exist with returned data. (e.g. a user could make a request to edit a document and you could return both 1) an error indicating they don't have permission, and 2) the full content of the document for them to view). These errors (per your schema design) exist as a property of the request body, e.g. for CreateSongMutation, the return data is createSong obj, which contains three props: song, songEdge, and errors.  As such, there is no clean, predictable way to access these errors outside of the requesting component, b/c at the level of fetchIt or RelayEnvironment you 1) cannot be guaranteed that an errors prop will exist, and 2) you won't know the name of that top-level object (though maybe there is a way to discern it in the environment code?)
 *
 */

/* const oneMinute = 60 * 1000;
export const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });
 */

async function fetchRelay(
  params: RequestParameters,
  variables: Variables,
  _cacheConfig: CacheConfig
) {
  /*   const queryID = params.text;
  const isMutation = params.operationKind === "mutation";
  const isQuery = params.operationKind === "query";
  const forceFetch = _cacheConfig && _cacheConfig.force;

  // try to retrieve data from cache on queries
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  } */

  // otherwise fetch from server
  const json = await fetchIt("/api/graphql", {
    method: "POST",
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors" property of the response, while returning an "OK" code of 200. (Whereas typical API behavior would throw an error from the server with an error code.) If any exceptions occurred when processing the request, throw an error to indicate what went wrong, to allow you to use "normal" error handling within the client.
  if (Array.isArray(json.errors)) {
    console.log("Relay errors prop:", json.errors);
    type GraphQLErrors = {
      message: string;
    };
    const errorArray = json.errors as GraphQLErrors[];
    // seems no reasonable way to communicate "top level" errors (e.g. auth errors like expired token) via HTTP error codes, given that server can't reasonably parse GraphQL requests to determine whether to run the check (e.g. it shouldn't be run on a "login" request, but should for most others) until the server has passed the request to GraphQL, at which point its anti-pattern to throw HTTP errors (and results in GraphQL trying to respond after you've sent the headers for the HTTP error)...
    // so solution seems to be: make use of ErrorBoundaries in app, which allow you to cast a broad net to handle errors, and throw those errors from the server via GraphQL's "errors" prop; (as opposed to relying on your custom "error" property built into your schema, which can't be globally parsed due to different structure for each query, and which would require injecting appropriate logic/UI at *every point* a query or mutation is executed)
    // to facilitate use of ErrorBoundaries, parse the GraphQL's "errors" array below, and - if detect certain error messages, rethrow a single error with the detected code; ErrorUI will be on the lookout for certain codes, and will respond by, e.g. logging user out
    const AUTH_ERROR_CODES = [AUTH_ERROR];
    for (let error of errorArray) {
      if (AUTH_ERROR_CODES.indexOf(error.message) > -1) {
        throw new Error(error.message);
      }
    }
    throw new Error(
      `Error fetching GraphQL query '${
        params.name
      }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors
      )}`
    );
  }

  /*  // update cache on queries
 if (isQuery && json) {
  cache.set(queryID, variables, json);
}
// clear cache on mutations
if (isMutation) {
  cache.clear();
} */

  // return the payload to the requesting Relay component
  return json;
}

export function generateEnvironment() {
  console.log("Generating new Relay environment.");
  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource(), {
      // This property tells Relay to not immediately clear its cache when the user
      // navigates around the app. Relay will hold onto the specified number of
      // query results, allowing the user to return to recently visited pages
      // and reusing cached data if its available/fresh.
      gcReleaseBufferSize: 10,
    }),
  });
}
