import React from "react";
import styled from "styled-components/macro";

import { MEDIA_QUERIES } from "../../_constants";

export interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
  allowDrag?: boolean;
}

export function ChordGridContainer({ children, style, allowDrag }: Props) {
  return (
    <Wrapper
      style={
        {
          ...style,
          // "--grid-gap": allowDrag ? "4px" : "0px",
        } as React.CSSProperties
      }
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.ul`
  /* good practice to add isolation: isolate to parent element of components that use z-index, to create a stacking context and avoid those elements using z-index from layering with elements of other components that use z-index  */
  /* using z-index to make selected chord "pop" above siblings; use isolated context to prevent it popping above external elements */
  isolation: isolate;
  width: 100%;
  max-width: 100%;
  /* override user agent styles */
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  padding: 0px 8px;
  display: grid;
  // perhaps add grid gap (with contrasting background color) only when in edit mode
  /* gap: 2px var(--grid-gap); */

  /* minmax(0, 1fr) keeps items equal width, at risk of hiding text overflow (in conjunction with ChordGridItem's white-space:nowrap) */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  & > {
    :nth-child(n) {
      background-color: var(--color-chord-rows-odd);
    }
    :nth-child(4n + 3),
    :nth-child(4n + 4) {
      background-color: var(--color-chord-rows-even);
    }
  }

  @media ${MEDIA_QUERIES.smPhoneAndUp} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    & > {
      :nth-child(n) {
        background-color: var(--color-chord-rows-odd);
      }
      :nth-child(6n + 4),
      :nth-child(6n + 5),
      :nth-child(6n + 6) {
        background-color: var(--color-chord-rows-even);
      }
    }
  }

  @media ${MEDIA_QUERIES.lgPhoneAndUp} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    & > {
      /* for nth-child, nodes are 1-indexed, but (n >= 0); tip for usage: the constant is the first item affected; the coefficient is the repeat rate; in your use here, b/c you have repeating rows of 4, and want to start on the second row, skipping every other row, your constant should be (4 < c <= 4*2) and your coefficient === (4 * 2)   */
      :nth-child(n) {
        background-color: var(--color-chord-rows-odd);
      }
      :nth-child(8n + 5),
      :nth-child(8n + 6),
      :nth-child(8n + 7),
      :nth-child(8n + 8) {
        background-color: var(--color-chord-rows-even);
      }
    }
  }

  @media ${MEDIA_QUERIES.lgTabletAndUp} {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    & > {
      :nth-child(n) {
        background-color: var(--color-chord-rows-odd);
      }
      :nth-child(12n + 7),
      :nth-child(12n + 8),
      :nth-child(12n + 9),
      :nth-child(12n + 10),
      :nth-child(12n + 11),
      :nth-child(12n + 12) {
        background-color: var(--color-chord-rows-even);
      }
    }
  }

  @media ${MEDIA_QUERIES.laptopAndUp} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    & > {
      :nth-child(n) {
        background-color: var(--color-chord-rows-odd);
      }
      :nth-child(16n + 9),
      :nth-child(16n + 10),
      :nth-child(16n + 11),
      :nth-child(16n + 12),
      :nth-child(16n + 13),
      :nth-child(16n + 14),
      :nth-child(16n + 15),
      :nth-child(16n + 16) {
        background-color: var(--color-chord-rows-even);
      }
    }
  }
`;
