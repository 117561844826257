import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { SongEditControlQuery } from "../__generated__/SongEditControlQuery.graphql";

import { QueryOptions } from "../_types/relayTypes";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { SongManageShareSharee } from "../SongMeta/SongManageShareSharee";
import { Modal } from "../_components/Modal";
import { SongClone } from "../SongMeta/SongClone";
// import { UpgradeFeature } from "../InAppPurchase/UpgradeFeature";

type EditSongButtonProps = {
  songId: string;
  setDoShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  notAllowed?: boolean;
};

// *** note: I initially defined this button within SongQuery's render, so I wouldn't need to pass songId and setDoShowDialog as props; worked fine in Chrome; in iOS Safari, it prevented any taps from registering on the page, until... [???] I scrolled up and down a few times; I do not understand what aspect of React would have caused this, and a quick search didn't help; cleaner making it a standalone component, anyway...
const EditSongButton = (props: EditSongButtonProps) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => {
        props.notAllowed
          ? props.setDoShowDialog(true)
          : history.push(`/song-edit/${props.songId}`);
      }}
      // fill="clear"
      fill="ghost"
      palette={props.notAllowed ? "disabled" : "primary"}
      size="sm"
    >
      <IconWrapper>
        <Icon id={"edit"} size={20} strokeWidth={2} />
      </IconWrapper>
    </Button>
  );
};

type Props = {
  songId: string;
  queryOptions: QueryOptions;
};

export default function SongEditControl(props: Props) {
  const history = useHistory();

  const data = useLazyLoadQuery<SongEditControlQuery>(
    graphql`
      query SongEditControlQuery($id: ID!) {
        song(id: $id) {
          song {
            id
            viewerPermission {
              user
              email
              isOwner
              canEdit
              didAccept
            }
            ...SongManageShareSharee_song
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { id: props.songId },
    props.queryOptions
  );

  const dataWithErrors = data.song;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }
  const { song, errors } = dataWithErrors;

  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);

  return (
    <>
      {/* data result */}
      {song && (
        <>
          <EditButtonWrapper>
            {song.viewerPermission.isOwner === true && (
              <EditSongButton
                songId={props.songId}
                setDoShowDialog={setDoShowDialog}
              />
            )}
            {song.viewerPermission.isOwner === false && (
              <>
                {song.viewerPermission.canEdit ? (
                  // @@@ disable in-app purchases @@@
                  /* 
                  <UpgradeFeature
                    productGroupName={"pro"}
                    featureName="Shared Editing"
                    lockIconSize={16}
                    lockIconOffset={[0, 0]}
                    lockIconOffsetUnit="px"
                  >
                    <EditSongButton
                      songId={props.songId}
                      setDoShowDialog={setDoShowDialog}
                    />
                  </UpgradeFeature>
                  */
                  <EditSongButton
                    songId={props.songId}
                    setDoShowDialog={setDoShowDialog}
                  />
                ) : (
                  <EditSongButton
                    songId={props.songId}
                    setDoShowDialog={setDoShowDialog}
                    notAllowed
                  />
                )}
              </>
            )}
          </EditButtonWrapper>
          <Modal
            doShowDialog={doShowDialog}
            closeDialog={() => setDoShowDialog(false)}
            // label={`Options for ${songSelectedForModal.title}`}
            // actionText={"OK"}
            doUseLeastDestructiveRef={true}
            showCloseButton={false}
            // showFullHeader={true}
          >
            <SongManageShareSharee
              songRef={song}
              userId={song.viewerPermission.user}
              callback={() => history.push("/user-songs")}
            />
            {song.viewerPermission.didAccept && (
              <SongClone
                songId={song.id}
                userId={song.viewerPermission.user}
                callback={() => history.push("/user-songs")}
              />
            )}
          </Modal>
        </>
      )}

      {/* null result */}
      {!song && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}

const EditButtonWrapper = styled.div`
  /* keep aligned with rightside header button */
  min-width: 56px;
  flex-basis: 56px;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
