import React from "react";
import styled from "styled-components/macro";

const HORIZ_PADDING = 18;

export const ModalHeader = styled.div`
  font-size: ${20 / 16}rem;
  padding: 8px ${HORIZ_PADDING}px;
  /* padding-bottom: 8px; */
  border-bottom: 1px solid var(--color-shadow);
  border-bottom: 1px solid var(--color-text-light);
  margin-bottom: ${8 / 16}rem;
`;

export const ModalTitle = styled.div`
  font-size: ${15 / 16}rem;
  padding: 0px ${HORIZ_PADDING}px;
  color: var(--color-text-dark);
  text-transform: uppercase;
  font-weight: 500;
`;

export const ModalContent = styled.div`
  padding: 16px ${HORIZ_PADDING}px;
  padding-bottom: 0px;
`;
