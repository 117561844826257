import { DefaultTheme } from "styled-components/macro";

import { devicePlatform } from "../_plugins/Device";

export const APP_THEME_LIGHT: DefaultTheme = {
  id: "light",
  color: {
    primary: {
      bg: "var(--color-white)",
      text: "var(--color-dark)",
    },
  },
};
export const APP_THEME_DARK: DefaultTheme = {
  id: "dark",
  color: {
    primary: {
      bg: "var(--color-dark)",
      text: "var(--color-white)",
    },
  },
};
export const APP_THEMES = {
  light: APP_THEME_LIGHT,
  dark: APP_THEME_DARK,
};

export type ColorThemeOption = keyof typeof APP_THEMES;

export const BREAKPOINTS = {
  // everything smaller than 375 is "small phones"
  // smPhoneMin used [at present] only to accommodate large text sizes on small screens, especially for ChordGridContainer (showing only 2 chords per row below this point
  smPhoneMin: 250,
  // 375 is iPhone 6/7/8/12mini portrait width
  // switched to using 300 to accomodate iOS "Larger Text" setting: if using a font size up to the 4th from the left (which I believe is 1 higher than default), on an iPhone 12mini, will display 4 chords across (instead of 3); I'd have preferred to keep this number at 375 and apply a multiplier to iOS only to reduce the effect; or perhaps 320 would be a better balance... upon tweaking with Android, 320 better, but still need to check with iOS
  // *** 375 is a sweet spot for ChordGridContainer grid media queries for Android (size of Moto G Play) with Accessibility "Display Size": Default and Large are 4-across portrait and 6-across landscape; Larger and Largest are 3-across portrait, 4-across landscape; this particularly important if Android Accessibility "Font Size" is Larger or Larger, otherwise chord qualities don't have enough room; it appears you will have to reconcile this with what you preferred for iOS.
  // appears up to 352 allows 4-chord width with iOS "Font Size" up to 4th notch; up to 314 to get 4-chord width with iOS "Font Size" at 5th notch
  lgPhoneMin: devicePlatform === "android" ? 375 : 310,
  smTabletMin: 550,
  // 768 is iPad portrait width;
  // 768 works well as breakpoint for 6-chord width when in landscape on larger phones... but would like 6-chord landscape for smaller phones like iPhone 12mini; up to 764 produces 6-chord width for iOS "Font Size" 4th notch in landscape; up to 683 at 5th notch
  // lgTabletMin: 768,
  lgTabletMin: 680,
  laptopMin: 1100,
  desktopMin: 1500,
};

// note: you cannot simulate large browser text size by altering font-size in html; seems like setting html's font-size to e.g. 2.0rem would allow you to simulate changing base browser font size, but not the case for media queries; instead, actually change browser's font size; https://stackoverflow.com/questions/47409585/using-rem-units-in-media-queries-and-as-width
export const MEDIA_QUERIES = {
  smPhoneAndUp: `(min-width: ${BREAKPOINTS.smPhoneMin / 16}rem)`,
  lgPhoneAndUp: `(min-width: ${BREAKPOINTS.lgPhoneMin / 16}rem)`,
  smTabletAndUp: `(min-width: ${BREAKPOINTS.smTabletMin / 16}rem)`,
  lgTabletAndUp: `(min-width: ${BREAKPOINTS.lgTabletMin / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
  desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
};
