import * as Yup from "yup";
import { ZERO_WIDTH_SPACE } from "../_constants";

export function getFieldErrors(
  schema: Yup.ObjectSchema<any, any, any, any>,
  inputVals: object,
  fieldName: string,
  // currently, will validate fields individually (and display their validation errors) only if form submit was attempted, and form (overall) failed validation;
  doValidateField: boolean
) {
  try {
    if (!doValidateField) {
      // always return some "text" to occupy space, so - upon display of validation error messages - inputs/buttons don't suddenly move downscreen to make room;
      return ZERO_WIDTH_SPACE;
    }
    const validationResult = schema.validateSyncAt(fieldName, inputVals, {
      // *** abortEarly:false; useful if want to collect all errors in field; otherwise Yup stops checking at the first
      // abortEarly: false,
    });
    return ZERO_WIDTH_SPACE;
  } catch (err) {
    type YupError = { name: string; errors: string[] };
    const yupError = err as YupError;
    // console.error(yupError.name, yupError.errors);
    // with current setup, validateSyncAt stops checking at first error, so errors array should have only one entry
    return yupError.errors[0];
  }
}

export const YUP_FIELDS = {
  // login / create account
  email: Yup.string().required("Email required").email("Valid email required"),
  password: Yup.string()
    .required("Password required")
    .min(8, "Password must be at least 8 characters"),
  ui_passwordConfirm: Yup.string()
    .required("Password confirm is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  // user feedback
  isSatisfied: Yup.boolean().required("Please let us know what you think."),
  // comment: Yup.string(),
  comment: Yup.string().max(
    10000,
    ({ max, value }) => `Max ${max} characters (${value.length - max} too many)`
  ),
};

export const FORM_PLACEHOLDERS = {
  email: "you@domain.com",
  emailOfAnother: "user@domain.com",
  password: "password",
};
