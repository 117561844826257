import React from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { isApiError } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { TextInputModal } from "../_components/TextInputModal";
import {
  deleteOwnAccountInput,
  DeleteOwnAccountMutation,
} from "../__generated__/DeleteOwnAccountMutation.graphql";
import { useLogout } from "../_hooks/useLogout";
import { Email } from "../_components/Email";

type Props = {
  email: string;
};

export function DeleteOwnAccount(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");
  const { handleLogout } = useLogout();

  const [commit, isInFlight] = useMutation<DeleteOwnAccountMutation>(graphql`
    mutation DeleteOwnAccountMutation($input: deleteOwnAccountInput!) {
      deleteOwnAccount(input: $input) {
        success
        errors {
          __typename
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function handleDeleteOwnAccount(input: deleteOwnAccountInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.deleteOwnAccount) {
          const { success, errors } = response.deleteOwnAccount;
          if (success) {
            handleLogout();
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "password",
            type: "password",
            label: "Password",
            required: true,
            initialValue: "",
          },
        ]}
        createLabel={"Delete Account"}
        // *** obviously, some duplication here...
        createIconId={"trash-2"}
        palette="danger"
        actionColor="danger"
        // modalHeaderIcon={{ id: "trash-2", color: "var(--color-danger)" }}
        createOrUpdate="create"
        actionText="Delete"
        callback={(inputValues: Record<string, string>) => {
          handleDeleteOwnAccount({
            password: inputValues.password,
          });
        }}
        isInFlight={isInFlight}
        modalLabel="Delete Account"
        modalMessage={
          <>
            <p>
              You will lose all songs associated with this account. This cannot
              be undone.
            </p>
            <p>
              You will be unable to make a new account using this email address.
            </p>
            <p>
              <b>
                <Email email={props.email} />
              </b>
            </p>
            <p>
              If you want to permanently delete your account, enter your
              password below.
            </p>
          </>
        }
        resetOnSubmit
        doUseLeastDestructiveRef={true}
        showFullHeader
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
