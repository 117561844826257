import React from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faQuestion,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {
  addUserTestEventInput,
  TestEventType,
} from "../__generated__/AddSongTestEventMutation.graphql";

import Button from "../_components/Button";
import { getAnticipatedBoxIndex, LeitnerCard } from "./leitner";

type Props = {
  lastEventType: TestEventType;
  songStats: LeitnerCard["songStats"];
  id: LeitnerCard["id"];
  boxIntervals: readonly number[];
  handleChangeTestAnswer: (lastEventType?: TestEventType | undefined) => void;
  handleAddSongTestEvent: (input: addUserTestEventInput) => void;
};

export function LeitnerFooter({
  lastEventType,
  songStats,
  id,
  boxIntervals,
  handleChangeTestAnswer,
  handleAddSongTestEvent,
}: Props) {
  return (
    <Wrapper>
      <LastResultWrapper
        style={
          {
            "--background-color": `${
              lastEventType === "REMEMBERED"
                ? "var(--color-remembered)"
                : "var(--color-forgot)"
            }`,
          } as React.CSSProperties
        }
      >
        <IconWrapper>
          {/* {lastEventType === "REMEMBERED" ? "Remembered" : "Forgot"} */}
          <FontAwesomeIcon
            icon={lastEventType === "REMEMBERED" ? faCheck : faTimes}
            color="#fff"
          />
        </IconWrapper>
        <LeitnerBoxTextWrapper>
          Review every
          <LeitnerBoxWrapper>
            {[0, 1, 2, 3, 4].map((val) => {
              var anticipatedIndex =
                getAnticipatedBoxIndex(
                  songStats.boxIndex,
                  songStats.lastViewDidRemember
                ) === val;
              return (
                <LeitnerBox
                  key={val}
                  style={
                    {
                      "--color":
                        // boxIndex is updated after review, so to show which box the card *will* be sorted into,
                        anticipatedIndex
                          ? "hsl(0,0%,100%)"
                          : "hsla(0,0%,100%,0.5)",
                      "--border-color":
                        // boxIndex is updated after review, so to show which box the card *will* be sorted into,
                        anticipatedIndex ? "hsl(0,0%,100%)" : "none",
                    } as React.CSSProperties
                  }
                >
                  {boxIntervals[val]}
                </LeitnerBox>
              );
            })}
          </LeitnerBoxWrapper>
          days
        </LeitnerBoxTextWrapper>
      </LastResultWrapper>
      <ButtonWrapper>
        <Button
          // type="button"
          onClick={() => handleChangeTestAnswer(lastEventType)}
          fill="clear"
          palette="mono"
          size="sm"
        >
          Change answer:
          <br />
          {lastEventType === "REMEMBERED" ? "I forgot" : "I remembered"}
        </Button>
        <Button
          // type="button"
          onClick={() => {
            handleAddSongTestEvent({
              songId: id,
              eventType: "REVIEWED" as TestEventType,
            });
          }}
          fill="clear"
          // size="sm"
        >
          Finish Review
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  /* background: var(--color-background-tint); */
  background: var(--color-background);
`;

const LastResultWrapper = styled.div`
  width: 100%;
  padding: 8px 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${16 / 16}rem;
  font-weight: 500;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px 0px; */
  /* border-radius: 4px; */
  background-color: var(--background-color);
`;

const IconWrapper = styled.div`
  /* fixed width to avoid line shifting when icon changes (due to user changing between "forgot" and "remembered") */
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeitnerBoxTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const LeitnerBoxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  padding: 0 4px;
`;
const LeitnerBox = styled.div`
  padding: 0 6px;
  color: var(--color);
  border-top: 2px solid var(--border-color);
  border-bottom: 2px solid var(--border-color);
`;
