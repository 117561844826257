import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import {
  ListItemContext,
  ListItemDraggable,
} from "../../_components/ListItemContext";

type Props = {
  songId: string;
  id: string;
  sectionName: string;
  openDialog?: () => void;
  doAllowReorder: boolean;
  style?: React.CSSProperties;
};

// in px, will be used as rem
export const SECTION_LIST_ITEM_HEIGHT = 60;

export function SectionListItem(props: Props) {
  const history = useHistory();

  return (
    <Wrapper style={props.style}>
      {props.doAllowReorder && (
        <ListItemDraggable
          style={
            {
              "--color": "var(--color-text)",
              // for block item with fixed height, use rem instead of px to ensure will scale with user's font size
              "--height": `${SECTION_LIST_ITEM_HEIGHT / 16}rem`,
            } as React.CSSProperties
          }
        >
          {props.sectionName}
        </ListItemDraggable>
      )}
      {!props.doAllowReorder && (
        <ListItemContext
          itemOnClick={() => {
            history.push(`/section/${props.songId}/${props.id}`);
          }}
          contextOnClick={() => props.openDialog && props.openDialog()}
          style={
            {
              "--color": "unset",
              // for block item with fixed height, use rem instead of px to ensure will scale with user's font size
              "--height": `${SECTION_LIST_ITEM_HEIGHT / 16}rem`,
            } as React.CSSProperties
          }
        >
          {props.sectionName}
        </ListItemContext>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
  user-select: none;
  opacity: var(--opacity, 1);
`;
