import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";

import { UpdateSectionChordsMutation_song$key } from "../__generated__/UpdateSectionChordsMutation_song.graphql";
import {
  UpdateSectionChordsMutation_section$key,
  UpdateSectionChordsMutation_section$data,
} from "../__generated__/UpdateSectionChordsMutation_section.graphql";
import {
  UpdateSectionChordsMutation,
  updateSectionChordsInput,
  ChordInput,
} from "../__generated__/UpdateSectionChordsMutation.graphql";

import { ChordOrCursor, RhythmUnit } from "../_types";
import { isApiError } from "../_types/errorTypes";

import { ChordContext } from "../_context/chordContext";
import { ChordsActionTypes } from "../_reducers";
import { useBatchQueue } from "../_hooks/useBatchQueue";
import { CURSOR_ID } from "../ChordsSimple/Cursor";

type SectionChords = UpdateSectionChordsMutation_section$data["chords"];

const mutation = graphql`
  mutation UpdateSectionChordsMutation($input: updateSectionChordsInput!) {
    updateSectionChords(input: $input) {
      song {
        id
        mongoVersion
      }
      section {
        id
        rhythmUnit
        chords {
          id
          duration
          rhythm
          definition {
            root
            qual
            bass
          }
          notation
        }
      }
      errors {
        __typename
        ... on UserError {
          message
        }
      }
    }
  }
`;

type Props = {
  songRef: UpdateSectionChordsMutation_song$key;
  sectionRef: UpdateSectionChordsMutation_section$key;
};
export default function useUpdateSectionChordsMutation(props: Props) {
  const song = useFragment(
    graphql`
      fragment UpdateSectionChordsMutation_song on Song {
        id
        mongoVersion
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment UpdateSectionChordsMutation_section on SongSection {
        id
        rhythmUnit
        chords {
          id
          duration
          rhythm
          definition {
            root
            qual
            bass
          }
          notation
        }
      }
    `,
    props.sectionRef
  );

  const [commit, isInFlight] =
    useMutation<UpdateSectionChordsMutation>(mutation);

  const { dispatch } = React.useContext(ChordContext);
  const [errMsg, setErrMsg] = React.useState<string>("");

  function getOptimisticResponse(input: updateSectionChordsInput) {
    return {
      updateSectionChords: {
        song: {
          id: input.songId,
          mongoVersion: input.mongoVersion + 1,
          // can't do this check: state/server data types aren't same
          // mongoVersion: arraysEqual(state.chords.sectionChords, input.chords)
          //   ? input.mongoVersion
          //   : input.mongoVersion + 1,
        },
        section: {
          id: input.sectionId,
          rhythmUnit: input.rhythmUnit,
          chords: input.chords,
        },
        errors: [],
      },
    };
  }

  function updateSectionChords(input: updateSectionChordsInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSectionChords) {
          const { section, errors } = response.updateSectionChords;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            if (section) {
              // loads chords from relay, and overwrites reducer state when relay updates;
              // this is needed to update the local chords state when update to chords in server has *failed*; when sectionChords hasn't changed, need to reset local state to "undo" the changes user made that were not synced to server b/c of error; (most likely errors: song is locked or client is using outdated version of song);
              // Note, if you spread fragments in your mutation, you cannot access those spread values here: https://relay.dev/docs/guided-tour/updating-data/graphql-mutations/#executing-a-callback-when-the-mutation-completes-or-errors
              dispatch({
                type: ChordsActionTypes.Set,
                payload: {
                  chords: section.chords as ChordOrCursor[],
                  rhythmUnit: section.rhythmUnit as RhythmUnit,
                },
              });
            }
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  function callbackUpdateSectionChords(
    chords: readonly ChordInput[],
    rhythmUnit: RhythmUnit
  ) {
    updateSectionChords({
      songId: song.id,
      mongoVersion: song.mongoVersion,
      sectionId: section.id,
      rhythmUnit: rhythmUnit,
      chords,
    });
  }

  /*   const serverStateData = React.useMemo(
    () => ({
      sectionChords: section.chords,
      rhythmUnit: section.rhythmUnit as RhythmUnit,
    }),
    [section.chords, section.rhythmUnit]
  ); */

  const { batchQueue } = useBatchQueue<{
    sectionChords: SectionChords;
    rhythmUnit: RhythmUnit;
  }>({
    callback: callbackUpdateSectionChords,
    isInFlight,
    /*  localState: {
      setLocalState: (serverStateData) =>
        dispatch({
          type: ChordsActionTypes.Set,
          payload: {
            chords: serverStateData.sectionChords as ChordOrCursor[],
            rhythmUnit: serverStateData.rhythmUnit as RhythmUnit,
          },
        }),
      // serverStateData: {
      //   sectionChords: section.chords,
      //   rhythmUnit: section.rhythmUnit as RhythmUnit,
      // },
      // you previously passed only section.chords as the value of serverStateData; after you decided to pass rhythmUnit also, and passed them together in an object, you created an infinite render loop; why? because you created a new object (the object wrapping each of those props) on every render, and sent this new object to useBatchQueue; useBatchQueue uses serverStateData in a useEffect hook that identifies serverStateData as a dependency; b/c the object changed every time, it fired, which results in a dispatch to ChordContext; ChordContext is a dependency of this component, thus caused a re-render and started the cycle again; solution: useMemo hook wrapping serverStateData to maintain the wrapping object across renders (until its props change); no doubt there are other questionable design choices you've made here (see your note in SectionQueryChords, which could be a similar issue); 
      serverStateData,
      // *** Further update: decided no longer need this feature of useBatchQueue; keeping this code around for now, b/c it was a long time ago I wrote this code and perhaps have forgotten the edge case I was addressing;
      // *** confirm don't need dispatcher in useBatchQueue, then delete/tidy
      // ***  If do want to use that dispatcher, why not use useCallback or useMemo to wrap the function instead of sending dispatch fnc and params separately?
    }, */
  });

  // only for server updates; local updates are handled before this fnc
  function handleUpdateSectionChords(
    // sectionId: string,
    chordsState: ChordOrCursor[],
    rhythmUnit?: RhythmUnit
  ) {
    const chords = chordsState
      // remove cursor before sending to server
      .filter((chord: ChordOrCursor) => chord.id !== CURSOR_ID)
      // remove unneeded properties
      .map((chord: ChordOrCursor) => {
        const { id, definition, duration, rhythm, notation } = chord;
        return {
          id,
          definition,
          duration,
          rhythm,
          // *** cast as "normal" array of strings, instead of readonly array... this surely is a sign of sloppy typing
          notation: notation as string[],
        };
      });

    batchQueue({
      callbackParams: [
        chords,
        rhythmUnit ?? (section.rhythmUnit as RhythmUnit),
      ],
    });
  }

  return { handleUpdateSectionChords, setErrMsg, errMsg };
}
