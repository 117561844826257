import React, { useEffect, useRef } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useTransition, animated, config } from "@react-spring/web";
import { ThemeProvider } from "styled-components/macro";

import { generateEnvironment } from "./RelayEnvironment";
import GlobalStyles from "./GlobalStyles";
import {
  RouteRoleWrapper,
  routes,
  routeOrder,
  stripParamsFromPath,
} from "./_routing";
import { AppContext, AppProvider } from "./_context/appContext";
import { UniqueUserProvider } from "./_context/uniqueUserContext";
import { BREAKPOINTS, APP_THEMES } from "./_constants";
import { isNativeCap } from "./_utilities/detectNative";
import { hideKeyboardAccessoryBar } from "./_plugins/Keyboard";
import { hideSplashScreen } from "./_plugins";
import { getDeviceInfo } from "./_plugins/Device";
// import { IAPSetUp } from "./InAppPurchase/IAPSetUp";
// import { AddPurchase } from "./InAppPurchase/AddPurchase";
import { LogAppDiagnostics } from "./Settings/LogAppDiagnostics";
import {
  setStatusBarStyleDark,
  setStatusBarStyleLight,
} from "./_plugins/StatusBar";

const Pages: React.FC = () => {
  const location = useLocation();
  const lastPathRef = useRef(stripParamsFromPath(location.pathname));
  useEffect(() => {
    lastPathRef.current = stripParamsFromPath(location.pathname);
  }, [location]);
  const lastPath = lastPathRef.current;
  const thisPath = stripParamsFromPath(location.pathname);
  const switchingModeLearn = lastPath === "/user-songs" && thisPath === "/test";
  const switchingModePlay = lastPath === "/test" && thisPath === "/user-songs";
  const samePath = lastPath === thisPath;
  const enterFromRight =
    routeOrder.indexOf(lastPath) < routeOrder.indexOf(thisPath);
  var from, leave;
  if (samePath) {
    from = { opacity: 0, transform: [0, 0, 0, 0] };
    leave = { opacity: 0, transform: [0, 0, 0, 0] };
  } else if (switchingModeLearn) {
    from = { opacity: 0, transform: [0, 0, 0, 90] };
    leave = { opacity: 0, transform: [0, 0, 0, -90] };
  } else if (switchingModePlay) {
    from = { opacity: 0, transform: [0, 0, 0, -90] };
    leave = { opacity: 0, transform: [0, 0, 0, 90] };
  } else if (enterFromRight) {
    from = { opacity: 0, transform: [50, 0, 0, 0] };
    leave = { opacity: 0, transform: [-50, 0, 0, 0] };
  } else {
    from = { opacity: 0, transform: [-50, 0, 0, 0] };
    leave = { opacity: 0, transform: [50, 0, 0, 0] };
  }
  const transition = useTransition(location, {
    from,
    enter: { opacity: 1, transform: [0, 0, 0, 0] },
    leave,
    config: { ...config.stiff, clamp: true },
  });
  return (
    <>
      {transition((style, item, t, i) => (
        <PageWrapperAnim
          key={item.toString()}
          style={{
            opacity: style.opacity,
            // *** (2022-06-14) contrary to what is suggested in the below links, this has *not* been fixed as of iOS 15.5; further, I've noticed that - as of at least v1.1.0 of your client, if you change pages while its content is mid-scroll... the scroll bar dislocates as described below, despite this hack; possible could fix by forcing screen to stop and/or scroll to top before transitioning to next page... but I have no patience for it now. I did the most cursory search, but haven't seriously sought an answer; for now, opacity transition will have to suffice;
            /*  transform: style.transform.to((x, y, z, rot) => {
              // hack to resolve bug in WKWebView; the scroll bar of the transformed element dislocates and appears somewhere in the middle; removing the transform after animation finishes "resets" the scroll bar:
              // as described in the bug report below, the scroll bar still appears in the wrong position, but removing the transform causes it to quickly return to correct position
              // https://github.com/ionic-team/ionic-framework/issues/22379
              // https://bugs.webkit.org/show_bug.cgi?id=218124
              if (rot !== 0) {
                return `rotate3d(0, 0, 1, ${rot}deg)`;
              } else if (x === 0) {
                return `none`;
              } else return `translate3d(${x}%,0,0)`;
            }), */
          }}
        >
          <Switch location={item}>
            {routes.map(
              ({ component: Component, path, params, allowedRoles }) => (
                <RouteRoleWrapper
                  key={path}
                  path={path + (params ? params : "")}
                  params={params}
                  exact={true}
                  allowedRoles={allowedRoles}
                >
                  <Component />
                </RouteRoleWrapper>
              )
            )}
          </Switch>
        </PageWrapperAnim>
      ))}
    </>
  );
};

function RouterWrapper() {
  const { state, dispatch } = React.useContext(AppContext);
  if (isNativeCap) {
    if (state.app.colorTheme === "dark") {
      setStatusBarStyleDark();
    } else {
      setStatusBarStyleLight();
    }
  }

  return (
    <ThemeProvider theme={APP_THEMES[state.app.colorTheme]}>
      <Router>
        <Pages />
        {/* AddPurchase uses useMutation, so needs to be inside RelayEnvironmentProvider; and uses ModalError, which uses useHistory, so needs to be inside Router*/}
        {/* @@@ disable in-app purchases @@@ */}
        {/* <AddPurchase /> */}
      </Router>

      <GlobalStyles />
    </ThemeProvider>
  );
}

// RelayEnvironmentProvider is in its own component to facilitate re-render via the changing key in AppContext.Provider; due to some of React's voodoo, if you move this JSX inline as part of App, it will not re-render when the key changes
// *** issues you're running into here could be made easier if you spun off a more limited context instance; problem here is: 1) you need AppProvider to wrap RelayEnvironmentProvider, because RelayEnvironmentProvider needs to wrap almost everything else AND b/c you use AppProvider to force RelayEnvironmentProvider to re-render, to force it to generate a new environement when user changes; 2) you need AppProvider to wrap IAPSetup b/c you attach IAPSetup's callbacks to context in order to access IAP products anywhere in app; 3) though you need AppProvider to force RelayEnvironmentProvider to re-render, you do *not* want IAPSetup to re-render, as initializing the IAP "store" a second time will break it;
// *** THUS, you need a more focused re-render than current, which is to re-render AppProvider itself; tempting to use context in RelayWrapper to retrieve userId value, and apply as a key to RelayEnvironmentProvider; however, function AppContext is used in will be re-rendered when that context changes, which will cause constantly triggering of generateEnvironment(), wiping relay store over and over (seen in the app as refreshes on every page every time); have tried to send just userId from AppProvider to children (by treating as a function), but that's causing constant re-renders as if it's the same as importing AppContext; possibly could spin off a context holding only userId and use here;
function RelayWrapper() {
  return (
    <>
      <UniqueUserProvider>
        {({ renderKey }: { renderKey: string }) => (
          <RelayEnvironmentProvider
            environment={generateEnvironment()}
            key={renderKey}
          >
            <RouterWrapper />
          </RelayEnvironmentProvider>
        )}
      </UniqueUserProvider>
    </>
  );
}

export const App: React.FC = () => {
  if (isNativeCap) {
    hideKeyboardAccessoryBar();
    hideSplashScreen();
    getDeviceInfo();
  }

  return (
    <Wrapper>
      <AppProvider>
        <RelayWrapper />

        {/* @@@ disable in-app purchases @@@ */}
        {/* <IAPSetUp /> */}
        <LogAppDiagnostics />
      </AppProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  contain: layout size style;
  z-index: 0;
  overflow: hidden;
  margin: 0 auto;

  /* set max width, as simple accommodation for wide-screen viewing */
  max-width: ${BREAKPOINTS.laptopMin}px;
`;

const PageWrapper = styled.div`
  /* animated.div needs absolute positioning b/c, during animation process, both pages briefly exist at once; without absolute, those pages become stacked, each taking up 50% briefly */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  contain: strict;
`;

const PageWrapperAnim = animated(PageWrapper);
