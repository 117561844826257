import { RateApp } from "capacitor-rate-app";

// alternative: https://ionicframework.com/docs/native/app-rate (https://github.com/pushandplay/cordova-plugin-apprate)

export async function requestReview() {
  try {
    RateApp.requestReview();
  } catch (err) {
    console.error(err);
  }
}
