import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import { LogoutUser } from "../_components/LogoutUser";
import { Page, PageHeader, PageContent } from "../_components/Page";
import Spacer from "../_components/Spacer";
import { SettingsQuery } from "./SettingsQuery";
import { VersionCopyright } from "../_components/VersionCopyright";
import { ToggleColorTheme } from "./ToggleColorTheme";
// import { UpgradeFeature } from "../InAppPurchase/UpgradeFeature";
import { ErrorSuspenseWrapper } from "../_components/ErrorSuspenseWrapper";
import Button from "../_components/Button";

export const Settings: React.FC = () => {
  const history = useHistory();
  return (
    <Page>
      <PageHeader
        title={"Settings"}
        backPath={`/user-songs`}
        controls={[
          // @@@ disable in-app purchases @@@
          /* 
          <ErrorSuspenseWrapper>
            {({ queryOptions }) => (
              <UpgradeFeature
                productGroupName={"pro"}
                featureName="Dark Mode"
                lockIconSize={16}
                lockIconOffset={[20, 24]}
                queryOptions={queryOptions}
              >
                <ToggleColorTheme size="sm" />
              </UpgradeFeature>
            )}
          </ErrorSuspenseWrapper>,
          */
          <ToggleColorTheme size="sm" />,
        ]}
      />
      <PageContent
        alwaysVisible={
          <>
            {/*             <Button
              fill="clear"
              iconId="user"
              size="md"
              onClick={() => history.push(`/account`)}
            >
              <AccountButtonContent>
                <div>Account</div>
                  <EmailWrapper>
                  <Email email={user.email} />
                </EmailWrapper>
              </AccountButtonContent>
            </Button> */}
            <LogoutUser />
            <Spacer size={16} axis="vert" />
            <PaddingWrapper>
              <VersionCopyright />
            </PaddingWrapper>
          </>
        }
      >
        {(queryOptions) => {
          return <SettingsQuery queryOptions={queryOptions} />;
        }}
      </PageContent>
    </Page>
  );
};

const PaddingWrapper = styled.div`
  padding: 0px 16px;
`;

const AccountButtonContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const EmailWrapper = styled.div`
  font-size: ${14 / 16}rem;
`;
