import React, { useState } from "react";
import styled from "styled-components/macro";

import Button from "./Button";
import { Modal } from "./Modal";
import { Icon } from "./Icon";

type Props = {
  label?: string;
  children?: React.ReactNode;
};

export function HelpModal(props: Props) {
  const [doShowDialog, setDoShowDialog] = useState<boolean>(false);

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  return (
    <Wrapper>
      <StyledButton onClick={open} palette="text" fill="clear" size="sm">
        <IconWrapper>
          <Icon id={"help-circle"} size={24} strokeWidth={2} />
        </IconWrapper>
      </StyledButton>

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={close}
        label={props.label}
        handleConfirm={close}
        actionText={"Ok"}
        // actionColor="danger"
        doUseLeastDestructiveRef={true}
      >
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  min-height: 44px;
  min-width: 44px;
  padding: 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalBody = styled.div`
  padding: 16px 16px;
  font-size: ${16 / 16}rem;
`;
