import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { SongUpdateShare_song$key } from "../__generated__/SongUpdateShare_song.graphql";
import { SongUpdateShare_permission$key } from "../__generated__/SongUpdateShare_permission.graphql";
import { SongUpdateShareMutation } from "../__generated__/SongUpdateShareMutation.graphql";

import { CustomErrors } from "../_types/errorTypes";

import { Select } from "../_components/Select";
import { ModalError } from "../_components/ModalError";
import { FriendlyErrors } from "../_components/FriendlyErrors";

type Props = {
  songRef: SongUpdateShare_song$key;
  permissionRef: SongUpdateShare_permission$key;
  shareUserId: string;
};

export function SongUpdateShare(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const song = useFragment(
    graphql`
      fragment SongUpdateShare_song on Song {
        id
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const permission = useFragment(
    graphql`
      fragment SongUpdateShare_permission on SongPermission {
        user
        email
        isOwner
        canEdit
        didAccept
      }
    `,
    props.permissionRef
  );

  const [commit, isInFlight] = useMutation<SongUpdateShareMutation>(graphql`
    mutation SongUpdateShareMutation($input: updateSongShareInput!) {
      updateSongShare(input: $input) {
        song {
          id
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function getOptimisticResponse(newCanEdit: boolean) {
    const updatedPermissions = song.permissions?.map((val) => {
      if (val.user === props.shareUserId) {
        return {
          ...val,
          canEdit: newCanEdit,
        };
      } else {
        return val;
      }
    });
    return {
      updateSongShare: {
        song: {
          id: song.id,
          permissions: updatedPermissions,
        },
        errors: [],
      },
    };
  }

  function handleCommit(newCanEdit: boolean) {
    commit({
      variables: {
        input: {
          songId: song.id,
          userId: props.shareUserId,
          canEdit: newCanEdit,
        },
      },
      onCompleted: (response) => {
        if (response.updateSongShare) {
          const { errors } = response.updateSongShare;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
      optimisticResponse: getOptimisticResponse(newCanEdit),
    });
  }

  return (
    <>
      <Select
        name="canEdit"
        onChange={(e) => {
          if (isInFlight) return;
          handleCommit(parseInt(e.target.value) === 1 ? true : false);
        }}
        value={permission.canEdit ? 1 : 0}
      >
        <option value={0}>View</option>
        <option value={1}>Edit</option>
      </Select>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </>
  );
}
