import React from "react";
import styled from "styled-components/macro";

/**
 * Ideas:
 * take size info as a prop (either explicitly or reference to element that could measure here), so can time ripple based on size of element
 * a touchstart/mousedown state that creates a small static "depression" ripple, in lieu of an active state
 * scale animation duration to size of button
 *
 * Note: you once ran into an issue that... after much testing... seems to have been caused by a bug in Tenor's Gif keyboard (iOS app); when you cycled to it (while focused on an input in Dom7), it would usually result in a bug with the Ripple animation; this occurred both in the "native" Dom7 app, and when running in iOS Chrome; you tested a lot, first thinking it had to do with the different soft keyboard altering the viewport size in a way that broke the calculations in this hook; after furthing testing, it appeared to be a break in the keyframe animation, which was effectively "jumping ahead"/"skipping" animation steps; you tested using classes/CSS transition and it resolved the problem (that code may still be below, commented out); finally you decided to try upgrading the Tenor Gif app from 4.16.9 to 4.18.9... and the problem resolved;
 */

// NOTE: It was tempting to export RippleContainer as part of useRipple; however, in a React hook, the useState is effectively run by the component consuming the hook, so it re-renders on state change and thus re-runs the hook, which means this hook was sending a brand new version of RippleContainer on each render, which - in this case - meant the ripple animation was flashing on every render; cleaner solution was to move RippleContainer to its own standalone component and import separately, ensuring it did not suffer the same unnecessary re-renders;

/* NOTE: Parent of RippleContainer needs to have following styles:
    position: relative;
    overflow: hidden;
*/

type ClickParams = {
  x: number;
  y: number;
  w: number;
  h: number;
  count: number;
  // isRippling: boolean;
};

export function useRipple() {
  const defaultClickParams: ClickParams = {
    x: -1,
    y: -1,
    w: 0,
    h: 0,
    count: 0,
    // isRippling: false,
  };

  const [clickParams, setClickParams] =
    React.useState<ClickParams>(defaultClickParams);

  /* 
  function stopRipple(
    e: React.MouseEvent<
      HTMLButtonElement | HTMLDivElement | HTMLSelectElement,
      MouseEvent
    >
  ) {
    setClickParams({
      ...defaultClickParams,
      isRippling: false,
    });
  } */

  function createRipple(
    e: React.MouseEvent<
      HTMLButtonElement | HTMLDivElement | HTMLSelectElement,
      MouseEvent
    >
  ) {
    const target = e.currentTarget;
    const rect = target.getBoundingClientRect();
    const diameter = Math.max(rect.width, rect.height);
    const radius = diameter / 2;

    // console.log(e.clientX, rect.left, rect.left + rect.width);
    // console.log(e.clientY, rect.top, rect.top + rect.height);
    if (
      e.clientX < rect.left ||
      e.clientX > rect.left + rect.width ||
      e.clientY < rect.top ||
      e.clientY > rect.top + rect.height
    ) {
      // if click is outside bounds of clickable parent, do not refresh ripple; this is useful e.g. for a select element, whose options will be rendered outside the select component (and for which you may not want the ripple effect to occur when an option is clicked, particularly as the ripple will not appear to come from the click point)
      return;
    }

    setClickParams({
      w: diameter,
      h: diameter,
      x: e.clientX - rect.left - radius,
      y: e.clientY - rect.top - radius,
      count: clickParams.count + 1,
      // isRippling: true,
    });
  }

  return { createRipple, /* stopRipple, */ clickParams };
}

type RippleContainerProps = {
  clickParams: ClickParams;
};

export function RippleContainer(props: RippleContainerProps) {
  const { clickParams } = props;
  // const diameter = Math.max(clickParams.w, clickParams.h);

  return (
    <Ripple
      // using key to force Ripple to refresh every time parent refreshes due to setClickParams()
      key={clickParams.count}
      style={
        {
          left: clickParams.x,
          top: clickParams.y,
          width: clickParams.w + "px",
          height: clickParams.h + "px",
          // correlate ripple animation time to button size;
          // "--ripple-animation-time": `${(diameter / 88) * 500}ms`,
        } as React.CSSProperties
      }
      // className={clickParams.isRippling ? "is-rippling" : ""}
    />
  );
}

//      <DebugClick>
//   {window.innerHeight}
//   <br />
//   {JSON.stringify(clickParams, null, "\t")}
// </DebugClick>
// const DebugClick = styled.div``;

const Ripple = styled.span`
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  /* could scale animation time to the size of the element, for uniformly smooth transition */
  /* animation: ripple var(--ripple-animation-time) ease-out; */
  /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
  animation: 500ms linear 0ms 1 normal none running ripple;
  /* transition: transform 0ms, opacity 0ms; */
  background-color: var(--color-ripple, var(--color-primary-light));
  /* b/c Ripple's span is positioned "on top" when it appears, possible for a user to interact with it and thus make it the active element; e.g. you noticed it with your transpose control buttons: you set their opacity to change based on being active/focused; if you double clicked, your second click would hit Ripple, causing transpose controls to lose active/focus and go transparent; */
  pointer-events: none;

  /* &.is-rippling {
    transition: transform 600ms, opacity 600ms;
    transform: scale(3);
    opacity: 0;
  } */

  @keyframes ripple {
    0% {
      transform: scale(0);
      opacity: 1;
    }
/*     50% {
      transform: scale(1);
      opacity: 0.5;
    } */
    100% {
      transform: scale(3);
      opacity: 0;
    }
  }
`;

/**
 * Inspiration:
 * https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons/
 * https://codepen.io/BretCameron/pen/ZEWJKbN
 * https://www.30secondsofcode.org/react/s/ripple-button
 *
 *  */
