import React from "react";
import styled from "styled-components/macro";

import { ChordCursorContext } from "../../_types";

import {
  CURSOR_EMPTY_VAL,
  CURSOR_ID,
  doHideCursorVals,
  isCursorValidChord,
} from "../Cursor";
import Spacer from "../../_components/Spacer";
import { Icon } from "../../_components/Icon";
import { CHORD_QUALS } from "../../_music/chordQualLib";
import {
  ELLIPSIS_SINGLE_CHAR,
  MEDIA_QUERIES,
  REPEAT_END,
  REPEAT_START,
  WORD_JOINER,
  ZERO_WIDTH_SPACE,
} from "../../_constants";
// import { SVGComponent } from "../../_svg/SVGComponent";
import { getDurationStrings } from "../DurationSlider";
// import { RippleButton } from "../../_components/RippleButton";

import RepeatStart from "../../_svg/repeatStart.svg";
import RepeatEnd from "../../_svg/repeatEnd.svg";
import { formatKeyLabel } from "../ChordInputs/QualityInputGroups";
import { RhythmDisplay } from "../../_reducers";
// import { MAX_TAPS_PER_CHORD } from "../../_context/chordContext";

type RhythmSectionProps = {
  rhythmDisplay: RhythmDisplay;
  groupSubdivisions: number;
  rhythmCount: string[];
};

export const RhythmSection = (props: RhythmSectionProps) => (
  <>
    {(props.rhythmDisplay === "count" || props.rhythmDisplay === "all") && (
      <RhythmCountGroup
        style={
          {
            // "--groupSubdivisions": Math.min(props.groupSubdivisions, 8),
            "--groupSubdivisions": props.groupSubdivisions,
            // "--maxRhythmCountReached": rhythmCount.length >= 32,
          } as React.CSSProperties
        }
      >
        {props.rhythmCount.map((val, i) => {
          if (val[0] === "*") {
            return (
              <RhythmCountPartEmphasis
                key={i}
                style={
                  {
                    "--color":
                      /*                               
                              // disused; see chordContext block of code related to MAX_TAPS_PER_CHORD;
                              //  check for 1 less than MAX_TAPS_PER_CHORD b/c last rhythm item won't be added to chord.rhythm until tap off of it;
                              rhythm.length >= MAX_TAPS_PER_CHORD - 1
                                ? "var(--color-danger)"
                                :  */
                      "var(--color-text)",
                  } as React.CSSProperties
                }
              >
                {val.slice(1)}
              </RhythmCountPartEmphasis>
            );
          } else if (val[0] === ZERO_WIDTH_SPACE) {
            return (
              <RhythmCountPartFiller key={i}>
                {val.slice(1)}0
              </RhythmCountPartFiller>
            );
          } else {
            return (
              <RhythmCountPart
                key={i}
                style={
                  {
                    "--color":
                      /*                               
                              // disused; see chordContext block of code related to MAX_TAPS_PER_CHORD;
                              //  check for 1 less than MAX_TAPS_PER_CHORD b/c last rhythm item won't be added to chord.rhythm until tap off of it;
                              rhythm.length >= MAX_TAPS_PER_CHORD - 1
                                ? "var(--color-danger-light)"
                                :  */
                      "var(--color-text-light)",
                  } as React.CSSProperties
                }
              >
                {val}
              </RhythmCountPart>
            );
          }
        })}
      </RhythmCountGroup>
    )}
  </>
);

const NOTATION_HEIGHT = 22;

type ChordItemProps = {
  chordCursorObj: ChordCursorContext;
  chordIndex: number;
  rhythmDisplay: RhythmDisplay;
  context: "edit" | "display";
  selectedId?: number;
  dragOverlay?: boolean;
};

export default function ChordItem(props: ChordItemProps) {
  const { definition, duration, id, notation, context, rhythm } =
    props.chordCursorObj;
  const { root, qual, bass } = definition;
  const { repAlt, rhythmCount, groupSubdivisions } = context;
  /*
--groupSubdivisions": Math.min(groupSubdivisions, 8),
// for editing, lock rhythmCount length to (Math.min(groupSubdivisions, 8) * 2);
// - if longer, ellipsize
// - if shorter, add empty items at start of array
*/

  // -- you handled "too long"; now handle "too short"

  // lock rhythm count display to two rows; this will be equal to twice the value of groupSubdivisions, which is capped at 8 (8 count items per row)
  const FIXED_RHTYHM_COUNT_LENGTH = Math.min(groupSubdivisions, 8) * 2;
  const ELLIPSIS_ITEMS_TO_INJECT = 1;
  var rhythmCountFixedLength = rhythmCount.slice(0);
  if (rhythmCountFixedLength.length > FIXED_RHTYHM_COUNT_LENGTH ) {
    // too many count items to fit in reserved space; ellipsize;
    let SHIFT_ELLIPSIS_LOCATION = 0;
    var startSplice =
        FIXED_RHTYHM_COUNT_LENGTH / 2 -
        ELLIPSIS_ITEMS_TO_INJECT +
        SHIFT_ELLIPSIS_LOCATION,
      deleteCount =
        rhythmCountFixedLength.length -
        FIXED_RHTYHM_COUNT_LENGTH +
        ELLIPSIS_ITEMS_TO_INJECT;
    rhythmCountFixedLength.splice(
      startSplice,
      deleteCount,
      // number of added items must be equal to ELLIPSIS_ITEMS_TO_INJECT
      ELLIPSIS_SINGLE_CHAR
    );
  } else if (rhythmCountFixedLength.length < FIXED_RHTYHM_COUNT_LENGTH) {
    // too few count items; add filler;
    let rhythmCountFiller = [
      ...Array(FIXED_RHTYHM_COUNT_LENGTH - rhythmCountFixedLength.length),
    ].map((val) => ZERO_WIDTH_SPACE);
    rhythmCountFixedLength = [...rhythmCountFixedLength, ...rhythmCountFiller];
  }

  const rhythmCountToUse = props.context === "edit" ? rhythmCountFixedLength : rhythmCount;

  const isSelected = props.selectedId === props.chordIndex;
  const isCursor = id === CURSOR_ID;

  // *** default to empty strings for unknown chord qualities to accomodate cursor, now that you've deactivated predictive cursor and it will have no values (except duration) until user selects; ideally, quals would be typed and include a "cursor" value
  // const thisQualLabel = CHORD_QUALS[qual]?.label ?? (qual !== "" ? qual : "???");
  const EMPTY_VAL_LABEL = "---";
  const thisQualLabel =
    qual === CURSOR_EMPTY_VAL ? EMPTY_VAL_LABEL : CHORD_QUALS[qual]?.label ?? qual;
  // const thisQualLabel = qual === CURSOR_EMPTY_VAL ? "?" : CHORD_QUALS[qual].label;
  const rootLabel = root === CURSOR_EMPTY_VAL ? EMPTY_VAL_LABEL : root;
  /*   if (!thisQualLabel) {
    console.error("qual undefined - define types to prevent this error:", qual);
    return null;
  }
 */
  var regExWordJoiner = new RegExp(WORD_JOINER, "g");

  const qualFormatted: string[] = thisQualLabel
    .replace(/maj$/, "")
    .replace(/min$/, "m")
    // break long qualities into two parts, before first instance of a number (e.g. 7,13), or at WORD_JOINER (no longer need to break at numbers, as manually controlling by inserting WORD_JOINER throughout chordQualLib)
    // .replace(/([0-9]+)/, "[BREAK]$1")
    .replace(regExWordJoiner, "[BREAK]")
    .split("[BREAK]");

  /*   var isSuggestion: boolean = false;
  if (isCursor) {
    // seems there should be a simpler way to get this value in a type-safe way; thought I could get it just from confirming id===CURSOR_ID, but still have to cast as Cursor
    var cursor = chordCursorObj as Cursor;
    isSuggestion = cursor.isSuggestion;
  } */

  // const hideVals = isCursor && doHideCursorVals(root, qual);
  const hideVals = false;

  const cursorIsValid = isCursorValidChord(root, qual);

  const durationStrings = getDurationStrings(duration);

  return (
    <Wrapper isCursor={isCursor}>
      {repAlt.doReserveSpace && (
        <NotationWrapper
          style={
            {
              // repAlt.connectStart && !isSelected ? "0px" : "fit-content",
              "--notation-height": NOTATION_HEIGHT / 16 + "rem",
              "--border-top-color":
                repAlt.label.length > 0 ? "var(--color-text)" : "transparent",
              // "--border-width-top": repAlt.label.length > 0 ? "2px" : "0px",
              "--border-width-left":
                // looks messy to have left border and "repeat start" symbol pressed against each other
                notation.includes(REPEAT_START) ||
                repAlt.label.length === 0 ||
                repAlt.connectStart
                  ? "0px"
                  : "2px",
            } as React.CSSProperties
          }
        >
          {notation.includes(REPEAT_START) && (
            <RepeatStartWrapper>
              {/* *** for one reason or another, I started habit of loading SVGs into SVG components, but sizing them has been rather difficult, e.g. here where I wanted a parent component to have variable height, but without a fixed height for the SVG to latch onto, it shrank to 0px; using img seems to "automatically" bypass these concerns due to natural layout handling of img; additionally, some sources suggest img is the most memory-efficient way to use SVGs */}
              <img src={RepeatStart} alt="repeat start" />
              {/* <SVGComponent svgId={"repeatStart"} /> */}
            </RepeatStartWrapper>
          )}
          <NotationText>
            {(!repAlt.connectStart || isSelected || props.dragOverlay) &&
              repAlt.label}
          </NotationText>
          {notation.includes(REPEAT_END) && (
            <RepeatEndWrapper>
              <img src={RepeatEnd} alt="repeat end" />
              {/* <SVGComponent svgId={"repeatEnd"} /> */}
            </RepeatEndWrapper>
          )}
        </NotationWrapper>
      )}
      {!hideVals && (
        <>
          <DefDurationWrapper
            style={
              {
                "--def-duration-padding-top": !repAlt.doReserveSpace
                  ? "8px"
                  : "0px",
              } as React.CSSProperties
            }
          >
            <DefWrapper>
              <RootBassWrapper>
                <Root>{rootLabel}</Root>
                {root !== bass && (
                  <>
                    <Spacer size={2} axis="vert" />
                    <Bass>{bass}</Bass>
                  </>
                )}
              </RootBassWrapper>
              <Spacer size={4} axis="horiz" />
              <QualWrapper>
                {/* consider using fnc akin to formatKeyLabel() from QualityInputGroups to reduce size of "add", "sus", etc. */}
                {qualFormatted.map((val) => (
                  <Qual key={val}>{formatKeyLabel(val)}</Qual>
                ))}
              </QualWrapper>
            </DefWrapper>
            <Spacer size={4} axis="vert" />
            {(props.rhythmDisplay === "duration" ||
              props.rhythmDisplay === "all") && (
              <FormattedDuration>
                {durationStrings.wholeNum !== "" && (
                  <WholeNumDuration>
                    {durationStrings.wholeNum}
                  </WholeNumDuration>
                )}
                {durationStrings.fraction !== "" && (
                  <FractionDuration>
                    {durationStrings.fraction}
                  </FractionDuration>
                )}
              </FormattedDuration>
            )}
            <RhythmSection
              rhythmDisplay={props.rhythmDisplay}
              groupSubdivisions={Math.min(groupSubdivisions, 8)}
              rhythmCount={rhythmCountToUse}
            />
          </DefDurationWrapper>
        </>
      )}

      {isCursor && (
        <CursorDecoration
          style={
            {
              "--color": cursorIsValid
                ? "var(--color-primary)"
                : "var(--color-text-medium)",
              "--left": hideVals ? "0" : "unset",
              "--top": hideVals ? "0" : "unset",
            } as React.CSSProperties
          }
        >
          <Icon id="plus-square" size={hideVals ? 36 : 28} />
        </CursorDecoration>
      )}
    </Wrapper>
  );
}

// still can't get styles to work with RippleButton... if try again, use it by replacing wrapper of ChordGridItem instead of ChordItem
// const Wrapper = styled(RippleButton)<{ isCursor: boolean }>`
const Wrapper = styled.div<{ isCursor: boolean }>`
  /*** begin overrides for RippleButton */
  /*   background: none;
  --color-ripple: var(--color-highlight);
  width: 100%;
  padding: 0;
  text-align: left; */
  /*** end overrides for RippleButton */

  height: 100%;

  /* NOTE: ChordItem as rendered in DisplaySection (display only) is simpler than that displayed in ChordGridItem (editing); in the former, all ChordItems are siblings in ChordGridContainer, and so when using variable height for ChordItem (as you do now, setting only a min-height and allowing them to stretch to accomodate user font settings... namely those in Android), if one in a row is taller, the others will stretch with it; this keeps chord details in line, e.g. keeps duration at the bottom of each chord; however via ChordGridItem, ChordItem is wrapped in both ChordGridItem and SortableChord, meaning the direct siblings within ChordGridContainer are SortableChords; solution: use display:flex; and flex:1 through those three components to ensure ChordItem's height and width fills both parent and grandparent (width is important b/c use of top border in NotationWrapper) */
  flex: 1;
  position: relative;
  /* avoid fixed height, namely for sake for Android "Font Size" accessibility setting, which does not simply scale up base HTML font size (as a browser would), but appears to specifically target text only, preventing your use of rems on block elements from resizing as they do in browser */
  /* min-height: ${104 / 16}rem; */
  min-height: ${64 / 16}rem;
  display: flex;
  flex-flow: column nowrap;
  /* override global line-height */
  line-height: 1;

  ${({ isCursor }) =>
    isCursor &&
    `
    --checker-color-1: var(--color-mono-translucent-15);
    --checker-color-2: transparent;
    
    /* background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-position: 0px 0px, 5px 5px;
    background-size: 10px 10px;
    background-image: linear-gradient(45deg, var(--checker-color-1) 25%, transparent 25%, transparent 75%, var(--checker-color-1) 75%, var(--checker-color-1) 100%),linear-gradient(45deg, var(--checker-color-1) 25%,  var(--checker-color-2) 25%, var(--checker-color-2) 75%, var(--checker-color-1) 75%, var(--checker-color-1) 100%); */
    
    background: repeating-conic-gradient(var(--checker-color-1) 0% 25%, var(--checker-color-2) 0% 50%) 50% / 10px 10px;
  `}
`;

const NotationWrapper = styled.div`
  min-height: var(--notation-height);
  /* *** this is counter to the rest of the styling here: fixed height; purpose: don't want NotationWrapper to be any taller, but do want repeat symbols to be larger; thus, set fixed height here and allow RepeatWrapper to overflow; if remove this fixed height without making other changes, when a repeat symbol is added to a chord, it will push other chord content down, knocking it out of alignment with its neighbors; if want to remove fixed height, must either set large enough min-height to hold RepeatWrapper, or must shrink height of RepeatWrapper's contents */
  height: var(--notation-height);
  border-top: 2px solid var(--border-top-color);
  border-left: var(--border-width-left) solid var(--color-text);
  font-size: ${14.4 / 16}rem;
  display: flex;
`;
const RepeatWrapper = styled.div`
  flex-basis: ${8 / 16}rem;
  /* https://stackoverflow.com/a/53336754 */
  filter: var(--svg-color-filter);
`;
const RepeatStartWrapper = styled(RepeatWrapper)`
  /* *** had used padding to help distinguish from horiz line that starts a repeat section, but 1) looked less attractive, and 2) adding padding to the image's container shrinks the image  */
  /* padding-left: ${1 / 16}rem; */
`;
const RepeatEndWrapper = styled(RepeatWrapper)`
  /* padding-right: ${1 / 16}rem; */
`;
const NotationText = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 4px;
  /* margin-top: ${-4 / 16}rem; */
`;
const DefDurationWrapper = styled.div`
  /* keep chord aligned with top of cell, and duration aligned with bottom */
  flex: 1;
  /* testing removing this padding (and matching negative margin RhythmCountGroup) to ensure first rhythm count symbol for a chord lines up with the chord root note on the left side */
  /* padding: 4px 6px; */
  padding-top: var(--def-duration-padding-top);
  display: flex;
  flex-flow: column nowrap;
`;
const DefWrapper = styled.div`
  /* fill available space, to keep duration aligned with bottom */
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
`;
const RootBassWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const Root = styled.span`
  font-size: ${24 / 16}rem;
  color: var(--color-chord-root);
  text-align: center;
`;
const QualWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
const Qual = styled.span`
  font-size: 1.1rem;
  /* color: #161; */
  color: var(--color-chord-qual);
  /* word-break or overflow-wrap seem identical for these purposes; or could insert special chars e.g. between "sus" and "4" to prescribe break locations when a break is needed; note: appears word-break:break-all; does not work with hyphens:auto; */
  /* word-break: break-all; */
  overflow-wrap: anywhere;
`;
const Bass = styled.div`
  border-top: 2px solid;
  font-size: ${22 / 16}rem;
  /* text-align: center; */
  /* left alignment of Bass with Root looks funny with the larger Root, with Bass looking like it's further to the left; a little left padding helps  */
  /* padding-left: 0.05rem; */
  text-align: center;
  color: var(--color-text);
`;

const RhythmCountGroup = styled.div`
  /* flex: 1; */
  /* beware overflow caused by grid with fixed number of columns (and with each grid item having a min or fixed width) */
  display: grid;
  grid-template-columns: repeat(var(--groupSubdivisions), minmax(0, 1fr));
  /* gap: 4px 4px; */
  place-items: center start;
  /* place-items: center; */
  @media ${MEDIA_QUERIES.lgPhoneAndUp} {
    /* grid-template-columns: repeat(var(--columns), minmax(0, 1fr)); */
    /* gap: 8px 8px; */
  }

  /* display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  */

  /* want to take advantage of full width of chord cell, and want spacing to be even between cells, so negative margin to counter cell padding */
  /* testing removing this negative margin (and matching padding on DefDurationWrapper) to ensure first rhythm count symbol for a chord lines up with the chord root note on the left side */
  /*   margin-left: -6px;
  margin-right: -6px; */
`;
const RhythmCountPart = styled.div`
  padding: 2px 1px;
  /* padding: 2px 0px; */
  text-align: center;
  font-family: var(--font-family-monospace);
  font-size: ${16 / 16}rem;
  color: var(--color);
`;
const RhythmCountPartEmphasis = styled(RhythmCountPart)`
  color: var(--color);
`;
const RhythmCountPartFiller = styled(RhythmCountPart)`
  height: 25%;
  width: 100%;
  background-color: var(--color-text-translucent);
  color: transparent;
`;

const FormattedDuration = styled.div`
  display: flex;
  align-items: center;
  font-size: ${18 / 16}rem;
  color: var(--color-primary);
`;
const WholeNumDuration = styled.span`
  // set line-height same as FractionDuration font-size, otherwise height of a chord cell will differ depending on presence of a fraction (b/c fraction font is larger);
  line-height: ${20 / 16}rem;
`;
const FractionDuration = styled.span`
  font-size: ${20 / 16}rem;
`;

const CursorDecoration = styled.div`
  position: absolute;
  top: var(--top, unset);
  right: var(--right, 0px);
  bottom: var(--bottom, 0px);
  left: var(--left, unset);
  color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: scale 200ms;
  /* line-height: 20px; */
  /* transform: scale(var(--scale), var(--scale)); */
  /* transform: scale(var(--scale)); */
  /* transform: scale(2, 2) translate(-25%, -25%); */
  // bottom padding to visually center text on vertical axis (alt could place text inside an element and apply negative margin to it)
  /* padding-bottom: 4px; */
`;
