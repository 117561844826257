/**
 *
 *Possoble solutions:
Uncap max duration
Uncap while tapping, but force user to scale down before allowing them to “save” (not the same as “finish” - have to allow finish bc it’s time sensitive
Similar considerations needed for smallest possible duration?

High level ideal, for tapping duration only: 
- Ratios between chords are maintained, no matter how small/large;
- "Normalized" to assume 4 beats to a chord
-- thus no matter acutal durations will "center" tapped progression around a 4-beat chords, to try to keep within range of 1/4 to 16 beats;
- or could normalize based on BPM, e.g. if set 60bpm as default, each second spent on a chord would result in one beat
- Therefore user could end up with some ridiculous durations, e.g. 1/16 to 64
-- If disallow this, will have to warn user and/or assume user made a mistake and override, and if override... what to do about rhythm taps
--- Either have to discard some/all, or have to scale them down with the amount the overall duration is scaled down, which will likely lead to their durations being too small... 
--- Plus scaling down is a problem: if user taps 18 beats on first chord, and 12 on the next, they want the first chord to be larger... scaling it e.g. to 9 would make it smaller... Scaling it to 16 would keep it longer than the 2nd chord, but only by 33% instead of 50%... so... would I then try to scale the second chord up?
-- If all I was doing was displaying the number, this is no problem; but want to be able to:
--- Allow user to modify with DurationSlider
--- Display rhythm of taps within chord cell(s)
= Possible solutions:
- Could discard DurationSlider, or otherwise find a way to make it "accepting" of arbitrarily long durations
-- But this doesn't solve problem of fitting rhythm symbols into the chord cell
- Could dynamically add new chord cells any time duration of one exceeds 16, either actual new chords (added as a user could add them), or virtual (only one chord would be stored, but when "printed", I would display multiple)
- Virtual chords are similar to what I'd been envisioning for an alternate display that correlates chord duration with the amount of space it occupies on the screen
-- Drag-to-reorder might be tricky with this; perhaps could shrink all chords on long-click select, or would want to re-instate a toggle to enter re-order mode
- But virtual doesn't solve DurationSlider problem... by default you'd still be changing duration of a single chord with the slider;

- Could update tap display as time ticks by... by the time user starts tapping on second chord, you know what your time ratios will be... as time passes, could update to show what new ratio is becoming... with enough time, could make duration font red with a warning symbol... or warning symbol/message in keyboard area to indicate their taps are no longer being recorded accurately... this all might be more work than accommodating any duration...

...You do allow a 64x duration variance between chords... that's pretty reasonable

 * 
 * OUTSTANDING CONCERNS:
 * 0) can't have conflicting sources of truth about length of a chord: rhythm tapping resets duration; changing duration needs to clear rhythm taps;
 * 1) duration may be shorter/longer than sum of rhythm numbers, because duration is moderated to ensure it fits between 0.25 and 16;
 * 2) Think about impacts of using the Scale feature... it modifies duration, but not rhythm
 * 3) longer rhythmSymbolSet will not fit within one chord tile... auto create extra tiles?
 * 4) handle time signatures other than 4/4: current code may handle 2/4 and 3/4 just fine, which largely covers concern (given that you allow scaling, seems that should satisfy ; just need to add a user setting and display to show which time signature user is in;
 * 5) handle more complicated rhythms; most relevant for pop perhaps is triplet... not sure offhand what you'd need to do;
 *
 *
 * To Do:
 * 1) add user option to toggle between duration and rhythmSymbolSet
 * 2) address above "outstanding concerns"
 * 3) rather inefficient how you added to addChartContext; consider revising;
 * 
 * Act:
 * 
 * Tapping - 
 * Let user tap his heart out. If it exceeds whatever limit you set for taps that can be displayed in a single chord tile, abbreviate it. 
 * Perhaps also include a way for the user to view the full tap pattern in a pop-up if they tap the abbreviation.
 * In song display mode, if a chord exceeds the limit (only look at rhythm pattern - remember rhythm patten would show for all chords if user wants it shown at all), split chord as many times as needed to keep to the limit.  Show main chord info in first cell only.
 * Consider not showing total duration if rhythm data present.
 * 
 * Scaling - 
 * For scaling with rhythm pattern, have to look at rhythm taps to determine whether can scale down. Look at each full chord duration to determine whether can scale up (though if uncap max tap duration, would uncap this too). 
 * May want to indicate in UI shortest identified tap duration to help user understand when can't scale down?
 * 
 * Duration slider, where rhythm present -
 * Change in duration should wipe rhythm data. Perhaps communicate to user in advance thi action will be destructive. If the user is inclined to use the slider on a chord with rhythm data, most likely they wanted to scale it. So I need to give them a way to scale chords that have not just been tapped.
 * Repurpose the toggle on the tap tab that is currently used to show the duration slider. Because duration slider will clear rhythm data, no good reason to keep it on this tab. Instead, when this toggle is used, modify the UI to facilitate selecting all or a subset of chords, and then scaling with the existing buttons.
 * 
 * 
 * When translating rhythm into rhythm count symbols consider: instead of storing literal value for each rhythm count, store a string id for each that uniquely identifies it, e.g. "1", "1&", "2", "2&"
 * 
 * 
 * 1   2   3   4
 * 1 A 2 A 3 A 4 A
 * 1 e A u 2 e A u
 * 1 A 2 A 3 A
 * 1 trip let 2 trip let
 * 
 * 
 * === Behaviors ===
 * Changing a chord's duration with slider wipes rhythm data.
 * 
 * 
 * Consider whether should count individual chords above bar length; e.g. defaulting to 4/4... if have 8 beats of a Cmaj in a row, do you show 1-8 or two sets of 1-4?  Counting up to that 8 might be less proper formally, but could be easier to read, and make it easier for you to discard showing the overall duration number, given that the count would now also reflect that;
 * ...but think how that would work beteen chords, e.g. if you spend a bar and a half on C, then half a bar on G: under the "usual" way it would read: (C) 1 2 3 4 1 2 (G) 3 4.  But if you allow counting higher than 4 would read (C) 1 2 3 4 5 6 (G) 1 2, or 7 8?
 * 
 * 
 * If do show multiple lines of rhythmCount... does it make sense to make user read multiple lines within one cell, then move to next cell and repeat...?  Isn't whole feel of changes you've been making via splitChordCells the idea you're supposed to read the split cells as a "single cell"?  And think about a group of a split chord that line breaks... that leans the other way, in favor of reading down then over, per chord cell.  But both considerations prefer a single line of rhythmCount, read straight across.
 * 
 * 
 */
/*
  Consider:
  - not showing non-tapped beats, or ONLY showing non-tapped beats if they are the main (numbered) downbeats; this could add clarity in what otherwise becomes dense print; use monospace character to fill in "empty" spots to ensure spacing is maintained; (see: Justin Rock book, p.121, 146 (counting the half-rest at bottom of page))
  - see if Justin has useful video on counting, and continue to look through his books
  - feasible to add triplet count into this code?
  - for 12/8 follow same pattern as 6/8: groups of 3 eighth notes, 4 to a bar; (see Justin Vintage book, p.187)

  - if in eighth note time signature, get rid of quarter note duration slider? Get rid of 2X slider if in quarter note time signature?

  - May want to treat conversion from duration differently, e.g. for 6/8 always show in "1 + a", and differentiate only for tapped rhythm as needed;

  - https://www.skoove.com/blog/eighth-and-sixteenth-notes/

*/

// export const TIME_SIG_OBJ: Record<TimeSigClient, string> = {
//   [TIME_SIGS_DEFAULT]: TIME_SIGS_DEFAULT,
//   "t2_2": "t2_2",
//   "t2_4": "t2_4",
//   "t3_4": "t3_4",
//   "t4_4": "t4_4",
//   "t6_8": "t6_8",
//   "t9_8": "t9_8",
//   "t12_8": "t12_8",
// } as const;

/**
 *
 * 1. can't reasonably make duration show "2" for a single bar of 6/8; this would conflate duration with "count", and you already have "count" in the rhythm count symbols;
 *    - instead, if in an 1/8 note time signature, double the duration for each chord (when tapped) to make that duration number equal the number of 1/8 notes, instead of 1/4 notes;
 *    - when duration selected manually, will need to ensure that number is halved, to treat it as 1/8 notes
 * 2. only way to ensure consistent results across separate tap sessions would be to take prior tap data into account on a new tap;
 *    - as you mention in your issue tracker, could do this by saving raw tap data, or by extrapolating tempo info;
 *    - maybe you could get part of the way there by altering tap results based on time signature, e.g. if receive only equal-length taps in 6/8, presume that's a single bar (six 8th notes), so set each to val of 1.5;
 *      == don't want to do this... essentially breaking your pure logic to write exceptions
 *
 *
 */

// KEY problem here with 6/8 etc. counts: using a rigid structure made for 4/4, e.g. you determine whether doesHaveEighths by checking for a remainder of 0.5, and if so you then double the number of symbols that need to be used (compared with quarter notes), which maps to your 8th note symbols (1 + 2 +); but for counting 6/8, you units are "dotted quarter note", "3 eighth notes", and "6 sixteenth notes";
// consider whether should change tap rhythm data; see calcTapStats() in src/_hooks/useRhythm.helpers.tsx; e.g. should you avoid decimals, and ensure only integers are saved? below, you convert tap duration "ratios" into "rhythmicStrikes": are tap duration ratios the best way to store this data in the first place? For example, you've raised concern about consecutive tap sessions giving diff. results, which occurs because each is bound to its context, and doesn't "know" about rhythm data not occuring during the same session; if saved actual durations of taps (in milliseconds) could use tap data from sessions to influence each other; could either: normalize the tap data before save, or don't normalize it and perform all the processing when rendering (as do here, for rhythm count symbols)
// think about ways to handle chords with durations but no tap rhythm (or whether to handle them at all...) e..g fallback to duration, or modify rhythm when modify duration (instead of wiping it, fill it with assumed placeholder values...  no don't like that now that using expliciy durations for rhythms; better to assume what those would be in this functino, using real rhythmdata from other chords in section)
// ************************** need to handle 1) duration-only chords, e.g. a chord with 2.25 duration (and no rhythm data) amongst chords with rhythm data that only inlcudes quarter or eighth notes; 2) reconciling chords from multiple tap sessions;

import { NON_BREAKING_SPACE } from "../_constants";
import { TapStatGroup } from "../_hooks/useRhythm.helpers";
import { ChordOrCursor, RhythmUnit } from "../_types";

export const TIME_SIGS_DEFAULT = "VALUE_UNSET";
export const TIME_SIGS = [
  TIME_SIGS_DEFAULT,
  "t2_2",
  "t2_4",
  "t3_4",
  "t4_4",
  "t6_8",
  "t9_8",
  "t12_8",
  // silly, but matching Relay enum typing here, to allow the server and client types to match without coercion;
  "%future added value",
] as const;

export type TimeSigClient = (typeof TIME_SIGS)[number];

export function timeSigValToLabel(val: TimeSigClient) {
  if (val === TIME_SIGS_DEFAULT) {
    return "4/4";
  }
  return val.replace("t", "").replace("_", "/");
}

// https://en.wikipedia.org/wiki/Counting_(music)
// https://www.reddit.com/r/explainlikeimfive/comments/3xpe54/eli5_how_to_count_time_signatures_in_songs/

// bothers me this typing doesn't work, as want to lock down type of potential time sigs here; https://stackoverflow.com/questions/70003710/record-types-as-compile-time-constant
// const timeSignatureToCount: Record<TimeSigClient, readonly number[]> = {
const timeSignatureToCount = {
  [TIME_SIGS_DEFAULT]: [4, 4],
  // seems I'm safe treating 2/2 the same as 2/4 for the purposes of this code;
  "t2_2": [2, 2],
  "t2_4": [2, 4],
  // 3/4 time = 3 quarter notes = 3 groups of 2 eighth notes
  // 1 + 2 + 3 + (six 8th notes)
  "t3_4": [3, 4],
  "t4_4": [4, 4],
  // 6/8 time = 2 dotted quarter notes = 2 groups of 3 eighth notes
  // 1 + a 2 + a (six 8th notes)
  "t6_8": [6, 8],
  // 1 + a 2 + a 3 + a (nine 8th notes)
  "t9_8": [3, 8],
  // 1 + a 2 + a 3 + a 4 + a (twelve 8th notes)
  "t12_8": [4, 8],
  // silly, but matching Relay enum typing here, to allow the server and client types to match without coercion;
  "%future added value": [4, 4],
} as const;

type TimeSignature = keyof typeof timeSignatureToCount;

const countSymbolSets = {
  2: {
    2: ["[NUM]"],
    4: ["[NUM]"],
    // 4: ["[NUM]", "+"],
    8: ["[NUM]", "+"],
    16: ["[NUM]", "e", "+", "a"],
  },
  4: {
    2: ["[NUM]"],
    4: ["[NUM]"],
    // 4: ["[NUM]", "+"],
    8: ["[NUM]", "+"],
    16: ["[NUM]", "e", "+", "a"],
  },
  8: {
    2: ["[NUM]"],
    4: ["[NUM]"],
    // 4: ["[NUM]", "+", "a"],
    8: ["[NUM]", "+", "a"],
    // https://www.reddit.com/r/musictheory/comments/mxpid2/how_do_i_count_sixteenth_notes_in_68/
    16: ["[NUM]", "t", "+", "t", "a", "t"],
  },
} as const;

type ChordOrCursorRhythm = Omit<ChordOrCursor, "definition" | "notation"> & {
  rhythmDuration?: number;
  subdivisionCount: number;
  rhythmicStrikes: number[];
  rhythmCount: string[];
  groupSubdivisions: number;

  // adjustedRhythmRatios: number[];
  // adjustedRhythmDurations: number[];
};

// update rhythm-related props for chord;
function updateChordRhythmProps(
  chord: ChordOrCursorRhythm,
  subdivision: RhythmUnit,
  shortestTap: number
) {
  // for each tap, use rounded ratio to shortest tap, to normalize taps across multiple tap sessions, and ensure e.g. the count symbols will be correctly emphasized for each chord (where they align with the actual taps);
  //
  //
  // ***NOTE: believe will want this fnc to receive tap sensitivity (1 or 0.5) and multiply benchmark by that to ensure sensitive enough to detect ratios that could be tapped with the more sensitive setting;
  //
  //
  const normalizeTapDurationToRatio = (
    tapDuration: number,
    benchmark: number
  ) => {
    //
    //
    // requires all rhythm vals being integer ratios, because of below loop condition: "thisChord.rhythmicStrikes.includes(j))";
    // *** this requires both: 1) normalizing taps created across different tap sessions; 2) handling 1.5 ratios which may occur if tapSensitivity === 0.5
    //
    //
    return Math.round(tapDuration / benchmark);
    // *** if modify how calc this ratio, will need to do same with largestDurationRatio; was considering "snapping" the ratio to a power of 2 or 3 (so won't e.g. 2x scale up a chord that had a tap that was a 2:1 ratio to the shortest tap, and end up with a 5:1);
    // https://stackoverflow.com/questions/4398711/round-to-the-nearest-power-of-two
    // https://stackoverflow.com/questions/466204/rounding-up-to-next-power-of-2
    // var ratio = tapDuration / benchmark;
    // var nearestBase2Exp = Math.round(Math.log(ratio) / Math.LN2);
    // return Math.pow(2, nearestBase2Exp);
  };

  // convert chord "rhythm" prop (an array of durations, each an individual tap user made on chord) into an array of 0-based "timestamps" indicating on which rhythmic subdivision each strike occurred;
  const getRhythmicStrikes = (
    rhythm: readonly number[],
    subdivisionScale: number
  ) => {
    if (rhythm.length === 0) {
      // there's no rhythm, so no strikes;
      return [];
    }
    var runningTotal = 0,
      rhythmicStrikes = [0];
    for (let i = 0; i < rhythm.length - 1; i++) {
      let thisStrikeDuration = normalizeTapDurationToRatio(
        rhythm[i],
        shortestTap
      );
      // runningTotal += thisStrikeDuration * subdivisionScale;
      runningTotal += thisStrikeDuration;
      rhythmicStrikes.push(runningTotal);
    }
    return rhythmicStrikes;
  };

  if (chord.rhythm.length === 0) {
/*     chord.rhythmDuration = chord.duration / (subdivision / 4);
    chord.subdivisionCount = chord.rhythmDuration * (subdivision / 4); */
    chord.subdivisionCount = chord.duration * (subdivision / 4);
  } else {
    chord.subdivisionCount = chord.rhythm.reduce((prev, curr) => {
      return prev + normalizeTapDurationToRatio(curr, shortestTap);
    }, 0);
    chord.rhythmDuration = chord.subdivisionCount / (subdivision / 4);
  }
  chord.rhythmicStrikes = getRhythmicStrikes(chord.rhythm, subdivision / 4);
  /*  chord.adjustedRhythmRatios = chord.rhythm.map((val) =>
    normalizeTapDurationToRatio(val, shortestTap)
  );
  chord.adjustedRhythmDurations = chord.adjustedRhythmRatios.map(
    (val) => val * shortestTap
  ); */
}

export function countProgressionRhythm(
  progression: ChordOrCursor[],
  timeSignature: TimeSignature = "t4_4",
  rhythmUnit: RhythmUnit
): {
  progressionWithRhythmCount: ChordOrCursorRhythm[];
} {
  const timeSigDef = timeSignatureToCount[timeSignature];
  const beatsPerBar = timeSigDef[0];
  const beatDuration = timeSigDef[1];
  var shortestTap: number = -1;
  // clone relevant props;
  var progressionWithRhythmCount: ChordOrCursorRhythm[] = progression
    // ignore any duration/rhythm attached to CURSOR;
    .filter((chord) => chord.id !== "CURSOR")
    // .filter((chord) => chord.chordId !== "CURSOR")
    .map((chord) => {
      let rhythmCopy = chord.rhythm.map((tap) => {
        // clone chord.rhythm with map instead of slice() b/c need to test for shortest/longest tap;
        if (shortestTap === -1 || tap < shortestTap) {
          shortestTap = tap;
        }
        return tap;
      });
      return {
        id: chord.id,
        duration: chord.duration,
        rhythm: rhythmCopy,
        subdivisionCount: 1,
        rhythmDuration: undefined,
        rhythmicStrikes: [],
        rhythmCount: [],
        groupSubdivisions: 1,
        // adjustedRhythmRatios: [],
        // adjustedRhythmDurations: [],
      };
    });
  if (progressionWithRhythmCount.length === 0) {
    // received empty section (cursor only); return early with default values;
    return {
      progressionWithRhythmCount: [],
    };
  }
  // if no chords include rhythm data, loop through chords again and use duration to determine shortest/longest taps; b/c rhythm duration in ms instead of durationRatio, cannot mix rhythm with duration (which is still in arbitrary beats);
  if (shortestTap === -1) {
    progressionWithRhythmCount.forEach((chord) => {
      if (shortestTap === -1 || chord.duration < shortestTap) {
        shortestTap = chord.duration;
      }
    });
  }

  const subdivision: RhythmUnit = rhythmUnit;
  const countSymbols = countSymbolSets[beatDuration][rhythmUnit];

  // create the rhythmSymbolSet for each chord (e.g. 1e+a2e+a);
  var currentCountSymbolIndex = 0,
    groupSubdivisions = 1,
    currentBeat = 1;
  for (let i = 0; i < progressionWithRhythmCount.length; i++) {
    let thisChord = progressionWithRhythmCount[i];
    // *** Note, in case future you again considers assigning rhythmUnit per-chord instead of per section: cannot have different subdivisions with current logic; you encountered error here because need to persist currentCountSymbolIndex across chords, to ensure count flows across them, but presently allow chords to have different subdivisions, and subdivisions decide the countSymbols used; so your below check currentCountSymbolIndex === countSymbols.length will be circumvented if moving from a longer countSymbols array to shorter, resulting in undefined values for symbolToPush; and there's no resonable way to handle; two chords with subdivision 8 may read: [C] 1 and 2 [D] and 3 and 4; if the second chord is subdivision 4, it won't "know" about "and"; it only knows how to count on the numbers, so you'd have: [C] 1 and 2 [D] 3 4; this is not what's desired, as now the first chord implicily has an extra half beat (an implied "and" at the end);
    updateChordRhythmProps(thisChord, subdivision, shortestTap);
    // build rhythmCount for chord;
    for (let j = 0; j < thisChord.subdivisionCount; j++) {
      let currentCountSymbol = countSymbols[currentCountSymbolIndex],
        symbolToPush;
      if (currentCountSymbol === "[NUM]") {
        // next "symbol" is the beat number;
        symbolToPush = currentBeat.toString();
        // increment beat number index;
        currentBeat++;
        if (currentBeat > beatsPerBar) {
          currentBeat = 1;
        }
      } else {
        // next "symbol" is a non-number: namely "e", "A", or "u";
        symbolToPush = currentCountSymbol;
      }

      if (symbolToPush === undefined) {
        console.log("ERROR");
        console.log({
          currentCountSymbol,
          countSymbols,
          currentCountSymbolIndex,
          currentBeat,
          subdivision,
        });
      }

      // increment symbol index for next loop;
      currentCountSymbolIndex++;
      if (currentCountSymbolIndex === countSymbols.length) {
        currentCountSymbolIndex = 0;
      }

      if (thisChord.rhythmicStrikes.includes(j)) {
        // rhythm counts that coincide with user taps: mark them to allow UI to emphasize them;
        symbolToPush = "*" + symbolToPush;
      } else if (
        // rhythm counts that do NOT coincide with user taps;
        // if chord has rhythm data (from being tapped by user), hide untapped count symbols for the beat division level, to keep interface tidier and make it easier to focus on tapped rhythm;
        // if chord relies soley on "duration" data, need to show all count symbols, or could have a chord displaying with nothing (e.g. set a chord to 1/4 duration and note it would have no count symbol displayed)
        thisChord.rhythm.length > 0 &&
        // don't hide non emphasized count symbols if they are at start/end of chord's rhythm: can be confusing if they are missing;
        j !== 0 &&
        j !== thisChord.subdivisionCount - 1 &&
        (beatDuration === 2 || beatDuration === 4)
      ) {
        if (
          subdivision === 16 &&
          currentCountSymbol !== "[NUM]" &&
          symbolToPush !== "+"
        ) {
          // if this count does not match a user tap, and is not a number or an "and" ("+"), hide it;
          // *** don't want this to apply to 6/8, 9/8, etc. times;
          symbolToPush = NON_BREAKING_SPACE;
        }
        if (subdivision !== 16 && currentCountSymbol !== "[NUM]") {
          // if this count does not match a user tap, and is not a number, hide it;
          symbolToPush = NON_BREAKING_SPACE;
        }
      }
      thisChord.rhythmCount.push(symbolToPush);
    }
    // track largest set of rhythm count symbols, for use in UI;
    if (thisChord.rhythmCount.length > groupSubdivisions) {
      groupSubdivisions = thisChord.rhythmCount.length;
    }
  }
  // apply groupSubdivision count to each chord, for use in UI;
  // *** this seems wasteful adding this prop to each chord instead of passing a single value per section; consider pain point of passing as a separate prop;
  for (let i = 0; i < progressionWithRhythmCount.length; i++) {
    let thisChord = progressionWithRhythmCount[i];
    thisChord.groupSubdivisions = groupSubdivisions;
  }

  return {
    progressionWithRhythmCount,
  };
}
