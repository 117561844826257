import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { CONNECTION_ERROR_MESSAGE } from "../_fetch";
import { AUTH_ERROR } from "../RelayEnvironment";

const REDIRECT_ERRORS = [/* CONNECTION_ERROR_MESSAGE, */ AUTH_ERROR];

export function ErrorUI({ error }: { error: Error }) {
  console.error(error);
  let history = useHistory();

  function redirectOnAuthError() {
    if (REDIRECT_ERRORS.indexOf(error.message) > -1) {
      history.push("/logout");
    }
  }

  React.useEffect(() => {
    redirectOnAuthError();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [error]);

  return (
    <Wrapper>
      <Title>Hey!</Title>
      <ErrorMessage>{error.message}</ErrorMessage>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-size: ${20 / 16}rem;
  font-weight: bold;
`;

const ErrorMessage = styled.div`
  font-size: ${18 / 16}rem;
  white-space: pre-wrap;
`;
