import React from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import {
  UpdateOwnEmailMutation,
  updateOwnEmailInput,
} from "../__generated__/UpdateOwnEmailMutation.graphql";
import { CreateLoginResponse } from "../_types";
import { isApiError } from "../_types/errorTypes";

import { AppContext } from "../_context/appContext";
import { AuthActionTypes } from "../_reducers";
import { ModalError } from "../_components/ModalError";
import { TextInputModal } from "../_components/TextInputModal";
import { ModalSimpleMessage } from "../_components/ModalSimpleMessage";
import { Email } from "../_components/Email";

type Props = {
  email: string;
};

export function UpdateOwnEmail(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  // const [didSucceed, setDidSucceed] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState<string>("");
  const [errMsg, setErrMsg] = React.useState<string>("");

  function saveUserData(userData: Partial<CreateLoginResponse>) {
    if (userData && userData.jwt && userData.user) {
      dispatch({
        type: AuthActionTypes.Set,
        payload: {
          user: userData?.user,
          jwt: userData?.jwt,
        },
      });
    }
  }

  const [commit, isInFlight] = useMutation<UpdateOwnEmailMutation>(graphql`
    mutation UpdateOwnEmailMutation($input: updateOwnEmailInput!) {
      updateOwnEmail(input: $input) {
        jwt {
          token
          expiresIn
          expiresAt
        }
        user {
          id
          email
          roles
          boxIntervals
          emailConfirmed
        }
        errors {
          __typename
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function handleUpdateOwnEmail(input: updateOwnEmailInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.updateOwnEmail) {
          const { user, jwt, errors } = response.updateOwnEmail;
          if (user && jwt) {
            saveUserData({ user, jwt });
            // setDidSucceed(true);
            setModalMessage(`Email changed to: ${user.email}`);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "email",
            type: "email",
            label: "Email",
            required: true,
            initialValue: props.email,
          },
          {
            id: "password",
            type: "password",
            label: "Password",
            required: true,
            initialValue: "",
          },
        ]}
        createLabel={
          <AccountButtonContent>
            Change Email
            <EmailWrapper>
              <Email email={props.email} />
            </EmailWrapper>
          </AccountButtonContent>
        }
        createIconId={"mail"}
        createOrUpdate="create"
        actionText="Update"
        callback={(inputValues: Record<string, string>) => {
          handleUpdateOwnEmail({
            email: inputValues.email,
            password: inputValues.password,
          });
        }}
        isInFlight={isInFlight}
        modalLabel="Change Email"
        resetOnSubmit
        doUseLeastDestructiveRef={true}
      />
      <ModalSimpleMessage
        message={modalMessage}
        setMessage={setModalMessage}
        label={modalMessage}
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const AccountButtonContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const EmailWrapper = styled.div`
  font-size: ${14 / 16}rem;
`;
