/**
 * @generated SignedSource<<5af109dfd6f8df4f5812bca0fd7b909b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TestEventType = "FORGOT" | "REMEMBERED" | "REVIEWED" | "%future added value";
export type addUserTestEventInput = {
  clientMutationId?: string | null;
  eventType: TestEventType;
  songId: string;
};
export type AddSongTestEventMutation$variables = {
  input: addUserTestEventInput;
};
export type AddSongTestEventMutation$data = {
  readonly addUserTestEvent: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly user: {
      readonly id: string;
      readonly testHistory: ReadonlyArray<{
        readonly eventType: TestEventType;
        readonly song: string;
        readonly time: string;
      }>;
    } | null;
  } | null;
};
export type AddSongTestEventMutation = {
  response: AddSongTestEventMutation$data;
  variables: AddSongTestEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "addUserTestEventPayload",
    "kind": "LinkedField",
    "name": "addUserTestEvent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserTestEvent",
            "kind": "LinkedField",
            "name": "testHistory",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "song",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSongTestEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSongTestEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f6f5dac7cab4419bfab5635c4298ed6",
    "id": null,
    "metadata": {},
    "name": "AddSongTestEventMutation",
    "operationKind": "mutation",
    "text": "mutation AddSongTestEventMutation(\n  $input: addUserTestEventInput!\n) {\n  addUserTestEvent(input: $input) {\n    user {\n      id\n      testHistory {\n        song\n        eventType\n        time\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "412beec07eaafb3cb5b6555796dbf56b";

export default node;
