import React from "react";

export { scrollToRef, scrollToTop };

function easeInOut(
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
}

//https://gist.github.com/james2doyle/5694700
export function scrollToElAnim(
  element: HTMLElement,
  to: number,
  duration: number = 200,
  axis: "vert" | "horiz" = "vert"
) {
  var start = axis === "horiz" ? element.scrollLeft : element.scrollTop,
    change = to - start,
    elapsedTime = 0,
    increment = 20;
  var animateScroll = function () {
    elapsedTime += increment;
    var position = easeInOut(elapsedTime, start, change, duration);
    if (axis === "horiz") {
      element.scrollLeft = position;
    } else {
      element.scrollTop = position;
    }
    if (elapsedTime < duration) {
      window.requestAnimationFrame(animateScroll);
    }
  };
  window.requestAnimationFrame(animateScroll);
}

function scrollToRef(
  targetChild: React.RefObject<HTMLDivElement>,
  containingParent: React.RefObject<HTMLDivElement>,
  duration?: number
) {
  // *** below options added for use with ChordGrid; if use elsewhere, generalize and make these vars params
  // minimize unnecessary scrolling, when item is clearly in view
  const threshold = 240;
  // show row above selected, and a touch of second row above, to help user know location in section
  const topBuffer = -100;
  // ensure .current !== null; React will assign the current property with the DOM element when the component mounts, and assign it back to null when it unmounts.
  if (targetChild.current && containingParent.current) {
    // offsetTop will include padding/margin separating it from scrolling parent
    const childOffset = targetChild.current.parentElement?.offsetTop;
    const parentOffset = containingParent.current.scrollTop;
    // const parentScrollHeight = targetChild.current.parentElement?.scrollHeight;
    if (
      childOffset !== undefined &&
      // if childOffset is more, it is either in view, or below; scroll only if far enough down into view
      (childOffset > parentOffset + threshold ||
        // if childOffset is less, then it's above, out of the viewport: always scroll
        childOffset < parentOffset)
    ) {
      scrollToElAnim(
        containingParent.current,
        childOffset + topBuffer,
        duration
      );
    }
  }
}

function scrollToTop(duration: number = 1000) {
  var root = document.getElementById("root");
  if (root) {
    var scrollHere = root.offsetTop;
    // document.documentElement is an alternative to "window" that is IE compatible
    scrollToElAnim(document.documentElement, scrollHere, duration);
  }
}

// despite being identified as compatible with Android 6.0 (API 23), both emulator and device fail to recognize .scroll() and .scrollTo() as functions
/*element.scroll({
  top: 0, 
  left: 0, 
  behavior: 'smooth' 
  });*/
