import React from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";

import { SongCloneMutation } from "../__generated__/SongCloneMutation.graphql";
import { CustomErrors } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";

type Props = {
  songId: string;
  userId: string;
  callback?: () => void;
};

export function SongClone(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const [commit, isInFlight] = useMutation<SongCloneMutation>(graphql`
    mutation SongCloneMutation($connections: [ID!]!, $input: cloneSongInput!) {
      cloneSong(input: $input) {
        song @appendNode(connections: $connections, edgeTypeName: "UserSongs") {
          id
          mongoVersion
          title
          artist
          tags
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
          viewerPermission {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCommit() {
    const connectionID = ConnectionHandler.getConnectionID(
      props.userId,
      "UserSongs_songs"
    );
    commit({
      variables: {
        input: {
          songId: props.songId,
        },
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.cloneSong) {
          const { errors } = response.cloneSong;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          } else {
            props.callback && props.callback();
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
    });
  }

  return (
    <>
      <Button
        onClick={handleCommit}
        fill="clear"
        palette="secondary"
        disabled={isInFlight}
        // iconId="file-plus"
        iconId="copy"
      >
        Clone Song
      </Button>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </>
  );
}
