import { storage } from "../_plugins";
import { devicePlatform } from "../_plugins/Device";

import { AUTH_STORAGE_KEY } from "../_constants";

const API_URL = {
  development: process.env.REACT_APP_API_URL_DEV,
  production: process.env.REACT_APP_API_URL_PROD,
  test: process.env.REACT_APP_API_URL_DEV,
}[process.env.NODE_ENV];

if (!API_URL) {
  throw new Error("No API URL set; check env vars.");
}

const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// previously set TIMEOUT_MAX_WAIT to 5000ms, but at least once MongoDB suffered a period of slowness, resulting in resposes taking up to 15sec; the client would stop waiting at 5sec, then retry, resulting in three failed attempts and an error state; meanwhile, each request eventually returned the desired response, meaning client had the data it needed; likely this has impacted users;
const TIMEOUT_MAX_WAIT = 30000;
const RETRY_FETCH_ATTEMPTS = 2;
export const CONNECTION_ERROR_MESSAGE =
  "Please check your connection and try again.\n\nIf your connection is fine... sorry, it's our fault!";

export async function buildHeaders() {
  const authData = await storage.getItem(AUTH_STORAGE_KEY);
  var token;
  if (authData) {
    token = JSON.parse(authData).jwt.token;
  }
  // header keys are converted to lowercase: don't use uppercase and expect to find the same server-side
  var headers = {
    accept: "application/json",
    "content-type": "application/json;charset=UTF-8",
    "x-dom7-client-id": REACT_APP_CLIENT_ID,
    "x-dom7-client-version": REACT_APP_VERSION,
    "x-dom7-client-platform": devicePlatform,
  };
  if (token) {
    // return authorization header with jwt token
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}

export async function fetchIt(
  path,
  { method, body },
  remainingFetchAttempts = RETRY_FETCH_ATTEMPTS
) {
  process.env.NODE_ENV === "development" &&
    console.log(path, method, JSON.parse(body));
  const url = `${API_URL}${path}`;
  const options = {
    method,
    mode: "cors",
    headers: await buildHeaders(),
    body,
  };
  try {
    let response = await Promise.race([
      fetch(url, options),
      new Promise((resolve, reject) =>
        setTimeout(() => reject(new Error("Timeout")), TIMEOUT_MAX_WAIT)
      ),
    ]);
    let data = await response.json();
    if (process.env.NODE_ENV === "development") {
      console.log("Response status: ", response?.status);
      console.log("Response JSON:", data);
    }
    // response.ok is true if response code is 200-299; GraphQL server will return 200 unless there's been a "true" GraphQL error, e.g. invalid variable, then it will return 500; other codes indicate server error outside of GraphQL
    let responseOK = response && response.ok;
    if (responseOK) {
      return data;
    } else {
      if (data.errors) {
        // GraphQL error e.g. "invalid input"
        const concatErrMsgs = data.errors.map((val) => val.message).join(" / ");
        throw new Error(concatErrMsgs);
      } else if (data.message) {
        // Kidner API error e.g. "invalid client"
        throw new Error(data.message);
      } else {
        throw new Error("Error - no details available.");
      }
      // if ([401, 403].indexOf(response.status) !== -1) {}
    }
  } catch (err) {
    // console.log(err);
    if (
      err.name === "TypeError" ||
      err.message === "Timeout" ||
      err.message === "Network request failed" ||
      // fetch's message when it receives net::ERR_CONNECTION_REFUSED
      err.message === "Failed to fetch"
    ) {
      if (remainingFetchAttempts > 0) {
        remainingFetchAttempts--;
        // retry
        return new Promise((resolve) => {
          setTimeout(
            () =>
              resolve(fetchIt(path, { method, body }, remainingFetchAttempts)),
            1000 * (RETRY_FETCH_ATTEMPTS / remainingFetchAttempts)
          );
        });
      } else {
        throw new Error(CONNECTION_ERROR_MESSAGE);
      }
    } else {
      throw err;
    }
  }
}
