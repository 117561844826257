/**
 * @generated SignedSource<<b79752fbe6986d4b2be391267530412c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type updateSectionNameInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  name: string;
  sectionId: string;
  songId: string;
};
export type UpdateSectionNameMutation$variables = {
  input: updateSectionNameInput;
};
export type UpdateSectionNameMutation$data = {
  readonly updateSectionName: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly section: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionName_section">;
    } | null;
    readonly song: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionName_song">;
    } | null;
  } | null;
};
export type UpdateSectionNameMutation = {
  response: UpdateSectionNameMutation$data;
  variables: UpdateSectionNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSectionNameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionNamePayload",
        "kind": "LinkedField",
        "name": "updateSectionName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionName_song"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionName_section"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSectionNameMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionNamePayload",
        "kind": "LinkedField",
        "name": "updateSectionName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4ec5334531f95951a05122a5f5e56ae",
    "id": null,
    "metadata": {},
    "name": "UpdateSectionNameMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSectionNameMutation(\n  $input: updateSectionNameInput!\n) {\n  updateSectionName(input: $input) {\n    song {\n      ...UpdateSectionName_song\n      id\n    }\n    section {\n      ...UpdateSectionName_section\n      id\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment UpdateSectionName_section on SongSection {\n  id\n  name\n}\n\nfragment UpdateSectionName_song on Song {\n  id\n  mongoVersion\n}\n"
  }
};
})();

(node as any).hash = "8fdd10e3219c9e06f41b460962b9b74e";

export default node;
