// WORD_JOINER is used by ChordItem to control where line breaks occur for chord quality names (they are forced at every WORD_JOINER, by splitting the qual name into an array), when they are displayed in ChordGrid; using WORD_JOINER (a non-breaking space) because same quality names are used in the chord keyboard, and do not want breaks there; could be more flexible by using both "must break" and "may break" characters in these quality names, e.g. want "madd13" to break after "m", but if "add13" doesn't fit on one line, want it to break after "add"; if do so, in RepetitionInputs, would want to replace those special chars with nonbreaking characters (or remove them) to ensure breaking doesn't occur on keyboard;
import { WORD_JOINER } from "../_constants";

export type QualGroupName = "maj" | "min" | "dom" | "misc";

export const intervalToHalfSteps = {
  // unison
  "P1": 0,
  "d2": 0,
  "A1": 1,
  "m2": 1,
  "M2": 2,
  "d3": 2,
  "A2": 3,
  "m3": 3,
  "M3": 4,
  "d4": 4,
  "A3": 5,
  "P4": 5,
  "A4": 6,
  "d5": 6,
  // perfect 5th
  "P5": 7,
  "d6": 7,
  "A5": 8,
  "m6": 8,
  "M6": 9,
  "d7": 9,
  "A6": 10,
  "m7": 10,
  "M7": 11,
  "d8": 11,
  "A7": 12,
  // octave
  "P8": 12,
  "d9": 12,
  "A8": 13,
  "m9": 13,
  "M9": 14,
  "d10": 14,
  "A9": 15,
  "m10": 15,
  "M10": 16,
  "d11": 16,
  "A10": 17,
  "P11": 17,
  "A11": 18,
  "d12": 18,
  // octave + P5
  "P12": 19,
  "d13": 19,
  "A12": 20,
  "m13": 20,
  "M13": 21,
  "d14": 21,
  "A13": 22,
  "m14": 22,
  "M14": 23,
  "d15": 23,
  "A14": 24,
  // double octave
  "P15": 24,
  "A15": 25,
} as const;

type Interval = keyof typeof intervalToHalfSteps;

type ChordQual = {
  // intervals: number[];
  intervals: Interval[];
  label: string;
  group: QualGroupName;
};

interface ChordLibrary {
  [index: string]: ChordQual;
}

export const CHORD_QUALS: ChordLibrary = {
  maj: {
    // intervals: [4, 3],
    intervals: ["M3", "m3"],
    label: "maj",
    group: "maj",
  },
  maj6: {
    // intervals: [4, 3, 2],
    intervals: ["M3", "m3", "M2"],
    label: "6",
    group: "maj",
  },
  maj7: {
    // intervals: [4, 3, 4],
    intervals: ["M3", "m3", "M3"],
    label: "maj7",
    group: "maj",
  },
  maj9: {
    // intervals: [4, 3, 4, 3],
    intervals: ["M3", "m3", "M3", "m3"],
    label: "maj9",
    group: "maj",
  },
  "maj♯11": {
    // intervals: [4, 3, 4, 3, 4],
    intervals: ["M3", "m3", "M3", "m3", "M3"],
    label: `maj${WORD_JOINER}♯11`,
    group: "maj",
  },
  maj13: {
    // 1 3 5 7 9 (11) 13 - drop the 11
    // *** noted which notes to drop primarily for the purpose of future audio feature: these are notes you'd likely exclude while playing, for clarity and ease of playing; may need to make these "notes to play" a separate property, as some other functions may have difficulty dealing with the "0"
    // intervals: [4, 3, 4, 3, 0, 7],
    // intervals: [4, 3, 4, 3, 3, 4],
    // intervals: ["M3", "m3", "M3", "m3", "m3", "M3"],
    intervals: ["M3", "m3", "M3", "m3", "P5"],
    label: `maj${WORD_JOINER}13`,
    group: "maj",
  },
  majAdd9: {
    // intervals: [4, 3, 7],
    intervals: ["M3", "m3", "P5"],
    label: "add9",
    group: "maj",
  },
  majAdd11: {
    // intervals: [4, 3, 10],
    intervals: ["M3", "m3", "m7"],
    label: `add${WORD_JOINER}11`,
    group: "maj",
  },
  majAdd13: {
    // intervals: [4, 3, 14],
    intervals: ["M3", "m3", "M9"],
    label: `add${WORD_JOINER}13`,
    group: "maj",
  },
  maj69: {
    // intervals: [4, 3, 2, 5],
    intervals: ["M3", "m3", "M2", "P4"],
    label: `6-9`,
    group: "maj",
  },
  min: {
    // intervals: [3, 4],
    intervals: ["m3", "M3"],
    label: "min",
    group: "min",
  },
  min6: {
    // inversion of m7b5
    // intervals: [3, 4, 2],
    intervals: ["m3", "M3", "M2"],
    label: "m6",
    group: "min",
  },
  min7: {
    // intervals: [3, 4, 3],
    intervals: ["m3", "M3", "m3"],
    label: "m7",
    group: "min",
  },
  min9: {
    // intervals: [3, 4, 3, 4],
    intervals: ["m3", "M3", "m3", "M3"],
    label: "m9",
    group: "min",
  },
  min11: {
    // intervals: [3, 4, 3, 4, 3],
    intervals: ["m3", "M3", "m3", "M3", "m3"],
    label: "m11",
    group: "min",
  },
  min13: {
    // because of the minor 3rd, the 11 isn't avoided in m13 chords, as it tends to be in maj13 and dom13;
    // intervals: [3, 4, 3, 4, 3, 4],
    intervals: ["m3", "M3", "m3", "M3", "m3", "M3"],
    label: "m13",
    group: "min",
  },
  minAdd9: {
    // intervals: [3, 4, 7],
    intervals: ["m3", "M3", "P5"],
    label: `m${WORD_JOINER}add9`,
    group: "min",
  },
  minAdd11: {
    // intervals: [3, 4, 10],
    intervals: ["m3", "M3", "m7"],
    label: `m${WORD_JOINER}add11`,
    group: "min",
  },
  minAdd13: {
    // intervals: [3, 4, 14],
    intervals: ["m3", "M3", "M9"],
    label: `m${WORD_JOINER}add13`,
    group: "min",
  },
  minMaj7: {
    // intervals: [3, 4, 4],
    intervals: ["m3", "M3", "M3"],
    label: `m${WORD_JOINER}Maj7`,
    group: "min",
  },
  dom7: {
    // intervals: [4, 3, 3],
    intervals: ["M3", "m3", "m3"],
    label: "7",
    group: "dom",
  },
  dom7sus2: {
    // intervals: [2, 5, 3],
    intervals: ["M2", "P4", "m3"],
    label: `7${WORD_JOINER}sus2`,
    group: "dom",
  },
  dom7sus4: {
    // intervals: [5, 2, 3],
    intervals: ["P4", "M2", "m3"],
    label: `7${WORD_JOINER}sus4`,
    group: "dom",
  },
  dom9: {
    // intervals: [4, 3, 3, 4],
    intervals: ["M3", "m3", "m3", "M3"],
    label: "9",
    group: "dom",
  },
  "dom♯9": {
    // 1 3 5 ♭7 ♯9; "Hendrix chord"; also "aug9"
    // intervals: [4, 3, 3, 5],
    intervals: ["M3", "m3", "m3", "A3"],
    label: "7♯9",
    group: "dom",
  },
  "dom♯11": {
    // 1 3 5 ♭7 (9) ♯11 - skip the 9
    // intervals: [4, 3, 3, 0, 8],
    // intervals: [4, 3, 3, 4, 4],
    // intervals: ["M3", "m3", "m3", "M3", "M3"],
    intervals: ["M3", "m3", "m3", "A5"],
    label: "7♯11",
    group: "dom",
  },
  dom13: {
    // 1 3 5 ♭7 9 (11) 13 - skip the 11
    // intervals: [4, 3, 3, 4, 0, 7],
    // intervals: [4, 3, 3, 4, 3, 4],
    // intervals: ["M3", "m3", "m3", "M3", "m3", "M3"],
    intervals: ["M3", "m3", "m3", "M3", "P5"],
    label: "13",
    group: "dom",
  },
  "5": {
    // intervals: [7],
    intervals: ["P5"],
    label: "5",
    group: "misc",
  },
  sus2: {
    // intervals: [2, 5],
    intervals: ["M2", "P4"],
    label: "sus2",
    group: "misc",
  },
  sus4: {
    // intervals: [5, 2],
    intervals: ["P4", "M2"],
    label: "sus4",
    group: "misc",
  },
  dim: {
    // intervals: [3, 3],
    intervals: ["m3", "m3"],
    label: "dim",
    group: "misc",
  },
  "m7♭5": {
    // intervals: [3, 3, 4],
    intervals: ["m3", "m3", "M3"],
    label: "m7♭5",
    group: "misc",
  },
  dim7: {
    // intervals: [3, 3, 3],
    intervals: ["m3", "m3", "m3"],
    label: "dim7",
    group: "misc",
  },
  aug: {
    // intervals: [4, 4],
    intervals: ["M3", "M3"],
    label: "aug",
    group: "misc",
  },
  "min7♯5": {
    // intervals: [3, 5, 2],
    intervals: ["m3", "A3", "d3"],
    label: "m7♯5",
    group: "misc",
  },
  "maj7♯5": {
    // intervals: [4, 4, 3],
    intervals: ["M3", "M3", "m3"],
    label: `maj7${WORD_JOINER}♯5`,
    group: "misc",
  },
  maj7sus4: {
    // intervals: [5, 2, 4],
    intervals: ["P4", "M2", "M3"],
    label: `M7${WORD_JOINER}sus4`,
    // label: `maj7${WORD_JOINER}sus4`,
    group: "misc",
  },
  // *** not sure what to do with these yet
  // aug7 is dom7#5
  // aug7: [4, 4, 2],
  // "m9♭5": [3, 3, 4, 4],
  // "m11♭5": [3, 3, 4, 4, 3],
  // "m13♭5": {
  //   intervals: [3, 3, 4, 4, 3, 4],
  //   label: "m13♭5",
  // },
  //   mMaj9: [3, 4, 4, 3],
  // mMaj11: [3, 4, 4, 3, 3],
  // mMaj13: [3, 4, 4, 3, 3, 4],
};

// could define this as a "normal" object type; advantage here is Record "builds in" use of the string literals as keys; need additional definition with plain object type; if want to make some keys optional, could make a Partial type from this type, or - perhaps more straightforward - use plain object type
type ChordQualGroups = Record<QualGroupName, string[]>;
const emptyQualGroups: ChordQualGroups = {
  maj: [],
  min: [],
  dom: [],
  misc: [],
};

export const chordQualGroups = Object.keys(CHORD_QUALS).reduce<ChordQualGroups>(
  (prev, curr: string) => {
    const prefix: QualGroupName = CHORD_QUALS[curr].group;
    const qualArray: string[] = [...(prev[prefix] && prev[prefix]), curr];
    return {
      ...prev,
      [prefix]: qualArray,
    };
  },
  emptyQualGroups
);
