/**
 * @generated SignedSource<<561af5f108f23423d1e68d96365dab5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongDeleteShare_song$data = {
  readonly artist: string;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "SongDeleteShare_song";
};
export type SongDeleteShare_song$key = {
  readonly " $data"?: SongDeleteShare_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"SongDeleteShare_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SongDeleteShare_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artist",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "a55b1d3545da009e7d6c0d468c72704e";

export default node;
