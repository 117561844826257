import React from "react";
import styled from "styled-components/macro";
import { hapticsImpactLight } from "../_plugins/Haptics";
import { RippleContainer, useRipple } from "./useRipple";

/**
 * Ideas:
 * ripple color as prop... prob can get it in existing style vars
 * a touchstart/mousedown state that creates a small static "depression" ripple, in lieu of an active state
 * scale animation duration to size of button
 *
 */

type Props = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
  onTouchStart?: React.MouseEventHandler<HTMLElement>;
  onMouseUp?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  onTouchMove?: React.MouseEventHandler<HTMLElement>;
  onTouchEnd?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
};

export const RippleButton = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    const { createRipple, clickParams } = useRipple();

    return (
      <Wrapper
        ref={ref}
        className={props.className}
        type={props.type}
        style={props.style as React.CSSProperties}
        // if pass disabled to native button component, don't need to handle separately here
        disabled={props.disabled}
        onClick={(e) => {
          // if (props.disabled) return;
          hapticsImpactLight();
          createRipple(e);
          props.onClick && props.onClick(e);
        }}
        onMouseDown={(e: any) => {
          props.onMouseDown && props.onMouseDown(e);
        }}
        onTouchStart={(e: any) => {
          props.onTouchStart && props.onTouchStart(e);
        }}
        onMouseUp={(e: any) => {
          props.onMouseUp && props.onMouseUp(e);
        }}
        onMouseLeave={(e: any) => {
          props.onMouseLeave && props.onMouseLeave(e);
        }}
        onTouchMove={(e: any) => {
          props.onTouchMove && props.onTouchMove(e);
        }}
        onTouchEnd={(e: any) => {
          props.onTouchEnd && props.onTouchEnd(e);
        }}
      >
        {/* {!props.disabled && ( */}

        <RippleContainer clickParams={clickParams} />

        {/* )} */}
        {props.children}
      </Wrapper>
    );
  }
);

const Wrapper = styled.button`
  position: relative;
  overflow: hidden;
  border: 0;
  color: var(--color-text);
`;
