export const sectionNameSuggestions: readonly string[] = [
  "Intro",
  "Verse",
  "Pre-Chorus",
  "Chorus",
  "Bridge",
  "Outro",
  // "Intro 2",
  // "Verse 2",
  // "Pre-Chorus 2",
  // "Chorus 2",
  // "Bridge 2",
  // "Outro 2",
];

const sampleSongSectionStructure: readonly string[] = [
  "Intro",
  "Verse",
  "Chorus",
  "Verse",
  "Chorus",
  "Bridge",
  "Chorus",
  "Outro",
];

// suggest section name based on prior section name and how many sections "deep" into song;
// *** could use same code to suggest based on an array of names approximating user's usual naming pattern
// *** also could be smart to count to see how many of each section type is allowable... e.g. in sampleStructure, Intro should only appear once, but many Chorus(es) are allowed

export function suggestSectionName(
  sectionNameArray: string[],
  sectionNum: number
) {
  if (sectionNum === 0) {
    // no prior section name to work with
    return sampleSongSectionStructure[0];
  }
  var priorSectionName = sectionNameArray[sectionNum - 1];
  var priorInSample = sampleSongSectionStructure
    .map((name, index) => ({ name, index }))
    .filter((nameObj) => priorSectionName.indexOf(nameObj.name) > -1);
  // .filter((nameObj) => nameObj.name === priorSectionName);
  // console.log({ priorInSample });
  if (priorInSample.length === 0) {
    // if prior section name not found in sample, guess based on location; *** alternatively, keep looking backwards until find a section name that exists in sample
    let targetIndex = Math.min(
      sampleSongSectionStructure.length - 1,
      sectionNum
    );
    return sampleSongSectionStructure[targetIndex];
  } else {
    // measure index distance of matching names found in sample, return index of lowest distance
    // *** filter out names that are contained in prior section name
    var sortedByDistance = priorInSample
      .map((nameObj) => {
        var distance = Math.abs(nameObj.index - sectionNum);
        return { ...nameObj, distance };
      })
      .sort((a, b) => a.distance - b.distance);
    var closestPriorName = sortedByDistance[0].index;
    let targetIndex = Math.min(
      sampleSongSectionStructure.length - 1,
      closestPriorName + 1
    );
    return sampleSongSectionStructure[targetIndex];
  }
}
