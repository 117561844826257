import React from "react";
import styled from "styled-components/macro";

import { ChordOrCursor } from "../../../_types";

import { HelpModal } from "../../../_components/HelpModal";
import { useTap } from "../../../_context/chordContext";
import Button from "../../../_components/Button";
import {
  TapGroupInstructions,
  ScaleButton,
  ScaleButtonText,
  ShowTappedBeatTotal,
  TapButtonRow,
  TapGroupWrapper,
} from "./TapGroupShared";
import { CURSOR_ID } from "../../Cursor";

type Props = {
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
};

export function TapScale(props: Props) {
  const {
    tappedBeatTotal,
    scaleOptions,
    scaleTapStatGroupsBy,
    selectAllForScaling,
    deSelectAllForScaling,
    tapIdSet,
    tapSessionData,
  } = useTap({
    callBackAfterUpdateChords: props.handleUpdateSectionChords,
  });

  const noChordsAreSelected = tapIdSet.size === 0;
  const allChordsAreSelected =
    tapIdSet.size >=
    tapSessionData.sectionChords.filter((val) => val.id !== CURSOR_ID).length;

  return (
    <TapGroupWrapper>
      <TapButtonRow>
        <TapGroupInstructions>
          {noChordsAreSelected ? "Select chords" : "Scale chords"}
        </TapGroupInstructions>
      </TapButtonRow>
      <TapButtonRow>
        <ShowTappedBeatTotal tappedBeatTotal={tappedBeatTotal} />
        <SelectButtons>
          <Button
            expand="inline"
            size={"sm"}
            disabled={allChordsAreSelected}
            fill={"clear"}
            onClick={selectAllForScaling}
            palette={allChordsAreSelected ? "disabled" : "text"}
          >
            All
          </Button>
          <Button
            expand="inline"
            size={"sm"}
            disabled={noChordsAreSelected}
            fill={"clear"}
            onClick={deSelectAllForScaling}
            palette={noChordsAreSelected ? "disabled" : "text"}
          >
            None
          </Button>
        </SelectButtons>
      </TapButtonRow>
      <TapButtonRow>
        {scaleOptions.map((option) => {
          const { scale, isAvailable } = option;
          return (
            <ScaleButton
              key={scale}
              onClick={() => scaleTapStatGroupsBy(scale)}
              expand="inline"
              size="sm"
              disabled={!isAvailable}
              fill="clear"
              // fill="outline"
              palette={isAvailable ? "text" : "disabled"}
            >
              <ScaleButtonText>
                {scale < 1 ? `1/${Math.round(1 / scale)}` : `${scale} x`}
              </ScaleButtonText>
            </ScaleButton>
          );
        })}
        <HelpModal label="Scale Rhythm">
          <h3>Scale Rhythm</h3>
          <p>Tap chords to select.</p>
          <p>
            Scale the durations of selected chords. This maintains their ratios,
            while changing overall duration by 1/3, 1/2, 2x, or 3x.
          </p>
        </HelpModal>
      </TapButtonRow>
    </TapGroupWrapper>
  );
}

const SelectButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
