import React from "react";
import styled from "styled-components/macro";
// import Portal from "@reach/portal";
import { useTransition, animated, config } from "@react-spring/web";

import type { ChordInputs, ChordOrCursor } from "../../_types";

import { Icon } from "../../_components/Icon";
import { SideTabs, TabItem } from "../../_components/SideTabs";
import { RepetitionInputs } from "./RepetitionInputs";
import { DurationSlider } from "../DurationSlider";
/* import SVGComponent from "../../_svg/SVGComponent";
import Button from "../../_components/Button"; */
import {
  CURSOR_ID,
  isRootValid,
  isQualValid,
  isCursorValidChord,
  CURSOR_EMPTY_VAL,
} from "../Cursor";
import { QualityInputGroups } from "./QualityInputGroups";
import { NoteInput } from "./NoteInput";
import { InputTabWrapper } from "./InputTabWrapper";
import { FooterCore } from "../../_components/FooterCore";
import { AddDelete } from "./AddDelete";
import { BREAKPOINTS } from "../../_constants";
import { useInputMode } from "../../_context/chordContext";
import { TapInputGroups } from "./TapInputGroup/TapInputGroups";
import { ChordInSong } from "../ChordsSimple";
import { SideScroller } from "../../_components";
import Button from "../../_components/Button";
import { CHORD_QUALS } from "../../_music/chordQualLib";

type Props = {
  selectedId: number;
  // selectedIndex: number;
  selectedChord: ChordOrCursor;
  handleCreateChord: (suggestedChord?: ChordInputs) => void;
  handleResetCursor: () => void;
  handleUpdateChord: (chord: ChordInputs) => void;
  handleDeleteChord: () => void;
  // children?: React.ReactNode;
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
  sectionChordCount: number;
  suggestedChords: ChordInSong[];
};

type TabOption =
  | "root"
  | "qual"
  | "addDelete"
  | "repeat"
  | "bass"
  | "tapDuration";

export function CreateUpdateChord({
  selectedId,
  // selectedIndex,
  selectedChord,
  handleCreateChord,
  handleResetCursor,
  handleUpdateChord,
  handleDeleteChord,
  // children,
  handleUpdateSectionChords,
  sectionChordCount,
  suggestedChords,
}: Props) {
  const { definition, duration, rhythm, notation } = selectedChord;
  const { root, qual, bass } = definition;

  const tabColors: Record<TabOption, string> = {
    root: "var(--color-tab-root)",
    qual: "var(--color-tab-qual)",
    addDelete: "var(--color-tab-addDelete)",
    bass: "var(--color-tab-bass)",
    repeat: "var(--color-tab-repeat)",
    tapDuration: "var(--color-tab-tap)",
  };

  const tabItems: TabItem[] = [
    {
      id: "root",
      label: "root",
      content: (selected: boolean) => (
        // <SVGComponent selected={selected} svgId={"trebleClef"} />
        <>Root</>
      ),
      background: tabColors.root,
    },
    {
      id: "qual",
      label: "qual",
      content: (selected: boolean) => (
        // <SVGComponent selected={selected} svgId={"bassClef"} />
        <>Qual</>
      ),
      background: tabColors.qual,
    },
    {
      id: "bass",
      label: "bass",
      content: (selected: boolean) => (
        // <SVGComponent selected={selected} svgId={"bassClef"} />
        <>Bass</>
      ),
      background: tabColors.bass,
    },
    {
      id: "repeat",
      label: "repeat",
      content: (selected: boolean) => (
        /* <>
        <SVGComponent selected={selected} svgId={"repeatStart"} />
        <SVGComponent selected={selected} svgId={"repeatEnd"} />
        </> */
        <>Rep</>
      ),
      background: tabColors.repeat,
    },
    {
      id: "tapDuration",
      label: "tapDuration",
      content: (selected: boolean) => <>Rhy</>,
      background: tabColors.tapDuration,
    },
    {
      id: "addDelete",
      label: "+/-",
      content: (selected: boolean) =>
        isCursorValidChord(root, qual) && (
          <>
            {selectedChord.id === CURSOR_ID ? (
              <IconWrapper
                style={
                  {
                    "--color": isCursorValidChord(root, qual)
                      ? "var(--color-primary)"
                      : "var(--color-text-light)",
                  } as React.CSSProperties
                }
              >
                <Icon id="plus-square" size={28} />
              </IconWrapper>
            ) : (
              <IconWrapper
                style={
                  { "--color": "var(--color-danger)" } as React.CSSProperties
                }
              >
                <Icon id="delete" size={28} />
              </IconWrapper>
            )}
          </>
        ),
      // I previously liked this (which allows "adding" by clicking on the tab button in certain circumstances), but now think it's more likely to cause confusion and frustration; similar behavior does not work for "delete", nor for any of the other tab buttons, so removing this callback makes UX more consistent;
      // callback: () => {
      //   selectedChord.id === CURSOR_ID && handleCreateChord();
      // },
      // isButton: true,
      background: isCursorValidChord(root, qual)
        ? tabColors.addDelete
        : "transparent",
      disabled: !isCursorValidChord(root, qual),
    },
  ].filter((val) => {
    if (val.id === "tapDuration" && sectionChordCount < 2) {
      return false;
    } else {
      return true;
    }
  });

  const DEFAULT_TAB = "root";
  const [selectedTab, setSelectedTab] = React.useState<TabOption>(DEFAULT_TAB);

  const { inputMode, setInputMode } = useInputMode();
  function changeInputModeOnTabChange(tabId: string) {
    if (tabId !== "tapDuration" && inputMode !== "manual") {
      // when leaving tapDuration tab, reset input mode; when switching to tapDuration tab, its component (TapInputGroups) uses setInputMode to change inputMode as needed, b/c each of its subtabs use different modes;
      setInputMode("manual");
    }
  }

  React.useEffect(
    () => {
      // reset input tab if cursor is selected (as will be the case after adding new chord), or if certain tabs are selected that are unlikely to be used serially (whereas, e.g. user may wish to stay on repeat tab if adding multiple chords to an alternate ending, or may wish to stay on root tab if transposing a section... which would be a problem b/c your code auto moves to qual tab after selecting root, but which below code cures... perhaps smarter solution is to stop auto-progression when editing chord)
      if (
        selectedChord.id === CURSOR_ID
        // ||
        // selectedTab === "qual" ||
        // selectedTab === "bass"
      ) {
        handleSetSelectedTab(DEFAULT_TAB);
      }
    },
    // listen for changes to selectedChord.id to ensure this triggers when selectedId (now an index) doesn't change (e.g. when delete last chord in section, so index remains "0", even though target chord changes to cursor), and still listen to sectionId, otherwise going from cursor to cursor (e.g. when adding two new chords in a row) won't trigger;
    [selectedId, selectedChord.id]
  );

  function handleSetSelectedTab(id: string) {
    // *** lazy typing here, as this component has evolved; ideally, shouldn't use "as" here, and this fnc should take id as TabsOptions;
    setSelectedTab(id as TabOption);
    changeInputModeOnTabChange(id);
  }

  function moveToTab(targetTabId?: TabOption) {
    // auto move tabs only for cursor input
    if (selectedChord.id !== CURSOR_ID) return;

    if (!targetTabId) {
      const currentTabItemIndex = tabItems.findIndex(
        (val) => val.id === selectedTab
      );
      const nextTab = tabItems[currentTabItemIndex + 1] || tabItems[0];
      var nextTabId = nextTab.id;
    }
    setTimeout(() => {
      handleSetSelectedTab(targetTabId ?? nextTabId);
    }, 200);
  }

  function handleEditChord({
    name,
    value,
  }: {
    name: string;
    value: string | number | string[];
  }) {
    var updatedChordState = {
      root,
      qual,
      bass,
      duration,
      rhythm,
      notation,
      [name]: value,
    };
    if (name === "root" && root === bass && typeof value === "string") {
      // if root and bass are currently the same, keep them locked when root changed
      updatedChordState = {
        ...updatedChordState,
        bass: value,
      };
    }
    if (name === "duration") {
      // manual change in a chord's duration should wipe its rhythm data; otherwise, they will reflect different durations; likewise, setting rhythm changes duration to match;
      updatedChordState = {
        ...updatedChordState,
        rhythm: [],
      };
    }
    handleUpdateChord(updatedChordState);
    /* if (
      selectedId === CURSOR_ID &&
      selectedTab !== "repeat" &&
      name !== "duration"
    ) {
      moveToTab();
    } */
  }

  const tabsObj = {
    root: (
      <NoteInput
        handleEditChord={handleEditChord}
        selectedNote={root}
        noteRole={"root"}
        moveToTab={() => {
          if (isQualValid(qual)) {
            moveToTab("addDelete");
          } else {
            moveToTab("qual");
          }
        }}
      />
    ),
    qual: (
      <QualityInputGroups
        selectedId={selectedId}
        handleEditChord={handleEditChord}
        qual={qual}
        moveToTab={() => {
          if (isRootValid(root)) {
            moveToTab("addDelete");
          } else {
            moveToTab("root");
          }
        }}
      />
    ),
    bass: (
      <NoteInput
        handleEditChord={handleEditChord}
        selectedNote={bass}
        noteRole={"bass"}
        moveToTab={() => {
          if (!isRootValid(root)) {
            moveToTab("root");
          } else if (!isQualValid(qual)) {
            moveToTab("qual");
          } else {
            moveToTab("addDelete");
          }
        }}
        altKeyColor={true}
      />
    ),
    repeat: (
      <RepetitionInputs handleEditChord={handleEditChord} notation={notation} />
    ),
    tapDuration: (
      <TapInputGroups handleUpdateSectionChords={handleUpdateSectionChords} />
    ),
    addDelete: (
      <AddDelete
        selectedId={selectedId}
        selectedChord={selectedChord}
        handleEditChord={handleEditChord}
        handleCreateChord={handleCreateChord}
        handleDeleteChord={handleDeleteChord}
        moveToTab={() => moveToTab("bass")}
      />
    ),
  };

  const handleAddSuggestedChord = (suggestedChord: ChordInSong) => {
    let { chord, count } = suggestedChord;
    let { definition } = chord;
    let { bass, root, qual } = definition;
    handleCreateChord({
      bass,
      root,
      qual,
      duration: 0,
      rhythm: [],
      notation: [],
    });
  };

  const chordDefIsPartiallyDefined =
    root !== CURSOR_EMPTY_VAL ||
    qual !== CURSOR_EMPTY_VAL ||
    bass !== CURSOR_EMPTY_VAL;

  function stopPropagation(e: React.TouchEvent<HTMLDivElement>) {
    // presently, primary use is to prevent drags here from activating pull-to-refresh function built into ancestor Page;
    console.warn("Stopping touch propagation: CreateUpdateChord.");
    e.stopPropagation();
  }

  /*   const durationSliderTransition = useTransition(
    inputMode === "manual" && selectedTab === "tapDuration",
    {
      from: { transform: "translate3d(100%,0,0)" },
      enter: { transform: "translate3d(0%,0,0)" },
      leave: { transform: "translate3d(100%,0,0)" },
      config: {
        ...config.stiff,
        clamp: false,
      },
    }
  ); */

  const TAB_WIDTH = 52;
  // PADDING_LEFT primarily to give space for SideTabs box-shadow
  const PADDING_LEFT = 2;

  return (
    <Wrapper
      // *** this was short-sighted of you; dangerous move to use as blunt an instrument as stopPropagation for such a narrow use; you wanted to prevent usePullRefresh from triggering within this component; side effect that drove you mad debugging: you were't able to scroll through a modal opened from inside this component; terribly deceptive, too, as that modal was deep into this component (inside TapDuration), and b/c it used ReachUI Portal (which uses React Portal), it seemed it should be immune to such a touch handler, just as it is immune to React parent styles;
      // onTouchStart={stopPropagation}
      // *** thought it would be wise to stopPropagation for all touch events, but apply to onTouchMove prevented dragging on DurationSlider; not sure why;
      // onTouchMove={stopPropagation}
      // onTouchEnd={stopPropagation}
      // onTouchCancel={stopPropagation}
      style={
        {
          "--background": tabColors[selectedTab],
        } as React.CSSProperties
      }
    >
      <SafeWrapper
        style={{ "--padding-left": `${TAB_WIDTH}px` } as React.CSSProperties}
      >
        <DurationSliderRelativeWrapper>
          {/* if want to restore hide/show DurationSlider (based on input mode), uncomment the below blocks wrapping DurationSlider, and the definition of durationSliderTransition above; */}
          {/*{durationSliderTransition((style, doShowDurationSlider) => (
            <DurationSliderWrapperAnim style={style}>
              {doShowDurationSlider && ( */}
          <DurationSlider
            // *** was resetting slider with a key... for some reason... instead of sending it selectedId and using useEffect to reset state val; needless to say, this is cleaner and more predictable *** don't need either of these currently as 1) now use useEffect with duration as a dependency to update slider value; 2) cursor doesn't change [to suggested] values when dragged to new location
            // selectedId={selectedId + selectedIndex}
            // key includes selectedIndex to handle dragging cursor, which will update its duration based on location
            // key={selectedId + selectedIndex}
            duration={duration}
            handleEditChord={handleEditChord}
            disabled={inputMode !== "manual"}
          />
          {/*)}
            </DurationSliderWrapperAnim>
          ))} */}
        </DurationSliderRelativeWrapper>
        <InputTabWrapper tabsObj={tabsObj} selectedTab={selectedTab} />
        <SideTabs
          groupId={"CreateUpdateChord"}
          items={tabItems}
          selectedTab={selectedTab}
          handleSetSelectedTab={handleSetSelectedTab}
          useSafeArea={true}
          wrapperStyles={{ paddingLeft: PADDING_LEFT, tabWidth: TAB_WIDTH }}
        />

        {/*   <Portal>
          {selectedId !== CURSOR_ID && (
            <DeleteWrapper>
              <Button
                onClick={handleDeleteChord}
                expand="inline"
                size="sm"
                palette="danger"
                fill="fill"
              >
                <Icon id="delete" size={28} />
              </Button>
            </DeleteWrapper>
          )}
        </Portal> */}
        {selectedChord.id === CURSOR_ID && selectedTab !== "tapDuration" && (
          <OneTouchChordList>
            <SideScroller resetScrollKey={selectedId}>
              {suggestedChords.map((val, i) => {
                let { chord, count } = val;
                let { definition } = chord;
                let { bass, root, qual } = definition;
                return (
                  <OneTouchChord
                    key={i}
                    onClick={() => handleAddSuggestedChord(val)}
                  >
                    <Button>
                      {root}
                      {CHORD_QUALS[qual]?.label}
                      {root !== bass && <>/{bass}</>}
                    </Button>
                  </OneTouchChord>
                );
              })}
              {chordDefIsPartiallyDefined && (
                <OneTouchChordReset
                  onClick={() => {
                    handleSetSelectedTab(DEFAULT_TAB);
                    handleResetCursor();
                  }}
                >
                  <Button palette="secondary">
                    <Icon id="rotate-ccw" />
                  </Button>
                </OneTouchChordReset>
              )}
            </SideScroller>
          </OneTouchChordList>
        )}
      </SafeWrapper>
    </Wrapper>
  );
}

// export const CREATE_UPDATE_CHORD_HEIGHT = 260;
export const CREATE_UPDATE_CHORD_HEIGHT = 304;

const Wrapper = styled(FooterCore)`
  /* *** note: CreateUpdateChord uses position:absolute, and you want it to attach to Page; thus, ensure there are no positioned elements in between; ideally, you'd hoist CreateUpdateChord up so it's a child of a PageFooter that is a sibling to PageContent... instead of a child of PageContent tacked onto Page via position:absolute; */

  background-color: var(--background);
  /* transform: translate3d(0, var(--height), 0); */
  /* min-height: var(--height); */
  /* transform: translate3d(0, 100%, 0);
  transition: transform ease-in-out 200ms; */
  /* transform: translate3d(0, 0, 0); */
`;

const SafeWrapper = styled.div`
  height: ${CREATE_UPDATE_CHORD_HEIGHT}px;
  min-height: ${CREATE_UPDATE_CHORD_HEIGHT}px;
  padding-left: var(--padding-left);
  display: flex;
  flex-flow: column nowrap;

  /* set max width, as simple accommodation for wide-screen viewing */
  /* *** if find yourself revisiting the structure of these elements, you may consider: move TabWrapper "within" SafeWrapper (by setting position:relative on SafeWrapper); this will allow you to keep the tabs closer to the inputs (and keep the tabs within the safe area), but you'll have to re-jig your tab-based background colors*/
  max-width: ${BREAKPOINTS.smTabletMin}px;
  margin: 0 auto;
`;

const OneTouchChordList = styled.div`
  /* position: absolute;
  top: -66px;
  left: 0px;
  right: 0px; */
  padding-bottom: 4px;
  /* width: 100%; */
`;

const OneTouchChord = styled.div`
  /* give buttons room to breathe, and favor shifting them to the right to avoid abutting tabs */
  margin-right: 4px;
  margin-left: auto;
`;
const OneTouchChordReset = styled(OneTouchChord)``;

const DurationSliderRelativeWrapper = styled.div`
  /* prevent DurationSliderWrapper from "collapsing" after animating away DurationSlider*/
  /* min-height: 26px; */
  min-height: 8px;
  /* when DurationSliderWrapper slides in and "springs" past its proper position on the left, ensure it appears above the chord input tabs */
  z-index: 1;
`;
const DurationSliderWrapper = styled.div`
  /* padding: 0 20px; */
  /* padding-bottom:8px; */
  /* margin-top: -14px; */
`;

const DurationSliderWrapperAnim = animated(DurationSliderWrapper);

const IconWrapper = styled.div`
  color: var(--color);
`;

/* 
const InputsWrapper = styled.div`
  position: relative;
  flex: 1;
  // ensure 3D elements in these inputs stay in front of DurationSlider's 3D handle
  z-index: 1;
`;

const InputsWrapperAbsolute = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const InputsWrapperAbsoluteAnim = animated(InputsWrapperAbsolute); */

const DeleteWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 12px;
  /* note: adding a border here also adds a 1px "gap" to inside top and left */
  /* border: 2px solid var(--color-danger-light); */
  border-radius: 4px;
  height: 40px;
  width: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px var(--color-shadow);
  &:active {
    box-shadow: 0px 1px 2px var(--color-shadow);
  }
`;
