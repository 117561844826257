import React from "react";
import styled from "styled-components/macro";
import { hapticsImpactLight } from "../_plugins/Haptics";
import { NativeCheckBox } from "./GKeyboard/GKey";
import { useGKeyboardTouch } from "./GKeyboard/useGKeyboardTouch";
import { GKEY_HANDOFF_ID } from "./GKeyboard/useGKeyboardTouch.helpers";
// import { RippleButton } from "./RippleButton";

const styles = {
  default: {
    "--background": "hsla(0, 0%, 0%, 0.2)",
    "--box-shadow": "inset -1px 0px 3px -1px var(--color-shadow)",
  },
  selected: {
    "--background": "none",
    "--color": "var(--color-text)",
    "--box-shadow": "-1px 0px 2px -1px var(--color-shadow)",
    "--border": "1px solid var(--color-shadow-light)",
  },
  disabled: {
    "--box-shadow": "none",
  },
};

type SideTabWrapperStyles = {
  tabWidth?: number;
  positionLeft?: number;
  paddingLeft?: number;
};

export type TabItem = {
  id: string;
  // note: you don't currently use label, and content was designed for a more complex use case, which you don't use; likely could dispense with content and simply use a label, though would have to update all TabItems to ensure desired display label is passed;
  label: string;
  content?: (selected: boolean) => React.ReactNode;
  callback?: () => void;
  background?: string;
  disabled?: boolean;
};

type Props = {
  groupId: string;
  items: TabItem[];
  selectedTab: string;
  // ideally, type of id param would be: one of the TabItem ids, not simply a string;
  handleSetSelectedTab: (id: string) => void;
  useSafeArea?: boolean;
  wrapperStyles?: SideTabWrapperStyles;
};

export function SideTabs(props: Props) {
  function handleSetSelectedTab(val: TabItem, isSelected: boolean) {
    // now handling haptics via useGKeyboardTouch
    /* 
    if (!isSelected) {
      hapticsImpactLight();
    }
     */
    val.callback && val.callback();
    props.handleSetSelectedTab(val.id);
  }

  function handleTouchCallbacks(name: string) {
    var selectedTabItem = props.items.find((val) => {
      return val.id === name;
    });
    selectedTabItem &&
      handleSetSelectedTab(
        selectedTabItem,
        selectedTabItem.id === props.selectedTab
      );
  }

  const { touchRef, hoverTarget } = useGKeyboardTouch({
    groupId: props.groupId,
    // handleSelect: handleTouchCallbacks,
    handleHover: handleTouchCallbacks,
    // checkedItems: [props.selectedTab],
  });

  return (
    <Wrapper
      ref={touchRef}
      style={
        {
          "--tab-width": `${props.wrapperStyles?.tabWidth ?? 0}px`,
          "--position-left": `${props.wrapperStyles?.positionLeft ?? 0}px`,
          "--padding-left": `${props.wrapperStyles?.paddingLeft ?? 0}px`,
        } as React.CSSProperties
      }
    >
      <SpacerTabTop disabled />
      {props.items.map((val) => {
        const isSelected = val.id === props.selectedTab;

        const style = val.disabled
          ? styles.disabled
          : isSelected
          ? styles.selected
          : styles.default;

        return (
          <Tab
            key={val.id}
            onClick={() => {
              handleSetSelectedTab(val, isSelected);
            }}
            disabled={val.disabled}
            style={
              {
                ...style,
                "--background": val.background,
                // "--background":
                //   hoverTarget === val.id
                //     ? "var(--color-highlight-light)"
                //     : val.background,
              } as React.CSSProperties
            }
            // className={`${props.groupId} ${GKEYBOARD_CLASS_TAG}`}
            data-gkey-group-id={props.groupId}
            data-gkey-handoff-id={GKEY_HANDOFF_ID}
          >
            <NativeCheckBox
              name={val.id}
              type="checkbox"
              disabled={val.disabled}
              // className={`${props.groupId}`}
            />
            <TabContentWrapper>
              {val.content?.(isSelected) ?? val.label}
            </TabContentWrapper>
            {isSelected && <SelectedTabFlair />}
          </Tab>
        );
      })}
      <SpacerTabBottom
        disabled
        style={
          {
            "--padding-bottom": props.useSafeArea
              ? "env(safe-area-inset-bottom)"
              : "unset",
          } as React.CSSProperties
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: var(--position-left);
  width: var(--tab-width);
  min-width: var(--tab-width);
  padding-left: var(--padding-left)
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

// const Tab = styled(RippleButton)`
//   --color-ripple: var(--color-background-translucent-60);
const Tab = styled.button`
  position: relative;
  overflow: hidden;
  flex: 0 1 56px;
  background-color: var(--background);
  color: var(--color);
  box-shadow: var(--box-shadow);
  border: var(--border);
  border-right: none;
  border-radius: var(--border-radius, 4px 0 0 4px);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s;
  user-select: none;
  font-size: ${16 / 16}rem;
  font-variant-caps: small-caps;
  &:active {
    color: var(--color-text);
  }
`;

const TabContentWrapper = styled.div`
  /* ignore pointer events on Tab content and "flair" to ensure clicks are received by Tab (the button); useGKeyboardTouch needs clicks to register on the parent element of the checkboox input; */
  pointer-events: none;
`;

const SelectedTabFlair = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--color-text-medium);
  pointer-events: none;
`;

const SpacerTab = styled(Tab)`
  flex: 1 1 12px;
  background-color: hsla(0, 0%, 0%, 0.05);
  box-shadow: none;
`;

const SpacerTabTop = styled(SpacerTab)`
  border-top: none;
  border-top-left-radius: 0;
`;

const SpacerTabBottom = styled(SpacerTab)`
  border-bottom: none;
  border-bottom-left-radius: 0;
  padding-bottom: var(--padding-bottom);
`;
