/**
 * @generated SignedSource<<2bf69c27e3a7c2e1ce9435bcf582cfb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeedbackEventType = "DECLINED" | "PROVIDED" | "%future added value";
export type FeedbackInitiatedBy = "SERVER" | "USER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminUserDetails_users$data = {
  readonly users: ReadonlyArray<{
    readonly createdAt: string;
    readonly email: string;
    readonly feedbackHistory: ReadonlyArray<{
      readonly comment: string | null;
      readonly initiatedBy: FeedbackInitiatedBy | null;
      readonly isSatisfied: boolean | null;
      readonly time: string;
      readonly type: FeedbackEventType;
    }>;
    readonly id: string;
    readonly updatedAt: string;
  } | null>;
  readonly " $fragmentType": "AdminUserDetails_users";
};
export type AdminUserDetails_users$key = {
  readonly " $data"?: AdminUserDetails_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminUserDetails_users">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminUserDetails_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbackEvent",
          "kind": "LinkedField",
          "name": "feedbackHistory",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "initiatedBy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSatisfied",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "comment",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AllDataQueryResult",
  "abstractKey": null
};

(node as any).hash = "baf6b01c962f57a9a5e75a1e26f0122a";

export default node;
