import React from "react";

import { useGKeyboardTouchHelpers } from "./useGKeyboardTouch.helpers";
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from "../../_plugins/Haptics";

const MIN_TIME_BETWEEN_HAPTICS = 200;

type Props = {
  groupId: string;
  handleSelect?: (val: string) => void;
  handleHover?: (val: string) => void;
  // checkedItems: readonly string[];
};

export const useGKeyboardTouch = (props: Props) => {
  // *** consider using ref instead of state here; and consider whether this should be passed to the init of useGKeyboardTouchHelpers, or if the val should be passed to touchend handler and setter should be passed to touchmove handler;
  const [handoffRef, setHandoffRef] = React.useState<Element | null>(null);
  const touchRef = React.useRef<HTMLDivElement>(null);

  const {
    handleMouseMove,
    handleTouchMove,
    handleMouseUp,
    handleTouchEnd,
    handleTouchCancel,
  } = useGKeyboardTouchHelpers(
    props.groupId,
    () => removeListeners(touchRef),
    setHandoffRef,
    touchRef,
    handoffRef
  );

  const [hoverTarget, setHoverTarget] = React.useState<string>("");

  const lastHoverHapticTime = React.useRef<number>(0);

  function setHovered(name: string) {
    if (name === hoverTarget) return;
    // console.log("SET HOVERED", props.groupId, name);
    if (name !== "") {
      hapticsImpactLight();
      lastHoverHapticTime.current = Date.now();
      props.handleHover?.(name);
    }
    setHoverTarget(name);
  }

  function setSelected(name: string) {
    if (props.handleSelect) {
      if (
        Date.now() - MIN_TIME_BETWEEN_HAPTICS >=
        lastHoverHapticTime.current
      ) {
        // if user simply "taps" a key, don't want both "hover" and "selected" haptics firing; suppress the "selected" haptic if too soon after most recent "hover"
        hapticsImpactMedium();
      }
      props.handleSelect?.(name);
    }
  }

  /*   function mouseMoveEvent(e: React.MouseEvent | MouseEvent) {
    e.preventDefault();
    handleMouseMove(e, setHovered);
  } */

  function touchEvent(e: React.TouchEvent | TouchEvent) {
    // console.log(e.type, props.groupId);
    // prevent mouse events;
    e.preventDefault();
    handleTouchMove(e, setHovered);
  }

  function mouseUpEvent(e: React.MouseEvent | MouseEvent) {
    e.preventDefault();
    handleMouseUp(e, setSelected);
  }

  function touchEndEvent(e: React.TouchEvent | TouchEvent) {
    // prevent mouse events;
    e.preventDefault();
    handleTouchEnd(e, setSelected, setHovered);
  }

  function touchCancelEvent(e: React.TouchEvent | TouchEvent) {
    e.preventDefault();
    handleTouchCancel(e, setSelected, setHovered);
  }

  function applyListeners(ref: React.RefObject<HTMLDivElement>) {
    let target = ref?.current;
    if (!target) return;
    // specify "passive" option prop to suppress Chrome warnings; because these listeners utilize preventDefault(), set "passive" to false to let the browser know you will prevent default behavior, including scroll behavior, so the browse cannot assume unimpeded scrolling;
    target.addEventListener("mouseup", mouseUpEvent, { passive: false });
    // for now, don't want same "popup" effect for mousemove
    // target.addEventListener("mousemove", mouseMoveEvent);
    target.addEventListener("touchstart", touchEvent, { passive: false });
    target.addEventListener("touchmove", touchEvent, { passive: false });
    target.addEventListener("touchend", touchEndEvent, { passive: false });
    target.addEventListener("touchcancel", touchCancelEvent, {
      passive: false,
    });
  }

  function removeListeners(ref: React.RefObject<HTMLDivElement>) {
    let target = ref?.current;
    if (!target) return;
    // don't need to include "passive" option prop in removeEventListener; if used "capture" or "useCapture", would need to match the options used in addEventListener for remove to succeed: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal;
    target.removeEventListener("mouseup", mouseUpEvent);
    // target.removeEventListener("mousemove", mouseMoveEvent);
    target.removeEventListener("touchstart", touchEvent);
    target.removeEventListener("touchmove", touchEvent);
    target.removeEventListener("touchend", touchEndEvent);
    target.removeEventListener("touchcancel", touchCancelEvent);
  }

  React.useEffect(() => {
    applyListeners(touchRef);
    return function cleanup() {
      // console.log("Cleaning up:", props.groupId)
      removeListeners(touchRef);
    };
    // *** need props.handleSelect in dependency array b/c: the callback handleEditChord in CreateUpdateChord holds the non-changed chord values; useGKeyboardTouch was retaining a stale version of this fnc, and thus was inheriting the values from the last chord the user had clicked on, not the present chord; thus if chord 1 is Am, and chord 2 is G7, an attempt to change the root of G7 to D would result in a Dm, inheriting all values but the just-changed quality from the Am instead of the G7
    // *** believe adding props.handleSelect to dependency array obviates need to include props.checkedItems
    // *** perhaps better explanation: Due to listeners holding callback fnc used to update chord values holding stale version of that fnc (with values from the previously selected chord), so updating the next chord would combine the updated property (e.g. quality) with the properties from the previous chord instead of the currently-selected chord; included that fnc in dependency array of useGKeyboardTouch to ensure listeners were reapplied when fnc changed.
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    touchRef,
    hoverTarget,
    props.handleSelect,
    props.handleHover,
    //  props.checkedItems,
  ]);

  return { touchRef, hoverTarget };
};

// *** reminder: this fnc is bound to an event listener, which creates an enclosure that means "hoverTarget" in this fnc will always equal value it had when bound; need to rebind the event listeners with the new state value when changed, which means adding "hoverTarget" to the dependency array of useEffect where you bind the listeners

// was appealing to send the callback (setSelected) directly inline with the event handler callback, but ends up causing trouble b/c(???) it ends up using the wrong reference...?  It manifested itself by causing my clicks on a root to actually select a quality on the next tab (which is auto-switched-to upon selection), and the same occurred there, an apparent click on a quality actually selected a note on the next tab (bass notes); binding the callback as I do above, within the render of the component, has no such problem

// attempted to use React synthetic events (e.g. onTouchStart), but could not use preventDefault(): in Chrome, received error "Unable to preventDefault inside passive event listener invocation"; known issue, no obvious solution, so reverted to attaching vanilla event listeners via ref
// had some head scratching when first implemented with vanilla listeners: listeners used old prop value, namely the prop value on first render; explanation appears to be something like: the listener fnc grabs the prop value via enclosure when it is applied, but on re-render the component scope the value came from effectively disappears... so it's still using the value from original render; need to add new listeners with updated scope when dependent variables change, using useEffect (and need to remove the prior set of listeners)
// 2023-04-09: encountered this again; added new callback, "handleHover", but hadn't added it to dependency array of useEffect, so callback for handleHover was using stale values;
