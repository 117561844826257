import React from "react";
import styled from "styled-components/macro";

import appIcon from "../_svg/appIcon.png";

export function AppIcon() {
  return <Wrapper src={appIcon} />;
}

const Wrapper = styled.img`
  // *** was struggling here: was using a larger png (789 x 601) and downsizing by constraining height; this looked good provided I don't go any shorter than ~152, but wanted to be able to go lower; tried with a smaller png (height 140), but DPI was lower and aliasing was obvious; tried with an SVG but aliasing was bad; finally, tried PNG a t double desired height (280px) and seems to tick all the boxes
  height: 100px;
  margin: auto;
`;
