import React, { useState } from "react";
import styled from "styled-components/macro";

import { Modal } from "./Modal";

type Props = {
  message: string | React.ReactNode;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  label?: string;
};

export function ModalSimpleMessage(props: Props) {
  const [doShowDialog, setDoShowDialog] = useState<boolean>(false);

  // show modal if non-empty message
  React.useEffect(() => {
    props.message !== "" && open();
  }, [props.message]);

  // on modal close, clear message
  React.useEffect(() => {
    if (
      doShowDialog === false &&
      // only fire if message is populated to - effectively - ensure this only fires after modal has been opened; otherwise will open on first render (b/c doShowDialog is initially false), and - due to setTimeout in clearMessage, if an error is caught soon after render (currently, within 500ms) it will be cleared almost as soon as it is shown
      props.message !== ""
    ) {
      clearMessage();
    }
  }, [doShowDialog]);

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  function clearMessage() {
    // delay clearing message to ensure message does not clear before modal has faded out
    setTimeout(() => props.setMessage(""), 500);
  }

  function handleConfirm() {
    close();
  }

  return (
    <Modal
      doShowDialog={doShowDialog}
      closeDialog={close}
      // handleConfirm={handleConfirm}
      label={props.label}
      actionText={"OK"}
      doUseLeastDestructiveRef={true}
    >
      <ModalBody>
        <Message>{props.message}</Message>
      </ModalBody>
    </Modal>
  );
}

const ModalBody = styled.div`
  padding: 32px 16px;
  /* trial this: at a minimum, useful for printing errors with massive strings, e.g. those returned by GraphQL if ResetPasswordMutation errors (due to GraphQL returning the inputs, which will include the massive token the client sends) */
  overflow: auto;
`;

const Message = styled.div`
  font-size: ${18 / 16}rem;
  font-weight: 400;
  /* preserve line breaks \n and tabs \t in user-entered text */
  white-space: pre-wrap;
  user-select: text;
`;
