import React from "react";
import { Route, Redirect /* RouteComponentProps */ } from "react-router-dom";

import { routeTable } from ".";
import { USER_ROLE_OBJ } from "../_constants/userRole";
import { UserRolesType } from "../_types";
import { AppContext } from "../_context/appContext";

function getPathWithoutParams(pathname: string): string {
  if (!pathname) return "";
  return "/" + pathname.split("/")[1];
}

function getParams(pathname: string): string | undefined {
  if (!pathname) return undefined;
  const params = pathname.split("/")[2];
  if (params && params.trim() === "") {
    return undefined;
  }
  return params;
}

function returnOriginalRoute({
  location,
  userRoles,
  defaultRoute,
}: {
  location: any;
  userRoles: UserRolesType;
  defaultRoute: string;
}) {
  // if anon user originally requested a page that requies auth, route to that page after auth occurs (either user log in or app loading auth data from async storage and causing re-render); confirm the originally-requested route exists, and the role is permitted to access it
  var pathname: string = location.state?.from?.pathname,
    originalRoute = routeTable[getPathWithoutParams(pathname)],
    // ignore routes that allow logout, as user unlikely to want to go back to them on login
    ignoreRoutes = ["/logout", "/settings", "/account"];
  if (
    originalRoute &&
    !ignoreRoutes.includes(pathname) &&
    originalRoute.allowedRoles.some((role) => userRoles.includes(role))
  ) {
    console.log(
      `%cReturning original route": ${pathname}`,
      "color: green; font-size: 12px; font-weight: bold;"
    );
    return (
      <Redirect
        to={{
          pathname: pathname,
          state: { from: location },
        }}
      />
    );
  } else {
    return (
      <Redirect to={{ pathname: defaultRoute, state: { from: location } }} />
    );
  }
}

interface Props {
  children: any;
  path: string;
  params?: string;
  exact: boolean;
  allowedRoles: USER_ROLE_OBJ[];
}

export const RouteRoleWrapper: React.FC<Props> = ({
  children,
  allowedRoles,
  path,
  params,
  ...rest
}) => {
  const { state, dispatch } = React.useContext(AppContext);
  const userRoles: UserRolesType = state?.auth?.user?.roles ?? [
    USER_ROLE_OBJ.ANON,
  ];
  const userEmail: string | undefined = state?.auth?.user?.email;

  return (
    <Route
      path={path}
      {...rest}
      // render={({ location }: RouteComponentProps) => {
      // known difficulty typing location.state; this is a workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/41674
      render={({ location }: { location: any }) => {
        const requestedPath = location.pathname,
          requestedPathWithoutParams = getPathWithoutParams(requestedPath),
          requestedParams = getParams(requestedPath),
          fromPath = location?.state?.from?.pathname;
        if (
          !allowedRoles ||
          !allowedRoles.some((role) => userRoles.includes(role)) ||
          // if route requires parameters, and none are provided, request is invalid
          (params && !requestedParams)
        ) {
          // unauthorized; return role-based default component
          console.log(
            `%cUnauthorized route - \n requested route: ${requestedPath}; \n route's roles: ${allowedRoles}; \n user's roles: ${userRoles}; \n router "from" prop:${fromPath}
            `,
            "color: green; font-size: 12px; font-weight: bold;"
          );
          if (
            userRoles.includes(USER_ROLE_OBJ.ADMIN) ||
            userRoles.includes(USER_ROLE_OBJ.USER)
          ) {
            return returnOriginalRoute({
              location,
              userRoles,
              defaultRoute: "/user-songs",
            });
          } else {
            // anon user
            // if email known, then it's an existing user; otherwise it's new user who needs intro
            // show intro only to an anon user accessing root ('/'), which will happen on a cold app load; if user is logged in, or is routing directly to a page (besides '/' or '/intro'), they will not be directed to the intro; when a user logs out, you push to '/login', so intro isn't shown
            return userEmail ? (
              <Redirect
                to={{ pathname: "/login-create", state: { from: location } }}
              />
            ) : (
              <Redirect
                to={{ pathname: "/intro", state: { from: location } }}
              />
            );
          }
        }
        // authorized; return requested component
        return children;
      }}
    />
  );
};
