import React from "react";
import styled from "styled-components/macro";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type Props = {
  id: string;
  doAllowReorder: boolean;
  isDragging: boolean;
  children?: React.ReactNode;
};

export function SortableItem({
  id,
  doAllowReorder,
  isDragging,
  children,
}: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled: !doAllowReorder,
      transition: { duration: 150, easing: "cubic-bezier(0.25, 1, 0.5, 1)" },
    });

  // when dragging, there will two "versions" of the item you're dragging: the "DragOverlay" item, which "hovers", adhered to your point of touch; and the "normal, glued-to-the-list" item: these styles will affect the latter, "glued", item, whereas styles given to the SectionListItem wrapped in DragOverlay will affect the "hovering" item
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging && {
      "--opacity": 0.5,
    }),
  };

  return (
    <Wrapper
      ref={setNodeRef}
      style={style as React.CSSProperties}
      {...attributes}
      {...listeners}
      tabIndex={doAllowReorder ? 0 : undefined}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  &:focus-visible {
    /* temporary fix... using this component to hold both button and draggable... arguably want different focus behavior for each... for now removing outline to prevent default outline from appearing in iOS Safari */
    outline: none;
    box-shadow: var(--shadow-elevation-low);
  }
`;
