import React from "react";

import { Page, PageHeader, PageContent } from "../_components/Page";
import { LeitnerSettingsQuery } from "./LeitnerSettingsQuery";

export const LeitnerSettings: React.FC = () => {
  return (
    <Page>
      <PageHeader title={"Memorize Songs"} backPath={`/settings`} />
      <PageContent>
        {(queryOptions) => {
          return <LeitnerSettingsQuery queryOptions={queryOptions} />;
        }}
      </PageContent>
    </Page>
  );
};
