import styled from "styled-components/macro";
import { replaceHyperlinks } from "../_utilities/strings";

type Props = {
  notes: string;
};

export function Notes(props: Props) {
  return <Wrapper>{replaceHyperlinks(props.notes)}</Wrapper>;
}

const Wrapper = styled.span`
  font-size: ${16 / 16}rem;
  font-weight: 400;
  color: var(--color-text);
  /* preserve line breaks "\n" and tabs "\t" in user-entered text */
  white-space: pre-wrap;
`;
