import React from "react";
import styled from "styled-components/macro";

import { ellipsizeText } from "../_utilities/strings";

// *** use layout computation in parent component to determine maxChars

type Props = {
  tags: readonly string[];
  maxChars?: number;
  tagFilter?: string[];
};

const MIN_TAG_LENGTH = 5;
// slicing/ellipsizing a tag adds three narrow characters ("..."), so no point slicing under 3 chars
const MIN_SLICE_AMOUNT = 3;
const EXCESSIVE_TAGS_INDICATOR = {
  val: "[...]",
  label: "[...]",
  highlight: false,
};
const CHAR_SPACE_CONSUMED_BY_WRAPPER = 2;

export function TagsSmall(props: Props) {
  // clone tags
  let shownTags = props.tags.map((tag) => ({
    val: tag,
    label: tag,
    highlight: props.tagFilter && props.tagFilter.indexOf(tag) > -1,
  }));

  if (props.maxChars) {
    // check total char count; if excessive, count how many chars need to be sliced
    let totalCharCount = 0;
    let charSpaceConsumedByTags =
      props.tags.length * CHAR_SPACE_CONSUMED_BY_WRAPPER;
    props.tags.forEach((tag) => (totalCharCount += tag.length));
    // tag containers occupy space beyond characters; the more tags, the less character space avail
    let excessiveChars =
      totalCharCount + charSpaceConsumedByTags - props.maxChars;
    let sliceableTagMinLength = MIN_TAG_LENGTH + MIN_SLICE_AMOUNT;
    let charsSliced = 0;
    if (excessiveChars > 0) {
      // sort longest first, to focus slicing on longer tags
      shownTags.sort((a, b) => b.val.length - a.val.length);

      // first pass slices all sliceable tags the min slice amount
      for (let tag of shownTags) {
        if (tag.label.length > sliceableTagMinLength) {
          tag.label = tag.label.slice(0, -MIN_SLICE_AMOUNT).trim();
          charsSliced += MIN_SLICE_AMOUNT;
        }
      }

      // subsequent passes slices one char at a time from all tags long enough to be sliced
      while (charsSliced < excessiveChars) {
        let charsSlicedThisPass = 0;
        for (let tag of shownTags) {
          if (
            // continue to slice only those sliced by the first pass (the only ones eligible for slicing)
            tag.label.length < tag.val.length &&
            // don't slice beyond min length
            tag.label.length > MIN_TAG_LENGTH
          ) {
            tag.label = tag.label.slice(0, -1).trim();
            charsSlicedThisPass += 1;
            charsSliced += 1;
            if (charsSliced >= excessiveChars) {
              // break early if condition met mid-for-loop
              break;
            }
          }
        }
        if (charsSlicedThisPass === 0) {
          // console.log("no further slicing poss");
          break;
        }
      }
    }

    // sort tags alpha
    shownTags.sort((a, b) =>
      a.val.toLowerCase() < b.val.toLowerCase() ? -1 : 1
    );

    if (props.tagFilter) {
      // for some reason, TS still saw props.tagFilter as potentially undefined inside the sort() callback, until I assigned to a new variable here;
      let tagFilterCopy = props.tagFilter.slice();
      shownTags.sort((a, b) => {
        let aIsInFilter = tagFilterCopy.indexOf(a.val) > -1;
        let bIsInFilter = tagFilterCopy.indexOf(b.val) > -1;
        if (bIsInFilter && !aIsInFilter) {
          return 1;
        } else if (!bIsInFilter && aIsInFilter) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    // if cannot slice individual tags enough to satisfy maxChars, remove tags altogether, starting from end of array, until within maxChars
    if (charsSliced < excessiveChars) {
      for (let i = shownTags.length - 1; i >= 0; i--) {
        let tagLength = shownTags[i].label.length;
        shownTags.splice(i, 1);
        // whene you slice a tag out, you also free up the space taken by its wrapper
        charsSliced += tagLength + CHAR_SPACE_CONSUMED_BY_WRAPPER;
        if (
          charsSliced >=
          excessiveChars + EXCESSIVE_TAGS_INDICATOR.label.length
        ) {
          break;
        }
      }
      shownTags.push(EXCESSIVE_TAGS_INDICATOR);
    }
    /*    console.log({
      maxChars: props.maxChars,
      totalCharCount,
      excessiveChars,
      charsSliced,
    }); */
  } else {
    // if no maxChars provided, simply sort tags by alpha;
    shownTags.sort((a, b) =>
      a.val.toLowerCase() < b.val.toLowerCase() ? -1 : 1
    );
  }

  return (
    <Tags>
      {shownTags.map((tag) => {
        return (
          <Tag
            key={tag.val}
            style={
              {
                "--color-tags": tag.highlight
                  ? "var(--color-text)"
                  : "var(--color-text-medium)",
                "--color-tags-border": tag.highlight
                  ? "var(--color-text-medium)"
                  : "var(--color-text-light)",
              } as React.CSSProperties
            }
          >
            {tag.label}
            {tag.val > tag.label && <Ellipsis>...</Ellipsis>}
          </Tag>
        );
      })}
    </Tags>
  );
}

const Tags = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-y: auto;
  padding-top: 2px;
  padding-bottom: 8px;
`;

const Tag = styled.div`
  font-size: ${12 / 16}rem;
  text-align: right;
  padding: 0px 3px;
  color: var(--color-tags);
  border: 1px dotted var(--color-tags-border);
  border-radius: 4px;
  margin-right: 4px;
  white-space: nowrap;
`;

const Ellipsis = styled.span`
  font-size: ${8 / 16}rem;
`;
