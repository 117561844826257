/**
 * @generated SignedSource<<b149491df591095e8a4553e2904ca063>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSongMeta_song$data = {
  readonly artist: string;
  readonly editLock: {
    readonly isLocked: boolean;
    readonly lockedBy: string;
    readonly lockedByEmail: string;
    readonly lockedUntil: string;
  } | null;
  readonly id: string;
  readonly isLockedByUser: boolean;
  readonly mongoVersion: number;
  readonly title: string;
  readonly " $fragmentType": "UpdateSongMeta_song";
};
export type UpdateSongMeta_song$key = {
  readonly " $data"?: UpdateSongMeta_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSongMeta_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSongMeta_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artist",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLockedByUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "editLock",
      "kind": "LinkedField",
      "name": "editLock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedByEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedUntil",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "a59e587fbdceb018bdf5275bc0e17632";

export default node;
