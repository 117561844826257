export enum USER_ROLE_OBJ {
  ADMIN = "ADMIN",
  USER = "USER",
  ANON = "ANON",
}

// export const USER_ROLE_OBJ = {
//   ADMIN: "ADMIN",
//   USER: "USER",
//   ANON: "ANON",
// };

export const USER_ROLE_DEFAULT = USER_ROLE_OBJ.ANON;

