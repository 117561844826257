/**
 * @generated SignedSource<<73bf8d284a45b3e1dd5e36f82ea3e9f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type acceptSongShareInput = {
  clientMutationId?: string | null;
  songId: string;
};
export type SongAcceptShareMutation$variables = {
  input: acceptSongShareInput;
};
export type SongAcceptShareMutation$data = {
  readonly acceptSongShare: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly song: {
      readonly id: string;
      readonly permissions: ReadonlyArray<{
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      }>;
      readonly viewerPermission: {
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      };
    } | null;
  } | null;
};
export type SongAcceptShareMutation = {
  response: SongAcceptShareMutation$data;
  variables: SongAcceptShareMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "acceptSongSharePayload",
    "kind": "LinkedField",
    "name": "acceptSongShare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Song",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongPermission",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongPermission",
            "kind": "LinkedField",
            "name": "viewerPermission",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongAcceptShareMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongAcceptShareMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2d1a0f5c99b6a881d81523833435fc98",
    "id": null,
    "metadata": {},
    "name": "SongAcceptShareMutation",
    "operationKind": "mutation",
    "text": "mutation SongAcceptShareMutation(\n  $input: acceptSongShareInput!\n) {\n  acceptSongShare(input: $input) {\n    song {\n      id\n      permissions {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n      viewerPermission {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8cab9c954897fd3ec441465f90430fbd";

export default node;
