import React from "react";

import { AppContext } from "../_context/appContext";
import { isNativeCap } from "../_utilities/detectNative";
import { devicePlatform } from "../_plugins/Device";
import { useDebugLog } from "../_hooks/useDebugLog";

// https://stackoverflow.com/questions/45978230/get-version-number-from-package-json-in-react-redux-create-react-app/50822003#50822003
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const NODE_ENV = process.env.NODE_ENV;

const API_URL = {
  development: process.env.REACT_APP_API_URL_DEV,
  production: process.env.REACT_APP_API_URL_PROD,
  test: process.env.REACT_APP_API_URL_DEV,
}[NODE_ENV];

export function LogAppDiagnostics() {
  const { state, dispatch } = React.useContext(AppContext);
  const { user, jwt } = state.auth;
  const { setDebugLog } = useDebugLog("LogAppDiagnostics");

  React.useEffect(() => {
    setDebugLog([
      `Client v${REACT_APP_VERSION} (${devicePlatform}, ${
        isNativeCap ? "native" : "non-native"
      }, ${NODE_ENV})`,
      `API: ${API_URL}`,
      user,
      jwt,
    ]);
  }, [REACT_APP_VERSION, devicePlatform, user, jwt]);

  return null;
}
