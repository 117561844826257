import React, { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import * as Yup from "yup";

import type {
  loginUserInput,
  LoginUserMutation,
} from "../__generated__/LoginUserMutation.graphql";
import type { CreateLoginResponse } from "../_types";
import { isApiError } from "../_types/errorTypes";

import { HiddenSubmitButton, LoadingSpinnerComplete } from "../_components";
import { AppContext } from "../_context/appContext";
import { UniqueUserContext } from "../_context/uniqueUserContext";
import { AuthActionTypes } from "../_reducers";
import { ModalError } from "../_components/ModalError";
import Button from "../_components/Button";
import { TextInputIcon } from "../_components/TextInputIcon";
import {
  FORM_PLACEHOLDERS,
  getFieldErrors,
  YUP_FIELDS,
} from "../_utilities/forms";
import Spacer from "../_components/Spacer";
import {
  FieldError,
  InputFields,
  StyledForm,
} from "../_components/FormComponents";
import { useDebugLog } from "../_hooks/useDebugLog";

const USER_CREDENTIALS_SCHEMA = Yup.object().shape({
  email: YUP_FIELDS.email,
  password: YUP_FIELDS.password,
});

export function Login() {
  const history = useHistory();
  const { state, dispatch } = React.useContext(AppContext);
  const recentEmail = state.auth.user?.email;
  const { uniqueUser, updateUniqueUser } = React.useContext(UniqueUserContext);
  const { setDebugLog } = useDebugLog("Login");

  type LoginInput = {
    email: string;
    password: string;
  };
  const LOGIN_INPUT_INITIAL = {
    email: recentEmail ?? "",
    password: "",
  };
  const [inputVals, setInputVals] =
    React.useState<LoginInput>(LOGIN_INPUT_INITIAL);

  const [formInputIsInvalid, setFormInputIsInvalid] = useState<boolean>(false);

  const [errMsg, setErrMsg] = useState<string>("");

  const [commit, isInFlight] = useMutation<LoginUserMutation>(graphql`
    mutation LoginUserMutation($input: loginUserInput!) {
      loginUser(input: $input) {
        jwt {
          token
          expiresIn
          expiresAt
        }
        user {
          id
          email
          roles
          boxIntervals
          products {
            productGroup
            productStore
            productType
            productId
            isVerified
            expiresAt
          }
        }
        errors {
          __typename
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function saveUserData(userData: Partial<CreateLoginResponse>) {
    if (userData && userData.jwt && userData.user) {
      dispatch({
        type: AuthActionTypes.Set,
        payload: {
          user: userData?.user,
          jwt: userData?.jwt,
        },
      });
      updateUniqueUser(userData?.user.id);
    }
  }

  function handleLogin(input: loginUserInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.loginUser) {
          const { jwt, user, errors } = response.loginUser;
          if (jwt && user) {
            setDebugLog([
              `Dom7 account products:`,
              user.products.length > 0 ? user.products : "(None)",
            ]);
            saveUserData({ user, jwt });
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
          setDebugLog([`Login error:`, JSON.stringify(err)]);
        }
      },
    });
  }

  function handleSubmit(e: FormEvent) {
    // make sure e.preventDefault() comes first for form submission
    e.preventDefault();
    if (isInFlight) return;

    if (!USER_CREDENTIALS_SCHEMA.isValidSync(inputVals)) {
      setFormInputIsInvalid(true);
      return;
    }
    handleLogin(inputVals);
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <InputFields>
          <TextInputIcon
            name="email"
            type="email"
            label="Email"
            value={inputVals.email}
            onChange={(val) =>
              setInputVals({
                ...inputVals,
                email: val,
              })
            }
            size="large"
            mode="border"
            placeholder={FORM_PLACEHOLDERS.email}
          />
          <FieldError>
            {getFieldErrors(
              USER_CREDENTIALS_SCHEMA,
              inputVals,
              "email",
              formInputIsInvalid
            )}
          </FieldError>
          <TextInputIcon
            name="password"
            type="password"
            label="Password"
            value={inputVals.password}
            onChange={(val) =>
              setInputVals({
                ...inputVals,
                password: val,
              })
            }
            size="large"
            mode="border"
          />
          <FieldError>
            {getFieldErrors(
              USER_CREDENTIALS_SCHEMA,
              inputVals,
              "password",
              formInputIsInvalid
            )}
          </FieldError>
        </InputFields>
        <Button
          type="submit"
          // allow attempted submission when inputs are invalid, to trigger display of input errors
          disabled={isInFlight}
          palette={isInFlight ? "mono" : "primary"}
        >
          Sign In
        </Button>
        {isInFlight && <LoadingSpinnerComplete overlay />}
      </StyledForm>

      <Spacer size={16} axis="vert" />
      <Button
        size="sm"
        fill="ghost"
        onClick={() => history.push("/forgot-password")}
      >
        Forgot Password
      </Button>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
