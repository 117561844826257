import React from "react";
import styled from "styled-components/macro";

import { /* FooterHeightContext, */ PageContext } from "./Page";
// import { ContentScrollStateContext } from "./Page";
import { FooterCore } from "../FooterCore";

type Props = {
  children?: React.ReactNode;
};

export function PageFooter({ children }: Props) {
  // const footerHeight = React.useContext(FooterHeightContext);
  // const [contentScrollState, setContentScrollState] = React.useContext(
  //   ContentScrollStateContext
  // );

  const [pageState, setPageState] = React.useContext(PageContext);

  const style = {
    "--footer-height": `${pageState.footerHeight / 16}rem`,
  };

  return (
    <Wrapper
      doCollapse={pageState.contentScrollState === "middle"}
      style={style as React.CSSProperties}
    >
      <SafeWrapper>{children}</SafeWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(FooterCore)<{
  doCollapse?: boolean;
}>`
  transition: transform 0.4s, opacity 0s;
  transform: none;

  ${({ doCollapse }) =>
    doCollapse &&
    `
  transition: transform 0.4s, opacity 0.3s;
  transform: translate3d(0, calc(100% - 2px), 0);
`}
`;

const SafeWrapper = styled.div`
  height: var(--footer-height);
  min-height: var(--footer-height);
`;
