/**
 * note: for time being, must use Swiper 6 with CreateReactApp:
 * https://gist.github.com/sindresorhus/a39789f98801d908bbc7ff3ecc99d99c
 * https://github.com/facebook/create-react-app/issues/10892
 *  */

import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// *** different between v6/v7
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/scrollbar/scrollbar.min.css";

import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { RippleButton } from "../_components/RippleButton";
import { FooterCore } from "../_components/FooterCore";
import appIcon from "../_svg/appIcon.png";
import RepeatStart from "../_svg/repeatStart.svg";
import RepeatEnd from "../_svg/repeatEnd.svg";

const makePossessive = (noun: string) => {
  if (noun.endsWith("s")) {
    return noun + "'";
  } else {
    return noun + "'s";
  }
};

const APP_NAME = process.env.REACT_APP_NAME ?? "Dom7";

const AppName = ({ possessive }: { possessive?: boolean }) => (
  <AppNameWrapper>
    {possessive ? makePossessive(APP_NAME) : APP_NAME}
  </AppNameWrapper>
);

const AppNameWrapper = styled.span`
  font-style: italic;
  font-weight: 500;
`;

// *** different between v6/v7
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export function Intro() {
  const history = useHistory();
  return (
    <Wrapper>
      <SwiperReactStyled
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          prevEl: ".prev-slide",
          nextEl: ".next-slide",
        }}
        // using cssMode to attempt to cure flickering that occurs when dragging between slides in iOS Safari: https://github.com/nolimits4web/swiper/issues/3527
        // no longer seeing this issue in production version running iOS15
        cssMode={true}
        // use during dev to preview specific slide
        // initialSlide={5}
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide>
          <SlideBody>
            <List>
              <ListHeader>Your songs. Your chords.</ListHeader>
              <ListItemSubCompact>
                Your compositions, transcriptions, and favorites in one place.
              </ListItemSubCompact>
              <ListItemSubCompact>
                For students and musicians of all levels -- no need to "read
                music".
              </ListItemSubCompact>
              <ListItemSubCompact>
                Intuitive chord keyboard. Clean, compact sheets.
              </ListItemSubCompact>

              <ListHeader>Access and play anywhere, with anyone</ListHeader>
              <ListItemSubCompact>
                Any device: iOS, Android, or web -- we never spam or share your
                email.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Share PDFs -- straight to email for easy viewing/printing.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Group sync -- share songs in-app with your group to play in
                sync, collaborate and edit together.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Transpose -- play in any key, on the fly.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Dark mode -- easy on the eyes in any venue.
              </ListItemSubCompact>
            </List>
          </SlideBody>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBody>
            <List>
              <ListHeader>Intuitive chord keyboard</ListHeader>
              <ListItemSubCompact>
                Add virtually any chord with a few taps.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Set bass notes for slash chords and inversions, e.g. <b>G/B</b>.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Use{" "}
                <RepeatWrapper>
                  <img src={RepeatStart} />
                </RepeatWrapper>{" "}
                repeat symbols{" "}
                <RepeatWrapper>
                  <img src={RepeatEnd} />
                </RepeatWrapper>{" "}
                to make songs faster to input, more compact to display, and
                easier to sight read.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Smart suggestions -- recommendations for section names, chord
                roots, and bass notes.
              </ListItemSubCompact>

              <ListHeader>Keep the beat</ListHeader>
              <ListItemSubCompact>
                Simple numeric beat notation.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Add rhythm naturally -- tap chords in rhythm to assign durations
                and counts (e.g. "1 and 2 and 3 and 4 and").
              </ListItemSubCompact>
            </List>
          </SlideBody>
        </SwiperSlide>
        <SwiperSlide>
          <SlideBody>
            <List>
              <ListHeader>Play on sight</ListHeader>
              <ListItemSubCompact>
                Clean, compact sheets -- fit more on your screen than traditional charts.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Large, distraction-free display -- no ads.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Use tags to organize your songs or create a set list.
              </ListItemSubCompact>

              <ListHeader>Memorize songs</ListHeader>
              <ListItemSubCompact>
                What's better than having songs at your fingertips? Having them
                in your head.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Review mode helps you to memorize songs.
              </ListItemSubCompact>
              <ListItemSubCompact>
                Focus your time where it's needed with "spaced repetition" --
                the better you know a song, the less often you review it.
              </ListItemSubCompact>
              <ListItemSubCompact>
                No need to review everything at once: target your review with
                tags or search terms.
              </ListItemSubCompact>
              <Button size="lg" onClick={() => history.push("/login-create")}>
                Get started
              </Button>
            </List>
          </SlideBody>
        </SwiperSlide>

        {/*  <SwiperSlide>
          <SlideBody>
            <List>
              <ListHeader>Pro Features</ListHeader>
              {DOM7_PRODUCT_GROUP_DETAILS.pro.description}
              <Button size="lg" onClick={() => history.push("/login-create")}>
                Get started
              </Button>
            </List>
          </SlideBody>
        </SwiperSlide> */}

        <FooterBlur />
        <PrevSlide className="prev-slide">
          <Icon id="chevron-left" size={36} strokeWidth={3} />
        </PrevSlide>
        <NextSlide className="next-slide">
          <Icon id="chevron-right" size={36} strokeWidth={3} />
        </NextSlide>
        <NavButtonStart
          className="next-slide"
          onClick={() => history.push("/login-create")}
        >
          <Icon id="log-in" size={36} strokeWidth={2} />
        </NavButtonStart>
      </SwiperReactStyled>
    </Wrapper>
  );
}

const CONTROLS_HEIGHT = 44;

const Wrapper = styled.div`
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);

  position: relative;

  /* if want to use icon or other image as background */
  /*  &::after {
    content: "";

    background-image: url(${appIcon});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    filter: blur(2px);

    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  } */
`;

const SwiperReactStyled = styled(Swiper)`
  height: 100%;
`;

// styling between SlideBody and List may seem odd; goal was to keep slide content centered vertically on page, while ensuring clean scrolling
const SlideBody = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  width: 100%;
  padding: 0 32px;
  padding-top: 20px;
  /* make space for pagination bullets, nav buttons */
  padding-bottom: ${CONTROLS_HEIGHT + 48}px;
  /* ensure text is scrollable for small screens e.g. iPhone SE */
  overflow-y: auto;
`;

const ListHeader = styled.div`
  margin-bottom: ${16 / 16}rem;
  font-size: ${20 / 16}rem;
  font-weight: 500;
`;

const ListItem = styled.div`
  margin-bottom: ${24 / 16}rem;
  font-size: ${18 / 16}rem;
  line-height: 1.3;
`;

const ListItemFlex = styled(ListItem)`
  display: flex;
  flex-flow: row nowrap;
`;

const ListItemSub = styled(ListItem)`
  margin-top: ${-12 / 16}rem;
  padding-left: ${12 / 16}rem;
  border-left: 12px solid var(--color-text-translucent);
  font-size: ${17 / 16}rem;
`;

const ListItemSubCompact = styled(ListItemSub)`
  margin: 0px;
  margin-bottom: ${12 / 16}rem;
  font-size: ${16 / 16}rem;
`;

const RepeatWrapper = styled.div`
  width: 8px;
  height: 16px;
  display: inline-block;
`;
const RepeatWrapperStart = styled.div`
  /* *** if grow unhappy with this method of aligning SVG to paragraph, see if there's any help here or similar articles: https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4 */
  height: 22px;
  flex: 0 0 8px;
  align-self: flex-start;
`;

const RepeatWrapperEnd = styled(RepeatWrapperStart)`
  align-self: flex-end;
`;

const FooterBlur = styled(FooterCore)`
  min-height: ${CONTROLS_HEIGHT}px;
  /* box-shadow: none; */
`;

const NavButton = styled(RippleButton)`
  --color-ripple: var(--color-primary-light);

  position: absolute;
  bottom: 0;
  top: auto;
  margin: auto;
  width: 40px;
  height: ${CONTROLS_HEIGHT}px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);

  background: none;
  border-radius: 4px;

  &.swiper-button-disabled {
    /* color: transparent; */
    /* opacity: 0.35; */
    /* cursor: auto; */
    /* pointer-events: none; */

    display: none;
  }
`;
const PrevSlide = styled(NavButton)`
  left: 0px;
  right: auto;
`;
const NextSlide = styled(NavButton)`
  right: 0px;
  left: auto;
`;

const NavButtonStart = styled(NextSlide)`
  display: none;

  &.swiper-button-disabled {
    display: flex;
  }
`;
