/**
 * @generated SignedSource<<94cdbfe7f7f732b6093b18b72970843c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TestEventType = "FORGOT" | "REMEMBERED" | "REVIEWED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TestDeck_user$data = {
  readonly boxIntervals: ReadonlyArray<number>;
  readonly id: string;
  readonly testHistory: ReadonlyArray<{
    readonly eventType: TestEventType;
    readonly song: string;
    readonly time: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AddSongTestEvent_user">;
  readonly " $fragmentType": "TestDeck_user";
};
export type TestDeck_user$key = {
  readonly " $data"?: TestDeck_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestDeck_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestDeck_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddSongTestEvent_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boxIntervals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTestEvent",
      "kind": "LinkedField",
      "name": "testHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "song",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "63bbeecd0701e5ccaf92fc47a8e72615";

export default node;
