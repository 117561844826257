import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { UserSongsQueryQuery } from "../__generated__/UserSongsQueryQuery.graphql";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { UserSongsList } from "./UserSongsList";
import { QueryOptions } from "../_types/relayTypes";
import { AddFeedbackEvent } from "../_components/AddFeedbackEvent";
// import { useDebugLog } from "../_hooks/useDebugLog";

type Props = {
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  searchFilter: string;
  tagFilter: string[];
  setAvailSongTags: React.Dispatch<
    React.SetStateAction<{
      tagSet: string[];
      tagCounts: Record<string, number>;
    }>
  >;
  queryOptions: QueryOptions;
  clientAllowPromptFeedback: boolean;
};

// *** tried to memoize this component, but continued to re-render on scroll; unclear what was causing, as props appeared static; for now, satisfying self with memoizing UserSongsList;
// due to all of the scroll/touch magic in Page/PageContent, there are many re-renders; memoize this component to prevent loads of unnecessary renders while scrolling/toucing (e.g. pull-to-refresh);
// export const UserSongsQuery = React.memo(UserSongsQueryComponent);

export function UserSongsQuery(props: Props) {
  const data = useLazyLoadQuery<UserSongsQueryQuery>(
    graphql`
      query UserSongsQueryQuery {
        viewer {
          user {
            id
            doPromptFeedback
            ...UserSongsList_user
            ...UserSongsList_songs
            # for benefit of web users, load roles and products data, which will be consumed by UpgradeFeature; (otherwise it will have to suspend when first displayed, e.g. in song context menu)
            roles
            products {
              productGroup
              productStore
              productType
              productId
              isVerified
              expiresAt
            }
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    {},
    props.queryOptions
  );

  const dataWithErrors = data.viewer;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors } = dataWithErrors;

  const { setUserId } = props;
  React.useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, [user, setUserId]);

  // const { setDebugLog } = useDebugLog("UserSongsQuery");
  // setDebugLog(["Rendering"], "render");

  return (
    <>
      {/* data result */}
      {user && (
        <>
          <UserSongsList
            userRef={user}
            songsRef={user}
            searchFilter={props.searchFilter}
            tagFilter={props.tagFilter}
            setAvailSongTags={props.setAvailSongTags}
          />
          {user && user.doPromptFeedback && props.clientAllowPromptFeedback && (
            <AddFeedbackEvent hideButton={true} forceShow={true} />
          )}
        </>
      )}

      {/* null result */}
      {!user && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
