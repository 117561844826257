import React from "react";
import { useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { SongAcceptShareMutation } from "../__generated__/SongAcceptShareMutation.graphql";
import { CustomErrors } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";

type Props = {
  songId: string;
  callback?: () => void;
};

export function SongAcceptShare(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");
  const [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const [commit, isInFlight] = useMutation<SongAcceptShareMutation>(graphql`
    mutation SongAcceptShareMutation($input: acceptSongShareInput!) {
      acceptSongShare(input: $input) {
        song {
          id
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
          viewerPermission {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCommit() {
    commit({
      variables: {
        input: {
          songId: props.songId,
        },
      },
      onCompleted: (response) => {
        if (response.acceptSongShare) {
          const { errors } = response.acceptSongShare;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          } else {
            props.callback && props.callback();
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
    });
  }

  return (
    <>
      <Button
        onClick={handleCommit}
        fill="clear"
        disabled={isInFlight}
        iconId="user-check"
      >
        Accept Song Share
      </Button>

      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />}
    </>
  );
}
