import React from "react";
import styled from "styled-components/macro";

import { Icon, icons } from "./Icon";
import { LoadingSpinnerComplete } from "./LoadingSpinner";
import { RippleButton } from "./RippleButton";

// https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/
export const BUTTON_MIN_HEIGHT = 44;

const SIZES = {
  xs: {
    "--border-width": 0 + "px",
    "--borderRadius": 1 + "px",
    "--fontSize": 12 / 16 + "rem",
    "--paddingVert": 4 + "px",
    "--paddingHoriz": 4 + "px",
  },
  sm: {
    "--border-width": 1 + "px",
    "--borderRadius": 2 + "px",
    "--fontSize": 16 / 16 + "rem",
    "--paddingVert": 4 + "px",
    "--paddingHoriz": 15 + "px",
  },
  md: {
    "--border-width": 1 + "px",
    "--borderRadius": 2 + "px",
    "--fontSize": 18 / 16 + "rem",
    "--paddingVert": 8 + "px",
    "--paddingHoriz": 15 + "px",
  },
  lg: {
    "--border-width": 2 + "px",
    "--borderRadius": 4 + "px",
    "--fontSize": 18 / 16 + "rem",
    "--paddingVert": 12 + "px",
    "--paddingHoriz": 22 + "px",
  },
};

const ICON_SIZES = {
  xs: 18,
  sm: 24,
  md: 24,
  lg: 24,
};

export type ButtonSize = keyof typeof SIZES;

export const PALETTES = {
  primary: {
    "--mainColor": "var(--color-primary)",
    "--mainColorLight": "var(--color-primary-light)",
  },
  secondary: {
    "--mainColor": "var(--color-secondary)",
    "--mainColorLight": "var(--color-secondary-light)",
  },
  highlight: {
    "--mainColor": "var(--color-highlight)",
    "--mainColorLight": "var(--color-highlight-light)",
  },
  text: {
    "--mainColor": "var(--color-text)",
    "--mainColorLight": "var(--color-text-light)",
  },
  mono: {
    "--mainColor": "var(--color-mono)",
    "--mainColorLight": "var(--color-mono-light)",
  },
  danger: {
    "--mainColor": "var(--color-danger)",
    "--mainColorLight": "var(--color-danger-light)",
  },
  disabled: {
    "--mainColor": "var(--color-mono-light)",
    "--mainColorLight": "var(--color-mono-translucent-15)",
  },
};

export type Palette = keyof typeof PALETTES;

const EXPAND = {
  /*   full: {
    "--display": "block",
    "--width": "100%",
    "--height": "100%",
  }, */
  block: {
    "--display": "block",
    "--width": "100%",
  },
  inline: {
    "--display": "inline",
    "--width": "unset",
  },
};
export type ButtonExpand = keyof typeof EXPAND;

type Props = {
  fill?: "fill" | "outline" | "underline" | "clear" | "ghost";
  size?: "xs" | "sm" | "md" | "lg";

  // *** this was a silly shortcut --- just compose the button where you want a major style change --- at moment, only used by PageHeader to set padding to 0
  // padding?: number;
  palette?: Palette;
  expand?: ButtonExpand;
  href?: string;
  className?: string;
  // onClick?: (e: Event) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLElement>;
  onTouchStart?: React.MouseEventHandler<HTMLElement>;
  onMouseUp?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  onTouchMove?: React.MouseEventHandler<HTMLElement>;
  onTouchEnd?: React.MouseEventHandler<HTMLElement>;
  // *** prob should ditch this... just change color palette when toggled "off"
  toggledOn?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;

  iconId?: keyof typeof icons;
  isInFlight?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      fill = "fill",
      size = "md",
      // padding,
      palette = "primary",
      expand = "block",
      href,
      className,
      onClick,
      onMouseDown,
      onTouchStart,
      onMouseUp,
      onMouseLeave,
      onTouchMove,
      onTouchEnd,
      toggledOn,
      type = "button",
      disabled,
      children,
      // *** ditch this prop; too limited both in applicability and options, and no sufficiently related to what Button should be responsible for... if you want a dedicated "IconButton", make separate component inheriting from Button or RippleButton
      iconId,
      isInFlight,
    }: Props,
    ref
  ) => {
    const style = {
      ...SIZES[size],
      // *** I've waffled on this, but better solution is for disabled to affect behavior only, not color; there are cases where you want a disabled button to appear as clear text instead of a faded button, e.g. UserSongsList, your "due for review" button that also shows the number of songs;
      // ...(disabled ? PALETTES["disabled"] : PALETTES[palette]),
      ...PALETTES[palette],

      ...EXPAND[expand],
      /*  ...(padding !== undefined && {
        "--paddingVert": padding,
        "--paddingHoriz": padding,
      }), */
    };

    // let Component: StyledComponent<"button", any, BaseButton, never>;
    let Component;
    if (fill === "fill") {
      Component = FillButton;
    } else if (fill === "outline") {
      Component = OutlineButton;
    } else if (fill === "underline") {
      Component = UnderlineButton;
    } else if (fill === "clear") {
      Component = ClearButton;
    } else if (fill === "ghost") {
      Component = GhostButton;
    } else {
      throw new Error(`Unrecognized Button fill: ${fill}`);
    }

    function handleClickWithDelay(e: React.MouseEvent<HTMLButtonElement>) {
      // had used delay of 200ms, but felt sluggish, esp. for buttons that didn't change page;
      setTimeout((e) => onClick && onClick(e), 100, [e]);
    }

    return (
      <Component
        // if "href" provided, return anchor link; (or could return a React Router "Link")
        // as={href ? "a" : "button"}
        // href={href}
        // forward className to allow composing this component in a new styled-component
        className={className}
        style={style as React.CSSProperties}
        /*  style={{
          ...(style as React.CSSProperties),
          transform: appliedStyle.transform.to((x, y, rotation, scale) => {
            return `translate(${x}%, ${y}%) rotate(${rotation}deg) scale(${scale})`;
          }),
        }} */
        // some typing bug forcing me to type as "any": https://github.com/pmndrs/react-spring/issues/1102, though don't understand why only encountering it here
        // style={{ ...style, transform: appliedStyle.transform as any }}
        onClick={handleClickWithDelay}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        type={type}
        disabled={disabled}
        $toggledOn={toggledOn}
        $expand={expand}
        ref={ref}
        // forward any additional props *** borrowed this concept from non-TS code... doesn't make sense in TS
        // {...delegated}
      >
        {iconId ? (
          <ButtonIconWrapper>
            <IconWrapper>
              {isInFlight ? (
                <LoadingSpinnerComplete width={ICON_SIZES[size]} />
              ) : (
                <Icon id={iconId} size={ICON_SIZES[size]} strokeWidth={2} />
              )}
            </IconWrapper>
            {children}
          </ButtonIconWrapper>
        ) : (
          <>{children}</>
        )}
      </Component>
    );
  }
);

interface BaseButton {
  // style?: any;
  // href?: string;
  disabled?: boolean;
  $toggledOn?: boolean;
  $expand?: ButtonExpand;
}

/* const ButtonBase = styled.button<BaseButton>` */
const ButtonBase = styled(RippleButton)<BaseButton>`
  display: var(--display);
  width: var(--width);
  height: var(--height);
  min-height: ${BUTTON_MIN_HEIGHT}px;

  font-size: var(--fontSize);
  padding: var(--paddingVert) var(--paddingHoriz);
  border-radius: var(--borderRadius);
  /*  border-radius: ${({ $expand }) =>
    $expand === "inline" ? `var(--borderRadius)` : `0`}; */

  /* opacity: ${({ disabled }) => disabled && `0.8`}; */
  cursor: ${({ disabled }) => disabled && `default`};

  user-select: none;

  &:focus-visible {
    outline-offset: -3px;
    outline-color: var(--color-text-contrast);
  }
  /*  &:active {
    opacity: 0.6;
  } */
`;

const FillButton = styled(ButtonBase)`
  background-color: var(--mainColor);
  color: var(--color-text-contrast);
  --color-ripple: var(--color-text-contrast);

  /* border: var(--border-width) solid transparent; */
  /* extra padding to compensate for lack of border, ensuring button doesn't change size if style is changed */
  padding: calc(var(--paddingVert) + var(--border-width))
    calc(var(--paddingHoriz) + var(--border-width));

  /* only apply where device has mouse or similar pointing input device */
  @media (hover: hover) {
    &:hover {
      background-color: ${({ disabled }) =>
        !disabled && `var(--mainColorLight);`};
    }
  }
  &:active {
    background-color: ${({ disabled }) =>
      !disabled && `var(--mainColorLight);`};
  }
`;

const OutlineButton = styled(ButtonBase)`
  background-color: var(--color-text-background);
  color: var(--mainColor);
  --color-ripple: var(--mainColor);
  /* *** used currentColor to "inherit" color for border, but glitched in iOS Safari when toggled the palette for buttons: part of the border (and not even side-by-side, but a horseshoe shape of border...) would not update color, leaving you with a two-tone border; officially iOS supports this property, so not sure why it goes wrong in this context */
  /* border: var(--border-width) solid currentColor; */
  border: var(--border-width) solid var(--mainColor);

  &:focus-visible {
    outline-color: var(--mainColor);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-background-tint);
    }
  }
  &:active {
    background-color: ${({ disabled }) =>
      !disabled && `var(--color-background-tint);`};
  }
`;

const UnderlineButton = styled(OutlineButton)`
  /* background:none; primarily for LoginCreate, where Buttons may overlap background image of app logo */
  background: none;
  border: none;
  border-bottom: var(--border-width) solid var(--mainColor);
  border-radius: 0;
  /* extra padding to compensate for border on bottom only, ensuring button doesn't change size when style is changed */
  padding-top: calc(var(--paddingVert) + var(--border-width));
  padding-right: calc(var(--paddingHoriz) + var(--border-width));
  padding-left: calc(var(--paddingHoriz) + var(--border-width));
`;

const ClearButton = styled(ButtonBase)`
  color: ${({ $toggledOn }) =>
    $toggledOn === false ? `var(--mainColorLight)` : `var(--mainColor)`};
  background-color: transparent;
  --color-ripple: var(--mainColorLight);

  /* extra padding to compensate for lack of border, ensuring button doesn't change size when style is changed */
  padding: calc(var(--paddingVert) + var(--border-width))
    calc(var(--paddingHoriz) + var(--border-width));

  /* box-shadow: inset -2px -4px 16px var(--color-background-dark); */

  &:focus-visible {
    outline-color: var(--mainColor);
  }
  /* apply :hover only for devices that support it, i.e. non-touch devices 
  https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices
  */
  @media (hover: hover) {
    &:hover {
      background: var(--color-mono-translucent-15);
    }
  }
  &:active {
    background-color: ${({ disabled }) =>
      !disabled && `var(--color-mono-translucent-15);`};
  }
`;

const GhostButton = styled(ClearButton)`
  color: var(--color-text-medium);
  background-color: transparent;
  --color-ripple: var(--color-text-medium);

  &:focus-visible {
    outline-color: var(--color-text-medium);
  }
  @media (hover: hover) {
    &:hover {
      background: var(--color-mono-translucent-15);
      color: var(--color-text);
    }
  }
  &:active {
    background: ${({ disabled }) =>
      !disabled && `var(--color-mono-translucent-15);`};
    color: ${({ disabled }) =>
      !disabled && `var(--color-text);`};
  }
`;

const IconWrapper = styled.div`
  margin-right: 20px;
  margin-right: calc(var(--paddingHoriz) + var(--border-width));
`;
const ButtonIconWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: left;
`;

export default Button;
