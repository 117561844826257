/**
 * @generated SignedSource<<df1ceec2643bf1027ffbec6c9a9c1807>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type updateSongMetaInput = {
  artist: string;
  clientMutationId?: string | null;
  mongoVersion: number;
  songId: string;
  title: string;
};
export type UpdateSongMetaMutation$variables = {
  input: updateSongMetaInput;
};
export type UpdateSongMetaMutation$data = {
  readonly updateSongMeta: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly updatedSong: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSongMeta_song">;
    } | null;
  } | null;
};
export type UpdateSongMetaMutation = {
  response: UpdateSongMetaMutation$data;
  variables: UpdateSongMetaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSongMetaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSongMetaPayload",
        "kind": "LinkedField",
        "name": "updateSongMeta",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "updatedSong",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongMeta_song"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSongMetaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSongMetaPayload",
        "kind": "LinkedField",
        "name": "updateSongMeta",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "updatedSong",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "artist",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLockedByUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "editLock",
                "kind": "LinkedField",
                "name": "editLock",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedByEmail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedUntil",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72853795dc86a8d6ba6c1a7dfcaadc9f",
    "id": null,
    "metadata": {},
    "name": "UpdateSongMetaMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSongMetaMutation(\n  $input: updateSongMetaInput!\n) {\n  updateSongMeta(input: $input) {\n    updatedSong {\n      ...UpdateSongMeta_song\n      id\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment UpdateSongMeta_song on Song {\n  id\n  mongoVersion\n  title\n  artist\n  isLockedByUser\n  editLock {\n    isLocked\n    lockedBy\n    lockedByEmail\n    lockedUntil\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed5b8617042f0c8b4838de0617842853";

export default node;
