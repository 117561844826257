/**
 * @generated SignedSource<<bc3fbd529a8d573ffb7c50f2517450ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SongListItem_song$data = {
  readonly artist: string;
  readonly id: string;
  readonly permissions: ReadonlyArray<{
    readonly canEdit: boolean;
    readonly didAccept: boolean;
    readonly email: string;
    readonly isOwner: boolean;
    readonly user: string;
  }>;
  readonly tags: ReadonlyArray<string>;
  readonly title: string;
  readonly viewerPermission: {
    readonly canEdit: boolean;
    readonly didAccept: boolean;
    readonly email: string;
    readonly isOwner: boolean;
    readonly user: string;
  };
  readonly " $fragmentType": "SongListItem_song";
};
export type SongListItem_song$key = {
  readonly " $data"?: SongListItem_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"SongListItem_song">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SongListItem_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artist",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SongPermission",
      "kind": "LinkedField",
      "name": "permissions",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SongPermission",
      "kind": "LinkedField",
      "name": "viewerPermission",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};
})();

(node as any).hash = "c453356dba216b37b2bb8d996040896c";

export default node;
