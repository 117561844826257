// https://capacitorjs.com/docs/apis/device
import { Device } from "@capacitor/device";

// "web", "android", or "ios"
export let devicePlatform: string = "web";

export async function getDeviceInfo() {
  try {
    let deviceInfo = await Device.getInfo();
    devicePlatform = deviceInfo.platform;
    return devicePlatform;
  } catch (err) {
    console.error(err);
  }
}
