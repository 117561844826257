import styled from "styled-components/macro";

export const FooterCore = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* ensure sits over positioned body content including elements with opacity < 1.0, e.g. Button in :active state */
  z-index: 1;

  /* padding-bottom: constant(safe-area-inset-bottom); */
  padding-bottom: env(safe-area-inset-bottom);

  /* needed e.g. when in landscape view */
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /* if backdrop-filter not supported: mostly opaque */
  background-color: hsla(0, 0%, 100%, 0.97);
  /* if backdrop-filter supported: more transparent and blurred */
  @supports (
    (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px))
  ) {
    background-color: hsla(0, 0%, 100%, 0.75);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  box-shadow: var(--shadow-elevation-low-invert);
`;
