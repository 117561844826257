import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { UpdateSectionName_song$key } from "../__generated__/UpdateSectionName_song.graphql";
import { UpdateSectionName_section$key } from "../__generated__/UpdateSectionName_section.graphql";
import {
  updateSectionNameInput,
  UpdateSectionNameMutation,
} from "../__generated__/UpdateSectionNameMutation.graphql";

import { TextInputModal } from "../_components/TextInputModal";
import { ModalError } from "../_components/ModalError";
import { isApiError } from "../_types/errorTypes";

type Props = {
  songRef: UpdateSectionName_song$key;
  sectionRef: UpdateSectionName_section$key;
};

export function UpdateSectionName(props: Props) {
  const song = useFragment(
    graphql`
      fragment UpdateSectionName_song on Song {
        id
        mongoVersion
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment UpdateSectionName_section on SongSection {
        id
        name
      }
    `,
    props.sectionRef
  );

  const [commit, isInFlight] = useMutation<UpdateSectionNameMutation>(graphql`
    mutation UpdateSectionNameMutation($input: updateSectionNameInput!) {
      updateSectionName(input: $input) {
        song {
          ...UpdateSectionName_song
        }
        section {
          ...UpdateSectionName_section
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  const [errMsg, setErrMsg] = React.useState<string>("");

  function getOptimisticResponse(input: updateSectionNameInput) {
    return {
      updateSectionName: {
        song: {
          id: input.songId,
          mongoVersion: input.mongoVersion + 1,
        },
        section: {
          id: input.sectionId,
          name: input.name,
        },
        errors: [],
      },
    };
  }

  function handleUpdateSection(input: updateSectionNameInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSectionName) {
          const { errors } = response.updateSectionName;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "name",
            initialValue: section.name,
            label: "Name",
            required: true,
            autoCapitalize: "sentences",
          },
        ]}
        callback={(inputValues: Record<string, string>) => {
          handleUpdateSection({
            songId: song.id,
            mongoVersion: song.mongoVersion,
            sectionId: section.id,
            name: inputValues.name,
          });
        }}
        modalLabel="Update Section Name"
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
