import React from "react";

import { ChordOrCursor } from "../../../_types";

import {
  TapGroupInstructions,
  TapButtonRow,
  TapGroupWrapper,
} from "./TapGroupShared";
import { HelpModal } from "../../../_components/HelpModal";

type Props = {
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
};

export function TapManual(props: Props) {
  return (
    <TapGroupWrapper>
      <TapButtonRow>
        <TapGroupInstructions>Use slider</TapGroupInstructions>
        <HelpModal label="Duration Slider">
          <h3>Duration Slider</h3>
          <p>Tap a single chord to select.</p>
          <p>Drag the slider to set the duration of the selected chord.</p>
          <p>
            Beware: using the slider will *remove* any rhythm you tapped on the
            chord.
          </p>
        </HelpModal>
      </TapButtonRow>
      {/* <TapButtonRow>
        SHOW TOTAL SECTION BEATS here, and in each of the other two tabs... for
        tap input, show what section beat count *will* be if user hits "finish"?
      </TapButtonRow> */}
    </TapGroupWrapper>
  );
}
