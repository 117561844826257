import { createGlobalStyle } from "styled-components/macro";
// import { createGlobalStyle } from "styled-components";

// https://github.com/styled-components/vscode-styled-components/issues/175
// https://github.com/prettier/prettier/pull/9025
// silly workaround ... just so code is formatted nicely by Prettier
const styled = { createGlobalStyle };
// const GlobalStyles = createGlobalStyle`
const GlobalStyles = styled.createGlobalStyle`
  /* 
    CSS Reset
    https://github.com/hankchizljaw/modern-css-reset 
*/

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  // Safari's (desktop and iOS) user agent sets margin to "0em" for button,input,textarea,select (per Safari's inspector)... which somehow becomes 2px (for buttons, at least);
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd,
  button,
  input,
  textarea,
  select {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role="list"],
  ol[role="list"] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100%;
    text-rendering: optimizeSpeed;
    /* text-rendering: optimizeLegibility; */
    touch-action: manipulation;
    line-height: 1.5;

    /* top safe area located in header components */
    /*padding-top: env(safe-area-inset-top);*/

    /* bottom safe area needs to be applied to specific (usually fixed) elements, instead of full body, to allow full screen height scrolling */
    /* padding-bottom: env(safe-area-inset-bottom);*/
  }

  /* "a" elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove animations and transitions for people that prefer not to see them */
  /* @media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
} */

  /** 
    GK Global Styles 
   **/

  *,
  *::before,
  *::after {
    /* for the benefit of MacOS browsers: https://courses.joshwcomeau.com/css-for-js/06-typography-and-media/02-text-rendering  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* avoid long presses triggering selection of text; be sure to opt-in text inputs, textareas */
    user-select: none;

    /* primarily for benefit of toggling light/dark modes */
    /* tried transitioning color also; it works, but it appears to have a cumulative effect (when applied with "*": for text that was) more deeply nested, the transition occurred more slowly */
    /* transition:  color 0.5s, background 0.5s; */
    transition: background 0.5s;
  }

  input,
  textarea {
    user-select: auto;
  }

  input,
  select,
  textarea {
    /* prevent iOS Safari zooming in when focusing input by ensuring fonts at least 16px */
    font-size: 1rem;
  }

  html {
    /* 
    *** this warrants more reading: although the below sources don't say so, applying font:-apple-system-[SUFFIX] to HTML acts as a global modifier, making setting iOS "Larger Text" affect all rem values, akin to changing browser's font scaling; doesn't work when this font style is applied to body;
    https://webkit.org/blog/3709/using-the-system-font-in-web-content/
    https://github.com/ionic-team/ionic-framework/issues/18592#issuecomment-721912944
    https://developer.mozilla.org/en-US/docs/Web/CSS/font
    */
    @supports (font: -apple-system-body) {
      font: -apple-system-body;
    }

    /* if want to suppress ReachUI warnings for not including ReachUI styles with components, set this var, which tricks ReachUI into thinking its styles are already loaded; if use this, should stop importing Reach styles, remove unnecessary overrides from components... this should clean up those components nicely */
    /* --reach-dialog:1; */

    /* @media (pointer: coarse) {
          --min-tap-height: 44px;
      } */
  }

  html,
  body,
  #root {
    /* prevent body scrolling in iOS Safari */
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  html,
  body {
    background-color: var(--color-background);
    color: var(--color-text);
  }

  body {
    /* https://meta.stackexchange.com/questions/364048/we-are-switching-to-system-fonts-on-may-10-2021 */
    font-family: system-ui, -apple-system, BlinkMacSystemFont,
      // San Francisco on macOS and iOS
      "Segoe UI",
      // Windows
      "Ubuntu",
      // Ubuntu
      "Roboto",
      "Noto Sans", "Droid Sans",
      // Chrome OS and Android with fallbacks
      sans-serif; // The final fallback for rendering in sans-serif.

    /* if use custom fonts, "swap" will load default fonts if custom font doesn't load quickly */
    /* font-display:swap; */

    /* Mobile browsers (looking at you Safari) may enlarge text size if its container is occupying 100% width; in your case, it presented itself when using a button that applied [flex:1;] to the text contained by the button, making that text larger than it should have been (and larger than the text in neighboring buttons without flex); some users report text-size-adjust should be set to "100%" instead of "none" to avoid bugs in some browsers, but those reports are old, so will follow the specs per MDN for now;
    https://developer.mozilla.org/en-US/docs/Web/CSS/text-size-adjust 
    https://stackoverflow.com/questions/20924039/wrong-font-size-when-using-float-right-in-css-on-mobile-safari/22417120#22417120
    */
    text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }

  #root {
    /* 
        Create a stacking context - without a z-index.
        This ensures all portal content (e.g. modals, tooltips) will appear on top of the app
    */
    isolation: isolate;
  }

  h1,
  h2,
  h3,
  p {
    margin-bottom: ${16 / 16}rem;
  }

  button,
  label {
    cursor: pointer;
    /* Safari user-agent stylesheet adds tap-highlight-color to buttons, labels, inputs, etc. on :active */
    -webkit-tap-highlight-color: transparent;
  }

  a,
  a:link,
  a:visited {
    color: var(--color-primary);
  }

  /* https://developers.google.com/recaptcha/docs/faq */
  .grecaptcha-badge {
    visibility: hidden;
  }

  :root {
    --font-family-serif: Times, "Times New Roman", Georgia, serif;
    --font-family-monospace: "Lucida Console", Courier, monospace;
    /* 
    Thoughts on theming
    - If all you're doing is setting light/dark mode vars within GlobalStyles, no point wrapping it in ThemeProvider... could just send it a single variable (e.g. "light");
    - For theming with GlobalStyles only, use only "theme colors" in components, to ensure you have a small set of variables you need to change based on theme
    - ThemeProvider is useful for any components you want to theme individually
    - in color definitions below, the number (e.g. "500") refers to tint/darkness
 
      For colors, could store colors in JS constants as e.g. "hsl(0deg 0% 0%)", or could store as "0deg 0% 0%" and add "hsl()" in CSS, or could simply use CSS and not JS, if never going to dynamically change those values or directly references them outside of CSS;

      Note: CSS4 now treats hsl() the same as hsla(), and seems CSS4 prefers space-separated, e.g. "hsl(235 100% 50% / .5)"
    */

    /**
     * Color Definitions
    **/
    /* 
      grays from: https://courses.joshwcomeau.com/css-for-js/treasure-trove/014-color-palettes 
      *** but I reversed the lightness numbers e.g. "500", as they ran opposite other systems 
    */
    --color-gray-hue: 210deg;
    --color-gray-50: hsl(var(--color-gray-hue), 25%, 96%);
    --color-gray-100: hsl(var(--color-gray-hue), 25%, 88%);
    --color-gray-200: hsl(var(--color-gray-hue), 20%, 77%);
    --color-gray-300: hsl(var(--color-gray-hue), 14%, 66%);
    --color-gray-400: hsl(var(--color-gray-hue), 12%, 55%);
    --color-gray-500: hsl(var(--color-gray-hue), 8%, 50%);
    --color-gray-600: hsl(var(--color-gray-hue), 9%, 45%);
    --color-gray-700: hsl(var(--color-gray-hue), 10%, 40%);
    --color-gray-800: hsl(var(--color-gray-hue), 15%, 25%);
    --color-gray-900: hsl(var(--color-gray-hue), 15%, 20%);
    --color-gray-1000: hsl(var(--color-gray-hue), 19%, 10%);

    --color-gray-500-translucent-15: hsl(var(--color-gray-hue) 8% 50% / 0.15);
    --color-gray-500-translucent-75: hsl(var(--color-gray-hue) 8% 50% / 0.75);
    --color-gray-50-translucent-20: hsl(var(--color-gray-hue) 25% 96% / 0.2);
    --color-gray-50-translucent-60: hsl(var(--color-gray-hue) 25% 96% / 0.6);
    --color-gray-1000-translucent-20: hsl(var(--color-gray-hue) 19% 10% / 0.2);
    --color-gray-1000-translucent-60: hsl(var(--color-gray-hue) 19% 10% / 0.6);

    /* colors from: https://tailwindcss.com/docs/customizing-colors */
    --color-blue-50: #eff6ff;
    --color-blue-100: #dbeafe;
    --color-blue-200: #bfdbfe;
    --color-blue-200-translucent-10: hsl(213 97% 87% / 0.1);
    --color-blue-300: #93c5fd;
    --color-blue-300-translucent-10: hsl(212 96% 78% / 0.1);
    --color-blue-300-translucent-20: hsl(212 96% 78% / 0.2);
    --color-blue-300-translucent-85: hsl(212 96% 78% / 0.85);
    --color-blue-400: #60a5fa;
    --color-blue-700: #1d4ed8;
    --color-blue-700-translucent-10: hsla(224, 76%, 48%, 0.1);
    --color-blue-700-translucent-20: hsla(224, 76%, 48%, 0.2);
    --color-blue-700-translucent-85: hsla(224, 76%, 48%, 0.85);
    --color-blue-800: #1e40af;
    --color-blue-800-translucent-10: hsl(226 71% 40% / 0.1);
    --color-blue-900: #1e3a8a;
    --color-blue-1000: hsl(
      224.44444444444443,
      64.28571428571428%,
      32.94117647058823%
    );

    --color-yellow-300: #fde047;
    --color-yellow-700: #a16207;

    --color-amber-100: #fef3c7;
    --color-amber-200: #fde68a;
    --color-amber-200-translucent: hsl(48 97% 77% / 0.4);
    --color-amber-300: #fcd34d;
    --color-amber-300-translucent: hsl(46 97% 65% / 0.4);
    --color-amber-700: #b45309;
    --color-amber-700-translucent: hsl(26 90% 37% / 0.4);
    --color-amber-800: #92400e;
    --color-amber-800-translucent: hsl(23 83% 31% / 0.4);
    --color-amber-900: #78350f;

    /*
    --color-highlight: hsl(57, 80%, 65%);
    --color-highlight-light: hsl(57, 80%, 80%);
    --color-highlight-translucent: hsla(57, 80%, 65%, 0.4); 
    */

    --color-emerald-100: #d1fae5;
    --color-emerald-200: #a7f3d0;
    --color-emerald-300: #6ee7b7;
    --color-emerald-300-translucent-20: hsl(156.2 71.6% 66.9% / 0.2);
    --color-emerald-300-translucent-85: hsl(156.2 71.6% 66.9% / 0.85);
    --color-emerald-700: #047857;
    --color-emerald-700-translucent-20: hsl(162.9 93.5% 24.3% /0.2);
    --color-emerald-700-translucent-85: hsl(162.9 93.5% 24.3% /0.85);
    --color-emerald-800: #065f46;
    --color-emerald-900: #064e3b;

    --color-red-300: #fca5a5;
    --color-red-700: #b91c1c;

    --color-dark: hsl(0deg 0% 7%);
    --color-dark-dark: hsl(0, 0%, 3%);
    --color-dark-translucent-20: hsla(0deg, 0%, 7%, 0.2);
    --color-dark-translucent-60: hsla(0deg, 0%, 7%, 0.6);
    --color-dark-translucent-75: hsla(0deg, 0%, 7%, 0.75);
    --color-dark-translucent-96: hsla(0deg, 0%, 7%, 0.96);

    --color-black: hsl(0deg 0% 0%);
    --color-black-translucent-20: hsla(0deg, 0%, 0%, 0.2);
    --color-black-translucent-60: hsla(0deg, 0%, 0%, 0.6);
    --color-black-translucent-75: hsla(0deg, 0%, 0%, 0.75);
    --color-black-translucent-96: hsla(0deg, 0%, 0%, 0.96);

    --color-off-black-hsl: 235deg 30% 15%;
    --color-off-black: hsl(var(--color-off-black-hsl));
    --color-off-black-translucent-75: hsl(var(--color-off-black-hsl) / 0.75);
    --color-off-black-translucent-96: hsl(var(--color-off-black-hsl) / 0.96);

    --color-white: hsl(0deg 0% 100%);
    --color-white-dark: hsl(0, 0%, 97%);
    --color-white-translucent-20: hsla(0deg, 0%, 100%, 0.2);
    --color-white-translucent-60: hsla(0deg, 0%, 100%, 0.6);
    --color-white-translucent-75: hsla(0deg, 0%, 100%, 0.75);
    --color-white-translucent-96: hsla(0deg, 0%, 100%, 0.96);
    --color-dull-white: hsl(0deg 0% 95%);

    --color-off-white-hsl: 235deg 40% 97%;
    --color-off-white: hsl(var(--color-off-white-hsl));
    --color-off-white-translucent-75: hsl(var(--color-off-white-hsl) / 0.75);
    --color-off-white-translucent-96: hsl(var(--color-off-white-hsl) / 0.96);

    --color-deep-silver: hsl(206.67, 4.07%, 56.66%);

    /**
     * Chord Keyboard
    **/

    --ios-keyboard-key-alt: #b1b5c0;
    --ios-keyboard-shadow: #7e8184;
    --ios-keyboard-key-dark: #1c1c1e;

    /**
     * Theme Colors
    **/

    /* example of how you could apply theme properties */
    /* --color-primary: ${(props) => props.theme.color.primary.text}; */

    /**
     * LIGHT THEME
    **/

    ${(props) =>
      props.theme.id === "light" &&
      `
    --color-background: var(--color-white);
    --color-background-dark: var(--color-white-dark);

    --color-background-translucent-20: var(--color-white-translucent-20);
    --color-background-translucent-60: var(--color-white-translucent-60);
    --color-background-translucent-75: var(--color-white-translucent-75);
    --color-background-translucent-96: var(--color-white-translucent-96);
    --color-background-tint: var(--color-off-white);
    --color-background-tint-translucent-75: var(--color-off-white-translucent-75);
    --color-background-tint-translucent-96: var(--color-off-white-translucent-96);
    
    --color-text: var(--color-black);
    --color-text-medium: var(--color-gray-700);
    --color-text-light: var(--color-gray-300);
    --color-text-translucent: var(--color-gray-500-translucent-15);
    --color-text-contrast: var(--color-white);

    --color-mono: var(--color-gray-700);
    --color-mono-light: var(--color-gray-300);
    --color-mono-dark: var(--color-gray-800);
    --color-mono-translucent-15: var(--color-gray-500-translucent-15);

    --color-primary: var(--color-blue-700);
    --color-primary-light: var(--color-blue-300);
    --color-primary-dark: var(--color-blue-800);
    --color-primary-translucent-10: var(--color-blue-700-translucent-10);
    --color-primary-translucent-20: var(--color-blue-700-translucent-20);
    --color-primary-translucent-85: var(--color-blue-700-translucent-85);

    --color-secondary: var(--color-emerald-700);
    --color-secondary-light: var(--color-emerald-300);
    --color-secondary-translucent-20: var(--color-emerald-700-translucent-20);
    --color-secondary-translucent-85: var(--color-emerald-700-translucent-85);

    --color-danger: var(--color-red-700);
    --color-danger-light: var(--color-red-300);

    --color-highlight: var(--color-amber-200);
    --color-highlight-light: var(--color-amber-100);
    --color-highlight-translucent: var(--color-amber-200-translucent);

    --color-shadow: var(--color-gray-1000-translucent-60);
    --color-shadow-light: var(--color-gray-1000-translucent-20);

    --svg-color-filter: none;

    /* Chord grid alternating background colors */
    --color-chord-rows-odd: var(--color-blue-200-translucent-10);
    --color-chord-rows-even: var(--color-white-translucent-20);

    --color-chord-root: var(--color-emerald-900);
    --color-chord-root: var(--color-text);
    --color-chord-qual: var(--color-text);

    --color-tab-lightness: 85%;

    /**
     * Chord Keyboard
    **/
    --g-keyboard-bg: transparent;
    --g-keyboard-key: var(--color-gray-50);
    --g-keyboard-font: var(--color-text);
    --g-keyboard-key-dark: var(--ios-keyboard-key-alt);
    --g-keyboard-font-dark: var(--color-text);
    --g-keyboard-shadow: var(--ios-keyboard-shadow);

    --color-tint-lightness: 0%;
    `}

    /**
     * DARK THEME
    **/

    ${(props) =>
      props.theme.id === "dark" &&
      `
    --color-background: var(--color-dark);
    --color-background-dark: var(--color-dark-dark);

    --color-background-translucent-20: var(--color-dark-translucent-20);
    --color-background-translucent-60: var(--color-dark-translucent-60);
    --color-background-translucent-75: var(--color-dark-translucent-75);
    --color-background-translucent-96: var(--color-dark-translucent-96);
    --color-background-tint: var(--color-off-black);
    --color-background-tint-translucent-75: var(--color-off-black-translucent-75);
    --color-background-tint-translucent-96: var(--color-off-black-translucent-96);

    --color-text: var(--color-white);
    --color-text-medium: var(--color-gray-300);
    --color-text-light: var(--color-gray-700);
    --color-text-translucent: var(--color-gray-500-translucent-15);
    --color-text-contrast: var(--color-dark);

    --color-mono: var(--color-gray-300);
    --color-mono-light: var(--color-gray-700);
    --color-mono-dark: var(--color-gray-200);
    --color-mono-translucent-15: var(--color-gray-500-translucent-15);

    --color-primary: var(--color-blue-300);
    --color-primary-light: var(--color-blue-700);
    --color-primary-dark: var(--color-blue-200);
    --color-primary-translucent-10: var(--color-blue-300-translucent-10);
    --color-primary-translucent-20: var(--color-blue-300-translucent-20);
    --color-primary-translucent-85: var(--color-blue-300-translucent-85);

    --color-secondary: var(--color-emerald-300);
    --color-secondary-light: var(--color-emerald-700);
    --color-secondary-translucent-20: var(--color-emerald-300-translucent-20);
    --color-secondary-translucent-85: var(--color-emerald-300-translucent-85);

    --color-danger: var(--color-red-300);
    --color-danger-light: var(--color-red-700);

    --color-highlight: var(--color-amber-800);
    --color-highlight-light: var(--color-amber-900);
    --color-highlight-translucent: var(--color-amber-800-translucent);

    --color-shadow: var(--color-black-translucent-60);
    --color-shadow-light: var(--color-black-translucent-20);

    --svg-color-filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(138deg) brightness(103%) contrast(101%);

    /* Chord grid alternating background colors */
    --color-chord-rows-odd: var(--color-blue-800-translucent-10);
    --color-chord-rows-even: var(--color-dark-translucent-20);

    --color-chord-root: var(--color-emerald-100);
    --color-chord-root: var(--color-text);
    --color-chord-qual: var(--color-text);

    --color-tab-lightness: 15%;

    /**
     * Chord Keyboard
    **/
    --g-keyboard-bg: transparent;
    --g-keyboard-key: var(--color-gray-900);
    --g-keyboard-font: var(--color-text);
    --g-keyboard-key-dark: var(--color-gray-1000);
    --g-keyboard-font-dark: var(--color-text);
    --g-keyboard-shadow: var(--color-shadow);
    
    --color-tint-lightness: 100%;
    `}

    /**
     * Shadows
    **/

    --shadow-elevation-low: 0px 0px 1px var(--color-shadow-light),
      0px 0.5px 0.5px var(--color-shadow-light),
      0px 1px 2px -0.5px var(--color-shadow-light),
      0px 2px 3px -1px var(--color-shadow-light);

    --shadow-elevation-low-invert: 0px 0px 1px var(--color-shadow-light),
      0px -0.5px 0.5px var(--color-shadow-light),
      0px -1px 2px -0.5px var(--color-shadow-light),
      0px -2px 3px -1px var(--color-shadow-light);

    --shadow-elevation-direct-low: 0px 0px 0.5px var(--color-shadow-light),
      0px 0px 0.5px var(--color-shadow-light),
      0px 0.5px 2px -0.5px var(--color-shadow-light),
      0px 0.5px 3px -1px var(--color-shadow-light);

    --shadow-elevation-inset-low: inset 0px 0px 1px var(--color-shadow-light),
      inset 0px 0.5px 0.5px var(--color-shadow-light),
      inset 0px 1px 2px -0.5px var(--color-shadow-light),
      inset 0px 2px 3px -1px var(--color-shadow-light);

    --shadow-elevation-medium: 0px 0px 1px var(--color-shadow-light),
      0px 0.5px 1.5px var(--color-shadow-light),
      0px 1.5px 2.5px -0.5px var(--color-shadow-light),
      0px 3.4px 3.8px -1.2px var(--color-shadow-light),
      0px 8.2px 9.2px -1.7px var(--color-shadow-light);

    --shadow-elevation-high: 0px 0.4px 0.5px var(--color-shadow-light),
      0px 2.4px 2.7px -0.4px var(--color-shadow-light),
      0px 4.5px 5.1px -0.7px var(--color-shadow-light),
      0px 7.4px 8.3px -1.1px var(--color-shadow-light),
      0px 11.7px 13.2px -1.4px var(--color-shadow-light),
      0px 18.4px 20.7px -1.8px var(--color-shadow-light),
      0px 27.9px 31.4px -2.1px var(--color-shadow-light),
      0px 41.1px 46.2px -2.5px var(--color-shadow-light);

    /**
     * Chord Editing
    **/

    --color-tab-saturation: 15%;
    --color-tab-opacity: 1;
    --color-tab-root: hsla(
      0,
      var(--color-tab-saturation),
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );
    --color-tab-qual: hsla(
      72,
      var(--color-tab-saturation),
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );
    --color-tab-bass: hsla(
      144,
      var(--color-tab-saturation),
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );
    --color-tab-repeat: hsla(
      216,
      var(--color-tab-saturation),
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );
    --color-tab-tap: hsla(
      288,
      var(--color-tab-saturation),
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );
    --color-tab-addDelete: hsla(
      0,
      0%,
      var(--color-tab-lightness),
      var(--color-tab-opacity)
    );

    /*   
        --color-tab-qual-maj:  hsla(90, var(--color-tab-saturation), var(--color-tab-lightness),var(--color-tab-opacity) );
        --color-tab-qual-min:  hsla(90, var(--color-tab-saturation), 90% ,var(--color-tab-opacity) );
    */

    /**
     * Leitner testing
    **/

    --color-remembered: hsl(161, 80%, 25%);
    --color-forgot: hsl(0, 70%, 40%);
  }
`;

export default GlobalStyles;
