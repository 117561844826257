/**
 * @generated SignedSource<<a2e8e52cc390cf2a0df79fdef28ff57d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SectionMetaQueryQuery$variables = {
  id: string;
};
export type SectionMetaQueryQuery$data = {
  readonly section: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NotFound";
      readonly message: string;
    }>;
    readonly section: {
      readonly id: string;
      readonly name: string;
      readonly notes: string;
      readonly " $fragmentSpreads": FragmentRefs<"DeleteSection_section" | "SectionQueryChords_section" | "UpdateSectionName_section" | "UpdateSectionNotes_section" | "UpdateSectionTimeSig_section">;
    } | null;
    readonly song: {
      readonly id: string;
      readonly mongoVersion: number;
      readonly " $fragmentSpreads": FragmentRefs<"DeleteSection_song" | "UpdateSectionName_song" | "UpdateSectionNotes_song" | "UpdateSectionTimeSig_song">;
    } | null;
  } | null;
};
export type SectionMetaQueryQuery = {
  response: SectionMetaQueryQuery$data;
  variables: SectionMetaQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoVersion",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "NotFound",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSig",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionMetaQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SectionQueryResult",
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionName_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionNotes_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeleteSection_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionTimeSig_song"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionName_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionNotes_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeleteSection_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionQueryChords_section"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionTimeSig_section"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SectionMetaQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SectionQueryResult",
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rhythmUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Chord",
                "kind": "LinkedField",
                "name": "chords",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rhythm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChordDef",
                    "kind": "LinkedField",
                    "name": "definition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "root",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "qual",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bass",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notation",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4acbc0af23c407b21d756a4e205923b0",
    "id": null,
    "metadata": {},
    "name": "SectionMetaQueryQuery",
    "operationKind": "query",
    "text": "query SectionMetaQueryQuery(\n  $id: ID!\n) {\n  section(id: $id) {\n    song {\n      id\n      mongoVersion\n      ...UpdateSectionName_song\n      ...UpdateSectionNotes_song\n      ...DeleteSection_song\n      ...UpdateSectionTimeSig_song\n    }\n    section {\n      id\n      name\n      notes\n      ...UpdateSectionName_section\n      ...UpdateSectionNotes_section\n      ...DeleteSection_section\n      ...SectionQueryChords_section\n      ...UpdateSectionTimeSig_section\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment ChordsSimple_section on SongSection {\n  id\n  timeSig\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n}\n\nfragment DeleteSection_section on SongSection {\n  id\n  name\n}\n\nfragment DeleteSection_song on Song {\n  id\n  mongoVersion\n}\n\nfragment SectionQueryChords_section on SongSection {\n  id\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n  ...UpdateSectionChordsMutation_section\n  ...ChordsSimple_section\n}\n\nfragment UpdateSectionChordsMutation_section on SongSection {\n  id\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n}\n\nfragment UpdateSectionName_section on SongSection {\n  id\n  name\n}\n\nfragment UpdateSectionName_song on Song {\n  id\n  mongoVersion\n}\n\nfragment UpdateSectionNotes_section on SongSection {\n  id\n  notes\n}\n\nfragment UpdateSectionNotes_song on Song {\n  id\n  mongoVersion\n}\n\nfragment UpdateSectionTimeSig_section on SongSection {\n  id\n  timeSig\n}\n\nfragment UpdateSectionTimeSig_song on Song {\n  id\n  mongoVersion\n  timeSig\n}\n"
  }
};
})();

(node as any).hash = "44d9a10eb64b84bc75134ee37a929c39";

export default node;
