import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type {
  createSectionInput,
  CreateSectionMutation,
} from "../__generated__/CreateSectionMutation.graphql";

// import CreateSectionMutation from "../_mutations/CreateSectionMutation";

import { TextInputModal } from "../_components/TextInputModal";

import { CreateSection_song$key } from "../__generated__/CreateSection_song.graphql";
import { getNodeArrayFromEdges } from "../_types/graphqlData";
import { typeNotEmpty } from "../_types/typeHelpers";
import {
  sectionNameSuggestions,
  suggestSectionName,
} from "../_utilities/songTools";
import { CustomErrors } from "../_types/errorTypes";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { ModalError } from "../_components/ModalError";
import { InputValueSuggestion } from "../_components/InputSuggest";

/* these imports... bring in different versions of ConnectionHandler?  .getConnectionID() is unavailable on the import, but available on the "require" */
// import { ConnectionHandler } from "relay-runtime";
const { ConnectionHandler } = require("relay-runtime");

interface Props {
  songRef: CreateSection_song$key;
}

export default function CreateSection(props: Props) {
  const history = useHistory();
  const [errMsg, setErrMsg] = React.useState<string>("");

  // let [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  // this query is solely to for sake of suggesting section names
  const song = useFragment(
    graphql`
      fragment CreateSection_song on Song {
        id
        mongoVersion
        sections(first: 2147483647) @connection(key: "MoveSection_sections") {
          edges {
            node {
              id
              name
              notes
              chords {
                id
                duration
                rhythm
                definition {
                  root
                  qual
                  bass
                }
                notation
              }
            }
          }
        }
      }
    `,
    props.songRef
  );
  const { sections } = song;
  const sectionNodes = getNodeArrayFromEdges(sections).filter(typeNotEmpty);
  const sectionNames = sectionNodes.map((node) => node.name);
  // const suggestedName = suggestSectionName(sectionNames, sectionNames.length);

  const [commit, isInFlight] = useMutation<CreateSectionMutation>(graphql`
    mutation CreateSectionMutation(
      # Define GraphQL var for connections array
      $connections: [ID!]!
      $input: createSectionInput!
    ) {
      createSection(input: $input) {
        song {
          # ostensibly, want to query all visible song props here, so if client tries to create a section while it has outdated version of song, ensure they receive updated version of song; surely cleaner way of doing this than repeating self... use fragment spread? could force refetch fragment or parent query, too...
          id
          mongoVersion
          title
          artist
          sections(first: 2147483647) @connection(key: "MoveSection_sections") {
            edges {
              node {
                id
                name
                notes
                chords {
                  id
                  duration
                  rhythm
                  definition {
                    root
                    qual
                    bass
                  }
                  notation
                }
              }
            }
          }
        }
        sectionEdge @appendEdge(connections: $connections) {
          __typename
          cursor
          node {
            id
            name
            notes
            chords {
              id
              duration
              rhythm
              definition {
                root
                qual
                bass
              }
              notation
            }
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCreateSection(input: createSectionInput) {
    const connectionID = ConnectionHandler.getConnectionID(
      song.id,
      "MoveSection_sections"
    );
    commit({
      variables: {
        input,
        // *** at moment, testing both means of getting connectionID, and both work
        // connections: [props.connectionID],
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.createSection) {
          const { sectionEdge, errors } = response.createSection;
          if (sectionEdge && errors.length === 0) {
            history.push(`/section/${song.id}/${sectionEdge.node?.id}`);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (error) => {
        setErrMsg(error.message);
        console.error(error);
      },
    });
  }

  const valueSuggestions: InputValueSuggestion[] = sectionNameSuggestions
    .slice(0)
    // remove section names already in song;
    // .filter((val) => !sectionNames.includes(val))
    .sort((a, b) => {
      // sort names not in song first, followed by those that are - in order of their appearance;
      return sectionNames.indexOf(a) - sectionNames.indexOf(b);
    })
    .map((name) => {
      return {
        key: name,
        value: name,
        display: name,
      };
    });

  return (
    <>
      <TextInputModal
        // force re-render on change of suggestedName
        // key={suggestedName}
        textInputs={[
          {
            elementType: "suggest",
            id: "name",
            // label: "Section Name",
            label: "",
            required: true,
            // initialValue: suggestedName,
            initialValue: "",
            // had used "words" for autoCapitalize for title, artist, section name, and tags; however this overrode capslock in iOS, and otherwise is inconsistent with how most users enter text; "sentences" should solve both issues;
            autoCapitalize: "sentences",
            valueSuggestions: valueSuggestions,
            placeholder: "Section name",
          },
        ]}
        createLabel={"Section"}
        createOrUpdate="create"
        callback={(inputValues: Record<string, string>) => {
          handleCreateSection({
            songId: song.id,
            mongoVersion: song.mongoVersion,
            name: inputValues.name,
          });
        }}
        // modalLabel="Section Name"
        modalLabel="Create Section"
        buttonSize="sm"
        resetOnSubmit
        isInFlight={isInFlight}
        initialShow={sectionNodes.length === 0}
        // showFullHeader={true}
        // if want to force focus on text input on modal open, set doUseLeastDestructiveRef to false;
        doUseLeastDestructiveRef={false}
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />

      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
    </>
  );
}
