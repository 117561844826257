import React from "react";
import { matchSorter } from "match-sorter";
import styled from "styled-components/macro";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  // ComboboxOptionText,
  // returns isExpanded prop; could be useful e.g. for styling differently when Combobox is expanded: https://github.com/reach/reach-ui/pull/501;
  // useComboboxContext,
  // useComboboxOptionContext
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { textInputStyles } from "./TextInput";
import { useThrottle } from "../_hooks/useThrottle";

// docs: https://reach.tech/combobox
// implementation examples: https://github.com/reach/reach-ui/tree/main/packages/combobox/examples

// Issue you may run into again: you tried to use ComboboxPopover with portal={false}, to cause the list of options to display in flow, instead of absolute positioning (you tried this because you didn't want the options concealing your accept/cancel buttons; also, you liked the idea of options always displaying - at least for your "section name" suggestions); problems: 1) Reach Combobox isn't intended for "always display" so its default behavior isn't desirable (see: https://github.com/reach/reach-ui/issues/709); 2) because you were using Combobox within a Modal (also using Reach portal), when you clicked within the Combobox input, the display of the list options in flow would cause the modal body content to be larger, and if you then clicked out of the input - into the lower region of the modal - instead of merely exiting the input, the modal would also close as if you clicked outside the modal body content... upshot is that you *did* click outside the modal body content; clicking outside the input triggered focusout which caused the option list to disappear and *then* triggered the click action which - milliseconds later - was suddenly triggered on the modal backdrop instead of modal content; likely a passable workaround for this - e.g. removing the focusout event or preventing click event immediately following focusout - but it wouldn't have solved problem #1, so instead settled on foregoing ComboboxPopover and instead using a similarly-styled div; so far, no obvious drawback;

export function useMatch(
  valueSuggestions: InputValueSuggestion[],
  term: string
) {
  const throttledTerm = useThrottle(term, 100);
  return React.useMemo(
    () =>
      term.trim() === ""
        ? valueSuggestions
        : matchSorter(valueSuggestions, term, {
            // https://github.com/kentcdodds/match-sorter#keys-string
            // keys: [(item) => `${item.value}`],
            keys: ["value"],
            threshold: matchSorter.rankings.CONTAINS,
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [throttledTerm]
  );
}

export type InputValueSuggestion = {
  key: string;
  value: string;
  display: string;
};

const ComboboxListWithResults = ({
  results,
}: {
  results: InputValueSuggestion[];
}) => (
  <StyledComboboxList
    persistSelection={false}
    // ref={comboboxListRef}
  >
    {results.map((result, index) => (
      <StyledComboboxOption key={index} value={`${result.value}`} />
    ))}
  </StyledComboboxList>
);

export type Props = {
  valueSuggestions: InputValueSuggestion[];
  value: string;
  onChange: // | React.Dispatch<React.SetStateAction<string>>
  (value: string) => void;
  label: string;
  placeholder?: string;
  usePortal?: boolean;
  autoFocus?: boolean;
};

export function InputSuggest(props: Props) {
  // let comboboxInputRef = React.useRef<HTMLInputElement>(null);
  /* 
  let comboboxListRef = React.useRef<HTMLUListElement>(null);
   const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(event.type);
    if (!event.isDefaultPrevented()) {
      const container = comboboxListRef.current;
      if (!container) return;

      window.requestAnimationFrame(() => {
        const element: HTMLLIElement | null = container.querySelector(
          "[aria-selected=true]"
        );
        if (element) {
          const top = element.offsetTop - container.scrollTop;
          const bottom =
            container.scrollTop +
            container.clientHeight -
            (element.offsetTop + element.clientHeight);

          if (bottom < 0) container.scrollTop -= bottom;
          if (top < 0) container.scrollTop += top;
        }
      });
    }
  }; */
  var results = useMatch(props.valueSuggestions, props.value);
  // default behavior: dropdown will still show after making a selection (or typing the exact text of a suggestion), meaning only one item will show in dropdown... an item identical to the value of the input; filter to remove;
  results = results.filter(
    (res) => res.value.toLowerCase() !== props.value.toLowerCase()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange(e.target.value);

  const handleSelect = (value: string) => {
    props.onChange(value);
  };

  /*   React.useEffect(() => {
    // focus text input on mount;
    ref.current?.focus();
  }, []); */

  return (
    <StyledCombobox
      openOnFocus={true}
      onSelect={handleSelect}
      aria-label={props.label}
    >
      <StyledComboboxInput
        // ref={comboboxInputRef}
        value={props.value}
        onChange={handleChange}
        autocomplete={true}
        autoFocus={props.autoFocus ?? false}
        placeholder={props.placeholder}
        // React doesn't use "focusin" or "focusout" events;
        // onBlur={() => null}
        // use to cause list of options scroll with keyboard (arrows): https://github.com/reach/reach-ui/issues/357;
        // onKeyDown={onKeyDown}
      />
      {props.usePortal ? (
        <StyledComboboxPopover portal={true}>
          <ComboboxListWithResults results={results} />
        </StyledComboboxPopover>
      ) : (
        <StyledDivReplacement>
          <ComboboxListWithResults results={results} />
        </StyledDivReplacement>
      )}
    </StyledCombobox>
  );
}

const StyledCombobox = styled(Combobox)``;

const StyledDivReplacement = styled.div`
  border: 1px dotted var(--color-text-translucent);
  border-top: none;
  border-bottom: none;
  /* margin-bottom: 8px; */

  &[data-reach-combobox-popover] {
    border: none;
    box-shadow: var(--shadow-elevation-direct-low);
  }
`;

const StyledComboboxPopover = styled(ComboboxPopover)`
  border: 1px dotted var(--color-text-translucent);
  border-top: none;
  border-bottom: none;

  &[data-reach-combobox-popover] {
    border: none;
    box-shadow: var(--shadow-elevation-direct-low);
  }
`;

const StyledComboboxList = styled(ComboboxList)`
  /* if want to limit height of list, and make scrollable, use with above code to cause list of options scroll with keyboard (arrows): https://github.com/reach/reach-ui/issues/357; */
  /*   max-height: 150px;
  overflow: auto; */

  /*
  > [data-reach-combobox-option][data-highlighted] {
    background-color: red;
  } 
  */
`;

const StyledComboboxOption = styled(ComboboxOption)`
  /* overflow-wrap: anywhere; */
  color: var(--color-text);
  background-color: var(--color-background-translucent-60);
  transition: background 0.2s;
  font-size: ${16 / 16}rem;
  padding: ${8 / 16}rem ${8 / 16}rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(odd) {
    background-color: var(--color-background-tint-translucent-75);
  }
  &:hover {
    background-color: var(--color-text-translucent);
  }
  border-bottom: 1px dotted var(--color-text-translucent);

  &[data-highlighted] {
    background-color: var(--color-highlight-light);
  }

  > [data-reach-combobox-option-text][data-suggested-value] {
    /* font-weight: 500; */
    font-weight: 400;
  }
`;
const StyledComboboxInput = styled(ComboboxInput)`
  ${textInputStyles}
  /* padding: 0px; */
  font-size: ${16 / 16}rem;
`;

/* 
{results.length > 0 ? (
  <StyledComboboxList persistSelection={false}>
    {results.map((result, index) => (
      <StyledComboboxOption key={index} value={`${result.value}`} />
    ))}
  </StyledComboboxList>
) :   (
    <span style={{ display: "block", margin: 8 }}>
      No results found
      <button
        onClick={() => {
          props.onChange("");
          // @ts-ignore
          ref.current.focus();
        }}
      >
        clear
      </button>
    </span>
  ) 
null} */
