import { format } from "date-fns";

// https://date-fns.org/v2.28.0/docs/format

export function formatSubscriptionDate(dateString: string) {
  return format(
    new Date(dateString),
    process.env.REACT_APP_API_URL_PROD ===
      process.env.REACT_APP_API_URL_PROD_TEST
      ? "yyyy-MM-dd HH:mm:ss"
      : "MMM do, yyyy"
  );
}

export function formatSongLockDate(dateString: string) {
  return format(new Date(dateString), "HH:mm");
}
