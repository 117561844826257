/**
 * @generated SignedSource<<9bb2c3407dc51e145707544b2416dc0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
export type SongQueryQuery$variables = {
  id: string;
};
export type SongQueryQuery$data = {
  readonly song: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NotFound";
      readonly message: string;
    }>;
    readonly song: {
      readonly artist: string;
      readonly id: string;
      readonly mongoVersion: number;
      readonly notes: string;
      readonly tags: ReadonlyArray<string>;
      readonly timeSig: TimeSig;
      readonly title: string;
      readonly viewerPermission: {
        readonly canEdit: boolean;
        readonly didAccept: boolean;
        readonly email: string;
        readonly isOwner: boolean;
        readonly user: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"SongBody_song" | "SongManageShareSharee_song" | "UpdateSongTimeSig_song">;
    } | null;
  } | null;
};
export type SongQueryQuery = {
  response: SongQueryQuery$data;
  variables: SongQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoVersion",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artist",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSig",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SongPermission",
  "kind": "LinkedField",
  "name": "viewerPermission",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "NotFound",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongTimeSig_song"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SongBody_song"
              },
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SongManageShareSharee_song"
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SongQueryResult",
        "kind": "LinkedField",
        "name": "song",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "SectionConnection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SongSection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rhythmUnit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Chord",
                            "kind": "LinkedField",
                            "name": "chords",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "duration",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rhythm",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ChordDef",
                                "kind": "LinkedField",
                                "name": "definition",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "root",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "qual",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bass",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "notation",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "sections(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MoveSection_sections",
                "kind": "LinkedHandle",
                "name": "sections"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SongPermission",
                "kind": "LinkedField",
                "name": "permissions",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1353c10febd66efe1d563264f055af88",
    "id": null,
    "metadata": {},
    "name": "SongQueryQuery",
    "operationKind": "query",
    "text": "query SongQueryQuery(\n  $id: ID!\n) {\n  song(id: $id) {\n    song {\n      id\n      mongoVersion\n      title\n      artist\n      notes\n      tags\n      timeSig\n      ...UpdateSongTimeSig_song\n      ...SongBody_song\n      viewerPermission {\n        user\n        email\n        isOwner\n        canEdit\n        didAccept\n      }\n      ...SongManageShareSharee_song\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment DisplaySection_section on SongSection {\n  id\n  name\n  notes\n  timeSig\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n}\n\nfragment DisplaySection_song on Song {\n  id\n  timeSig\n  viewerPermission {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n\nfragment SongBody_song on Song {\n  ...DisplaySection_song\n  sections(first: 2147483647) {\n    edges {\n      node {\n        ...DisplaySection_section\n        id\n        name\n        notes\n        timeSig\n        chords {\n          id\n          duration\n          rhythm\n          definition {\n            root\n            qual\n            bass\n          }\n          notation\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SongManageShareSharee_song on Song {\n  ...SongRejectShare_song\n  id\n  permissions {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n  viewerPermission {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n\nfragment SongRejectShare_song on Song {\n  id\n  title\n  viewerPermission {\n    user\n    email\n    isOwner\n    canEdit\n    didAccept\n  }\n}\n\nfragment UpdateSongTimeSig_song on Song {\n  id\n  mongoVersion\n  timeSig\n}\n"
  }
};
})();

(node as any).hash = "02eefaf3b624169ef1cd648aeb1b0c12";

export default node;
