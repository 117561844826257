import React from "react";

import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers/appReducer";

type Props = {
  scrollEl: HTMLDivElement | HTMLElement | null;
  scrollId: string;
  restoreDependencyArr: (string | number | React.RefObject<HTMLDivElement>)[];
  doRestore?: boolean;
  // axis?: "vert" | "horiz";
  // duration?: number;
};

export function RestoreScroll({
  scrollEl,
  scrollId,
  restoreDependencyArr,
  doRestore = false,
}: // axis = "vert",
// duration = 200,
Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const { scrollPosMap } = state.app;

  React.useLayoutEffect(() => {
    // console.log("RestoreScroll", scrollId, scrollEl);

    // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
    if (scrollEl && doRestore) {
      let scrollTop = scrollPosMap.get(scrollId) ?? 0;

      if ((scrollTop || scrollTop === 0) && scrollEl) {
        scrollEl.scrollTop = scrollTop;
      }
      // console.log("Restoring:", scrollTop);

      return () => {
        const currentScrollTop = scrollEl?.scrollTop;

        if (currentScrollTop || currentScrollTop === 0) {
          // console.log("Setting:", currentScrollTop);
          dispatch({
            type: AppActionTypes.SetSongListScroll,
            payload: { key: scrollId, val: currentScrollTop },
          });
        }
      };
    }
  }, [
    ...restoreDependencyArr,
    // include scrollEl in dependency array, as it will be null initially; need ref to be populated to act on it;
    scrollEl,
  ]);

  return null;
}
