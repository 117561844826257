import React, { FormEvent, useState, HTMLInputTypeAttribute } from "react";
import styled from "styled-components/macro";

import { Modal } from "./Modal";
import Button, { ButtonExpand, Palette, ButtonSize } from "./Button";
import { Icon, icons } from "./Icon";

import { HiddenSubmitButton } from "./HiddenSubmitButton";
import { TextArea } from "./TextInput";
import { AutoCapitalize, TextInputIcon } from "./TextInputIcon";
import { ellipsizeText } from "../_utilities/strings";
import Spacer from "./Spacer";
import { LoadingSpinnerComplete } from "./LoadingSpinner";
import { InputSuggest, InputValueSuggestion } from "./InputSuggest";

/* To do:
-[maybe future]: allow additional validation options; warning for invalid input (in addition to "ok" button disabled)
--- could take as props Yup validation schema
-improve typing: add overloads to fnc component so that, e.g. if createOrUpdate==="create", createLabel is required
*/

type TextInputProps = {
  elementType?: "input" | "textarea";
  type?: HTMLInputTypeAttribute;
  id: string;
  initialValue?: string;
  label: string;
  required?: boolean;
  autoCapitalize?: AutoCapitalize;
  placeholder?: string;
};

type InputSuggestEnhancedProps = Omit<TextInputProps, "elementType"> & {
  elementType: "suggest";
  valueSuggestions: InputValueSuggestion[];
};

type TextInputState = Record<string, string>;

type Props = {
  textInputs: (TextInputProps | InputSuggestEnhancedProps)[];
  modalLabel?: string;
  modalMessage?: string | React.ReactNode;
  modalHeaderIcon?: { id: keyof typeof icons; color: string };
  createLabel?: string | React.ReactNode;
  createIconId?: keyof typeof icons;
  callback: (inputState: TextInputState) => void;
  initialShow?: boolean;
  resetOnSubmit?: boolean;
  createOrUpdate?: "create" | "update";
  expand?: ButtonExpand;
  actionText?: string;
  isInFlight?: boolean;
  buttonSize?: ButtonSize;
  palette?: Palette;
  actionColor?: Palette;
  doUseLeastDestructiveRef?: boolean;
  showFullHeader?: boolean;
};

export function TextInputModal({
  textInputs,
  modalLabel,
  modalHeaderIcon,
  modalMessage,
  createLabel,
  createIconId = "plus",
  callback,
  initialShow = false,
  resetOnSubmit = true,
  createOrUpdate = "update",
  expand = "block",
  actionText = createOrUpdate === "update" ? "Save" : "Add",
  isInFlight,
  buttonSize = "md",
  palette,
  actionColor,
  doUseLeastDestructiveRef = false,
  showFullHeader,
}: Props) {
  const [doShowDialog, setDoShowDialog] = useState<boolean>(initialShow);
  function getInitialValueMap() {
    return textInputs.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr.initialValue || "",
      }),
      {}
    );
  }

  const [inputValues, setInputValues] = useState<TextInputState>(
    getInitialValueMap()
  );

  function openDialog() {
    // useful to reset state vals with props here, in case they've changed; e.g. user1 is looking at SongMeta page; user2 updates song notes; user1 does a pull-to-refresh, which will show a change to notes; if user1 then clicks on notes and opens input modal, they will see contents of input are stale, reflecting prior state, b/c this component's state "protects" that value from props changes; so, when open that modal, reset state from props to ensure they are up-to-date;
    resetInitialValues();
    setDoShowDialog(true);
  }

  function closeDialog() {
    setDoShowDialog(false);
  }

  function resetInitialValues() {
    const valueMap = getInitialValueMap();
    setInputValues(valueMap);
  }

  function isInputValid() {
    for (let input of textInputs) {
      if (
        input.required &&
        (!inputValues[input.id] || inputValues[input.id].trim() === "")
      ) {
        return false;
      }
    }
    return true;
  }

  function handleConfirm() {
    if (!isInputValid()) {
      // show warning
      //
      return;
    }
    callback(inputValues);
    closeDialog();
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    handleConfirm();
  }

  /*   React.useEffect(() => {
    // *** don't recall why I did this; perhaps was necessary previously to ensure inputs updated their states following submission, but doesn't appear to be now, and generally bad practice to let re-renders reset your state (which is what happens here if parent component re-renders)
    resetInitialValues();
    // eslint-disable react-hooks/exhaustive-deps
  }, [textInputs]); */

  // fires at end of modal close animation, whether due to "submit" or "cancel" of modal
  function handleOnModalClose() {}

  // event that triggers at end of modal close animation *only* when modal was "canceled", otherwise conflicts with the useEffect that sets new input state values after submission
  function handleOnModalCancel() {
    resetOnSubmit && resetInitialValues();
  }

  return (
    <>
      {createOrUpdate === "update" ? (
        // update text fields attached to entity
        <>
          {textInputs.map((input) => (
            <Button
              key={input.id}
              onClick={openDialog}
              expand={expand}
              fill="clear"
              size={buttonSize}
              palette={palette}
            >
              <HeaderTitle>
                {input.label}
                <Spacer size={8} axis={"horiz"} />
                <HeaderTitleIcon>
                  <Icon id={"edit"} size={14} />
                </HeaderTitleIcon>
              </HeaderTitle>
              <EntryControlWrapper>
                {/*   <IconWrapper>
                  <Icon id={"edit"} size={20} />
                </IconWrapper>
                <Spacer size={8} axis={"horiz"} /> */}
                <LabelWrapper>
                  {input.elementType === "textarea" ? (
                    <InitialValueTextArea>
                      {/* {ellipsizeText(input.initialValue || "-", 128)} */}
                      {input.initialValue}
                    </InitialValueTextArea>
                  ) : (
                    <InitialValue>{input.initialValue}</InitialValue>
                  )}
                  {/* <InitialValueLabel>{input.label}</InitialValueLabel> */}
                </LabelWrapper>
              </EntryControlWrapper>
            </Button>
          ))}
        </>
      ) : (
        // create entity using text fields
        <Button
          onClick={openDialog}
          expand={expand}
          fill="clear"
          size={buttonSize}
          palette={palette}
          // *** you later added the following functionality to Button, which largely supplants the custom job below; only problem I spot at a glance is for the "add tag" button, which uses only an icon; using the below Button props instead of the custom job makes the add tags button too wide and off-center;
          // iconId={createIconId}
          // isInFlight={isInFlight}
        >
          <EntryControlWrapperCreate>
            <CreateIconWrapper>
              {isInFlight ? (
                <LoadingSpinnerComplete width={24} />
              ) : (
                <Icon
                  id={createIconId}
                  // size={createIconId === "plus" ? 28 : 24}
                  size={24}
                  strokeWidth={2}
                />
              )}
            </CreateIconWrapper>
            {createLabel && (
              <>
                <Spacer
                  size={buttonSize === "xs" || buttonSize === "sm" ? 6 : 16}
                  axis={"horiz"}
                />
                <LabelWrapper>{createLabel}</LabelWrapper>
              </>
            )}
          </EntryControlWrapperCreate>
        </Button>
      )}
      <Modal
        doShowDialog={doShowDialog}
        closeDialog={closeDialog}
        handleConfirm={handleConfirm}
        label={modalLabel}
        headerIcon={modalHeaderIcon}
        message={modalMessage}
        handleOnModalCancel={handleOnModalCancel}
        actionText={actionText}
        actionColor={actionColor}
        isConfirmDisabled={!isInputValid()}
        // use doUseLeastDestructiveRef to put initial focus on "cancel" button; don't use, and focus will be on first input
        doUseLeastDestructiveRef={doUseLeastDestructiveRef}
        showCloseButton={true}
        showFullHeader={showFullHeader}
      >
        <Form onSubmit={handleSubmit}>
          {textInputs.map((input, i) => (
            <InputLabel key={input.id}>
              {input.elementType === "textarea" && (
                <TextArea
                  // as={"textarea"}
                  rows={6}
                  value={inputValues[input.id]}
                  autoCapitalize={input.autoCapitalize}
                  // *** previously only typed for HTMLTextAreaElement... no err on Ubuntu machines; error on Mac... perhaps only b/c I cleared node_modules and packagelock, so I *should* have received TS error before?
                  onChange={(
                    e:
                      | React.ChangeEvent<HTMLTextAreaElement>
                      | React.ChangeEvent<HTMLInputElement>
                  ) =>
                    setInputValues((state) => ({
                      ...state,
                      [input.id]: e.target.value,
                    }))
                  }
                />
              )}
              {input.elementType === "input" ||
                (input.elementType === undefined && (
                  <TextInputIcon
                    type={input.type}
                    value={inputValues[input.id]}
                    autoCapitalize={input.autoCapitalize}
                    // give default autoFocus to first input
                    autoFocus={i === 0}
                    mode="border"
                    onChange={(value) =>
                      setInputValues((state) => ({
                        ...state,
                        [input.id]: value,
                      }))
                    }
                  />
                ))}
              {input.elementType === "suggest" && (
                <InputSuggest
                  valueSuggestions={input.valueSuggestions}
                  value={inputValues[input.id]}
                  onChange={(value) =>
                    setInputValues((state) => ({
                      ...state,
                      [input.id]: value,
                    }))
                  }
                  label={input.label}
                  placeholder={input.placeholder}
                  // give default autoFocus to first input
                  autoFocus={i === 0}
                />
              )}
              <InputName>{input.label}</InputName>
            </InputLabel>
          ))}
          <HiddenSubmitButton disabled={false} />
        </Form>
      </Modal>
    </>
  );
}

const EntryControlWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const EntryControlWrapperCreate = styled(EntryControlWrapper)`
  align-items: center;
`;

const IconWrapper = styled.div`
  padding-top: 4px;
  width: 24px;
`;

const CreateIconWrapper = styled(IconWrapper)`
  padding-top: 0px;
`;

const LabelWrapper = styled.div`
  flex: 1;
  text-align: left;
`;

const InitialValue = styled.span`
  font-size: ${18 / 16}rem;
  color: var(--color-primary);
`;

const InitialValueTextArea = styled(InitialValue)`
  font-size: ${16 / 16}rem;
  /* preserve line breaks "\n" and tabs "\t" in user-entered text */
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
`;

const InitialValueLabel = styled.div`
  font-size: ${13 / 16}rem;
  color: var(--color-text-medium);
`;

const Form = styled.form`
  /* padding-top: 20px; */
  /* padding-bottom: 8px; */
`;
const InputLabel = styled.label`
  display: block;
  padding: 8px;
`;
const InputName = styled.span`
  padding: 4px 8px;
  color: var(--color-text-medium);
`;

const HeaderTitle = styled.div`
  font-size: ${14 / 16}rem;
  display: flex;
  align-items: center;
  color: var(--color-text-dark);
  text-transform: uppercase;
  font-weight: 500;
`;

const HeaderTitleIcon = styled.div`
  margin-top: -3px;
`;
