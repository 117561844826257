/**
 * @generated SignedSource<<58694800034a6e052ea093b28d0657f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TestEventType = "FORGOT" | "REMEMBERED" | "REVIEWED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddSongTestEvent_user$data = {
  readonly id: string;
  readonly testHistory: ReadonlyArray<{
    readonly eventType: TestEventType;
    readonly song: string;
    readonly time: string;
  }>;
  readonly " $fragmentType": "AddSongTestEvent_user";
};
export type AddSongTestEvent_user$key = {
  readonly " $data"?: AddSongTestEvent_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddSongTestEvent_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddSongTestEvent_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTestEvent",
      "kind": "LinkedField",
      "name": "testHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "song",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a67e42b0bbaae944d31490acfde41935";

export default node;
