/**
 * @generated SignedSource<<40a03cd4bd01a246f14a5f2c2e9e3c77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSongsList_songs$data = {
  readonly songs: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly artist: string;
        readonly id: string;
        readonly permissions: ReadonlyArray<{
          readonly canEdit: boolean;
          readonly didAccept: boolean;
          readonly email: string;
          readonly isOwner: boolean;
          readonly user: string;
        }>;
        readonly tags: ReadonlyArray<string>;
        readonly title: string;
        readonly viewerPermission: {
          readonly canEdit: boolean;
          readonly didAccept: boolean;
          readonly email: string;
          readonly isOwner: boolean;
          readonly user: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeleteSong_song" | "ShareSongPDF_song" | "SongListItem_song" | "SongManageShareSharee_song" | "SongManageShare_song">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "UserSongsList_songs";
};
export type UserSongsList_songs$key = {
  readonly " $data"?: UserSongsList_songs$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSongsList_songs">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "user",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOwner",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canEdit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "didAccept",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "songs"
        ]
      }
    ]
  },
  "name": "UserSongsList_songs",
  "selections": [
    {
      "alias": "songs",
      "args": null,
      "concreteType": "UserSongsConnection",
      "kind": "LinkedField",
      "name": "__UserSongs_songs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSongsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Song",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SongListItem_song"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SongManageShare_song"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SongManageShareSharee_song"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DeleteSong_song"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ShareSongPDF_song"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "artist",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tags",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SongPermission",
                  "kind": "LinkedField",
                  "name": "permissions",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SongPermission",
                  "kind": "LinkedField",
                  "name": "viewerPermission",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "5462a41084d804cb1e3c51ed7e367cba";

export default node;
