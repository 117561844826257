import styled from "styled-components/macro";

// retrieve scroll position from content: if scrolled to top, no box shadow

export const HeaderCore = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* ensure sits over positioned body content including elements with opacity < 1.0, e.g. Button in :active state */
  z-index: 1;

  /* matching safe areas in header and content components work in conjunction */
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  /* needed e.g. when in landscape view */
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /* if backdrop-filter not supported: mostly opaque */
  background-color: var(--color-background-translucent-96);
  /* if backdrop-filter supported: more transparent and blurred */
  @supports (
    (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px))
  ) {
    background-color: var(--color-background-translucent-75);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  box-shadow: var(--shadow-elevation-low);
`;
