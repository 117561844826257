import React from "react";
import styled from "styled-components/macro";

import { useGKeyboardTouch } from "./useGKeyboardTouch";
import { GKey } from "./GKey";
import type { RowPositions } from "./GKey";

import { MEDIA_QUERIES } from "../../_constants";
import { GKEY_HANDOFF_ID } from "./useGKeyboardTouch.helpers";

type CheckBoxItem = {
  label: string | React.ReactNode;
  value: string;
};

type Props = {
  groupId: string;
  items: CheckBoxItem[];
  checkedItems: readonly string[];
  manualChange: (value: string) => void;
  // emphasis may be useful if want to highlight keys related to the active key
  emphasizeWhen?: (hoverTarget: string, value: string) => boolean;
  altKeyColor?: boolean;
  columns?: number;
  label?: string;
};

export const GKeyboard = ({
  groupId,
  label,
  items,
  checkedItems,
  manualChange,
  emphasizeWhen,
  columns = 4,
  altKeyColor,
}: Props) => {
  const { touchRef, hoverTarget } = useGKeyboardTouch({
    groupId,
    handleSelect: (name) => manualChange(name),
    // checkedItems,
  });

  return (
    <Wrapper>
      <Keys
        // ref={ref}
        ref={touchRef}
        style={
          {
            "--columns": `${columns}`,
          } as React.CSSProperties
        }
      >
        {items.map((item, index) => {
          const { value } = item;
          var rowPosition: RowPositions = "middle";
          // based on grid column count, determine row position of child
          if (index % columns === 0) {
            // left end
            rowPosition = "left";
          } else if (index % columns === columns - 1) {
            // right end
            rowPosition = "right";
          } else {
            // somwhere in the middle
          }

          return (
            <GKey
              // in one iteration, used empty checkboxes to occupy space until a root letter was selected (then showed accidentals in checkboxes flanking that letter); for those need to use index in addition to value
              key={value + index}
              label={item.label}
              value={value}
              isChecked={checkedItems.indexOf(value) > -1}
              isHovered={hoverTarget === value}
              isEmph={emphasizeWhen && emphasizeWhen(hoverTarget, value)}
              isDisabled={value.trim() === ""}
              rowPosition={rowPosition}
              groupId={groupId}
              gKeyboardHandoffKey={GKEY_HANDOFF_ID}
              altKeyColor={altKeyColor}
            />
          );
        })}
      </Keys>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* padding: 4px; */
  /* not sure why I started with 100% height, but don't like it for new use within AddDelete (trying to include it in same parent as other components), and see no consequence elsewhere of removing it */
  /* height: 100%; */
  /* width: 100%; */
  user-select: none;
  -webkit-user-select: none;
  background-color: var(--g-keyboard-bg);
  /* good practice to add isolation: isolate to parent element of components that use z-index, to create a stacking context and avoid those elements using z-index from layering with elements of other components that use z-index  */
  isolation: isolate;
`;
const Label = styled.div`
  padding-top: 16px;
  text-transform: capitalize;
  font-size: ${13 / 16}rem;
  color: var(--color-text-medium);
`;
const Keys = styled.div`
  /* height: 100%; */
  /* beware overflow caused by grid with fixed number of columns (and with each grid item having a min or fixed width) */
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: 4px 4px;
  place-items: center;
  @media ${MEDIA_QUERIES.lgPhoneAndUp} {
    gap: 8px 8px;
    padding: 0 8px;
  }
`;

/* 
consider tidying and hosting on github... and consider combining with tabs (e.g. your chord quals) as an optional feature of the component (tabbed keyboard... nested tabs); same with your slider... 

still possible for bug to occur if activate checkbox elsewhere in app, and end up returning its values through this component... should check for specific parent component or class, or add custom attribute 

 Dim border if disabled; if selected (only for chord selection), "blend in" with left/right neighbors
could create a "pop up" like with duration slider to allow to see better what you are hovering
discard circles, go for something more open - make them closer together... underline/border all of same letter, highlight selected; perhaps some ghost indicator on unavailable sharp/flats
*/

// *** prob should create a new [HOC?] on top of CheckboxGroup to handle theese cases: 1 choice only, allow hover interact
// *** also b/c it's rather uncomfortable having onChange and manualChange co-exist... React doesn't like it either as it blurs the line between controlled and uncontrolled component
// *** should use overloads to ensure receive one of 1) onChange, 2) manunalChange
