import React, { useState } from "react";
import styled from "styled-components/macro";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

import {
  MoveSection_song$data,
  MoveSection_song$key,
} from "../../__generated__/MoveSection_song.graphql";
import {
  moveSectionInput,
  MoveSectionMutation,
} from "../../__generated__/MoveSectionMutation.graphql";
import { typeNotEmpty } from "../../_types/typeHelpers";
import { isApiError } from "../../_types/errorTypes";
import { StringObj } from "../../_types";
import { getNodeArrayFromEdges } from "../../_types/graphqlData";

import { LoadingSpinnerComplete } from "../../_components";
import Button from "../../_components/Button";
import { Icon } from "../../_components/Icon";
import { SectionList } from "./SectionList";
import { hapticsImpactLight } from "../../_plugins/Haptics";
import { SectionListItem, SECTION_LIST_ITEM_HEIGHT } from "./SectionListItem";
import { moveArrItem } from "../../_utilities/arrays";
import { ModalError } from "../../_components/ModalError";

// *** would perhaps be cleaner to take all drag-n-drop functionality and move it inside SectionList

type Props = {
  songRef: MoveSection_song$key;
};

export function MoveSection(props: Props) {
  const song = useFragment(
    graphql`
      fragment MoveSection_song on Song {
        ...SectionList_song
        id
        mongoVersion
        sections(first: 2147483647) @connection(key: "MoveSection_sections") {
          edges {
            node {
              id
              name
              notes
              chords {
                id
                duration
                rhythm
                definition {
                  root
                  qual
                  bass
                }
                notation
              }
            }
          }
        }
      }
    `,
    props.songRef
  );

  const [errMsg, setErrMsg] = React.useState<string>("");
  // newer version of DndKit prefers UniqueIdentifier over string as type for activeId, but string still acceptable (as long as coerce it to string with "as");
  const [activeId, setActiveId] = useState<string | null>(null);
  const [doAllowReorder, setDoAllowReorder] = useState<boolean>(false);

  function toggleDoSort() {
    setDoAllowReorder((state) => !state);
  }

  const [commit, isInFlight] = useMutation<MoveSectionMutation>(graphql`
    mutation MoveSectionMutation($input: moveSectionInput!) {
      moveSection(input: $input) {
        song {
          # spread same fragment used by this component, as it comprises the same data you want to update with the mutation response
          ...MoveSection_song
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleMoveSection(input: moveSectionInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(
        input.sectionId,
        input.mongoVersion,
        input.toRank,
        song.sections
      ),
      onCompleted: (response) => {
        if (response.moveSection) {
          const { errors } = response.moveSection;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  function getOptimisticResponse(
    sectionId: string,
    mongoVersion: number,
    toRank: number,
    sections: MoveSection_song$data["sections"]
  ) {
    var sectionNodes =
      sections && sections.edges
        ? sections.edges.map((edge) => edge?.node).filter(typeNotEmpty)
        : [];
    const targetSectionIndex = sectionNodes.findIndex(
      (val) => val.id === sectionId
    );
    const targetSection = sectionNodes.find((val) => val.id === sectionId);
    // const targetSection = sectionNodes.find((val) => val.id === sectionId);
    if (!targetSection) return;

    // option #1: work with the nodes, then rebuild structure;
    const newSectionNodes = moveArrItem(
      sectionNodes,
      targetSectionIndex,
      toRank
    );
    // option #2: work with the edges, to preserve prior cursor vals (but keep in mind this isn't necessarily better, as the cursors could be invalid if carried forward to mutated data;
    // const edgesClone = sections?.edges?.slice(0);
    // if (!edgesClone) return null;
    // const newEdges = moveArrItem(edgesClone, targetSectionIndex, toRank);

    // *** shifted from using a "rank" prop on each section, to relying on array order (on the server), and thus "list" or "connection" order (for relay/graphql); however, struggled to get relay to "see" this reordering; when rank changed, relay saw that the rank prop on sections changed, and updated the store entry for each affected section; then my render code, using array sort, put them in the correct position; when all that changes is the order, if only return those section nodes, there is no change to be updated; however, spreading the same section fragment (used in the query) into the mutation, it does refresh that connection and get the new order;
    // *** the remaining difficulty was optimisticUpdate; previously, b/c you returned a simple list of sections with only their id and rank, it was easy to model; when returning this spread value, it is the SongSections connection to Song, thus it involves edges, nodes, cursors, and pageInfo (even though you don't query for cursors or pageInfo, they still appear in the returned connection data and thus must be included in the optimistic update); for your purposes, at least, it seems you can fake the cursor and pageInfo without consequence; you did it two ways: 1) using prior edges, 2) using prior nodes
    return {
      moveSection: {
        song: {
          id: song.id,
          mongoVersion: mongoVersion + 1,
          sections: {
            // edges: newEdges,
            edges: newSectionNodes.map((val) => ({
              node: val,
              cursor: null,
            })),
            pageInfo: null,
          },
        },
        errors: [],
      },
    };
  }

  const sectionNodes = getNodeArrayFromEdges(song.sections).filter(
    typeNotEmpty
  );
  const sectionNodeIds = sectionNodes.map((val) => val.id);
  const sectionsObj: StringObj = sectionNodes.reduce((agg, curr) => {
    return {
      ...agg,
      [curr.id]: curr,
    };
  }, {});

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function stopPropagationIfSorting(e: React.TouchEvent<HTMLDivElement>) {
    // prevent drags here from activating pull-to-refresh function built into Page component;
    if (doAllowReorder) {
      e.stopPropagation();
    }
  }

  return (
    <>
      <Wrapper onTouchStart={stopPropagationIfSorting}>
        <HeaderWrapper>
          <HeaderTitle>
            Sections
            {isInFlight && (
              <SpinnerWrapper>
                <LoadingSpinnerComplete width={14} />
              </SpinnerWrapper>
            )}
          </HeaderTitle>
          {sectionNodes.length > 1 && (
            <HeaderButton>
              <ComposedButton onClick={toggleDoSort} fill="clear" size="sm">
                {doAllowReorder ? (
                  <Icon id={"check"} size={24} strokeWidth={2} />
                ) : (
                  <>
                    <IconMarginWrapperLeft>
                      <Icon id={"arrow-up"} size={18} strokeWidth={2} />
                    </IconMarginWrapperLeft>
                    <IconMarginWrapperRight>
                      <Icon id={"arrow-down"} size={18} strokeWidth={2} />
                    </IconMarginWrapperRight>
                  </>
                )}
              </ComposedButton>
            </HeaderButton>
          )}
        </HeaderWrapper>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext
            items={sectionNodeIds}
            strategy={verticalListSortingStrategy}
          >
            <SectionList
              songRef={song}
              doAllowReorder={doAllowReorder}
              activeId={activeId}
            />
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <SectionListItem
                songId={song.id}
                id={activeId}
                sectionName={sectionsObj[activeId].name}
                doAllowReorder={true}
                // when dragging, there will two "versions" of the item you're dragging: the "DragOverlay" item, which "hovers", adhered to your point of touch; and the "normal, glued-to-the-list" item --- these styles will affect the former, "hovering" item whereas styles passed via SortableItem will affect the latter, "glued" item
                style={
                  {
                    // want to give the draggable item some background color/border so - when dragging - doesn't appear to be merely floating text
                    backgroundColor: "var(--color-background)",
                    boxShadow: "0px 2px 4px var(--color-shadow)",
                  } as React.CSSProperties
                }
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Wrapper>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveId(active.id as string);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      hapticsImpactLight();
      handleMoveSection({
        songId: song.id,
        sectionId: active.id as string,
        mongoVersion: song.mongoVersion,
        toRank: sectionNodeIds.indexOf(
          over.id as string
        ) /*  sectionsObj[over.id].rank */,
      });
    }

    setActiveId(null);
  }
}

const Wrapper = styled.div``;

export const HeaderWrapper = styled.div`
  min-height: ${32 / 16}rem;
  display: flex;
  align-items: flex-end;
`;

export const HeaderTitle = styled.div`
  padding: 0px 16px;
  flex: 1;
  font-size: ${15 / 16}rem;
  /* text-align: left; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  color: var(--color-text-medium);
  text-transform: uppercase;
  font-weight: 500;
`;
export const SpinnerWrapper = styled.div`
  width: ${16 / 16}rem;
  margin-left: ${8 / 16}rem;
`;
const HeaderButton = styled.div`
  height: 100%;
  display: flex;
  flex: 0 0 ${SECTION_LIST_ITEM_HEIGHT / 16}rem;
`;
const ComposedButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconMarginWrapper = styled.div`
  --margin-incr: -4px;
`;
const IconMarginWrapperLeft = styled(IconMarginWrapper)`
  margin-top: var(--margin-incr);
  margin-right: var(--margin-incr);
`;
const IconMarginWrapperRight = styled(IconMarginWrapper)`
  margin-bottom: var(--margin-incr);
  margin-left: var(--margin-incr);
`;

/*   const [commit, isInFlight] = useMutation<MoveSectionMutation>(graphql`
    mutation MoveSectionMutation($input: moveSectionInput!) {
      moveSection(input: $input) {
        sections {
          id
        }
        errors {
          __typename
          message
        }
      }
    }
  `); */
/*   const [commit, isInFlight] = useMutation<MoveSectionMutation>(graphql`
    mutation MoveSectionMutation($input: moveSectionInput!) {
      moveSection(input: $input) {
        song {
          id
          sections {
            edges {
              node {
                id
              }
            }
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `); */

/* 
    function moveItemShiftRanks() {
      if (targetSection.rank === toRank) return;
      if (targetSection.rank < toRank) {
        // this code is repeated in following else block, but was cleanest typesafe way to do it... otherwise ran into issues with readonly props/constant var for targetSection
        sectionNodes = sectionNodes.map((val) => {
          if (val.id === sectionId) {
            return {
              ...val,
              rank: toRank,
            };
          }
          if (targetSection.rank < val.rank && val.rank <= toRank) {
            // shift down
            return {
              ...val,
              rank: val.rank - 1,
            };
          } else return val;
        });
      } else {
        sectionNodes = sectionNodes.map((val) => {
          if (val.id === sectionId) {
            return {
              ...val,
              rank: toRank,
            };
          }
          if (targetSection.rank > val.rank && val.rank >= toRank) {
            // shift up
            return {
              ...val,
              rank: val.rank + 1,
            };
          } else return val;
        });
      }
    }
    const stripped = sectionNodes.map((val) => ({
      id: val.id,
      rank: val.rank,
    }));
    return {
      moveSection: {
        sections: stripped,
        errors: [],
      },
    };
    */
