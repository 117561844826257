import React from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";

import {
  Page,
  PageHeader,
  PageContent,
  PageContext,
} from "../_components/Page";
import { UserSongsQuery } from "./UserSongsQuery";
import { CreateSong } from "./CreateSong";
import { TextInputIcon } from "../_components/TextInputIcon";
import { FilterTags } from "./FilterTags";
import { Icon } from "../_components/Icon";
import Button from "../_components/Button";
import { SONG_LIST_ITEM_HEIGHT } from "./SongListItem";
import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers/appReducer";
import { ResetScroll } from "../_components/ResetScroll";
import { FloatingWrapper } from "../_components/FloatingWrapper";
import { RestoreScroll } from "../_components/RestoreScroll";

export function UserSongs() {
  const scrollingContentRef = React.useRef<HTMLDivElement>(null);
  // const [pageState, setPageState] = React.useContext(PageContext);

  const { state, dispatch } = React.useContext(AppContext);
  const { searchFilter, tagFilter } = state.app;

  const history = useHistory();

  const location: any = useLocation();
  const clientAllowPromptFeedback: boolean =
    location?.state?.clientAllowPromptFeedback ?? false;

  const [userId, setUserId] = React.useState<string>("");
  const [availSongTags, setAvailSongTags] = React.useState<{
    tagSet: string[];
    tagCounts: Record<string, number>;
  }>({ tagSet: [], tagCounts: {} });

  const USER_SONGS_HEADER_HEIGHT = availSongTags.tagSet.length > 0 ? 102 : 60;

  React.useEffect(() => {
    dispatch({
      type: AppActionTypes.SetMode,
      payload: "play",
    });
  }, []);

  function setSearchFilter(newSearchFilter: string) {
    dispatch({
      type: AppActionTypes.SetSearchFilter,
      payload: newSearchFilter,
    });
  }

  function setTagFilter(newTagFilter: string[]) {
    dispatch({
      type: AppActionTypes.SetTagFilter,
      payload: newTagFilter,
    });
  }

  function handleSearchInput(value: string) {
    setSearchFilter(value);
  }

  function handleTagFilter(newTags: string[]) {
    setTagFilter(newTags);
  }

  return (
    <>
      <Page
        headerHeight={USER_SONGS_HEADER_HEIGHT}
        footerHeight={SONG_LIST_ITEM_HEIGHT * 2}
      >
        <PageHeader allowHeaderCollapse={true}>
          <FiltersWrapper>
            <MenuSearchWrapper>
              <TextInputIcon
                value={searchFilter}
                onChange={handleSearchInput}
                icon={"search"}
                size={"large"}
                placeholder={"Search songs"}
                mode="border"
              />
              <ButtonWrapper>
                <Button
                  onClick={() => history.push("/settings")}
                  fill="ghost"
                  size="sm"
                >
                  <Icon id="user" size={24} strokeWidth={2} />
                </Button>
              </ButtonWrapper>
            </MenuSearchWrapper>
            {availSongTags.tagSet.length > 0 && (
              <FilterTags
                tags={availSongTags.tagSet}
                tagCounts={availSongTags.tagCounts}
                tagFilter={tagFilter}
                handleTagFilter={handleTagFilter}
                resetScrollOnChange
              />
            )}
          </FiltersWrapper>
        </PageHeader>
        <PageContent ref={scrollingContentRef}>
          {/* <PageContent> */}
          {(queryOptions) => {
            return (
              <>
                <UserSongsQuery
                  setUserId={setUserId}
                  searchFilter={searchFilter}
                  tagFilter={tagFilter}
                  setAvailSongTags={setAvailSongTags}
                  queryOptions={queryOptions}
                  clientAllowPromptFeedback={clientAllowPromptFeedback}
                />
                {userId !== "" && (
                  <FloatingWrapper>
                    <CreateSong userId={userId} />
                  </FloatingWrapper>
                )}
              </>
            );
          }}
        </PageContent>
      </Page>

      <ResetScroll
        // element={pageState.pageContentRef.current}
        element={scrollingContentRef.current}
        resetValArr={[searchFilter, tagFilter.length]}
        duration={200}
      />
      <RestoreScroll
        // scrollEl={pageState.pageContentRef.current}
        scrollEl={scrollingContentRef.current}
        scrollId={"UserSongs"}
        // need to incl. USER_SONGS_HEADER_HEIGHT in dep. array; big picture reason: b/c you locate your queries within PageContent, values at this level ("Page") that are dependent upon queried values will always start at a default value on first render, then will be set correctly on a subsequent render after the query has returned; in this case, availSongTags (a queried value) is used to calc USER_SONGS_HEADER_HEIGHT, which is passed to Page as headerHeight, and then consumed by PageContent to determine size of top Spacer (ensuring it matches size of header so header never overlaps content); on first render will equal a smaller val b/c tags will not have been queried; on next render if tags are found it will be a larger value; this of course will throw off scrollTop by the difference between the numbers;
        // regarding above, this fnc could still go wrong in edge cases: if user has tags on only one song, navs to that song and removes all tags, then navs back; now have a scrollTop "calibrated" for a header with space for tag filter, but no longer have tags; same issue would occur in reverse (no tags, navs to song and adds tags, then returns)
        restoreDependencyArr={[USER_SONGS_HEADER_HEIGHT]}
        // want scroll state to restore only if navigating here from Song page; at the moment piggybacking on location?.state?.clientAllowPromptFeedback, which only Song sets to "true" when navigating to UserSongs; *** however, this will not trigger on use of back button
        // doRestore={clientAllowPromptFeedback}
        // *** the above, besides not working for back button, didn't work on app load (so first time naving to Song page and back wouldn't restore); better to make it always restore than miss these times; still won't restore after hard close/open
        doRestore={true}
      />
    </>
  );
}

const FiltersWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 4px;
`;

const MenuSearchWrapper = styled.div`
  padding-left: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  min-width: 48px;
  height: 100%;
  display: flex;
`;
