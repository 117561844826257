import React from "react";
import { useHistory } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { SectionMetaQueryQuery } from "../__generated__/SectionMetaQueryQuery.graphql";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { UpdateSectionName } from "../SongEdit/UpdateSectionName";
import { DeleteSection } from "../SongEdit/DeleteSection";
import { UpdateSectionNotes } from "../SongEdit/UpdateSectionNotes";
import { QueryOptions } from "../_types/relayTypes";
import UpdateSectionTimeSig from "./UpdateSectionTimeSig";

type Props = {
  songId: string;
  sectionId: string;
  queryOptions: QueryOptions;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

export default function SectionMetaQuery(props: Props) {
  const history = useHistory();
  const data = useLazyLoadQuery<SectionMetaQueryQuery>(
    graphql`
      query SectionMetaQueryQuery($id: ID!) {
        section(id: $id) {
          song {
            id
            mongoVersion
            ...UpdateSectionName_song
            ...UpdateSectionNotes_song
            ...DeleteSection_song
            ...UpdateSectionTimeSig_song
          }
          section {
            id
            name
            notes
            ...UpdateSectionName_section
            ...UpdateSectionNotes_section
            ...DeleteSection_section
            ...SectionQueryChords_section
            ...UpdateSectionTimeSig_section
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    { id: props.sectionId },
    props.queryOptions
  );

  const dataWithErrors = data.section;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { song, section, errors } = dataWithErrors;

  function callbackAfterDelete() {
    history.push(`/song-edit/${props.songId}`);
  }

  React.useEffect(() => {
    if (section) {
      props.setTitle(section.name);
    }
  }, [section]);

  return (
    <>
      {/* data result */}
      {song && section && (
        <>
          <UpdateSectionName songRef={song} sectionRef={section} />
          <UpdateSectionNotes songRef={song} sectionRef={section} />
          <UpdateSectionTimeSig song={song} section={section} />
          <DeleteSection
            songRef={song}
            sectionRef={section}
            callback={callbackAfterDelete}
          />
        </>
      )}

      {/* null result */}
      {!section && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
