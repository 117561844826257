import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import styled from "styled-components/macro";

import type {
  Chord,
  ChordDefinition,
  ChordInputs,
  ChordOrCursor,
  RhythmUnit,
} from "../_types";

import { useSectionChords, useTap } from "../_context/chordContext";
import { ChordGrid } from "./ChordGrid/ChordGrid";
import { CreateUpdateChord } from "./ChordInputs/CreateUpdateChord";
import { CURSOR_EMPTY_VAL, CURSOR_ID } from "./Cursor";
import { itemRefs } from "./ChordGrid/ChordGridItem";
import { scrollToRef } from "../_utilities/scroll";
import { addChartContext, splitChordCells } from "../_music/addChartContext";
import { SectionPageContentRef } from "../Section/Section";
import { debounceInline } from "../_utilities/debounceInline";
// import { PageContext } from "../_components/Page";
import { ChordsSimple_section$key } from "../__generated__/ChordsSimple_section.graphql";
import { ChordsSimple_song$key } from "../__generated__/ChordsSimple_song.graphql";
import { TIME_SIGS_DEFAULT } from "../_music/rhythmCount";
import { APP_CONFIG } from "../_constants";
import { RhythmSection } from "./ChordGrid/ChordItem";
import { getNodeArrayFromEdges } from "../_types/graphqlData";
import { typeNotEmpty } from "../_types/typeHelpers";

export type ChordInSong = {
  chord: ChordOrCursor;
  count: { currentSection: number; song: number; identicalNeighbors: number };
};

type Props = {
  sectionId: string;
  handleUpdateSectionChords: (chordsState: ChordOrCursor[]) => void;
  song: ChordsSimple_song$key;
  section: ChordsSimple_section$key;
};

export function ChordsSimple(props: Props) {
  const songData = useFragment(
    graphql`
      fragment ChordsSimple_song on Song {
        id
        mongoVersion
        timeSig
        sections(first: 2147483647) @connection(key: "MoveSection_sections") {
          edges {
            node {
              id
              name
              notes
              chords {
                id
                duration
                rhythm
                definition {
                  root
                  qual
                  bass
                }
                notation
              }
            }
          }
        }
      }
    `,
    props.song
  );

  const sectionData = useFragment(
    graphql`
      fragment ChordsSimple_section on SongSection {
        id
        timeSig
        rhythmUnit
        chords {
          id
          duration
          rhythm
          definition {
            root
            qual
            bass
          }
          notation
        }
      }
    `,
    props.section
  );

  // if decide want this value again, this is now PageContext from Page.tsx
  // const headerHeight = React.useContext(HeaderHeightContext);

  // const chordsSimpleRef = React.createRef<HTMLDivElement>();

  const {
    setChordsState,
    createChord,
    resetCursor,
    updateChord,
    moveChord,
    deleteChord,
    setSelected,
    sectionChords,
    selectedId,
  } = useSectionChords({
    updateServer: props.handleUpdateSectionChords,
  });

  React.useEffect(() => {
    // on first load, set ChordContext state to match server;
    setChordsState(
      sectionData.chords as ChordOrCursor[],
      sectionData.rhythmUnit as RhythmUnit
    );
    // run only on mount, so exclude dependencies;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // ***NOTE: you're getting/setting state from ChordsContext via both useSectionChords() and useTap();
  const {
    inputMode,
    addRhythmTap,
    toggleChordForScaling,
    tapIdSet: tappedChordIdSet,
    tapSessionData,
    finishRhythmTap,
    tapStatGroup,
    lastTappedId,
  } = useTap({
    callBackAfterUpdateChords: props.handleUpdateSectionChords,
  });

  const timeSigInherited =
    sectionData.timeSig === TIME_SIGS_DEFAULT
      ? songData.timeSig
      : sectionData.timeSig;

  // *** unsure if I want to proceed this way, but may be good enough for now to reflect tapped values in ChordGrid
  // *** it's been so long, I forget why I'm using sectionChords via ChordContext instead of using directly from GraphQL (sectionData); pretty sure it's for faster responsiveness, so user can edit and receive updates in real time, while your batchQueue() function handles updating the server at appropriate intervals;
  let sectionChordsForContext =
    inputMode === "tap" ? tapSessionData.sectionChords : sectionChords;
  let rhythmUnitForContext =
    inputMode === "tap"
      ? tapSessionData.rhythmUnit
      : (sectionData.rhythmUnit as RhythmUnit);
  let sectionChordsWithContext = addChartContext(
    sectionChordsForContext,
    rhythmUnitForContext,
    timeSigInherited
  );

  // section-wide stats
  // *** need to separately count beats for alternate endings; could leverage logic similar to that found in addChartContext
  /*   const totalBeats = sectionChords
    .filter((val) => val.id !== CURSOR_ID)
    .reduce((prev: number, curr) => {
      return prev + curr.duration;
    }, 0);
  console.log({ totalBeats }); */

  const cursorIndex = sectionChords.findIndex((val) => val.id === CURSOR_ID);
  /*   const selectedIndex = sectionChords.findIndex(
    (chord) => chord.id === selectedId
  ); */
  const selectedChord = sectionChords[selectedId] ?? sectionChords[cursorIndex];
  const chordPriorToCursor = sectionChords[cursorIndex - 1];

  /*   sectionChordsWithContext = splitChordCells(
    sectionChordsWithContext,
    APP_CONFIG.song.maxRhythmSymbolsPerChordTile,
    rhythmUnitForContext
  ); */

  /* 
*** if want to recommend chords based on chord profile of song, need access to all song's chords here; see implementation in [src/Song/SongBody.tsx], and other GraphQL queries that use [sections(first: 2147483647)]

definition: Object { root: "G", qual: "maj", bass: "B" }
​​​​
bass: "B"
​​​​
qual: "maj"
​​​​
root: "G"
*/
  const { sections } = songData;
  const sectionNodes = getNodeArrayFromEdges(sections).filter(typeNotEmpty);
  // const songChordsMap = new Map<string, {chord: ChordOrCursor, count: number}>();

  const hasSameDefinition = (
    suggestedChordDef: ChordDefinition,
    refChordDef: ChordDefinition
  ) => {
    for (let [key, val] of Object.entries(suggestedChordDef)) {
      let refChordVal = refChordDef[key as keyof ChordDefinition];
      if (refChordVal !== val) {
        return false;
      }
    }
    // all definition fields match
    return true;
  };

  const songChordsMap: Record<string, ChordInSong> = {};
  for (let section of sectionNodes) {
    for (let chord of section.chords) {
      let { bass, qual, root } = chord.definition;
      let chordDef = `${bass}_${root}_${qual}`;
      let chordInMap = songChordsMap[chordDef];
      let isInCurrentSection = sectionData.id === section.id;
      let identicalToPrior = chordPriorToCursor && hasSameDefinition(
        chord.definition,
        chordPriorToCursor?.definition
      );
      // convert chord def to string (pretty sure you already built this logic on the server); use as key
      if (chordInMap) {
        chordInMap.count.song++;
        isInCurrentSection && chordInMap.count.currentSection++;
      } else {
        songChordsMap[chordDef] = {
          chord,
          count: {
            song: 1,
            currentSection: isInCurrentSection ? 1 : 0,
            identicalNeighbors: identicalToPrior ? 1 : 0,
          },
        };
      }
    }
  }

  const suggestedChordMatchesInputs = (
    suggestedChordDef: ChordDefinition,
    inputChordDef: ChordDefinition
  ) => {
    for (let [key, val] of Object.entries(suggestedChordDef)) {
      let inputChordVal = inputChordDef[key as keyof ChordDefinition];
      if (inputChordVal !== CURSOR_EMPTY_VAL && inputChordVal !== val) {
        return false;
      }
    }
    // all definition fields match
    return true;
  };

  // don't show unless cursor selected
  // *** but then allow way to "clear" selected vals? add as button inside suggestions row

  const suggestedChords = Object.values(songChordsMap)
    // filter by currently selected values, e.g. if user already chosen root ---
    .filter((val) =>
      suggestedChordMatchesInputs(
        val.chord.definition,
        selectedChord.definition
      )
    )
    .sort(
      (a, b) =>
        // de-prioritize prior chord
        a.count.identicalNeighbors - b.count.identicalNeighbors ||
        // give priority to chords already in this section;
        b.count.currentSection - a.count.currentSection ||
        b.count.song - a.count.song
    );
  // const sectionNames = sectionNodes.map((node) => node.name);
  // console.log(sectionNames)

  // const [pageState, setPageState] = React.useContext(PageContext);
  function scrollToChord(chordIndex: number) {
    setTimeout(() => {
      // scrollToRef(itemRefs[chordIndex], pageState.pageContentRef, 200);
      scrollToRef(itemRefs[chordIndex], SectionPageContentRef, 200);
    }, 0);
  }

  function handleSetSelectedChord(chordId: number) {
    setSelected(chordId);
    scrollToChord(chordId);
  }

  function handleCreateChord(suggestedChord?: ChordInputs) {
    // prevent rapid clicks on add button from adding several instances of chord to local state
    if (debounceInline(200) === false) return;
    createChord(cursorIndex, suggestedChord);
    scrollToChord(selectedId);
  }

  function handleResetCursor() {
    resetCursor();
  }

  function handleUpdateChord(chordInputs: ChordInputs) {
    updateChord(chordInputs);
  }

  function handleOnMoveChord(chordId: string, toRank: number) {
    moveChord(chordId, toRank);
  }

  function handleDeleteChord() {
    var callback = (selectedId: number) => {
      // receive new selectedId after target chord is deleted;
      scrollToChord(selectedId);
    };
    deleteChord(callback);
  }

  var lastTappedChordTaps = tapStatGroup.find(
    (val) => val.chordId === lastTappedId
  );

  var lastTappedChord = sectionChordsWithContext.find(
    (val) => val.id === lastTappedId
  );
  var lastTappedRhythmCount = lastTappedChord?.context.rhythmCount;

  return (
    <Wrapper
    // ref={chordsSimpleRef}
    // style={{ "--headerHeight": `${headerHeight}px` } as React.CSSProperties}
    >
      {/*       <RhythmDisplay>
        {lastTappedChordTaps?.rhythm.join(",")}
        {lastTappedRhythmCount && (
          <RhythmSection
            rhythmCount={lastTappedRhythmCount}
            groupSubdivisions={Math.min(lastTappedRhythmCount.length, 32)}
            rhythmDisplay="count"
          />
        )}
      </RhythmDisplay> */}
      <ChordGrid
        sectionChordsWithContext={sectionChordsWithContext}
        // chordsObj={chordsObj}
        // chordIds={chordsStateIdArray}
        selectedId={selectedId}
        handleSetSelectedChord={handleSetSelectedChord}
        handleOnMoveChord={handleOnMoveChord}
        handleCreateChord={handleCreateChord}
        // tappedChordIds={tapStatGroups.map((group) => group.id)}
        tappedChordIdSet={tappedChordIdSet}
        // addRhythmTap={addRhythmTap}
        handleTouchStartChord={
          {
            "manual": undefined,
            "tap": addRhythmTap,
            "scale": toggleChordForScaling,
          }[inputMode]
        }
        finishRhythmTap={finishRhythmTap}
      />
      <CreateUpdateChord
        selectedId={selectedId}
        // selectedIndex={selectedId}
        selectedChord={selectedChord}
        handleCreateChord={handleCreateChord}
        handleResetCursor={handleResetCursor}
        handleUpdateChord={handleUpdateChord}
        handleDeleteChord={handleDeleteChord}
        handleUpdateSectionChords={props.handleUpdateSectionChords}
        sectionChordCount={sectionChordsWithContext.length}
        suggestedChords={suggestedChords}
      />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  /* *** note: despite lack of styling, this element is necessary for structure, to ensure heights/overflows operate as expected  */

  /* *** note: CreateUpdateChord uses position:absolute, and you want it to attach to Page; thus, ensure there are no positioned elements in between; ideally, you'd hoist CreateUpdateChord up so it's a child of a PageFooter that is a sibling to PageContent... instead of a child of PageContent tacked onto Page via position:absolute; */
  /* position: relative; */
  /* height: 100%; */

  /* goal with this height calc, in conjunction with styles on ChordGrid's Wrapper and ScrollableWrapper: 1) treat ChordsSimple's footer differently from default behavior by constraining its main content to its viewable area, as opposed to allowing it to occupy fully height with the footer positioned absolutely on top of it, and padding added to bottom of content to allow it to scroll all children into view; 2) this is to allow chord dragging (to re-order) to occur within the visible chord area; otherwise if want to drag chord down and force content scroll, must drag chord halfway through keyboard; 3) to avoid having two simultaneous scrolling areas, need to prevent PageContent from scrolling, which is why want to reduce height of ChordsSimple by headerHeight; 4) main disadvantage... besides the extra overhead... is you lose access to functionality tied to PageContent scrolling, namely hiding the header on scroll 5) to disable, simply comment out the few tagged lines of CSS in ChordGrid as well as the height line below, and restore height:100%; 6) ideally, this would all be baked into Page/PageContent/PageFooter logic, but if it's a one-off, prob not worth it */
  /* height: calc(100% - var(--headerHeight)); */
`;

const RhythmDisplay = styled.div`
  height: 90px;
  background-color: blue;
  position: sticky;
  top: 0px;
`;
