import React from "react";

import { AppContext } from "../_context/appContext";
import Button from "../_components/Button";
import { AppActionTypes, RhythmDisplay } from "../_reducers";

export function ToggleRhythmDisplay() {
  const { state, dispatch } = React.useContext(AppContext);
  const { rhythmDisplay } = state.app;

  const nextMode = {
    duration: "count",
    count: "all",
    all: "none",
    none: "duration",
  }[rhythmDisplay];

  const modeLabel = {
    duration: "Duration",
    count: "Count",
    all: "Duration + Count",
    none: "None",
  }[rhythmDisplay];

  function handleClick() {
    dispatch({
      type: AppActionTypes.SetRhythmDisplay,
      // I'm sure I've come up with a better way to get the typing right in a similar scenario, rather than using "as"; given the one-time nature of this, though, not a big deal;
      payload: nextMode as RhythmDisplay,
    });
  }

  return (
    <Button onClick={handleClick} fill="clear" size="md" iconId={"music"}>
      Show Rhythm: {modeLabel}
    </Button>
  );
}
