import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { ConnectionHandler } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { DeleteSection_song$key } from "../__generated__/DeleteSection_song.graphql";
import { DeleteSection_section$key } from "../__generated__/DeleteSection_section.graphql";
import { DeleteSectionMutation } from "../__generated__/DeleteSectionMutation.graphql";

import { Modal } from "../_components/Modal";
import Button from "../_components/Button";
import { ModalError } from "../_components/ModalError";
import { isApiError } from "../_types/errorTypes";

type Props = {
  songRef: DeleteSection_song$key;
  sectionRef: DeleteSection_section$key;
  callback?: () => void;
  // useful for e.g. closing an existing modal before opening another
  handleOnModalOpen?: () => void;
  handleOnModalClose?: () => void;
  callBackOnError?: (errMsg: string) => void;
};

export function DeleteSection(props: Props) {
  // const [errMsg, setErrMsg] = React.useState<string>("");
  const [doShowDialog, setDoShowDialog] = useState<boolean>(false);

  const song = useFragment(
    graphql`
      fragment DeleteSection_song on Song {
        id
        mongoVersion
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment DeleteSection_section on SongSection {
        id
        name
      }
    `,
    props.sectionRef
  );

  const [commit, isInFlight] = useMutation<DeleteSectionMutation>(graphql`
    mutation DeleteSectionMutation(
      # Define a GraphQL variable for the connections array
      $connections: [ID!]!
      $input: deleteSectionInput!
    ) {
      deleteSection(input: $input) {
        song {
          id
          mongoVersion
          # using @connection key, I can update entire set of song.sections using this query; note how many queries/fragments/mutations use this key;
          sections(first: 2147483647) @connection(key: "MoveSection_sections") {
            edges {
              node {
                id
              }
            }
          }
        }
        deletedSection {
          id @deleteEdge(connections: $connections)
          # do not request addition details from this node; in some cases, the server will have access only to the id of the deleted node, namely if client requests deletion of a section that has been deleted by another client;
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  function handleDeleteSection() {
    const connectionID = ConnectionHandler.getConnectionID(
      song.id,
      "MoveSection_sections"
    );
    commit({
      variables: {
        input: {
          songId: song.id,
          sectionId: section.id,
          mongoVersion: song.mongoVersion,
        },
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.deleteSection) {
          const { deletedSection, errors } = response.deleteSection;
          if (response.deleteSection) {
            close();
            props.callback && props.callback();
            // if section deleted, also close section-related context modal (if any)
            props.handleOnModalClose && props.handleOnModalClose();
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            // setErrMsg(errMsgs.join("; "));
            props.callBackOnError && props.callBackOnError(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          // setErrMsg(err.message);
          props.callBackOnError && props.callBackOnError(err.message);
        }
      },
    });
  }

  return (
    <>
      <Wrapper>
        <Button
          disabled={isInFlight}
          palette="danger"
          onClick={open}
          fill="clear"
          iconId={"trash-2"}
        >
          Delete Section
        </Button>
        <Modal
          doShowDialog={doShowDialog}
          closeDialog={close}
          handleOnModalOpen={props.handleOnModalOpen}
          handleConfirm={handleDeleteSection}
          label={"Delete Section"}
          actionText={"Delete"}
          actionColor="danger"
          doUseLeastDestructiveRef={true}
          showCloseButton={true}
        >
          <ModalBody>
            <TextWarning>
              Delete section: <SongTitle>{section.name}</SongTitle>?
            </TextWarning>
          </ModalBody>
        </Modal>
      </Wrapper>
      {/* <ModalError errMsg={errMsg} setErrMsg={setErrMsg} /> */}
    </>
  );
}

const Wrapper = styled.div``;
const ModalBody = styled.div`
  padding: 32px 16px;
`;
const TextWarning = styled.span`
  font-size: ${18 / 16}rem;
`;
const SongTitle = styled.span`
  font-weight: 500;
`;
