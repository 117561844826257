/**
 * @generated SignedSource<<ff2f199d45a846dc7ac28b65dad42f34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateSongNotesInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  notes: string;
  songId: string;
};
export type UpdateSongNotesMutation$variables = {
  input: updateSongNotesInput;
};
export type UpdateSongNotesMutation$data = {
  readonly updateSongNotes: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly updatedSong: {
      readonly editLock: {
        readonly isLocked: boolean;
        readonly lockedBy: string;
        readonly lockedByEmail: string;
        readonly lockedUntil: string;
      } | null;
      readonly id: string;
      readonly isLockedByUser: boolean;
      readonly mongoVersion: number;
      readonly notes: string;
    } | null;
  } | null;
};
export type UpdateSongNotesMutation = {
  response: UpdateSongNotesMutation$data;
  variables: UpdateSongNotesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateSongNotesPayload",
    "kind": "LinkedField",
    "name": "updateSongNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Song",
        "kind": "LinkedField",
        "name": "updatedSong",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mongoVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLockedByUser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "editLock",
            "kind": "LinkedField",
            "name": "editLock",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedByEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockedUntil",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSongNotesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSongNotesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04d1d7e5f43d01c565d11c8c93efb844",
    "id": null,
    "metadata": {},
    "name": "UpdateSongNotesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSongNotesMutation(\n  $input: updateSongNotesInput!\n) {\n  updateSongNotes(input: $input) {\n    updatedSong {\n      id\n      notes\n      mongoVersion\n      isLockedByUser\n      editLock {\n        isLocked\n        lockedBy\n        lockedByEmail\n        lockedUntil\n      }\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "862a241e84def4dc87f1992b76c3654f";

export default node;
