import React from "react";
import { useParams } from "react-router-dom";

import { Page, PageHeader, PageContent } from "../_components/Page";
import SectionMetaQuery from "./SectionMetaQuery";

export const SectionMeta: React.FC = () => {
  const { songId, sectionId } = useParams<{
    songId: string;
    sectionId: string;
  }>();
  const [title, setTitle] = React.useState<string>("");

  return (
    <Page>
      <PageHeader title={title} backPath={`/section/${songId}/${sectionId}`} />
      <PageContent>
        {(queryOptions) => {
          return (
            <SectionMetaQuery
              songId={songId}
              sectionId={sectionId}
              queryOptions={queryOptions}
              setTitle={setTitle}
            />
          );
        }}
      </PageContent>
    </Page>
  );
};
