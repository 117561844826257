/**
 * @generated SignedSource<<577b782581e5db5c33e591679a522f8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSectionName_section$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "UpdateSectionName_section";
};
export type UpdateSectionName_section$key = {
  readonly " $data"?: UpdateSectionName_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionName_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSectionName_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "SongSection",
  "abstractKey": null
};

(node as any).hash = "d38da1942d9860eee545d9ec1732e89b";

export default node;
