import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { TestDeck } from "./TestDeck";

import type { LeitnerQueryQuery } from "../__generated__/LeitnerQueryQuery.graphql";
import type { QueryOptions } from "../_types/relayTypes";

type Props = {
  queryOptions: QueryOptions;
};

export function LeitnerQuery(props: Props) {
  const data = useLazyLoadQuery<LeitnerQueryQuery>(
    graphql`
      query LeitnerQueryQuery {
        viewer {
          user {
            id
            ...TestDeck_user
            ...TestDeck_songs
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    {},
    props.queryOptions
  );

  const dataWithErrors = data.viewer;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors } = dataWithErrors;

  return (
    <>
      {/* data result */}
      {user && <TestDeck songs={user} user={user} />}

      {/* null result */}
      {!user && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
