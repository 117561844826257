/**
 * @generated SignedSource<<592f93222f89dd344e527a2a0d43a253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisplaySection_section$data = {
  readonly chords: ReadonlyArray<{
    readonly definition: {
      readonly bass: string;
      readonly qual: string;
      readonly root: string;
    };
    readonly duration: number;
    readonly id: string;
    readonly notation: ReadonlyArray<string>;
    readonly rhythm: ReadonlyArray<number>;
  }>;
  readonly id: string;
  readonly name: string;
  readonly notes: string;
  readonly rhythmUnit: number;
  readonly timeSig: TimeSig;
  readonly " $fragmentType": "DisplaySection_section";
};
export type DisplaySection_section$key = {
  readonly " $data"?: DisplaySection_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisplaySection_section">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisplaySection_section",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rhythmUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Chord",
      "kind": "LinkedField",
      "name": "chords",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rhythm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ChordDef",
          "kind": "LinkedField",
          "name": "definition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "root",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qual",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bass",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SongSection",
  "abstractKey": null
};
})();

(node as any).hash = "5f0651372266d21c2e0eaea101c4ffcc";

export default node;
