import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Page, PageHeader, PageContent } from "../_components/Page";
import { SongEditQuery } from "./SongEditQuery";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { AppContext } from "../_context/appContext";
import { SECTION_LIST_ITEM_HEIGHT } from "./SectionList/SectionListItem";

export type SongEditHeaderDetails = {
  title: string;
  viewerIsOwner: boolean;
};

export const SongEdit: React.FC = () => {
  const { songId } = useParams<{ songId: string }>();
  const { state, dispatch } = React.useContext(AppContext);
  const history = useHistory();
  const [headerDetails, setHeaderDetails] = useState<SongEditHeaderDetails>({
    title: "",
    viewerIsOwner: false,
  });

  const controls = (
    <Button
      onClick={() => history.push(`/song-meta/${songId}`)}
      fill="ghost"
      size="sm"
    >
      <Icon id={"settings"} size={24} strokeWidth={2} />
    </Button>
  );

  return (
    <Page footerHeight={SECTION_LIST_ITEM_HEIGHT * 2}>
      <PageHeader
        title={headerDetails.title}
        // "edit" buttons on test/view pages set app "mode", so SongEdit knows where to go back to
        backPath={state.app.mode === "learn" ? `/test` : `/song/${songId}`}
        
        // *** initially liked idea of denying access to song meta page to non-owning user... then decided that page was the best place for the "song unlock" button, which sharees do need access to
        // controls={headerDetails.viewerIsOwner && controls}
        controls={[controls]}
      />
      <PageContent>
        {(queryOptions) => {
          return (
            <SongEditQuery
              songId={songId}
              setHeaderDetails={setHeaderDetails}
              queryOptions={queryOptions}
            />
          );
        }}
      </PageContent>
    </Page>
  );
};
