import React from "react";

import { useLogout } from "../_hooks/useLogout";
import Button from "./Button";

export function LogoutUser() {
  const { handleLogout } = useLogout();

  return (
    <Button
      onClick={() => handleLogout()}
      fill="clear"
      iconId="log-out"
      size="md"
      palette="primary"
    >
      Log out
    </Button>
  );
}
