import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import Button from "./Button";
import Spacer from "./Spacer";

export function DataNotFound() {
  let history = useHistory();

  return (
    <Wrapper>
      <p>Sorry, we couldn't find this!</p>
      <p>It may have been deleted.</p>
      <Button onClick={() => history.goBack()}>Go Back</Button>
      <Spacer size={16} axis="vert" />
      <Button palette="secondary" onClick={() => history.push("/user-songs")}>
        Go Home
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
`;
