/**
 * @generated SignedSource<<9376f296924b7e71a9061eb5b81204eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type updateSongTagsInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  songId: string;
  tags: ReadonlyArray<string>;
};
export type UpdateSongTagsMutation$variables = {
  input: updateSongTagsInput;
};
export type UpdateSongTagsMutation$data = {
  readonly updateSongTags: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly updatedSong: {
      readonly tags: ReadonlyArray<string>;
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSongTags_song">;
    } | null;
  } | null;
};
export type UpdateSongTagsMutation = {
  response: UpdateSongTagsMutation$data;
  variables: UpdateSongTagsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSongTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSongTagsPayload",
        "kind": "LinkedField",
        "name": "updateSongTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "updatedSong",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSongTags_song"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSongTagsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSongTagsPayload",
        "kind": "LinkedField",
        "name": "updateSongTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "updatedSong",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abee536c566f48e956da03904f755595",
    "id": null,
    "metadata": {},
    "name": "UpdateSongTagsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSongTagsMutation(\n  $input: updateSongTagsInput!\n) {\n  updateSongTags(input: $input) {\n    updatedSong {\n      ...UpdateSongTags_song\n      tags\n      id\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment UpdateSongTags_song on Song {\n  id\n  mongoVersion\n  tags\n}\n"
  }
};
})();

(node as any).hash = "f521e346ea5d1e2aafa960cf948c4431";

export default node;
