import React from "react";

/**
 * Note: in React development mode, this component (in addition to populating with error details), will re-throw any error it catches, leaving the error ultimately uncaught and displayed in-browser.  In production builds, this component will *not* re-throw, and will simply display the desired component.
 * Error boundaries must be class components: https://reactjs.org/docs/error-boundaries.html
 * Can include retry logic: https://relay.dev/docs/guided-tour/rendering/error-states/
 * */

type ChildrenProps = {
  fetchKey: number;
};

type FallbackProps = {
  error: Error;
  clearError: () => void;
  retry: () => void;
};

type Props = {
  children:
    | React.ReactNode
    | (({ fetchKey }: ChildrenProps) => React.ReactNode);
  fallback: ({ error, clearError, retry }: FallbackProps) => React.ReactNode;
};

type State = {
  error: Error | null;
  fetchKey: number;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, fetchKey: Date.now() };
  }

  static getDerivedStateFromError(error: Error): State {
    // console.log("*** getDerivedStateFromError", error.message);
    return {
      error,
      fetchKey: Date.now(),
    };
  }

  // *** could log error to an API endpoint
  // componentDidCatch(error: any, errorInfo: any) {
  //   logErrorToMyService(error, errorInfo);
  // }

  clearError = () => {
    // console.log("error cleared");
    this.setState((prev) => ({
      error: null,
      fetchKey: Date.now(),
    }));
  };

  retry = () => {
    this.setState((prev) => ({
      error: null,
      fetchKey: Date.now(),
    }));
  };

  render() {
    const { children, fallback } = this.props;
    const { error, fetchKey } = this.state;

    if (error) {
      if (typeof fallback === "function") {
        return fallback({
          error,
          clearError: this.clearError,
          retry: this.retry,
        });
      }
      return fallback;
    }

    if (typeof children === "function") {
      return children({ fetchKey });
    }
    return children;
  }
}
