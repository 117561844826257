import React, { FormEvent } from "react";
import styled from "styled-components/macro";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import { useParams } from "react-router-dom";

import {
  resetPasswordInput,
  ResetPasswordMutation,
} from "../__generated__/ResetPasswordMutation.graphql";
import { isApiError } from "../_types/errorTypes";

import * as Yup from "yup";
import { HiddenSubmitButton, LoadingSpinnerComplete } from "../_components";
import { ModalError } from "../_components/ModalError";
import Button from "../_components/Button";
import { TextInputIcon } from "../_components/TextInputIcon";
import {
  FORM_PLACEHOLDERS,
  getFieldErrors,
  YUP_FIELDS,
} from "../_utilities/forms";
import Spacer from "../_components/Spacer";
import { AppIcon } from "../_components/AppIcon";
import {
  FieldError,
} from "../_components/FormComponents";

// *** substantial overlap with CreateUser / Login --- should spin off some fncs/components to share between them; getFieldErrors should prob be turned into a React component instead of a mere fnc

const USER_CREDENTIALS_SCHEMA = Yup.object().shape({
  email: YUP_FIELDS.email,
  password: YUP_FIELDS.password,
  ui_passwordConfirm: YUP_FIELDS.ui_passwordConfirm,
});

export function ResetPassword() {
  const { id, token } = useParams<{ id: string; token: string }>();

  type FormInput = {
    email: string;
    password: string;
    ui_passwordConfirm: string;
  };
  const FORM_INPUT_INITIAL = {
    email: "",
    password: "",
    ui_passwordConfirm: "",
  };
  const [inputVals, setInputVals] =
    React.useState<FormInput>(FORM_INPUT_INITIAL);
  const [formInputIsInvalid, setFormInputIsInvalid] =
    React.useState<boolean>(false);
  const [didSucceed, setDidSucceed] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState<string>("");

  const [commit, isInFlight] = useMutation<ResetPasswordMutation>(graphql`
    mutation ResetPasswordMutation($input: resetPasswordInput!) {
      resetPassword(input: $input) {
        success
        errors {
          __typename
          ... on UserError {
            message
          }
        }
      }
    }
  `);

  function handleLogin(input: resetPasswordInput) {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (response.resetPassword) {
          const { success, errors } = response.resetPassword;
          if (success) {
            setDidSucceed(true);
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join(" "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  function handleSubmit(e: FormEvent) {
    // make sure e.preventDefault() comes first for form submission
    e.preventDefault();
    if (isInFlight) return;

    if (!USER_CREDENTIALS_SCHEMA.isValidSync(inputVals)) {
      console.log("Input vals fail schema validation.");
      setFormInputIsInvalid(true);
      return;
    }
    const { email, password } = inputVals;
    handleLogin({ userId: id, token, email, newPassword: password });
  }

  return (
    <Wrapper>
      <SafeWrapper>
        <AppIcon />
        <Header>{process.env.REACT_APP_NAME}</Header>
        <Spacer size={16} axis="vert" />
        {didSucceed ? (
          <SuccessMessage>
            <p>{`Password changed. You may close this browser tab.`}</p>
          </SuccessMessage>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            <InputFields>
              <TextInputIcon
                // name="email"
                type="email"
                label="Confirm Email"
                value={inputVals.email}
                onChange={(val) =>
                  setInputVals({
                    ...inputVals,
                    email: val,
                  })
                }
                size="large"
                mode="border"
                placeholder={FORM_PLACEHOLDERS.email}
              />
              <FieldError>
                {getFieldErrors(
                  USER_CREDENTIALS_SCHEMA,
                  inputVals,
                  "email",
                  formInputIsInvalid
                )}
              </FieldError>
              <TextInputIcon
                // name="password"
                type="password"
                label="New Password"
                value={inputVals.password}
                onChange={(val) =>
                  setInputVals({
                    ...inputVals,
                    password: val,
                  })
                }
                size="large"
                mode="border"
                // placeholder={FORM_PLACEHOLDERS.PASSWORD}
              />
              <FieldError>
                {getFieldErrors(
                  USER_CREDENTIALS_SCHEMA,
                  inputVals,
                  "password",
                  formInputIsInvalid
                )}
              </FieldError>
              <TextInputIcon
                // name="ui_passwordConfirm"
                type="password"
                label="Confirm New Password"
                value={inputVals.ui_passwordConfirm}
                onChange={(val) =>
                  setInputVals({
                    ...inputVals,
                    ui_passwordConfirm: val,
                  })
                }
                size="large"
                mode="border"
                // placeholder="password"
              />
              <FieldError>
                {getFieldErrors(
                  USER_CREDENTIALS_SCHEMA,
                  inputVals,
                  "ui_passwordConfirm",
                  formInputIsInvalid
                )}
              </FieldError>
              {isInFlight && <LoadingSpinnerComplete overlay />}
            </InputFields>
            <Spacer size={32} axis="vert" />
            <Button
              type="submit"
              // allow attempted submission when inputs are invalid, to trigger display of input errors
              disabled={isInFlight}
              palette={isInFlight ? "mono" : "primary"}
              expand="block"
            >
              Change password
            </Button>
            <HiddenSubmitButton disabled={isInFlight} />
          </StyledForm>
        )}
        <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
      </SafeWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);

  /* max-width: 600px; */
  /* margin: 0 auto; */
`;

const SafeWrapper = styled.div`
  padding-top: 48px;
  /* display: flex; */
  /* flex-flow: column nowrap; */
  /* remember: using center here will effectively shrink contents to flex-basis, versus default (stretch) */
  /* align-items: center; */
  height: 100%;
`;

const Header = styled.header`
  text-align: center;
  font-size: ${20 / 16}rem;
  font-weight: 500;
`;

const StyledForm = styled.form`
  height: 100%;
  /* padding: 0 16px; */
  /* display: flex;
  flex-flow: column nowrap; */
`;

const InputFields = styled.div`
  padding-top: 8px;
  position: relative;
  flex: 1;
`;

const SuccessMessage = styled.div`
  font-size: ${20 / 16}rem;
  padding: 32px 16px;
`;
