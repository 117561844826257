import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components/macro";
import {
  closestCenter,
  DragOverlay,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

import { ChordCursorContext } from "../../_types";

import { ChordGridContainer } from "./ChordGridContainer";
import { ChordGridItem } from "./ChordGridItem";
import { CURSOR_ID } from "../Cursor";
import Spacer from "../../_components/Spacer";
import { CREATE_UPDATE_CHORD_HEIGHT } from "../ChordInputs/CreateUpdateChord";
import {
  SectionPageContentRef,
  SectionSortableContext,
} from "../../Section/Section";
import { SortableChord } from "./SortableChord";
import { useInputMode } from "../../_context/chordContext";
import { Icon } from "../../_components/Icon";
import { PageContext } from "../../_components/Page";

interface Props {
  sectionChordsWithContext: ChordCursorContext[];
  selectedId: number;
  handleSetSelectedChord: (chordId: number) => void;
  handleOnMoveChord: (chordId: string, toRank: number) => void;
  handleCreateChord: () => void;
  // addRhythmTap: (chordId: string) => void;
  finishRhythmTap: () => void;
  tappedChordIdSet: Set<string>;
  handleTouchStartChord?: (chordId: string) => void;
}

export const ChordGrid: React.FC<Props> = (props: Props) => {
  const { inputMode, setInputMode } = useInputMode();

  const allowDrag = React.useContext(SectionSortableContext);
  const [activeId, setActiveId] = React.useState<string | null>(null);

  const chordIds: string[] = props.sectionChordsWithContext.map(
    (chord) => chord.id
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    if (!active) {
      return;
    }
    setActiveId(active.id as string);
    // had liked the idea of chord being selected when dragged, but: 1) user may want to stay focused on currently selected chord or cursor; 2) b/c handleSetSelected causes selected chord to be scrolled into view, it might cause user to inadvertently rapidly drag chord up/down across the screen; if decide want to re-implement select-chord-on-drag, decouple scroll-to function and fire it only on click select of chord
    /*  if (active.id !== props.selectedId) {
      handleSetSelectedChord(active.id);
    } */
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    setActiveId(null);
    if (over && active.id !== over.id) {
      // determine toRank based on order of array in state, *not* on rank prop of section at "over"; if you try the latter you create a race condition and could be working with an outdated rank
      const overIndex = chordIds.indexOf(over.id as string);
      props.handleOnMoveChord(active.id as string, overIndex);
      // had liked the idea of chord being selected when dragged, but: 1) user may want to stay focused on currently selected chord or cursor; 2) b/c handleSetSelected causes selected chord to be scrolled into view, it might cause user to inadvertently rapidly drag chord up/down across the screen; if decide want to re-implement select-chord-on-drag, decouple scroll-to function and fire it only on click select of chord
      // handleSetSelectedChord(active.id);
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300, // 300
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const getIndex = chordIds.indexOf.bind(chordIds);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const [pageContentDoesOverflow, setPageContentDoesOverflow] =
    React.useState<boolean>(false);
  function elDoesOverflow(ref: React.RefObject<HTMLElement>) {
    // https://stackoverflow.com/a/143889
    if (ref.current) {
      return ref.current.clientHeight < ref.current.scrollHeight;
    } else {
      return false;
    }
  }
  React.useLayoutEffect(() => {
    setPageContentDoesOverflow(elDoesOverflow(SectionPageContentRef));
  }, [
    SectionPageContentRef,
    inputMode,
    // chord taps could cause chord heights to increase, causing overflow;
    props.sectionChordsWithContext,
  ]);
  // const [pageState, setPageState] = React.useContext(PageContext);
  /* React.useLayoutEffect(() => {
    setPageContentDoesOverflow(elDoesOverflow(pageState.pageContentRef));
  }, [pageState, inputMode]);
 */

  function ellipsizeRhythmCount(
    rhythmCount: string[],
    maxToShow: number = 64
  ): string[] {
    if (rhythmCount.length > maxToShow) {
      // console.log(rhythmCount.length);
      return [...rhythmCount.slice(0, maxToShow - 1), "..."];
    } else {
      return rhythmCount;
    }
  }

  return (
    <Wrapper
    /* onClick={(e) => {
      if (e.target !== e.currentTarget) {
        return;
      }
      // allow de-selecting chord (thus having no chord selected); related to issue tracker line 273; would want this code also located in ChordGridContainer, or otherwise find a way to make this apply to that component, otherwise would have to click below last line of chords, even when an "empty slot" appears open to the right of the last chord; same applies below for onTouchStart to finishRhythmTap
      props.handleSetSelectedChord(-1);
    }} */
    /*    onTouchStart={
        inputMode !== "manual"
          ? (e) => {
              if (e.target !== e.currentTarget) {
                return;
              }
              props.finishRhythmTap();
            }
          : undefined
      } */
    >
      <ScrollableWrapper>
        {inputMode !== "manual" && pageContentDoesOverflow && (
          <ScrollZone>
            <ScrollHereText>
              <Icon id="chevron-up" size={28} strokeWidth={3} />
              <Spacer size={12} axis="vert" />
              Scroll
              <Spacer size={12} axis="vert" />
              <Icon id="chevron-down" size={28} strokeWidth={3} />
            </ScrollHereText>
          </ScrollZone>
        )}
        <DndWrapper
          style={
            {
              "--dndWrapper-touch-action":
                inputMode !== "manual" && pageContentDoesOverflow
                  ? "none"
                  : "revert",
            } as React.CSSProperties
          }
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={() => setActiveId(null)}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext items={chordIds} strategy={rectSortingStrategy}>
              <ChordGridContainer allowDrag={allowDrag}>
                {props.sectionChordsWithContext
                  .filter((chord) => {
                    if (inputMode === "manual") {
                      return true;
                    } else {
                      // remove cursor for other inputModes;
                      return chord.id !== CURSOR_ID;
                    }
                  })
                  .map((chord, index) => (
                    <SortableChord
                      key={chord.id}
                      id={chord.id}
                      // doAllowReorder={allowDrag ? true : value === CURSOR_ID}
                      // doAllowReorder={allowDrag}
                      doAllowReorder={allowDrag && inputMode === "manual"}
                      isDragging={activeId === chord.id}
                      isCursor={chord.id === CURSOR_ID}
                    >
                      <ChordGridItem
                        chordCursorObj={chord}
                        // doesn't make sense to ellipsize: user needs to see what they tapped while on the edit screen; prevent excessive taps, and display whatever the user has tapped, there's no workaround here;
                        // chordCursorObj={{
                        //   ...chord,
                        //   context: {
                        //     ...chord.context,
                        //     rhythmCount: ellipsizeRhythmCount(
                        //       chord.context.rhythmCount
                        //     ),
                        //   },
                        // }}
                        selectedId={props.selectedId}
                        handleSetSelectedChord={props.handleSetSelectedChord}
                        handleCreateChord={props.handleCreateChord}
                        chordIndex={index}
                        handleTouchStart={() =>
                          props.handleTouchStartChord?.(chord.id)
                        }
                        // handleTouchStart={
                        //   inputMode === "manual"
                        //     ? undefined
                        //     : () => props.addRhythmTap(chord.id)
                        // }
                        tappedChordIdSet={props.tappedChordIdSet}
                      />
                    </SortableChord>
                  ))}
              </ChordGridContainer>
            </SortableContext>
            {createPortal(
              <DragOverlay adjustScale={false}>
                {activeIndex !== -1 ? (
                  // previous bug: rendered if activeId was non-null, instead of activeIndex; possible for activeId to be non-null, but activeIndex to be -1 (b/c element with that id no longer in array), e.g. other user deletes chord being referenced, and current user downloads updated data;
                  <ChordGridItem
                    chordCursorObj={props.sectionChordsWithContext[activeIndex]}
                    selectedId={props.selectedId}
                    handleSetSelectedChord={props.handleSetSelectedChord}
                    handleCreateChord={props.handleCreateChord}
                    chordIndex={activeIndex}
                    dragOverlay={true}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </DndWrapper>
      </ScrollableWrapper>

      {/* due to bug (or at least unexpected behavior), padding "collapses" in circumstances like this (https://bugzilla.mozilla.org/show_bug.cgi?id=748518); spacer is a great alternative */}
      <Spacer size={CREATE_UPDATE_CHORD_HEIGHT + 32} axis="vert" />
      {/* for use with a scrollable ScrollableWrapper; if using default behavior, use above */}
      {/* <Spacer size={CREATE_UPDATE_CHORD_HEIGHT} axis="vert" /> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  isolation: isolate;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  // to enable ScrollWrapper to do all the scrolling
  /* display: flex; */
  /* flex-direction: column; */
`;

const ScrollableWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  // solution to allow chord dragging/reordering without having to drag chord halfway through keyboard; however your PageContent will still scroll b/c of space it reserves for PageHeader; perhaps smartest solution would be to refactor: 1) inject CreateUpdateChord into a PageFooter (via page "Section"), and pass CREATE_UPDATE_CHORD_HEIGHT as the footerHeight; 2) then would need to create an option to display PageContent/Footer differently so they occupy different space instead of allowing COntent to scroll under Footer... would be a PITA...
  /* overflow-y: auto; */
`;

const ScrollZone = styled.div`
  flex: 0 0 50px;
  background-image: repeating-linear-gradient(
    0deg,
    var(--color-text-translucent),
    var(--color-text-translucent) 2px,
    transparent 1px,
    transparent
  );
  background-size: 8px 8px;
`;

const ScrollHereText = styled.div`
  position: sticky;
  top: ${48 + 24 + 24}px;

  /* margin:0 auto; is sufficient for Chrome, but FFX needed width:100%; (which is sufficient for both) */
  margin: 0 auto;
  width: 100%;

  display: flex;
  align-items: center;

  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: ${2 / 16}rem;

  font-size: ${19 / 16}rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-text-light);
  opacity: 0.8;
`;

const DndWrapper = styled.div`
  flex: 1;
  touch-action: var(--dndWrapper-touch-action);
`;
