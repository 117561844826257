import React from "react";

import { Page, PageContent, PageHeader } from "../_components/Page";
import { AdminQuery } from "./AdminQuery";

export function Admin() {
  return (
    <Page>
      <PageHeader title={"Admin"} />
      <PageContent>
        {(queryOptions) => {
          return <AdminQuery queryOptions={queryOptions} />;
        }}
      </PageContent>
    </Page>
  );
}
