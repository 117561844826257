import React from "react";
import styled from "styled-components/macro";

export const IndentedList = styled.div``;

export const IndentedListTitle = styled.h3``;

export const IndentedListGroup = styled.div`
  margin-bottom: ${16 / 16}rem;
`;

export const IndentedListLabel = styled.div`
  font-weight: 500;
  padding-bottom: ${8 / 16}rem;
`;

export const IndentedListItem = styled.div`
  font-weight: 500;
  /* margin-left: ${16 / 16}rem; */
  padding-left: ${8 / 16}rem;
  border-left: ${8 / 16}rem solid var(--color-text-translucent);
`;
