import React, { Suspense } from "react";

import { ErrorBoundary, ErrorUI } from "../_components";
import { QueryOptions } from "../_types/relayTypes";
import Button from "./Button";
import { Icon } from "./Icon";
import { LoadingSpinnerComplete } from "./LoadingSpinner";
import { ModalError } from "./ModalError";

type ChildrenProps = {
  fetchKey: number;
};

type Props = {
  // children?: React.ReactNode;
  children?:
    | React.ReactNode
    | (({ queryOptions }: { queryOptions: QueryOptions }) => React.ReactNode);
  // showErrorInModal?: boolean;
  showErrorInBody?: boolean;
  suspenseFallback?: React.ReactNode;
};

// *** This is incomplete!  No way to refresh/clear error, so error will perist; e.g. if query Settings page w/o server connection, both this (wrapping dark mode) and PageContentData will error, but can't clear this err, so will continue to pop up modal even after connection restored;

// *** at moment, this is used only to wrap UpgradeFeature, b/c you often use it outside of PageContent (which houses your main use of ErrorBoundary / suspense); may wish to combine some of the logic, esp. the option of showing a modal in this component; the modal is esp. useful in the context of UpgradeFeature, b/c it usually doesn't occupy a main content area (e.g. it may wrap a single header button, whereas use in PageContent wraps the entire... content of a page; in the latter case it makes sense to fill body with error, in former case, modal is needed to avoid overflowing text)

export function ErrorSuspenseWrapper(props: Props) {
  const [errMsg, setErrMsg] = React.useState<string>("");

  return (
    <ErrorBoundary
      fallback={({ error, clearError, retry }) => {
        console.log(error.message);
        if (props.showErrorInBody) {
          return <ErrorUI error={error} />;
        } else {
          return (
            <>
              <Button
                onClick={() => {
                  retry();
                }}
                fill="clear"
                palette="secondary"
                size="sm"
              >
                <Icon id="refresh-cw" size={24} />
              </Button>
              <ModalError errMsg={error.message} setErrMsg={setErrMsg}>
                <Button
                  onClick={() => {
                    retry();
                  }}
                  iconId="refresh-cw"
                  fill="clear"
                  palette="secondary"
                >
                  Retry
                </Button>
              </ModalError>
            </>
          );
        }
      }}
    >
      {({ fetchKey }) => (
        <Suspense
          fallback={
            props.suspenseFallback ?? <LoadingSpinnerComplete width={24} />
          }
        >
          {typeof props.children === "function"
            ? props.children({
                queryOptions: {
                  fetchKey,
                  fetchPolicy: "store-or-network",
                },
              })
            : props.children}
        </Suspense>
      )}
    </ErrorBoundary>
  );
}
