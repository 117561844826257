import React, { createContext } from "react";
// import { IAPStore } from "../_plugins/InAppPurchases";

const UniqueUserContext = createContext<{
  uniqueUser: string | null;
  // setUniqueUser: React.Dispatch<React.SetStateAction<string>>;
  updateUniqueUser: (user: string | null) => void;
}>({
  uniqueUser: null,
  updateUniqueUser: () => null,
});

// Note: you want to carefully control renders of this component (including any change to uniqueUser); when this component re-renders, it will cause RelayEnvironment to generate a new environment, thus wiping the Relay cache; for example, you cannot use useDebugLog here: useDebugLog relies upon AppContext and React.useContext() forces re-renders when the target context updates;

const UniqueUserProvider: React.FC = (props) => {
  const [uniqueUser, setUniqueUser] = React.useState<string | null>(null);

  function updateUniqueUser(user: string | null) {
    setUniqueUser(user);
    if (user) {
      // user changing to non-null means a login or account creation, and means localStorage[AUTH_STORAGE_KEY]will be populated with JWT needed to speak with server; refresh IAP plugin to push any purchased products through verification process;
      // if want to test manual "restore purchases" feature, deactivate this

      // @@@ disable in-app purchases @@@
/*       console.log("New unique user; refreshing IAP store.");
      IAPStore.update(); */
    }
  }

  return (
    <UniqueUserContext.Provider
      // could pass these values as a tuple "[]" to create a similar interface on useContext as you have on useReducer, e.g.: https://stackoverflow.com/a/59432211/6281555
      value={{ uniqueUser, updateUniqueUser }}
      key={uniqueUser}
    >
      {/* {children} */}
      {typeof props.children === "function"
        ? props.children({ renderKey: uniqueUser })
        : props.children}
    </UniqueUserContext.Provider>
  );
};

export { UniqueUserProvider, UniqueUserContext };
