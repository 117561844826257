import { TestEventType } from "../__generated__/AddSongTestEventMutation.graphql";

export const BOX_COUNT = 5;

// subset of songNode
export type LeitnerCard = {
  id: string;
  title: string;
  artist: string;
  tags: readonly string[];
  testHistory: readonly {
    readonly song: string;
    readonly eventType: TestEventType;
    readonly time: string;
  }[];
  songStats: {
    boxIndex: number;
    timesTested: number;
    timesRemembered: number;
    recallRate: number;
    recallRateHistory: readonly number[];
    recallRateHistoryRecent: readonly number[];
    lastViewDidRemember: boolean | null;
    timeLastTested: string;
    timeLastReviewed: string;
  };
};

// card is due if current local calendar day (midnight-midnight) is equal to or greater than the local calendar day of timeLastReviewed plus the number of days prescribed for next review (by that card's boxIntervals number)
function isCardDue(testDate: Date, daysUntilDue: number = 1): boolean {
  var now = new Date();
  var todayMidnightLocal = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  var testDateMidnightLocal = new Date(
    testDate.getFullYear(),
    testDate.getMonth(),
    testDate.getDate()
  );
  var testDateMidnightLocalIncremented = new Date(testDateMidnightLocal);
  testDateMidnightLocalIncremented.setDate(
    testDateMidnightLocalIncremented.getDate() + daysUntilDue
  );
  // console.log(`is ${todayMidnightLocal} >= ${testDateMidnightLocalIncremented} - ${(todayMidnightLocal >= testDateMidnightLocalIncremented)}`);
  if (todayMidnightLocal >= testDateMidnightLocalIncremented) {
    return true;
  }
  return false;
}

// returns array of cards and tags due today
function filterCardsDueToday(
  cards: LeitnerCard[],
  boxIntervals: readonly number[]
) {
  var cardIsDue: boolean,
    tagsDueToday: any[] = [];

  var cardsDueToday = cards.filter((thisCard) => {
    cardIsDue = isCardDue(
      new Date(thisCard.songStats.timeLastReviewed),
      boxIntervals[thisCard.songStats.boxIndex]
    );
    if (cardIsDue) {
      thisCard.tags.forEach((tag) => {
        if (tagsDueToday.indexOf(tag) === -1) {
          tagsDueToday.push(tag);
        }
      });
      return true;
    }
    return false;
  });
  return {
    cardsDueToday,
    tagsDueToday,
  };
}

export function createSortedDeck(
  cards: LeitnerCard[],
  boxIntervals: readonly number[]
) {
  var { cardsDueToday, tagsDueToday } = filterCardsDueToday(
    cards,
    boxIntervals
  );
  var sortedDueToday = cardsDueToday.sort((c1, c2) => {
    // *** could base order on box... e.g. to give user what you may assume are "easier" cards first (those in higher boxes, for which the user has proven greater competence); for now, simply sort based on timeLastReviewed
    var c1Weight = Date.parse(c1.songStats.timeLastReviewed),
      c2Weight = Date.parse(c2.songStats.timeLastReviewed);

    if (c1Weight - c2Weight === 0) {
      // in event weights are equal (would happen only if timeLastReviewed is identical), let timeLastTested break the tie *** this is *vital* because you default timeLastReviewed for all cards as the same time ("0" UNIX time); without this tiebreaker, the following will occur *only* in development mode Safari: 1) in Test, user indicates he "forgot" card; 2) card is updated in server and then in local state; 3) updating the card's prop in the "cards" value in local state results in the order of those object properties changing (when you extract an array from that object with Object.keys()); 4) finally, when this sort occurs again, the just-updated card (which had been the first item in the array) is now sorted after *all* cards with the same "0" timeLastReviewed; 5) thus, instead of showing the "back" of the card that was just marked "forgotten", the display shows the "front" of the next card in the deck; *** alternatively, could do away with this tiebreaker if you default timeLastReviewed to the time the song was created
      return (
        Date.parse(c2.songStats.timeLastTested) -
        Date.parse(c1.songStats.timeLastTested)
      );
    } else {
      return c1Weight - c2Weight;
    }
  });

  return {
    sortedDueToday,
    tagsDueToday,
  };
}

export function countReviewedToday(cards: LeitnerCard[]) {
  const todayStart = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  var reviewedToday = 0;
  cards.forEach((card) => {
    if (Date.parse(card.songStats.timeLastReviewed) > todayStart) {
      reviewedToday++;
    }
  });
  return reviewedToday;
}

// https://www.typescriptlang.org/docs/handbook/2/functions.html#constraints
export function filterSongs<
  ObjWithTitleArtistTags extends {
    title: string;
    artist: string;
    tags: readonly string[];
  }
>(songs: ObjWithTitleArtistTags[], tagFilter: string[], searchFilter: string) {
  const filteredByTags = filterByTags(songs, tagFilter);
  const filteredByTagsTitleArtist = filterByTitleArtist(
    filteredByTags,
    searchFilter
  );
  return filteredByTagsTitleArtist;
}

export function filterByTags<
  ObjWithTags extends {
    tags: readonly string[];
  }
>(arr: ObjWithTags[], tagFilter: string[]) {
  const filtered = arr.filter((val) => {
    const { tags } = val;
    // check for match of all filtered tags
    for (let filteredTag of tagFilter) {
      if (tags.indexOf(filteredTag) === -1) {
        return false;
      }
    }
    return true;
  });
  return filtered;
}

export function filterByTitleArtist<
  ObjWithTitleArtist extends {
    title: string;
    artist: string;
  }
>(arr: ObjWithTitleArtist[], searchFilter: string) {
  const filtered = arr.filter((val) => {
    const { title, artist } = val;
    // check for matching string in title/artist
    const value = searchFilter.trim().toLowerCase();
    var titleHit = title.toLowerCase().indexOf(value);
    var artistHit = artist.toLowerCase().indexOf(value);
    return titleHit > -1 || artistHit > -1;
  });
  return filtered;
}

export function getAnticipatedBoxIndex(
  boxIndex: number,
  lastViewDidRemember: boolean | null
) {
  // if forgot, move song to lowest box
  var newBoxIndex = 0;
  // if remembered, move up one box, not exceeding max box
  if (lastViewDidRemember) {
    newBoxIndex = Math.min(BOX_COUNT - 1, boxIndex + 1);
  }
  return newBoxIndex;
}
