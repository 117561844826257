import React from "react";
import styled from "styled-components/macro";

type TitleArtistProps = {
  title: string;
  artist: string;
};

export const TitleArtist = (props: TitleArtistProps) => (
  <TitleArtistWrapper>
    <Title>{props.title}</Title>
    <Artist>{props.artist}</Artist>
  </TitleArtistWrapper>
);

const TitleArtistWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
`;

const SongMeta = styled.div`
  /* these styles, in concert, create an "organic", responsive ellipsizing; at least for this context, preferable to your prior fixed solution  */
  /* node: you needed to ensure this component, and its parent, inherited width of ancestors, instead of simply hiding their overflow "behind" them, preventing the text-overflw from occurring */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled(SongMeta)`
  font-size: ${18 / 16}rem;
  line-height: 1.2;
`;
const Artist = styled(SongMeta)`
  font-size: ${16 / 16}rem;
  color: var(--color-text-medium);
  line-height: 1.25;
`;
