import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import { DisplaySection_song$key } from "../__generated__/DisplaySection_song.graphql";
import { DisplaySection_section$key } from "../__generated__/DisplaySection_section.graphql";

import { ChordOrCursor } from "../_types/chordTypes";

import ChordItem from "../ChordsSimple/ChordGrid/ChordItem";
import { ChordGridContainer } from "../ChordsSimple/ChordGrid/ChordGridContainer";
import { addChartContext, splitChordCells } from "../_music/addChartContext";
import { DisplaySectionMeta, Empty } from "./DisplaySectionMeta";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import {
  TransposeAccidentalType,
  transposeSection,
} from "../_components/Transpose";
import { APP_CONFIG } from "../_constants";
import { RhythmDisplay } from "../_reducers";
import { AppContext } from "../_context/appContext";
import { TIME_SIGS_DEFAULT } from "../_music/rhythmCount";
import { RhythmUnit } from "../_types/sectionTypes";

type Props = {
  songRef: DisplaySection_song$key;
  sectionRef: DisplaySection_section$key;
  transposeVal: number;
  accidentalsVal: TransposeAccidentalType;
  rhythmDisplay: RhythmDisplay;
};

export function DisplaySection(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const history = useHistory();

  const song = useFragment(
    graphql`
      fragment DisplaySection_song on Song {
        id
        timeSig
        viewerPermission {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment DisplaySection_section on SongSection {
        id
        name
        notes
        timeSig
        rhythmUnit
        chords {
          id
          duration
          rhythm
          definition {
            root
            qual
            bass
          }
          notation
        }
      }
    `,
    props.sectionRef
  );

  // *** not ideal typing, as this read-only presentation of chords will never include a cursor; did it this way to make it match ChordsSimple, as goal is to keep presentation of chords the same whether in a read/write mode, and chart context is needed to display relations b/w chords e.g. notation
  var sectionChords = section.chords as ChordOrCursor[];
  var transposedChords = transposeSection(
    sectionChords,
    props.transposeVal,
    props.accidentalsVal
  );
  const timeSigInherited =
    section.timeSig === TIME_SIGS_DEFAULT ? song.timeSig : section.timeSig;
  let sectionChordsWithContext = addChartContext(
    transposedChords,
    section.rhythmUnit as RhythmUnit,
    timeSigInherited
  );
  if (
    state.app.rhythmDisplay === "count" ||
    state.app.rhythmDisplay === "all"
  ) {
    sectionChordsWithContext = splitChordCells(
      sectionChordsWithContext,
      APP_CONFIG.song.maxRhythmSymbolsPerChordTile,
      section.rhythmUnit as RhythmUnit
    );
  }

  return (
    <Wrapper>
      <MetaEditWrapper>
        <DisplaySectionMeta
          name={section.name}
          notes={section.notes}
          timeSig={
            section.timeSig === song.timeSig ||
            section.timeSig === TIME_SIGS_DEFAULT
              ? undefined
              : timeSigInherited
          }
        />
        {song.viewerPermission.canEdit && (
          <EditButtonWrapper>
            <Button
              onClick={() =>
                history.push(`/section/${song.id}/${section.id}`, {
                  backPath: `/song/${song.id}`,
                })
              }
              fill="clear"
              palette="secondary"
              size="sm"
            >
              <IconWrapper>
                <Icon id={"edit-2"} size={14} strokeWidth={1} />
              </IconWrapper>
            </Button>
          </EditButtonWrapper>
        )}
      </MetaEditWrapper>

      {sectionChordsWithContext.length > 0 ? (
        <ChordGridContainer>
          {sectionChordsWithContext.map((chord, index) => {
            return (
              <ChordItem
                key={chord.id}
                chordCursorObj={chord}
                chordIndex={index}
                rhythmDisplay={props.rhythmDisplay}
                context="display"
              />
            );
          })}
        </ChordGridContainer>
      ) : (
        // if a section has notes, don't show placeholder
        <>{!section.notes && <Empty>[No chords]</Empty>}</>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const MetaEditWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /* button will be 44px; ensure consistent height;  */
  min-height: 44px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const EditButtonWrapper = styled.div`
  /* keep aligned with song edit button and rightside header button, if keep right aligned */
  min-width: 56px;
`;
