/**
 * @generated SignedSource<<ab7935808f6061d072c8111cf4cd251c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserProductGroup = "plus" | "pro" | "%future added value";
export type UserProductId = "dom7_plus" | "dom7_pro_annual" | "dom7_pro_monthly" | "%future added value";
export type UserProductStore = "android" | "ios" | "%future added value";
export type UserProductType = "NON_CONSUMABLE" | "PAID_SUBSCRIPTION" | "%future added value";
export type User_Role = "ADMIN" | "ANON" | "USER" | "%future added value";
export type loginUserInput = {
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type LoginUserMutation$variables = {
  input: loginUserInput;
};
export type LoginUserMutation$data = {
  readonly loginUser: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message?: string;
    }>;
    readonly jwt: {
      readonly expiresAt: string | null;
      readonly expiresIn: string | null;
      readonly token: string | null;
    } | null;
    readonly user: {
      readonly boxIntervals: ReadonlyArray<number>;
      readonly email: string;
      readonly id: string;
      readonly products: ReadonlyArray<{
        readonly expiresAt: string | null;
        readonly isVerified: boolean;
        readonly productGroup: UserProductGroup;
        readonly productId: UserProductId;
        readonly productStore: UserProductStore;
        readonly productType: UserProductType;
      }>;
      readonly roles: ReadonlyArray<User_Role>;
    } | null;
  } | null;
};
export type LoginUserMutation = {
  response: LoginUserMutation$data;
  variables: LoginUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "loginUserPayload",
    "kind": "LinkedField",
    "name": "loginUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JsonWebToken",
        "kind": "LinkedField",
        "name": "jwt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresIn",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserAuthDetails",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boxIntervals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProduct",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productStore",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVerified",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "UserError",
            "abstractKey": "__isUserError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d33bc7a792d54e331711a831c846a3fa",
    "id": null,
    "metadata": {},
    "name": "LoginUserMutation",
    "operationKind": "mutation",
    "text": "mutation LoginUserMutation(\n  $input: loginUserInput!\n) {\n  loginUser(input: $input) {\n    jwt {\n      token\n      expiresIn\n      expiresAt\n    }\n    user {\n      id\n      email\n      roles\n      boxIntervals\n      products {\n        productGroup\n        productStore\n        productType\n        productId\n        isVerified\n        expiresAt\n      }\n    }\n    errors {\n      __typename\n      ... on UserError {\n        __isUserError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3aa78464038d8d271fd923387ae5caf1";

export default node;
