import React from "react";
import styled from "styled-components/macro";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";

import Spacer from "./Spacer";
import { AppContext } from "../_context/appContext";
import { AppActionTypes } from "../_reducers";
import Button from "./Button";
import { useDebugLog } from "../_hooks/useDebugLog";
import { storage } from "../_plugins";
import { APP_STORAGE_KEY, AUTH_STORAGE_KEY } from "../_constants";
import { animated } from "@react-spring/web";
import useLongPress from "../_utilities/useLongPress";
import { RippleButton } from "./RippleButton";
import { Icon } from "../_components/Icon";

// *** allow clear individual log entries (x button)

// *** add "search logs" and filter log entries by input string

function getSecondsElapsed(t1: Date, t2: Date) {
  return Math.floor((t2.getTime() - t1.getTime()) / 1000);
}

const LOG_TEXT_COUNT_WHEN_COLLAPSED = 2;

type Props = {
  children: React.ReactNode;
};

// export const DebugLog: React.FC = () => {
export function DebugLog(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const { isInDebugMode, debugLog } = state.app;

  const [clickCount, setClickCount] = React.useState<number>(0);
  function incrementClickCount() {
    var nextClickCount = clickCount + 1;
    setClickCount(nextClickCount);
    if (nextClickCount > 0 && nextClickCount % 7 === 0) {
      toggleDebugMode();
    }
  }
  function toggleDebugMode() {
    dispatch({
      type: AppActionTypes.SetDebugMode,
      payload: !isInDebugMode,
    });
  }

  const [highlightedLog, setHighlightedLog] = React.useState<number | null>(
    null
  );

  // inspiration: https://stackoverflow.com/questions/64967082/scroll-into-view-in-react
  /*   const scrollRefs = React.useRef<React.RefObject<HTMLButtonElement>[]>([]);
  React.useEffect(() => {
    scrollRefs.current = [...Array(debugLog.length)].map(
      // this is perhaps a more efficient way to create the refs, re-using pre-existing ones instead of re-creating all of them when debugLog changes
      (_, i) => scrollRefs.current[i] ?? React.createRef()
    );
  }, [debugLog]);
  function scrollSmoothHandler(index: number) {
    if (scrollRefs && scrollRefs.current) {
      scrollRefs.current[index]?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  } */

  const [logCollapse, setLogCollapse] = React.useState<boolean[]>([]);
  /*   React.useEffect(() => {
    // collapse all logs when new log added; this seemed like a good idea, but was damn annoying when new logs created quickly, e.g. when you were testing usePullRefresh and every touch was generating a new log...
    setLogCollapse(Array(debugLog.length).fill(true));
  }, [debugLog]); */

  function collapseAll() {
    setLogCollapse(Array(debugLog.length).fill(true));
  }

  function expandAll() {
    setLogCollapse(Array(debugLog.length).fill(false));
  }

  // *** thinking this is causing a significant performance hit; was using this to update "seconds elapsed" display on logs
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  /*   const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  React.useEffect(() => {
    let intervalForceUpdate = setInterval(() => {
      forceUpdate();
    }, 5000);
    return function cleanup() {
      clearInterval(intervalForceUpdate);
    };
  }, [forceUpdate]); */

  function toggleCollapseLog(logIndex: number) {
    setLogCollapse((state) => {
      let stateClone = [...state];
      stateClone[logIndex] = !stateClone[logIndex];
      return stateClone;
    });
  }

  const { setDebugLog } = useDebugLog("DebugLog");

  function clearLog() {
    dispatch({
      type: AppActionTypes.ClearDebugLog,
      payload: null,
    });
  }
  async function copyLogToClipboard(logIndex?: number) {
    try {
      let logPortionToCopy =
        logIndex !== undefined ? debugLog[logIndex] : debugLog;
      await Clipboard.write({
        string: JSON.stringify(logPortionToCopy, null),
      });
      // const { type, value } = await Clipboard.read();
      // console.log(`Got ${type} from clipboard: ${value}`);
    } catch (err) {
      console.log(err);
    }
  }
  async function shareLog() {
    try {
      await Share.share({
        title: `Dom7 Log ${new Date().toISOString()}`,
        text: JSON.stringify(debugLog, null),
        url: "https://www.grantkidner.com",
        dialogTitle: "Share Debug Log",
      });
      setDebugLog(["Share Plugin Success"]);
    } catch (err) {
      setDebugLog(["Share Plugin Error: ", err]);
      console.log(err);
    }
  }

  async function wipeLocalStorage() {
    try {
      await storage.removeItem(AUTH_STORAGE_KEY);
      await storage.removeItem(APP_STORAGE_KEY);
      document.location.reload();
    } catch (err) {
      setDebugLog(["Wipe storage error:", err]);
    }
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 1000,
  };
  const longPressClearLog = useLongPress(clearLog, () => null, defaultOptions);
  const longPressWipeLocalStorage = useLongPress(
    wipeLocalStorage,
    () => null,
    defaultOptions
  );

  return (
    <>
      <TapToActivate onClick={incrementClickCount}>
        {props.children}
      </TapToActivate>
      {isInDebugMode && (
        <LogWrapper>
          <Button size="sm" fill="clear" onClick={expandAll} expand="inline">
            Expand
          </Button>
          <Button size="sm" fill="clear" onClick={collapseAll} expand="inline">
            Collapse
          </Button>
          <Button
            size="sm"
            fill="clear"
            onClick={() => copyLogToClipboard()}
            expand="inline"
          >
            Copy
          </Button>
          <Button size="sm" fill="clear" onClick={shareLog} expand="inline">
            Share
          </Button>
          <br />
          <Button
            size="sm"
            fill="clear"
            onClick={toggleDebugMode}
            expand="inline"
            palette="secondary"
          >
            Debug Off
          </Button>
          <AnimatedButton
            size="sm"
            fill="clear"
            {...longPressClearLog}
            expand="inline"
            palette="danger"
          >
            Clear
          </AnimatedButton>
          <AnimatedButton
            size="sm"
            fill="clear"
            {...longPressWipeLocalStorage}
            expand="inline"
            palette="danger"
          >
            Wipe
          </AnimatedButton>
          {debugLog.map((log, logIndex) => {
            return (
              <LogEntry
                // ref={scrollRefs.current[logIndex]}
                key={logIndex}
                onClick={() => {
                  toggleCollapseLog(logIndex);
                  copyLogToClipboard(logIndex);
                  setHighlightedLog(logIndex);
                  // liked the idea of scrolling to activated log, but this implementation: 1) takes it to the top of the screen in iOS, which partially concealse under header; and 2) causes a significant delay on click;
                  // scrollSmoothHandler(logIndex);
                }}
                style={{
                  ...(highlightedLog === logIndex &&
                    ({
                      backgroundColor: "var(--color-highlight-light)",
                    } as React.CSSProperties)),
                }}
              >
                <LogHeader>
                  {/* used below test when tried using only slice (not line clamp) to limit vertical length of LogText; doesn't make sense with line-clamp as can't reasonably programmatically know if the browser hid overflow due to line-clamp */}
                  {/* {log.content.length > LOG_TEXT_COUNT_WHEN_COLLAPSED ? ( */}
                  <Icon
                    id={
                      logCollapse[logIndex] ? "chevron-right" : "chevron-down"
                    }
                    size={16}
                  />
                  {/* ) : (
                    <Spacer size={16} />
                  )} */}
                  <LogTime>
                    {/* show only time portion of ISO string */}
                    {log.time.slice(11)}
                    {logIndex < debugLog.length - 1 && (
                      <>
                        {" (+"}
                        {getSecondsElapsed(
                          new Date(debugLog[debugLog.length - 1].time),
                          new Date(log.time)
                        )}
                        {/* {getSecondsElapsed(new Date(log.time), new Date())} */}
                        {"s) "}
                      </>
                    )}
                  </LogTime>
                  {log.source && <LogSource>({log.source})</LogSource>}
                </LogHeader>
                <LogContent>
                  {log.content
                    .slice(
                      0,
                      logCollapse[logIndex]
                        ? LOG_TEXT_COUNT_WHEN_COLLAPSED
                        : undefined
                    )
                    .map((item, i) => {
                      return (
                        <LogText
                          key={i}
                          style={
                            {
                              "--webkit-line-clamp": logCollapse[logIndex]
                                ? 5
                                : "none",
                            } as React.CSSProperties
                          }
                        >
                          {typeof item === "object"
                            ? JSON.stringify(item, null, "\t")
                            : item}
                          {i === log.content.length - 1 && <>{"\n###"}</>}
                        </LogText>
                      );
                    })}
                </LogContent>
              </LogEntry>
            );
          })}
          <Spacer size={96} axis="vert" />
        </LogWrapper>
      )}
    </>
  );
}

const TapToActivate = styled.div``;

const LogWrapper = styled.div`
  width: 100%;
`;
const LogEntry = styled(RippleButton)`
  border-bottom: 1px dotted black;
  user-select: text;
  /* override RippleButton styles */
  --color-ripple: var(--color-background-tint);
  width: 100%;
  padding: 0px;
  text-align: left;
  background: none;
  /* color: black; */
  &:nth-child(even) {
    background-color: var(--color-background-tint);
  }
`;
const LogHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;
const LogTime = styled.span`
  font-weight: 500;
  user-select: text;
`;
const LogSource = styled.span`
  font-weight: 500;
  user-select: text;
  margin-left: auto;
`;
const LogContent = styled.div``;

// *** if this fixes line-clamp sometimes failing in Safari (and instead of clamping, causing lines of text to appear on top of each other), it's because you originally applied the line-clamp properties to a parent div and expected it to apply to child divs, which fails in a known Safari bug: https://stackoverflow.com/questions/70679732/css-line-clamp-does-not-work-in-safari-on-inner-block-level-elements/70683084#70683084
const LogText = styled.div`
  overflow-wrap: anywhere;
  /* preserve line breaks "\n" and tabs "\t" in user-entered text */
  white-space: pre-wrap;
  user-select: text;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--webkit-line-clamp);
  overflow: hidden;
`;

const AnimatedButton = animated(Button);
