import React from "react";
import styled from "styled-components/macro";

import {
  // Slider,
  SliderInput,
  SliderTrack,
  SliderTrackProps,
  // SliderRange,
  SliderHandle,
  SliderMarker,
  // SliderMarkerProps,
} from "@reach/slider";
import "@reach/slider/styles.css";

import { createRange } from "../_utilities";
import { hapticsImpactLight } from "../_plugins/Haptics";
import { RippleButton } from "../_components/RippleButton";
import { useSimpleTouch } from "../_components/GKeyboard/useSimpleTouch";
import { useDelayFnc } from "../_hooks/useDelayFnc";

export function getDurationStrings(duration: number) {
  if (duration === 0) {
    return { wholeNum: "", fraction: "" };
  }
  const wholeNum = duration >= 1 ? `${Math.floor(duration)}` : "";
  const remainder = duration % 1;
  var fraction = "";
  if (remainder !== 0) {
    if (remainder === 0.25) {
      fraction = `\u00BC`;
    } else if (remainder === 0.5) {
      fraction = `\u00BD`;
    } else if (remainder === 0.75) {
      fraction = `\u00BE`;
    } else {
      // this may occur for a fraction of a second when animate DurationSlider in, leaving tap mode (occurs in CreateUpdateChord); the fncs in this component that change the slider DisplayMode to suit the duration given then kick in, and all is well
      /*  console.log(
        "Unsupported remainder in getDurationStrings",
        remainder,
        duration
      ); */
    }
  }
  return { wholeNum, fraction };
}

// https://stackoverflow.com/a/54061487
const DISPLAY_MODES = [
  "WHOLE_EIGHT",
  "HALF_EIGHT",
  "QUARTER_FOUR",
  "WHOLE_SIXTEEN",
] as const;
export type DisplayMode = (typeof DISPLAY_MODES)[number];
const MAX_VAL = 16;
const DURATION_RANGES = {
  // WHOLE_EIGHT: createRange(MAX_VAL / 2, (index) => index + 1),
  WHOLE_EIGHT: createRange(MAX_VAL / 2 + 1, (index) => index),
  WHOLE_SIXTEEN: createRange(MAX_VAL + 1, (index) => index),
  HALF_EIGHT: createRange(MAX_VAL + 1, (index) => index / 2),
  QUARTER_FOUR: createRange(MAX_VAL + 1, (index) => index / 4),
};

type Props = {
  duration: number;
  handleEditChord: ({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) => void;
  disabled?: boolean;
};

export function DurationSlider({ duration, handleEditChord, disabled }: Props) {
  const { touchRef, isActive } = useSimpleTouch({
    actionOnTouchEnd: undefined,
  });

  const { delayFnc } = useDelayFnc();
  const [sliderState, setSliderState] = React.useState<{
    displayMode: DisplayMode;
    // array index of duration value
    sliderVal: number;
  }>({
    // *** IDEALLY, would set correct displayMode on first render, instead of defaulting to WHOLE_EIGHT (which will have to be changed immediately if 2x or 1/2 needed)
    //
    //
    //
    displayMode: DISPLAY_MODES[0],
    sliderVal: DURATION_RANGES[DISPLAY_MODES[0]].indexOf(duration),
  });
  const durationValues = DURATION_RANGES[sliderState.displayMode];

  const durationStrings = getDurationStrings(
    durationValues[sliderState.sliderVal]
  );

  function cycleSetDisplayMode() {
    let currentModeIndex = DISPLAY_MODES.indexOf(sliderState.displayMode);
    let newModeIndex =
      currentModeIndex === DISPLAY_MODES.length - 1 ? 0 : currentModeIndex + 1;
    let newMode = DISPLAY_MODES[newModeIndex];
    let newDuration = duration;

    if (newMode === "WHOLE_EIGHT") {
      // change from "WHOLE_SIXTEEN"; if val is greater than max val avail on slider, set to max;
      if (duration > MAX_VAL / 2) {
        newDuration = MAX_VAL / 2;
      }
    } else if (newMode === "HALF_EIGHT") {
      // change from "WHOLE_EIGHT"; HALF_EIGHT includes all WHOLE_EIGHT values; do nothing;
    } else if (newMode === "QUARTER_FOUR") {
      // change from "HALF_EIGHT"; if val is greater than max val avail on slider, set to max;
      if (duration > MAX_VAL / 4) {
        newDuration = MAX_VAL / 4;
      }
    } else if (newMode === "WHOLE_SIXTEEN") {
      // change from "QUARTER_FOUR"; WHOLE_SIXTEEN has only whole values: if current duration is fractional, round duration to nearest integer above 0; useEffect will set slider to new value;
      newDuration = Math.max(Math.round(duration), 1);
    }
    let newSliderVal = DURATION_RANGES[newMode].indexOf(newDuration);
    setSliderState((prev) => ({
      ...prev,
      displayMode: newMode,
      // updating sliderVal when handleEditChord in cycleSetDisplayMode... it not strictly necessary, but will result in handle updating slightly faster, akin to when drag handle
      sliderVal: newSliderVal,
    }));
    if (newDuration !== duration) {
      handleEditChord({
        name: "duration",
        value: newDuration,
      });
    }
  }

  React.useEffect(() => {
    let newMode = sliderState.displayMode;
    if (durationValues.indexOf(duration) === -1) {
      // if duration changes, and doesn't exist in current slider range options, change displayMode
      if (duration > MAX_VAL / 2) {
        // only WHOLE_SIXTEEN has a range this high;
        newMode = "WHOLE_SIXTEEN";
      } else if (duration % 1 === 0.5) {
        newMode = "HALF_EIGHT";
      } else if (duration % 1 === 0.25 || duration % 1 === 0.75) {
        newMode = "QUARTER_FOUR";
      } else if (sliderState.displayMode === "QUARTER_FOUR" && duration > 4) {
        // QUARTER_FOUR is only range that doesn't reach 8;
        newMode = "HALF_EIGHT";
      }
    }
    let newVal = DURATION_RANGES[newMode].indexOf(duration);
    setSliderState((prev) => ({
      ...prev,
      displayMode: newMode,
      sliderVal: newVal,
    }));
  }, [
    duration,
    // check displayMode to handle e.g. duration 4 remaining at "4" on slider as user switches modes (otherwise index on slider will stay the same and sliderVal will become "2" when switched to 1/2 displayMode)
    sliderState.displayMode,
    // durationValues included in dependency array only to satisfy eslint:react-hooks/exhaustive-deps; if it appears this change is causing unnecessary renders, consider reverting
    durationValues,
  ]);

  function handleChange(newValue: number) {
    if (newValue === sliderState.sliderVal) return;
    hapticsImpactLight();
    setSliderState((prev) => ({ ...prev, sliderVal: newValue }));

    const callback = () => {
      var selectedDuration: number = durationValues[newValue];
      handleEditChord({
        name: "duration",
        value: selectedDuration,
      });
    };
    delayFnc({ callback, delay: 50 });
  }

  return (
    <Wrapper style={{ opacity: disabled ? 0.5 : 1.0 }}>
      <DurationSliderInput
        name="duration"
        min={0}
        max={durationValues.length - 1}
        step={1}
        value={sliderState.sliderVal}
        onChange={handleChange}
        disabled={disabled}
      >
        {/* casting ReachUI components with styled-components sometimes throws type errors, e.g. for SliderTrack and SliderMarker.  One solution: use "as" prop (worked for Track, but not Marker); the other is to cast with the Reach prop types, as seen below for both of these components. 
        https://github.com/reach/reach-ui/issues/724
        */}
        <SliderTrack
          as={DurationSliderTrack}
          $isActive={isActive}
          ref={touchRef}
        >
          {/* <DurationSliderTrack $isActive={isActive}> */}
          {durationValues.map((x, index) => {
            function getMarkerHeight(markerVal: number) {
              if (markerVal % 4 === 0) {
                return "20px";
              } else if (markerVal % 1 === 0) {
                return "14px";
              } else {
                return "8px";
              }
            }
            const markerStyle = {
              "--height": getMarkerHeight(durationValues[index]),
            };
            return (
              // *** this was fine when using linear set of vals (1-16), but DurationSliderMarker uses sliderVal to determine placement of the marker, so a value <1 resulted in a negative value, removing the marker
              // <DurationSliderMarker key={x} value={x - 1} />
              <DurationSliderMarker
                key={index}
                value={index}
                style={markerStyle as React.CSSProperties}
              />
            );
          })}
          {/* <DurationSliderRange /> */}
          <SliderHandle
            as={DurationSliderHandle}
            $isActive={isActive}
            // ref={touchRef}
          >
            <CurrentValueBubbleConnect />
            <CurrentValueBubble
              style={
                {
                  "--font-size":
                    sliderState.displayMode !== "WHOLE_EIGHT"
                      ? `${18 / 16}rem`
                      : `${19 / 16}rem`,
                } as React.CSSProperties
              }
            >
              <FormattedDuration>
                {durationStrings.wholeNum !== "" && (
                  <WholeNumDuration>
                    {durationStrings.wholeNum}
                  </WholeNumDuration>
                )}
                {durationStrings.fraction !== "" && (
                  <FractionDuration>
                    {durationStrings.fraction}
                  </FractionDuration>
                )}
                {durationStrings.wholeNum === "" &&
                  durationStrings.fraction === "" && (
                    <WholeNumDuration>-</WholeNumDuration>
                  )}
              </FormattedDuration>
            </CurrentValueBubble>
          </SliderHandle>
        </SliderTrack>
        {/* </DurationSliderTrack> */}
      </DurationSliderInput>
      <ButtonWrapper>
        <ToggleButton
          disabled={disabled}
          onClick={cycleSetDisplayMode}
          // this style used to follow default behavior for ReactUI SliderInput when fed "disabled" prop
          // style={{ opacity: disabled ? 0.5 : 1.0 }}
        >
          {
            {
              WHOLE_EIGHT: "1x",
              WHOLE_SIXTEEN: "2x",
              HALF_EIGHT: "\u00BD",
              QUARTER_FOUR: "\u00BC",
            }[sliderState.displayMode]
          }
        </ToggleButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 8px;
`;

const DurationSliderInput = styled(SliderInput)`
  flex: 1;
  padding: 0 20px;

  /* explanation of the "&&" in this component: https://github.com/reach/reach-ui/issues/116 */
  && {
    /* height: 40px; */
    height: 18px;
  }

  &[data-reach-slider-input][data-disabled] {
    /* override default "disabled" behavior and apply opacity to wrapper component, to address bug seen on iOS where slider would become only partially transparent; */
    opacity: 1;
  }
`;

const DurationSliderMarker = styled(
  SliderMarker
  // SliderMarker as React.FC<SliderMarkerProps>
)`
  background: var(--color-text-translucent);
  box-shadow: none;
  transition: all 200ms;
  && {
    height: var(--height);

    // below 3 lines, and height of DurationSliderInput were effort to simplify this component by overriding more of the default Reach styles; maintaining record of prior state in case I missed something and need to revert... next time you read this, can tidy it
    // margin-top: ${({ value }) => ((value + 1) % 4 === 0 ? "3px" : "0px")};
    top: 0;
    transform: translateX(-50%);
  }
`;

const SliderHandleHeight = 28;
const SliderHandleWidth = 24;
const CurrentValueBubble = styled.div`
  --width: 30px;
  position: absolute;
  top: 0;
  height: ${SliderHandleHeight}px;
  left: calc(${SliderHandleWidth}px / 2 - var(--width) / 2);
  background-color: var(--color-primary-dark);
  width: var(--width);
  border-radius: 8px 8px 2px 2px;
  color: var(--color-text-contrast);
  transform: translateY(-6px) scale(1.1);
  transition: transform 0.2s, scale 0.2s, box-shadow 0.2s, background-color 0.2s;
  /* transition-delay: 0.05s; */
  user-select: none;

  /* this element is for display only, and isn't a true part of the slider; prevent it from taking pointer events */
  pointer-events: none;

  font-size: var(--font-size);
  box-shadow: 0 0 1px var(--color-shadow);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CurrentValueBubbleConnect = styled(CurrentValueBubble)`
  transform: translateY(-2px) scale(1);
  transition: transform 0.1s, scale 0.1s, box-shadow 0.1s;
  /* transition-delay: 0s; */
`;

const DurationSliderHandle = styled(SliderHandle)<{ $isActive: boolean }>`
  position: relative;
  margin-top: -2px;
  width: ${SliderHandleWidth}px;
  height: ${SliderHandleHeight}px;
  background-color: var(--color-primary-dark);
  border-radius: 8px 8px 2px 2px;
  &:focus-visible {
    /* outline is a poor choice for duration slider, due to unusal shape and layered elements */
    outline: none;
  }
  ${({ $isActive }) =>
    !$isActive &&
    `
   &:focus-visible {
    background-color: var(--color-primary-translucent-85);
     ${CurrentValueBubble} {
      transform: translateY(-12px) scale(1.1);
      box-shadow: 0px 1px 1px var(--color-shadow);
      background-color: var(--color-primary);
    }
    
    ${CurrentValueBubbleConnect} {
      transform: translateY(-6px) scale(1.02);
      background-color: var(--color-primary-translucent-85);
    } 
   
  }
  `}
`;

/* const DurationSliderTrack = styled(
  SliderTrack as React.FC<SliderTrackProps & { $isActive: boolean }>
)` */
const DurationSliderTrack = styled(SliderTrack)<{ $isActive: boolean }>`
  background: none;
  border-radius: 0;
  ${({ $isActive }) =>
    $isActive &&
    `
    /* border-top: 1px solid var(--color-shadow-light); */
     ${CurrentValueBubble} {
      transform: translateY(-48px) scale(1.25);
      box-shadow: 0px 2px 3px var(--color-shadow);
      background-color: var(--color-primary);
    }
    
    ${CurrentValueBubbleConnect} {
      transform: translateY(-22px) scale(1.04);
      box-shadow: 0px 2px 2px var(--color-shadow);
      background-color: var(--color-primary-translucent-85);
    }
   
    ${DurationSliderHandle} {
      background-color: var(--color-primary-translucent-85);
    }
    ${DurationSliderMarker} {
      background: var(--color-text-light);
      /* box-shadow: 0 0 1px var(--color-shadow); */
    }
  `}
`;

/* *** instead of standard range indicator, would be nice to have a "ghost" or prior selection that moves to current selection at same time as debounce triggers */
/* const DurationSliderRange = styled(SliderRange)`
  transition: width 0.05s;
  transition-delay: 0.15s;
  border-radius: 0;
  background-color: var(--color-primary-translucent-85); 
`;*/

const FormattedDuration = styled.div`
  display: flex;
  align-items: center;
`;
const WholeNumDuration = styled.span``;
const FractionDuration = styled.span`
  font-size: ${16 / 16}rem;
`;

const ButtonWrapper = styled.div`
  margin-top: -16px;
  width: 40px;
  height: 32px;
`;

const ToggleButton = styled(RippleButton)`
  --color-ripple: var(--color-primary-light);

  /* flex: 0 1 56px; */
  height: 100%;
  width: 100%;

  background-color: var(--color-primary);
  color: var(--color-text-contrast);
  box-shadow: var(--shadow-elevation-medium);
  border-radius: 4px 0px 0px 4px;
  font-size: ${18 / 16}rem;

  /* in browser, flex is unnecessary; if Safari iOS, text is shoved to right without forcing centering */
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s;
  user-select: none;
`;
