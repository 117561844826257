import React from "react";
import styled from "styled-components/macro";

import { rootLetters } from "../../_music/roots";

import { GKeyboard } from "../../_components/GKeyboard";

type Props = {
  selectedNote: string;
  noteRole: "root" | "bass";
  handleEditChord: ({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) => void;
  moveToTab: () => void;
  altKeyColor?: boolean;
};

export function NoteInput({
  selectedNote,
  noteRole,
  handleEditChord,
  moveToTab,
  altKeyColor,
}: Props) {
  const noteLetter = selectedNote.slice(0, 1);

  // show accidentals for a letter only when letter is selected;
  // meant for 5-column layout
  const noteOptionsCompact = rootLetters.reduce(
    (prev: string[], curr: string, index) => {
      const emptyString = ``;
      const nextSelected = rootLetters[index + 1] === noteLetter;
      // selected
      if (curr === noteLetter) {
        return [...prev, `${curr}♭`, curr, `${curr}♯`];
      }
      // not selected, *next* is selected, even
      else if (index % 2 === 0 && nextSelected) {
        return [...prev, emptyString, curr];
      }
      // not selected, even
      else if (index % 2 === 0 && !nextSelected) {
        return [...prev, emptyString, curr, emptyString];
      }
      // not selected, odd
      else {
        return [...prev, curr, emptyString];
      }
    },
    []
  );

  // meant for 6-column layout
  const noteOptionsAlwaysShow = rootLetters.reduce(
    (prev: string[], curr: string, index) => {
      return [...prev, `${curr}♭`, curr, `${curr}♯`];
    },
    []
  );

  function handleManualChange(value: string) {
    handleEditChord({
      name: noteRole,
      value: value,
    });
    moveToTab();
  }

  function emphasizeWhen(hoverTarget: string, value: string) {
    return hoverTarget.indexOf(value.slice(0, 1)) > -1;
  }

  return (
    <Wrapper>
      <GKeyboard
        groupId={`ChordKeeper_Checkbox_${noteRole}`}
        // label={noteRole}
        items={noteOptionsAlwaysShow.map((val) => {
          return {
            label: val.toString(),
            value: val.toString(),
          };
        })}
        columns={6}
        checkedItems={[selectedNote]}
        manualChange={handleManualChange}
        emphasizeWhen={emphasizeWhen}
        altKeyColor={altKeyColor}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 4px;
`;
