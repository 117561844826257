import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type {
  CreateSongMutation,
  createSongInput,
} from "../__generated__/CreateSongMutation.graphql";

import { TextInputModal } from "../_components/TextInputModal";

import type { CustomErrors } from "../_types/errorTypes";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import styled from "styled-components";
import { ModalError } from "../_components/ModalError";

/* these imports... bring in different versions of ConnectionHandler?  .getConnectionID() is unavailable on the import, but available on the "require" */
// import { ConnectionHandler } from "relay-runtime";
const { ConnectionHandler } = require("relay-runtime");

type Props = {
  connectionID?: string;
  userId?: string;
};

export function CreateSong(props: Props) {
  const history = useHistory();
  const [errMsg, setErrMsg] = React.useState<string>("");

  // let [friendlyErrors, setFriendlyErrors] = React.useState<CustomErrors>([]);

  const [commit, isInFlight] = useMutation<CreateSongMutation>(graphql`
    mutation CreateSongMutation(
      $connections: [ID!]!
      $input: createSongInput!
    ) {
      createSong(input: $input) {
        # return newly created song to make use its id
        song {
          id
        }
        # return song edges to update song list in client store
        songEdge @appendEdge(connections: $connections) {
          __typename
          cursor
          node {
            id
            title
            artist
            tags
            permissions {
              user
              email
              isOwner
              canEdit
              didAccept
            }
            viewerPermission {
              user
              email
              isOwner
              canEdit
              didAccept
            }
          }
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  function handleCreateSong(input: createSongInput) {
    const connectionID = ConnectionHandler.getConnectionID(
      props.userId,
      "UserSongs_songs"
    );
    commit({
      variables: {
        input,
        // *** at moment, testing both means of getting connectionID, and both work
        // connections: [props.connectionID],
        connections: [connectionID],
      },
      onCompleted: (response) => {
        if (response.createSong) {
          const { song, songEdge, errors } = response.createSong;
          if (song) {
            history.push(`/song-edit/${song.id}`, { showTagFilter: true });
          }
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
            // setFriendlyErrors(errors);
          }
        }
      },
      onError: (error) => {
        setErrMsg(error.message);
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "title",
            label: "Title",
            required: true,
            initialValue: "",
            // had used "words" for autoCapitalize for title, artist, section name, and tags; however this overrode capslock in iOS, and otherwise is inconsistent with how most users enter text; "sentences" should solve both issues;
            autoCapitalize: "sentences",
          },
          {
            id: "artist",
            label: "Artist",
            required: false,
            initialValue: "",
            autoCapitalize: "sentences",
          },
        ]}
        createLabel="Create"
        createOrUpdate="create"
        callback={(inputValues: Record<string, string>) => {
          handleCreateSong({
            title: inputValues.title,
            artist: inputValues.artist,
          });
        }}
        modalLabel="Create Song"
        buttonSize="sm"
        // expand="full"
        resetOnSubmit
        isInFlight={isInFlight}
      />
      {/* {isInFlight && (
        <SpinnerWrapper>
          <LoadingSpinner size="sm" />
        </SpinnerWrapper>
      )} */}
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />

      {/* {friendlyErrors.length > 0 && <FriendlyErrors errors={friendlyErrors} />} */}
    </>
  );
}

/* const SpinnerWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
`;
 */
