import React from "react";

import { AppContext } from "../_context/appContext";
import { AuthActionTypes, AppActionTypes } from "../_reducers";
import { UniqueUserContext } from "../_context/uniqueUserContext";
// import { cache } from "../RelayEnvironment";

export function useLogout() {
  const { state, dispatch } = React.useContext(AppContext);
  const { uniqueUser, updateUniqueUser } = React.useContext(UniqueUserContext);

  function handleLogout() {
    dispatch({
      type: AuthActionTypes.Reset,
      payload: null,
    });
    dispatch({
      type: AppActionTypes.Reset,
      payload: null,
    });
    updateUniqueUser(null);
    // cache.clear();
  }

  return { handleLogout };
}
