import { ALTERNATE_ENDING_FLAG, REPEAT_END } from "../_constants";
import { TapStatGroup } from "../_hooks/useRhythm.helpers";
import {
  ChordCursorContext,
  ChordOrCursor,
  ChordOrCursorObj,
  RhythmUnit,
} from "../_types";
import { countProgressionRhythm, TimeSigClient } from "./rhythmCount";

function getAltEndNums(notations: readonly string[] | string[]) {
  // undefined case may occur when seeking prev/next vals in array, which may be undefined indexes
  if (!notations) return "";
  if (notations.length === 0) return "";
  return (
    notations
      .filter((val) => val.indexOf(ALTERNATE_ENDING_FLAG) > -1)
      .map((val) => val.slice(ALTERNATE_ENDING_FLAG.length))
      // sort, to ensure 1) reps display in correct order; 2) can easily stringify and compare for grouping
      .sort((a, b) => parseInt(a) - parseInt(b))
      .join(",")
  );
}

export function addChartContext(
  sectionChords: ChordOrCursor[],
  rhythmUnit: RhythmUnit,
  timeSig: TimeSigClient,
  doAlwaysReserveSpace?: boolean
) {
  let blockSize = 4;
  // each index represents block
  let reserveSpaceArray: boolean[] = [];
  // store altEndNums for comparison
  let altEndNumsArray: string[] = [];
  for (let i = 0; i < sectionChords.length; i++) {
    let thisChord = sectionChords[i];
    let hasNotations = thisChord.notation.length > 0;
    if (hasNotations) {
      reserveSpaceArray[Math.floor(i / blockSize)] = true;
      // could make faster if, when one chord in block has notations, skip testing other chords in block
      //
    }
    altEndNumsArray.push(getAltEndNums(thisChord.notation));
  }

  // *** instead of deriving progressionWithRhythmCount from sectionChords, then mapping it back to it, consider maintaining all values in progressionWithRhythmCount, and using that here on out; but don't bother if would need to perform a similar mapping in rhythmCount, only do if can make cleaner;

  const { progressionWithRhythmCount } = countProgressionRhythm(
    sectionChords,
    timeSig,
    rhythmUnit
  );

  let chordsWithContext: ChordCursorContext[] = [];
  for (let i = 0; i < sectionChords.length; i++) {
    let thisChord = sectionChords[i];
    let chordWithRhythmContext = progressionWithRhythmCount.find(
      (val) => val.id === thisChord.id
    );
    let altEndNums = altEndNumsArray[i];
    let thisItem: ChordCursorContext = {
      ...thisChord,
      duration: chordWithRhythmContext?.rhythmDuration ?? thisChord.duration,
      context: {
        rhythmCount: chordWithRhythmContext?.rhythmCount ?? [],
        groupSubdivisions: chordWithRhythmContext?.groupSubdivisions ?? 1,
        repAlt: {
          doReserveSpace:
            doAlwaysReserveSpace ||
            reserveSpaceArray[Math.floor(i / blockSize)] === true,
          label: altEndNums,
          connectStart: altEndNums === altEndNumsArray[i - 1],
          connectEnd: altEndNums === altEndNumsArray[i + 1],
        },
      },
    };
    chordsWithContext.push(thisItem);
  }
  // console.log("BEFORE");
  // console.log(chordsWithContext);
  return chordsWithContext;
}

export function splitChordCells(
  chordsWithContext: ChordCursorContext[],
  maxCountPerChord: number = 16,
  rhythmUnit: RhythmUnit
): ChordCursorContext[] {
  var thisChordSplits: ChordCursorContext[] = [],
    thisRhythmCountClone: string[] = [],
    thisRhythmCountSplit: string[] = [],
    thisSplitCount: number;

  const chordsWithContextSplit = chordsWithContext.reduce((prev, curr) => {
    thisRhythmCountClone = curr.context.rhythmCount;

    if (thisRhythmCountClone.length > maxCountPerChord) {
      // chord is long enough to need to be split;
      thisChordSplits = [];
      thisSplitCount = 0;
      while (thisRhythmCountClone.length) {
        thisRhythmCountSplit = thisRhythmCountClone.splice(0, maxCountPerChord);
        thisChordSplits.push({
          ...curr,
          id: curr.id + `_split-${thisSplitCount}`,
          context: {
            ...curr.context,
            rhythmCount: thisRhythmCountSplit,
            repAlt: {
              ...curr.context.repAlt,
              connectStart:
                thisSplitCount === 0 ? curr.context.repAlt.connectStart : true,
            },
          },
          duration:
            // show duration based on the rhythmCount apportioned to this chord split;
            // (4 / rhythmUnit) * thisRhythmCountSplit.length,
            // ... or show full duration for chord, on first split only;
            thisSplitCount === 0 ? curr.duration : 0,
          definition: {
            ...curr.definition,
            root: thisSplitCount === 0 ? curr.definition.root : "",
            bass: thisSplitCount === 0 ? curr.definition.bass : "",
            qual: thisSplitCount === 0 ? curr.definition.qual : "\u00A0",
          },
          // for notation, for first split of chord, allow REPEAT_⁠START and the alt endings (e.g. ALT_END_⁠1); for last split allow only REPEAT_END, and for any splits inbetween, remove all notations (but want to show line on top to keep them "connected", but that's handled via context.repAlt);
          notation:
            thisSplitCount === 0
              ? curr.notation.filter((val) => val !== REPEAT_END)
              : thisRhythmCountClone.length === 0
              ? curr.notation.filter((val) => val === REPEAT_END)
              : [],
        });
        thisSplitCount++;
      }
      return [...prev, ...thisChordSplits];
    } else {
      // chord is short enough it doesn't need to be split;
      return [...prev, curr];
    }
  }, [] as ChordCursorContext[]);
  // console.log("AFTER");
  // console.log(chordsWithContextSplit);
  return chordsWithContextSplit;
}

/* 
  if any chord [in 4 / in 8 / in section] has notation data
  label all with context.repAlt.doReserveSpace = true

  easiest: if any chord in section has notation data, reserve space for all

  harder, track groups of 4 or 8: as loop through, create an array with each position representing a 4/8 chord block 
*/
