import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { useTransition, animated, config } from "@react-spring/web";

// import { AppContext } from "../../_context/appContext";
import { PageContext } from "./Page";
// import { HeaderHeightContext } from "./Page";
// import { ContentScrollStateContext } from "./Page";
// import { HeaderVisibleContext } from "./Page";
import { HeaderCore } from "../HeaderCore";
import Button from "../Button";
import { Icon, icons } from "../Icon";

type Props = {
  title?: string;
  // consider defining more precisely, a la: https://stackoverflow.com/a/51448473 and https://www.typescriptlang.org/play?#code/FAFwngDgpgBAsgQwE4GspICoEsC2sC8ABgCQDeAdgK44BG6APjAORMC+ZVt6rAXB9XSTsKA7oWDAANlBAwEPeMjSZcBGACIADAFpNm9TAD0hmOiQB7JFJkwaCxKnTY8MfBs089B4zHMprsgDG9kpOqq7uAJye+kYmfsBAA
  // requires typescript 4.1+
  // *** consider making controls an array of buttons
  controls?: React.ReactNode[];
  backPath?: string | null;
  backState?: object;
  backIcon?: keyof typeof icons;
  children?: React.ReactNode;
  allowHeaderCollapse?: boolean;
  hideTitleIfScrollTop?: boolean;
};

/* const STYLES = {
  play: {
    "--background-color-with-filter": "hsla(0, 0%, 100%, 0.75)",
    "--background-color-no-filter": "hsla(0, 0%, 100%, 0.96)",
  },
  learn: {
    "--background-color-with-filter": "hsla(100, 50%, 90%, 0.75)",
    "--background-color-no-filter": "hsla(100, 50%, 90%, 0.96)",
  },
}; */

export function PageHeader({
  title,
  // default to single empty control, to ensure equal space is occupied left/right sides of header, keeping title centered;
  controls = [<></>],
  backPath,
  backState,
  children,
  allowHeaderCollapse = true,
  backIcon,
  hideTitleIfScrollTop = false,
}: Props) {
  let history = useHistory();

  // const { state, dispatch } = React.useContext(AppContext);
  // const headerHeight = React.useContext(HeaderHeightContext);
  // const [contentScrollState, setContentScrollState] = React.useContext(
  //   ContentScrollStateContext
  // );
  const [pageState, setPageState] = React.useContext(PageContext);

  const transitions = useTransition(pageState.contentScrollState === "top", {
    from: { opacity: 0.2, transform: "translate3d(-32px,16px,0px)" },
    enter: { opacity: 1, transform: "translate3d(0px,0px,0px)" },
    leave: { opacity: 0.2, transform: "translate3d(-32px,16px,0px)" },
    config: {
      ...config.stiff,
      clamp: true,
    },
  });

  const style = {
    // ...STYLES[state.app.mode],
    "--header-height": `${pageState.headerHeight / 16}rem`,
  };

  // const [isHeaderVisible, setIsHeaderVisible] =
  //   React.useContext(HeaderVisibleContext);
  let collapseHeader: boolean =
    allowHeaderCollapse && !pageState.isHeaderVisible;

  return (
    <>
      <SafeAreaMask collapseHeader={collapseHeader} />
      <Wrapper
        collapseHeader={collapseHeader}
        hideBoxShadow={
          // contentScrollState === "top"
          pageState.contentScrollState === "top"
          // hacky... b/c UserSongs doesn't use PageContent, and thus its scrolling isn't detected by the logic that shows/hides header box shadow
          // && !forceBoxShadow
        }
        style={style as React.CSSProperties}
      >
        <SafeWrapper>
          {/* 
          if title provided, render default header layout; if no backPath, use goBack(), though prefer providing backPath to ensure 1) you can force nav out-of-order without having to worry about where users will end up when using back button; 2) app can cold load onto a page with a back button, and back button will keep them within the app, instead of attempting to leave it */}
          {!children && (
            <FlexWrapper>
              <ButtonWrapper>
                <BackButton
                  onClick={() => {
                    if (backPath) {
                      history.push(backPath, backState);
                    } else {
                      history.goBack();
                    }
                  }}
                  fill="ghost"
                  size="sm"
                  $isDefaultIcon={backIcon === undefined}
                  // padding={backIcon ? undefined : 0}
                >
                  <Icon
                    id={backIcon ?? "chevron-left"}
                    size={backIcon ? 24 : 32}
                    strokeWidth={2}
                  />
                </BackButton>
              </ButtonWrapper>
              {hideTitleIfScrollTop ? (
                <TitleWrapperRel>
                  {transitions((style, scrollIsTopAnim) => (
                    <TitleWrapperAbsAnim style={style}>
                      <TitleWrapper>
                        {scrollIsTopAnim ? "" : title}
                      </TitleWrapper>
                    </TitleWrapperAbsAnim>
                  ))}
                </TitleWrapperRel>
              ) : (
                <TitleWrapper>{title}</TitleWrapper>
              )}
              {controls?.map((control, i) => {
                return <ButtonWrapper key={i}>{control}</ButtonWrapper>;
              })}
            </FlexWrapper>
          )}
          {children}
        </SafeWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(HeaderCore)<{
  collapseHeader?: boolean;
  hideBoxShadow?: boolean;
}>`
  transition: transform 0.4s, opacity 0s, box-shadow 0.2s;
  transform: none;
  opacity: 1;

  ${({ hideBoxShadow }) =>
    hideBoxShadow &&
    `
    box-shadow: none;
`}

  ${({ collapseHeader }) =>
    collapseHeader &&
    `
    transition: transform 0.4s, opacity 0.3s, box-shadow 0.1s;
    transform: translate3d(0, -100%, 0);
    opacity: 0;
`}
`;

export const SafeAreaMask = styled(Wrapper)`
  padding: none;

  /* use height instead of padding 1) to prevent empty div from collapsing; 2) so only shows where safe area is needed */
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  box-shadow: none;

  /*
  transition: transform 0.2s, opacity 0.1s;
  transform: translate3d(0, -100%, 0);
  */
  /*  visibility: hidden; */
  opacity: 0;
  transition: opacity 0s;
  ${({ collapseHeader }) =>
    collapseHeader &&
    `
  transform: translate3d(0, 0, 0);
  opacity: 1.0;
 /*  visibility: visible; */
`}
`;

const SafeWrapper = styled.div`
  height: var(--header-height);
  min-height: var(--header-height);
`;

const FlexWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  /* flex basis to ensure wrapper occupies space even in absence of button (namely top-left "back" button); set to 32 to match size of button's icon... or set smaller (e.g. 16) to give a bit of padding */
  /* flex: 0 1 32px; */
  min-width: 56px;
  /* width: 48px; */
  height: 100%;
  /* display: flex; */
`;

const BackButton = styled(Button)<{ $isDefaultIcon: boolean }>`
  ${({ $isDefaultIcon }) =>
    $isDefaultIcon &&
    `
      padding: 0px 2px;
  `}
`;

const TitleWrapperRel = styled.div`
  flex: 1;
  /* TitleAbsolute need TitleWrapper and FlexWrapper to have height:100%; to avoid collapsing; then use flex in Title to center title text; */
  height: 100%;
  position: relative;
`;

const TitleWrapperAbs = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrapperAbsAnim = animated(TitleWrapperAbs);

const TitleWrapper = styled.div`
  flex: 1;
  font-size: ${18 / 16}rem;
  text-align: center;
  /* constrain long titles; NOTE: this took me a while to realize: if you want these to constrain flex-calculated minimum size, these need to be applied to the same element that is "flexing"; e.g. I tried applying these to a child of this element, which resulted in long titles pushing the right-side button off the screen, because they did not reduce the size calculation of this element; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
