/**
 * @generated SignedSource<<53579eb8d50eca74bebc1236f02a5abc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LeitnerSettingsQueryQuery$variables = {};
export type LeitnerSettingsQueryQuery$data = {
  readonly viewer: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly user: {
      readonly boxIntervals: ReadonlyArray<number>;
      readonly id: string;
    } | null;
  } | null;
};
export type LeitnerSettingsQueryQuery = {
  response: LeitnerSettingsQueryQuery$data;
  variables: LeitnerSettingsQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryResult",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boxIntervals",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeitnerSettingsQueryQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LeitnerSettingsQueryQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1d814d7e67f3911aa99d25398a4dd2cd",
    "id": null,
    "metadata": {},
    "name": "LeitnerSettingsQueryQuery",
    "operationKind": "query",
    "text": "query LeitnerSettingsQueryQuery {\n  viewer {\n    user {\n      id\n      boxIntervals\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cec7a07b990eeff29601c1c74ac78e07";

export default node;
