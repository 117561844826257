import styled from "styled-components/macro";

export const FAB_EDGE_MARGIN = {
  horiz: 12,
  bottom: 32,
};

export const FloatingWrapper = styled.div`
  /* need isolation:isolate; - or perhaps another way of creating a stacking context - to avoid the button's ripple overflowing this floating container - (iOS?) Safari only */
  isolation: isolate;
  position: absolute;
  bottom: ${FAB_EDGE_MARGIN.bottom}px;
  right: ${FAB_EDGE_MARGIN.horiz}px;
  /* somewhat arbitrary value for border-radius; want it to be equal to or greater than height, to ensure maximum (circle) curve  */
  border-radius: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  /* transition: box-shadow 1s; */

  box-shadow: var(--shadow-elevation-medium);
  &:active {
    box-shadow: var(--shadow-elevation-low);
  }

  /* needed e.g. when in landscape view */
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);

  /* if backdrop-filter not supported: mostly opaque */
  background-color: var(--color-background-translucent-96);
  /* background-color: var(--color-background-tint-translucent-96); */

  /* if backdrop-filter supported: more transparent and blurred */
  @supports (
    (backdrop-filter: blur(4px)) or (-webkit-backdrop-filter: blur(4px))
  ) {
    background-color: var(--color-background-translucent-75);
    /* background-color: var(--color-background-tint-translucent-75); */
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
`;

export const FloatingWrapperCircle = styled(FloatingWrapper)`
  border-radius: 50%;
  height: 60px;
  width: 60px;
`;
