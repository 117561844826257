import React, { createContext, useReducer, Dispatch } from "react";
import { CURSOR_ID, isCursorValidChord } from "../ChordsSimple/Cursor";
import {
  FINISH_RHYTHM_ID,
  getScalingUpdates,
  MIN_TAP_DISTANCE_MS,
  pruneNonConsecTapsOnChord,
} from "../_hooks/useRhythm.helpers";
import { hapticsImpactLight } from "../_plugins/Haptics";

import {
  chordsReducer,
  chordsReducerInit,
  ChordsType,
  ChordsActions,
  ChordInputMode,
  ChordsActionTypes,
} from "../_reducers/chordsReducer";
import {
  makeChordItem,
  updateCursorForContext,
} from "../_reducers/chordsReducer.helpers";
import { normalizeTaps } from "../_standalone/normalizeTaps";
import {
  ChordInputs,
  ChordOrCursor,
  RhythmUnit,
  rhythmUnitOptions,
} from "../_types";

export const MAX_TAPS_PER_CHORD = 16;

type InitialStateType = {
  chords: ChordsType;
};

const initialState: InitialStateType = {
  chords: chordsReducerInit,
};

// export type AllActions = ChordsActions;

export const ChordContext = createContext<{
  // *** should give more descriptive names, e.g. chordsState, chordsDispatch
  state: InitialStateType;
  dispatch: Dispatch<ChordsActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { chords }: InitialStateType,
  action: ChordsActions
): InitialStateType => ({
  chords: chordsReducer(chords, action),
});

export const ChordProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <ChordContext.Provider
      // could pass these values as a tuple "[]" to create a similar interface on useContext as you have on useReducer, e.g.: https://stackoverflow.com/a/59432211/6281555
      value={{ state, dispatch }}
    >
      {children}
    </ChordContext.Provider>
  );
};

type UseSectionChords = {
  updateServer: (chordsState: ChordOrCursor[], rhythmUnit?: RhythmUnit) => void;
};
export function useSectionChords(props: UseSectionChords) {
  const { updateServer } = props;
  const { state, dispatch } = React.useContext(ChordContext);
  const { sectionChords, selectedId } = state.chords;

  function setChordsState(
    sectionChords: ChordOrCursor[],
    rhythmUnit: RhythmUnit
  ) {
    dispatch({
      type: ChordsActionTypes.Set,
      payload: {
        chords: sectionChords,
        rhythmUnit,
      },
    });
  }

  function createChord(cursorIndex: number, suggestedChord?: ChordInputs) {
    const chord = sectionChords[cursorIndex];
    const { definition } = chord;
    var chordInputs;
    if (suggestedChord) {
      let { root, qual } = suggestedChord;
      // don't attempt create if without root or qual
      if (!isCursorValidChord(root, qual)) return;
      chordInputs = {
        ...suggestedChord,
        // take only chord definition from suggested chord; inherit duration, etc. as usual;
        duration: chord.duration,
        rhythm: chord.rhythm,
        notation: chord.notation,
      };
    } else {
      let { root, qual } = definition;
      // don't attempt create if without root or qual
      if (!isCursorValidChord(root, qual)) return;
      chordInputs = {
        ...chord.definition,
        duration: chord.duration,
        rhythm: chord.rhythm,
        notation: chord.notation,
      };
    }
    const newChord = makeChordItem(chordInputs);
    const updatedSectionChords = [
      ...sectionChords.slice(0, cursorIndex),
      newChord,
      ...sectionChords.slice(cursorIndex),
    ];
    const { newCursor, newCursorIndex } =
      updateCursorForContext(updatedSectionChords);
    updatedSectionChords.splice(newCursorIndex, 1, newCursor);
    dispatch({
      type: ChordsActionTypes.UpdateSectionChords,
      payload: {
        sectionChords: updatedSectionChords,
        selectedId: selectedId + 1,
      },
    });
    updateServer(updatedSectionChords);
  }

  function resetCursor() {
    const updatedSectionChords = [...sectionChords];
    const { newCursor, newCursorIndex } =
      updateCursorForContext(updatedSectionChords);
    updatedSectionChords.splice(newCursorIndex, 1, newCursor);
    dispatch({
      type: ChordsActionTypes.UpdateSectionChords,
      payload: {
        sectionChords: updatedSectionChords,
      },
    });
  }

  function updateChord(chordInputs: ChordInputs) {
    // *** perhaps preferable for this action to take only the changed property in its payload, instead of the entire set of chord values; one potential bug this would help with occurred to you: the callback handleEditChord in CreateUpdateChord holds the non-changed chord values; useGKeyboardTouch was retaining a stale version of this fnc, and thus was inheriting the values from the last chord the user had clicked on, not the present chord; thus if chord 1 is Am, and chord 2 is G7, an attempt to change the root of G7 to D would result in a Dm, inheriting all values but the just-changed quality from the Am instead of the G7
    // *** RELATED: there's no check whether vals changed, so e.g. selecting the same root will initiate a complete update, including server;
    const { root, qual, bass, duration, rhythm, notation } = chordInputs;
    const updatedChord: ChordOrCursor = {
      ...sectionChords[selectedId],
      definition: {
        root,
        qual,
        bass,
      },
      duration,
      rhythm,
      notation,
    };
    const updatedSectionChords = [
      ...sectionChords.slice(0, selectedId),
      updatedChord,
      ...sectionChords.slice(selectedId + 1),
    ];
    dispatch({
      type: ChordsActionTypes.UpdateSectionChords,
      payload: {
        sectionChords: updatedSectionChords,
      },
    });
    if (updatedChord.id !== CURSOR_ID) {
      updateServer(updatedSectionChords);
    }
  }

  function moveChord(chordId: string, toRank: number) {
    const updatedSectionChords = sectionChords.slice(0);
    const chordIndex = updatedSectionChords.findIndex(
      (chord) => chord.id === chordId
    );
    // remove target chord; store
    const movingChord = updatedSectionChords.splice(chordIndex, 1);
    // place target chord in new location
    updatedSectionChords.splice(toRank, 0, movingChord[0]);
    const { newCursor, newCursorIndex } = updateCursorForContext(
      updatedSectionChords,
      // do not set new duration for cursor when it is moved; b/c user may have selected all options for chord, then wants to move it into place before adding it;
      true
    );
    updatedSectionChords.splice(newCursorIndex, 1, newCursor);
    dispatch({
      type: ChordsActionTypes.UpdateSectionChords,
      payload: {
        sectionChords: updatedSectionChords,
        selectedId: toRank,
      },
    });
    if (chordId !== CURSOR_ID) {
      updateServer(updatedSectionChords);
    }
  }

  function deleteChord(callback: (selectedId: number) => void) {
    const updatedSectionChords = sectionChords.slice(0);
    const selectedChord = { ...updatedSectionChords[selectedId] };
    // disallow deleting cursor
    if (selectedChord.id === CURSOR_ID) {
      return state;
    }
    // set new selectedId
    var newSelectedId = updatedSectionChords.findIndex(
      (val) => val.id === CURSOR_ID
    );
    if (selectedId > 0) {
      newSelectedId = selectedId - 1;
    } else if (selectedId < updatedSectionChords.length - 1) {
      // chord is about to be deleted, next chord in line will "inherit" its index
      newSelectedId = selectedId;
    }
    // remove target chord
    updatedSectionChords.splice(selectedId, 1);
    // update cursor duration
    const { newCursor, newCursorIndex } =
      updateCursorForContext(updatedSectionChords);
    updatedSectionChords.splice(newCursorIndex, 1, newCursor);
    dispatch({
      type: ChordsActionTypes.UpdateSectionChords,
      payload: {
        sectionChords: updatedSectionChords,
        selectedId: newSelectedId,
      },
    });
    updateServer(updatedSectionChords);
    callback(newSelectedId);
  }

  function setSelected(chordId: number) {
    dispatch({
      type: ChordsActionTypes.SetSelected,
      payload: {
        selectedId: chordId,
      },
    });
  }

  return {
    setChordsState,
    createChord,
    resetCursor,
    updateChord,
    moveChord,
    deleteChord,
    setSelected,
    sectionChords,
    selectedId,
  };
}

export function useInputMode() {
  const { state, dispatch } = React.useContext(ChordContext);
  const { inputMode } = state.chords;

  function clearTapHistory() {
    dispatch({
      type: ChordsActionTypes.ClearTapHistory,
      payload: {},
    });
  }

  function setInputMode(inputMode: ChordInputMode) {
    dispatch({
      type: ChordsActionTypes.SetInputMode,
      payload: { inputMode },
    });

    if (inputMode === "manual") {
      clearTapHistory();
    }
  }

  return { inputMode, setInputMode };
}

// *** perhaps should split "tap" into separate reducer from chords; could, at the end of this flow (which is the only one that touches pre-existing chord state), fire a reducer to effect needed chord changes, since they would still share mainReducer
//
//
//
type UseTapProps = {
  callBackAfterUpdateChords: (
    chordsState: ChordOrCursor[],
    rhythmUnit?: RhythmUnit
  ) => void;
};
export function useTap(props: UseTapProps) {
  const { state, dispatch } = React.useContext(ChordContext);
  const {
    sectionChords,
    rhythmUnit,
    inputMode,
    tapsInSession,
    tapStatGroup,
    tapSessionRhythmUnit,
    tapIdSet,
    scaleOptions,
    canFinishRhythm,
    tapDurationSens,
    tappedBeatTotal,
    lastTappedId,
  } = state.chords;

  function clearTapHistory() {
    dispatch({
      type: ChordsActionTypes.ClearTapHistory,
      payload: {},
    });
  }

  function setInputMode(inputMode: ChordInputMode) {
    dispatch({
      type: ChordsActionTypes.SetInputMode,
      payload: { inputMode },
    });

    // if (inputMode === "manual") {
    clearTapHistory();
    // }
  }

  function toggleInputMode() {
    if (inputMode === "manual") {
      setInputMode("tap");
    } else {
      setInputMode("manual");
    }
  }

  function scaleTapStatGroupsBy(scale: number) {
    // don't merely rely on UI to control whether further scaling is allowed; also check here, otherwise user could rapid click on a scaling button, resulting in invalid value;
    var thisScaleOption = scaleOptions.find((option) => option.scale === scale);
    if (!thisScaleOption?.isAvailable) {
      return state;
    }
    var {
      scaledChordsTapStats,
      updatedSectionChords,
      availableScales,
      tappedBeatTotal,
    } = getScalingUpdates(sectionChords, tapIdSet, scale);

    var updatedScaleOptions = scaleOptions.map((option) => {
      return {
        ...option,
        isAvailable: availableScales.indexOf(option.scale) > -1,
      };
    });
    dispatch({
      type: ChordsActionTypes.ScaleTapStatGroupsBy,
      payload: {
        tapStatGroup: scaledChordsTapStats,
        sectionChords: updatedSectionChords,
        scaleOptions: updatedScaleOptions,
        tappedBeatTotal,
      },
    });
    props.callBackAfterUpdateChords(updatedSectionChords);
  }

  function setRhythmUnit(rhythmUnit: RhythmUnit) {
    if (rhythmUnitOptions.indexOf(rhythmUnit) === -1) {
      // requested rhythmUnit is not available; do nothing;
      return;
    }
    dispatch({
      type: ChordsActionTypes.SetRhythmUnit,
      payload: {
        rhythmUnit,
      },
    });
    props.callBackAfterUpdateChords(sectionChords, rhythmUnit);
  }

  function setTapDurationSens(sensitivity: number) {
    dispatch({
      type: ChordsActionTypes.SetTapDurationSens,
      payload: { sensitivity },
    });
  }

  function toggleTapDurationSens() {
    if (tapDurationSens === 1) {
      setTapDurationSens(0.5);
    } else {
      setTapDurationSens(1);
    }
  }

  function addRhythmTap(chordId: string) {
    hapticsImpactLight();
    var now = Date.now();
    var tapIndex = tapsInSession.length - 1;
    var prevTap = tapsInSession[tapIndex];
    if (prevTap) {
      if (now - prevTap.time < MIN_TAP_DISTANCE_MS) {
        // ignore if taps are too close in time, to prevent accidental double-taps;
        // console.log("Tap too fast: ignoring.");
        return;
      }
      /*      
      // had limited consecutive taps per chord b/c had shown all taps within one chord cell when in edit mode; moving away from that (b/c don't want chord cells changing in size and moving other chord cells up and down while tapping), so prefer to remove this restiction to maintain max flexibility for user when tapping;
      if (prevTap.chordId === chordId) {
        // limit taps per chord;
        let tapsCurrentChord = 1;
        while (tapsInSession[--tapIndex]?.chordId === chordId) {
          tapsCurrentChord++;
        }
        if (tapsCurrentChord >= MAX_TAPS_PER_CHORD) {
          console.log("Too many taps on same chord: ignoring;");
          return;
        }
      }
       */
      if (prevTap.chordId === FINISH_RHYTHM_ID) {
        // if previous tap was on "finish" button, subsequent tap clears tap history;
        clearTapHistory();
      }
    }
    const prunedTapsInSession = pruneNonConsecTapsOnChord(
      tapsInSession,
      chordId
    );
    prunedTapsInSession.push({ chordId: chordId, time: now });
    let updatedTapIdSet = new Set(tapIdSet).add(chordId);
    // console.log({ prunedTapsInSession, updatedTapIdSet });
    const { normalizedTaps, rhythmUnit } = normalizeTaps({
      chordTapTimes: prunedTapsInSession,
      tapDurationSens,
      tapIdSet: updatedTapIdSet,
      sectionChords,
    });
    console.log({ normalizedTaps, rhythmUnit });
    const tappedBeatTotal = normalizedTaps.reduce((prev, curr) => {
      return prev + curr.durationRatio;
    }, 0);
    /*var tempo = getTempo(tapStats);
      setTempo(tempo);*/
    dispatch({
      type: ChordsActionTypes.AddRhythmTap,
      payload: {
        tapsInSession: prunedTapsInSession,
        tapIdSet: updatedTapIdSet,
        tapStatGroup: normalizedTaps,
        tapSessionRhythmUnit: rhythmUnit,
        tappedBeatTotal,
        lastTappedId: chordId,
      },
    });
    if (chordId === FINISH_RHYTHM_ID) {
      const updatedSectionChords = sectionChords.map((chord) => {
        var updatedDuration = normalizedTaps.find(
          (group) => group.chordId === chord.id
        );
        return {
          ...chord,
          duration: updatedDuration?.durationRatio ?? chord.duration,
          rhythm: updatedDuration?.rhythm ?? chord.rhythm,
        };
      }) as ChordOrCursor[];
      // update chord state;
      dispatch({
        type: ChordsActionTypes.FinishRhythmTapSession,
        payload: {
          sectionChords: updatedSectionChords,
        },
      });
      // update scaleOptions;
      dispatch({
        type: ChordsActionTypes.SetRhythmScalingOptions,
        payload: {},
      });
      //
      clearTapHistory();
      // update server;
      props.callBackAfterUpdateChords(
        updatedSectionChords,
        tapSessionRhythmUnit
      );
    }
  }

  function toggleChordForScaling(chordId: string) {
    dispatch({
      type: ChordsActionTypes.ToggleChordForScaling,
      payload: {
        chordId,
      },
    });
  }

  function selectAllForScaling() {
    dispatch({
      type: ChordsActionTypes.SelectAllForScaling,
      payload: {},
    });
  }

  function deSelectAllForScaling() {
    dispatch({
      type: ChordsActionTypes.DeselectAllForScaling,
      payload: {},
    });
  }

  function finishRhythmTap() {
    canFinishRhythm && addRhythmTap(FINISH_RHYTHM_ID);
  }

  return {
    setInputMode,
    toggleInputMode,
    inputMode,
    tapDurationSens,
    // setTapDurationSens,
    toggleTapDurationSens,
    addRhythmTap,
    toggleChordForScaling,
    selectAllForScaling,
    deSelectAllForScaling,
    tapIdSet,
    canFinishRhythm,
    tapSessionData: {
      rhythmUnit: tapSessionRhythmUnit,
      sectionChords: sectionChords.map((chord) => {
        var updatedDuration = tapStatGroup.find(
          (group) => group.chordId === chord.id
        );
        return {
          ...chord,
          duration: updatedDuration?.durationRatio ?? chord.duration,
          rhythm: updatedDuration?.rhythm ?? chord.rhythm,
        };
      }) as ChordOrCursor[],
    },
    finishRhythmTap,
    tappedBeatTotal,
    scaleOptions,
    canScale: scaleOptions.some((option) => option.isAvailable === true),
    rhythmUnit,
    scaleTapStatGroupsBy,
    setRhythmUnit,
    clearTapHistory,
    tapStatGroup,
    lastTappedId,
  };
}
