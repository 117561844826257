import { Preferences } from '@capacitor/preferences';

export const storage = {
    setItem,
    getItem,
    removeItem
};

// this is a custom interface patch to make Storage compatible with redux-persist, and thus to model it after the interface of storage engines such as React Native's AsyncStorage.
// *** of particular note: Storage's getItem - contrary to its docs - returns an object, with the requested value stored in the property "value"; this is why you wrapped it in a promise, to pull that property out

async function setItem(storageKey, dataStr) {
    try {
        return await Preferences.set({
            key: storageKey,
            value: dataStr
        });
    } catch (err) {
        console.error("Set error", err);
        return null
    }
}

function getItem(storageKey) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await Preferences.get({ key: storageKey });
            resolve(res.value);
            // return JSON.parse(res.value);
        } catch (err) {
            console.error("Get error", err);
            // return null;
            reject(null)
        }
    });
}

async function removeItem(storageKey) {
    try {
        return await Preferences.remove({ key: storageKey });
    } catch (err) {
        console.error("Remove error", err);
        return null
    }
}