import React from "react";

import { AppContext } from "../_context/appContext";
import Button from "../_components/Button";
import { AppActionTypes } from "../_reducers";
import { Icon } from "../_components/Icon";

type Props = {
  size?: "sm" | "md";
};

export function ToggleColorTheme(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const { colorTheme } = state.app;

  function toggleColorTheme() {
    dispatch({
      type: AppActionTypes.SetColorTheme,
      payload: colorTheme === "light" ? "dark" : "light",
    });
  }

  if (props.size === "md")
    return (
      <Button
        onClick={toggleColorTheme}
        fill="clear"
        size="md"
        iconId={colorTheme === "dark" ? "moon" : "sun"}
        palette={colorTheme === "dark" ? "mono" : "secondary"}
      >
        Color Theme: {colorTheme === "dark" ? "Dark" : "Light"}
      </Button>
    );

  return (
    <Button onClick={toggleColorTheme} fill="ghost" size="sm">
      <Icon id={colorTheme === "dark" ? "moon" : "sun"} size={24} />
    </Button>
  );
}
