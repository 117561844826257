import React from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";
import { format } from "date-fns";

import {
  AdminUserDetails_users$key,
  AdminUserDetails_users$data,
} from "../__generated__/AdminUserDetails_users.graphql";
import {
  AdminUserDetails_songs$key,
  AdminUserDetails_songs$data,
} from "../__generated__/AdminUserDetails_songs.graphql";
import { typeNotEmpty } from "../_types/typeHelpers";
import { ArrayElement } from "../_types";

type Song = NonNullable<ArrayElement<AdminUserDetails_songs$data["songs"]>>;
type User = NonNullable<ArrayElement<AdminUserDetails_users$data["users"]>>;
type UserFeedback = ArrayElement<
  NonNullable<
    ArrayElement<AdminUserDetails_users$data["users"]>
  >["feedbackHistory"]
>;
type UserWithFeedback = {
  user: string;
  email: string;
  feedbackEvent: UserFeedback;
};

type Props = {
  usersRef: AdminUserDetails_users$key;
  songsRef: AdminUserDetails_songs$key;
};

export function AdminUserDetails(props: Props) {
  const users = useFragment(
    graphql`
      fragment AdminUserDetails_users on AllDataQueryResult {
        users {
          id
          createdAt
          updatedAt
          email
          feedbackHistory {
            type
            time
            initiatedBy
            isSatisfied
            comment
          }
        }
      }
    `,
    props.usersRef
  );

  const songs = useFragment(
    graphql`
      fragment AdminUserDetails_songs on AllDataQueryResult {
        songs {
          id
          user
          createdAt
          updatedAt
          title
          permissions {
            user
            email
            isOwner
            canEdit
            didAccept
          }
        }
      }
    `,
    props.songsRef
  );

  /*
  more stats to add:
      users
        when last active based on last updated song (instead of only looking at user doc updatedAt)
            if want to match user to a song update, need to reference both permissions array (namely, if it's shared), and song.editLock
        isolate your old test accounts ("combondrn+") (and their songs) so you can delete cleanly
      songs
        # orphaned
      feedback
        most recent
  */

  const [lookBackDays, setLookBackDays] = React.useState<number>(7);

  function sortByUpdatedAt<T extends { updatedAt: string }>(a: T, b: T) {
    var aTime = new Date(a.updatedAt);
    var bTime = new Date(b.updatedAt);
    if (aTime > bTime) {
      return -1;
    } else if (aTime < bTime) {
      return 1;
    } else {
      return 0;
    }
  }
  const usersMap = new Map();

  // *** this is prettttty dumb, though I appreciate why you wanted to use fragments here... perhaps this component should be rolled in with AdminQuery, or if use a fragment, it prob should be entirety of AllDataQueryResult instead of separating for users/songs
  const songsByUser: Record<string, string[]> = {};

  songs.songs?.filter(typeNotEmpty).forEach((song) => {
    var thisSongsEntry = songsByUser[song.user];
    if (thisSongsEntry) {
      thisSongsEntry.push(song.id);
    } else {
      songsByUser[song.user] = [song.id];
    }
  });

  const songsSorted = songs.songs.filter(typeNotEmpty).sort(sortByUpdatedAt);
  const lookBackPeriodMs = new Date(
    Date.now() - lookBackDays * 24 * 60 * 60 * 1000
  );
  const lookBackPeriodText = "last " + lookBackDays + " days";
  const recentlyCreatedSongs: Song[] = [];
  const recentlyUpdatedSongs: Song[] = [];
  for (let song of songsSorted) {
    if (new Date(song.updatedAt) > lookBackPeriodMs) {
      recentlyUpdatedSongs.push(song);
      if (new Date(song.createdAt) > lookBackPeriodMs) {
        recentlyCreatedSongs.push(song);
      }
    } else {
      // array is sorted, so break as soon as outside desired time range
      break;
    }
  }

  const usersSortedWithStats = users.users
    .filter(typeNotEmpty)
    .sort(sortByUpdatedAt)
    .map((user) => {
      return {
        ...user,
        songCount: songsByUser[user.id]?.length ?? 0,
      };
    });

  const recentlyCreatedUsers: User[] = [];
  const recentlyUpdatedUsers: User[] = [];
  const recentlyCreatedFeedback: UserWithFeedback[] = [];

  for (let user of usersSortedWithStats) {
    if (new Date(user.updatedAt) > lookBackPeriodMs) {
      recentlyUpdatedUsers.push(user);
      if (new Date(user.createdAt) > lookBackPeriodMs) {
        recentlyCreatedUsers.push(user);
      }
      for (let i = user.feedbackHistory.length - 1; i >= 0; i--) {
        if (new Date(user.feedbackHistory[i].time) > lookBackPeriodMs) {
          recentlyCreatedFeedback.push({
            user: user.id,
            email: user.email,
            feedbackEvent: user.feedbackHistory[i],
          });
        }
      }
    } else {
      // array is sorted, so break as soon as outside desired time range
      break;
    }
  }

  return (
    <Wrapper>
      <input
        type="number"
        value={lookBackDays}
        onChange={(e) => setLookBackDays(parseInt(e.target.value))}
      />
      <Stats>Users total: {usersSortedWithStats.length}</Stats>
      In {lookBackPeriodText}:
      <Stats>...created: {recentlyCreatedUsers.length}</Stats>
      <Stats>...updated {recentlyUpdatedUsers.length}</Stats>
      <br />
      <Stats>
        Feedback created in {lookBackPeriodText}:{" "}
        {recentlyCreatedFeedback.length}
      </Stats>
      <br />
      <Stats>Songs total: {songsSorted.length}</Stats>
      In {lookBackPeriodText}:
      <Stats>...created: {recentlyCreatedSongs.length}</Stats>
      <Stats>...updated: {recentlyUpdatedSongs.length}</Stats>
      <br />
      {usersSortedWithStats.map((user) => {
        return (
          <UserRow key={user.id}>
            {user.email} (
            {format(new Date(user.updatedAt), "yyyy-MM-dd HH:mm:ss")})
            <br />
            Song count: {user.songCount}
            <UserFeedback>
              {user.feedbackHistory
                // array is readonly (as are all? arrays from GraphQL types; slice first;)
                .slice()
                // *** already ordered from oldest to newest... so could reverse instead of full sort... or could leave in this order
                // .reverse()
                /*  .sort((a, b) => {
                  var aTime = new Date(a.time);
                  var bTime = new Date(b.time);
                  if (aTime > bTime) {
                    return -1;
                  } else if (aTime < bTime) {
                    return 1;
                  } else {
                    return 0;
                  }
                }) */
                .map((feedbackEntry, i) => (
                  <UserFeedbackEntry key={i}>
                    {format(
                      new Date(feedbackEntry.time),
                      "yyyy-MM-dd HH:mm:ss"
                    )}
                    <br />
                    {feedbackEntry.isSatisfied ? ":-)" : ":-("}
                    <br />
                    {feedbackEntry.comment}
                  </UserFeedbackEntry>
                ))}
            </UserFeedback>
          </UserRow>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const Stats = styled.div``;
const UserRow = styled.div`
  &:nth-of-type(even) {
    background: var(--color-background-tint);
  }
`;
const UserFeedback = styled.div``;
const UserFeedbackEntry = styled.div``;
