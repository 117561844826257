// if this library ceases to be updated, go straight to mongodb: https://github.com/mongodb/js-bson
// ostensibly could use own code (e.g. https://stackoverflow.com/questions/10593337/is-there-any-way-to-create-mongodb-like-id-strings-without-mongodb), but this library purports to match mongo's bson standard;
// https://github.com/williamkapke/bson-objectid
import ObjectID from "bson-objectid";

import { ChordItem, ChordOrCursor, ChordInputs } from "../_types";

import { Cursor, makeDefaultCursor, CURSOR_ID } from "../ChordsSimple/Cursor";
import { REPEAT_END, REPEAT_START } from "../_constants";

export function makeChordItem(chordInputs: ChordInputs): ChordItem {
  const { root, qual, bass, duration, rhythm, notation } = chordInputs;
  const definition = {
    root,
    qual,
    bass,
  };
  const objectId = ObjectID().toHexString();
  return {
    id: objectId,
    duration,
    rhythm,
    definition,
    notation,
  };
}

export function updateCursorForContext(
  sectionChords: ChordOrCursor[],
  preserveDuration?: boolean
) {
  var newCursor: Cursor = makeDefaultCursor();
  const newCursorIndex = sectionChords.findIndex((val) => val.id === CURSOR_ID);
  // get values of prior chord
  const priorChord = sectionChords[newCursorIndex - 1];
  if (priorChord) {
    newCursor.notation = priorChord.notation
      .slice(0)
      .filter((val) => val !== REPEAT_START && val !== REPEAT_END);
    if (!preserveDuration) {
      newCursor.duration = priorChord.duration ?? 4;
    }
    // *** preferable to look at total beat count up to this point, and rounding to four from there
    // newCursor.duration = 4 - (priorChord.duration % 4);
    // *** unless allow user to input time signature... assuming 4/4 may be annoying... for now, use same duration as prior, as most chords will tend to have same duration
  }
  return {
    newCursor,
    newCursorIndex,
  };
}
