import React from "react";
import styled from "styled-components/macro";

const styles = {
  default: {
    "--background": "var(--g-keyboard-key)",
    "--color": "var(--g-keyboard-font)",
  },
  altKeyColor: {
    "--background": "var(--g-keyboard-key-dark)",
    "--color": "var(--g-keyboard-font-dark)",
  },
  isChecked: {
    // "--background": "var(--ios-keyboard-key-alt)",
    "--background": "var(--color-highlight)",
    "--color": "var(--g-keyboard-font)",
  },
  isHovered: {
    // "--background": "var(--ios-keyboard-key-alt)",
    "--background": "var(--color-highlight-light)",
    "--color": "var(--g-keyboard-font)",
  },
};

export type RowPositions = "left" | "middle" | "right";

type Props = {
  groupId: string;
  gKeyboardHandoffKey: string;
  label: string | React.ReactNode;
  value: string;
  isChecked: boolean;
  altKeyColor?: boolean;
  isHovered?: boolean;
  isEmph?: boolean;
  isDisabled?: boolean;
  rowPosition?: RowPositions;
  // onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const GKey = ({
  groupId,
  gKeyboardHandoffKey,
  label,
  value,
  altKeyColor,
  isChecked,
  isHovered,
  isEmph,
  isDisabled,
  rowPosition = "middle",
}: // onChange,
Props) => {
  // const checkRef = React.useRef<HTMLInputElement>(null);


  // *** could do with some tidying here: you set some styles here via props e.g. isHovered... and you set others within styled-components via same props; also you use a number of manual assignments (e.g. box-shadow for main vs popup) that should live in variables and be calculated as neeed be;

  const style = {
    ...styles.default,
    ...(altKeyColor && styles.altKeyColor),
    ...(isChecked && styles.isChecked),
    ...(isHovered && styles.isHovered),
  };

  return (
    <Wrapper>
      <NativeCheckBoxLabel
        // className={groupId}
        // className={`${groupId} ${gKeyboardHandoffKey}`}
        data-gkey-group-id={groupId}
        data-gkey-handoff-id={gKeyboardHandoffKey}
      >
        <NativeCheckBox
          name={value}
          type="checkbox"
          // checked={onChange ? isChecked : undefined}
          // defaultChecked={!onChange ? isChecked : undefined}
          defaultChecked={isChecked}
          // onChange={onChange}
          disabled={isDisabled}
          // className={groupId}
          // ref={checkRef}
        />
      </NativeCheckBoxLabel>
      {/*  <PopupConnect
        style={style as React.CSSProperties}
        isHovered={isHovered}
      /> */}
      <PresentationCheckBoxMain
        style={style as React.CSSProperties}
        isHovered={isHovered}
        isEmph={isEmph}
        rowPosition={rowPosition}
      >
        <Label isHovered={isHovered}>{label}</Label>
      </PresentationCheckBoxMain>
      <PresentationCheckBoxPopup
        style={style as React.CSSProperties}
        isHovered={isHovered}
        isEmph={isEmph}
        rowPosition={rowPosition}
      >
        <Label>{label}</Label>
      </PresentationCheckBoxPopup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* width: fit-content; */
  /* top: 0; */
`;

// use label to ensure entire surface of the wrapper is clickable; the checkbox itself cannot be so easily styled
const NativeCheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-tap-highlight-color: transparent;
`;
export const NativeCheckBox = styled.input`
  /* it takes a lot to properly hide a checkbox, esp. in Safari; some of this doesn't appear to be necessary, but it does the trick*/
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  opacity: 0;
`;

// Label used for both main checkbox and popup: only main receives isHovered, so only main will "hide" its label on hover
const Label = styled.label<{
  isHovered?: boolean | undefined;
}>`
  font-size: ${17 / 16}rem;
  transition: opacity 200ms;
  ${({ isHovered }) =>
    isHovered &&
    `
    opacity: 0;
    /* color: transparent; */
    `}
`;

//***set var for popup scale... use it to offset popup placement */
const POPUP_SCALE = 1.5;
const POPUP_TRANSLATE_Y = -56;
// percent offset to cause popups for buttons in left and right columns to have outermost border aligned with base button
const POPUP_TRANSLATE_X = ((POPUP_SCALE - 1) / 2) * 100;

const PresentationCheckBox = styled.div<{
  isHovered?: boolean | undefined;
  isEmph?: boolean | undefined;
  rowPosition?: RowPositions;
}>`
  min-height: 48px;
  min-width: 40px;
  max-width: 160px;
  padding: 4px;
  border-radius: 6px;
  background-color: var(--background);
  color: var(--color);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  /*  ${({ isEmph }) =>
    isEmph &&
    `
  font-weight: bold;
  `}
   */
  /* ought to add some focus styling */

  /* don't want active state while using touch/mousedown logic */
  /*  ${NativeCheckBoxLabel}:active + & {
    opacity: 0.6;
  } */
`;

const PresentationCheckBoxMain = styled(PresentationCheckBox)`
  position: relative;
  top: 0;
  transition: background-color 0.1s;
  box-shadow: 0 1px 1px var(--g-keyboard-shadow);
  ${({ isHovered }) =>
    isHovered &&
    `
    /* ensure main presentation box sits above its "popup" to avoid popup's shadow overlapping it */
    z-index: 2;
    `}
  ${({ isHovered, rowPosition }) =>
    isHovered &&
    rowPosition === "left" &&
    `
    border-top-left-radius: unset;
    `}
  ${({ isHovered, rowPosition }) =>
    isHovered &&
    rowPosition === "right" &&
    `
    border-top-right-radius: unset;
    `}
`;

const PresentationCheckBoxPopup = styled(PresentationCheckBox)`
  position: absolute;
  top: 0;
  /* setting both left and right to 0 will center element */
  left: 0;
  right: 0;
  transform: translateY(0px) scale(1);
  transition: transform 0.2s, scale 0.2s, box-shadow 0.2s, background-color 0.1s,
    opacity 0.1s;
  opacity: 0;
  /* popup is scaled when it appears, approx. 1.5x; so need to reduce shadow by 1/3 to ensure it appears same size as "main box" shadow */
  box-shadow: 0 1px 1px -0.333px var(--g-keyboard-shadow);

  ${({ isHovered }) =>
    isHovered &&
    `
    transform: translateY(${POPUP_TRANSLATE_Y}px) scale(${POPUP_SCALE});
    opacity: 1;
    /* ensure hovered key sits in front of others, otherwise part of PresentationCheckBoxPopup may be hidden by neighboring keys */
    z-index:1;
    `}
  ${({ isHovered, rowPosition }) =>
    isHovered &&
    rowPosition === "left" &&
    `
    transform: translateX(${POPUP_TRANSLATE_X}%) translateY(${POPUP_TRANSLATE_Y}px) scale(${POPUP_SCALE});
    border-bottom-left-radius: unset;
    `}
  ${({ isHovered, rowPosition }) =>
    isHovered &&
    rowPosition === "right" &&
    `
    transform: translateX(-${POPUP_TRANSLATE_X}%) translateY(${POPUP_TRANSLATE_Y}px) scale(${POPUP_SCALE});
    border-bottom-right-radius: unset;
    `}
`;

/* const PopupConnect = styled(PresentationCheckBoxPopup)`
  z-index: 1;
  transition: transform 0.1s, scale 0.1s, box-shadow 0.1s, background-color 0.1s,
    opacity 0.2s;
  ${({ isHovered }) =>
    isHovered &&
    `
      transform: translateY(-22px) scale(1.0);
      `}
`;
 */
