/**
 * @generated SignedSource<<cdb7582072ce7ea12af14a3213bf9969>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSongTags_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly tags: ReadonlyArray<string>;
  readonly " $fragmentType": "UpdateSongTags_song";
};
export type UpdateSongTags_song$key = {
  readonly " $data"?: UpdateSongTags_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSongTags_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSongTags_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "69116ee2510b2ae228d1945a07ac67fa";

export default node;
