/**
 * @generated SignedSource<<102d9f1b448a39475674e587dd72a2d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type confirmEmailInput = {
  clientMutationId?: string | null;
  token: string;
  userId: string;
};
export type ConfirmEmailMutation$variables = {
  input: confirmEmailInput;
};
export type ConfirmEmailMutation$data = {
  readonly confirmEmail: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message?: string;
    }>;
    readonly success: boolean | null;
  } | null;
};
export type ConfirmEmailMutation = {
  response: ConfirmEmailMutation$data;
  variables: ConfirmEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "confirmEmailPayload",
    "kind": "LinkedField",
    "name": "confirmEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "UserError",
            "abstractKey": "__isUserError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2645ccdf1680d9ddb5a5450f8fb83532",
    "id": null,
    "metadata": {},
    "name": "ConfirmEmailMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmEmailMutation(\n  $input: confirmEmailInput!\n) {\n  confirmEmail(input: $input) {\n    success\n    errors {\n      __typename\n      ... on UserError {\n        __isUserError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6edd3ca86bedf50769234030c20e079f";

export default node;
