import React from "react";
import { ZERO_WIDTH_SPACE } from "../_constants";

// *** ideally would be optionally responsive to screenwidth, for variable-width elements
export function ellipsizeText(txt = "", maxLen = 20, replacer = "...") {
  var txtLen = txt.length;
  // if 0 provided as maxLen, allow unlimited
  if (txtLen <= maxLen || maxLen === 0) {
    return txt;
  } else {
    // trim text to make room for replacer, so (text + replacer) length === maxLen;
    // add back 1 b/c - at least for your primary use case - you're adding 3 periods, which take up less width than word characters
    const sliceTo = maxLen - replacer.length + 1;
    return txt.slice(0, sliceTo) + replacer;
  }
}

// simple, inclusive regex for urls; if it finds "http(s):" or "www.", it captures all following characters until it encounters a space char; ideally, would avoid capturing invalid URL chars, esp. those likely to be found in notes, e.g. a comma following the url
const regExHyperlink = new RegExp(/(?:(?:https?:)|(?:www.))\S+/, "gi");
const regExUrlCharsToBreakOn = new RegExp(/([./])/, "g");

export function replaceHyperlinks(str: string) {
  let allMatches = [],
    match;

  while ((match = regExHyperlink.exec(str)) !== null) {
    let matchingVal = match[0];
    allMatches.push({
      matchingVal,
      indexStart: match.index,
      indexEnd: regExHyperlink.lastIndex,
    });
  }

  // no matches
  if (allMatches.length === 0) {
    return str;
  }

  // 1+ matches
  var workingIndex = 0,
    displayUrl,
    hrefUrl,
    stringJSX = [],
    thisMatch;

  for (let i = 0; i < allMatches.length; i++) {
    thisMatch = allMatches[i];
    stringJSX.push(
      <React.Fragment key={`${i}_text`}>
        {str.slice(workingIndex, thisMatch.indexStart)}
      </React.Fragment>
    );
    // allow breaks at certain URL chars
    // *** consider hiding all but domain from displayed URL
    displayUrl = thisMatch.matchingVal.replace(
      regExUrlCharsToBreakOn,
      `$1${ZERO_WIDTH_SPACE}`
    );
    hrefUrl =
      // if first char is "h", must begin with "http(s)" instead of "www"; need to add protocol ("//") to urls that begin with "www" b/c behavior w/o protocol is to open link under current domain (e.g. grantkidner.com/www.google.com)
      // using "https://" instead of "//": with only "//" as protocol, iOS assumes I'm trying to redirect to another app [in the com.grantkidner.xxx domain], and opens CleverChords instead of the URL
      (thisMatch.matchingVal[0] === "h" ? "" : "https://") +
      thisMatch.matchingVal;
    stringJSX.push(
      <a
        key={`${i}_url`}
        href={hrefUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {displayUrl}
      </a>
    );

    workingIndex = thisMatch.indexEnd;
    if (i === allMatches.length - 1) {
      stringJSX.push(
        <React.Fragment key={`${i}_remainingText`}>
          {str.slice(workingIndex)}
        </React.Fragment>
      );
    }
  }

  return stringJSX;
}

export function addBreaksToEmail(email: string) {
  return email
    .replace("@", `${ZERO_WIDTH_SPACE}@`)
    .replace("+", `${ZERO_WIDTH_SPACE}+`);
}
