import React from "react";
import { useHistory } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { AccountQueryQuery } from "../__generated__/AccountQueryQuery.graphql";

import { RequestEmailConfirmation } from "./RequestEmailConfirmation";
import { DataNotFound } from "../_components";
import { FriendlyErrors } from "../_components/FriendlyErrors";
import { UpdateOwnEmail } from "./UpdateOwnEmail";
// import { UpdateOwnPassword } from "./UpdateOwnPassword";
import Button from "../_components/Button";
import { QueryOptions } from "../_types/relayTypes";
import { DeleteOwnAccount } from "./DeleteOwnAccount";

// @@@ disable in-app purchases @@@
/* 
import { AppContext } from "../_context/appContext";
import { isNativeCap } from "../_utilities/detectNative";
import { IAPStore, USE_IAP_TEST_DATA } from "../_plugins/InAppPurchases";
import { Upgrade } from "../InAppPurchase/Upgrade";
import { RestorePurchases } from "../InAppPurchase/RestorePurchases"; 
*/

type Props = {
  queryOptions: QueryOptions;
};

export function AccountQuery(props: Props) {
  const history = useHistory();

  // @@@ disable in-app purchases @@@
  /*   const { state, dispatch } = React.useContext(AppContext);
  const { app } = state; */

  const data = useLazyLoadQuery<AccountQueryQuery>(
    graphql`
      query AccountQueryQuery {
        viewer {
          user {
            id
            email
            emailConfirmed
          }
          errors {
            __typename
            message
          }
        }
      }
    `,
    {},
    props.queryOptions
  );

  const dataWithErrors = data.viewer;
  if (!dataWithErrors) {
    throw new Error(
      "Expected top-level query variable on 'data' to be defined."
    );
  }

  const { user, errors } = dataWithErrors;

  return (
    <>
      {/* data result */}
      {user && (
        <>
          {/* @@@ disable in-app purchases @@@ */}
          {/* 
          {USE_IAP_TEST_DATA && <Upgrade productGroupName="test" />}
          <Upgrade productGroupName="pro" />
          {(isNativeCap || app.isInDebugMode) && (
            <>
              <RestorePurchases />
              <Button
                onClick={() => IAPStore.manageSubscriptions()}
                iconId="calendar"
                fill="clear"
                palette="secondary"
              >
                Manage Subscriptions
              </Button>
            </>
          )} 
          */}

          <UpdateOwnEmail email={user.email} />
          <RequestEmailConfirmation emailConfirmed={user.emailConfirmed} />
          {/* <UpdateOwnPassword /> */}
          <Button
            onClick={() => history.push("/forgot-password")}
            iconId="key"
            fill="clear"
          >
            Reset Password
          </Button>
          <DeleteOwnAccount email={user.email} />
        </>
      )}
      {/* null result */}
      {!user && <DataNotFound />}

      {/* user-friendly error message from schema */}
      {errors.length > 0 && <FriendlyErrors errors={errors} />}
    </>
  );
}
