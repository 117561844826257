/**
 * @generated SignedSource<<87ba5d27051b2cc4e2f1fd74ba06ecd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type updateSectionNotesInput = {
  clientMutationId?: string | null;
  mongoVersion: number;
  notes: string;
  sectionId: string;
  songId: string;
};
export type UpdateSectionNotesMutation$variables = {
  input: updateSectionNotesInput;
};
export type UpdateSectionNotesMutation$data = {
  readonly updateSectionNotes: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "AuthError";
      readonly message: string;
    }>;
    readonly section: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionNotes_section">;
    } | null;
    readonly song: {
      readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionNotes_song">;
    } | null;
  } | null;
};
export type UpdateSectionNotesMutation = {
  response: UpdateSectionNotesMutation$data;
  variables: UpdateSectionNotesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSectionNotesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionNotesPayload",
        "kind": "LinkedField",
        "name": "updateSectionNotes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionNotes_song"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSectionNotes_section"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSectionNotesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "updateSectionNotesPayload",
        "kind": "LinkedField",
        "name": "updateSectionNotes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed80bb5ae2c4398bc0adb40eba01ce34",
    "id": null,
    "metadata": {},
    "name": "UpdateSectionNotesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSectionNotesMutation(\n  $input: updateSectionNotesInput!\n) {\n  updateSectionNotes(input: $input) {\n    song {\n      ...UpdateSectionNotes_song\n      id\n    }\n    section {\n      ...UpdateSectionNotes_section\n      id\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment UpdateSectionNotes_section on SongSection {\n  id\n  notes\n}\n\nfragment UpdateSectionNotes_song on Song {\n  id\n  mongoVersion\n}\n"
  }
};
})();

(node as any).hash = "4f76cb36e2fe487bf03b9033116185bc";

export default node;
