import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";

import { SectionQueryChords_song$key } from "../__generated__/SectionQueryChords_song.graphql";
import { SectionQueryChords_section$key } from "../__generated__/SectionQueryChords_section.graphql";

import { ChordsSimple } from "../ChordsSimple/ChordsSimple";
import { ModalError } from "../_components/ModalError";
import useUpdateSectionChordsMutation from "./UpdateSectionChordsMutation";

type Props = {
  songRef: SectionQueryChords_song$key;
  sectionRef: SectionQueryChords_section$key;
};

export default function SectionQueryChords(props: Props) {
  const song = useFragment(
    graphql`
      fragment SectionQueryChords_song on Song {
        id
        mongoVersion
        ...UpdateSectionChordsMutation_song
        ...ChordsSimple_song
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment SectionQueryChords_section on SongSection {
        id
        rhythmUnit
        chords {
          id
          duration
          rhythm
          definition {
            root
            qual
            bass
          }
          notation
        }
        ...UpdateSectionChordsMutation_section
        ...ChordsSimple_section
      }
    `,
    props.sectionRef
  );

  const { handleUpdateSectionChords, setErrMsg, errMsg } =
    useUpdateSectionChordsMutation({
      songRef: song,
      sectionRef: section,
    });

  return (
    <>
      <ChordsSimple
        sectionId={section.id}
        // chords={section.chords}
        handleUpdateSectionChords={handleUpdateSectionChords}
        song={song}
        section={section}
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
