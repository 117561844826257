import { StatusBar, Style } from "@capacitor/status-bar";

export const setStatusBarStyleDark = async () => {
  try {
    await StatusBar.setStyle({ style: Style.Dark });
  } catch (err) {
    console.error(err);
  }
};

export const setStatusBarStyleLight = async () => {
  try {
    await StatusBar.setStyle({ style: Style.Light });
  } catch (err) {
    console.error(err);
  }
};
