interface EdgesWithNodes<T> {
  readonly edges:
    | readonly ({
        readonly node: T;
      } | null)[]
    | null;
}

export function getNodeArrayFromEdges<T>(val: EdgesWithNodes<T>) {
  return val && val.edges
    ? val.edges.map(
        (edge) =>
          edge?.node ||
          // return null if edge undefined, to avoid "undefined" as a possible array value
          null
      )
    : [];
}

// get type of node within graphql edge array
// *** now you properly pass refs to fragments (in most places), don't need to "hack" your way into the GraphQL types anymore.  (This came from DisplaySection)
/* 
import { ArrayElement } from "../_types";

export type SectionEdge = NonNullable<
  ArrayElement<NonNullable<MoveSection_song$data["sections"]["edges"]>>
>;
export type SectionNode = SectionEdge["node"]; 
*/

// get type of node within graphql edge array
/* import { SongSections_song } from "../../__generated__/SongSections_song.graphql";
import { ArrayElement } from "../../_types"; */
/* export type SectionEdge = NonNullable<
  ArrayElement<NonNullable<SongSections_song["sections"]["edges"]>>
>;
export type SectionNode = SectionEdge["node"]; */

/* 
import { ArrayElement } from "../../_types";

// get type of node within graphql edge array
export type SectionEdge = NonNullable<
  ArrayElement<NonNullable<MoveSection_song$data["sections"]["edges"]>>
>;
export type SectionNode = SectionEdge["node"]; */
