var debounceTimeout: NodeJS.Timeout | null = null;

export function debounceInline(delay: number = 50) {
  if (debounceTimeout === null) {
    debounceTimeout = setTimeout(resetTimeout, delay);
    return true;
  } else {
    return false;
  }

  function resetTimeout() {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = null;
  }
}
