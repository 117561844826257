/**
 * @generated SignedSource<<4a253d3a0041ecba92ae176b4e412f88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSectionNotes_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly " $fragmentType": "UpdateSectionNotes_song";
};
export type UpdateSectionNotes_song$key = {
  readonly " $data"?: UpdateSectionNotes_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSectionNotes_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSectionNotes_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "578302227a9bc219ff8cc07fa77d66c9";

export default node;
