import React, { createRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Page, PageHeader, PageContent } from "../_components/Page";
import { SectionQuery } from "./SectionQuery";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { lockPortrait, unlockOrientation } from "../_plugins/OrientationCapacitor";
import { isNativeCap } from "../_utilities/detectNative";
import { FetchPolicy } from "relay-runtime";
import { NON_BREAKING_SPACE } from "../_constants";

export const SectionPageContentRef = createRef<HTMLDivElement>();
export const SectionSortableContext = React.createContext(false);

export type SectionNavDetails = {
  // songId: string | null;
  name: string;
  songTitle: string;
};

export const Section: React.FC = () => {
  const { songId, sectionId } = useParams<{
    songId: string;
    sectionId: string;
  }>();
  const history = useHistory();
  const location: any = useLocation();
  // backPath may be defined by a location state passed by useHistory.push(); otherwise use default;
  const backPath: string = location?.state?.backPath ?? `/song-edit/${songId}`;
  React.useEffect(() => {
    isNativeCap && lockPortrait();
    return () => {
      isNativeCap && unlockOrientation();
    };
  }, [isNativeCap, lockPortrait, unlockOrientation]);

  const [sectionNavDetails, setSectionNavDetails] =
    React.useState<SectionNavDetails>({
      // songId: null,
      name: "",
      songTitle: "",
    });

  const [allowDrag, setAllowDrag] = React.useState<boolean>(true);
  function toggleAllowDrag() {
    setAllowDrag((allowDrag) => {
      return !allowDrag;
    });
  }

  const controls = (
    <>
      {/*      <Button
        onClick={toggleAllowDrag}
        fill="clear"
        size="sm"
        toggledOn={allowDrag}
      >
        <Icon id={"move"} size={24} strokeWidth={2} />
      </Button> */}
      <Button
        onClick={() => history.push(`/section-meta/${songId}/${sectionId}`)}
        fill="ghost"
        size="sm"
      >
        <Icon id={"settings"} size={24} strokeWidth={2} />
      </Button>
    </>
  );
  return (
    <Page disablePullRefresh={true}>
      <PageHeader
        title={`${sectionNavDetails.name}${
          sectionNavDetails.songTitle &&
          NON_BREAKING_SPACE +
            " | " +
            NON_BREAKING_SPACE +
            sectionNavDetails.songTitle
        }`}
        backPath={backPath}
        allowHeaderCollapse={false}
        controls={[controls]}
      />
      <PageContent ref={SectionPageContentRef}>
        {/* <PageContent> */}
        {(queryOptions) => {
          return (
            <SectionSortableContext.Provider value={allowDrag}>
              <SectionQuery
                songId={songId}
                sectionId={sectionId}
                setSectionNavDetails={setSectionNavDetails}
                queryOptions={queryOptions}
                // queryOptions={{...queryOptions, fetchPolicy: "store-or-network" as FetchPolicy}}
              />
            </SectionSortableContext.Provider>
          );
        }}
      </PageContent>
    </Page>
  );
};
