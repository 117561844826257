import React, { useEffect } from "react";
import styled from "styled-components/macro";

import { SideTabs } from "../../_components/SideTabs";
import { chordQualGroups, CHORD_QUALS } from "../../_music/chordQualLib";
import type { QualGroupName } from "../../_music/chordQualLib";
import { InputTabWrapper } from "./InputTabWrapper";
import { GKeyboard } from "../../_components/GKeyboard";

/* 
  add touchmove on tabs (as it is on buttons)?
*/

export function formatKeyLabel(label: string) {
  // assumes only one term will be found per chord qual label;
  const termsToShrink = ["add", "sus"];
  for (let term of termsToShrink) {
    const termLength = term.length;
    const termIndex = label.indexOf(term);
    if (termIndex > -1) {
      return (
        <>
          {label.slice(0, termIndex)}
          <SmallText>
            {label.slice(termIndex, termIndex + termLength)}
          </SmallText>
          {label.slice(termIndex + termLength)}
        </>
      );
    }
  }
  return label;
}

function addTransparencyByIndex(i: number) {
  // add 1 to indext to ensure even item at index 0 gets some transparency
  return `hsl(0 0% var(--color-tint-lightness) / ${(i + 1) * 0.01})`;
}

type Props = {
  selectedId: number;
  qual: string;
  handleEditChord: ({
    name,
    value,
  }: {
    name: string;
    value: string | number;
  }) => void;
  moveToTab: () => void;
};

export function QualityInputGroups(props: Props) {
  function handleSetSelectedTab(id: string) {
    setSelectedTab(id as QualGroupName);
    // on chord qual group tab change, auto-select the first qual in group
    // *** testing w/o, to see which I prefer
    /*  const newSelectedTab = chordQualGroups[id as QualGroupName];
    setTimeout(() => {
      props.handleEditChord({
        name: "qual",
        value: newSelectedTab[0],
      });
    }, 200); */
  }

  function handleManualChange(value: string) {
    props.handleEditChord({
      name: "qual",
      value: value,
    });
    props.moveToTab();
  }

  const qualGroupNames = Object.keys(chordQualGroups) as QualGroupName[];
  const selectedQualGroupName = CHORD_QUALS[props.qual]?.group || "maj";

  const [selectedTab, setSelectedTab] = React.useState<QualGroupName>(
    selectedQualGroupName
  );

  /*  const switchQualTab = React.useCallback(() => {
    setSelectedTab(selectedQualGroupName);
  }, [selectedQualGroupName]); */

  useEffect(() => {
    setSelectedTab(selectedQualGroupName);
    // switchQualTab();

    // *** eslint:react-hooks/exhaustive-deps wants me to include selectedQualGroupName in dependency array; however, this causes an unnecessary call on this useEffect when a user selects a quality that's in a different tab from the last, and I see no detriment to leaving it out, besides to avoid the eslint warning; as evidenced by the commented code, I attempted to solve via useCallback, but all that did was add an extra step to reach the same result: ultimately still required to check for changes in selectedQualGroupName, so the same unnecessary render will occur

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.selectedId /* selectedQualGroupName */]);

  const tabsObj = qualGroupNames.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: (
        <QualKeyboardWrapper>
          <GKeyboard
            groupId={`ChordKeeper_Checkbox_qual_${curr}`}
            items={chordQualGroups[curr].map((val) => {
              var thisQual = CHORD_QUALS[val];
              return {
                label: formatKeyLabel(thisQual.label),
                // label: thisQual.label,
                value: val,
              };
            })}
            checkedItems={[props.qual]}
            manualChange={handleManualChange}
            columns={3}
          />
        </QualKeyboardWrapper>
      ),
    };
  }, {});

  const TAB_WIDTH = 52;
  const POSITION_LEFT = 8;

  return (
    <Wrapper
      style={
        {
          "--padding-left": `${TAB_WIDTH + POSITION_LEFT}px`,
        } as React.CSSProperties
      }
    >
      <SideTabs
        groupId={"QualityInputGroups"}
        items={qualGroupNames.map((val, i) => {
          return {
            id: val,
            label: val,
            content: () => <>{val}</>,
            // if tab selected, want it to be same color as chord quality background, so remove color from tab;
            background:
              selectedTab === val ? "none" : addTransparencyByIndex(i),
          };
        })}
        selectedTab={selectedTab}
        handleSetSelectedTab={handleSetSelectedTab}
        wrapperStyles={{
          tabWidth: TAB_WIDTH,
          positionLeft: POSITION_LEFT,
        }}
      />
      <InputTabWrapper tabsObj={tabsObj} selectedTab={selectedTab} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  padding-left: var(--padding-left);
  background-color: var(--background);
  /* b/c you render InputTabWrapper as a child, which has flex:1; without display:flex; all children collapse to 0 height... meaning your touches *near* the keys weren't actually hitting the keyboard (b/c it was only showing due to overflow) and thus you couldn't touchstart on keyboard then drag to key */
  display: flex;
`;

const QualKeyboardWrapper = styled.div`
  padding: 4px;
`;

const SmallText = styled.span`
  font-size: ${15 / 16}rem;
`;
