import React from "react";
import styled from "styled-components/macro";
import {
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogLabel,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";
import {
  useTransition,
  animated,
  config,
  AnimationResult,
  Controller,
  SpringValue,
} from "@react-spring/web";

import Button from "./Button";
import { PALETTES } from "./Button";
import Spacer from "./Spacer";
import VisuallyHidden from "./VisuallyHidden";
import { BREAKPOINTS, MEDIA_QUERIES } from "../_constants";
import { DEFAULT_HEADER_HEIGHT } from "./Page";
import { Icon, icons } from "./Icon";
import { LoadingSpinnerComplete } from "./LoadingSpinner";

type Props = {
  doShowDialog: boolean;
  closeDialog: () => void;
  handleConfirm?: () => void;
  label?: string;
  headerIcon?: { id: keyof typeof icons; color: string };
  message?: string | React.ReactNode;
  actionText?: string;
  cancelText?: string;
  actionColor?: keyof typeof PALETTES;
  isConfirmDisabled?: boolean;
  // potentially useful for e.g. closing an existing modal before opening another
  handleOnModalOpen?: () => void;
  handleOnModalClose?: () => void;
  handleOnModalCancel?: () => void;
  showCloseButton?: boolean;
  showFullHeader?: boolean;
  // Reach, via ref, gives focus to the designated "leastDestructiveRef" button (or other element);
  // if instead want to give an input focus on modal show, need to re-target or deactivate this behavior
  // *** ReachUI Dialog's behavior: give focus to first focusable element; use initialFocusRef or doUseLeastDestructiveRef to override
  // *** note: element you want focused (e.g. input) still needs autoFocus={true} prop to tell browser what to focus;
  doUseLeastDestructiveRef?: boolean;
  children?: React.ReactNode;
  isInFlight?: boolean;
};

export function Modal({
  doShowDialog,
  closeDialog,
  handleConfirm,
  label,
  headerIcon,
  message,
  actionText = "Ok",
  cancelText,
  actionColor = "primary",
  isConfirmDisabled: isSubmitDisabled,
  handleOnModalOpen,
  handleOnModalClose,
  handleOnModalCancel,
  showCloseButton = false,
  showFullHeader = false,
  doUseLeastDestructiveRef = true,
  children,
  isInFlight,
}: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const wasDialogCanceled = React.useRef<boolean>(false);

  /*   React.useEffect(() => {
    if (doShowDialog === true && handleOnModalOpen) {
      console.log("handling open");
      handleOnModalOpen();
    }
  }, [doShowDialog]);
 */

  React.useEffect(() => {
    if (doShowDialog === true) {
      wasDialogCanceled.current = false;
    }
  }, [doShowDialog]);

  function handleCancelDialog() {
    wasDialogCanceled.current = true;
    closeDialog();
  }

  const transitions = useTransition(doShowDialog, {
    from: { opacity: 0, transform: "translate3d(0,-16px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,16px,0)" },
    config: {
      ...config.stiff,
      // ...config.molasses,
      // will usually want to use clamp for transition animations, otherwise e.g. here user will believe they are able to touch main page buttons after the modal disappears, but the modal will exist longer than expected, though fully transparent
      clamp: true,
    },
    onRest: (
      result: AnimationResult,
      spring: Controller | SpringValue,
      item: unknown
    ) => {
      if (
        // check doShowDialog for a "close" state
        doShowDialog === false &&
        // check item b/c onRest is triggered twice, at start and end of transition, once with each value (in this case, for closing the modal, true then false); only trigger on "false" to ensure it's the end of the animation and only fires once
        item === false
      ) {
        handleOnModalClose && handleOnModalClose();
        // check ref value whether close is due to a "cancel" instead of a "submit" action on modal; reset on every open (doShowDialog === true)
        if (wasDialogCanceled.current === true) {
          handleOnModalCancel && handleOnModalCancel();
        }
      }
    },
  });

  return (
    <Wrapper>
      {transitions(
        (style, transitionShowDialog) =>
          transitionShowDialog && (
            <OverlayAnimated
              leastDestructiveRef={cancelRef}
              onClick={handleCancelDialog}
              style={{ opacity: style.opacity }}
              // isOpen is usually used to toggle modal display, but *not* when using an animation library like react-spring
              // *** encountering a bug where: toggle doShowDialog to false, modal "leave" animation completes, but modal continues to display (though at opacity:0); inconsistent, so haven't troubleshooted well, but it's as if "doShowDialog" is toggled back to "true" in the middle of the "leave" animation, but react-spring's transition loses track of it, so the animation ends in a "leave" state, but with "doShowDialog" at true... so, in addition to the render guard (transitionShowDialog &&), testing using "isOpen" in parallel
              // *** this didn't seem to help... appears this is caused by a toggle of doShowDialog false->true->false that does properly leave state of doShowDialog at false, along with corresponding "leave" animation... but leaves transitionShowDialog at true...
              // *** nope... transitionShowDialog is ending at false, alongside doShowDialog... so... believe this is not a bug in the code, but buggy behavior caused by React's hot reload... yup!  So, note to self, for similar odd behavior, test after hot reload vs after true reload; if it only occurs after hot, assume you can ignore
              // isOpen={transitionShowDialog}
              // onDismiss enables use of "escape" button to close modal
              onDismiss={handleCancelDialog}
            >
              <ContentAnimated style={{ transform: style.transform }}>
                {/* variety of "header" options follow */}

                {/* 1 - label only, to satisfy ReachUI accessibility, hidden from view */}
                {false && (
                  <VisuallyHidden>
                    <StyledAlertDialogLabel>{label}</StyledAlertDialogLabel>
                  </VisuallyHidden>
                )}

                {/* 2 - label in a header, always shown if defined */}
                {false && (
                  <ModalHeader>
                    <VisuallyHidden show={label !== undefined}>
                      <StyledAlertDialogLabel>{label}</StyledAlertDialogLabel>
                    </VisuallyHidden>
                  </ModalHeader>
                )}

                {/* 3 - full header with label and close button in top right -- close button always visible */}
                {/* this cancel button always exists, to act as a target for leastDestructiveRef, even when visible button co-exists; sharing the ref between them and showing one or the other gave inconsistent results */}
                {/* 2023-01-16: why bother having 2 close buttons instead of attaching the ref to the visible one and removing the invisible one?  Don't recall why I did this initially, but at the moment I see value b/c it allows me to avoid the UI outline that appears around the button that receives the leastDestructiveRef; */}
                {false && (
                  <ModalHeader>
                    <VisuallyHidden>
                      <StyledAlertDialogLabel>{label}</StyledAlertDialogLabel>
                    </VisuallyHidden>
                    <VisuallyHidden>
                      <CloseButtonAbs
                        ref={doUseLeastDestructiveRef ? cancelRef : null}
                        onClick={handleCancelDialog}
                        expand="inline"
                        fill="clear"
                        palette="mono"
                        size="xs"
                      >
                        <Icon id="x" />
                      </CloseButtonAbs>
                    </VisuallyHidden>
                    <CloseButtonAbs
                      onClick={handleCancelDialog}
                      expand="inline"
                      fill="clear"
                      palette="mono"
                      size="xs"
                    >
                      <Icon id="x" />
                    </CloseButtonAbs>
                  </ModalHeader>
                )}

                {/* 4 - full header with label and close button in top right -- all behind VisuallyHidden */}
                {true && (
                  <VisuallyHidden show={showFullHeader}>
                    <ModalHeader>
                      <StyledAlertDialogLabel>{label}</StyledAlertDialogLabel>
                      <CloseButtonAbs
                        ref={doUseLeastDestructiveRef ? cancelRef : null}
                        onClick={handleCancelDialog}
                        expand="inline"
                        fill="clear"
                        palette="mono"
                        size="xs"
                      >
                        <Icon id="x" />
                      </CloseButtonAbs>
                    </ModalHeader>
                  </VisuallyHidden>
                )}

                {headerIcon && (
                  <>
                    <HeaderIconWrapper>
                      <HeaderIcon
                        style={
                          {
                            "--header-icon-color": headerIcon.color ?? "black",
                          } as React.CSSProperties
                        }
                      >
                        <Icon id={headerIcon.id} size={32} strokeWidth={2} />
                      </HeaderIcon>
                    </HeaderIconWrapper>
                    <Spacer size={12} axis="vert" />
                  </>
                )}
                {message && <Message>{message}</Message>}
                {/* body of modal */}
                {children && (
                  <ModalBody
                    style={
                      {
                        "--padding-bottom":
                          showCloseButton || handleConfirm ? "60px" : "8px",
                      } as React.CSSProperties
                    }
                  >
                    {children}
                  </ModalBody>
                )}
                {(showCloseButton || handleConfirm) && (
                  <>
                    <ControlWrapper>
                      {/* in Chrome, including this button wrapped in VisuallyHidden causes some odd issue with its neighboring confirm button, resulting in a small amount of (apparent) padding to the right of it */}
                      {/* <VisuallyHidden show={showCloseButton}>
                      <ComposedButton
                        ref={doUseLeastDestructiveRef ? cancelRef : null}
                        onClick={handleCancelDialog}
                        fill="ghost"
                        size="sm"
                        expand="inline"
                      >
                        {handleConfirm ? "Cancel" : "Close"}
                      </ComposedButton>
                    </VisuallyHidden> */}
                      {showCloseButton && (
                        <ComposedButton
                          onClick={handleCancelDialog}
                          fill="ghost"
                          size="sm"
                          expand="inline"
                        >
                          {cancelText
                            ? cancelText
                            : handleConfirm
                            ? "Cancel"
                            : "Close"}
                        </ComposedButton>
                      )}
                      {showCloseButton && handleConfirm && (
                        <Spacer size={16} axis="horiz" />
                      )}

                      {handleConfirm && (
                        <ComposedButton
                          onClick={handleConfirm}
                          size="md"
                          expand="inline"
                          disabled={isSubmitDisabled}
                          palette={isSubmitDisabled ? "mono" : actionColor}
                          // type="submit"
                        >
                          {actionText}
                        </ComposedButton>
                      )}
                    </ControlWrapper>
                  </>
                )}
                {isInFlight && <LoadingSpinnerComplete overlay />}
              </ContentAnimated>
              {!doShowDialog && <ClosingShield />}
            </OverlayAnimated>
          )
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Overlay = styled(AlertDialogOverlay)`
  /* *** if/when remove Reach bundled styles, will need to implement basic styling; start with below; */
  /*
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; 
  */
  background: var(--color-shadow);
`;
const OverlayAnimated = animated(Overlay);

const Content = styled(AlertDialogContent)`
  position: absolute;
  /* use fixed value for "top" so when the web view is resized (by Capacitor's Keyboard plugin, with config: resize:"native"), the content doesn't abruptly shift position */
  /* https://stackoverflow.com/questions/47803973/using-calc-with-envsafe-area-inset */
  top: calc(env(safe-area-inset-top) + ${DEFAULT_HEADER_HEIGHT}px);
  left: 8px;
  right: 8px;
  max-width: ${BREAKPOINTS.smTabletMin}px;
  /* override default reach width */
  width: unset;
  margin: 0 auto;

  /* padding: 8px; */
  padding: 0px;

  background: var(--color-background-tint);
  box-shadow: var(--shadow-elevation-high);
  /* in Safari only, a small ~2px border/padding persists for unknown reason */
  /* padding: 4px; */
  /* border: "4px solid hsla(0, 0%, 0%, 0.5)"; */
  /* currently have buttons along edge of Content, without margin or padding, so hide overflow to account for any difference in contours of bottom right button (namely border-radius) */
  overflow: hidden;

  border-radius: 4px;
  /*  @media ${MEDIA_QUERIES.smTabletAndUp} {
    border-radius: 4px;
  } */
`;
const ContentAnimated = animated(Content);

const StyledAlertDialogLabel = styled(AlertDialogLabel)`
  padding: 4px 24px;
  font-size: ${15 / 16}rem;
  font-weight: 500;
  color: var(--color-text-medium);
  /* color: var(--color-text); */
  text-transform: uppercase;
`;

const HeaderIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderIcon = styled.div`
  color: var(--header-icon-color);
  padding: 8px 24px;
  border-left: 2px dotted;
  border-right: 2px dotted;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  border-radius: 4px;
`;

const ModalBody = styled.div`
  /* padding: 16px; */
  /* padding-top: 20px; */
  /* padding-bottom: 8px; */
  /* font-size: ${18 / 16}rem; */
  /* color: var(--color-text-medium); */

  /* useful for email addresses, long user input (e.g. song titles), to prevent y-axis overflow */
  overflow-wrap: anywhere;

  max-height: 75vh;
  overflow-y: auto;
  padding: 8px;
  padding-bottom: var(--padding-bottom);
`;

const Message = styled.div`
  padding: 8px 16px;
  font-size: ${16 / 16}rem;
  /* color: var(--color-text-medium); */
  color: var(--color-text);
  /* useful for email addresses, long user input (e.g. song titles), to prevent y-axis overflow */
  overflow-wrap: anywhere;
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  /* padding-top: 8px; */
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0.96;
  /* background: var(--color-background-tint); */
  /* backdrop-filter: blur(1px); */
`;

const ComposedButton = styled(Button)`
  min-width: 80px;
`;

const ModalHeader = styled.div`
  position: relative;
  min-height: 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
`;

const CloseButton = styled(Button)`
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const CloseButtonAbs = styled(CloseButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

// prevent clicks on modal content (namely, buttons) as it is animating out
const ClosingShield = styled.div`
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
