const defaultInitializer = (index: number) => index;

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index));
}

// export function range(
//   size: number,
//   startAt: number = 0
// ): ReadonlyArray<number> {
//   return [...Array(size).keys()].map((i) => i + startAt);
// }
