import React, { useState } from "react";

// import { UpgradeFeature } from "../InAppPurchase/UpgradeFeature";
// import { ErrorSuspenseWrapper } from "../_components/ErrorSuspenseWrapper";
import { ToggleColorTheme } from "../Settings/ToggleColorTheme";
import Button from "../_components/Button";
import { Icon } from "../_components/Icon";
import { Modal } from "../_components/Modal";
import { ToggleShowTranspose } from "../Settings/ToggleShowTranspose";
import { ToggleRhythmDisplay } from "../Settings/ToggleRhythmDisplay";

export const SongControls: React.FC = () => {
  const [doShowDialog, setDoShowDialog] = useState<boolean>(false);
  function open() {
    setDoShowDialog(true);
  }
  function close() {
    setDoShowDialog(false);
  }

  return (
    <>
      <Button onClick={open} fill="ghost" size="sm">
        <Icon id={"sliders"} size={24} strokeWidth={2} />
      </Button>
      <Modal
        doShowDialog={doShowDialog}
        closeDialog={close}
        doUseLeastDestructiveRef={true}
        label={"Performance Tools"}
        showFullHeader={true}
      >
        {/* @@@ disable in-app purchases @@@ */}
        {/* 
        <ErrorSuspenseWrapper>
          {({ queryOptions }) => (
            <UpgradeFeature
              productGroupName={"pro"}
              featureName="Dark Mode"
              lockIconSize={16}
              lockIconOffset={[5, 50]}
              lockIconOffsetUnit="percent"
              queryOptions={queryOptions}
            >
              <ToggleColorTheme size="md" />
            </UpgradeFeature>
          )}
        </ErrorSuspenseWrapper>
        <ErrorSuspenseWrapper>
          {({ queryOptions }) => (
            <UpgradeFeature
              productGroupName={"pro"}
              featureName="Transpose"
              lockIconSize={16}
              lockIconOffset={[5, 50]}
              lockIconOffsetUnit="percent"
              queryOptions={queryOptions}
            >
              <ToggleShowTranspose />
            </UpgradeFeature>
          )}
        </ErrorSuspenseWrapper>
         */}
        <ToggleColorTheme size="md" />
        <ToggleShowTranspose />
        <ToggleRhythmDisplay />
      </Modal>
    </>
  );
};
