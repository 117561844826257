import React, { FormEvent } from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components/macro";

import { ShareSongPDF_song$key } from "../__generated__/ShareSongPDF_song.graphql";
import { ShareSongPDFMutation } from "../__generated__/ShareSongPDFMutation.graphql";

import Button from "../_components/Button";
import { AddShareInputsWrapper } from "./SongManageShare";
import { ModalError } from "../_components/ModalError";
import {
  InputSuggest,
  InputValueSuggestion,
} from "../_components/InputSuggest";
import { addBreaksToEmail } from "../_utilities/strings";
import { ShareRelation } from "../UserSongs/UserSongsList";
import { Modal } from "../_components/Modal";
import { ModalTitle, ModalHeader } from "../_style-components/Modal.style";
import { TitleArtist } from "../_style-components/TitleArtist";
import { FORM_PLACEHOLDERS } from "../_utilities/forms";

type Props = {
  songRef: ShareSongPDF_song$key;
  shareRelations: ShareRelation[];
  userEmail: string;
};

export function ShareSongPDF(props: Props) {
  const song = useFragment(
    graphql`
      fragment ShareSongPDF_song on Song {
        id
        title
        artist
        mongoVersion
        permissions {
          user
          email
          isOwner
          canEdit
          didAccept
        }
      }
    `,
    props.songRef
  );

  const [commit, isInFlight] = useMutation<ShareSongPDFMutation>(graphql`
    mutation ShareSongPDFMutation($input: shareSongPDFInput!) {
      shareSongPDF(input: $input) {
        errors {
          __typename
          message
        }
      }
    }
  `);

  const [errMsg, setErrMsg] = React.useState<string>("");
  const [shareEmail, setShareEmail] = React.useState<string>("");
  const [doShowModal, setDoShowModal] = React.useState<boolean>(false);

  function openModal() {
    setDoShowModal(true);
  }

  function closeModal() {
    setDoShowModal(false);
    resetForm();
  }

  function resetForm() {
    setShareEmail("");
  }

  function handleCommit() {
    commit({
      variables: {
        input: {
          songId: song.id,
          shareEmail,
        },
      },
      onCompleted: (response) => {
        if (response.shareSongPDF) {
          const { errors } = response.shareSongPDF;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message).join("; ");
            setErrMsg(errMsgs);
            // setFriendlyErrors(errors);
          } else {
            closeModal();
            // resetForm();
          }
        }
      },
      onError: (err) => {
        setErrMsg(err.message);
      },
    });
  }

  function handleSubmit(e: FormEvent) {
    // make sure e.preventDefault() comes first for form submission
    e.preventDefault();
    handleCommit();
  }

  function handleConfirmModal() {
    handleCommit();
  }

  const valueSuggestions: InputValueSuggestion[] = [
    // special case for ShareSongPDF (versus SongAddShare): allow user to "share" with self via email; make this the top choice;
    { email: props.userEmail, shareCount: 9999 },
    ...props.shareRelations,
  ]
    .sort((a, b) => b.shareCount - a.shareCount)
    .map((val) => {
      const { email } = val;
      const display = addBreaksToEmail(email);
      return {
        key: email,
        value: email,
        display,
      };
    });

  return (
    <>
      <Button
        onClick={openModal}
        fill="clear"
        disabled={isInFlight}
        iconId="send"
        palette="secondary"
      >
        Email PDF
      </Button>
      <Modal
        doShowDialog={doShowModal}
        closeDialog={closeModal}
        label={"Email PDF"}
        handleConfirm={handleConfirmModal}
        actionText={"Send"}
        showCloseButton={true}
        isInFlight={isInFlight}
      >
        <ModalHeader>
          <TitleArtist title={song.title} artist={song.artist} />
        </ModalHeader>
        <ModalTitle>Email PDF</ModalTitle>
        <Form onSubmit={handleSubmit}>
          <AddShareInputsWrapper>
            <TextInputWrapper>
              <InputSuggest
                valueSuggestions={valueSuggestions}
                value={shareEmail}
                onChange={setShareEmail}
                label={"Email address"}
                placeholder={FORM_PLACEHOLDERS.emailOfAnother}
              />
            </TextInputWrapper>
          </AddShareInputsWrapper>
        </Form>
      </Modal>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const Form = styled.form`
  padding-bottom: 16px;
  padding-left: 10px;
`;
const TextInputWrapper = styled.div`
  flex: 1;
  /* constrain long text; NOTE: this took me a while to realize: if you want these to constrain flex-calculated minimum size, these need to be applied to the same element that is "flexing"; e.g. I tried applying these to a child of this element, which resulted in long titles pushing the right-side button off the screen, because they did not reduce the size calculation of this element; */
  overflow: hidden;
`;
