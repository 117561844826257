/**
 * @generated SignedSource<<0721d2761426aa01404504d367aea05f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminUserDetails_songs$data = {
  readonly songs: ReadonlyArray<{
    readonly createdAt: string;
    readonly id: string;
    readonly permissions: ReadonlyArray<{
      readonly canEdit: boolean;
      readonly didAccept: boolean;
      readonly email: string;
      readonly isOwner: boolean;
      readonly user: string;
    }>;
    readonly title: string;
    readonly updatedAt: string;
    readonly user: string;
  } | null>;
  readonly " $fragmentType": "AdminUserDetails_songs";
};
export type AdminUserDetails_songs$key = {
  readonly " $data"?: AdminUserDetails_songs$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminUserDetails_songs">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminUserDetails_songs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Song",
      "kind": "LinkedField",
      "name": "songs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SongPermission",
          "kind": "LinkedField",
          "name": "permissions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isOwner",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canEdit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "didAccept",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AllDataQueryResult",
  "abstractKey": null
};
})();

(node as any).hash = "3b95a971021bfa4602c9ba60a83033b1";

export default node;
