/**
 * @generated SignedSource<<2aa31a11938025d6305233c69f8d3f1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneSection_song$data = {
  readonly id: string;
  readonly mongoVersion: number;
  readonly " $fragmentType": "CloneSection_song";
};
export type CloneSection_song$key = {
  readonly " $data"?: CloneSection_song$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneSection_song">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneSection_song",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mongoVersion",
      "storageKey": null
    }
  ],
  "type": "Song",
  "abstractKey": null
};

(node as any).hash = "4dee97fcae05daa47c84052d5ebbb23b";

export default node;
