import React from "react";
import { useSpring, config, SpringValue } from "@react-spring/web";
import { hapticsImpactMedium } from "../_plugins/Haptics";
import { useWindowDimensions } from "./useWindowDimensions";

export type PartialTouch = {
  identifier: number;
  pageX: number;
  pageY: number;
  timestamp: number;
};

const TOUCH_STATE = {
  unknown: "unknown",
  preventRefresh: "preventRefresh",
  refreshInit: "refreshInit",
  refreshTriggered: "refreshTriggered",
} as const;

type TouchState = typeof TOUCH_STATE[keyof typeof TOUCH_STATE];

export type OngoingTouch = {
  start: PartialTouch;
  lastMove: PartialTouch;
  count: number;
  maxAbsHorizDeviation: number;
  touchState: TouchState;
};

const PARTIAL_TOUCH_INIT: PartialTouch = {
  identifier: -1,
  pageX: 0,
  pageY: 0,
  timestamp: 0,
};
const ONGOING_TOUCH_INIT: OngoingTouch = {
  start: PARTIAL_TOUCH_INIT,
  lastMove: PARTIAL_TOUCH_INIT,
  count: 0,
  maxAbsHorizDeviation: 0,
  touchState: TOUCH_STATE.unknown,
};

const MAX_TOUCH_EVENTS_PER_TOUCH = 0; // 200
const MAX_TOUCHES_FOR_PULL_DOWN = 0; // 100
const VERT_MIN_FOR_DISPLAY = 50; // 20
const VERT_MIN_TO_REFRESH = 200; // 100
const TOUCH_DURATION_FOR_HOLD_MS = 300; // 300;
const REFRESH_MIN_DISTANCE_FROM_BOTTOM = VERT_MIN_TO_REFRESH + 20;
const MAX_HORIZ_DEVIATION = VERT_MIN_TO_REFRESH / 5;

const LINE_WIDTH = 8;

// these filters do *not* work in iOS Safari;
const CANVAS_DO_USE_FILTER = false;
const CANVAS_FILTER_BLUR = `blur(4px)`;
const CANVAS_FILTER_OPACITY = `opacity(0.2)`;
const CANVAS_FILTER_DROP_SHADOW = `drop-shadow(0px 0px 4px #888)`;

export type UseTouchStyle = {
  opacity: SpringValue<number>;
  transform: SpringValue<number[]>;
};

const GRAFFITI_MODE_SHOW_START_END = false;

type Props = {
  doAllowPullRefresh: boolean;
  callbackPullDown: () => void;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
  canvasParentRef?: React.RefObject<HTMLDivElement>;
  canvasSizingContainerRef?: React.RefObject<HTMLDivElement>;
  // touches return page-based positioning; need offset of canvas to draw on canvas directly under touches;
  canvasOffset?: { x: number; y: number };
  // was used to experiment with sending touches programmatically; unused currently;
  touchTargetRef?: React.RefObject<HTMLDivElement>;
  doShowGraffiti?: boolean;
};

export function usePullRefresh(props: Props) {
  const canvasOffsetX = props.canvasOffset?.x ?? 0,
    canvasOffsetY = props.canvasOffset?.y ?? 0;

  const ongoingTouches = React.useRef<OngoingTouch[]>([]);
  const canvasContext = React.useRef<
    CanvasRenderingContext2D | undefined | null
  >(props.canvasRef?.current?.getContext("2d"));

  // useful for detecting *when* a resize has occurred (e.g. orientation change), but not for detecting what that new size is; see notes in useWindowDimensions;
  const { windowDimensions } = useWindowDimensions();

  const [primaryTouch, setPrimaryTouch] =
    React.useState<OngoingTouch>(ONGOING_TOUCH_INIT);

  React.useEffect(() => {
    canvasContext.current = props.canvasRef?.current?.getContext("2d");
  }, [props.canvasRef]);

  const resizeCanvas = React.useCallback(() => {
    var canvasSizingContainer = props.canvasSizingContainerRef?.current;
    var canvasParent = props.canvasParentRef?.current;
    var canvas = props.canvasRef?.current;
    if (canvas && canvasParent && canvasSizingContainer) {
      var newWidth = canvasSizingContainer.clientWidth;
      var newHeight = canvasSizingContainer.clientHeight;
      canvasParent.style.height = newHeight + "px";
      canvasParent.style.width = newWidth + "px";
      canvasParent.style.marginTop = -newHeight / 2 + "px";
      canvasParent.style.marginLeft = -newWidth / 2 + "px";
      // remember you aren't setting *style* width/height here, you're setting properties of the canvas element;
      canvas.width = newWidth;
      canvas.height = newHeight;
      // wipe canvas on size change
      // *** at moment, effectively done automatically
    }
  }, [props.canvasRef, props.canvasParentRef, props.canvasSizingContainerRef]);

  React.useEffect(() => {
    resizeCanvas();
  }, [windowDimensions, resizeCanvas]);

  function getOngoingTouchInit({
    touch,
    doPreventRefresh,
  }: {
    touch: React.Touch;
    doPreventRefresh?: boolean;
  }) {
    return {
      start: copyTouch(touch),
      lastMove: copyTouch(touch),
      count: 1,
      maxAbsHorizDeviation: 0,
      touchState:
        props.doAllowPullRefresh && !doPreventRefresh
          ? TOUCH_STATE.unknown
          : TOUCH_STATE.preventRefresh,
    };
  }

  function setInitialTouch(touch: React.Touch) {
    const distanceFromBottom = window.innerHeight - touch.clientY;
    var doPreventRefresh = false;
    if (distanceFromBottom < REFRESH_MIN_DISTANCE_FROM_BOTTOM) {
      // if touch is too close to bottom of screen for user to drag finger for required length of gesture, prevent refresh for that touch, so UI doesn't appear and tease user;
      doPreventRefresh = true;
    }
    var initOngoingTouch = getOngoingTouchInit({ touch, doPreventRefresh });
    ongoingTouches.current?.push(initOngoingTouch);
    // if want first touch (of multiple) to be "primary" that will handle drag-to-refresh, then setPrimaryTouch only if primaryTouch has default value (identified === -1);
    // *** problem with doing this: in order to allow animateRefreshSpinnerOff to play in full before "removing" refresh UI, I do not reset primaryTouch to default when I unsetTouch; what this means is identifier will not return to -1, but will remain what it was; if the next touch (that you'd want to be the primaryTouch) doesn't have the same identifier as prior primaryTouch, then primaryTouch doesn't get updated, and so... neither does the refresh UI;
    // if (primaryTouch.start.identifier === -1) {
    setPrimaryTouch(initOngoingTouch);
    // }
  }

  const SPRING_DEFAULT_TRANSFORM = [1, 1, 0];
  const SPRING_DEFAULT_OPACITY = 0;
  const [useTouchStyle, api] = useSpring(() => {
    return {
      transform: SPRING_DEFAULT_TRANSFORM,
      opacity: SPRING_DEFAULT_OPACITY,
      config: { ...config.default, clamp: true },
    };
  });

  function animateRefreshSpinnerUpdate(updatedOngoingTouch: OngoingTouch) {
    var scaleY =
      (updatedOngoingTouch.lastMove.pageY - updatedOngoingTouch.start.pageY) /
      VERT_MIN_FOR_DISPLAY;
    var scaleX = Math.min(1 / (scaleY * 2), 1);
    var ratioToRefresh =
      (updatedOngoingTouch.lastMove.pageY - updatedOngoingTouch.start.pageY) /
      VERT_MIN_TO_REFRESH;
    var rot = ratioToRefresh * 360 * (1 / 4);
    var opacity = 0.2 + ratioToRefresh;

    api.start({
      transform: [scaleY, scaleX, rot],
      opacity,
      config: { ...config.default, clamp: false },
    });
  }

  function animateRefreshSpinnerOff() {
    api.start({
      transform: SPRING_DEFAULT_TRANSFORM,
      opacity: SPRING_DEFAULT_OPACITY,
      config: { ...config.stiff, clamp: true },
    });
  }

  function animateRefreshSpinnerActive() {
    api.start({
      transform: [0.5, 0.5, 360],
      opacity: 0.5,
      config: { ...config.default, clamp: false },
    });
  }

  function updateTouch(
    latestTouch: React.Touch,
    ongoingTouch: OngoingTouch,
    idx: number,
    ongoingTouchUpdate?: Partial<OngoingTouch>
  ) {
    // console.log("updateTouch");
    const updatedOngoingTouch = {
      ...ongoingTouch,
      ...ongoingTouchUpdate,
    };
    ongoingTouches.current?.splice(idx, 1, updatedOngoingTouch);
    if (
      // primaryTouch.start.identifier === -1 ||
      latestTouch.identifier === primaryTouch?.start.identifier
    ) {
      setPrimaryTouch(updatedOngoingTouch);
      animateRefreshSpinnerUpdate(updatedOngoingTouch);
    }
  }

  function unsetTouch(touch: React.Touch, idx: number) {
    // console.log("unsetTouch");
    ongoingTouches.current?.splice(idx, 1);
    if (touch.identifier === primaryTouch?.start.identifier) {
      // setPrimaryTouch(ONGOING_TOUCH_INIT);
      animateRefreshSpinnerOff();
    }
  }

  function resetTouch(touch: React.Touch, idx: number) {
    // console.log("resetTouch");
    var initOngoingTouch = getOngoingTouchInit({
      touch,
      // currently reset only following a condition that also signals no "refresh" is intended
      doPreventRefresh: true,
    });
    ongoingTouches.current?.splice(idx, 1, initOngoingTouch);
    if (
      // primaryTouch.start.identifier === -1 ||
      touch.identifier === primaryTouch?.start.identifier
    ) {
      setPrimaryTouch(initOngoingTouch);
      animateRefreshSpinnerOff();
    }
  }

  function drawAllStartCircles(touches: React.TouchList) {
    const ctx = canvasContext.current;
    if (ctx) {
      const bodyStyle = window.getComputedStyle(document.body, null);
      const color = bodyStyle.color;

      for (let i = 0; i < touches.length; i++) {
        // const color = colorForTouch(touches[i]);
        ctx.beginPath();
        ctx.arc(
          touches[i].pageX - canvasOffsetX,
          touches[i].pageY - canvasOffsetY,
          8,
          0,
          2 * Math.PI,
          false
        );
        ctx.fillStyle = color;
        ctx.fill();
      }
    }
  }

  function handleStart(evt: React.TouchEvent) {
    const touches = evt.changedTouches;
    if (props.doShowGraffiti && GRAFFITI_MODE_SHOW_START_END) {
      drawAllStartCircles(touches);
    }
    for (let i = 0; i < touches.length; i++) {
      setInitialTouch(touches[i]);
    }
  }

  function drawAllMoveLines(touches: React.TouchList) {
    const ctx = canvasContext.current;
    if (ctx) {
      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/filter
      // *** this does not work iOS Safari; if want blur effect, may need to wrap canvas in a container that uses blur();
      if (CANVAS_DO_USE_FILTER) {
        ctx.filter = `${CANVAS_FILTER_BLUR} ${CANVAS_FILTER_OPACITY} ${CANVAS_FILTER_DROP_SHADOW}`;
      }
      // const bodyStyle = window.getComputedStyle(document.body, null);
      //   const color = bodyStyle.color;
      for (let i = 0; i < touches.length; i++) {
        const idx = ongoingTouchIndexById(touches[i].identifier);
        if (ongoingTouches.current && idx >= 0) {
          const thisOngoingTouch = ongoingTouches.current[idx];
          if (thisOngoingTouch.touchState === TOUCH_STATE.preventRefresh) {
            // only draw if know you won't be refreshing, as don't want canvas doodles to show up overlapping the pull-to-refesh UI;
            const color = colorForTouch(touches[i]);
            const lastMove = thisOngoingTouch.lastMove;
            ctx.beginPath();
            ctx.moveTo(
              lastMove.pageX - canvasOffsetX,
              lastMove.pageY - canvasOffsetY
            );
            ctx.lineTo(
              touches[i].pageX - canvasOffsetX,
              touches[i].pageY - canvasOffsetY
            );
            ctx.lineWidth =
              MAX_TOUCH_EVENTS_PER_TOUCH > 0
                ? LINE_WIDTH *
                  ((MAX_TOUCH_EVENTS_PER_TOUCH - thisOngoingTouch.count) /
                    MAX_TOUCH_EVENTS_PER_TOUCH)
                : LINE_WIDTH;
            ctx.strokeStyle = color;
            ctx.stroke();
          }
        } else {
          // console.log("Can't determine which touch to continue.");
        }
      }
    }
  }

  function handleMove(evt: React.TouchEvent) {
    const touches = evt.changedTouches;
    if (props.doShowGraffiti) {
      // b/c other code in handleMove updates ongoingTouches, need to draw on canvas first;
      drawAllMoveLines(touches);
    }
    for (let i = 0; i < touches.length; i++) {
      const idx = ongoingTouchIndexById(touches[i].identifier);
      if (ongoingTouches.current && idx >= 0) {
        const thisOngoingTouch = ongoingTouches.current[idx];
        // update ongoingTouch
        thisOngoingTouch.lastMove = copyTouch(touches[i]);
        thisOngoingTouch.count++;
        const thisHorizDeviation = Math.abs(
          thisOngoingTouch.lastMove.pageX - thisOngoingTouch.start.pageX
        );
        if (thisHorizDeviation > thisOngoingTouch.maxAbsHorizDeviation) {
          thisOngoingTouch.maxAbsHorizDeviation = thisHorizDeviation;
        }
        testTouch(touches[i], thisOngoingTouch, idx);
      } else {
        // console.log("Can't determine which touch to continue.");
      }
    }
  }

  function testTouch(
    latestTouch: React.Touch,
    ongoingTouch: OngoingTouch,
    idx: number
  ) {
    // this is a general purpose check: doesn't apply spec. to pull-to-refresh, so run this test first;
    if (
      MAX_TOUCH_EVENTS_PER_TOUCH > 0 &&
      ongoingTouch.count > MAX_TOUCH_EVENTS_PER_TOUCH
    ) {
      console.log("max touch events exceeded; resetting touch;");
      resetTouch(latestTouch, idx);
      clearCanvas();
      return;
    }

    if (ongoingTouch.touchState === TOUCH_STATE.preventRefresh) {
      // refresh action not possible for this touch; no point checking further;
      // updateTouch(latestTouch, ongoingTouch, idx);
      return;
    }

    const touchDidNotExceedCount = (ongoingTouch: OngoingTouch) =>
      MAX_TOUCHES_FOR_PULL_DOWN > 0 &&
      ongoingTouch.count > MAX_TOUCHES_FOR_PULL_DOWN;

    const touchDidNotDeviateHoriz = (ongoingTouch: OngoingTouch) =>
      ongoingTouch.maxAbsHorizDeviation > MAX_HORIZ_DEVIATION;

    const touchDidNotGoAboveStart = (ongoingTouch: OngoingTouch) =>
      ongoingTouch.lastMove.pageY <= ongoingTouch.start.pageY;

    const touchDidNotHold = (ongoingTouch: OngoingTouch) =>
      // test only for duration between touchStart and first touchMove (which should be at count===2)
      ongoingTouch.count === 2 &&
      ongoingTouch.lastMove.timestamp >
        ongoingTouch.start.timestamp + TOUCH_DURATION_FOR_HOLD_MS;

    if (ongoingTouch.touchState === TOUCH_STATE.unknown) {
      if (
        touchDidNotExceedCount(ongoingTouch) ||
        touchDidNotDeviateHoriz(ongoingTouch) ||
        touchDidNotGoAboveStart(ongoingTouch) ||
        touchDidNotHold(ongoingTouch)
      ) {
        updateTouch(latestTouch, ongoingTouch, idx, {
          touchState: TOUCH_STATE.preventRefresh,
        });
        animateRefreshSpinnerOff();
        return;
      }
    }

    // if refresh has been started, don't let horiz deviation cancel
    if (ongoingTouch.touchState === TOUCH_STATE.refreshInit) {
      if (
        (MAX_TOUCHES_FOR_PULL_DOWN > 0 &&
          ongoingTouch.count > MAX_TOUCHES_FOR_PULL_DOWN) ||
        ongoingTouch.lastMove.pageY <= ongoingTouch.start.pageY
      ) {
        updateTouch(latestTouch, ongoingTouch, idx, {
          touchState: TOUCH_STATE.preventRefresh,
        });
        animateRefreshSpinnerOff();
        return;
      }
    }

    const { start, lastMove } = ongoingTouch;
    // const dX = lastMove.pageX - start.pageX;
    const dY = lastMove.pageY - start.pageY;

    if (
      ongoingTouch.touchState === TOUCH_STATE.refreshInit &&
      dY > VERT_MIN_TO_REFRESH
    ) {
      // haptic feedback
      hapticsImpactMedium();
      // initiate refresh
      props.callbackPullDown();
      // state change needs to come beore animation (api.start), or re-render will effectively cancel animation;
      updateTouch(latestTouch, ongoingTouch, idx, {
        touchState: TOUCH_STATE.preventRefresh,
      });
      animateRefreshSpinnerActive();
      clearCanvas();
      return;
    } else if (dY > VERT_MIN_FOR_DISPLAY) {
      updateTouch(latestTouch, ongoingTouch, idx, {
        touchState: TOUCH_STATE.refreshInit,
      });
      return;
    }
  }

  function sendSimulatedTouchCancel(latestTouch: React.Touch) {
    // send simulated "touchcancel" event; this will cause "handleCancel" to trigger, which means it will ultimately call "unsetTouch" like option 1; but this *should* also cancel other impacts of the touch, e.g. in iOS pull-to-refresh is likely to result in overscroll, and this touchcancel should be equivalent to user lifting his finger, causing scroll to return to neutral position;
    // *** did not work as hoped (and described in above comment), whether used on actual target of touch, or my designated touchTargetRef
    if (props.touchTargetRef?.current) {
      // var touchTarget = props.touchTargetRef.current;
      var touchTarget = latestTouch.target;
      console.log(touchTarget);
      const touch = new Touch({
        identifier: latestTouch.identifier,
        target: touchTarget,
      });
      const touchEvent = new TouchEvent("touchcancel", {
        // touches: [touch],
        // your code uses "changedTouches", so you need to populate at least this TouchList
        changedTouches: [touch],
        view: window,
        cancelable: true,
        bubbles: true,
      });
      touchTarget.dispatchEvent(touchEvent);
    }
  }

  function drawAllEndSquares(touches: React.TouchList) {
    const ctx = canvasContext.current;
    const END_SQUARE_WIDTH = 16;

    for (let i = 0; i < touches.length; i++) {
      // const bodyStyle = window.getComputedStyle(document.body, null);
      // const color = bodyStyle.color;
      let idx = ongoingTouchIndexById(touches[i].identifier);

      if (ongoingTouches.current && idx >= 0) {
        const thisOngoingTouch = ongoingTouches.current[idx];
        if (thisOngoingTouch.touchState === TOUCH_STATE.preventRefresh) {
          // only draw if know you won't be refreshing, as don't want canvas doodles to show up overlapping the pull-to-refesh UI;
          const color = colorForTouch(touches[i]);
          const lastMove = thisOngoingTouch.lastMove;

          if (ctx) {
            ctx.lineWidth = LINE_WIDTH;
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.moveTo(
              lastMove.pageX - canvasOffsetX,
              lastMove.pageY - canvasOffsetY
            );
            ctx.lineTo(
              touches[i].pageX - canvasOffsetX,
              touches[i].pageY - canvasOffsetY
            );
            // and a square at the end
            ctx.fillRect(
              touches[i].pageX - canvasOffsetX - END_SQUARE_WIDTH / 2,
              touches[i].pageY - canvasOffsetY - END_SQUARE_WIDTH / 2,
              END_SQUARE_WIDTH,
              END_SQUARE_WIDTH
            );
          }
        }
      } else {
        // console.log("Can't figure out which touch to end.");
      }
    }
  }

  function handleEnd(evt: React.TouchEvent) {
    const touches = evt.changedTouches;
    if (props.doShowGraffiti && GRAFFITI_MODE_SHOW_START_END) {
      drawAllEndSquares(touches);
    } else {
      clearCanvas();
    }
    for (let i = 0; i < touches.length; i++) {
      let idx = ongoingTouchIndexById(touches[i].identifier);
      if (ongoingTouches.current && idx >= 0) {
        unsetTouch(touches[i], idx);
      } else {
        // console.log("Can't figure out which touch to end.");
      }
    }
  }

  function handleCancel(evt: React.TouchEvent) {
    const touches = evt.changedTouches;
    for (let i = 0; i < touches.length; i++) {
      let idx = ongoingTouchIndexById(touches[i].identifier);
      unsetTouch(touches[i], idx);
    }
  }

  function colorForTouch(touch: React.Touch) {
    let h = (touch.pageX + touch.pageY) % 360;
    // let s = `${touch.pageY % 100}%`;
    let s = `100%`;
    let l = `50%`;
    const color = `hsl(${h}, ${s}, ${l})`;
    return color;
  }

  function copyTouch({ identifier, pageX, pageY }: React.Touch): PartialTouch {
    return { identifier, pageX, pageY, timestamp: Date.now() };
  }

  function ongoingTouchIndexById(idToFind: number) {
    if (ongoingTouches.current) {
      for (let i = 0; i < ongoingTouches.current.length; i++) {
        const id = ongoingTouches.current[i].lastMove.identifier;
        if (id === idToFind) {
          return i;
        }
      }
    }
    return -1;
  }

  function clearCanvas() {
    var canvas = props.canvasRef?.current;
    if (canvas) {
      const ctx = canvasContext.current;
      if (ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      }
    }
  }

  return {
    touchHandlers: {
      onTouchStart: handleStart,
      onTouchEnd: handleEnd,
      onTouchCancel: handleCancel,
      onTouchMove: handleMove,
    },
    primaryTouch,
    VERT_MIN_FOR_DISPLAY,
    useTouchStyle,
  };
}
