import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import graphql from "babel-plugin-relay/macro";
import { useFragment, usePaginationFragment } from "react-relay/hooks";
import { useTransition, animated, config } from "@react-spring/web";

import { getNodeArrayFromEdges } from "../_types/graphqlData";
import { typeNotEmpty } from "../_types/typeHelpers";
import { TestDeck_songs$key } from "../__generated__/TestDeck_songs.graphql";
import { TestDeck_user$key } from "../__generated__/TestDeck_user.graphql";
import { createSortedDeck, filterSongs } from "./leitner";
import type { ArrayElement } from "../_types";

import { AddSongTestEvent } from "./AddSongTestEvent";
import Button from "../_components/Button";

import { AppContext } from "../_context/appContext";
import { getSongStats } from "../_utilities/cardStatsCombined";

import { PageFieldsContext } from "./LeitnerPage";

type Props = {
  songs: TestDeck_songs$key;
  user: TestDeck_user$key;
};

export function TestDeck(props: Props) {
  const { state, dispatch } = React.useContext(AppContext);
  const { searchFilter, tagFilter } = state.app;
  const history = useHistory();

  const userData = useFragment(
    graphql`
      fragment TestDeck_user on User {
        id
        ...AddSongTestEvent_user
        boxIntervals
        testHistory {
          song
          eventType
          time
        }
      }
    `,
    props.user
  );

  const { data: songData } = usePaginationFragment(
    graphql`
      fragment TestDeck_songs on User
      @argumentDefinitions(count: { type: "Int" }, cursor: { type: "String" })
      @refetchable(queryName: "TestDeckPaginationQuery") {
        songs(first: $count, after: $cursor)
          # uncertain whether I should be reusing this conenction key here
          @connection(key: "UserSongs_songs") {
          __id
          edges {
            node {
              id
              title
              artist
              tags
              notes
              viewerPermission {
                user
                email
                isOwner
                canEdit
                didAccept
              }
            }
          }
        }
      }
    `,
    props.songs
  );

  const { boxIntervals, testHistory } = userData;

  const { songs } = songData;
  const songNodes = getNodeArrayFromEdges(songs).filter(typeNotEmpty);

  const songNodesWithStats = songNodes.map((node) => {
    const songTestHistory = testHistory.filter((val) => val.song === node.id);
    return {
      ...node,
      // songStats: getSongStats(node.testHistory),
      songStats: getSongStats(songTestHistory),
      testHistory: songTestHistory,
    };
  });

  type SongNodes = typeof songNodesWithStats;
  type OneSongNode = ArrayElement<SongNodes>;

  let songsObj: Record<string, OneSongNode> = {};
  for (let song of songNodesWithStats) {
    songsObj[song.id] = song;
  }

  const { sortedDueToday, tagsDueToday } = createSortedDeck(
    // exclude non-accepted shared songs from leitner review deck
    songNodesWithStats.filter(
      (song) => song.viewerPermission.didAccept === true
    ),
    boxIntervals
  );

  const filteredSortedDueToday = filterSongs(
    sortedDueToday,
    tagFilter,
    searchFilter
  );

  const nextSong = filteredSortedDueToday.slice(0, 1)[0];

  const [pageFields, setSomePageFields] = React.useContext(PageFieldsContext);

  React.useEffect(
    () =>
      setSomePageFields({
        title: `${filteredSortedDueToday.length} song${
          filteredSortedDueToday.length !== 1 ? "s" : ""
        } to review`,
      }),
    // note: want to use filteredSortedDueToday.length as the dependency, not filteredSortedDueToday, which is an array of objects that will differ with every render; combine that with fact setSomePageFields modifies state of parent, you'll end up in a never-ending re-render loop
    [filteredSortedDueToday.length]
  );

  const nextSongId = nextSong?.id || "none";

  const transitions = useTransition(nextSongId, {
    from: { opacity: 0, transform: [0, 50, 0, 0] },
    enter: { opacity: 1, transform: [0, 0, 0, 0] },
    leave: { opacity: 0, transform: [0, -50, 0, 0] },
    config: { ...config.stiff, clamp: true },
  });

  return (
    <Wrapper>
      {transitions((style, nextSongIdTransition) => (
        <TransistionWrapperAnim
          style={{
            opacity: style.opacity,
            transform: style.transform.to((x, y) => {
              // hack to resolve bug in WKWebView; the scroll bar of the transformed element dislocates and appears somewhere in the middle; removing the transform after animation finishes "resets" the scroll bar:
              // as described in the bug report below, the scroll bar still appears in the wrong position, but removing the transform causes it to quickly return to correct position
              // https://github.com/ionic-team/ionic-framework/issues/22379
              // https://bugs.webkit.org/show_bug.cgi?id=218124
              if (y === 0) {
                return `none`;
              } else return `translate3d(0,${y}%,0)`;
            }),
          }}
        >
          {nextSongIdTransition !== "none" && (
            <AddSongTestEvent
              userRef={userData}
              leitnerCard={songsObj[nextSongIdTransition]}
              boxIntervals={boxIntervals}
              countSongsDue={filteredSortedDueToday.length}
            />
          )}
          {nextSongIdTransition === "none" && (
            <FinishedWrapper>
              <FinishedMessage>
                Finished reviewing{" "}
                {tagFilter.length > 0 || searchFilter.length > 0
                  ? "selected songs."
                  : "all songs."}
              </FinishedMessage>
              <Button onClick={() => history.push(`/user-songs`)} iconId="list">
                Return to song list
              </Button>
            </FinishedWrapper>
          )}
        </TransistionWrapperAnim>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const TransitionWrapper = styled.div`
  /* animated.div needs absolute positioning b/c, during animation process, both pages briefly exist at once; without absolute, those pages become stacked, each taking up 50% briefly */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const TransistionWrapperAnim = animated(TransitionWrapper);

const FinishedWrapper = styled.div`
  font-size: ${18 / 16}rem;
`;
const FinishedMessage = styled.div`
  padding: 16px 16px;
`;
