import React from "react";
import styled from "styled-components/macro";
import {
  AlertTriangle,
  ArrowDown,
  ArrowDownCircle,
  ArrowUp,
  AtSign,
  BookOpen,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUp,
  ChevronUp,
  Clock,
  Copy,
  CornerUpLeft,
  Delete,
  DollarSign,
  Edit,
  Edit2,
  Eye,
  EyeOff,
  FilePlus,
  Frown,
  Gift,
  Grid,
  HelpCircle,
  Home,
  Key,
  List,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Maximize2,
  Menu,
  MessageCircle,
  Minus,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Move,
  Music,
  Play,
  Plus,
  PlusSquare,
  RefreshCw,
  Repeat,
  RotateCcw,
  RotateCw,
  Search,
  Send,
  Settings,
  Sliders,
  Smile,
  Sun,
  Target,
  Terminal,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Trash2,
  Unlock,
  User,
  UserCheck,
  UserMinus,
  UserPlus,
  Users,
  UserX,
  X,
  XCircle,
} from "react-feather";

export const icons = {
  "alert-triangle": AlertTriangle,
  "arrow-down": ArrowDown,
  "arrow-down-circle": ArrowDownCircle,
  "arrow-up": ArrowUp,
  "at-sign": AtSign,
  "book-open": BookOpen,
  calendar: Calendar,
  check: Check,
  "check-circle": CheckCircle,
  "check-square": CheckSquare,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "chevrons-up": ChevronsUp,
  clock: Clock,
  copy: Copy,
  "corner-up-left": CornerUpLeft,
  delete: Delete,
  "dollar-sign": DollarSign,
  edit: Edit,
  "edit-2": Edit2,
  eye: Eye,
  "eye-off": EyeOff,
  "file-plus": FilePlus,
  frown: Frown,
  gift: Gift,
  grid: Grid,
  "help-circle": HelpCircle,
  home: Home,
  key: Key,
  list: List,
  lock: Lock,
  "log-in": LogIn,
  "log-out": LogOut,
  mail: Mail,
  "maximize-2": Maximize2,
  menu: Menu,
  "message-circle": MessageCircle,
  minus: Minus,
  moon: Moon,
  "more-horizontal": MoreHorizontal,
  "more-vertical": MoreVertical,
  move: Move,
  music: Music,
  play: Play,
  plus: Plus,
  "plus-square": PlusSquare,
  "refresh-cw": RefreshCw,
  repeat: Repeat,
  "rotate-cw": RotateCw,
  "rotate-ccw": RotateCcw,
  search: Search,
  send: Send,
  settings: Settings,
  sliders: Sliders,
  smile: Smile,
  sun: Sun,
  target: Target,
  terminal: Terminal,
  "thumbs-up": ThumbsUp,
  "thumbs-down": ThumbsDown,
  "toggle-left": ToggleLeft,
  "toggle-right": ToggleRight,
  "trash-2": Trash2,
  unlock: Unlock,
  user: User,
  "user-check": UserCheck,
  "user-minus": UserMinus,
  "user-plus": UserPlus,
  "user-x": UserX,
  users: Users,
  x: X,
  "x-circle": XCircle,
};

function scaleToUserFontSize(iconSize: number) {
  const USER_REM_SIZE =
    parseFloat(getComputedStyle(document.documentElement).fontSize) / 16;
  return iconSize * USER_REM_SIZE;
}

type Props = {
  id: keyof typeof icons;
  size?: number;
  strokeWidth?: number;
  display?: "inline" | "block";
};

export function Icon({
  id,
  size = 24,
  strokeWidth = 2,
  display = "block",
}: Props) {
  const Component = icons[id];

  if (!Component) {
    throw new Error(`No icon found for ID: ${id}`);
  }

  return (
    <Wrapper
      style={
        {
          "--icon-size": size + "px",
          "--icon-stroke-width": strokeWidth + "px",
          "--icon-display": display,
        } as React.CSSProperties
      }
    >
      {/*
       *** used currentColor in Button to "inherit" color for border, but glitched in iOS Safari when toggled the palette for buttons: part of the border (and not even side-by-side, but a horseshoe shape of border...) would not update color, leaving you with a two-tone border; officially iOS supports this property, so not sure why it went wrong in that context; haven't noticed a problem with it here, but be wary;
       */}
      <Component color="currentColor" size={size} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: var(--icon-size);
  height: var(--icon-size);
  display: var(--icon-display);

  /* in iOS Safari, causes SVG element to "move", as if the shadow occupies space in the dom, and takes a moment to "load" the shadow */
  /* filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3)); */

  /*
    react-feather doesn't make it possible to pass discrete styles to the nested SVG within its components.
  */
  & > svg {
    /* b/c default display of svg is "inline", and b/c browsers treat "inline" elements as if they were typography, there will be extra space added to top/bottom; fix by setting display to "block", or setting parent's line-height to 0: https://courses.joshwcomeau.com/css-for-js/01-rendering-logic-1/09-flow-layout#inline-elements-have-magic-space */
    display: var(--icon-display);
    stroke-width: var(--icon-stroke-width);
    /* height: 1em;
    width: 1em; */
  }
`;
