import { Keyboard } from "@capacitor/keyboard";

/* export const keyboard = {
  showAccessoryBar: () => Keyboard.setAccessoryBarVisible({ isVisible: false }),
}; */

export async function hideKeyboardAccessoryBar() {
  try {
    await Keyboard.setAccessoryBarVisible({ isVisible: false });
  } catch (err) {
    console.error(err);
  }
}
