import React from "react";
import styled from "styled-components/macro";

import { QueryOptions } from "../../_types/relayTypes";

import { PageContext } from "./Page";
import { PageContentData } from "./PageContentData";
import { useDelayFnc } from "../../_hooks/useDelayFnc";
import Spacer from "../Spacer";

function isScrolledToBottom(element: HTMLElement, threshold: number) {
  return (
    // scrollHeight returns integer, so may be rounded up, causing this equality to fail by a fraction, even when element is scrolled to bottom; use Math.ceil on scrollTop value to similarly round it
    element.scrollHeight - Math.ceil(Math.abs(element.scrollTop)) - threshold <=
    element.clientHeight
  );
}

type Props = {
  children?: React.ReactNode | ((queryOptions: QueryOptions) => void);
  // for use with e.g. "logout" button on settings page: elements you want to "escape" ErrorBoundary and Suspense
  // *** not a flexible solution, b/c will always display the "alwaysVisible" content below children
  alwaysVisible?: React.ReactNode;
};

// PageContent receives ref from Section, to allow ChordsSimple to scroll to chord on its selection, and UserSongs to allow scroll to be reset on change of search inputs
// *** later note on the above: given current use of PageContext, wouldn't it make more sense to set ref here, and pass down to these components via context?
// *** ran into issues; not worth it to track down at the moment, so will continue to forwardRefs as before;
export const PageContent = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const [pageState, setPageState] = React.useContext(PageContext);

    const MIN_SCROLL_FROM_TOP = pageState.headerHeight - 16;
    // min distance to scroll before header state can change; prevents e.g. header from rapidly appearing/disappearing if user quicly scrolls up and down alternatively
    const SCROLL_CUMULATIVE_THRESH = 8;

    const scrollCumulative = React.useRef(0);
    const lastScroll = React.useRef(0);

    function handleScroll(e: React.UIEvent) {
      const BOTTOM_THRESHOLD = 32;
      // EventTarget interface in typescript is generic, need to cast the target as correct element type to convince TS to allow you to access properties on the event target
      // https://stackoverflow.com/questions/42081549/typescript-react-event-types
      let target = e.target as HTMLElement;
      const currentScroll = target?.scrollTop;
      if (currentScroll <= 0) {
        setPageState((state) => ({
          ...state,
          isHeaderVisible: true,
          contentScrollState: "top",
        }));
        return;
      } else if (isScrolledToBottom(target, BOTTOM_THRESHOLD)) {
        setPageState((state) => ({
          ...state,
          isHeaderVisible: true,
          contentScrollState: "bottom",
        }));
        return;
      } else {
        const scrollDirection =
          currentScroll > lastScroll.current ? "down" : "up";
        if (
          scrollDirection === "down" &&
          pageState.isHeaderVisible &&
          // don't hide header unless scrolled down most of header's height
          currentScroll > MIN_SCROLL_FROM_TOP
        ) {
          // scroll down
          if (scrollCumulative.current >= SCROLL_CUMULATIVE_THRESH) {
            // *** note: unlikely to be problemmatic in practice, but b/c you test only scrollCumulative.current, and don't add in the current scroll (the difference between currentScroll and lastScroll.current), user could have moved well beyond SCROLL_CUMULATIVE_THRESH at this point, but it wouldn't satisfy this if() condition; rather, the next scroll event - provided its in the same direction would trigger it, no matter its distance; could be significant detail if add throttling and only poll every 200ms or so
            setPageState((state) => ({
              ...state,
              contentScrollState: "middle",
              isHeaderVisible: false,
            }));
            scrollCumulative.current = 0;
          } else {
            // must update contentScrollState here (and in comparable place in next "if" block, for scrolling up), or use can scroll down once quickly (easy to reproduce on desktop) and e.g. won't get box shadow around header b/c contentScrollState would still be "top";
            setPageState((state) => ({
              ...state,
              contentScrollState: "middle",
            }));
            scrollCumulative.current += currentScroll - lastScroll.current;
          }
        } else if (scrollDirection === "up" && !pageState.isHeaderVisible) {
          // scroll up
          if (scrollCumulative.current <= -SCROLL_CUMULATIVE_THRESH) {
            setPageState((state) => ({
              ...state,
              contentScrollState: "middle",
              isHeaderVisible: true,
            }));
            scrollCumulative.current = 0;
          } else {
            setPageState((state) => ({
              ...state,
              contentScrollState: "middle",
            }));
            scrollCumulative.current += currentScroll - lastScroll.current;
          }
        } else {
          setPageState((state) => ({
            ...state,
            contentScrollState: "middle",
          }));
        }
      }

      lastScroll.current = currentScroll;
    }

    const { delayFnc } = useDelayFnc();

    function throttleHandleScroll(e: React.UIEvent) {
      delayFnc({
        callback: handleScroll,
        callbackParams: [e],
        delay: 20,
      });
    }

    return (
      <Wrapper ref={ref} onScroll={throttleHandleScroll}>
        {/* <Wrapper ref={pageState.pageContentRef} onScroll={throttleHandleScroll}> */}
        <Spacer
          size={pageState.headerHeight}
          axis="vert"
          transition="min-height 300ms ease-in, height 300ms ease-in"
        />
        <PageContentData
          children={props.children}
          alwaysVisible={props.alwaysVisible}
        />
        <Spacer size={pageState.footerHeight} axis="vert" />
      </Wrapper>
    );
  }
);

const Wrapper = styled.main`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  /* matching safe areas in header and content components work in conjunction */
  padding-top: env(safe-area-inset-top);
`;
