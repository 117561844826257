import React from "react";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { UpdateSectionNotes_song$key } from "../__generated__/UpdateSectionNotes_song.graphql";
import { UpdateSectionNotes_section$key } from "../__generated__/UpdateSectionNotes_section.graphql";
import {
  UpdateSectionNotesMutation,
  updateSectionNotesInput,
} from "../__generated__/UpdateSectionNotesMutation.graphql";

import { TextInputModal } from "../_components/TextInputModal";
import { ModalError } from "../_components/ModalError";
import { isApiError } from "../_types/errorTypes";

type Props = {
  songRef: UpdateSectionNotes_song$key;
  sectionRef: UpdateSectionNotes_section$key;
};

export function UpdateSectionNotes(props: Props) {
  const song = useFragment(
    graphql`
      fragment UpdateSectionNotes_song on Song {
        id
        mongoVersion
      }
    `,
    props.songRef
  );

  const section = useFragment(
    graphql`
      fragment UpdateSectionNotes_section on SongSection {
        id
        notes
      }
    `,
    props.sectionRef
  );

  const [commit, isInFlight] = useMutation<UpdateSectionNotesMutation>(graphql`
    mutation UpdateSectionNotesMutation($input: updateSectionNotesInput!) {
      updateSectionNotes(input: $input) {
        song {
          ...UpdateSectionNotes_song
        }
        section {
          ...UpdateSectionNotes_section
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  const [errMsg, setErrMsg] = React.useState<string>("");

  function getOptimisticResponse(input: updateSectionNotesInput) {
    return {
      updateSectionNotes: {
        song: {
          id: input.songId,
          mongoVersion: input.mongoVersion + 1,
        },
        section: {
          id: input.sectionId,
          notes: input.notes,
        },
        errors: [],
      },
    };
  }

  function handleUpdateSection(input: updateSectionNotesInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSectionNotes) {
          const { errors } = response.updateSectionNotes;
          if (errors.length > 0) {
            const errMsgs = errors.map((err) => err.message);
            setErrMsg(errMsgs.join("; "));
          }
        }
      },
      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <TextInputModal
        textInputs={[
          {
            id: "notes",
            initialValue: section.notes,
            label: "Notes",
            elementType: "textarea",
          },
        ]}
        callback={(inputValues: Record<string, string>) => {
          handleUpdateSection({
            songId: song.id,
            mongoVersion: song.mongoVersion,
            sectionId: section.id,
            notes: inputValues.notes,
          });
        }}
        modalLabel="Update Section Notes"
      />
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}
