// return true if arrays are non-null and contain same members in same order
// note: this means any members of the array that are arrays or objects must be *identical*, not merely containing the same values
export function arraysEqual(
  a: any[] | readonly any[],
  b: any[] | readonly any[],
  doSort?: boolean
) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  var aClone = a.slice();
  var bClone = b.slice();
  if (doSort) {
    aClone.sort();
    bClone.sort();
  }

  for (var i = 0; i < aClone.length; ++i) {
    if (aClone[i] !== bClone[i]) return false;
  }
  return true;
}

// push each element in child onto parent, if it's not already there; return new array
export function pushUnique(parent: any[], child: any[]) {
  const parentClone = parent.slice();
  const childClone = child.slice();
  for (let val of childClone) {
    var location = parentClone.indexOf(val);
    if (location === -1) {
      parentClone.push(val);
    }
  }
  return parentClone;
}

// splice each element in child out of parent, return array of removed elements
export function removeFromArr(parent: any[], child: any[]) {
  const removed = [];
  for (let val of child) {
    var location = parent.indexOf(val);
    if (location > -1) {
      removed.push(parent.splice(location, 1));
    }
  }
  return removed;
}

// splice each element in child out of parent, return new array and array of removed elements
export function removeFromArrImmut(parent: any[], child: any[]) {
  const parentClone = parent.slice();
  const childClone = child.slice();
  const removed = [];
  for (let val of childClone) {
    var location = parentClone.indexOf(val);
    if (location > -1) {
      removed.push(parentClone.splice(location, 1));
    }
  }
  return { result: parentClone, removed };
}

// *** better if typed to return same sort of array received
export function moveArrItem(
  arr: any[],
  moveFrom: number,
  moveTo: number
): any[] {
  if (moveFrom === moveTo) return [...arr];
  var targetElement = arr[moveFrom];
  if (!targetElement) return [...arr];
  // if moving element up, it should go above target element; if moving down, it should go below; thus causing all other elements to "shift" in the direction it came from;
  const insertAdjust = moveTo > moveFrom ? 1 : 0;
  var newArr = [
    ...arr.slice(0, moveTo + insertAdjust),
    targetElement,
    ...arr.slice(moveTo + insertAdjust),
  ];
  // if moving element down, its index will have increased by one after you insert a clone of it at moveTo, so find it (and remove it) at moveFrom + 1;
  const removeAdjust = moveTo < moveFrom ? 1 : 0;
  newArr.splice(moveFrom + removeAdjust, 1);
  return newArr;
}

// note: briefly used a version of this fnc in rhythmCount to ensure each chord's "rhythm" array was same length, to ensure when rhythms were displayed (using CSS flex), each rhythm count would occupy the same hoizontal space; moved away from this solution, instead using CSS grid, with columns for all cells set to the length of the longest "rhythm" array in the section;
// could modify to take a fnc to determine padding;
export function padArray(arr: any[], minArrSize: number, padVal: any) {
  var arrClone = arr.slice(0);
  arrClone = [
    ...arrClone,
    ...Array.from(Array(minArrSize - arrClone.length)).map((val) => padVal),
  ];
}
