import React from "react";
import styled from "styled-components/macro";
import { hapticsImpactLight } from "../../_plugins/Haptics";

import { Icon } from "../Icon";
// import { RippleContainer, useRipple } from "../useRipple";
import { getDisplayedValue } from "./Select.helpers";

type Props = {
  name: string;
  label?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  children: React.ReactNode;
  // children: HTMLOptionElement[];
};

export const Select = ({ name, label, value, onChange, children }: Props) => {
  const displayedValue = getDisplayedValue(value, children);
  // const { createRipple, clickParams } = useRipple();
  const CHEVRON_SIZE = 20;

  return (
    <SelectWrapper
      // onClick={(e) => createRipple(e)}
      onClick={() => hapticsImpactLight()}
    >
      <LabelSelectWrapper>
        {label && (
          <LabelWrapper>
            <Label htmlFor={name}>{label}</Label>
          </LabelWrapper>
        )}
        <NativeSelect name={name} value={value} onChange={onChange}>
          {children}
        </NativeSelect>
        <PresentationSelect>
          {displayedValue}
          {/* 
        In TS, can't pass object literal with unidentified props. 
        1) To pass style object, can store as object var then pass var to style prop (instead of including object literal inline) *and* type the receiving styled-component to accept "style" as an "object"
        2) Or can cast/extend type per:
        https://chaseadams.io/posts/typescript-var-cssproperties/ */}
          <IconWrapper
            style={
              {
                "--size": CHEVRON_SIZE + "px",
                "--right": 8 * (16 / CHEVRON_SIZE) + "px",
              } as React.CSSProperties
            }
          >
            <Icon id="chevron-down" strokeWidth={2} size={CHEVRON_SIZE} />
          </IconWrapper>
        </PresentationSelect>
        {/* <RippleContainer clickParams={clickParams} /> */}
      </LabelSelectWrapper>
    </SelectWrapper>
  );
};

const LabelSelectWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
`;

const SelectWrapper = styled.div`
  position: relative;
  /* overflow hidden to contain RippleContainer's ripple */
  overflow: hidden;
`;

const NativeSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

const PresentationSelect = styled.div`
  /* background-color: var(--color-background-tint-translucent-75); */
  /* background-color: var(--color-mono-translucent-15); */
  /* nice that this color matches that of text input underline */
  /* background-color: var(--color-text-translucent); */
  color: var(--color-text-dark);
  font-size: ${16 / 16}rem;
  padding: 8px 16px;
  padding-right: 36px;
  border-radius: 8px;
  /* font-weight: 500; */

  ${NativeSelect}:focus-visible + & {
    background-color: var(--color-background-tint);
    color: var(--color-text);
  }

  ${NativeSelect}:hover + & {
    color: var(--color-text);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  /*   right: calc(var(--size) / 2); */
  right: var(--right);
  margin: auto;
  width: var(--size);
  height: var(--size);
  pointer-events: none;
  /* color: var(--color-text-medium); */
  color: var(--color-primary);

  /* background-color: var(--color-text-translucent); */
  /* border-radius: 20%; */
`;

const LabelWrapper = styled.div`
  flex: 1;
`;

const Label = styled.label`
  font-size: ${14 / 16}rem;
  /* color: var(--color-text-medium); */
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: 500;
`;
