import styled from "styled-components/macro";

export const StyledForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  // position:relative; to allow an absolute-positioned overlay while request is in flight;
  position: relative;
`;

export const InputFields = styled.div`
  flex: 1;
  padding-bottom: 16px;
`;

export const FieldError = styled.div`
  padding-left: 8px;
  color: var(--color-danger);
  font-weight: 500;
  /* font-style: italic; */
`;
