/**
 * @generated SignedSource<<2b6c3ed6d1e733923c1f240765d74209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updateOwnPrefsInput = {
  boxIntervals: ReadonlyArray<number>;
  clientMutationId?: string | null;
};
export type UpdateBoxIntervalsMutation$variables = {
  input: updateOwnPrefsInput;
};
export type UpdateBoxIntervalsMutation$data = {
  readonly updateOwnPrefs: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
      readonly message?: string;
    }>;
    readonly updatedUser: {
      readonly boxIntervals: ReadonlyArray<number>;
      readonly id: string;
    } | null;
  } | null;
};
export type UpdateBoxIntervalsMutation = {
  response: UpdateBoxIntervalsMutation$data;
  variables: UpdateBoxIntervalsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "updateOwnPrefsPayload",
    "kind": "LinkedField",
    "name": "updateOwnPrefs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updatedUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boxIntervals",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "UserError",
            "abstractKey": "__isUserError"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBoxIntervalsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBoxIntervalsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0e26d809d02a46b704f8de283280de1",
    "id": null,
    "metadata": {},
    "name": "UpdateBoxIntervalsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBoxIntervalsMutation(\n  $input: updateOwnPrefsInput!\n) {\n  updateOwnPrefs(input: $input) {\n    updatedUser {\n      id\n      boxIntervals\n    }\n    errors {\n      __typename\n      ... on UserError {\n        __isUserError: __typename\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea51430ef950313b950f26d2a9ddfeaa";

export default node;
