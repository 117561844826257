import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay/hooks";
import styled from "styled-components/macro";

import { UpdateSongTimeSig_song$key } from "../__generated__/UpdateSongTimeSig_song.graphql";
import {
  TimeSig,
  updateSongTimeSigInput,
  UpdateSongTimeSigMutation,
} from "../__generated__/UpdateSongTimeSigMutation.graphql";

import { isApiError } from "../_types/errorTypes";

import { ModalError } from "../_components/ModalError";
import { Select } from "../_components/Select";
import {
  timeSigValToLabel,
  TIME_SIGS,
  TIME_SIGS_DEFAULT,
} from "../_music/rhythmCount";

type Props = {
  song: UpdateSongTimeSig_song$key;
};

export default function UpdateSongTimeSig(props: Props) {
  const songData = useFragment(
    graphql`
      fragment UpdateSongTimeSig_song on Song {
        id
        mongoVersion
        timeSig
      }
    `,
    props.song
  );

  const { id, timeSig, mongoVersion } = songData;
  const [errMsg, setErrMsg] = useState<string>("");

  const [commit, isInFlight] = useMutation<UpdateSongTimeSigMutation>(graphql`
    mutation UpdateSongTimeSigMutation($input: updateSongTimeSigInput!) {
      updateSongTimeSig(input: $input) {
        updatedSong {
          id
          timeSig
          mongoVersion
        }
        errors {
          __typename
          message
        }
      }
    }
  `);

  /*
  // unless want to make changing key a "locking" activity, any need for these props?  What if update request rejected b/c other user has locked?   
  isLockedByUser
  editLock {
    isLocked
    lockedBy
    lockedByEmail
    lockedUntil
  } */

  function getOptimisticResponse(input: updateSongTimeSigInput) {
    return {
      updateSongTimeSig: {
        updatedSong: {
          id: input.songId,
          mongoVersion:
            input.timeSig === timeSig
              ? input.mongoVersion + 1
              : input.mongoVersion,
          timeSig: input.timeSig,
        },
        errors: [],
      },
    };
  }

  function handleUpdateSong(input: updateSongTimeSigInput) {
    commit({
      variables: {
        input,
      },
      optimisticResponse: getOptimisticResponse(input),
      onCompleted: (response) => {
        if (response.updateSongTimeSig) {
          const { updatedSong, errors } = response.updateSongTimeSig;
          if (errors.length > 0) {
            if (errors.length > 0) {
              const errMsgs = errors.map((err) => err.message);
              setErrMsg(errMsgs.join("; "));
            }
          }
        }
      },

      onError: (err) => {
        if (isApiError(err)) {
          setErrMsg(err.message);
        }
      },
    });
  }

  return (
    <>
      <Wrapper>
        <Select
          name="timeSig"
          label="Time Signature"
          onChange={(e) =>
            handleUpdateSong({
              songId: id,
              mongoVersion: mongoVersion,
              // *** I do this elsewhere, and I don't like it; need to identify a better way to ensure type "flow" between server and client code;
              timeSig: e.target.value as TimeSig,
            })
          }
          value={timeSig}
        >
          {TIME_SIGS.filter((val) => {
            if (val === "%future added value") {
              return false;
            }
            // if timeSig is default val (unset) display it as "4/4"; if not unset, filter that val so it can't be selected;
            /* if (timeSig !== TIME_SIGS_DEFAULT && val === TIME_SIGS_DEFAULT) {
              return false;
            } */
            else {
              return true;
            }
          }).map((val) => {
            return (
              <option value={val} key={val}>
                {timeSigValToLabel(val)}
                {val === TIME_SIGS_DEFAULT && " (default)"}
              </option>
            );
          })}
        </Select>
      </Wrapper>
      <ModalError errMsg={errMsg} setErrMsg={setErrMsg} />
    </>
  );
}

const Wrapper = styled.div`
  padding: 8px 16px;
`;
