/**
 * @generated SignedSource<<a3aa277317de690611b4db2323a1dfbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeSig = "VALUE_UNSET" | "t12_8" | "t2_2" | "t2_4" | "t3_4" | "t4_4" | "t6_8" | "t9_8" | "%future added value";
export type SectionQueryQuery$variables = {
  sectionId: string;
  songId: string;
};
export type SectionQueryQuery$data = {
  readonly songSection: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "NotFound";
      readonly message: string;
    }>;
    readonly section: {
      readonly chords: ReadonlyArray<{
        readonly definition: {
          readonly bass: string;
          readonly qual: string;
          readonly root: string;
        };
        readonly duration: number;
        readonly id: string;
        readonly notation: ReadonlyArray<string>;
      }>;
      readonly id: string;
      readonly name: string;
      readonly notes: string;
      readonly timeSig: TimeSig;
      readonly " $fragmentSpreads": FragmentRefs<"SectionQueryChords_section">;
    } | null;
    readonly song: {
      readonly id: string;
      readonly mongoVersion: number;
      readonly timeSig: TimeSig;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"SectionQueryChords_song">;
    } | null;
  } | null;
};
export type SectionQueryQuery = {
  response: SectionQueryQuery$data;
  variables: SectionQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sectionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "songId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "sectionId",
    "variableName": "sectionId"
  },
  {
    "kind": "Variable",
    "name": "songId",
    "variableName": "songId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoVersion",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSig",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ChordDef",
  "kind": "LinkedField",
  "name": "definition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "root",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bass",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notation",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "NotFound",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rhythm",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SectionQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SongSectionQueryResult",
        "kind": "LinkedField",
        "name": "songSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionQueryChords_song"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Chord",
                "kind": "LinkedField",
                "name": "chords",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SectionQueryChords_section"
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SectionQueryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SongSectionQueryResult",
        "kind": "LinkedField",
        "name": "songSection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Song",
            "kind": "LinkedField",
            "name": "song",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "SectionConnection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SongSection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Chord",
                            "kind": "LinkedField",
                            "name": "chords",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v9/*: any*/),
                              (v15/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "sections(first:2147483647)"
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "MoveSection_sections",
                "kind": "LinkedHandle",
                "name": "sections"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SongSection",
            "kind": "LinkedField",
            "name": "section",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Chord",
                "kind": "LinkedField",
                "name": "chords",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rhythmUnit",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "51c5c6fce9b35e36ab716c57d36868f1",
    "id": null,
    "metadata": {},
    "name": "SectionQueryQuery",
    "operationKind": "query",
    "text": "query SectionQueryQuery(\n  $songId: ID!\n  $sectionId: ID!\n) {\n  songSection(songId: $songId, sectionId: $sectionId) {\n    song {\n      id\n      title\n      mongoVersion\n      timeSig\n      ...SectionQueryChords_song\n    }\n    section {\n      id\n      name\n      timeSig\n      notes\n      chords {\n        id\n        duration\n        definition {\n          root\n          qual\n          bass\n        }\n        notation\n      }\n      ...SectionQueryChords_section\n    }\n    errors {\n      __typename\n      message\n    }\n  }\n}\n\nfragment ChordsSimple_section on SongSection {\n  id\n  timeSig\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n}\n\nfragment ChordsSimple_song on Song {\n  id\n  mongoVersion\n  timeSig\n  sections(first: 2147483647) {\n    edges {\n      node {\n        id\n        name\n        notes\n        chords {\n          id\n          duration\n          rhythm\n          definition {\n            root\n            qual\n            bass\n          }\n          notation\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SectionQueryChords_section on SongSection {\n  id\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n  ...UpdateSectionChordsMutation_section\n  ...ChordsSimple_section\n}\n\nfragment SectionQueryChords_song on Song {\n  id\n  mongoVersion\n  ...UpdateSectionChordsMutation_song\n  ...ChordsSimple_song\n}\n\nfragment UpdateSectionChordsMutation_section on SongSection {\n  id\n  rhythmUnit\n  chords {\n    id\n    duration\n    rhythm\n    definition {\n      root\n      qual\n      bass\n    }\n    notation\n  }\n}\n\nfragment UpdateSectionChordsMutation_song on Song {\n  id\n  mongoVersion\n}\n"
  }
};
})();

(node as any).hash = "42cc674f735cd21fa11e2a6889bc88df";

export default node;
