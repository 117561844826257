import { StringObj } from "../_types";

export const rootLetters = ["A", "B", "C", "D", "E", "F", "G"];

// single accidentals for half steps; double for full steps
export const rootNums: StringObj = {
  "G♯♯": 0,
  A: 0,
  "B♭♭": 0,
  "A♯": 1,
  "B♭": 1,
  "A♯♯": 2,
  B: 2,
  "C♭": 2,
  "B♯": 3,
  C: 3,
  "D♭♭": 3,
  "B♯♯": 4,
  "C♯": 4,
  "D♭": 4,
  "C♯♯": 5,
  D: 5,
  "E♭♭": 5,
  "D♯": 6,
  "E♭": 6,
  "D♯♯": 7,
  E: 7,
  "F♭": 7,
  "E♯": 8,
  F: 8,
  "G♭♭": 8,
  "E♯♯": 9,
  "F♯": 9,
  "G♭": 9,
  "F♯♯": 10,
  G: 10,
  "A♭♭": 10,
  "G♯": 11,
  "A♭": 11,
  "B♭♭♭": 11,
};

export const roots: string[] = Object.keys(rootNums);
