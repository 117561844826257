import React from "react";
import styled from "styled-components/macro";

import { usePullRefresh } from "../../_hooks/usePullRefresh";
import { ResizableCanvas } from "../ResizableCanvas";
import { PullToRefreshUI } from "./PullToRefreshUI";

/**
 * Consider: Adding "setTitle" logic into Page components, if you find you often will need to use callbacks to determine what header title will be
 * */

export const DEFAULT_HEADER_HEIGHT = 48;
export const DEFAULT_FOOTER_HEIGHT = 0;

type ContentScrollState = "top" | "middle" | "bottom";

type PageState = {
  headerHeight: number;
  footerHeight: number;
  contentScrollState: ContentScrollState;
  isHeaderVisible: boolean;
  refreshQuery: (doUseStore?: boolean) => void;
  pageContentRef: React.RefObject<HTMLDivElement>;
};

const pageStateInit: PageState = {
  headerHeight: DEFAULT_HEADER_HEIGHT,
  footerHeight: DEFAULT_FOOTER_HEIGHT,
  contentScrollState: "top",
  isHeaderVisible: true,
  refreshQuery: () => null,
  pageContentRef: React.createRef<HTMLDivElement>(),
};

export const PageContext = React.createContext<
  [PageState, React.Dispatch<React.SetStateAction<PageState>>]
>([pageStateInit, () => null]);

type Props = {
  children?: React.ReactNode;
  headerHeight?: number;
  footerHeight?: number;
  disablePullRefresh?: boolean;
};

export function Page(props: Props) {
  const canvasSizingContainerRef = React.useRef<HTMLDivElement>(null);
  const canvasParentRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const [pageState, setPageState] = React.useState<PageState>(pageStateInit);

  const { refreshQuery, contentScrollState } = pageState;

  React.useEffect(() => {
    setPageState((prevState) => ({
      ...prevState,
      ...(props.headerHeight && { headerHeight: props.headerHeight }),
      ...(props.footerHeight && { footerHeight: props.footerHeight }),
    }));
  }, [props.headerHeight, props.footerHeight]);

  const DO_SHOW_GRAFFITI = false;
  const {
    touchHandlers,
    primaryTouch,
    VERT_MIN_FOR_DISPLAY,
    useTouchStyle,
  } = usePullRefresh({
    // want contentScrollState to be "top" *at start of drag* before activating pull down; this works b/c of debounce in PageContent that sets contentScrollState; w/o debounce, the update to contentScrollState will fire constantly during a drag, meaning val will be "top" the instant top is reached, so the pullDown event would always fire; with the debounce, it won't update immediately, so unless val was already "top" before drag began, pull down event won't fire;
    doAllowPullRefresh: contentScrollState === "top",
    canvasRef,
    canvasParentRef,
    canvasSizingContainerRef,
    canvasOffset: {
      x: 0,
      y: 0,
      // y: DEFAULT_HEADER_HEIGHT
    },
    callbackPullDown: () => refreshQuery(true),
    // touchTargetRef,
    doShowGraffiti: DO_SHOW_GRAFFITI,
  });

  return (
    <>
      {DO_SHOW_GRAFFITI && (
        <ResizableCanvas
          canvasSizingContainerRef={canvasSizingContainerRef}
          canvasParentRef={canvasParentRef}
          canvasRef={canvasRef}
        />
      )}

      <Wrapper
        {...(!props.disablePullRefresh && touchHandlers)}
        // ref={touchTargetRef}
      >
        <PageContext.Provider value={[pageState, setPageState]}>
          {props.children}
        </PageContext.Provider>
      </Wrapper>

      {!props.disablePullRefresh && (
        <PullToRefreshUI
          primaryTouch={primaryTouch}
          useTouchStyle={useTouchStyle}
          VERT_MIN_FOR_DISPLAY={VERT_MIN_FOR_DISPLAY}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  height: 100%;

  /* top safe area is handled in header/content */
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  /* no background, to allow canvas to be seen through Page (if canvas in use) */
`;
