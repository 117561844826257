import React from "react";
import styled from "styled-components/macro";

type Props = {
  size?: number;
  axis?: "horiz" | "vert";
  transition?: string;
};

const Spacer = ({ size = 8, axis = "vert", transition = "none" }: Props) => {
  const style = {
    "--height": axis === "horiz" ? "1px" : size / 16 + "rem",
    "--width": axis === "vert" ? "1px" : size / 16 + "rem",
    "--transition": transition,
  };

  return <Wrapper style={style} />;
};

const Wrapper = styled.span<{
  style: object;
}>`
  transition: var(--transition);
  display: block;
  width: var(--width);
  min-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  user-select: none;
`;

export default Spacer;
