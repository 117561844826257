import React from "react";
import { useParams } from "react-router-dom";

import { Page, PageHeader, PageContent } from "../_components/Page";
import { SongMetaQuery } from "./SongMetaQuery";

export const SongMeta: React.FC = () => {
  const { songId } = useParams<{ songId: string }>();
  const [title, setTitle] = React.useState<string>("");

  return (
    <Page>
      <PageHeader title={title} backPath={`/song-edit/${songId}`} />
      <PageContent>
        {(queryOptions) => {
          return (
            <SongMetaQuery
              songId={songId}
              setTitle={setTitle}
              queryOptions={queryOptions}
            />
          );
        }}
      </PageContent>
    </Page>
  );
};
